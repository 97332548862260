
// Utility function to get venue image based on venue properties
export const getVenueImage = (venue: any) => {
  // For Sportslane, use a specific image
  if (venue.name.toLowerCase().includes('sportslane')) {
    return "/lovable-uploads/3be8a4e6-6b93-4939-a954-a79bbd3aadd5.png";
  }
  
  // For external venues, gyms, or academies, use the placeholder image
  if (!venue.is_bookable || venue.is_external) {
    return "/lovable-uploads/4379314c-2c04-48cb-8e39-33c32386ce3a.png";
  }
  
  // For other bookable venues, use their own images if available
  return venue.images && venue.images.length > 0 ? venue.images[0] : "/lovable-uploads/4379314c-2c04-48cb-8e39-33c32386ce3a.png";
};

// Utility function to get image for gyms and academies, ensuring external ones use the abstract image
export const getExternalImage = (item: any) => {
  // Always use the abstract placeholder for external items
  if (item.is_external === true) {
    return "/lovable-uploads/4379314c-2c04-48cb-8e39-33c32386ce3a.png";
  }
  
  // For non-external items, use their images if available
  if (item.images && item.images.length > 0) {
    return item.images[0];
  } else if (item.image_url) {
    return item.image_url;
  } else {
    // Fallback to placeholder
    return "/lovable-uploads/4379314c-2c04-48cb-8e39-33c32386ce3a.png";
  }
};
