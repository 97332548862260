
import { format, parseISO, addMinutes } from "date-fns";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Sparkles, X, Lock, ChevronDown } from "lucide-react";
import { FacilityRules } from "./FacilityRules";

interface TimeSlot {
  id: string;
  start_time: string;
  end_time: string;
  is_booked: boolean;
  facility_id: string;
}

interface TimeSlotsProps {
  facilityName: string;
  slots: TimeSlot[];
  selectedSlots: string[];
  onSlotSelect: (slotId: string) => void;
  pricePerHour: number;
  className?: string;
  titleClassName?: string;
  onClearSelections?: (facilityId: string) => void;
  onScrollToForm?: () => void;
  isUnblockMode?: boolean;
}

export const TimeSlots = ({
  facilityName,
  slots,
  selectedSlots,
  onSlotSelect,
  pricePerHour,
  className,
  titleClassName,
  onClearSelections,
  onScrollToForm,
  isUnblockMode = false
}: TimeSlotsProps) => {
  console.log(`Rendering TimeSlots for ${facilityName}`, {
    slotsCount: slots.length,
    selectedSlots,
    firstSlotTime: slots.length > 0 ? slots[0].start_time : null,
    timeSlots: slots.map(s => format(parseISO(s.start_time), "h:mm a"))
  });

  // Determine if this is a value court based on the name
  const isValueCourt = facilityName === "Badminton Court 5" || facilityName === "Badminton Court 6";
  
  // Check if we should show the value pricing note
  const showValuePricingNote = ["Badminton Court 1", "Badminton Court 2", "Badminton Court 3", "Badminton Court 4"].includes(facilityName);

  // Calculate the display price based on court type
  let displayPrice = pricePerHour;
  if (["Badminton Court 1", "Badminton Court 2", "Badminton Court 3", "Badminton Court 4"].includes(facilityName)) {
    displayPrice = 400;
  } else if (isValueCourt) {
    displayPrice = 208; // Updated from 250 to 208
  } else if (facilityName.startsWith("Pickleball Court") || facilityName.startsWith("Tennis Court")) {
    displayPrice = 400; // Updated from 600 to 400
  }

  // Modified to always return true for single slot selection
  const canSelectSlot = (slotId: string) => {
    return true; // Allow selection of any available slot
  };

  // Handle toggling a time slot selection with single selection logic
  const handleSlotToggle = (slotId: string, event?: React.TouchEvent | React.MouseEvent) => {
    if (event) {
      event.preventDefault();
    }

    // If the slot is already selected, deselect it
    if (selectedSlots.includes(slotId)) {
      onSlotSelect(slotId);
      return;
    }

    // If another slot is already selected, we need to first deselect it (by passing it to onSlotSelect)
    // then select the new slot
    if (selectedSlots.length > 0) {
      // Get the current selected slot (assuming only one is selected)
      const currentSelectedSlot = selectedSlots[0];
      // Deselect the current slot
      onSlotSelect(currentSelectedSlot);
    }
    
    // Now select the new slot
    onSlotSelect(slotId);
  };

  // Check if any slots are selected for this facility
  const hasSelectedSlots = slots.some(slot => selectedSlots.includes(slot.id));
  
  // Get the facility ID for clearing selections
  const facilityId = slots.length > 0 ? slots[0].facility_id : '';
  
  // Handle clearing all selections for this facility
  const handleClearSelections = () => {
    if (onClearSelections && facilityId) {
      onClearSelections(facilityId);
    }
  };

  // Sort the time slots chronologically by start time
  const sortedSlots = [...slots].sort((a, b) => {
    const dateA = new Date(a.start_time);
    const dateB = new Date(b.start_time);
    return dateA.getTime() - dateB.getTime();
  });

  // Format the time in a readable format using date-fns
  const formatTimeInIST = (timeString: string) => {
    try {
      const date = parseISO(timeString);
      // Format as hour:minute AM/PM consistently for all courts
      return format(date, "h:mm a");
    } catch (error) {
      console.error("Error formatting time:", error, timeString);
      return "Invalid time";
    }
  };

  // Debug log to check the time slot formatting for all courts
  if (facilityName.startsWith("Tennis Court") || facilityName.startsWith("Pickleball Court")) {
    console.log(`Time slots for ${facilityName}:`, sortedSlots.map(slot => ({
      id: slot.id,
      rawTime: slot.start_time,
      parsedTime: parseISO(slot.start_time).toISOString(),
      formattedTime: formatTimeInIST(slot.start_time)
    })));
  }

  return (
    <Card className={className}>
      <CardHeader className="pb-3">
        <CardTitle className="text-lg font-semibold flex justify-between items-center">
          <div className="flex items-center gap-2">
            <span className={titleClassName}>{facilityName}</span>
            {isValueCourt && (
              <div className="flex items-center gap-2 bg-gradient-to-r from-amber-100 to-amber-200 text-amber-800 py-1 rounded-full text-sm font-medium animate-fade-in md:text-sm text-xs md:px-3 px-[12px] mx-[8px]">
                <Sparkles className="w-4 h-4 md:w-4 md:h-4 w-3 h-3" />
                <span>beSportly Exclusive - Value Price</span>
              </div>
            )}
          </div>
          <span className="text-sm text-gray-700 dark:text-gray-300">₹{displayPrice}/hour</span>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-4 sm:grid-cols-5 md:grid-cols-6 gap-2">
          {sortedSlots.map(slot => {
            // Determine if the slot should be selectable based on mode
            const isSelectable = isUnblockMode ? slot.is_booked : !slot.is_booked;
            
            return (
              <Button
                key={slot.id}
                variant={selectedSlots.includes(slot.id) ? "default" : "outline"}
                className={`w-full touch-manipulation relative ${
                  selectedSlots.includes(slot.id) 
                    ? "dark:bg-primary dark:text-white" 
                    : isSelectable
                      ? "dark:bg-transparent dark:text-gray-300 dark:hover:bg-gray-700"
                      : "bg-gray-100 text-gray-400 dark:bg-gray-800 dark:text-gray-500 cursor-not-allowed opacity-70 hover:bg-gray-100 dark:hover:bg-gray-800"
                }`}
                onClick={(e) => isSelectable && handleSlotToggle(slot.id, e)}
                onTouchStart={(e) => {
                  if (isSelectable) {
                    e.stopPropagation();
                    handleSlotToggle(slot.id, e);
                  }
                }}
                disabled={!isSelectable}
              >
                {formatTimeInIST(slot.start_time)}
                {slot.is_booked && !isUnblockMode && (
                  <span className="absolute inset-0 flex items-center justify-center bg-gray-200/60 dark:bg-gray-800/60 rounded-md">
                    <Lock className="h-3 w-3 text-gray-500 dark:text-gray-400" />
                  </span>
                )}
                {slot.is_booked && isUnblockMode && (
                  <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full m-1"></span>
                )}
              </Button>
            );
          })}
        </div>
        
        {hasSelectedSlots && (
          <div className="mt-3 flex flex-col items-center gap-3">
            <Button
              variant="outline"
              size="sm"
              onClick={handleClearSelections}
              className="bg-gradient-to-r from-purple-500/90 to-indigo-500/90 hover:from-purple-600 hover:to-indigo-600 text-white border-none shadow-md hover:shadow-lg transition-all duration-300 rounded-full px-4 py-2 flex items-center gap-2"
            >
              <X className="h-4 w-4" strokeWidth={2.5} />
              <span>Clear All Selections</span>
            </Button>
            
            {/* Mobile-only complete booking button - Modified to center text and remove animation */}
            {onScrollToForm && hasSelectedSlots && (
              <Button
                variant="ghost"
                size="sm"
                onClick={onScrollToForm}
                className="md:hidden flex items-center justify-center bg-gradient-to-r from-blue-400 to-violet-400 text-white shadow-md hover:shadow-lg rounded-full px-6 py-3 mt-2"
              >
                <span className="font-medium text-sm">Complete Booking</span>
              </Button>
            )}
          </div>
        )}
        
        {showValuePricingNote && (
          <div className="mt-4 p-4 bg-gradient-to-r from-purple-50 to-amber-50 dark:from-purple-900/10 dark:to-amber-900/10 rounded-lg border border-amber-200 dark:border-amber-800">
            <div className="flex items-start gap-3">
              <Sparkles className="w-5 h-5 text-amber-600 dark:text-amber-400 mt-1" />
              <div>
                <p className="text-gray-800 dark:text-gray-200 font-medium">Looking for better rates?</p>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Try our value-priced Badminton Courts 5 & 6 for the most affordable booking options!
                </p>
              </div>
            </div>
          </div>
        )}
        
        {/* Added Facility Rules component */}
        <div className="mt-3 flex justify-between items-center">
          <div className="text-xs text-gray-500">
            All times shown are in Indian Standard Time (IST)
          </div>
          <FacilityRules facilityName={facilityName} facilityType="" />
        </div>
      </CardContent>
    </Card>
  );
};
