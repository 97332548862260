
import { ArrowLeft, Bot, Sparkles, Megaphone } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useIsMobile } from "@/hooks/use-mobile";
import FeedbackSheet from "@/components/Support/FeedbackSheet";
import { motion } from "framer-motion";

// FAQ data
const faqs = [
  {
    question: "How do I book a sports venue on beSportly?",
    answer: "Browse our Venues section, select your preferred location, and choose available time slots. Filter venues by sport type, location, and price range. After selecting a slot, fill in your details and complete the payment to receive an instant confirmation email."
  },
  {
    question: "What payment methods do you accept and what's the cancellation policy?",
    answer: "We accept UPI, credit/debit cards, and net banking. For cancellations made 24 hours before booking, you'll receive a full refund. Cancellations within 24 hours get a 50% refund. No refunds for no-shows."
  },
  {
    question: "How can I connect with a sports coach?",
    answer: "Visit our Coaches section to browse verified coaches. Filter by sport, experience, and location. Each profile shows expertise, ratings, and availability. Book directly through their profile page after selecting your preferred time slot."
  },
  {
    question: "What's included in beSportly memberships?",
    answer: "Our memberships offer priority bookings, discounted rates, premium venue access, and exclusive event invites. Premium members get AI coaching features, personalized workout tracking, and nutrition guidance."
  },
  {
    question: "How does the AI Coaching feature work?",
    answer: "Access AI Coaching from the menu to get instant form feedback, training plans, and workout analysis. Upload videos/photos for technique review, or chat with our AI coach for immediate guidance and tips."
  },
  {
    question: "How do I join and interact in sports communities?",
    answer: "Visit our Communities section to join sport-specific groups. Chat with fellow enthusiasts, share experiences, and participate in discussions. You can also create private groups and use our WhatsApp communities feature."
  },
  {
    question: "Can I organize tournaments through beSportly?",
    answer: "Yes! Use our Competitions section to create tournaments. Our BracketGPT AI helps organize brackets, manage registrations, and track scores. We support both small friendly matches and large tournaments."
  },
  {
    question: "How do I track my fitness progress?",
    answer: "Use BeFit to track workouts, nutrition, and progress. Set goals, log activities, and monitor improvements. Our AI provides insights while the calorie tracker helps manage your diet. Connect with experts for guidance."
  },
  {
    question: "Are the venues and facilities verified?",
    answer: "Yes, all venues undergo physical inspection for quality and safety standards. We verify amenities, equipment condition, and facility maintenance. Regular audits ensure consistent standards."
  },
  {
    question: "What should I do if I face technical issues?",
    answer: "Try refreshing the page first. If issues persist, use our AI Support Assistant for instant help, contact us through this support form, or email social@besportly.com. Include relevant details like booking IDs for faster resolution."
  },
  {
    question: "How secure are my payments and personal information?",
    answer: "We use industry-standard encryption for all transactions and data storage. Your personal information is protected under our privacy policy, and we never share payment details with third parties."
  },
  {
    question: "Can I get professional training through beSportly?",
    answer: "Yes! Book certified coaches for one-on-one or group sessions. All coaches are verified professionals with documented expertise. You can also join training academies through our Academies section."
  }
];

const Support = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    
    try {
      const { error } = await supabase
        .from('support_inquiries')
        .insert({
          name: formData.get('name')?.toString() || '',
          email: formData.get('email')?.toString() || '',
          message: formData.get('message')?.toString() || ''
        });

      if (error) throw error;

      toast({
        title: "Message sent successfully",
        description: "We'll get back to you within 24 hours.",
      });

      (e.target as HTMLFormElement).reset();
    } catch (error) {
      toast({
        title: "Error sending message",
        description: "Please try again later.",
        variant: "destructive"
      });
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 md:pt-32">
        {isMobile && (
          <>
            <div className="absolute top-4 left-4 z-10">
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
              >
                <Button
                  onClick={() => navigate(-1)}
                  variant="ghost"
                  size="sm"
                  className="flex items-center justify-center p-2 rounded-full backdrop-blur-sm bg-white/80 hover:bg-white shadow-md shadow-indigo-100/20 border border-indigo-100/30 transition-all duration-300 hover:scale-105"
                  aria-label="Go back"
                >
                  <ArrowLeft className="h-4 w-4 text-indigo-600" />
                </Button>
              </motion.div>
            </div>
            
            <div className="mb-6 mt-5">
              <button
                onClick={() => setIsFeedbackOpen(true)}
                className="w-full py-3 px-4 bg-gradient-to-r from-purple-500 via-fuchsia-500 to-blue-500 text-white rounded-xl shadow-lg flex items-center justify-center gap-2 animate-pulse"
              >
                <div className="absolute -left-1 -top-1 w-14 h-14 rounded-full bg-white/20 blur-xl animate-ping opacity-75"></div>
                <Sparkles className="h-5 w-5 text-yellow-200" />
                <span className="font-medium">Report bug or request feature</span>
                <Megaphone className="h-5 w-5 text-yellow-200" />
              </button>
            </div>
          </>
        )}
      
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-bold text-center mb-12 dark:text-black">Support Center</h1>

          <div className="grid gap-8">
            {/* Contact Form */}
            <Card className="p-6">
              <h2 className="text-2xl font-semibold mb-6 dark:text-black">Contact Us</h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1 dark:text-black">Name</label>
                  <Input name="name" placeholder="Your name" required />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1 dark:text-black">Email</label>
                  <Input name="email" type="email" placeholder="your@email.com" required />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1 dark:text-black">Message</label>
                  <Textarea name="message" placeholder="How can we help you?" required className="min-h-[100px]" />
                </div>
                <Button type="submit" className="w-full">Send Message</Button>
              </form>
              <div className="mt-4 text-center text-sm text-gray-500 dark:text-gray-400">
                Or email us directly at: <a href="mailto:social@besportly.com" className="text-blue-600 hover:underline">social@besportly.com</a>
              </div>
            </Card>

            {/* FAQs */}
            <Card className="p-6">
              <h2 className="text-2xl font-semibold mb-6 dark:text-black">Frequently Asked Questions</h2>
              <Accordion type="single" collapsible className="w-full">
                {faqs.map((faq, index) => (
                  <AccordionItem key={index} value={`item-${index}`}>
                    <AccordionTrigger className="dark:text-black">{faq.question}</AccordionTrigger>
                    <AccordionContent className="dark:text-gray-600">{faq.answer}</AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </Card>

            {/* AI Support Link */}
            <Button
              onClick={() => navigate('/ai-support')}
              variant="ghost"
              className="mx-auto flex items-center gap-2 text-blue-600 hover:text-blue-700 hover:bg-blue-50"
            >
              <Bot className="w-4 h-4" />
              <span>Try our AI Support Assistant</span>
            </Button>

            {/* Documentation Card */}
            <Card className="p-6 relative md:p-6 p-4 md:min-h-[200px] min-h-[120px]">
              <span className="absolute top-4 right-4 text-xs font-medium text-blue-600 bg-blue-100 px-2 py-1 rounded-full">
                Coming Soon
              </span>
              <h2 className="text-2xl font-semibold mb-4 dark:text-black md:text-2xl text-xl">Documentation</h2>
              <p className="text-gray-600 dark:text-gray-300 md:text-base text-sm">
                Comprehensive guides and documentation to help you make the most of our platform.
              </p>
            </Card>
          </div>
        </div>
      </div>
      
      <FeedbackSheet open={isFeedbackOpen} onOpenChange={setIsFeedbackOpen} />
    </div>
  );
};

export default Support;
