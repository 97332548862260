import { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import { format } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Dumbbell, Pencil, Check, X, Trash2 } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface ExerciseLog {
  id: string;
  exercise_name: string;
  exercise_type: string;
  calories_burned: number;
  duration_minutes: number;
  log_date: string;
  notes: string | null;
}

export const ExerciseHistory = ({ onExerciseUpdated }: { onExerciseUpdated: () => void }) => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [exercises, setExercises] = useState<ExerciseLog[]>([]);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editForm, setEditForm] = useState({
    exercise_name: "",
    exercise_type: "",
    calories_burned: "",
    duration_minutes: "",
    notes: "",
  });

  useEffect(() => {
    if (!user) return;
    fetchExercises();
  }, [user]);

  const fetchExercises = async () => {
    const { data } = await supabase
      .from("exercise_logs")
      .select("*")
      .eq("user_id", user?.id)
      .order("log_date", { ascending: false });

    if (data) {
      setExercises(data);
    }
  };

  const startEditing = (exercise: ExerciseLog) => {
    setEditingId(exercise.id);
    setEditForm({
      exercise_name: exercise.exercise_name,
      exercise_type: exercise.exercise_type,
      calories_burned: exercise.calories_burned.toString(),
      duration_minutes: exercise.duration_minutes.toString(),
      notes: exercise.notes || "",
    });
  };

  const handleUpdate = async (id: string) => {
    try {
      const { error } = await supabase
        .from("exercise_logs")
        .update({
          exercise_name: editForm.exercise_name,
          exercise_type: editForm.exercise_type,
          calories_burned: parseInt(editForm.calories_burned),
          duration_minutes: parseInt(editForm.duration_minutes),
          notes: editForm.notes,
        })
        .eq("id", id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Exercise log updated successfully!",
      });

      setEditingId(null);
      fetchExercises();
      onExerciseUpdated();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update exercise log. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const { error } = await supabase
        .from("exercise_logs")
        .delete()
        .eq("id", id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Exercise log deleted successfully!",
      });

      fetchExercises();
      onExerciseUpdated();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete exercise log. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <Dumbbell className="w-6 h-6 text-purple-500" />
        <h3 className="text-lg font-semibold">Exercise History</h3>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Date</TableHead>
              <TableHead>Exercise</TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Duration</TableHead>
              <TableHead>Calories</TableHead>
              <TableHead>Notes</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {exercises.map((exercise) => (
              <TableRow key={exercise.id}>
                <TableCell>
                  {format(new Date(exercise.log_date), "MMM dd, yyyy HH:mm")}
                </TableCell>
                <TableCell>
                  {editingId === exercise.id ? (
                    <Input
                      value={editForm.exercise_name}
                      onChange={(e) =>
                        setEditForm({ ...editForm, exercise_name: e.target.value })
                      }
                    />
                  ) : (
                    exercise.exercise_name
                  )}
                </TableCell>
                <TableCell>
                  {editingId === exercise.id ? (
                    <Select
                      value={editForm.exercise_type}
                      onValueChange={(value) =>
                        setEditForm({ ...editForm, exercise_type: value })
                      }
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="cardio">Cardio</SelectItem>
                        <SelectItem value="strength">Strength Training</SelectItem>
                        <SelectItem value="flexibility">Flexibility</SelectItem>
                        <SelectItem value="sports">Sports</SelectItem>
                      </SelectContent>
                    </Select>
                  ) : (
                    <span className="capitalize">{exercise.exercise_type}</span>
                  )}
                </TableCell>
                <TableCell>
                  {editingId === exercise.id ? (
                    <Input
                      type="number"
                      value={editForm.duration_minutes}
                      onChange={(e) =>
                        setEditForm({ ...editForm, duration_minutes: e.target.value })
                      }
                      className="w-24"
                    />
                  ) : (
                    `${exercise.duration_minutes} min`
                  )}
                </TableCell>
                <TableCell>
                  {editingId === exercise.id ? (
                    <Input
                      type="number"
                      value={editForm.calories_burned}
                      onChange={(e) =>
                        setEditForm({ ...editForm, calories_burned: e.target.value })
                      }
                      className="w-24"
                    />
                  ) : (
                    exercise.calories_burned
                  )}
                </TableCell>
                <TableCell>
                  {editingId === exercise.id ? (
                    <Input
                      value={editForm.notes || ""}
                      onChange={(e) =>
                        setEditForm({ ...editForm, notes: e.target.value })
                      }
                    />
                  ) : (
                    exercise.notes || "-"
                  )}
                </TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    {editingId === exercise.id ? (
                      <>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => handleUpdate(exercise.id)}
                        >
                          <Check className="w-4 h-4 text-green-500" />
                        </Button>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => setEditingId(null)}
                        >
                          <X className="w-4 h-4 text-red-500" />
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => startEditing(exercise)}
                        >
                          <Pencil className="w-4 h-4 text-gray-500" />
                        </Button>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => handleDelete(exercise.id)}
                          className="hover:bg-red-50"
                        >
                          <Trash2 className="w-4 h-4 text-red-500" />
                        </Button>
                      </>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};