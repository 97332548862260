
import { useEffect } from 'react';

export const useAutoRefresh = (itemCount: number, noResultsShowing: boolean = false) => {
  useEffect(() => {
    let timeoutId: number | undefined;
    
    if (itemCount === 0 && !noResultsShowing) {
      timeoutId = window.setTimeout(() => {
        console.log('No items loaded and no "coming soon" message showing after 7 seconds, refreshing page');
        window.location.reload();
      }, 7000);
    }
    
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [itemCount, noResultsShowing]);
};
