
import { ArrowRight, MapPin } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const FeaturedAcademies = () => {
  const navigate = useNavigate();
  
  const academies = [
    {
      id: 1,
      name: "Champions Cricket Academy",
      address: "Sector 42",
      city: "Gurgaon",
      sports: ["Cricket"],
      monthly_fee: 3000,
      rating: 4.8,
      programs: 5,
      image: "/lovable-uploads/13e4d899-ad99-4346-9fe0-8e3cd49e0f65.png",
      category: "External"
    },
    {
      id: 2,
      name: "Apex Tennis Academy",
      address: "Sector 43",
      city: "Gurgaon",
      sports: ["Tennis"],
      monthly_fee: 3500,
      rating: 4.9,
      programs: 3,
      image: "/lovable-uploads/d2b23e05-0d5c-452a-89cd-dc65a1f14cf9.png",
      category: "External"
    },
    {
      id: 3,
      name: "Roots Football School",
      address: "Sector 41",
      city: "Gurgaon",
      sports: ["Football", "Basketball"],
      monthly_fee: 3500,
      rating: 4.7,
      programs: 8,
      image: "/lovable-uploads/c29275f6-5625-4193-8f35-06298da7fddc.png",
      category: "External"
    }
  ];

  const handleNavigate = () => {
    navigate('/academies');
  };

  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold bg-gradient-to-r from-emerald-500 via-teal-500 to-green-500 bg-clip-text text-transparent animate-gradient px-1 py-1 rounded-lg relative">
            Featured Academies
            <div className="h-1 w-full rounded-full bg-gradient-to-r from-emerald-400 via-teal-400 to-green-400 opacity-70 absolute bottom-0 left-0"></div>
          </h2>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleNavigate}
            className="flex items-center space-x-2 px-4 py-2 rounded-full bg-gradient-to-r from-emerald-500 to-teal-500 text-white shadow-lg hover:shadow-xl transition-all duration-300 group cursor-pointer"
          >
            <span className="font-medium">View more</span>
            <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
          </motion.button>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {academies.map((academy) => (
            <div
              key={academy.id}
              className="bg-white rounded-xl overflow-hidden border border-gray-100 hover:shadow-lg transition-all duration-300 animate-fadeIn"
            >
              <div className="relative h-48 overflow-hidden">
                <img
                  src={academy.image}
                  alt={academy.name}
                  className="w-full h-full object-cover"
                />
                <div className="absolute top-4 left-4">
                  <span className="px-3 py-1 bg-primary text-white backdrop-blur-sm rounded-full text-sm font-medium">
                    {academy.category}
                  </span>
                </div>
              </div>

              <div className="p-6 space-y-4">
                <h3 className="text-xl font-semibold text-gray-900">{academy.name}</h3>
                
                <div className="flex items-center text-gray-600">
                  <MapPin size={16} className="mr-2" />
                  <span>{academy.address}, {academy.city}</span>
                </div>

                <div className="flex flex-wrap gap-2">
                  {academy.sports.map((sport, index) => (
                    <span key={index} className="text-xs bg-gray-100 dark:bg-gray-900 px-2 py-1 rounded-full dark:text-white">
                      {sport}
                    </span>
                  ))}
                </div>

                <div className="flex justify-between items-center">
                  <div className="flex items-center text-gray-600">
                    <span>{academy.programs} programs</span>
                  </div>
                </div>

                <div className="flex justify-between items-center pt-4 border-t border-gray-100">
                  <div className="flex items-center">
                    <span className="text-yellow-400">★</span>
                    <span className="ml-1 text-gray-600">{academy.rating}</span>
                  </div>
                  <span className="text-primary font-semibold">Available on inquiry</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturedAcademies;
