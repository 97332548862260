
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { BadmintonIcon, TennisIcon, PickleballIcon } from "@/components/icons/SportIcons";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

interface SportInfo {
  name: string;
  icon: React.ReactNode;
  gradient: string;
  textColor: string;
}

interface SportSelectorProps {
  availableSports: string[];
  selectedSport: string | null;
  onSelectSport: (sport: string) => void;
}

export const SportSelector = ({ 
  availableSports, 
  selectedSport, 
  onSelectSport 
}: SportSelectorProps) => {
  // Map of sport names and their UI details
  const sportDetails: Record<string, SportInfo> = {
    "Badminton": {
      name: "Badminton",
      icon: <BadmintonIcon className="w-8 h-8" />,
      gradient: "from-amber-400 to-amber-600", 
      textColor: "text-amber-900"
    },
    "Tennis": {
      name: "Tennis",
      icon: <TennisIcon className="w-8 h-8" />,
      gradient: "from-green-400 to-green-600",
      textColor: "text-green-900"
    },
    "Pickleball": {
      name: "Pickleball",
      icon: <PickleballIcon className="w-8 h-8" />,
      gradient: "from-blue-400 to-blue-600",
      textColor: "text-blue-900"
    }
  };

  // Filter to only include sports that are available at this venue
  const sportOptions = availableSports
    .filter(sport => sportDetails[sport])
    .map(sport => sportDetails[sport]);

  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-3 text-gray-800 dark:text-gray-200">Select a Sport</h3>
      <div className="flex flex-wrap gap-3 justify-center sm:justify-start">
        {sportOptions.map((sport) => (
          <motion.div
            key={sport.name}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Button
              onClick={() => onSelectSport(sport.name)}
              className={cn(
                "h-auto py-3 px-6 rounded-xl shadow-lg transition-all duration-300",
                "flex flex-col items-center gap-2",
                `bg-gradient-to-br ${sport.gradient}`,
                selectedSport === sport.name 
                  ? "ring-4 ring-white ring-opacity-50 shadow-xl scale-105" 
                  : "opacity-90 hover:opacity-100"
              )}
            >
              <div className="bg-white bg-opacity-30 rounded-full p-3">
                {sport.icon}
              </div>
              <span className={cn("font-bold", sport.textColor)}>
                {sport.name}
              </span>
            </Button>
          </motion.div>
        ))}
      </div>
    </div>
  );
};
