import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/components/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Share2, Trophy, BrainCircuit, Award, Brain, Sparkles, LightbulbIcon } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { motion } from "framer-motion";
import { useIsMobile } from "@/hooks/use-mobile";

const topics = [
  "English Premier League History",
  "UEFA Champions League Records",
  "Indian Premier League (IPL) Cricket",
  "NBA Championship Classics",
  "FIFA World Cup Legends",
  "Formula 1 Grand Prix History",
  "NFL Super Bowl Moments",
  "MLB World Series Highlights",
  "Rugby World Cup Chronicles",
  "ATP Tennis Grand Slams"
];

const Quizzes = () => {
  const [selectedTopic, setSelectedTopic] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [score, setScore] = useState(0);
  const [showCorrect, setShowCorrect] = useState(false);
  const [showScoreDialog, setShowScoreDialog] = useState(false);
  const { toast } = useToast();
  const { user } = useAuth();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        navigate("/auth", { replace: true });
      }
    };
    
    checkAuth();
  }, [navigate]);

  const optionIcons = [
    <Trophy key="icon1" className="text-amber-500 w-5 h-5" />,
    <BrainCircuit key="icon2" className="text-indigo-500 w-5 h-5" />,
    <Award key="icon3" className="text-emerald-500 w-5 h-5" />,
    <Brain key="icon4" className="text-purple-500 w-5 h-5" />
  ];

  const { data: quiz, isLoading: isLoadingQuiz } = useQuery({
    queryKey: ['quiz', selectedTopic],
    queryFn: async () => {
      if (!selectedTopic || !user) return null;
      
      const response = await supabase.functions.invoke('generate-quiz', {
        body: { topic: selectedTopic }
      });
      
      if (response.error) {
        console.error('Quiz generation error:', response.error);
        throw response.error;
      }
      
      const { data: quizData, error: saveError } = await supabase
        .from('quizzes')
        .insert({
          title: `Quiz on ${selectedTopic}`,
          category: 'sports',
          questions: response.data.questions,
          created_by: user.id
        })
        .select()
        .single();
      
      if (saveError) {
        console.error('Quiz save error:', saveError);
        throw saveError;
      }
      
      return response.data.questions;
    },
    enabled: !!selectedTopic && !!user
  });

  const submitAnswer = useMutation({
    mutationFn: async () => {
      if (!quiz || !user) return;
      
      const currentQuestion = quiz[currentQuestionIndex];
      const isCorrect = selectedAnswer === currentQuestion.correctAnswer;
      
      if (isCorrect) {
        setScore(prev => prev + 1);
        setShowCorrect(true);
        setTimeout(() => setShowCorrect(false), 1000);
      }
      
      const { error } = await supabase
        .from('quiz_attempts')
        .insert({
          quiz_id: quiz.id,
          user_id: user.id,
          answers: { 
            questionIndex: currentQuestionIndex,
            selectedAnswer,
            isCorrect 
          },
          score: isCorrect ? 1 : 0
        });

      if (error) {
        console.error('Error saving attempt:', error);
        throw error;
      }
      
      if (currentQuestionIndex < quiz.length - 1) {
        setCurrentQuestionIndex(prev => prev + 1);
        setSelectedAnswer("");
      } else {
        setShowScoreDialog(true);
      }
    }
  });

  const resetQuiz = () => {
    setShowScoreDialog(false);
    setSelectedTopic("");
    setCurrentQuestionIndex(0);
    setScore(0);
  };

  const handleShare = () => {
    const shareUrl = `${window.location.origin}/quizzes?topic=${encodeURIComponent(selectedTopic)}`;
    navigator.clipboard.writeText(shareUrl);
    toast({
      title: "Share link copied!",
      description: "Send this link to invite others to take the same quiz.",
    });
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-blue-50 to-white">
      <main className="flex-grow pt-20 pb-8">
        <div className="max-w-3xl mx-auto px-4">
          <motion.div 
            className="text-center mb-10"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="inline-block mb-4">
              <div className="relative">
                <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 via-blue-500 to-indigo-400 rounded-full blur-md opacity-75 animate-pulse"></div>
                <div className="relative bg-white dark:bg-black p-2 rounded-full">
                  <LightbulbIcon className="h-12 w-12 text-yellow-500" />
                </div>
              </div>
            </div>
            <h1 className="text-4xl font-bold bg-gradient-to-r from-blue-600 via-purple-500 to-indigo-500 text-transparent bg-clip-text mb-4">Test Your Sports Knowledge</h1>
            <p className="text-lg text-gray-600 max-w-xl mx-auto">
              Challenge yourself with interactive quizzes and see how well you know your favorite sports!
            </p>
          </motion.div>

          {!selectedTopic ? (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Card className="shadow-xl hover:shadow-2xl transition-all duration-300 overflow-hidden border-0">
                <div className="h-2 bg-gradient-to-r from-blue-500 via-purple-500 to-indigo-500"></div>
                <CardHeader className="bg-gradient-to-br from-blue-50 to-indigo-50">
                  <CardTitle className="text-center text-2xl font-bold text-blue-800">Choose Your Challenge</CardTitle>
                  <CardDescription className="text-center text-gray-600">Select a sports topic to start your quiz journey</CardDescription>
                </CardHeader>
                <CardContent className="p-8 space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    {topics.slice(0, 4).map((topic, index) => (
                      <motion.div
                        key={topic}
                        whileHover={{ scale: 1.03 }}
                        whileTap={{ scale: 0.98 }}
                        className="cursor-pointer"
                        onClick={() => setSelectedTopic(topic)}
                      >
                        <div className={`p-4 rounded-xl flex items-center gap-3 transition-all ${
                          index === 0 ? "bg-amber-50 hover:bg-amber-100" :
                          index === 1 ? "bg-indigo-50 hover:bg-indigo-100" :
                          index === 2 ? "bg-emerald-50 hover:bg-emerald-100" :
                          "bg-purple-50 hover:bg-purple-100"
                        }`}>
                          <div className={`p-2 rounded-full ${
                            index === 0 ? "bg-amber-100" :
                            index === 1 ? "bg-indigo-100" :
                            index === 2 ? "bg-emerald-100" :
                            "bg-purple-100"
                          }`}>
                            {optionIcons[index % 4]}
                          </div>
                          <span className="font-medium text-gray-800">{topic}</span>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                  
                  <Select value={selectedTopic} onValueChange={setSelectedTopic}>
                    <SelectTrigger className="w-full bg-gradient-to-r from-blue-50 to-indigo-50 border-blue-200 hover:border-blue-300 transition-all h-12">
                      <SelectValue placeholder="Or select from all topics..." />
                    </SelectTrigger>
                    <SelectContent position="popper" className="bg-white border-blue-200 max-h-80">
                      {topics.map((topic) => (
                        <SelectItem key={topic} value={topic} className="hover:bg-blue-50 cursor-pointer py-2.5">
                          {topic}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </CardContent>
                <CardFooter className="bg-gradient-to-br from-blue-50 to-indigo-50 p-6 flex justify-center">
                  <p className="text-sm text-center text-gray-600 max-w-md">
                    <Sparkles className="inline-block w-4 h-4 mr-1 text-blue-500" />
                    Answer questions correctly to earn points and compete with others!
                  </p>
                </CardFooter>
              </Card>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0, scale: 0.98 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.4 }}
            >
              <Card className="shadow-xl relative overflow-hidden border-0">
                <div className="h-2 bg-gradient-to-r from-blue-500 via-purple-500 to-indigo-500"></div>
                <CardHeader className="bg-gradient-to-br from-blue-50 to-indigo-50 pb-4">
                  <div className="flex justify-between items-center">
                    <div>
                      <CardTitle className="text-xl text-blue-800">{selectedTopic}</CardTitle>
                      <CardDescription className="flex items-center gap-2 mt-1">
                        <span className="bg-blue-100 text-blue-800 px-2 py-0.5 rounded-full text-xs font-medium">
                          Question {currentQuestionIndex + 1} of {quiz?.length || 0}
                        </span>
                        <span className="bg-emerald-100 text-emerald-800 px-2 py-0.5 rounded-full text-xs font-medium">
                          Score: {score}
                        </span>
                      </CardDescription>
                    </div>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={handleShare}
                      className="flex items-center gap-2 bg-white hover:bg-blue-50"
                    >
                      <Share2 className="w-4 h-4" />
                      Invite
                    </Button>
                  </div>
                </CardHeader>
                <CardContent className="p-6">
                  {isLoadingQuiz ? (
                    <div className="text-center py-12">
                      <div className="relative mx-auto w-16 h-16 mb-6">
                        <div className="absolute inset-0 rounded-full border-t-2 border-l-2 border-r-2 border-b-2 border-blue-200 animate-spin"></div>
                        <div className="absolute inset-2 rounded-full border-t-2 border-l-2 border-blue-500 animate-spin" style={{ animationDirection: 'reverse', animationDuration: '1s' }}></div>
                        <div className="absolute inset-4 rounded-full border-t-2 border-r-2 border-purple-500 animate-spin" style={{ animationDuration: '1.5s' }}></div>
                      </div>
                      <p className="text-gray-600 font-medium">Crafting your quiz questions...</p>
                      <p className="text-sm text-gray-500 mt-2">This might take a moment as we prepare a challenging set of questions for you!</p>
                    </div>
                  ) : quiz ? (
                    <div className="space-y-6">
                      <div className="bg-white p-5 rounded-xl shadow-sm border border-blue-100">
                        <p className="text-lg font-medium text-gray-900 leading-relaxed">
                          {quiz[currentQuestionIndex].question}
                        </p>
                      </div>
                      <RadioGroup value={selectedAnswer} onValueChange={setSelectedAnswer} className="space-y-3">
                        {quiz[currentQuestionIndex].options.map((option, index) => (
                          <motion.div 
                            key={index}
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                          >
                            <div className="flex items-center space-x-3 p-4 rounded-lg hover:bg-blue-50 transition-colors border border-gray-100 hover:border-blue-200">
                              <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 shrink-0">
                                {optionIcons[index % 4]}
                              </div>
                              <div className="flex-grow">
                                <RadioGroupItem value={option} id={`option-${index}`} className="peer sr-only" />
                                <Label htmlFor={`option-${index}`} className="flex-grow cursor-pointer text-gray-700 font-medium peer-data-[state=checked]:text-blue-700">
                                  {option}
                                </Label>
                              </div>
                            </div>
                          </motion.div>
                        ))}
                      </RadioGroup>
                      <Button 
                        onClick={() => submitAnswer.mutate()}
                        disabled={!selectedAnswer || submitAnswer.isPending}
                        className="w-full py-6 text-lg bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 text-white shadow-md hover:shadow-lg transition-all duration-300"
                      >
                        {submitAnswer.isPending ? (
                          <span className="flex items-center justify-center">
                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Submitting...
                          </span>
                        ) : "Submit Answer"}
                      </Button>
                    </div>
                  ) : null}
                  {showCorrect && (
                    <div className="absolute inset-0 flex items-center justify-center bg-green-500/20 backdrop-blur-sm animate-fade-in z-50">
                      <motion.div 
                        initial={{ scale: 0.5, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.4, type: "spring" }}
                        className="bg-white p-6 rounded-2xl shadow-lg flex flex-col items-center"
                      >
                        <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
                          <Sparkles className="w-8 h-8 text-green-600" />
                        </div>
                        <div className="text-3xl font-bold text-green-600 animate-pulse">
                          Correct! 🎉
                        </div>
                      </motion.div>
                    </div>
                  )}
                </CardContent>
              </Card>
            </motion.div>
          )}
        </div>
      </main>
      <Dialog open={showScoreDialog} onOpenChange={setShowScoreDialog}>
        <DialogContent className="sm:max-w-md rounded-xl bg-gradient-to-br from-blue-50 to-indigo-50 border-0 shadow-xl">
          <div className="absolute -z-10 inset-0 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-xl blur-xl"></div>
          <DialogHeader>
            <DialogTitle className="text-center text-2xl font-bold bg-gradient-to-r from-blue-600 via-purple-500 to-indigo-600 bg-clip-text text-transparent flex items-center justify-center gap-2">
              <Trophy className="h-6 w-6 text-yellow-500" />
              Quiz Complete! 🎉
            </DialogTitle>
            <DialogDescription className="text-center text-lg mt-2">
              You scored <span className="font-bold text-blue-700">{score}</span> out of <span className="font-bold text-blue-700">{quiz?.length || 0}</span> questions!
            </DialogDescription>
          </DialogHeader>
          <div className="flex flex-col items-center justify-center gap-4 mt-4">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-400 rounded-full blur-md animate-pulse"></div>
              <div className="relative bg-white rounded-full p-4">
                <Award className="h-12 w-12 text-yellow-500" />
              </div>
            </div>
            <p className="text-gray-600 text-center">
              {score === (quiz?.length || 0) ? "Perfect score! You're a sports expert!" :
              score >= (quiz?.length || 0) * 0.8 ? "Excellent knowledge! Almost perfect!" :
              score >= (quiz?.length || 0) * 0.6 ? "Good job! You know your sports well!" :
              score >= (quiz?.length || 0) * 0.4 ? "Not bad! Keep learning and try again!" :
              "Keep practicing! You'll get better with each quiz!"}
            </p>
            <Button onClick={resetQuiz} className="bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 text-white shadow-lg hover:shadow-xl transition-all duration-300 px-8 py-6 h-auto text-lg">
              Try Another Quiz
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Quizzes;
