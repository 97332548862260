
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MapPin, Calendar, ChevronRight, MapPin as VenueIcon, User as CoachIcon, Dumbbell as GymIcon, GraduationCap as AcademyIcon, Waves } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { popularCities } from "@/utils/cityData";
import { useIsMobile } from "@/hooks/use-mobile";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { Card, CardContent } from "@/components/ui/card";
import MagicButton from "./MagicButton";
import { useAuth } from "@/components/AuthProvider";
import { BadmintonIcon, CricketIcon, PickleballIcon, SquashIcon, BasketballIcon } from "@/components/icons/SportIcons";

// Custom icon components for the new uploaded images
const FootballIcon: React.FC<{ className?: string }> = ({ className }) => (
  <img 
    src="/lovable-uploads/1b2d9255-3750-4a9d-ae69-9214a7184373.png" 
    alt="Football" 
    className={className} 
  />
);

const TennisIcon: React.FC<{ className?: string }> = ({ className }) => (
  <img 
    src="/lovable-uploads/7492ee19-44a3-4ad8-a0f2-2342c4614cf6.png" 
    alt="Tennis" 
    className={className} 
  />
);

const SportCircleButtons = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSport, setSelectedSport] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [bookNowDialog, setBookNowDialog] = useState(false);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { user } = useAuth();

  if (!isMobile) return null;

  const handleSportClick = (sport: string) => {
    setSelectedSport(sport);
    setOpenDialog(true);
  };

  const handleCityConfirm = () => {
    if (selectedCity) {
      navigate(`/venues?city=${selectedCity}&sport=${selectedSport}`);
      setOpenDialog(false);
    }
  };

  const handleBookNowClick = () => {
    setBookNowDialog(true);
  };

  const handleCategorySelect = (route: string) => {
    setBookNowDialog(false);
    navigate(`/${route}`);
  };

  const sportButtons = [
    {
      name: "Badminton",
      icon: BadmintonIcon,
      color: "from-pink-500 to-purple-600",
      shadowColor: "shadow-pink-500/30",
      bgGlow: "bg-pink-400",
      gradient: "linear-gradient(225deg, #FFE29F 0%, #FFA99F 48%, #FF719A 100%)",
    },
    {
      name: "Cricket",
      icon: CricketIcon,
      color: "from-blue-500 to-cyan-400",
      shadowColor: "shadow-blue-500/30",
      bgGlow: "bg-blue-400",
      gradient: "linear-gradient(90deg, hsla(186, 33%, 94%, 1) 0%, hsla(216, 41%, 79%, 1) 100%)",
    },
    {
      name: "Pickleball",
      icon: PickleballIcon,
      color: "from-green-500 to-emerald-500",
      shadowColor: "shadow-green-500/30",
      bgGlow: "bg-green-400",
      gradient: "linear-gradient(90deg, hsla(139, 70%, 75%, 1) 0%, hsla(63, 90%, 76%, 1) 100%)",
    },
    {
      name: "Football",
      icon: FootballIcon,
      color: "from-amber-500 to-orange-500",
      shadowColor: "shadow-amber-500/30",
      bgGlow: "bg-amber-400",
      gradient: "linear-gradient(90deg, hsla(29, 92%, 70%, 1) 0%, hsla(0, 87%, 73%, 1) 100%)",
      background: "bg-gradient-to-r from-yellow-400 to-yellow-600",
    },
  ];

  interface SportButton {
    name: string;
    icon: React.FC<{ className?: string }>;
    color: string;
    shadowColor: string;
    bgGlow: string;
    gradient: string;
    background?: string;
  }

  const additionalSportButtons: SportButton[] = [
    {
      name: "Tennis",
      icon: TennisIcon,
      color: "from-emerald-500 to-teal-600",
      shadowColor: "shadow-emerald-500/30",
      bgGlow: "bg-emerald-400",
      gradient: "linear-gradient(90deg, hsla(151, 50%, 70%, 1) 0%, hsla(161, 60%, 60%, 1) 100%)",
      background: "bg-white", // White background for tennis
    },
    {
      name: "Squash",
      icon: SquashIcon,
      color: "from-purple-500 to-indigo-600",
      shadowColor: "shadow-purple-500/30",
      bgGlow: "bg-purple-400",
      gradient: "linear-gradient(90deg, hsla(273, 75%, 75%, 1) 0%, hsla(263, 70%, 70%, 1) 100%)",
      background: "bg-purple-600", // Purple background for squash
    },
    {
      name: "Basketball",
      icon: BasketballIcon,
      color: "from-red-500 to-rose-600",
      shadowColor: "shadow-red-500/30",
      bgGlow: "bg-red-400",
      gradient: "linear-gradient(90deg, hsla(0, 80%, 70%, 1) 0%, hsla(350, 75%, 65%, 1) 100%)",
      background: "bg-black", // Black background for basketball
    },
    {
      name: "Swimming",
      icon: ({ className }) => <Waves className={className} />,
      color: "from-cyan-500 to-blue-600",
      shadowColor: "shadow-cyan-500/30",
      bgGlow: "bg-cyan-400",
      gradient: "linear-gradient(90deg, hsla(190, 90%, 70%, 1) 0%, hsla(210, 80%, 60%, 1) 100%)",
    },
  ];

  const categoryCards = [
    {
      title: "Venues",
      route: "venues",
      icon: VenueIcon,
      gradient: "from-violet-500 to-purple-500",
      description: "Find the perfect location to play your favorite sport",
      bgPattern: "bg-[radial-gradient(circle_at_top_right,rgba(255,255,255,0.15),transparent)]",
    },
    {
      title: "Coaches",
      route: "coaches",
      icon: CoachIcon,
      gradient: "from-blue-500 to-cyan-400",
      description: "Connect with expert coaches to improve your skills",
      bgPattern: "bg-[radial-gradient(circle_at_bottom_left,rgba(255,255,255,0.15),transparent)]",
    },
    {
      title: "Gyms",
      route: "gyms",
      icon: GymIcon,
      gradient: "from-amber-500 to-orange-400",
      description: "Discover state-of-the-art fitness facilities",
      bgPattern: "bg-[radial-gradient(circle_at_top_left,rgba(255,255,255,0.15),transparent)]",
    },
    {
      title: "Academies",
      route: "academies",
      icon: AcademyIcon,
      gradient: "from-emerald-500 to-green-400",
      description: "Join specialized training programs and academies",
      bgPattern: "bg-[radial-gradient(circle_at_bottom_right,rgba(255,255,255,0.15),transparent)]",
    },
  ];

  return (
    <>
      <div className="relative w-full flex justify-center mb-4 mt-6">
        <div className="absolute inset-x-auto w-28 h-full bg-gradient-to-r from-purple-500/30 to-pink-500/30 rounded-lg blur-lg"></div>
        <div className="flex items-center gap-2">
          <div 
            onClick={handleBookNowClick}
            className="relative flex items-center justify-center px-3 py-2.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg shadow-lg overflow-hidden w-28 active:scale-95 transition-transform cursor-pointer"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-pink-500/10"></div>
            <span className="text-white font-semibold tracking-wide z-10 text-sm whitespace-nowrap">Book Now</span>
          </div>
          
          <MagicButton compact={true} />
        </div>
      </div>

      <div className="grid grid-cols-4 gap-3 mx-2 mb-3">
        {sportButtons.map((sport) => (
          <motion.button
            key={sport.name}
            onClick={() => handleSportClick(sport.name)}
            whileTap={{ scale: 0.95 }}
            whileHover={{ y: -5 }}
            className={`relative flex flex-col items-center justify-center gap-2`}
          >
            <div className="relative">
              <div className={`absolute ${sport.bgGlow} opacity-70 blur-xl w-16 h-16 rounded-full -z-10`} />
              <div 
                className={`relative flex items-center justify-center w-16 h-16 rounded-full ${sport.background || `bg-gradient-to-br ${sport.color}`} ${sport.shadowColor} p-0.5 shadow-lg`}
              >
                <div className="absolute inset-0.5 bg-white/10 backdrop-blur-sm rounded-full" />
                <div className="absolute inset-0 bg-gradient-to-br from-white/20 to-transparent rounded-full opacity-70" />
                <sport.icon className="relative w-9 h-9 text-white z-10" />
                <div className="absolute inset-0 rounded-full border border-white/30 animate-pulse" />
              </div>
            </div>
            <span className="text-[10px] font-medium text-gray-800 mt-1 bg-clip-text text-transparent bg-gradient-to-b from-gray-800 to-gray-600">
              {sport.name === "Football" ? "Play Football" : `Play ${sport.name}`}
            </span>
          </motion.button>
        ))}
      </div>

      <div className="grid grid-cols-4 gap-3 mx-2 mb-4">
        {additionalSportButtons.map((sport) => (
          <motion.button
            key={sport.name}
            onClick={() => handleSportClick(sport.name)}
            whileTap={{ scale: 0.95 }}
            whileHover={{ y: -5 }}
            className={`relative flex flex-col items-center justify-center gap-2`}
          >
            <div className="relative">
              <div className={`absolute ${sport.bgGlow} opacity-70 blur-xl w-16 h-16 rounded-full -z-10`} />
              <div 
                className={`relative flex items-center justify-center w-16 h-16 rounded-full ${sport.background || `bg-gradient-to-br ${sport.color}`} ${sport.shadowColor} p-0.5 shadow-lg`}
              >
                <div className="absolute inset-0.5 bg-white/10 backdrop-blur-sm rounded-full" />
                <div className="absolute inset-0 bg-gradient-to-br from-white/20 to-transparent rounded-full opacity-70" />
                <sport.icon className="relative w-9 h-9 text-white z-10" />
                <div className="absolute inset-0 rounded-full border border-white/30 animate-pulse" />
              </div>
            </div>
            <span className="text-[10px] font-medium text-gray-800 mt-1 bg-clip-text text-transparent bg-gradient-to-b from-gray-800 to-gray-600">
              {sport.name === "Swimming" ? "Swimming" : `Play ${sport.name}`}
            </span>
          </motion.button>
        ))}
      </div>

      <Dialog open={bookNowDialog} onOpenChange={setBookNowDialog}>
        <DialogContent className="sm:max-w-md mx-auto rounded-3xl border-0 overflow-hidden p-0">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="relative"
          >
            <div className="absolute inset-0 -z-10 overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-br from-gray-50 to-white opacity-90" />
              <div className="absolute top-0 left-0 right-0 h-32 bg-gradient-to-b from-[#9b87f5]/30 to-transparent" />
              <motion.div
                initial={{ opacity: 0.5, scale: 0.8 }}
                animate={{ 
                  opacity: [0.4, 0.6, 0.4], 
                  scale: [0.8, 1.1, 0.8],
                  x: ["-10%", "5%", "-10%"],
                  y: ["-5%", "10%", "-5%"]
                }}
                transition={{ 
                  repeat: Infinity, 
                  duration: 8,
                  ease: "easeInOut"
                }}
                className="absolute -top-20 -left-20 w-60 h-60 bg-[#D946EF]/20 rounded-full blur-3xl"
              />
              <motion.div
                initial={{ opacity: 0.5, scale: 0.8 }}
                animate={{ 
                  opacity: [0.5, 0.7, 0.5], 
                  scale: [0.8, 1.2, 0.8],
                  x: ["5%", "-10%", "5%"],
                  y: ["10%", "-5%", "10%"]
                }}
                transition={{ 
                  repeat: Infinity, 
                  duration: 10,
                  ease: "easeInOut"
                }}
                className="absolute -bottom-40 -right-20 w-80 h-80 bg-[#9b87f5]/30 rounded-full blur-3xl"
              />
              <motion.div 
                className="absolute top-1/4 left-1/4 w-1 h-1 bg-white rounded-full"
                animate={{ 
                  opacity: [0, 1, 0],
                  scale: [0.5, 1.5, 0.5]
                }}
                transition={{ 
                  repeat: Infinity, 
                  duration: 2,
                  repeatType: "reverse"
                }}
              />
              <motion.div 
                className="absolute bottom-1/3 right-1/4 w-1 h-1 bg-white rounded-full"
                animate={{ 
                  opacity: [0, 1, 0],
                  scale: [0.5, 1.2, 0.5]
                }}
                transition={{ 
                  repeat: Infinity, 
                  duration: 1.5,
                  repeatType: "reverse",
                  delay: 0.5
                }}
              />
            </div>
            
            <div className="px-6 pt-8 pb-8 relative">
              <div className="w-12 h-1.5 bg-gradient-to-r from-purple-500 via-pink-500 to-purple-500 rounded-full mx-auto mb-7" />
              
              <DialogHeader className="mb-7">
                <motion.div 
                  initial={{ y: -10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="relative"
                >
                  <DialogTitle className="text-center text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
                    Discover & Play
                  </DialogTitle>
                </motion.div>
                <DialogDescription className="text-center text-gray-600 mt-3">
                  <motion.div
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.1, duration: 0.3 }}
                  >
                    What would you like to explore today?
                  </motion.div>
                </DialogDescription>
              </DialogHeader>
              
              <div className="grid grid-cols-2 gap-4">
                {categoryCards.map((category, index) => (
                  <motion.div
                    key={category.title}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 + index * 0.1, duration: 0.4 }}
                    whileHover={{ 
                      scale: 1.03,
                      boxShadow: "0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)"
                    }}
                    whileTap={{ scale: 0.97 }}
                    onClick={() => handleCategorySelect(category.route)}
                    className="relative rounded-2xl overflow-hidden cursor-pointer"
                  >
                    <div className={`absolute inset-0 bg-gradient-to-br ${category.gradient} opacity-90`} />
                    <div className={`absolute inset-0 ${category.bgPattern} opacity-20`} />
                    
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: [0.2, 0.5, 0.2] }}
                      transition={{ 
                        repeat: Infinity, 
                        duration: 3 + index,
                        repeatType: "mirror"
                      }}
                      className="absolute inset-0 bg-gradient-to-tr from-white/10 to-transparent"
                    />
                    
                    <div className="relative p-4 flex flex-col items-center text-white h-full">
                      <div className="p-2 rounded-full bg-white/20 backdrop-blur-sm mb-3">
                        <category.icon size={22} className="text-white" />
                      </div>
                      <h3 className="font-bold text-lg mb-1">{category.title}</h3>
                      <p className="text-[10px] text-white/80 text-center leading-tight">
                        {category.description}
                      </p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        </DialogContent>
      </Dialog>

      <AnimatePresence>
        {openDialog && (
          <Dialog open={openDialog} onOpenChange={setOpenDialog}>
            <DialogContent className="sm:max-w-md mx-auto rounded-3xl border-0 overflow-hidden p-0">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                className="relative"
              >
                <div className="absolute inset-0 -z-10 overflow-hidden">
                  <div className="absolute inset-0 bg-gradient-to-br from-gray-50 to-white opacity-90" />
                  <div className="absolute top-0 left-0 right-0 h-32 bg-gradient-to-b from-[#9b87f5]/30 to-transparent" />
                  <motion.div
                    initial={{ opacity: 0.5, scale: 0.8 }}
                    animate={{ 
                      opacity: [0.4, 0.6, 0.4], 
                      scale: [0.8, 1.1, 0.8],
                      x: ["-10%", "5%", "-10%"],
                      y: ["-5%", "10%", "-5%"]
                    }}
                    transition={{ 
                      repeat: Infinity, 
                      duration: 8,
                      ease: "easeInOut"
                    }}
                    className="absolute -top-20 -left-20 w-60 h-60 bg-[#D946EF]/20 rounded-full blur-3xl"
                  />
                  <motion.div
                    initial={{ opacity: 0.5, scale: 0.8 }}
                    animate={{ 
                      opacity: [0.5, 0.7, 0.5], 
                      scale: [0.8, 1.2, 0.8],
                      x: ["5%", "-10%", "5%"],
                      y: ["10%", "-5%", "10%"]
                    }}
                    transition={{ 
                      repeat: Infinity, 
                      duration: 10,
                      ease: "easeInOut"
                    }}
                    className="absolute -bottom-40 -right-20 w-80 h-80 bg-[#9b87f5]/30 rounded-full blur-3xl"
                  />
                  <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 w-2/3 h-px bg-gradient-to-r from-transparent via-[#9b87f5]/50 to-transparent" />
                  
                  <motion.div 
                    className="absolute top-1/4 left-1/4 w-1 h-1 bg-white rounded-full"
                    animate={{ 
                      opacity: [0, 1, 0],
                      scale: [0.5, 1.5, 0.5]
                    }}
                    transition={{ 
                      repeat: Infinity, 
                      duration: 2,
                      repeatType: "reverse"
                    }}
                  />
                  <motion.div 
                    className="absolute bottom-1/3 right-1/4 w-1 h-1 bg-white rounded-full"
                    animate={{ 
                      opacity: [0, 1, 0],
                      scale: [0.5, 1.2, 0.5]
                    }}
                    transition={{ 
                      repeat: Infinity, 
                      duration: 1.5,
                      repeatType: "reverse",
                      delay: 0.5
                    }}
                  />
                </div>
                
                <div className="px-6 pt-8 pb-8 relative">
                  <div className="w-12 h-1.5 bg-gradient-to-r from-[#9b87f5] via-[#D946EF] to-[#9b87f5] rounded-full mx-auto mb-7" />
                  
                  <DialogHeader className="mb-7">
                    <motion.div 
                      initial={{ y: -10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.3 }}
                      className="relative"
                    >
                      <DialogTitle className="text-center text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#9b87f5] to-[#D946EF]">
                        {selectedSport} Venues
                      </DialogTitle>
                      <motion.div 
                        className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 h-0.5 w-8 rounded-full bg-gradient-to-r from-[#9b87f5] to-[#D946EF]"
                        initial={{ width: 0 }}
                        animate={{ width: 32 }}
                        transition={{ delay: 0.2, duration: 0.5 }}
                      />
                    </motion.div>
                    <DialogDescription className="text-center text-gray-600 mt-3">
                      <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.1, duration: 0.3 }}
                      >
                        Discover perfect spots near you
                      </motion.div>
                    </DialogDescription>
                  </DialogHeader>
                  
                  <div className="space-y-6">
                    <motion.div
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.2, duration: 0.3 }}
                      className="relative"
                    >
                      <div className="absolute -inset-1 bg-gradient-to-r from-[#9b87f5]/50 via-[#D946EF]/50 to-[#9b87f5]/50 rounded-xl blur-md opacity-70" />
                      
                      <div className="relative flex items-center gap-3 pl-3 pr-1 py-1 bg-white/80 backdrop-blur-md rounded-xl shadow-md z-10 border border-purple-100/50">
                        <MapPin className="w-5 h-5 text-purple-500" />
                        <Select value={selectedCity} onValueChange={setSelectedCity}>
                          <SelectTrigger className="border-0 shadow-none bg-transparent h-14 focus:ring-0 focus:ring-offset-0 min-w-0 flex-1">
                            <SelectValue placeholder="Select your city" className="text-purple-900" />
                          </SelectTrigger>
                          <SelectContent className="max-h-80 rounded-xl border-purple-100 bg-white/90 backdrop-blur-lg">
                            {popularCities.map((city) => (
                              <SelectItem 
                                key={city} 
                                value={city}
                                className="text-gray-800 hover:bg-purple-50 hover:text-purple-800 focus:bg-purple-50 focus:text-purple-800"
                              >
                                {city}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </motion.div>
                    
                    <motion.div
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.3, duration: 0.3 }}
                      className="p-4 bg-purple-50/70 backdrop-blur-sm rounded-xl border border-purple-100"
                    >
                      <div className="flex items-center gap-3">
                        <Calendar className="w-5 h-5 text-purple-500" />
                        <div className="flex-1">
                          <p className="text-sm font-medium text-purple-900">Popular Time</p>
                          <p className="text-xs text-purple-700">Today, 5PM - 8PM</p>
                        </div>
                        <span className="text-xs bg-purple-200 text-purple-800 px-2 py-1 rounded-full">
                          Trending
                        </span>
                      </div>
                    </motion.div>
                    
                    <motion.div
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.4, duration: 0.3 }}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className="relative mt-4"
                    >
                      <div className="absolute -inset-0.5 bg-gradient-to-r from-[#9b87f5] to-[#D946EF] rounded-xl blur-sm opacity-70" />
                      <Button 
                        onClick={handleCityConfirm}
                        disabled={!selectedCity}
                        className="w-full h-14 relative bg-gradient-to-r from-[#9b87f5] to-[#D946EF] text-white rounded-xl shadow-lg shadow-purple-200/50 border-0 overflow-hidden group"
                      >
                        <div className="absolute inset-0 rounded-xl bg-gradient-to-br from-white/20 to-transparent opacity-80" />
                        <div className="absolute inset-0 rounded-xl bg-black/5 opacity-0 group-hover:opacity-20 transition-opacity" />
                        <div className="relative flex items-center justify-center gap-2">
                          <span className="font-semibold tracking-wide text-lg">Find {selectedSport} Venues</span>
                          <ChevronRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                        </div>
                        
                        <div className="absolute inset-0 opacity-20">
                          <div className="absolute top-1/4 left-1/4 w-1 h-1 bg-white rounded-full animate-ping" style={{ animationDuration: '3s', animationDelay: '0.2s' }} />
                          <div className="absolute top-3/4 left-2/3 w-1 h-1 bg-white rounded-full animate-ping" style={{ animationDuration: '2.5s', animationDelay: '0.5s' }} />
                          <div className="absolute top-1/2 left-3/4 w-1 h-1 bg-white rounded-full animate-ping" style={{ animationDuration: '2s', animationDelay: '0.7s' }} />
                        </div>
                      </Button>
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            </DialogContent>
          </Dialog>
        )}
      </AnimatePresence>
    </>
  );
};

export default SportCircleButtons;
