
import { ArrowRight, MapPin, Star } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { motion } from "framer-motion";
import { useIsMobile } from "@/hooks/use-mobile";

const FeaturedCoaches = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  
  const coaches = [
    {
      id: "1de271da-724d-4eab-a027-96afa5902f3a",
      name: "Raghav Kapur",
      sports: ["Cricket"],
      experience: 8,
      location: "Sector 50, Gurgaon",
      hourly_rate: 1200,
      rating: 4.9,
      image: "/lovable-uploads/43af30a5-f215-450d-b49a-2833b8e2a254.png",
      isVerified: true
    },
    {
      id: "f6e94603-480a-49c1-9d42-0709f6dcfe20",
      name: "Kaptaan Varma",
      sports: ["Cricket"],
      experience: 10,
      location: "Sector 28, Gurgaon",
      hourly_rate: 1200,
      rating: 4.8,
      image: "/lovable-uploads/d8557430-9463-43e7-b1d4-0e18f4a2b9da.png",
      isVerified: true,
      imageStyle: "object-cover object-[center_15%]" // Adjusted for better face visibility
    },
    {
      id: "db6dbdf1-0461-4ab9-95ea-2f4963bb5bdb",
      name: "Sukhveer Singh",
      sports: ["Cricket"],
      experience: 6,
      location: "Sector 14, Gurgaon",
      hourly_rate: 1200,
      rating: 4.7,
      image: "/lovable-uploads/3bf8a645-44fe-47a5-a7ce-105b04e83ee2.png",
      isVerified: true,
      imageStyle: "object-cover object-[center_12%]" // Adjusted for better face visibility
    }
  ];

  const handleViewCoach = (id: string) => {
    navigate(`/coaches/${id}`);
  };

  const handleNavigate = () => {
    navigate('/coaches');
  };

  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold bg-gradient-to-r from-[#D6BCFA] via-[#B794F4] to-[#9F7AEA] bg-clip-text text-transparent animate-gradient px-1 py-1 rounded-lg relative">
            Featured Coaches
            <div className="h-1 w-full rounded-full bg-gradient-to-r from-[#D6BCFA] via-[#B794F4] to-[#9F7AEA] opacity-70 absolute bottom-0 left-0"></div>
          </h2>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/coaches')}
            className="flex items-center space-x-2 px-4 py-2 rounded-full bg-gradient-to-r from-purple-400 to-purple-600 text-white shadow-md hover:shadow-lg transition-all duration-300 group cursor-pointer"
          >
            <span className="font-medium">View more</span>
            <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
          </motion.button>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {coaches.map((coach, index) => (
            <motion.div
              key={coach.id}
              initial={isMobile ? { opacity: 0, y: 20 } : false}
              animate={isMobile ? { opacity: 1, y: 0 } : {}}
              transition={{ delay: index * 0.2 }}
              onClick={() => navigate(`/coaches/${coach.id}`)}
              className="bg-white cursor-pointer rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 group"
            >
              <div className="relative h-64 overflow-hidden">
                <img
                  src={coach.image}
                  alt={coach.name}
                  className={`w-full h-full ${coach.imageStyle || 'object-cover'} group-hover:scale-105 transition-transform duration-300`}
                />
                {coach.isVerified && (
                  <div className="absolute top-4 left-4">
                    <span className="px-3 py-1 bg-primary text-white rounded-full text-sm font-semibold">
                      Verified
                    </span>
                  </div>
                )}
              </div>
              
              <div className="p-6 space-y-4">
                <h3 className="text-xl font-semibold text-gray-900">{coach.name}</h3>
                
                <div className="flex items-center text-gray-600">
                  <MapPin size={16} className="mr-2" />
                  <span>{coach.location}</span>
                </div>
                
                <div className="flex flex-wrap gap-2">
                  {coach.sports.map((sport, index) => (
                    <span key={index} className="text-xs bg-gray-100 px-2 py-1 rounded-full text-gray-600">
                      {sport}
                    </span>
                  ))}
                </div>
                
                <div className="flex justify-between items-center pt-4 border-t border-gray-100">
                  <div className="flex items-center text-gray-600">
                    <Star className="w-4 h-4 text-yellow-400 fill-yellow-400 mr-1" />
                    <span>{coach.rating}</span>
                    <span className="mx-2">•</span>
                    <span>{coach.experience} years exp</span>
                  </div>
                  <span className="text-primary font-semibold">₹{coach.hourly_rate}/hr</span>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturedCoaches;
