import { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "@/hooks/use-mobile";
import { openInExternalBrowser } from "@/utils/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { Card, CardContent } from "@/components/ui/card";
import { 
  Trophy, Activity, Target, Dumbbell, Flag, AlertCircle, Database, 
  Play, Eye, Calendar, Star, Clock, Users, ExternalLink
} from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import LoadingScreen from "@/components/ui/loading-screen";
import { Button } from "@/components/ui/button";
import InAppBrowser from "@/components/InAppBrowser";

const sports = [
  { id: "cricket", name: "Cricket", icon: Activity },
  { id: "football", name: "Football", icon: Activity },
  { id: "basketball", name: "Basketball", icon: Activity },
  { id: "baseball", name: "Baseball", icon: Activity },
  { id: "tennis", name: "Tennis", icon: Activity },
  { id: "volleyball", name: "Volleyball", icon: Activity },
  { id: "rugby", name: "Rugby", icon: Activity },
  { id: "hockey", name: "Hockey", icon: Activity },
  { id: "golf", name: "Golf", icon: Activity },
  { id: "boxing", name: "Boxing", icon: Activity },
  { id: "mma", name: "MMA", icon: Activity },
  { id: "athletics", name: "Athletics", icon: Activity },
  { id: "swimming", name: "Swimming", icon: Activity },
  { id: "cycling", name: "Cycling", icon: Activity },
  { id: "motorsports", name: "Motorsports", icon: Activity },
  { id: "esports", name: "Esports", icon: Activity },
  { id: "general", name: "General", icon: Activity },
];

const apiSources = [
  { id: "serp-api", name: "SERP API" },
  { id: "google-cse", name: "Google Search" },
  { id: "sportradar", name: "Sportradar" },
  { id: "mock", name: "Sample Data" },
];

const getSportIcon = (sportName: string) => {
  const sport = sports.find(s => s.id === sportName);
  return sport ? sport.icon : Activity;
};

const formatMatchStatus = (status: string) => {
  if (status.match(/^\d+'/)) {
    return `${status} min`;
  }
  return status;
};

const LiveFeed = () => {
  const [selectedSport, setSelectedSport] = useState("cricket");
  const [selectedView, setSelectedView] = useState("trending");
  const [selectedSource, setSelectedSource] = useState("google-cse");
  const [browserUrl, setBrowserUrl] = useState("");
  const [browserOpen, setBrowserOpen] = useState(false);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const queryClient = useQueryClient();
  
  useEffect(() => {
    if (selectedView === 'results') {
      setSelectedSource('serp-api');
    } else if (selectedView === 'trending' || selectedView === 'highlights') {
      setSelectedSource('google-cse');
    }
  }, [selectedView]);

  const { data: resultsFeed, isLoading: isResultsLoading, error: resultsError } = useQuery({
    queryKey: ['sports-feed', selectedSport, selectedSource],
    queryFn: async () => {
      console.log('Fetching sports feed for:', selectedSport, 'using source:', selectedSource);
      
      if (selectedSource === 'serp-api') {
        const { data, error } = await supabase.functions.invoke('fetch-serp-sports', {
          body: { sport: selectedSport }
        });

        if (error) {
          console.error('Error fetching from SERP API:', error);
          throw error;
        }

        console.log('Received SERP API data:', data);
        
        if (selectedSport === 'golf' && (!data?.feed || data.feed.length === 0)) {
          console.log('No golf data available from SERP API');
          return [];
        }
        
        return data?.feed || [];
      } 
      
      const { data, error } = await supabase.functions.invoke('generate-sports-feed', {
        body: { 
          sport: selectedSport,
          source: selectedSource,
          queryType: 'scores'
        }
      });

      if (error) {
        console.error('Error fetching sports feed:', error);
        throw error;
      }

      console.log('Received sports feed data:', data);
      
      if (selectedSport === 'golf' && (!data?.feed || data.feed.length === 0)) {
        console.log('No golf data available');
        return [];
      }
      
      return data?.feed || [];
    },
    enabled: selectedView === 'results',
    retry: 2,
    staleTime: 60000,
    refetchInterval: 300000
  });

  const { data: trendingFeed, isLoading: isTrendingLoading, error: trendingError } = useQuery({
    queryKey: ['cse-news', selectedSport, selectedSource],
    queryFn: async () => {
      console.log('Fetching trending news via CSE for:', selectedSport);
      const { data, error } = await supabase.functions.invoke('fetch-cse-news', {
        body: { sport: selectedSport }
      });
      
      if (error) {
        console.error('Error fetching CSE news:', error);
        throw error;
      }
      
      console.log('Received CSE news data:', data);
      return data?.feed || [];
    },
    enabled: selectedView === 'trending',
    retry: 1,
    staleTime: 300000,
    refetchOnWindowFocus: false
  });

  const { data: highlightsFeed, isLoading: isHighlightsLoading } = useQuery({
    queryKey: ['sports-highlights', selectedSport],
    queryFn: async () => {
      console.log('Fetching highlights for:', selectedSport);
      const { data, error } = await supabase.functions.invoke('fetch-sports-highlights', {
        body: { sport: selectedSport }
      });
      
      if (error) {
        console.error('Error fetching highlights:', error);
        throw error;
      }
      
      console.log('Received highlights:', data);
      return data?.highlights || [];
    },
    enabled: selectedView === 'highlights',
    retry: 2,
    staleTime: 600000
  });

  const decodeHtmlEntities = (text: string) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  };

  const handleOpenArticle = (url: string, event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    
    openInExternalBrowser(url);
  };

  const renderTrendingNews = () => {
    if (isTrendingLoading) {
      return (
        <div className="flex justify-center py-16">
          <LoadingScreen size="md" transparent={true} />
        </div>
      );
    }

    if (trendingError) {
      return (
        <Alert className="bg-red-50 border-red-200">
          <AlertCircle className="h-4 w-4 text-red-600" />
          <AlertDescription className="text-gray-900">
            Unable to fetch trending news. Please try again later.
          </AlertDescription>
        </Alert>
      );
    }

    if (!trendingFeed || trendingFeed.length === 0) {
      return (
        <Alert className="bg-purple-50 border-purple-200">
          <AlertCircle className="h-4 w-4 text-purple-600" />
          <AlertDescription className="text-gray-900">
            No trending news available for {selectedSport} at the moment. Try another sport or check back later.
          </AlertDescription>
        </Alert>
      );
    }

    return (
      <div className="space-y-6">
        {trendingFeed.map((item: any, index: number) => (
          <a 
            href={item.url} 
            key={index}
            className="block"
            onClick={(e) => handleOpenArticle(item.url, e)}
          >
            <Card className="overflow-hidden hover:shadow-xl transition-all duration-300 border-purple-100 bg-gradient-to-br from-white via-purple-50/30 to-blue-50/30 animate-fade-in transform hover:scale-[1.02]">
              <CardContent className="p-4 sm:p-6">
                <div className="flex flex-col sm:flex-row items-start gap-4">
                  <div className="w-full sm:w-16 h-16 rounded-lg overflow-hidden flex-shrink-0 bg-gradient-to-br from-purple-500 to-blue-500">
                    {item.imageUrl ? (
                      <img 
                        src={item.imageUrl} 
                        alt={item.title}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <Trophy className="w-8 h-8 text-white" />
                      </div>
                    )}
                  </div>
                  <div className="space-y-3 flex-1">
                    <h3 className="text-lg sm:text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600 line-clamp-2">
                      {item.title}
                    </h3>
                    <p className="text-sm sm:text-base text-gray-600 leading-relaxed line-clamp-3">
                      {item.description}
                    </p>
                    <div className="flex flex-wrap items-center gap-4 text-xs sm:text-sm mt-4">
                      <span className="text-purple-600 flex items-center gap-1">
                        <Activity className="w-4 h-4" />
                        {new Date(item.publishedAt).toLocaleString()}
                      </span>
                      <span className="text-blue-600 flex items-center gap-1">
                        <Database className="w-4 h-4" />
                        {item.source}
                      </span>
                      {item.upvotes && (
                        <span className="text-orange-600 flex items-center gap-1">
                          ↑ {item.upvotes}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </a>
        ))}
      </div>
    );
  };

  const renderHighlights = () => {
    if (isHighlightsLoading) {
      return (
        <div className="flex justify-center py-16">
          <LoadingScreen size="md" transparent={true} />
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {highlightsFeed?.map((highlight: any) => (
          <a
            href={`https://www.youtube.com/watch?v=${highlight.id}`}
            key={highlight.id}
            className="block group"
            onClick={(e) => handleOpenArticle(`https://www.youtube.com/watch?v=${highlight.id}`, e)}
          >
            <Card className="overflow-hidden hover:shadow-xl transition-all duration-300 bg-gradient-to-br from-white via-purple-50/30 to-blue-50/30 transform group-hover:scale-[1.02]">
              <CardContent className="p-0">
                <div className="relative aspect-video md:aspect-[16/9] w-full max-w-full md:w-auto @container">
                  <img
                    src={highlight.thumbnail}
                    alt={decodeHtmlEntities(highlight.title)}
                    className="w-full h-full object-cover @[400px]:scale-90 md:scale-100"
                  />
                  <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                    <Play className="w-16 h-16 text-white" />
                  </div>
                  <div className="absolute bottom-2 right-2 bg-black/80 text-white px-2 py-1 rounded text-sm">
                    {highlight.duration}
                  </div>
                </div>
                <div className="p-4 space-y-3">
                  <h3 className="font-semibold text-lg line-clamp-2 text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600">
                    {decodeHtmlEntities(highlight.title)}
                  </h3>
                  <div className="flex items-center justify-between text-sm text-gray-600">
                    <span className="flex items-center gap-1">
                      <Eye className="w-4 h-4" />
                      {highlight.views} views
                    </span>
                    <span className="flex items-center gap-1">
                      <Calendar className="w-4 h-4" />
                      {highlight.publishedAt}
                    </span>
                  </div>
                  <p className="text-sm text-gray-500 line-clamp-2">
                    {decodeHtmlEntities(highlight.channelTitle)}
                  </p>
                </div>
              </CardContent>
            </Card>
          </a>
        ))}
      </div>
    );
  };

  const renderScoreContent = (content: string, sport: string) => {
    const [status, score] = content.split(':').map(part => part.trim());
    
    return (
      <div className="space-y-1">
        <div className="flex items-center gap-2">
          <Clock className="h-4 w-4 text-purple-500" />
          <span className="text-sm font-medium text-purple-700">{formatMatchStatus(status)}</span>
        </div>
        <div className="text-xl font-bold text-gray-900">{score}</div>
      </div>
    );
  };

  const formatTimeAgo = (timestamp: string) => {
    const date = new Date(timestamp);
    const now = new Date();
    const diffMs = now.getTime() - date.getTime();
    const diffMins = Math.round(diffMs / 60000);
    
    if (diffMins < 1) return 'Just now';
    if (diffMins < 60) return `${diffMins}m ago`;
    
    const diffHours = Math.round(diffMins / 60);
    if (diffHours < 24) return `${diffHours}h ago`;
    
    return date.toLocaleDateString();
  };

  const renderContent = () => {
    if (selectedView === 'trending') {
      return renderTrendingNews();
    }

    if (selectedView === 'highlights') {
      return renderHighlights();
    }

    if (isResultsLoading) {
      return (
        <div className="flex justify-center py-16">
          <LoadingScreen size="md" transparent={true} />
        </div>
      );
    }

    if (resultsError) {
      return (
        <Alert className="bg-red-50 border-red-200">
          <AlertCircle className="h-4 w-4 text-red-600" />
          <AlertDescription className="text-gray-900">
            Unable to fetch results. Please try again later. {resultsError.message}
          </AlertDescription>
        </Alert>
      );
    }

    if (selectedSport === 'golf' && (!resultsFeed || resultsFeed.length === 0)) {
      return (
        <Alert className="bg-purple-50 border-purple-200">
          <AlertCircle className="h-4 w-4 text-purple-600" />
          <AlertDescription className="text-gray-900">
            No live matches available for golf at the moment. Check back later for updates.
          </AlertDescription>
        </Alert>
      );
    }

    if (!resultsFeed || resultsFeed.length === 0) {
      return (
        <Alert className="bg-purple-50 border-purple-200">
          <AlertCircle className="h-4 w-4 text-purple-600" />
          <AlertDescription className="text-gray-900">
            No live matches available for {selectedSport} at the moment. Check back later for updates.
          </AlertDescription>
        </Alert>
      );
    }

    return (
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {resultsFeed.map((item: any, index: number) => {
          const SportIcon = getSportIcon(selectedSport);
          
          const matchTitle = item.title || item.matchTitle || 'Live Match';
          const matchStatus = item.matchStatus || item.status || 'Live';
          const scoreDisplay = item.score || item.scoreContent || item.content?.split(':')[1]?.trim() || 'No score available';
          
          return (
            <Card 
              key={index} 
              className="overflow-hidden hover:shadow-lg transition-all duration-300 border-purple-100 bg-gradient-to-br from-white via-purple-50/30 to-blue-50/30 hover:scale-[1.02]"
            >
              <CardContent className="p-5">
                <div className="flex flex-col gap-4">
                  <div className="flex items-start justify-between">
                    <div className="flex items-center gap-2">
                      <div className="p-2 rounded-full bg-purple-100">
                        <SportIcon className="w-5 h-5 text-purple-600" />
                      </div>
                      <h3 className="font-semibold text-lg text-gray-900 line-clamp-1">
                        {matchTitle}
                      </h3>
                    </div>
                    {item.url && (
                      <a 
                        href={item.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800"
                      >
                        <ExternalLink className="w-4 h-4" />
                      </a>
                    )}
                  </div>
                  
                  <div className="mt-1">
                    <div className="space-y-1">
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4 text-purple-500" />
                        <span className="text-sm font-medium text-purple-700">{formatMatchStatus(matchStatus)}</span>
                      </div>
                      <div className="text-xl font-bold text-gray-900">{scoreDisplay}</div>
                    </div>
                  </div>
                  
                  <div className="flex items-center justify-between text-sm text-gray-500 mt-2">
                    <span className="flex items-center gap-1">
                      <Users className="w-4 h-4" />
                      {item.source || item.provider || 'Live Score'}
                    </span>
                    <span className="flex items-center gap-1">
                      <Calendar className="w-4 h-4" />
                      {formatTimeAgo(item.timestamp)}
                    </span>
                  </div>
                </div>
              </CardContent>
            </Card>
          );
        })}
      </div>
    );
  };

  const handleRefresh = () => {
    if (selectedView === 'results') {
      queryClient.invalidateQueries({ queryKey: ['sports-feed'] });
    } else if (selectedView === 'trending') {
      queryClient.invalidateQueries({ queryKey: ['cse-news'] });
    } else if (selectedView === 'highlights') {
      queryClient.invalidateQueries({ queryKey: ['sports-highlights'] });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white pt-16 md:pt-32 px-4 sm:px-6 lg:px-8">
      <InAppBrowser 
        url={browserUrl}
        isOpen={browserOpen}
        onClose={() => setBrowserOpen(false)}
      />
      
      <div className="max-w-7xl mx-auto space-y-8">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600">
            Live Sports Feed
          </h1>
          <p className="mt-4 text-lg text-gray-600">
            Stay updated with the latest in sports
          </p>
        </div>

        {isMobile && (
          <button
            onClick={() => navigate('/favorites')}
            className="w-full py-3 px-4 flex items-center justify-center gap-2 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] active:scale-[0.98]"
          >
            <Star className="w-5 h-5" />
            <span className="font-medium">Track your favourite teams</span>
          </button>
        )}

        <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
          <Select
            value={selectedSport}
            onValueChange={(value) => {
              setSelectedSport(value);
              queryClient.invalidateQueries({ 
                queryKey: ['cse-news'] 
              });
              queryClient.invalidateQueries({ 
                queryKey: ['sports-feed'] 
              });
            }}
          >
            <SelectTrigger className="w-[200px] bg-white border-purple-200 hover:border-purple-300 text-gray-900 dark:text-black">
              <SelectValue placeholder="Select sport" />
            </SelectTrigger>
            <SelectContent>
              {sports.map((sport) => (
                <SelectItem key={sport.id} value={sport.id}>
                  <div className="flex items-center gap-2 text-gray-900 dark:text-black">
                    <sport.icon className="w-4 h-4" />
                    {sport.name}
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          {selectedView === 'results' ? (
            <div className="w-[200px] px-4 py-2 rounded-md bg-white border border-purple-200 flex items-center gap-2 text-gray-900">
              <Database className="w-4 h-4 text-purple-600" />
              <span>SERP API</span>
            </div>
          ) : (
            <div className="w-[200px] px-4 py-2 rounded-md bg-white border border-purple-200 flex items-center gap-2 text-gray-900">
              <Database className="w-4 h-4 text-purple-600" />
              <span>Google Search</span>
            </div>
          )}

          <Tabs value={selectedView} onValueChange={setSelectedView} className="w-[300px]">
            <TabsList className="grid grid-cols-3 bg-purple-100">
              <TabsTrigger 
                value="trending" 
                className="data-[state=active]:bg-white data-[state=active]:text-purple-600"
              >
                Trending
              </TabsTrigger>
              <TabsTrigger 
                value="results" 
                className="data-[state=active]:bg-white data-[state=active]:text-purple-600"
              >
                Results
              </TabsTrigger>
              <TabsTrigger 
                value="highlights" 
                className="data-[state=active]:bg-white data-[state=active]:text-purple-600"
              >
                Highlights
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        <div className="flex justify-end">
          <Button 
            onClick={handleRefresh}
            variant="outline"
            className="text-purple-600 border-purple-300 hover:bg-purple-50"
          >
            <Clock className="mr-2 h-4 w-4" />
            Refresh Data
          </Button>
        </div>

        <div className="grid gap-6">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default LiveFeed;
