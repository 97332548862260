
import React, { useState } from "react";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { Venue } from "@/types/venue";
import { Map, Star, Clock, Phone, Globe, MapPin } from "lucide-react";
import { Button } from "@/components/ui/button";
import { getVenueImage } from "@/utils/venueImageUtils";
import InAppBrowser from "@/components/InAppBrowser";

interface VenueDetailsSheetProps {
  venue: Venue | null;
  isOpen: boolean;
  onClose: () => void;
}

const VenueDetailsSheet: React.FC<VenueDetailsSheetProps> = ({ venue, isOpen, onClose }) => {
  if (!venue) return null;
  
  const [browserUrl, setBrowserUrl] = useState<string | null>(null);
  const [showBrowser, setShowBrowser] = useState(false);
  
  const handleExternalLink = (url: string) => {
    if (url) {
      if (url.startsWith('tel:')) {
        // For phone numbers, use tel: protocol directly
        window.location.href = url;
      } else {
        // For websites, use in-app browser
        setBrowserUrl(url);
        setShowBrowser(true);
      }
    }
  };
  
  const handleOpenGoogleMaps = () => {
    // Check if running on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    // Determine the correct URL to use
    let mapUrl: string | null = null;
    if (venue.maps_url) {
      mapUrl = venue.maps_url;
    } else if (venue.place_id) {
      mapUrl = `https://www.google.com/maps/place/?q=place_id:${venue.place_id}`;
    }
    
    if (!mapUrl) return;
    
    if (isMobile) {
      // Try to open in the Google Maps app
      const googleMapsAppUrl = `https://maps.google.com/?q=${encodeURIComponent(venue.address || venue.name)}&cid=${venue.place_id}`;
      window.location.href = googleMapsAppUrl;
    } else {
      // On desktop, just open in a new tab
      window.open(mapUrl, '_blank', 'noopener,noreferrer');
    }
  };
  
  const handleCloseBrowser = () => {
    setShowBrowser(false);
    setBrowserUrl(null);
  };
  
  return (
    <>
      <Sheet open={isOpen} onOpenChange={onClose}>
        <SheetContent className="overflow-auto">
          <SheetHeader className="pb-2">
            <SheetTitle className="text-xl font-bold">{venue.name}</SheetTitle>
            <SheetDescription className="text-gray-600 flex items-center">
              <MapPin className="h-4 w-4 mr-1" />
              {venue.address}, {venue.city}
            </SheetDescription>
          </SheetHeader>
          
          <div className="space-y-4 mt-4">
            <div className="h-48 rounded-md overflow-hidden bg-gray-100">
              <img 
                src={getVenueImage(venue)} 
                alt={venue.name}
                className="w-full h-full object-cover"
              />
            </div>
            
            {venue.description && (
              <div>
                <h3 className="font-medium mb-1">About</h3>
                <p className="text-sm text-gray-600">{venue.description}</p>
              </div>
            )}
            
            <div className="flex flex-wrap gap-2 mt-2">
              {venue.available_sports?.map((sport) => (
                <span 
                  key={sport} 
                  className="bg-gray-100 text-xs text-gray-700 px-2 py-1 rounded-full"
                >
                  {sport}
                </span>
              ))}
            </div>
            
            <div className="grid grid-cols-2 gap-3 text-sm">
              {venue.opening_time && venue.closing_time && (
                <div className="flex items-center">
                  <Clock className="h-4 w-4 mr-2 text-gray-500" />
                  <span>{venue.opening_time} - {venue.closing_time}</span>
                </div>
              )}
              
              {venue.rating && (
                <div className="flex items-center">
                  <Star className="h-4 w-4 mr-2 text-yellow-500 fill-yellow-500" />
                  <span>{venue.rating.toFixed(1)} {venue.total_ratings && `(${venue.total_ratings})`}</span>
                </div>
              )}
              
              <div className="flex items-center">
                <span className="font-medium">
                  {venue.is_bookable ? `₹${venue.price_per_hour}/hr` : "Available on inquiry"}
                </span>
              </div>
            </div>
            
            {venue.facilities && venue.facilities.length > 0 && (
              <div>
                <h3 className="font-medium mb-1">Facilities</h3>
                <div className="flex flex-wrap gap-2">
                  {venue.facilities.map((facility) => (
                    <span 
                      key={facility} 
                      className="bg-gray-100 text-xs text-gray-700 px-2 py-1 rounded-full"
                    >
                      {facility}
                    </span>
                  ))}
                </div>
              </div>
            )}
            
            <div className="flex flex-col gap-2 pt-4">
              {venue.maps_url && (
                <Button 
                  onClick={handleOpenGoogleMaps} 
                  variant="outline" 
                  className="w-full flex items-center justify-center gap-2"
                >
                  <Map className="h-4 w-4" />
                  <span>View on Google Maps</span>
                </Button>
              )}
              
              {venue.website && (
                <Button 
                  onClick={() => handleExternalLink(venue.website!)} 
                  variant="outline"
                  className="w-full flex items-center justify-center gap-2"
                >
                  <Globe className="h-4 w-4" />
                  <span>Visit Website</span>
                </Button>
              )}
              
              {venue.phone && (
                <Button 
                  onClick={() => handleExternalLink(`tel:${venue.phone}`)} 
                  variant="outline"
                  className="w-full flex items-center justify-center gap-2"
                >
                  <Phone className="h-4 w-4" />
                  <span>Call Venue</span>
                </Button>
              )}
            </div>
          </div>
        </SheetContent>
      </Sheet>
      
      {browserUrl && (
        <InAppBrowser 
          url={browserUrl} 
          isOpen={showBrowser} 
          onClose={handleCloseBrowser} 
        />
      )}
    </>
  );
};

export default VenueDetailsSheet;
