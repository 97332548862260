import { useState, useRef, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Send, Bot, Loader2, ArrowLeft } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useIsMobile } from "@/hooks/use-mobile";
import { motion } from "framer-motion";

interface Message {
  role: "assistant" | "user";
  content: string;
}

interface BracketGPTProps {
  onClose?: () => void;
}

const predefinedPrompts = [
  "How do I estimate costs for organizing a tournament?",
  "What's the best tournament format for 16 teams?",
  "How should I schedule matches to avoid conflicts?",
];

const BracketGPT = ({ onClose }: BracketGPTProps) => {
  const [messages, setMessages] = useState<Message[]>([
    {
      role: "assistant",
      content: "Hello! I'm BracketGPT, your event planning assistant. I can help you organize tournaments, leagues, and sporting events. What would you like to know?",
    },
  ]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestedTopics, setShowSuggestedTopics] = useState(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const lastResponseRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToLastResponse = () => {
    if (isMobile && lastResponseRef.current) {
      lastResponseRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1].role === "assistant") {
      scrollToLastResponse();
    }
  }, [messages]);

  const handleSubmit = async (content: string) => {
    if (!content.trim()) return;

    setInput("");
    setIsLoading(true);

    if (isMobile) {
      setShowSuggestedTopics(false);
    }

    const newMessages: Message[] = [
      ...messages,
      { role: "user" as const, content: content.trim() },
    ];
    setMessages(newMessages);

    try {
      const { data, error } = await supabase.functions.invoke('bracketgpt', {
        body: {
          messages: newMessages.map(m => ({
            role: m.role,
            content: m.content,
          })),
        },
      });

      if (error) throw error;

      setMessages([
        ...newMessages,
        { role: "assistant" as const, content: data.generatedText },
      ]);
    } catch (error) {
      console.error("Error calling BracketGPT:", error);
      setMessages([
        ...newMessages,
        {
          role: "assistant" as const,
          content: "I apologize, but I encountered an error. Please try again.",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`flex flex-col ${isMobile ? 'h-[90vh] mx-4 my-8 rounded-2xl overflow-hidden shadow-2xl' : 'h-screen'} bg-white`}>
      <motion.div 
        initial={isMobile ? { y: 20, opacity: 0 } : false}
        animate={isMobile ? { y: 0, opacity: 1 } : {}}
        className="flex items-center justify-between p-3 md:p-4 border-b bg-white/90 backdrop-blur-lg"
      >
        <div className="flex items-center space-x-2">
          {onClose && (
            <Button variant="ghost" onClick={onClose} className="mr-1 md:mr-2 hover:bg-gray-100">
              <ArrowLeft className="w-4 h-4" />
            </Button>
          )}
          <Bot className="w-5 h-5 md:w-6 md:h-6 text-blue-600" />
          <h2 className="text-lg md:text-xl font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            BracketGPT
          </h2>
        </div>
      </motion.div>

      <div className="flex-1 overflow-hidden">
        <div className="flex-1 flex flex-col h-full">
          <ScrollArea className="flex-1 px-2 md:px-4 py-3">
            <div className="space-y-3 md:space-y-4 mx-auto max-w-screen-sm">
              {messages.map((message, i) => (
                <motion.div
                  key={i}
                  initial={isMobile ? { scale: 0.95, opacity: 0 } : false}
                  animate={isMobile ? { scale: 1, opacity: 1 } : {}}
                  transition={{ duration: 0.2 }}
                  ref={i === messages.length - 1 && message.role === "assistant" ? lastResponseRef : null}
                  className={`flex ${
                    message.role === "assistant" ? "justify-start" : "justify-end"
                  }`}
                >
                  <div
                    className={`max-w-[85%] md:max-w-[80%] rounded-2xl p-3 md:p-4 ${
                      message.role === "assistant"
                        ? "bg-gray-100 text-gray-900"
                        : "bg-gradient-to-r from-blue-600 to-purple-600 text-white"
                    } shadow-sm`}
                  >
                    <p className="whitespace-pre-wrap text-sm md:text-base">{message.content}</p>
                  </div>
                </motion.div>
              ))}
              <div ref={messagesEndRef} />
            </div>
          </ScrollArea>

          <div className="p-2 md:p-4 border-t bg-white/90 backdrop-blur-lg">
            {showSuggestedTopics && (
              <div className="mb-3 md:mb-4">
                <div className="text-xs md:text-sm font-medium text-gray-500 mb-2">
                  Suggested topics:
                </div>
                <div className="flex flex-wrap gap-1.5 md:gap-2">
                  {predefinedPrompts.map((prompt, i) => (
                    <motion.button
                      key={i}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => handleSubmit(prompt)}
                      disabled={isLoading}
                      className="text-xs md:text-sm bg-gradient-to-r from-blue-50 to-purple-50 hover:from-blue-100 hover:to-purple-100 px-3 py-1.5 rounded-full text-gray-700 font-medium transition-colors"
                    >
                      {prompt}
                    </motion.button>
                  ))}
                </div>
              </div>
            )}

            <div className="flex space-x-2 max-w-screen-sm mx-auto">
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message..."
                onKeyPress={(e) => e.key === "Enter" && handleSubmit(input)}
                disabled={isLoading}
                className="text-sm md:text-base rounded-full"
              />
              <Button
                onClick={() => handleSubmit(input)}
                disabled={isLoading || !input.trim()}
                className="rounded-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700"
              >
                {isLoading ? (
                  <Loader2 className="w-4 h-4 animate-spin" />
                ) : (
                  <Send className="w-4 h-4" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BracketGPT;
