
export const popularCities = [
  "Mumbai",
  "Bangalore",
  "Delhi",
  "Gurgaon",
  "Hyderabad",
  "Chennai",
  "Kolkata",
  "Pune",
  "Ahmedabad",
  "Jaipur",
  "Lucknow",
  "Chandigarh",
  "Indore",
  "Noida",
  "Thane",
  "Navi Mumbai",
  "Faridabad",
  "Ghaziabad",
  "Vadodara",
  "Surat",
  "Coimbatore",
  "Bhopal",
  "Nagpur",
  "Visakhapatnam",
  "Kochi",
  "Dehradun",
  "Bhubaneswar",
  "Mysore",
  "Pondicherry",
  "Ranchi"
];
