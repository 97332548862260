import { 
  ArrowLeft, Shield, FileText, LockKeyhole, Users, 
  Scale, Globe, AlertTriangle, Settings, Mail, 
  ExternalLink, BookOpen, CheckCircle2, Map
} from "lucide-react";
import BackButton from "@/components/ui/back-button";

const Terms = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50 pt-16 md:pt-32">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pb-24 relative">
        <div className="absolute left-0 top-0">
          <BackButton className="md:flex hidden" />
          <button 
            onClick={() => window.history.back()}
            className="hidden md:flex items-center gap-2 text-gray-600 hover:text-blue-700 transition-colors group p-2"
            aria-label="Go back"
          >
            <ArrowLeft className="h-5 w-5 group-hover:-translate-x-1 transition-transform" />
            <span className="font-medium">Back</span>
          </button>
        </div>
        
        <div className="mb-16 text-center">
          <div className="relative inline-block mb-4">
            <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-indigo-600 rounded-lg blur opacity-25"></div>
            <h1 className="relative text-3xl md:text-4xl font-bold text-gray-900 bg-clip-text text-transparent bg-gradient-to-r from-blue-800 to-indigo-600">
              Terms & Conditions
            </h1>
          </div>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Please read these terms carefully before using beSportly's services
          </p>
        </div>
        
        <div className="flex flex-wrap -mx-4 mb-16">
          <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0">
            <div className="sticky top-32">
              <div className="bg-white rounded-xl shadow-lg p-6 mb-6">
                <h2 className="text-lg font-bold text-gray-900 mb-4 flex items-center gap-2">
                  <BookOpen className="h-5 w-5 text-blue-600" />
                  Quick Navigation
                </h2>
                <ul className="space-y-3">
                  {[
                    { icon: <CheckCircle2 className="h-4 w-4" />, name: "Acceptance of Terms", href: "#acceptance" },
                    { icon: <Users className="h-4 w-4" />, name: "User Accounts", href: "#accounts" },
                    { icon: <Globe className="h-4 w-4" />, name: "Platform Usage", href: "#usage" },
                    { icon: <Shield className="h-4 w-4" />, name: "Intellectual Property", href: "#ip" },
                    { icon: <Map className="h-4 w-4" />, name: "Google Maps Data", href: "#maps-data" },
                    { icon: <LockKeyhole className="h-4 w-4" />, name: "Privacy", href: "#privacy" },
                    { icon: <Settings className="h-4 w-4" />, name: "Modifications", href: "#modifications" },
                    { icon: <AlertTriangle className="h-4 w-4" />, name: "Termination", href: "#termination" },
                    { icon: <Mail className="h-4 w-4" />, name: "Contact Us", href: "#contact" },
                  ].map((item, index) => (
                    <li key={index}>
                      <a 
                        href={item.href} 
                        className="flex items-center gap-2 text-gray-700 hover:text-blue-600 transition-colors"
                      >
                        <span className="text-blue-600">{item.icon}</span>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              
              <div className="bg-gradient-to-r from-blue-500 to-indigo-600 rounded-xl shadow-lg p-6 text-white">
                <h3 className="font-bold mb-2 flex items-center gap-2">
                  <Scale className="h-5 w-5" />
                  Last Updated
                </h3>
                <p className="opacity-90 mb-4">March 2025</p>
                <div className="border-t border-white/30 pt-4 mt-2">
                  <a 
                    href="/privacy" 
                    className="flex items-center gap-2 text-white hover:text-blue-100 transition-colors"
                  >
                    <LockKeyhole className="h-4 w-4" />
                    Privacy Policy
                    <ExternalLink className="h-3 w-3" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          
          <div className="w-full md:w-2/3 px-4">
            <div className="bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="border-b border-gray-100">
                <div className="flex items-center py-3 px-6">
                  <div className="flex space-x-1.5">
                    <div className="w-3 h-3 rounded-full bg-red-500"></div>
                    <div className="w-3 h-3 rounded-full bg-amber-500"></div>
                    <div className="w-3 h-3 rounded-full bg-green-500"></div>
                  </div>
                </div>
              </div>
              
              <div className="p-8 prose prose-blue max-w-none">
                <div id="acceptance" className="mb-12 scroll-mt-32">
                  <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2 mb-4 pb-2 border-b border-gray-100">
                    <CheckCircle2 className="h-6 w-6 text-blue-600" />
                    1. Acceptance of Terms
                  </h2>
                  <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg p-5 border-l-4 border-blue-500 mb-6">
                    <p className="text-gray-700 leading-relaxed">
                      By accessing and using beSportly's services, you acknowledge that you have read, 
                      understood, and agree to be bound by these Terms & Conditions.
                    </p>
                  </div>
                  <p className="text-gray-700 leading-relaxed">
                    These terms constitute a legally binding agreement between you and beSportly regarding 
                    your use of our website, mobile applications, and related services. If you do not agree 
                    with any part of these terms, you must not use our services.
                  </p>
                </div>
                
                <div id="accounts" className="mb-12 scroll-mt-32">
                  <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2 mb-4 pb-2 border-b border-gray-100">
                    <Users className="h-6 w-6 text-blue-600" />
                    2. User Accounts
                  </h2>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    To access certain features of our platform, you must register for an account. You agree to provide 
                    accurate and complete information during registration and to keep this information up to date.
                  </p>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    You are responsible for maintaining the confidentiality of your account credentials and for all 
                    activities that occur under your account. You agree to notify us immediately of any unauthorized 
                    use of your account.
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    We reserve the right to disable your account if we determine, in our sole discretion, that you have 
                    violated these Terms & Conditions.
                  </p>
                </div>
                
                <div id="usage" className="mb-12 scroll-mt-32">
                  <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2 mb-4 pb-2 border-b border-gray-100">
                    <Globe className="h-6 w-6 text-blue-600" />
                    3. Platform Usage
                  </h2>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    You agree to use our platform only for lawful purposes and in accordance with these Terms. 
                    You will not use our platform in any way that violates any applicable laws or regulations.
                  </p>
                  <div className="bg-white border border-gray-200 rounded-lg p-4 mb-4">
                    <h4 className="font-semibold text-gray-900 mb-2">Prohibited Activities include:</h4>
                    <ul className="list-disc pl-6 space-y-1 text-gray-700">
                      <li>Using the platform to engage in fraudulent activities</li>
                      <li>Attempting to interfere with or disrupt the platform or servers</li>
                      <li>Introducing malware, viruses, or harmful materials</li>
                      <li>Impersonating another person or entity</li>
                      <li>Engaging in any activity that could damage, disable, or impair the platform</li>
                    </ul>
                  </div>
                  <p className="text-gray-700 leading-relaxed">
                    We reserve the right to monitor your use of our platform to ensure compliance with 
                    these Terms & Conditions.
                  </p>
                </div>
                
                <div id="ip" className="mb-12 scroll-mt-32">
                  <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2 mb-4 pb-2 border-b border-gray-100">
                    <Shield className="h-6 w-6 text-blue-600" />
                    4. Intellectual Property
                  </h2>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    All content, features, and functionality of our platform are owned by beSportly and are protected 
                    by international copyright, trademark, and other intellectual property laws.
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    You may not reproduce, distribute, modify, create derivative works of, publicly display, 
                    publicly perform, republish, download, store, or transmit any of the material on our platform, 
                    except as generally necessary for reasonable and customary use of the platform.
                  </p>
                </div>
                
                <div id="maps-data" className="mb-12 scroll-mt-32">
                  <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2 mb-4 pb-2 border-b border-gray-100">
                    <Map className="h-6 w-6 text-blue-600" />
                    5. Google Maps Data Usage
                  </h2>
                  
                  <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-2">
                      <img 
                        src="/lovable-uploads/fe3707c9-b036-43ac-907c-44fcf93ac4be.png" 
                        alt="Google Maps" 
                        className="h-10 w-auto"
                      />
                      <span className="text-sm text-gray-500">Powered by Google</span>
                    </div>
                  </div>
                  
                  <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg p-5 border-l-4 border-blue-500 mb-6">
                    <p className="text-gray-700 leading-relaxed">
                      Our platform uses the Google Maps Platform and Google Places API to provide location-based 
                      information for non-bookable venues. The use of these services is subject to 
                      <a 
                        href="https://cloud.google.com/maps-platform/terms" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 font-medium mx-1"
                      >
                        Google Maps Platform Terms of Service
                      </a>
                      and 
                      <a 
                        href="https://policies.google.com/privacy" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 font-medium mx-1"
                      >
                        Google Privacy Policy
                      </a>.
                    </p>
                  </div>
                  
                  <h3 className="text-xl font-semibold text-gray-900 mb-3">Non-Bookable Venues Information</h3>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    For certain venues displayed on our platform that are not directly bookable through beSportly 
                    ("non-bookable venues"), we utilize data from the Google Maps Platform and Google Places API 
                    to provide you with location information, directions, and basic details about these venues.
                  </p>
                  
                  <h3 className="text-xl font-semibold text-gray-900 mb-3">Disclaimer of Liability</h3>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    beSportly is not responsible for the accuracy, completeness, timeliness, or reliability of 
                    any information displayed about non-bookable venues. This information is sourced from 
                    third-party services (Google Maps and Google Places API) and is provided "as is" and "as available" 
                    without any warranties of any kind, either express or implied.
                  </p>
                  
                  <h3 className="text-xl font-semibold text-gray-900 mb-3">User Due Diligence</h3>
                  <p className="text-gray-700 leading-relaxed mb-6">
                    You acknowledge and agree that:
                  </p>
                  <ul className="list-disc pl-6 space-y-2 text-gray-700 mb-4">
                    <li>Information about non-bookable venues is provided for informational purposes only</li>
                    <li>It is your responsibility to verify the accuracy of information before visiting any venue</li>
                    <li>beSportly is not affiliated with these non-bookable venues unless explicitly stated</li>
                    <li>Any decisions you make based on this information are made at your own risk</li>
                    <li>You should contact the venue directly to confirm details such as operating hours, 
                      availability, pricing, and any specific requirements</li>
                  </ul>
                  
                  <h3 className="text-xl font-semibold text-gray-900 mb-3">Google Maps Attribution</h3>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    Map data ©2025 Google. Google Maps and the Google Maps logo are registered trademarks of 
                    Google LLC. By using this feature, you also agree to 
                    <a 
                      href="https://www.google.com/intl/en_US/help/terms_maps/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 font-medium mx-1"
                    >
                      Google Maps Terms of Service
                    </a>.
                  </p>
                  
                  <div className="bg-white border border-gray-200 rounded-lg p-4 mb-4">
                    <h4 className="font-semibold text-gray-900 mb-2">Usage Restrictions:</h4>
                    <ul className="list-disc pl-6 space-y-1 text-gray-700">
                      <li>You may not use Google Maps content without Google's permission</li>
                      <li>You may not copy, download, or redistribute Google Maps data</li>
                      <li>You may not modify, obscure, or remove any Google branding, logos, or legal notices</li>
                      <li>Google Maps data may not be used for commercial purposes without proper licensing</li>
                    </ul>
                  </div>
                </div>
                
                <div id="privacy" className="mb-12 scroll-mt-32">
                  <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2 mb-4 pb-2 border-b border-gray-100">
                    <LockKeyhole className="h-6 w-6 text-blue-600" />
                    6. Privacy
                  </h2>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    Your privacy is important to us. Please review our 
                    <a href="/privacy" className="text-blue-600 hover:text-blue-800 font-medium mx-1">Privacy Policy</a>
                    to understand how we collect, use, and protect your personal information.
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    By using our platform, you consent to the collection, use, and sharing of your information as 
                    described in our Privacy Policy.
                  </p>
                </div>
                
                <div id="modifications" className="mb-12 scroll-mt-32">
                  <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2 mb-4 pb-2 border-b border-gray-100">
                    <Settings className="h-6 w-6 text-blue-600" />
                    7. Modifications
                  </h2>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    We reserve the right to modify these Terms & Conditions at any time. We will notify users of 
                    any material changes via email or through our platform.
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    Your continued use of the platform after such modifications constitutes your acceptance of 
                    the updated Terms & Conditions.
                  </p>
                </div>
                
                <div id="termination" className="mb-12 scroll-mt-32">
                  <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2 mb-4 pb-2 border-b border-gray-100">
                    <AlertTriangle className="h-6 w-6 text-blue-600" />
                    8. Termination
                  </h2>
                  <p className="text-gray-700 leading-relaxed mb-4">
                    We reserve the right to terminate or suspend your account and access to our services at our 
                    sole discretion, without notice, for conduct that we believe violates these Terms & Conditions.
                  </p>
                  <p className="text-gray-700 leading-relaxed">
                    Upon termination, your right to use the platform will immediately cease, and you must cease 
                    all use of the platform and delete any copies of platform materials in your possession.
                  </p>
                </div>
                
                <div id="contact" className="scroll-mt-32">
                  <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2 mb-4 pb-2 border-b border-gray-100">
                    <Mail className="h-6 w-6 text-blue-600" />
                    9. Contact Information
                  </h2>
                  <p className="text-gray-700 leading-relaxed mb-6">
                    If you have any questions about these Terms & Conditions, please contact us at:
                  </p>
                  <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-xl p-6 flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <Mail className="h-6 w-6 text-blue-600" />
                      <a 
                        href="mailto:legal@besportly.com" 
                        className="text-lg font-medium text-blue-600 hover:text-blue-800 transition-colors"
                      >
                        legal@besportly.com
                      </a>
                    </div>
                    <div className="animate-pulse">
                      <ExternalLink className="h-5 w-5 text-blue-600" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="mt-8 flex justify-center">
              <button 
                onClick={() => window.history.back()}
                className="flex items-center gap-2 text-gray-600 hover:text-blue-700 transition-colors group"
              >
                <ArrowLeft className="h-5 w-5 group-hover:-translate-x-1 transition-transform" />
                <span>Back to previous page</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
