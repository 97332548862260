import { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ArrowLeft, Pencil, Check, X, Link as LinkIcon, Plus, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useAuth } from "@/components/AuthProvider";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { format } from "date-fns";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { cn } from "@/lib/utils";
import { Label } from "@/components/ui/label";

type EditableField = "name" | "description" | "venue" | "startDate" | "endDate";

type EditingMatch = {
  id: string;
  homeScore: string;
  awayScore: string;
} | null;

const CompetitionDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [editingField, setEditingField] = useState<EditableField | null>(null);
  const [editValue, setEditValue] = useState("");
  const [editingMatch, setEditingMatch] = useState<EditingMatch>(null);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [editingTeam, setEditingTeam] = useState<{ index: number; name: string } | null>(null);
  const [newTeamName, setNewTeamName] = useState("");
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [editDate, setEditDate] = useState<Date | null>(null);
  const [accessEmail, setAccessEmail] = useState("");
  const [showAccessDialog, setShowAccessDialog] = useState(false);

  const isPublicAccess = new URLSearchParams(location.search).has('key');

  const { data: competition, isLoading, error } = useQuery({
    queryKey: ["competition", id],
    queryFn: async () => {
      if (!id) return null;
      const { data, error } = await supabase
        .from("competitions")
        .select("*")
        .eq("id", id)
        .single();

      if (error) throw error;
      return data;
    },
    enabled: !!id
  });

  const updateCompetitionMutation = useMutation({
    mutationFn: async (updates: any) => {
      const { error } = await supabase
        .from("competitions")
        .update(updates)
        .eq("id", id);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["competition", id] });
      toast({
        description: "Competition updated successfully",
      });
      setEditingField(null);
      setEditingTeam(null);
      setIsAddingTeam(false);
      setEditDate(null);
    },
    onError: () => {
      toast({
        variant: "destructive",
        description: "Failed to update competition",
      });
    },
  });

  const { data: accessList, refetch: refetchAccess } = useQuery({
    queryKey: ["competition-access", id],
    queryFn: async () => {
      if (!id) return [];
      const { data, error } = await supabase
        .from("competition_access")
        .select("*")
        .eq("competition_id", id);

      if (error) throw error;
      return data;
    },
    enabled: !!id && !!user
  });

  const grantAccessMutation = useMutation({
    mutationFn: async (email: string) => {
      const { error } = await supabase
        .from("competition_access")
        .insert({
          competition_id: id,
          email: email.toLowerCase(),
          granted_by: user?.id
        });

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["competition-access", id] });
      toast({
        description: "Access granted successfully",
      });
      setAccessEmail("");
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: "Failed to grant access: " + error.message,
      });
    },
  });

  const revokeAccessMutation = useMutation({
    mutationFn: async (accessId: string) => {
      const { error } = await supabase
        .from("competition_access")
        .delete()
        .eq("id", accessId);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["competition-access", id] });
      toast({
        description: "Access revoked successfully",
      });
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        description: "Failed to revoke access: " + error.message,
      });
    },
  });

  const handleGrantAccess = (e: React.FormEvent) => {
    e.preventDefault();
    if (!accessEmail.trim()) return;
    grantAccessMutation.mutate(accessEmail.trim());
  };

  const generateMatchesForNewTeam = (existingTeams: string[], newTeam: string) => {
    const newMatches: any[] = [];
    const settings = competition?.settings as Record<string, any>;
    const currentMatches = settings.matches || [];
    
    existingTeams.forEach((existingTeam) => {
      newMatches.push({
        id: `match-${crypto.randomUUID()}`,
        homeTeam: newTeam,
        awayTeam: existingTeam,
        homeScore: null,
        awayScore: null,
        round: 1,
      });

      if (settings.homeAndAway) {
        newMatches.push({
          id: `match-${crypto.randomUUID()}`,
          homeTeam: existingTeam,
          awayTeam: newTeam,
          homeScore: null,
          awayScore: null,
          round: 2,
        });
      }
    });

    return [...currentMatches, ...newMatches];
  };

  const handleAddTeam = async () => {
    if (!competition?.settings || !newTeamName.trim()) return;
    
    const settings = competition.settings as Record<string, any>;
    const updatedTeams = [...settings.teams, newTeamName];
    const updatedMatches = generateMatchesForNewTeam(settings.teams, newTeamName);

    try {
      await updateCompetitionMutation.mutateAsync({
        settings: {
          ...settings,
          teams: updatedTeams,
          matches: updatedMatches,
        },
      });
      setNewTeamName("");
      setIsAddingTeam(false);
    } catch (error) {
      console.error("Error adding team:", error);
    }
  };

  const handleTeamNameUpdate = async (index: number, newName: string) => {
    if (!competition?.settings) return;
    
    const settings = competition.settings as Record<string, any>;
    const updatedTeams = [...settings.teams];
    const oldName = updatedTeams[index];
    updatedTeams[index] = newName;

    const updatedMatches = settings.matches.map((match: any) => ({
      ...match,
      homeTeam: match.homeTeam === oldName ? newName : match.homeTeam,
      awayTeam: match.awayTeam === oldName ? newName : match.awayTeam,
    }));

    await updateCompetitionMutation.mutateAsync({
      settings: {
        ...settings,
        teams: updatedTeams,
        matches: updatedMatches,
      },
    });
  };

  const handleScoreUpdate = async (match: any) => {
    if (!editingMatch || !competition?.id) return;
    
    try {
      const settings = competition.settings as Record<string, any>;
      const matches = settings.matches || [];
      const updatedMatches = matches.map((m: any) =>
        m.id === match.id ? {
          ...m,
          homeScore: editingMatch.homeScore === '' ? null : parseInt(editingMatch.homeScore),
          awayScore: editingMatch.awayScore === '' ? null : parseInt(editingMatch.awayScore),
        } : m
      );

      const updatedSettings = {
        ...settings,
        matches: updatedMatches,
      };

      await updateCompetitionMutation.mutateAsync({
        settings: updatedSettings,
      });
      
      setEditingMatch(null);
    } catch (error) {
      console.error("Error updating match:", error);
      toast({
        variant: "destructive",
        description: "Failed to update match result",
      });
    }
  };

  const handleDateUpdate = async (field: "startDate" | "endDate", date: Date) => {
    await updateCompetitionMutation.mutateAsync({
      [field]: date.toISOString(),
    });
  };

  const canEdit = () => {
    if (!user || !competition || isPublicAccess) return false;
    return user.id === competition.created_by;
  };

  const getShareableUrl = () => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/competitions/${id}?key=${competition?.public_view_key}`;
  };

  const calculateLeagueStats = (matches: any[]): any[] => {
    if (!competition?.settings || competition.type !== 'league') return [];
    const teams = (competition.settings as any).teams as string[];
    
    const stats: Record<string, any> = {};
    teams.forEach(team => {
      stats[team] = {
        name: team,
        played: 0,
        won: 0,
        drawn: 0,
        lost: 0,
        goalsFor: 0,
        goalsAgainst: 0,
        points: 0,
      };
    });

    matches.forEach(match => {
      if (match.homeScore === null || match.awayScore === null) return;

      const homeTeam = stats[match.homeTeam];
      const awayTeam = stats[match.awayTeam];

      homeTeam.played++;
      awayTeam.played++;
      homeTeam.goalsFor += match.homeScore;
      homeTeam.goalsAgainst += match.awayScore;
      awayTeam.goalsFor += match.awayScore;
      awayTeam.goalsAgainst += match.homeScore;

      if (match.homeScore > match.awayScore) {
        homeTeam.won++;
        awayTeam.lost++;
        homeTeam.points += (competition.settings as any).points.win;
        awayTeam.points += (competition.settings as any).points.loss;
      } else if (match.homeScore < match.awayScore) {
        awayTeam.won++;
        homeTeam.lost++;
        awayTeam.points += (competition.settings as any).points.win;
        homeTeam.points += (competition.settings as any).points.loss;
      } else {
        homeTeam.drawn++;
        awayTeam.drawn++;
        homeTeam.points += (competition.settings as any).points.draw;
        awayTeam.points += (competition.settings as any).points.draw;
      }
    });

    return Object.values(stats).sort((a, b) => b.points - a.points);
  };

  if (isLoading) {
    return <div className="min-h-screen bg-white pt-24 pb-12 px-4">Loading...</div>;
  }

  if (error) {
    return (
      <div className="min-h-screen bg-white pt-24 pb-12 px-4">
        <Alert variant="destructive">
          <AlertDescription>Error loading competition</AlertDescription>
        </Alert>
      </div>
    );
  }

  if (!competition) {
    return (
      <div className="min-h-screen bg-white pt-24 pb-12 px-4">
        <Alert>
          <AlertDescription>Competition not found</AlertDescription>
        </Alert>
      </div>
    );
  }

  const settings = competition.settings as Record<string, any>;

  return (
    <div className="min-h-screen bg-white pt-24 pb-12 px-4">
      <div className="max-w-7xl mx-auto">
        <Button
          variant="ghost"
          className="mb-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back
        </Button>

        <div className="space-y-6">
          <div>
            {competition.image_url && (
              <img
                src={competition.image_url}
                alt={competition.name}
                className="w-full h-48 object-cover rounded-lg mb-6"
              />
            )}
            
            {editingField === "name" ? (
              <div className="flex items-center gap-2">
                <Input
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  className="text-3xl font-bold"
                />
                <Button size="icon" onClick={() => updateCompetitionMutation.mutate({ name: editValue })}>
                  <Check className="h-4 w-4" />
                </Button>
                <Button size="icon" variant="ghost" onClick={() => setEditingField(null)}>
                  <X className="h-4 w-4" />
                </Button>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <h1 className="text-3xl font-bold">{competition.name}</h1>
                {canEdit() && (
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => {
                      setEditingField("name");
                      setEditValue(competition.name);
                    }}
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                )}
              </div>
            )}

            {editingField === "description" ? (
              <div className="flex items-center gap-2 mt-2">
                <Input
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  placeholder="Add a description"
                />
                <Button size="icon" onClick={() => updateCompetitionMutation.mutate({ description: editValue })}>
                  <Check className="h-4 w-4" />
                </Button>
                <Button size="icon" variant="ghost" onClick={() => setEditingField(null)}>
                  <X className="h-4 w-4" />
                </Button>
              </div>
            ) : (
              <div className="flex items-center gap-2 mt-2">
                <p className="text-gray-600">{competition.description || "No description"}</p>
                {canEdit() && (
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => {
                      setEditingField("description");
                      setEditValue(competition.description || "");
                    }}
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                )}
              </div>
            )}

            <div className="flex items-center gap-4 mt-4">
              <div>
                <label className="text-sm text-gray-500">Start Date</label>
                <div className="flex items-center gap-2">
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "justify-start text-left font-normal",
                          !competition.start_date && "text-muted-foreground"
                        )}
                        disabled={!canEdit()}
                      >
                        {competition.start_date ? format(new Date(competition.start_date), "PPP") : "Pick a date"}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={new Date(competition.start_date)}
                        onSelect={(date) => date && handleDateUpdate("startDate", date)}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
              <div>
                <label className="text-sm text-gray-500">End Date</label>
                <div className="flex items-center gap-2">
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "justify-start text-left font-normal",
                          !competition.end_date && "text-muted-foreground"
                        )}
                        disabled={!canEdit()}
                      >
                        {competition.end_date ? format(new Date(competition.end_date), "PPP") : "Pick a date"}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={new Date(competition.end_date)}
                        onSelect={(date) => date && handleDateUpdate("endDate", date)}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
            </div>

            {editingField === "venue" ? (
              <div className="flex items-center gap-2 mt-4">
                <Input
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  placeholder="Enter venue"
                />
                <Button 
                  size="icon" 
                  onClick={() => updateCompetitionMutation.mutate({ 
                    settings: { ...settings, venue: editValue } 
                  })}
                >
                  <Check className="h-4 w-4" />
                </Button>
                <Button size="icon" variant="ghost" onClick={() => setEditingField(null)}>
                  <X className="h-4 w-4" />
                </Button>
              </div>
            ) : (
              <div className="flex items-center gap-2 mt-4">
                <p className="text-gray-600">
                  Venue: {settings.venue || "No venue specified"}
                </p>
                {canEdit() && (
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => {
                      setEditingField("venue");
                      setEditValue(settings.venue || "");
                    }}
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                )}
              </div>
            )}
          </div>

          {competition.type === "league" && (
            <div className="mt-6 space-y-8">
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold">League Details</h3>
                  <Button
                    variant="outline"
                    onClick={() => setShowShareDialog(true)}
                    className="flex items-center gap-2"
                  >
                    <LinkIcon className="h-4 w-4" />
                    Share League
                  </Button>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg space-y-4">
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <h4 className="font-medium">Teams ({settings.teams.length})</h4>
                      {canEdit() && !isAddingTeam && (
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setIsAddingTeam(true)}
                          className="flex items-center gap-2"
                        >
                          <Plus className="h-4 w-4" />
                          Add Team
                        </Button>
                      )}
                    </div>
                    
                    {isAddingTeam && (
                      <div className="flex items-center gap-2 mb-4">
                        <Input
                          value={newTeamName}
                          onChange={(e) => setNewTeamName(e.target.value)}
                          placeholder="Enter team name"
                        />
                        <Button size="sm" onClick={handleAddTeam}>
                          <Check className="h-4 w-4" />
                        </Button>
                        <Button size="sm" variant="ghost" onClick={() => setIsAddingTeam(false)}>
                          <X className="h-4 w-4" />
                        </Button>
                      </div>
                    )}

                    <ul className="space-y-2">
                      {settings.teams.map((team: string, index: number) => (
                        <li key={index} className="flex items-center justify-between">
                          {editingTeam?.index === index ? (
                            <div className="flex items-center gap-2 flex-1">
                              <Input
                                value={editingTeam.name}
                                onChange={(e) => setEditingTeam({ ...editingTeam, name: e.target.value })}
                              />
                              <Button size="sm" onClick={() => handleTeamNameUpdate(index, editingTeam.name)}>
                                <Check className="h-4 w-4" />
                              </Button>
                              <Button size="sm" variant="ghost" onClick={() => setEditingTeam(null)}>
                                <X className="h-4 w-4" />
                              </Button>
                            </div>
                          ) : (
                            <>
                              <span>{team}</span>
                              {canEdit() && (
                                <Button
                                  size="icon"
                                  variant="ghost"
                                  onClick={() => setEditingTeam({ index, name: team })}
                                >
                                  <Pencil className="h-4 w-4" />
                                </Button>
                              )}
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-medium mb-2">Format</h4>
                    <p>{settings.homeAndAway ? 'Home and Away' : 'Single Round'}</p>
                  </div>

                  <div>
                    <h4 className="font-medium mb-2">Points System</h4>
                    <div className="grid grid-cols-3 gap-4">
                      <div>
                        <p className="text-sm text-gray-600">Win</p>
                        <p className="font-bold">{settings.points.win}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-600">Draw</p>
                        <p className="font-bold">{settings.points.draw}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-600">Loss</p>
                        <p className="font-bold">{settings.points.loss}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-4">League Table</h3>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Position</TableHead>
                      <TableHead>Team</TableHead>
                      <TableHead>Played</TableHead>
                      <TableHead>Won</TableHead>
                      <TableHead>Drawn</TableHead>
                      <TableHead>Lost</TableHead>
                      <TableHead>GF</TableHead>
                      <TableHead>GA</TableHead>
                      <TableHead>Points</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {calculateLeagueStats(settings.matches || []).map((team, index) => (
                      <TableRow key={team.name}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell className="font-medium">{team.name}</TableCell>
                        <TableCell>{team.played}</TableCell>
                        <TableCell>{team.won}</TableCell>
                        <TableCell>{team.drawn}</TableCell>
                        <TableCell>{team.lost}</TableCell>
                        <TableCell>{team.goalsFor}</TableCell>
                        <TableCell>{team.goalsAgainst}</TableCell>
                        <TableCell className="font-bold">{team.points}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-4">Match Schedule</h3>
                <div className="space-y-4">
                  {(settings.matches || []).map((match: any) => (
                    <div key={match.id} className="bg-white p-4 rounded-lg border">
                      <div className="flex items-center justify-between">
                        <div className="flex-1 text-right">{match.homeTeam}</div>
                        <div className="flex items-center space-x-4 mx-4">
                          <Input
                            type="number"
                            className="w-16 text-center"
                            value={
                              editingMatch?.id === match.id
                                ? editingMatch.homeScore
                                : match.homeScore ?? ''
                            }
                            onChange={(e) => {
                              if (!canEdit()) return;
                              setEditingMatch({
                                id: match.id,
                                homeScore: e.target.value,
                                awayScore: editingMatch?.id === match.id 
                                  ? editingMatch.awayScore 
                                  : match.awayScore?.toString() ?? ''
                              });
                            }}
                            disabled={!canEdit()}
                          />
                          <span className="text-gray-500">vs</span>
                          <Input
                            type="number"
                            className="w-16 text-center"
                            value={
                              editingMatch?.id === match.id
                                ? editingMatch.awayScore
                                : match.awayScore ?? ''
                            }
                            onChange={(e) => {
                              if (!canEdit()) return;
                              setEditingMatch({
                                id: match.id,
                                homeScore: editingMatch?.id === match.id 
                                  ? editingMatch.homeScore 
                                  : match.homeScore?.toString() ?? '',
                                awayScore: e.target.value
                              });
                            }}
                            disabled={!canEdit()}
                          />
                          {editingMatch?.id === match.id ? (
                            <div className="flex gap-2">
                              <Button size="sm" onClick={() => handleScoreUpdate(match)}>
                                <Check className="h-4 w-4" />
                              </Button>
                              <Button size="sm" variant="ghost" onClick={() => setEditingMatch(null)}>
                                <X className="h-4 w-4" />
                              </Button>
                            </div>
                          ) : (
                            canEdit() && (
                              <Button 
                                variant="ghost" 
                                size="sm"
                                onClick={() => {
                                  setEditingMatch({
                                    id: match.id,
                                    homeScore: match.homeScore?.toString() ?? '',
                                    awayScore: match.awayScore?.toString() ?? ''
                                  });
                                }}
                              >
                                <Pencil className="h-4 w-4" />
                              </Button>
                            )
                          )}
                        </div>
                        <div className="flex-1">{match.awayTeam}</div>
                      </div>
                      <div className="text-sm text-gray-500 text-center mt-2">
                        Round {match.round}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {user && user.id === competition?.created_by && (
            <div className="mt-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Access Management</h3>
                <Button
                  variant="outline"
                  onClick={() => setShowAccessDialog(true)}
                  className="flex items-center gap-2"
                >
                  <Plus className="h-4 w-4" />
                  Grant Access
                </Button>
              </div>
              
              {accessList && accessList.length > 0 ? (
                <div className="space-y-2">
                  {accessList.map((access) => (
                    <div key={access.id} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                      <span>{access.email}</span>
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => revokeAccessMutation.mutate(access.id)}
                        className="text-red-600 hover:text-red-700 hover:bg-red-50"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500 text-sm">No additional users have been granted access.</p>
              )}
            </div>
          )}
        </div>
      </div>

      <Dialog open={showShareDialog} onOpenChange={setShowShareDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Share League</DialogTitle>
            <DialogDescription>
              Anyone with this link can view the league details
            </DialogDescription>
          </DialogHeader>
          <div className="flex items-center gap-2">
            <Input
              readOnly
              value={getShareableUrl()}
              onClick={(e) => (e.target as HTMLInputElement).select()}
            />
            <Button
              onClick={() => {
                navigator.clipboard.writeText(getShareableUrl());
                toast({
                  description: "Link copied to clipboard",
                });
              }}
            >
              Copy
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={showAccessDialog} onOpenChange={setShowAccessDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Grant Competition Access</DialogTitle>
            <DialogDescription>
              Enter an email address to grant edit access to this competition. The user must have an account or create one with this email to access it.
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleGrantAccess} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="access-email">Email Address</Label>
              <Input
                id="access-email"
                type="email"
                value={accessEmail}
                onChange={(e) => setAccessEmail(e.target.value)}
                placeholder="Enter email address"
              />
            </div>
            <div className="flex justify-end gap-2">
              <Button type="button" variant="outline" onClick={() => setShowAccessDialog(false)}>
                Cancel
              </Button>
              <Button type="submit">
                Grant Access
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CompetitionDetail;
