import { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import { format } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Scale, Pencil, Check, X, Trash2 } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";

interface WeightLog {
  id: string;
  weight: number;
  log_date: string;
  notes: string | null;
}

export const WeightHistory = ({ onWeightUpdated }: { onWeightUpdated: () => void }) => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [weights, setWeights] = useState<WeightLog[]>([]);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editForm, setEditForm] = useState({
    weight: "",
    notes: "",
  });

  useEffect(() => {
    if (!user) return;
    fetchWeights();
  }, [user]);

  const fetchWeights = async () => {
    const { data } = await supabase
      .from("weight_logs")
      .select("*")
      .eq("user_id", user?.id)
      .order("log_date", { ascending: false });

    if (data) {
      setWeights(data);
    }
  };

  const startEditing = (log: WeightLog) => {
    setEditingId(log.id);
    setEditForm({
      weight: log.weight.toString(),
      notes: log.notes || "",
    });
  };

  const handleUpdate = async (id: string) => {
    try {
      const { error } = await supabase
        .from("weight_logs")
        .update({
          weight: parseFloat(editForm.weight),
          notes: editForm.notes,
        })
        .eq("id", id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Weight log updated successfully!",
      });

      setEditingId(null);
      fetchWeights();
      onWeightUpdated();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update weight log. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const { error } = await supabase
        .from("weight_logs")
        .delete()
        .eq("id", id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Weight log deleted successfully!",
      });

      fetchWeights();
      onWeightUpdated();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete weight log. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <Scale className="w-6 h-6 text-purple-500" />
        <h3 className="text-lg font-semibold">Weight History</h3>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Date</TableHead>
              <TableHead>Weight (kg)</TableHead>
              <TableHead>Notes</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {weights.map((log) => (
              <TableRow key={log.id}>
                <TableCell>
                  {format(new Date(log.log_date), "MMM dd, yyyy HH:mm")}
                </TableCell>
                <TableCell>
                  {editingId === log.id ? (
                    <Input
                      type="number"
                      step="0.1"
                      value={editForm.weight}
                      onChange={(e) =>
                        setEditForm({ ...editForm, weight: e.target.value })
                      }
                      className="w-24"
                    />
                  ) : (
                    log.weight
                  )}
                </TableCell>
                <TableCell>
                  {editingId === log.id ? (
                    <Input
                      value={editForm.notes || ""}
                      onChange={(e) =>
                        setEditForm({ ...editForm, notes: e.target.value })
                      }
                    />
                  ) : (
                    log.notes || "-"
                  )}
                </TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    {editingId === log.id ? (
                      <>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => handleUpdate(log.id)}
                        >
                          <Check className="w-4 h-4 text-green-500" />
                        </Button>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => setEditingId(null)}
                        >
                          <X className="w-4 h-4 text-red-500" />
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => startEditing(log)}
                        >
                          <Pencil className="w-4 h-4 text-gray-500" />
                        </Button>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => handleDelete(log.id)}
                          className="hover:bg-red-50"
                        >
                          <Trash2 className="w-4 h-4 text-red-500" />
                        </Button>
                      </>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};