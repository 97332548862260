
import React, { useState, useRef, useEffect } from "react";
import { X, ArrowLeft, ArrowRight, ExternalLink, RotateCw, AlertCircle } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/hooks/use-mobile";

interface InAppBrowserProps {
  url: string;
  isOpen: boolean;
  onClose: () => void;
}

const InAppBrowser = ({ url, isOpen, onClose }: InAppBrowserProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const isMobile = useIsMobile();
  const [title, setTitle] = useState("Loading...");
  
  const handleLoad = () => {
    setLoading(false);
    setError(null);
    try {
      // Try to get the title from the iframe
      const iframeDocument = iframeRef.current?.contentDocument;
      if (iframeDocument) {
        setTitle(iframeDocument.title || new URL(url).hostname);
      }
    } catch (error) {
      // Security restrictions might prevent accessing iframe content
      try {
        setTitle(new URL(url).hostname);
      } catch (e) {
        setTitle("Article View");
      }
    }
  };
  
  const handleError = () => {
    setLoading(false);
    try {
      const hostname = new URL(url).hostname;
      setError(`${hostname} refused to connect. Try opening in external browser.`);
    } catch (e) {
      setError("Website refused to connect. Try opening in external browser.");
    }
  };
  
  const handleRefresh = () => {
    setLoading(true);
    setError(null);
    if (iframeRef.current) {
      iframeRef.current.src = url;
    }
  };
  
  const handleBack = () => {
    try {
      if (iframeRef.current?.contentWindow) {
        iframeRef.current.contentWindow.history.back();
      }
    } catch (error) {
      console.error("Cannot access iframe history:", error);
    }
  };
  
  const handleForward = () => {
    try {
      if (iframeRef.current?.contentWindow) {
        iframeRef.current.contentWindow.history.forward();
      }
    } catch (error) {
      console.error("Cannot access iframe history:", error);
    }
  };
  
  const openExternal = () => {
    window.open(url, "_blank");
  };
  
  // Reset loading state and update iframe src when URL changes
  useEffect(() => {
    if (isOpen && url) {
      setLoading(true);
      setError(null);
      setTitle("Loading...");
      
      // Small delay to ensure the component is properly mounted
      const timer = setTimeout(() => {
        if (iframeRef.current) {
          iframeRef.current.src = url;
        }
      }, 100);
      
      return () => clearTimeout(timer);
    }
  }, [url, isOpen]);
  
  if (!isMobile) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <Sheet open={isOpen} onOpenChange={onClose}>
          <SheetContent 
            side="bottom" 
            className="h-[92vh] p-0 rounded-t-3xl border-t-0 overflow-hidden"
            hideCloseButton
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex flex-col h-full bg-white"
            >
              {/* Browser Header */}
              <div className="relative flex items-center justify-between p-3 border-b border-gray-100 bg-gradient-to-r from-purple-50 to-pink-50">
                {/* Handle for dragging */}
                <div className="absolute top-1 left-1/2 transform -translate-x-1/2 w-12 h-1.5 bg-gradient-to-r from-purple-400 to-pink-400 rounded-full opacity-70" />
                
                <div className="flex items-center gap-2 pt-2">
                  <Button 
                    variant="ghost" 
                    size="icon" 
                    className="h-8 w-8 rounded-full bg-white/80 backdrop-blur-sm shadow-sm" 
                    onClick={onClose}
                  >
                    <X className="h-4 w-4 text-gray-700" />
                  </Button>
                  
                  <div className="flex gap-1">
                    <Button 
                      variant="ghost" 
                      size="icon" 
                      className="h-8 w-8 rounded-full bg-white/80 backdrop-blur-sm shadow-sm"
                      onClick={handleBack}
                    >
                      <ArrowLeft className="h-4 w-4 text-gray-700" />
                    </Button>
                    <Button 
                      variant="ghost" 
                      size="icon" 
                      className="h-8 w-8 rounded-full bg-white/80 backdrop-blur-sm shadow-sm"
                      onClick={handleForward}
                    >
                      <ArrowRight className="h-4 w-4 text-gray-700" />
                    </Button>
                  </div>
                </div>
                
                <div className="flex-1 mx-2 px-3 py-1.5 text-sm font-medium text-gray-800 bg-white/70 rounded-full truncate text-center backdrop-blur-sm">
                  {title}
                </div>
                
                <div className="flex items-center gap-1">
                  <Button 
                    variant="ghost" 
                    size="icon" 
                    className="h-8 w-8 rounded-full bg-white/80 backdrop-blur-sm shadow-sm"
                    onClick={handleRefresh}
                  >
                    <RotateCw className="h-4 w-4 text-gray-700" />
                  </Button>
                  <Button 
                    variant="ghost" 
                    size="icon" 
                    className="h-8 w-8 rounded-full bg-white/80 backdrop-blur-sm shadow-sm"
                    onClick={openExternal}
                  >
                    <ExternalLink className="h-4 w-4 text-gray-700" />
                  </Button>
                </div>
              </div>
              
              {/* Browser Content */}
              <div className="relative flex-1 w-full">
                {loading && (
                  <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-50 z-10">
                    <div className="w-10 h-10 border-4 border-gray-200 border-t-purple-500 rounded-full animate-spin mb-4"></div>
                    <p className="text-sm text-gray-600">Loading content...</p>
                  </div>
                )}
                
                {error && (
                  <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-50 z-10 p-6">
                    <div className="bg-red-50 border border-red-200 rounded-xl p-4 flex items-start gap-3 mb-6 max-w-sm text-center">
                      <AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5" />
                      <p className="text-sm text-red-700">{error}</p>
                    </div>
                    <Button 
                      variant="outline" 
                      onClick={openExternal}
                      className="flex items-center gap-2"
                    >
                      <ExternalLink className="h-4 w-4" />
                      Open in External Browser
                    </Button>
                  </div>
                )}
                
                <iframe
                  ref={iframeRef}
                  className="w-full h-full border-0"
                  onLoad={handleLoad}
                  onError={handleError}
                  title="In-app browser"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                />
              </div>
            </motion.div>
          </SheetContent>
        </Sheet>
      )}
    </AnimatePresence>
  );
};

export default InAppBrowser;
