
const Privacy = () => {
  return (
    <div className="min-h-screen bg-white pt-16 md:pt-36">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="flex items-center gap-4 md:gap-8 mb-8">
          <button 
            onClick={() => window.history.back()}
            className="inline-flex items-center text-gray-600 hover:text-gray-900 transition-colors md:scale-75 md:-ml-4"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-5 w-5 mr-2" 
              viewBox="0 0 20 20" 
              fill="currentColor"
            >
              <path 
                fillRule="evenodd" 
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L4.414 9H17a1 1 0 110 2H4.414l5.293 5.293a1 1 0 010 1.414z" 
                clipRule="evenodd" 
              />
            </svg>
            Back
          </button>
          <h1 className="text-4xl font-bold text-gray-900">Privacy Policy</h1>
        </div>
        <div className="prose prose-purple max-w-none">
          <div className="bg-gray-50 border border-gray-200 rounded-lg p-6 mb-8">
            <p className="text-gray-600 mb-2">
              This Privacy Policy is maintained by:
            </p>
            <h3 className="text-xl font-semibold text-gray-900 mb-2">beSportly Technologies Private Limited</h3>
            <p className="text-gray-600">
              Registered Office: Andheri East, Mumbai, India<br />
              Email: social@besportly.com<br />
              Phone: +91 92204 40441
            </p>
          </div>

          <p className="text-lg text-gray-600 mb-6">
            At beSportly Technologies Private Limited (referred to as "beSportly", "we", "us", or "our"), we prioritize the protection of your privacy and personal information. This Privacy Policy explains our practices regarding the collection, use, disclosure, and safeguarding of your information when you use our services.
          </p>

          <div className="bg-gradient-to-r from-purple-50 to-indigo-50 border-l-4 border-purple-500 rounded-lg p-6 mb-8 shadow-sm">
            <div className="flex flex-col md:flex-row md:items-center gap-4 mb-4">
              <div className="bg-purple-600 text-white p-3 rounded-full h-14 w-14 flex items-center justify-center flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-8 w-8">
                  <path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z"></path>
                  <path d="m9 12 2 2 4-4"></path>
                </svg>
              </div>
              <div>
                <h3 className="text-xl font-bold text-purple-900 mb-1">Our Privacy Commitment to You</h3>
                <p className="text-purple-800 text-lg">
                  We believe your personal information belongs to you, not us.
                </p>
              </div>
            </div>
            <div className="space-y-4">
              <p className="text-gray-700 leading-relaxed">
                When you register with beSportly, we make these promises to you:
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="bg-white p-4 rounded-lg border border-purple-100 flex items-start gap-3">
                  <div className="rounded-full bg-purple-100 p-2 mt-0.5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-purple-700">
                      <path d="M21 10V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l2-1.14"></path>
                      <path d="M16.5 9.4 7.55 4.24"></path>
                      <polyline points="3.29 7 12 12 20.71 7"></polyline>
                      <line x1="12" y1="22" x2="12" y2="12"></line>
                      <circle cx="18.5" cy="15.5" r="2.5"></circle>
                      <path d="M20.27 17.27 22 19"></path>
                    </svg>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-900">Email Privacy</h4>
                    <p className="text-sm text-gray-600">Your email address will always be kept private and will never be shared with third parties without your explicit consent.</p>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-lg border border-purple-100 flex items-start gap-3">
                  <div className="rounded-full bg-purple-100 p-2 mt-0.5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-purple-700">
                      <path d="m18 16 4-4-4-4"></path>
                      <path d="m6 8-4 4 4 4"></path>
                      <path d="m14.5 4-5 16"></path>
                    </svg>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-900">No Ad Tracking</h4>
                    <p className="text-sm text-gray-600">We do not track app interactions for advertising purposes without your explicit consent.</p>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-lg border border-purple-100 flex items-start gap-3">
                  <div className="rounded-full bg-purple-100 p-2 mt-0.5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-purple-700">
                      <path d="M20 10c0-4.4-3.6-8-8-8s-8 3.6-8 8 3.6 8 8 8h8"></path>
                      <path d="M15 13.5a4.5 4.5 0 1 0 9 0 4.5 4.5 0 1 0-9 0Z"></path>
                      <path d="M20.25 8.5H17v-3H19A5 5 0 0 1 22 9.8c0 .5-.04.99-.12 1.45"></path>
                    </svg>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-900">Minimal Data Collection</h4>
                    <p className="text-sm text-gray-600">We limit data collection to only what's necessary for providing our services.</p>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-lg border border-purple-100 flex items-start gap-3 md:col-span-2">
                  <div className="rounded-full bg-purple-100 p-2 mt-0.5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-purple-700">
                      <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                  </div>
                  <div>
                    <h4 className="font-semibold text-gray-900">Phone Number Privacy</h4>
                    <p className="text-sm text-gray-600">We will never share your phone number with third parties, call you in an unsolicited manner, or share your personal contact data with any external organizations.</p>
                  </div>
                </div>
              </div>
              <div className="mt-4 bg-purple-100 p-4 rounded-lg border border-purple-200">
                <p className="text-sm text-purple-800 font-medium">
                  You maintain full control over your personal information, with the ability to update or delete it at any time.
                </p>
              </div>
            </div>
          </div>

          <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">1. Information We Collect</h2>
          <p className="text-gray-600 mb-4">
            We collect the following types of information:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li><strong>Account Information:</strong> Email address, name, phone number, and profile information when you create an account</li>
            <li><strong>Usage Data:</strong> Information about how you use our services, including activity logs, preferences, and booking history</li>
            <li><strong>Device Information:</strong> Device type, operating system, unique device identifiers (such as device ID and other similar identifiers) used for security and authentication purposes, and mobile network information</li>
            <li><strong>Location Information:</strong> Approximate location based on IP address and precise location when explicitly granted permission</li>
            <li><strong>Payment Information:</strong> Transaction details, payment method information (processed securely through our payment partners)</li>
            <li><strong>Health and Fitness Data:</strong> Any fitness-related information you choose to input, such as weight, exercise logs, and meal tracking data</li>
          </ul>
          
          <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">2. How We Use Your Information</h2>
          <p className="text-gray-600 mb-4">
            We use your information to:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Provide, maintain, and improve our services</li>
            <li>Process your bookings, payments, and refunds</li>
            <li>Personalize your experience and deliver content relevant to your interests</li>
            <li>Send you important service updates and notifications</li>
            <li>Analyze usage patterns to enhance our platform</li>
            <li>Protect against fraudulent or unauthorized activity</li>
            <li>Comply with legal obligations</li>
          </ul>
          
          <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">3. Data Storage and Security</h2>
          <p className="text-gray-600 mb-4">
            We implement industry-standard security measures to protect your data:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Encryption of data in transit using TLS and at rest using AES-256</li>
            <li>Regular security assessments and penetration testing</li>
            <li>Secure access controls and multi-factor authentication mechanisms</li>
            <li>Data backup and disaster recovery procedures</li>
            <li>Regular security training for our team members</li>
          </ul>
          
          <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">4. Information Sharing</h2>
          <p className="text-gray-600 mb-4">
            We do not sell your personal information to third parties. We may share your information in the following circumstances:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>With service providers who assist in operating our platform (payment processors, cloud service providers, analytics services)</li>
            <li>With sports facilities and coaches when you make a booking</li>
            <li>When required by law or to protect our rights</li>
            <li>In connection with a business merger, sale, or acquisition</li>
            <li>With your explicit consent</li>
          </ul>
          
          <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">5. Data Retention and Deletion</h2>
          <p className="text-gray-600 mb-4">
            We retain your information for as long as your account is active or as needed to provide you services. You can request deletion of your account and associated data at any time through our platform or by contacting our privacy team. Upon receiving a deletion request:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>We will delete your personal information within 30 days</li>
            <li>Backup copies may be retained for up to 90 days</li>
            <li>Some information may be retained for legal compliance</li>
            <li>Anonymized data may be retained for analytics purposes</li>
          </ul>
          
          <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">6. Your Rights and Choices</h2>
          <p className="text-gray-600 mb-4">
            You have the right to:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Access and update your personal information</li>
            <li>Request deletion of your data</li>
            <li>Opt-out of marketing communications</li>
            <li>Control app permissions (camera, location, etc.)</li>
            <li>Export your data in a portable format</li>
            <li>Object to processing of your personal data</li>
            <li>Lodge a complaint with a supervisory authority</li>
          </ul>
          
          <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">7. Children's Privacy</h2>
          <p className="text-gray-600 mb-4">
            Our services are not intended for children under 13 years of age. We do not knowingly collect or maintain information from children under 13. If we learn that we have collected personal information from a child under 13, we will take steps to delete such information promptly.
          </p>
          
          <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">8. Third-Party Services</h2>
          <p className="text-gray-600 mb-4">
            Our service may contain links to third-party websites or services. We are not responsible for the privacy practices of these third parties. We encourage you to read their privacy policies before providing any information to them.
          </p>
          
          <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">9. Updates to This Policy</h2>
          <p className="text-gray-600 mb-4">
            We may update this Privacy Policy from time to time. We will notify you of any material changes by:
          </p>
          <ul className="list-disc pl-6 mb-4 text-gray-600">
            <li>Posting the new Privacy Policy on this page</li>
            <li>Updating the "Last Updated" date at the top of this page</li>
            <li>Sending you an email notification</li>
          </ul>
          <p className="text-gray-600 mb-4">
            Your continued use of our services after such modifications constitutes your acknowledgment of the modified Privacy Policy.
          </p>
          
          <h2 className="text-2xl font-semibold text-gray-900 mt-8 mb-4">10. Contact Us</h2>
          <p className="text-gray-600 mb-8">
            If you have any questions, concerns, or requests related to this Privacy Policy or our privacy practices, please contact us at:
            <br /><br />
            Email: social@besportly.com<br />
            Phone: +91 92204 40441
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
