
import { useState } from "react";
import { BookOpen, ShoppingBag, Dumbbell, Award, Shield, AlertTriangle, Star } from "lucide-react";
import { 
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { useIsMobile } from "@/hooks/use-mobile";

interface FacilityRule {
  title: string;
  description: string;
  icon?: React.ReactNode;
  highlight?: boolean;
}

interface RentalEquipment {
  name: string;
  price: string;
  available: boolean;
}

interface FacilityRulesProps {
  facilityName: string;
  facilityType: string;
}

export const FacilityRules = ({ facilityName, facilityType }: FacilityRulesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  
  // Determine sport type based on facility name
  const sportType = 
    facilityName.toLowerCase().includes("badminton") ? "badminton" :
    facilityName.toLowerCase().includes("tennis") ? "tennis" :
    facilityName.toLowerCase().includes("pickleball") ? "pickleball" :
    "general";
  
  // Define rental equipment by sport type
  const rentalEquipment: Record<string, RentalEquipment[]> = {
    badminton: [
      { name: "Racket", price: "₹100/session", available: true },
      { name: "Shuttlecocks (3 pcs)", price: "₹150", available: true },
      { name: "Court Shoes", price: "₹150/session", available: false }
    ],
    tennis: [
      { name: "Racket", price: "₹150/session", available: true },
      { name: "Tennis Balls (3 pcs)", price: "₹200", available: true },
      { name: "Tennis Shoes", price: "₹200/session", available: false }
    ],
    pickleball: [
      { name: "Paddle", price: "₹120/session", available: true },
      { name: "Pickleballs (3 pcs)", price: "₹180", available: true }
    ],
    general: []
  };
  
  // Define common rules for all facilities
  const commonRules: FacilityRule[] = [
    {
      title: "General Courtesy",
      description: "Please be respectful to other players and staff. Maintain appropriate noise levels.",
      icon: <Shield className="h-5 w-5 text-purple-500" />
    },
    {
      title: "Refreshments",
      description: "Water, food, and snacks may be available at the facility, either complimentary or for purchase.",
      icon: <AlertTriangle className="h-5 w-5 text-amber-500" />
    },
    {
      title: "Cleanliness",
      description: "Please ensure the court is left clean after use. Dispose of trash in designated bins.",
      icon: <Shield className="h-5 w-5 text-purple-500" />
    },
    {
      title: "Timings",
      description: "Please arrive 5 minutes before your booking time and vacate the court promptly when your time slot ends.",
      icon: <AlertTriangle className="h-5 w-5 text-amber-500" />
    }
  ];
  
  // Define sport-specific rules
  const sportSpecificRules: Record<string, FacilityRule[]> = {
    badminton: [
      {
        title: "Footwear",
        description: "Non-marking shoes are mandatory. Players without proper footwear may be denied court access.",
        icon: <Shield className="h-5 w-5 text-purple-500" />
      },
      {
        title: "Equipment",
        description: "Players must bring their own rackets or rent from the venue. Shuttlecocks may be available for purchase at the venue.",
        icon: <Dumbbell className="h-5 w-5 text-purple-500" />,
        highlight: true
      },
      {
        title: "Court Etiquette",
        description: "When retrieving shuttles from adjacent courts, wait for play to stop before entering.",
        icon: <Award className="h-5 w-5 text-amber-500" />
      }
    ],
    tennis: [
      {
        title: "Footwear",
        description: "Tennis shoes with non-marking soles are required. Clay or grass courts have specific footwear requirements.",
        icon: <Shield className="h-5 w-5 text-purple-500" />
      },
      {
        title: "Equipment",
        description: "Players must bring their own rackets or rent from the venue. Tennis balls may be available for purchase at the venue.",
        icon: <Dumbbell className="h-5 w-5 text-purple-500" />,
        highlight: true
      },
      {
        title: "Court Maintenance",
        description: "For clay courts, please sweep the court after use if equipment is provided.",
        icon: <Award className="h-5 w-5 text-amber-500" />
      }
    ],
    pickleball: [
      {
        title: "Footwear",
        description: "Non-marking, court-friendly athletic shoes are required.",
        icon: <Shield className="h-5 w-5 text-purple-500" />
      },
      {
        title: "Equipment",
        description: "Players must bring their own paddles or rent from the venue. Pickleballs may be available for purchase.",
        icon: <Dumbbell className="h-5 w-5 text-purple-500" />,
        highlight: true
      },
      {
        title: "Noise Levels",
        description: "Be mindful of noise levels as pickleball paddles can be louder than other racket sports.",
        icon: <AlertTriangle className="h-5 w-5 text-amber-500" />
      }
    ],
    general: [
      {
        title: "Facility Use",
        description: "Use equipment and facilities as intended. Report any damages immediately.",
        icon: <Shield className="h-5 w-5 text-purple-500" />
      },
      {
        title: "Reservation Policy",
        description: "Cancellations must be made at least 24 hours in advance for a refund.",
        icon: <AlertTriangle className="h-5 w-5 text-amber-500" />
      }
    ]
  };
  
  // Combine common and sport-specific rules
  const allRules = [...sportSpecificRules[sportType], ...commonRules];

  // Mobile uses Sheet, desktop uses Dialog
  const RulesContent = () => (
    <div className="space-y-5 max-h-[60vh] overflow-y-auto pr-2">
      <div className="relative overflow-hidden rounded-xl p-5 bg-gradient-to-br from-purple-50 via-pink-50 to-indigo-50 border border-purple-100">
        <div className="absolute -top-10 -right-10 w-40 h-40 bg-gradient-to-bl from-pink-300/20 to-purple-300/20 rounded-full blur-3xl"></div>
        <div className="absolute -bottom-20 -left-20 w-60 h-60 bg-gradient-to-tr from-indigo-300/20 to-purple-300/20 rounded-full blur-3xl"></div>
        <div className="relative">
          <div className="flex items-center mb-4">
            <div className="h-10 w-10 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center shadow-md">
              <ShoppingBag className="h-5 w-5 text-white" />
            </div>
            <h3 className="ml-3 text-lg font-semibold bg-gradient-to-r from-purple-700 to-pink-500 bg-clip-text text-transparent">
              Equipment Rental Available
            </h3>
          </div>
          
          {rentalEquipment[sportType].length > 0 ? (
            <div className="space-y-3">
              <p className="text-sm text-gray-600 mb-3">
                The following sporting equipment is available for rent to enhance your playing experience:
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                {rentalEquipment[sportType].map((item, idx) => (
                  <div 
                    key={idx} 
                    className={`flex items-center justify-between p-3 rounded-lg ${
                      item.available 
                        ? "bg-gradient-to-r from-white/80 to-white/90 border border-green-100 shadow-sm hover:shadow-md transition-shadow duration-300" 
                        : "bg-gray-50/80 border border-gray-200"
                    }`}
                  >
                    <div className="flex items-center">
                      <div className={`h-10 w-10 rounded-full flex items-center justify-center ${
                        item.available 
                          ? "bg-gradient-to-r from-green-100 to-emerald-200" 
                          : "bg-gradient-to-r from-gray-100 to-gray-200"
                      }`}>
                        <Dumbbell className={`h-5 w-5 ${
                          item.available ? "text-green-600" : "text-gray-500"
                        }`} />
                      </div>
                      <span className={`ml-3 font-medium ${
                        item.available ? "text-gray-800" : "text-gray-500"
                      }`}>
                        {item.name}
                      </span>
                    </div>
                    <div className="flex flex-col items-end">
                      <span className={`text-sm ${
                        item.available ? "bg-gradient-to-r from-purple-600 to-pink-500 bg-clip-text text-transparent font-semibold" : "text-gray-500"
                      }`}>
                        {item.price}
                      </span>
                      <span className={`text-xs ${
                        item.available ? "text-green-600" : "text-red-500"
                      } flex items-center`}>
                        {item.available ? (
                          <>
                            <div className="w-2 h-2 rounded-full bg-green-400 mr-1"></div>
                            Available
                          </>
                        ) : (
                          <>
                            <div className="w-2 h-2 rounded-full bg-red-400 mr-1"></div>
                            Unavailable
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="bg-yellow-50 p-3 rounded-lg border border-yellow-100 mt-4">
                <p className="text-xs text-yellow-700 flex items-start">
                  <Star className="h-4 w-4 mr-2 text-yellow-500 flex-shrink-0 mt-0.5" />
                  <span>Equipment is subject to availability. Please request at the reception desk upon arrival. A security deposit may be required.</span>
                </p>
              </div>
            </div>
          ) : (
            <p className="text-sm text-gray-600">
              No equipment rental is available for this facility type.
            </p>
          )}
        </div>
      </div>

      <div className="flex items-center space-x-3 mb-2">
        <div className="h-10 w-10 rounded-full bg-gradient-to-r from-purple-500 to-indigo-500 flex items-center justify-center shadow-md">
          <Shield className="h-5 w-5 text-white" />
        </div>
        <h3 className="text-lg font-semibold bg-gradient-to-r from-purple-700 to-indigo-500 bg-clip-text text-transparent">
          Facility Rules
        </h3>
      </div>
      
      <p className="text-sm text-gray-600 mb-4 ml-2 border-l-2 border-purple-200 pl-3">
        Please review and follow these rules while using our {sportType} facility for the best experience.
      </p>
      
      <div className="space-y-4">
        {allRules.map((rule, index) => (
          <div 
            key={index} 
            className={`p-4 rounded-lg transition-all duration-300 hover:shadow-md ${
              rule.highlight 
                ? "bg-gradient-to-br from-purple-50 via-purple-100/30 to-pink-50 border border-purple-100 hover:border-pink-200" 
                : index % 4 === 0 
                  ? "bg-gradient-to-br from-purple-50/70 to-indigo-50/70 border border-purple-100/70 hover:border-indigo-200/70" 
                  : index % 4 === 1 
                    ? "bg-gradient-to-br from-blue-50/70 to-cyan-50/70 border border-blue-100/70 hover:border-cyan-200/70"
                    : index % 4 === 2
                      ? "bg-gradient-to-br from-green-50/70 to-emerald-50/70 border border-green-100/70 hover:border-emerald-200/70"
                      : "bg-gradient-to-br from-amber-50/70 to-yellow-50/70 border border-amber-100/70 hover:border-yellow-200/70"
            }`}
          >
            <div className="flex items-start">
              <div className="mt-1 mr-3 flex-shrink-0">
                {rule.icon}
              </div>
              <div>
                <h4 className="font-medium text-gray-800 mb-1">{rule.title}</h4>
                <p className="text-sm text-gray-600">{rule.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      <div className="mt-6 bg-gradient-to-r from-purple-50 to-indigo-50 p-4 rounded-lg border border-purple-100/50">
        <p className="text-sm text-center text-purple-700">
          Thank you for choosing beSportly. We hope you enjoy your game!
        </p>
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <>
        <button 
          onClick={() => setIsOpen(true)}
          className="flex items-center text-xs text-purple-700 font-medium hover:text-purple-500 transition-colors"
        >
          <BookOpen className="h-3.5 w-3.5 mr-1" strokeWidth={2.5} />
          <span>Facility Rules</span>
        </button>
        
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
          <SheetContent side="bottom" className="h-[85vh] rounded-t-xl pt-6 overflow-hidden bg-gradient-to-b from-white via-white to-gray-50">
            <div className="absolute top-0 right-0 w-64 h-64 -mt-32 -mr-32 bg-gradient-to-bl from-purple-200/20 to-pink-200/20 rounded-full blur-3xl"></div>
            <div className="absolute bottom-0 left-0 w-64 h-64 -mb-32 -ml-32 bg-gradient-to-tr from-blue-200/20 to-purple-200/20 rounded-full blur-3xl"></div>
            
            <SheetHeader className="text-left mb-4 relative">
              <SheetTitle className="text-xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-indigo-600 bg-clip-text text-transparent">
                {facilityName} Rules
              </SheetTitle>
              <div className="h-1 w-24 mt-1 rounded-full bg-gradient-to-r from-purple-500 via-pink-400 to-indigo-500 opacity-70"></div>
            </SheetHeader>
            <RulesContent />
          </SheetContent>
        </Sheet>
      </>
    );
  }

  return (
    <>
      <button 
        onClick={() => setIsOpen(true)}
        className="flex items-center text-xs text-purple-700 font-medium hover:text-purple-500 transition-colors"
      >
        <BookOpen className="h-3.5 w-3.5 mr-1" strokeWidth={2.5} />
        <span>Facility Rules</span>
      </button>
      
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-lg overflow-hidden bg-gradient-to-b from-white to-gray-50">
          <div className="absolute top-0 right-0 w-64 h-64 -mt-32 -mr-32 bg-gradient-to-bl from-purple-200/20 to-pink-200/20 rounded-full blur-3xl"></div>
          <div className="absolute bottom-0 left-0 w-64 h-64 -mb-32 -ml-32 bg-gradient-to-tr from-blue-200/20 to-purple-200/20 rounded-full blur-3xl"></div>
          
          <DialogHeader className="relative">
            <DialogTitle className="text-xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-indigo-600 bg-clip-text text-transparent flex items-center">
              <Shield className="h-5 w-5 mr-2 text-purple-500" />
              {facilityName} Rules
            </DialogTitle>
            <div className="h-1 w-32 mt-1.5 rounded-full bg-gradient-to-r from-purple-500 via-pink-400 to-indigo-500 opacity-70"></div>
          </DialogHeader>
          <RulesContent />
        </DialogContent>
      </Dialog>
    </>
  );
};
