
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Star, ExternalLink, Lock, Search, Rocket } from "lucide-react";
import { Slider } from "@/components/ui/slider";
import LoadingScreen from "@/components/ui/loading-screen";
import { useIsMobile } from "@/hooks/use-mobile";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";

const Products = () => {
  const [query, setQuery] = useState("");
  const [platform, setPlatform] = useState<"all" | "amazon" | "flipkart" | "decathlon" | "cult_sport">("all");
  const [priceRange, setPriceRange] = useState([0, 50000]);
  const [deliveryTime, setDeliveryTime] = useState<"any" | "1" | "2" | "5">("any");
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { data: products, isLoading, error } = useQuery({
    queryKey: ['products', query, platform, priceRange, deliveryTime],
    queryFn: async () => {
      if (!query) return [];
      
      const { data } = await supabase.functions.invoke('search-products', {
        body: { 
          query,
          platform: platform === "all" ? undefined : platform,
          minPrice: priceRange[0],
          maxPrice: priceRange[1],
          deliveryTime: deliveryTime === "any" ? undefined : parseInt(deliveryTime)
        }
      });
      
      return data || [];
    },
    enabled: query.length > 0
  });

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50/50 to-white">
      <div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${isMobile ? 'pt-28' : 'pt-16 md:pt-32'}`}>
        {isMobile && (
          <div className="absolute top-12 left-4 z-10">
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <Button
                onClick={() => navigate(-1)}
                variant="ghost"
                size="sm"
                className="flex items-center justify-center p-2.5 rounded-full backdrop-blur-sm bg-white/90 hover:bg-white shadow-lg shadow-indigo-100/20 border border-indigo-100/30 transition-all duration-300 hover:scale-105"
                aria-label="Go back"
              >
                <ArrowLeft className="h-5 w-5 text-indigo-600" />
              </Button>
            </motion.div>
          </div>
        )}

        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-10 md:mb-16"
        >
          <h1 className={`${isMobile ? 'text-4xl' : 'text-3xl md:text-4xl'} font-bold text-gray-900 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 via-indigo-600 to-pink-600`}>
            Search Sporting and Fitness Products
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Compare prices and find the best deals across multiple platforms
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className={`mb-10 ${isMobile ? 'mt-2' : 'mt-8'}`}
        >
          <Card className="overflow-hidden bg-gradient-to-r from-blue-50/80 to-indigo-50/80 border-none shadow-xl shadow-indigo-200/20 rounded-2xl relative">
            <div className="absolute inset-0 bg-white/40 backdrop-blur-sm -z-10"></div>
            <div className="absolute -top-12 -right-12 w-48 h-48 rounded-full bg-blue-500/10 blur-3xl"></div>
            <div className="absolute -bottom-12 -left-12 w-48 h-48 rounded-full bg-indigo-500/10 blur-3xl"></div>
            
            <div className="p-6 flex flex-col relative z-10">
              <div className="flex items-center mb-4">
                <div className="bg-gradient-to-br from-blue-500 to-indigo-600 p-3 rounded-xl shadow-lg shadow-blue-200/30 mr-4">
                  <Rocket className="w-6 h-6 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-gray-900 mb-1">
                    Product Search Coming Soon!
                  </h3>
                  <p className="text-gray-600">
                    Our integrated product search will be available shortly after launch.
                  </p>
                </div>
              </div>
              
              <div className="ml-16 mt-2 space-y-3">
                <h4 className="font-medium text-gray-800">Our product search will include:</h4>
                <ul className="space-y-2 text-gray-600">
                  <li className="flex items-start">
                    <div className="h-5 w-5 text-indigo-500 mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <span>Price comparison across Amazon, Flipkart, Decathlon, and more</span>
                  </li>
                  <li className="flex items-start">
                    <div className="h-5 w-5 text-indigo-500 mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <span>Delivery time filtering to get products faster</span>
                  </li>
                  <li className="flex items-start">
                    <div className="h-5 w-5 text-indigo-500 mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <span>Advanced filtering by price range, brand, and ratings</span>
                  </li>
                  <li className="flex items-start">
                    <div className="h-5 w-5 text-indigo-500 mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <span>Special discounts for beSportly members</span>
                  </li>
                </ul>
              </div>
              
              <div className="bg-white/80 backdrop-blur-sm px-4 py-2 rounded-full text-sm font-medium text-blue-700 border border-blue-100/50 shadow-sm animate-pulse mt-5 self-center">
                Launching Soon
              </div>
            </div>
          </Card>
        </motion.div>

        <div className={`bg-white/80 backdrop-blur-sm rounded-xl shadow-xl p-6 mb-12 ${isMobile ? 'mt-2' : 'mt-6'} relative overflow-hidden`}>
          <div className="absolute inset-0 bg-gradient-to-br from-purple-500/5 to-pink-500/5 opacity-70"></div>
          
          <div className="absolute inset-0 bg-white/70 backdrop-blur-[2px] flex flex-col items-center justify-center z-20 rounded-xl">
            <div className="bg-gradient-to-br from-purple-600 to-indigo-600 p-4 rounded-full shadow-lg shadow-purple-200/40 mb-4 lock-icon">
              <Lock className="w-8 h-8 text-white" />
            </div>
            <p className="text-lg font-semibold text-gray-800 text-center">
              Coming Soon
            </p>
          </div>
          
          <form onSubmit={handleSearch} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 relative z-10">
            <div className="relative">
              <Input
                type="text"
                placeholder="Search products..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="w-full pr-10 bg-white/90 border-purple-100"
                disabled
              />
              <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            </div>
            
            <Select value={platform} onValueChange={(value: any) => setPlatform(value)} disabled>
              <SelectTrigger className="bg-white/90 border-purple-100">
                <SelectValue placeholder="Select platform" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Platforms</SelectItem>
                <SelectItem value="amazon">Amazon</SelectItem>
                <SelectItem value="flipkart">Flipkart</SelectItem>
                <SelectItem value="decathlon">Decathlon</SelectItem>
                <SelectItem value="cult_sport">Cult.Sport</SelectItem>
              </SelectContent>
            </Select>

            <div className="space-y-2">
              <label className="text-sm text-gray-600">Price Range (₹)</label>
              <Slider
                defaultValue={[0, 50000]}
                max={50000}
                step={1000}
                value={priceRange}
                onValueChange={setPriceRange}
                className="py-4"
                disabled
              />
              <div className="flex justify-between text-xs text-gray-500">
                <span>₹{priceRange[0]}</span>
                <span>₹{priceRange[1]}</span>
              </div>
            </div>

            <Select value={deliveryTime} onValueChange={(value: any) => setDeliveryTime(value)} disabled>
              <SelectTrigger className="bg-white/90 border-purple-100">
                <SelectValue placeholder="Delivery time" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="any">Any Delivery Time</SelectItem>
                <SelectItem value="1">1 Day Delivery</SelectItem>
                <SelectItem value="2">2 Days Delivery</SelectItem>
                <SelectItem value="5">5 Days Delivery</SelectItem>
              </SelectContent>
            </Select>
          </form>
        </div>

        <div className="text-center py-10 mb-16">
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="text-gray-500"
          >
            Product search will be available soon. Check back after our launch!
          </motion.p>
        </div>
      </div>
    </div>
  );
};

export default Products;
