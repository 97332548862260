import { useEffect, useState } from "react";
import { Sparkles } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { supabase } from "@/integrations/supabase/client";

export const QuoteOfTheDay = () => {
  const [quote, setQuote] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const { data, error } = await supabase.functions.invoke('generate-quote', {
          body: { type: 'fitness' }
        });

        if (error) throw error;
        setQuote(data.quote);
      } catch (error) {
        console.error("Error fetching quote:", error);
        setQuote("Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.");
      } finally {
        setLoading(false);
      }
    };

    fetchQuote();
  }, []);

  return (
    <Card className="bg-gradient-to-br from-purple-50 to-pink-50 hover:shadow-lg transition-shadow h-full">
      <CardHeader>
        <CardTitle className="flex items-center gap-2 text-lg">
          <Sparkles className="w-5 h-5 text-purple-500" />
          Quote of the Day
        </CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-gray-700 italic text-lg font-medium">
          {loading ? "Loading..." : `"${quote}"`}
        </p>
      </CardContent>
    </Card>
  );
};