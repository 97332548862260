import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { DateSelector } from "@/components/booking/DateSelector";
import { TimeSlots } from "@/components/booking/TimeSlots";
import { SportSelector } from "@/components/booking/SportSelector";
import { VenueHeader } from "@/components/booking/VenueHeader";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { ArrowLeft, Calendar, Shield, Info, AlertTriangle, Lock, Unlock, RefreshCw, Trash } from "lucide-react";
import { format } from "date-fns";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

interface Facility {
  id: string;
  name: string;
  description: string | null;
  price_per_hour: number;
  capacity: number | null;
  sort_order: number | null;
}

interface AvailabilitySlot {
  id: string;
  facility_id: string;
  start_time: string;
  end_time: string;
  is_booked: boolean;
}

interface VenueWithFacilities {
  id: string;
  name: string;
  description: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  images: string[];
  facilities: Facility[];
  available_sports?: string[];
}

const AdminVenueManager = () => {
  const { id: venueId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedSport, setSelectedSport] = useState<string | null>(null);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<string[]>([]);
  const [venue, setVenue] = useState<VenueWithFacilities | null>(null);
  const [availableSlots, setAvailableSlots] = useState<AvailabilitySlot[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUnblockMode, setIsUnblockMode] = useState(false);
  const [facilityToDelete, setFacilityToDelete] = useState<Facility | null>(null);
  const [isDeletingFacility, setIsDeletingFacility] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const isAdmin = localStorage.getItem("adminAuthenticated") === "true";
    if (!isAdmin) {
      navigate("/admin");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchVenue = async () => {
      try {
        const { data, error } = await supabase
          .from("venues")
          .select(`
            id,
            name,
            description,
            address,
            city,
            state,
            pincode,
            images,
            available_sports,
            facilities!facilities_venue_id_fkey (
              id,
              name,
              description,
              price_per_hour,
              capacity,
              sort_order
            )
          `)
          .eq("id", venueId)
          .maybeSingle();

        if (error) throw error;
        if (!data) throw new Error("Venue not found");

        if (data.facilities) {
          data.facilities.sort((a, b) => {
            if (a.name.includes("Court 5") || a.name.includes("Court 6")) {
              return -1;
            }
            if (b.name.includes("Court 5") || b.name.includes("Court 6")) {
              return 1;
            }
            if (a.sort_order !== null && b.sort_order !== null) {
              return a.sort_order - b.sort_order;
            }
            return a.name.localeCompare(b.name);
          });
        }

        setVenue(data as VenueWithFacilities);

        if (data.available_sports?.length) {
          setSelectedSport(data.available_sports[0]);
        } else if (data.facilities.length) {
          const sportDetectionPatterns = [
            { pattern: /badminton/i, sport: "Badminton" },
            { pattern: /tennis/i, sport: "Tennis" },
            { pattern: /pickleball/i, sport: "Pickleball" }
          ];
          
          const detectedSports = new Set<string>();
          data.facilities.forEach(facility => {
            sportDetectionPatterns.forEach(({ pattern, sport }) => {
              if (pattern.test(facility.name)) {
                detectedSports.add(sport);
              }
            });
          });
          
          const sports = Array.from(detectedSports);
          if (sports.length) {
            setSelectedSport(sports[0]);
          }
        }
      } catch (error) {
        console.error("Error fetching venue:", error);
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to load venue information.",
        });
      }
    };

    if (venueId) {
      fetchVenue();
    }
  }, [venueId, toast]);

  useEffect(() => {
    const fetchSlots = async () => {
      if (!venue?.facilities) return;
      
      setIsLoading(true);
      setSelectedTimeSlots([]); // Clear selections when fetching new slots
      
      try {
        const startOfDay = new Date(selectedDate);
        startOfDay.setHours(0, 0, 0, 0);
        
        const endOfDay = new Date(selectedDate);
        endOfDay.setHours(23, 59, 59, 999);

        console.log("Fetching slots for date range:", startOfDay.toISOString(), "to", endOfDay.toISOString());
        console.log("Facility IDs:", venue.facilities.map(f => f.id));

        const { data: slots, error: slotsError } = await supabase
          .from("availability_slots")
          .select("*")
          .in("facility_id", venue.facilities.map(f => f.id))
          .gte("start_time", startOfDay.toISOString())
          .lte("end_time", endOfDay.toISOString());

        if (slotsError) throw slotsError;
        
        console.log("Fetched slots:", slots?.length || 0);
        setAvailableSlots(slots || []);
      } catch (error) {
        console.error("Error fetching slots:", error);
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to load availability slots.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchSlots();
  }, [venue?.facilities, selectedDate, toast]);

  const filteredFacilities = venue?.facilities?.filter(facility => {
    if (!selectedSport) return true;
    
    if (selectedSport === "Badminton") {
      return facility.name.toLowerCase().includes("badminton");
    } else if (selectedSport === "Tennis") {
      return facility.name.toLowerCase().includes("tennis");
    } else if (selectedSport === "Pickleball") {
      return facility.name.toLowerCase().includes("pickleball");
    }
    return true;
  }) || [];

  const availableSports = venue?.available_sports?.length
    ? venue.available_sports
    : Array.from(new Set(
        venue?.facilities?.map(facility => {
          const name = facility.name.toLowerCase();
          if (name.includes("badminton")) return "Badminton";
          if (name.includes("tennis")) return "Tennis";
          if (name.includes("pickleball")) return "Pickleball";
          return null;
        }).filter(Boolean) || []
      ));

  const handleSlotSelect = (slotId: string) => {
    console.log("Slot selected:", slotId);
    
    const slot = availableSlots.find(s => s.id === slotId);
    
    if ((isUnblockMode && slot?.is_booked) || (!isUnblockMode && !slot?.is_booked)) {
      setSelectedTimeSlots(prev => {
        if (prev.includes(slotId)) {
          return prev.filter(id => id !== slotId);
        }
        return [...prev, slotId];
      });
    }
  };

  const handleClearFacilitySelections = (facilityId: string) => {
    if (availableSlots) {
      const facilitySlotIds = availableSlots
        .filter(slot => slot.facility_id === facilityId)
        .map(slot => slot.id);
      
      setSelectedTimeSlots(prev => 
        prev.filter(slotId => !facilitySlotIds.includes(slotId))
      );
    }
  };

  const toggleUnblockMode = () => {
    setIsUnblockMode(prev => !prev);
    setSelectedTimeSlots([]); // Clear selections when toggling mode
  };

  const handleSlotAction = async () => {
    if (selectedTimeSlots.length === 0) {
      toast({
        variant: "default",
        title: `No slots selected`,
        description: `Please select slots to ${isUnblockMode ? 'unblock' : 'block'}.`,
      });
      return;
    }
    
    setIsUpdating(true);
    
    try {
      console.log(`Attempting to ${isUnblockMode ? 'unblock' : 'block'} these slot IDs:`, selectedTimeSlots);
      
      const { data: result, error } = await supabase.rpc(
        isUnblockMode ? 'admin_unblock_slots' : 'admin_block_slots',
        { slot_ids: selectedTimeSlots }
      );
      
      console.log(`${isUnblockMode ? 'Unblock' : 'Block'} operation result:`, result, "Error:", error);
      
      if (error) {
        throw error;
      }
      
      if (result && typeof result === 'number' && result > 0) {
        toast({
          title: "Success",
          description: `${result} slots have been ${isUnblockMode ? 'unblocked' : 'blocked'}.`,
        });
        setSelectedTimeSlots([]);
        
        await refreshSlotData();
      } else {
        toast({
          variant: "default",
          title: "No slots updated",
          description: `No slots were ${isUnblockMode ? 'unblocked' : 'blocked'}. They may already be in the desired state.`,
        });
      }
    } catch (error) {
      console.error(`Error in ${isUnblockMode ? 'unblock' : 'block'} operation:`, error);
      toast({
        variant: "destructive",
        title: "Error",
        description: `Failed to ${isUnblockMode ? 'unblock' : 'block'} selected time slots: ${error instanceof Error ? error.message : String(error)}`,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDeleteFacility = async () => {
    if (!facilityToDelete) return;
    
    setIsDeletingFacility(true);
    
    try {
      console.log("Deleting facility:", facilityToDelete.id, facilityToDelete.name);
      
      const { error: slotsError } = await supabase
        .from("availability_slots")
        .delete()
        .eq("facility_id", facilityToDelete.id);
      
      if (slotsError) throw slotsError;
      
      const { error: facilityError } = await supabase
        .from("facilities")
        .delete()
        .eq("id", facilityToDelete.id);
      
      if (facilityError) throw facilityError;
      
      if (venue) {
        const updatedFacilities = venue.facilities.filter(f => f.id !== facilityToDelete.id);
        setVenue({
          ...venue,
          facilities: updatedFacilities
        });
      }
      
      setAvailableSlots(prev => prev.filter(slot => slot.facility_id !== facilityToDelete.id));
      
      toast({
        title: "Facility deleted",
        description: `${facilityToDelete.name} has been successfully deleted.`,
      });
      
      setFacilityToDelete(null);
    } catch (error) {
      console.error("Error deleting facility:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: `Failed to delete facility: ${error instanceof Error ? error.message : String(error)}`,
      });
    } finally {
      setIsDeletingFacility(false);
    }
  };

  const refreshSlotData = async () => {
    if (!venue?.facilities) return;
    
    try {
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      
      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(23, 59, 59, 999);
      
      console.log("Refreshing slots for date range:", startOfDay.toISOString(), "to", endOfDay.toISOString());
      
      const { data: refreshedSlots, error: refreshError } = await supabase
        .from("availability_slots")
        .select("*")
        .in("facility_id", venue.facilities.map(f => f.id))
        .gte("start_time", startOfDay.toISOString())
        .lte("end_time", endOfDay.toISOString());
      
      if (refreshError) {
        console.error("Error refreshing slots:", refreshError);
        throw refreshError;
      } else {
        console.log("Received refreshed slots:", refreshedSlots?.length || 0);
        setAvailableSlots(refreshedSlots || []);
      }
    } catch (error) {
      console.error("Error in refreshSlotData:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to refresh slot data: " + (error instanceof Error ? error.message : String(error)),
      });
    }
  };

  if (isLoading && !venue) {
    return (
      <div className="min-h-screen pt-32 flex items-center justify-center">
        <div className="animate-pulse text-xl text-gray-600">Loading venue details...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen pt-32 pb-16 bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between mb-6">
          <Button
            variant="outline"
            size="sm"
            className="flex items-center gap-2"
            onClick={() => navigate("/admin/dashboard")}
          >
            <ArrowLeft className="h-4 w-4" />
            Back to Dashboard
          </Button>
          
          <div className="flex items-center gap-2">
            <Shield className="h-5 w-5 text-indigo-600 dark:text-indigo-400" />
            <span className="font-medium text-gray-700 dark:text-gray-300">Admin Mode</span>
          </div>
        </div>
        
        <Alert className="mb-6 bg-gradient-to-r from-amber-50 to-yellow-50 border-amber-200 dark:from-amber-900/20 dark:to-yellow-900/20 dark:border-amber-800/30">
          <Info className="h-4 w-4 text-amber-600 dark:text-amber-400" />
          <AlertTitle className="text-amber-800 dark:text-amber-300">Admin Slot Management Tool</AlertTitle>
          <AlertDescription className="text-amber-700 dark:text-amber-400">
            This tool allows you to block or unblock slots. Toggle between modes using the switch below.
          </AlertDescription>
        </Alert>
        
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md mb-6">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                {venue?.name}
              </h1>
              <p className="text-gray-600 dark:text-gray-400">
                {venue?.address}, {venue?.city}
              </p>
            </div>
            
            <div className="text-right">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Selected Date
              </p>
              <p className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                {format(selectedDate, "EEEE, MMMM d, yyyy")}
              </p>
            </div>
          </div>
        </div>

        <div className="mb-6 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="flex items-center space-x-2 mb-4 sm:mb-0">
              <div className={`p-2 rounded-md ${isUnblockMode ? 'bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400' : 'bg-blue-100 text-blue-700 dark:bg-blue-900/30 dark:text-blue-400'}`}>
                {isUnblockMode ? (
                  <Unlock className="h-5 w-5" />
                ) : (
                  <Lock className="h-5 w-5" />
                )}
              </div>
              <div>
                <h3 className="font-medium">
                  Current Mode: <span className={isUnblockMode ? 'text-green-600 dark:text-green-400' : 'text-blue-600 dark:text-blue-400'}>
                    {isUnblockMode ? 'Unblock Slots' : 'Block Slots'}
                  </span>
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {isUnblockMode 
                    ? 'Select booked slots (locked) to make them available again' 
                    : 'Select available slots to block them from being booked'}
                </p>
              </div>
            </div>
            
            <div className="flex items-center gap-2">
              <Label htmlFor="mode-toggle" className="cursor-pointer">
                {isUnblockMode ? 'Switch to Block Mode' : 'Switch to Unblock Mode'}
              </Label>
              <Switch
                id="mode-toggle"
                checked={isUnblockMode}
                onCheckedChange={toggleUnblockMode}
              />
            </div>
          </div>
          
          <div className="mt-4 flex justify-end">
            <Button
              variant="outline"
              size="sm"
              onClick={refreshSlotData}
              className="flex items-center gap-2"
            >
              <RefreshCw className="h-4 w-4" />
              Refresh Slots
            </Button>
          </div>
        </div>

        {venue && <VenueHeader {...venue} />}
        
        <DateSelector 
          selectedDate={selectedDate}
          onDateChange={setSelectedDate}
        />
        
        {availableSports?.length > 0 && (
          <SportSelector 
            availableSports={availableSports}
            selectedSport={selectedSport}
            onSelectSport={setSelectedSport}
          />
        )}

        <div className="space-y-6">
          {filteredFacilities.map((facility) => (
            <div key={facility.id} className="relative">
              
              <TimeSlots
                key={`timeslot-${facility.id}`}
                facilityName={facility.name}
                slots={availableSlots.filter(slot => slot.facility_id === facility.id) || []}
                selectedSlots={selectedTimeSlots}
                onSlotSelect={handleSlotSelect}
                pricePerHour={facility.price_per_hour}
                className="bg-white dark:bg-gray-800 rounded-lg shadow-sm pt-12 sm:pt-4"
                titleClassName="text-gray-900 dark:text-gray-100 font-semibold"
                onClearSelections={handleClearFacilitySelections}
                isUnblockMode={isUnblockMode}
              />
            </div>
          ))}
          
          {filteredFacilities.length === 0 && selectedSport && (
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm text-center">
              <p className="text-gray-500 dark:text-gray-400">
                No {selectedSport} facilities available at this venue.
              </p>
            </div>
          )}
        </div>
        
        <div className="mt-8 bg-indigo-50 dark:bg-indigo-900/30 p-6 rounded-lg shadow-sm">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Selected Slots</h3>
              <p className="text-gray-600 dark:text-gray-400">
                {selectedTimeSlots.length === 0 
                  ? "No time slots selected" 
                  : `${selectedTimeSlots.length} slot${selectedTimeSlots.length === 1 ? '' : 's'} selected`}
              </p>
            </div>
            
            <Button
              className={`w-full md:w-auto shadow-md hover:shadow-lg transition-all duration-300 ${
                isUnblockMode 
                  ? 'bg-gradient-to-r from-green-600 to-emerald-600 hover:from-green-700 hover:to-emerald-700 text-white' 
                  : 'bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 text-white'
              }`}
              onClick={handleSlotAction}
              disabled={selectedTimeSlots.length === 0 || isUpdating}
            >
              {isUpdating ? (
                <div className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing...
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  {isUnblockMode ? <Unlock className="h-5 w-5" /> : <Lock className="h-5 w-5" />}
                  <span>{isUnblockMode ? 'Unblock Selected Slots' : 'Block Selected Slots'}</span>
                </div>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminVenueManager;
