import { useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { toast } from "@/hooks/use-toast";
import { Button } from "@/components/ui/button";
import { Gamepad2 } from "lucide-react";

const BOARD_SIZE = 19;
const CELL_SIZE = 30;

type Stone = 'black' | 'white' | null;
type Board = Stone[][];

const initialBoard = Array(BOARD_SIZE).fill(null).map(() => Array(BOARD_SIZE).fill(null));

export const GoBoard = () => {
  const [board, setBoard] = useState<Board>(initialBoard);
  const [currentPlayer, setCurrentPlayer] = useState<'black' | 'white'>('black');
  const [lastMove, setLastMove] = useState<{ row: number; col: number } | null>(null);
  const [gameEnded, setGameEnded] = useState(false);
  const [passCount, setPassCount] = useState(0);

  const isValidMove = (row: number, col: number): boolean => {
    return board[row][col] === null;
  };

  const getAdjacentPositions = (row: number, col: number) => {
    return [
      [row - 1, col],
      [row + 1, col],
      [row, col - 1],
      [row, col + 1],
    ].filter(([r, c]) => r >= 0 && r < BOARD_SIZE && c >= 0 && c < BOARD_SIZE);
  };

  const captureStones = useCallback((row: number, col: number, currentBoard: Board) => {
    const opponent = currentPlayer === 'black' ? 'white' : 'black';
    let captured = false;
    
    const adjacentPositions = getAdjacentPositions(row, col);
    const newBoard = [...currentBoard.map(row => [...row])];
    
    adjacentPositions.forEach(([r, c]) => {
      if (newBoard[r][c] === opponent) {
        const group = findGroup(r, c, opponent, newBoard);
        if (group.length > 0 && !hasLiberties(group, newBoard)) {
          captured = true;
          group.forEach(([gr, gc]) => {
            newBoard[gr][gc] = null;
          });
        }
      }
    });
    
    return { newBoard, captured };
  }, [currentPlayer]);

  const findGroup = (row: number, col: number, color: Stone, board: Board): [number, number][] => {
    const group: [number, number][] = [];
    const visited = new Set<string>();
    
    const dfs = (r: number, c: number) => {
      const key = `${r},${c}`;
      if (visited.has(key)) return;
      visited.add(key);
      
      if (board[r][c] !== color) return;
      group.push([r, c]);
      
      getAdjacentPositions(r, c).forEach(([nr, nc]) => {
        dfs(nr, nc);
      });
    };
    
    dfs(row, col);
    return group;
  };

  const hasLiberties = (group: [number, number][], board: Board): boolean => {
    for (const [r, c] of group) {
      const adjacent = getAdjacentPositions(r, c);
      if (adjacent.some(([ar, ac]) => board[ar][ac] === null)) {
        return true;
      }
    }
    return false;
  };

  const handlePass = () => {
    if (gameEnded) return;
    
    const newPassCount = passCount + 1;
    setPassCount(newPassCount);
    
    if (newPassCount >= 2) {
      endGame();
    } else {
      setCurrentPlayer(currentPlayer === 'black' ? 'white' : 'black');
    }
  };

  const endGame = () => {
    setGameEnded(true);
    const territories = calculateTerritories();
    toast({
      title: "Game Over!",
      description: `Game has ended! ${territories.black > territories.white ? 'Black' : 'White'} wins with ${Math.max(territories.black, territories.white)} points!`,
      className: "bg-gradient-to-br from-purple-100 to-indigo-100 border-2 border-indigo-200",
    });
  };

  const calculateTerritories = () => {
    let black = 0;
    let white = 0;
    
    board.forEach((row, i) => {
      row.forEach((cell, j) => {
        if (cell === 'black') black++;
        if (cell === 'white') white++;
      });
    });
    
    return { black, white };
  };

  const handleCellClick = (row: number, col: number) => {
    if (gameEnded || !isValidMove(row, col)) return;

    setPassCount(0);
    const newBoard = [...board.map(row => [...row])];
    newBoard[row][col] = currentPlayer;

    const { newBoard: capturedBoard, captured } = captureStones(row, col, newBoard);
    
    if (!captured) {
      const group = findGroup(row, col, currentPlayer, capturedBoard);
      if (!hasLiberties(group, capturedBoard)) {
        return; // Suicide move not allowed
      }
    }

    setBoard(capturedBoard);
    setLastMove({ row, col });
    setCurrentPlayer(currentPlayer === 'black' ? 'white' : 'black');
  };

  return (
    <div className="flex flex-col items-center justify-center bg-white p-8 rounded-lg shadow-lg">
      <div className="mb-4 flex items-center justify-between w-full max-w-xl">
        <div className="text-lg font-semibold text-gray-800">
          {gameEnded ? (
            <span className="text-purple-600">Game Over</span>
          ) : (
            <>Current Player: {currentPlayer === 'black' ? '⚫' : '⚪'}</>
          )}
        </div>
        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={handlePass}
            disabled={gameEnded}
            className="bg-white hover:bg-gray-50"
          >
            <span className="dark:text-black">Pass</span>
          </Button>
          {gameEnded && (
            <Button
              variant="outline"
              onClick={() => {
                setBoard(initialBoard);
                setCurrentPlayer('black');
                setGameEnded(false);
                setPassCount(0);
                setLastMove(null);
              }}
              className="bg-white hover:bg-gray-50"
            >
              <Gamepad2 className="w-4 h-4 mr-2" />
              New Game
            </Button>
          )}
        </div>
      </div>
      
      <div 
        className="relative bg-[#DCB35C] rounded-lg p-4 shadow-inner"
        style={{
          width: CELL_SIZE * (BOARD_SIZE + 1),
          height: CELL_SIZE * (BOARD_SIZE + 1),
        }}
      >
        {/* Grid lines */}
        {Array(BOARD_SIZE).fill(null).map((_, i) => (
          <div key={`horizontal-${i}`}>
            <div
              className="absolute bg-black"
              style={{
                left: CELL_SIZE,
                top: CELL_SIZE * (i + 1),
                width: CELL_SIZE * (BOARD_SIZE - 1),
                height: 1,
              }}
            />
            <div
              className="absolute bg-black"
              style={{
                top: CELL_SIZE,
                left: CELL_SIZE * (i + 1),
                height: CELL_SIZE * (BOARD_SIZE - 1),
                width: 1,
              }}
            />
          </div>
        ))}

        {/* Star points */}
        {[3, 9, 15].map(row => 
          [3, 9, 15].map(col => (
            <div
              key={`star-${row}-${col}`}
              className="absolute w-2 h-2 bg-black rounded-full transform -translate-x-1 -translate-y-1"
              style={{
                left: CELL_SIZE * (row + 1),
                top: CELL_SIZE * (col + 1),
              }}
            />
          ))
        )}

        {/* Stones */}
        {board.map((row, rowIndex) =>
          row.map((cell, colIndex) => {
            if (cell === null) return null;
            const isLastMove = lastMove?.row === rowIndex && lastMove?.col === colIndex;
            
            return (
              <motion.div
                key={`stone-${rowIndex}-${colIndex}`}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                className={`absolute rounded-full shadow-lg ${
                  cell === 'black' 
                    ? 'bg-gray-900' 
                    : 'bg-white border border-gray-300'
                }`}
                style={{
                  width: CELL_SIZE - 4,
                  height: CELL_SIZE - 4,
                  left: CELL_SIZE * (colIndex + 1) - (CELL_SIZE - 4) / 2,
                  top: CELL_SIZE * (rowIndex + 1) - (CELL_SIZE - 4) / 2,
                }}
              >
                {isLastMove && (
                  <div className={`absolute inset-0 flex items-center justify-center text-xs ${
                    cell === 'black' ? 'text-white' : 'text-black'
                  }`}>
                    •
                  </div>
                )}
              </motion.div>
            );
          })
        )}

        {/* Clickable areas */}
        {!gameEnded && board.map((row, rowIndex) =>
          row.map((_, colIndex) => (
            <button
              key={`cell-${rowIndex}-${colIndex}`}
              className="absolute cursor-pointer"
              style={{
                width: CELL_SIZE,
                height: CELL_SIZE,
                left: CELL_SIZE * (colIndex + 1) - CELL_SIZE / 2,
                top: CELL_SIZE * (rowIndex + 1) - CELL_SIZE / 2,
              }}
              onClick={() => handleCellClick(rowIndex, colIndex)}
            />
          ))
        )}
      </div>
    </div>
  );
};
