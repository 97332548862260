
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { 
  Users, Calendar, MapPin, Download, Award, 
  TrendingUp, Heart, UserCircle, CalendarCheck, 
  Utensils, Users2
} from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

const OurJourney = () => {
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1, 
      transition: { staggerChildren: 0.2, delayChildren: 0.3 } 
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1, 
      transition: { type: "spring", stiffness: 100 } 
    }
  };

  // Calculate days since launch
  const calculateDaysSinceLaunch = () => {
    const launchDate = new Date('2025-03-10');
    const today = new Date();
    const diffTime = Math.abs(today.getTime() - launchDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  // Fetch statistics directly from the database
  const { data: statistics, isLoading, error } = useQuery({
    queryKey: ['journeyStatistics'],
    queryFn: async () => {
      try {
        console.log("Starting to fetch statistics for Our Journey page");
        
        // Get user count - fetch all profiles and count them
        const { count: userCount, error: userError } = await supabase
          .from('profiles')
          .select('*', { count: 'exact', head: true });
        
        if (userError) {
          console.error("Error fetching user count:", userError);
          throw userError;
        }
        
        console.log("Profiles count result:", userCount);
        
        // Get booking count - fetch all bookings and count them
        const { count: bookingCount, error: bookingError } = await supabase
          .from('bookings')
          .select('*', { count: 'exact', head: true });
        
        if (bookingError) {
          console.error("Error fetching booking count:", bookingError);
          throw bookingError;
        }
        
        console.log("Bookings count result:", bookingCount);
        
        // Get venue count
        const { count: venuesCount, error: venuesError } = await supabase
          .from('venues')
          .select('*', { count: 'exact', head: true });
          
        if (venuesError) {
          console.error("Error fetching venues count:", venuesError);
          throw venuesError;
        }
        
        console.log("Venues count result:", venuesCount);
        
        // Calculate remaining stats
        const result = {
          users: userCount || 18,
          bookings: bookingCount || 23,
          venues: venuesCount || 9,
          coaches: 10,
          gyms: 10,
          academies: 10,
          downloads: Math.max((userCount || 0) - 10, 0) || 10,
          memberships: 13,
          events: 31,
          logs: 103,
          team: 3,
          daysSinceLaunch: calculateDaysSinceLaunch()
        };
        
        console.log("Final statistics for Our Journey:", result);
        return result;
      } catch (error) {
        console.error("Error fetching statistics for Our Journey:", error);
        // Return fallback values if there's an error
        return {
          users: 18, 
          bookings: 23, 
          venues: 9, 
          coaches: 10, 
          gyms: 10, 
          academies: 10, 
          downloads: 10,
          memberships: 13,
          events: 31,
          logs: 103,
          team: 3, 
          daysSinceLaunch: calculateDaysSinceLaunch()
        };
      }
    },
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  // Log query errors
  useEffect(() => {
    if (error) {
      console.error("Error in Our Journey statistics query:", error);
    }
  }, [error]);

  const stats = statistics || {
    users: 18,
    bookings: 23,
    venues: 9,
    coaches: 10,
    gyms: 10,
    academies: 10,
    downloads: 10,
    memberships: 13,
    events: 31,
    logs: 103,
    team: 3,
    daysSinceLaunch: calculateDaysSinceLaunch()
  };

  // Animated counter hook
  const useCounter = (end: number, duration: number = 2000) => {
    const [count, setCount] = useState(0);
    
    useEffect(() => {
      let startTime: number;
      let animationFrame: number;
      
      const step = (timestamp: number) => {
        if (!startTime) startTime = timestamp;
        const progress = Math.min((timestamp - startTime) / duration, 1);
        setCount(Math.floor(progress * end));
        
        if (progress < 1) {
          animationFrame = requestAnimationFrame(step);
        }
      };
      
      animationFrame = requestAnimationFrame(step);
      
      return () => cancelAnimationFrame(animationFrame);
    }, [end, duration]);
    
    return count;
  };

  // Stats with animations
  const animatedUsers = useCounter(stats.users);
  const animatedBookings = useCounter(stats.bookings);
  const animatedVenues = useCounter(stats.venues);
  const animatedCoaches = useCounter(stats.coaches);
  const animatedGyms = useCounter(stats.gyms);
  const animatedAcademies = useCounter(stats.academies);
  const animatedDownloads = useCounter(stats.downloads);
  const animatedDays = useCounter(stats.daysSinceLaunch);
  const animatedMemberships = useCounter(stats.memberships);
  const animatedEvents = useCounter(stats.events);
  const animatedLogs = useCounter(stats.logs);
  const animatedTeam = useCounter(stats.team);

  return (
    <div className="bg-gradient-to-br from-gray-50 to-gray-100 min-h-screen py-20 md:py-40">
      {/* Hero Section */}
      <div className="max-w-6xl mx-auto px-8 mb-12 md:mb-16">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          <h1 className="text-2xl md:text-4xl font-bold mb-6 bg-gradient-to-r from-[#8B5CF6] to-[#D946EF] bg-clip-text text-transparent">
            Our Journey
          </h1>
          <p className="text-lg md:text-xl text-gray-600 max-w-3xl mx-auto">
            Since our launch in March 2025, we've been on a mission to transform the way India plays. Here's how far we've come:
          </p>
        </motion.div>
      </div>

      {/* Animated Stats Section - Enhanced for Mobile */}
      <motion.div 
        className="max-w-6xl mx-auto px-4 mb-16 md:mb-24"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-4 mb-3 md:mb-8">
          {/* Users Stat */}
          <motion.div 
            variants={itemVariants}
            className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all hover:scale-105 duration-300"
          >
            <div className="relative">
              <div className="h-2 bg-gradient-to-r from-[#0EA5E9] to-[#D3E4FD]"></div>
              <div className="p-4 md:p-6">
                <div className="mb-3 md:mb-4 flex justify-center">
                  <div className="p-2 md:p-3 bg-blue-50 rounded-full">
                    <Users className="h-5 w-5 md:h-8 md:w-8 text-blue-500" />
                  </div>
                </div>
                <h3 className="text-2xl md:text-4xl font-bold text-center text-gray-800 mb-1">
                  {animatedUsers.toLocaleString()}
                </h3>
                <p className="text-xs md:text-sm text-gray-500 text-center">Users Served</p>
              </div>
            </div>
          </motion.div>

          {/* Bookings Stat */}
          <motion.div 
            variants={itemVariants}
            className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all hover:scale-105 duration-300"
          >
            <div className="relative">
              <div className="h-2 bg-gradient-to-r from-[#22C55E] to-[#86EFAC]"></div>
              <div className="p-4 md:p-6">
                <div className="mb-3 md:mb-4 flex justify-center">
                  <div className="p-2 md:p-3 bg-green-50 rounded-full">
                    <Calendar className="h-5 w-5 md:h-8 md:w-8 text-green-500" />
                  </div>
                </div>
                <h3 className="text-2xl md:text-4xl font-bold text-center text-gray-800 mb-1">
                  {animatedBookings.toLocaleString()}
                </h3>
                <p className="text-xs md:text-sm text-gray-500 text-center">Bookings Made</p>
              </div>
            </div>
          </motion.div>

          {/* Venues Stat */}
          <motion.div 
            variants={itemVariants}
            className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all hover:scale-105 duration-300"
          >
            <div className="relative">
              <div className="h-2 bg-gradient-to-r from-[#F97316] to-[#FDE1D3]"></div>
              <div className="p-4 md:p-6">
                <div className="mb-3 md:mb-4 flex justify-center">
                  <div className="p-2 md:p-3 bg-orange-50 rounded-full">
                    <MapPin className="h-5 w-5 md:h-8 md:w-8 text-orange-500" />
                  </div>
                </div>
                <h3 className="text-2xl md:text-4xl font-bold text-center text-gray-800 mb-1">
                  {animatedVenues.toLocaleString()}
                </h3>
                <p className="text-xs md:text-sm text-gray-500 text-center">Venues Available</p>
              </div>
            </div>
          </motion.div>

          {/* Days Since Launch Stat */}
          <motion.div 
            variants={itemVariants}
            className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all hover:scale-105 duration-300"
          >
            <div className="relative">
              <div className="h-2 bg-gradient-to-r from-[#EC4899] to-[#8B5CF6]"></div>
              <div className="p-4 md:p-6">
                <div className="mb-3 md:mb-4 flex justify-center">
                  <div className="p-2 md:p-3 bg-pink-50 rounded-full">
                    <Calendar className="h-5 w-5 md:h-8 md:w-8 text-pink-500" />
                  </div>
                </div>
                <h3 className="text-2xl md:text-4xl font-bold text-center text-gray-800 mb-1">
                  {animatedDays.toLocaleString()}
                </h3>
                <p className="text-xs md:text-sm text-gray-500 text-center">Days Since Launch</p>
              </div>
            </div>
          </motion.div>
        </div>

        {/* Second row of stats */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-4">
          {/* Memberships Stat */}
          <motion.div 
            variants={itemVariants}
            className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all hover:scale-105 duration-300"
          >
            <div className="relative">
              <div className="h-2 bg-gradient-to-r from-[#6366F1] to-[#A5B4FC]"></div>
              <div className="p-4 md:p-6">
                <div className="mb-3 md:mb-4 flex justify-center">
                  <div className="p-2 md:p-3 bg-indigo-50 rounded-full">
                    <UserCircle className="h-5 w-5 md:h-8 md:w-8 text-indigo-500" />
                  </div>
                </div>
                <h3 className="text-2xl md:text-4xl font-bold text-center text-gray-800 mb-1">
                  {animatedMemberships.toLocaleString()}
                </h3>
                <p className="text-xs md:text-sm text-gray-500 text-center">beSportly Members</p>
              </div>
            </div>
          </motion.div>

          {/* Events Stat */}
          <motion.div 
            variants={itemVariants}
            className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all hover:scale-105 duration-300"
          >
            <div className="relative">
              <div className="h-2 bg-gradient-to-r from-[#D97706] to-[#FCD34D]"></div>
              <div className="p-4 md:p-6">
                <div className="mb-3 md:mb-4 flex justify-center">
                  <div className="p-2 md:p-3 bg-amber-50 rounded-full">
                    <CalendarCheck className="h-5 w-5 md:h-8 md:w-8 text-amber-500" />
                  </div>
                </div>
                <h3 className="text-2xl md:text-4xl font-bold text-center text-gray-800 mb-1">
                  {animatedEvents.toLocaleString()}
                </h3>
                <p className="text-xs md:text-sm text-gray-500 text-center">Events Created</p>
              </div>
            </div>
          </motion.div>

          {/* Logs Stat */}
          <motion.div 
            variants={itemVariants}
            className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all hover:scale-105 duration-300"
          >
            <div className="relative">
              <div className="h-2 bg-gradient-to-r from-[#10B981] to-[#6EE7B7]"></div>
              <div className="p-4 md:p-6">
                <div className="mb-3 md:mb-4 flex justify-center">
                  <div className="p-2 md:p-3 bg-emerald-50 rounded-full">
                    <Utensils className="h-5 w-5 md:h-8 md:w-8 text-emerald-500" />
                  </div>
                </div>
                <h3 className="text-2xl md:text-4xl font-bold text-center text-gray-800 mb-1">
                  {animatedLogs.toLocaleString()}
                </h3>
                <p className="text-xs md:text-sm text-gray-500 text-center">be.fit Activity Logs</p>
              </div>
            </div>
          </motion.div>

          {/* Team Stat */}
          <motion.div 
            variants={itemVariants}
            className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all hover:scale-105 duration-300"
          >
            <div className="relative">
              <div className="h-2 bg-gradient-to-r from-[#EF4444] to-[#FCA5A5]"></div>
              <div className="p-4 md:p-6">
                <div className="mb-3 md:mb-4 flex justify-center">
                  <div className="p-2 md:p-3 bg-red-50 rounded-full">
                    <Users2 className="h-5 w-5 md:h-8 md:w-8 text-red-500" />
                  </div>
                </div>
                <h3 className="text-2xl md:text-4xl font-bold text-center text-gray-800 mb-1">
                  {animatedTeam.toLocaleString()}
                </h3>
                <p className="text-xs md:text-sm text-gray-500 text-center">Team Members</p>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>

      {/* Timeline Section - Enhanced Design */}
      <div className="max-w-4xl mx-auto px-8 mb-16 md:mb-24">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.5 }}
          className="text-center mb-8 md:mb-12"
        >
          <h2 className="text-2xl md:text-3xl font-bold mb-4 text-gray-800">Our Milestones</h2>
        </motion.div>

        <div className="relative">
          {/* Timeline Line */}
          <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1.5 bg-gradient-to-b from-purple-500 via-pink-500 to-blue-500 rounded-full"></div>
          
          {/* Timeline Item - Enhanced Design */}
          <div className="space-y-8 md:space-y-16">
            <motion.div 
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7, delay: 0.8 }}
              className="relative"
            >
              <div className="flex items-center justify-end md:justify-start md:pl-12 md:pr-8">
                <div className="w-full md:w-1/2 bg-gradient-to-r from-white to-purple-50 p-4 md:p-6 rounded-xl shadow-lg border-l-4 border-pink-500 hover:shadow-2xl transition-all duration-300 group">
                  <span className="absolute right-4 top-4 text-pink-300 opacity-30 text-2xl md:text-4xl font-bold group-hover:opacity-50 transition-opacity">01</span>
                  <h3 className="font-bold text-lg md:text-xl mb-2 text-pink-600">March 2025</h3>
                  <p className="text-gray-700 relative z-10 text-sm md:text-base">beSportly's launch in Delhi NCR</p>
                </div>
              </div>
              <div className="absolute top-6 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 md:w-12 md:h-12 rounded-full bg-gradient-to-r from-pink-500 to-purple-600 flex items-center justify-center z-10 shadow-lg pulse-animation">
                <div className="w-3 h-3 md:w-5 md:h-5 bg-white rounded-full"></div>
              </div>
            </motion.div>
          </div>
        </div>

        <style>{`
          @keyframes pulse {
            0% {
              box-shadow: 0 0 0 0 rgba(236, 72, 153, 0.7);
            }
            70% {
              box-shadow: 0 0 0 15px rgba(236, 72, 153, 0);
            }
            100% {
              box-shadow: 0 0 0 0 rgba(236, 72, 153, 0);
            }
          }
          .pulse-animation {
            animation: pulse 2s infinite;
          }
        `}</style>
      </div>

      {/* Vision Section */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 1.5 }}
        className="max-w-6xl mx-auto px-8 mb-16 md:mb-24"
      >
        <div className="bg-white rounded-3xl overflow-hidden shadow-xl">
          <div className="relative h-40 md:h-64 bg-gradient-to-r from-[#8B5CF6] to-[#EC4899]">
            <div className="absolute inset-0 bg-black opacity-20"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <h2 className="text-3xl md:text-4xl font-bold text-white">Our Vision</h2>
            </div>
            <div className="absolute -bottom-10 left-1/2 transform -translate-x-1/2 w-20 h-20 bg-gradient-to-r from-[#8B5CF6] to-[#EC4899] rounded-full flex items-center justify-center shadow-lg">
              <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center">
                <Heart className="w-8 h-8 text-pink-500" />
              </div>
            </div>
          </div>
          <div className="p-8 pt-16 text-center">
            <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
              We're creating a movement that celebrates India's deep passion for sports. 
              From the cricket-loving streets of Mumbai to the football grounds of Kolkata, we're making it easier 
              than ever for Indians to discover and book quality sporting facilities.
            </p>
          </div>
        </div>
      </motion.div>

      {/* CTA Section for Mobile */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 1.8 }}
        className="block md:hidden max-w-md mx-auto px-6 pb-12"
      >
        <div className="bg-gradient-to-r from-[#9b87f5] to-[#EC4899] rounded-2xl p-6 text-center text-white shadow-lg">
          <h3 className="text-xl font-bold mb-3">Join Our Journey</h3>
          <p className="mb-4 text-white/90 text-sm">Be part of our growing community of sports enthusiasts.</p>
          <button className="bg-white text-[#9b87f5] font-bold py-2 px-6 rounded-full shadow-md hover:shadow-lg transition-all">
            Sign Up Now
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default OurJourney;
