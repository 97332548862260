
import { Link } from "react-router-dom";
import { Instagram, Twitter, Facebook, Linkedin, Shield, ExternalLink } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/components/AuthProvider";

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps) => {
  const { user } = useAuth();
  
  // Query to check if user has a coach profile
  const { data: coachProfile, isLoading } = useQuery({
    queryKey: ['coachProfile', user?.id],
    queryFn: async () => {
      if (!user) return null;
      
      console.log("Fetching coach profile for user ID:", user.id);
      
      const { data, error } = await supabase
        .from('coaches')
        .select('id')
        .eq('profile_id', user.id)
        .maybeSingle();
      
      if (error) {
        console.error("Error fetching coach profile:", error);
        return null;
      }
      
      console.log("Coach profile data:", data);
      return data;
    },
    enabled: !!user,
  });

  const hasCoachProfile = !!coachProfile;
  console.log("User has coach profile:", hasCoachProfile, coachProfile);

  const platformLinks = [
    { name: "Venues", href: "/venues" },
    { name: "Coaches", href: "/coaches" },
    { name: "Academies", href: "/academies" },
    { name: "Gyms", href: "/gyms" },
    { name: "Events", href: "/events-and-games" },
  ];

  // Create coaching link separately based on coach profile status
  const coachingLink = user
    ? hasCoachProfile
      ? { name: "Manage Coaching Profile", href: "/coach/dashboard" }
      : { name: "Become a Coach", href: "/coach/register" }
    : { name: "Become a Coach", href: "/coach/register" };

  return (
    <footer className={`bg-gray-50 border-t border-gray-100 animate-fadeIn mt-auto hidden md:block ${className}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-1 md:col-span-2">
            <img 
              src="/lovable-uploads/137dfc81-3701-45bd-a455-36668afeee39.png" 
              alt="beSportly" 
              className="h-16 w-auto mb-4"
            />
            <p className="text-gray-600 max-w-md">
              Discover and book sporting and fitness venues, connect with coaches, and join academies across India.
            </p>
            
            <div className="flex space-x-4 mt-6">
              <a href="https://instagram.com/be.sportly" target="_blank" rel="noopener noreferrer" 
                className="text-gray-600 hover:text-[#ff00ff] transition-colors">
                <Instagram className="h-5 w-5" />
              </a>
              <a href="https://twitter.com/besportly" target="_blank" rel="noopener noreferrer"
                className="text-gray-600 hover:text-[#00bfff] transition-colors">
                <Twitter className="h-5 w-5" />
              </a>
              <a href="https://facebook.com/besportlyapp" target="_blank" rel="noopener noreferrer"
                className="text-gray-600 hover:text-[#ff00ff] transition-colors">
                <Facebook className="h-5 w-5" />
              </a>
              <a href="https://linkedin.com/company/besportly" target="_blank" rel="noopener noreferrer"
                className="text-gray-600 hover:text-[#00bfff] transition-colors">
                <Linkedin className="h-5 w-5" />
              </a>
            </div>

            <div className="flex flex-col space-y-4 mt-6">
              <div className="flex space-x-4">
                <a 
                  href="https://apps.apple.com/app/besportly/id6742348742" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="transition-opacity hover:opacity-80"
                >
                  <img 
                    src="/lovable-uploads/f9ac38bb-d196-4eaa-ad10-4bf63e516c34.png" 
                    alt="App Store" 
                    className="h-10"
                  />
                </a>
                <a 
                  href="https://play.google.com/store/apps/details?id=branded.m6818cd43ae2241278977ff65ceb6cf40.besportly" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="transition-opacity hover:opacity-80"
                >
                  <img 
                    src="/lovable-uploads/5da71b37-3e02-4bf1-8252-4fd41ed118bf.png" 
                    alt="Play Store" 
                    className="h-10"
                  />
                </a>
              </div>
              <Link to="/product-demo" className="flex items-center text-purple-600 hover:text-purple-500 transition-colors font-medium">
                <ExternalLink className="h-4 w-4 mr-2" /> 
                Product Demo
              </Link>
            </div>
          </div>
          
          <div>
            <h3 className="font-semibold text-gray-900 mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/terms" className="text-gray-600 hover:text-primary transition-colors">
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-gray-600 hover:text-primary transition-colors">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/refund" className="text-gray-600 hover:text-primary transition-colors">
                  Refund Policy
                </Link>
              </li>
              <li>
                <Link to="/investors" className="text-gray-600 hover:text-primary transition-colors">
                  Investors
                </Link>
              </li>
              <li>
                <Link to="/also-by-us" className="text-gray-600 hover:text-primary transition-colors">
                  Also by Us
                </Link>
              </li>
              <li>
                <Link to="/join-platform" className="text-gray-600 hover:text-primary transition-colors">
                  Join Our Platform
                </Link>
              </li>
              <li>
                <Link to="/partners" className="text-gray-600 hover:text-primary transition-colors">
                  Partners
                </Link>
              </li>
              {/* Here's where we add the coach link */}
              <li>
                <Link to={coachingLink.href} className="text-gray-600 hover:text-primary transition-colors">
                  {coachingLink.name}
                </Link>
              </li>
              <li>
                <Link to="/admin" className="text-gray-600 hover:text-primary transition-colors flex items-center gap-1">
                  <Shield className="h-3.5 w-3.5" />
                  Admin
                </Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="font-semibold text-gray-900 mb-4">Contact</h3>
            <ul className="space-y-2">
              <li className="text-gray-600">
                beSportly Technologies Private Limited
              </li>
              <li className="text-gray-600">
                Andheri, Mumbai
              </li>
              <li>
                <a href="mailto:social@besportly.com" className="text-gray-600 hover:text-primary transition-colors">
                  social@besportly.com
                </a>
              </li>
              <li>
                <Link to="/support" className="text-gray-600 hover:text-primary transition-colors">
                  Support
                </Link>
              </li>
              <li>
                <Link to="/ai-support" className="text-gray-600 hover:text-primary transition-colors">
                  AI Support
                </Link>
              </li>
              <li>
                <Link to="/careers" className="text-gray-600 hover:text-primary transition-colors">
                  Careers
                </Link>
              </li>
              <li>
                <Link to="/our-journey" className="text-gray-600 hover:text-primary transition-colors">
                  Our Journey
                </Link>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="mt-12 pt-8 border-t border-gray-200">
          <p className="text-center text-gray-600">
            © {new Date().getFullYear()} beSportly Technologies Private Limited. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
