import { useState } from "react";
import { motion } from "framer-motion";
import { ChessBoard } from "@/components/Games/Chess/ChessBoard";
import { GoBoard } from "@/components/Games/Go/GoBoard";
import { Button } from "@/components/ui/button";
import { Gamepad2, CircleDot, ArrowLeft, Crown, RefreshCw } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Card } from "@/components/ui/card";
import BackButton from "@/components/ui/back-button";
import { useIsMobile } from "@/hooks/use-mobile";

const Games = () => {
  const [selectedGame, setSelectedGame] = useState<"chess" | "go" | null>(null);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleBackToSelection = () => {
    setSelectedGame(null);
  };

  if (selectedGame === "chess") {
    return (
      <div className="min-h-screen bg-white dark:bg-black p-4 md:pt-4 pt-28">
        <Button 
          variant="ghost" 
          onClick={handleBackToSelection}
          className="text-gray-900 dark:text-white mb-4 hover:bg-gray-100 dark:hover:bg-gray-800"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Games
        </Button>
        <ChessBoard />
      </div>
    );
  }

  if (selectedGame === "go") {
    return (
      <div className="min-h-screen bg-white dark:bg-black p-4 md:pt-4 pt-28">
        <Button 
          variant="ghost" 
          onClick={handleBackToSelection}
          className="text-gray-900 dark:text-white mb-4 hover:bg-gray-100 dark:hover:bg-gray-800"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Games
        </Button>
        <GoBoard />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white dark:bg-black p-6 md:pt-6 pt-28 relative">
      {isMobile && (
        <div className="absolute top-12 left-4 z-10">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Button
              onClick={() => navigate(-1)}
              variant="ghost"
              size="sm"
              className="flex items-center justify-center p-2.5 rounded-full backdrop-blur-sm bg-white/90 hover:bg-white shadow-lg shadow-indigo-100/20 border border-indigo-100/30 transition-all duration-300 hover:scale-105"
              aria-label="Go back"
            >
              <ArrowLeft className="h-5 w-5 text-indigo-600" />
            </Button>
          </motion.div>
        </div>
      )}
      
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-8 text-center mt-4">
          Classic Board Games
        </h1>
        <div className="grid md:grid-cols-2 gap-6">
          <motion.div
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <Card 
              className="relative overflow-hidden cursor-pointer group dark:bg-gray-800"
              onClick={() => setSelectedGame("chess")}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-purple-100 to-blue-50 dark:from-purple-900/30 dark:to-blue-900/30 group-hover:opacity-100 transition-opacity" />
              <div className="p-6 relative z-10">
                <div className="flex items-center gap-4 mb-4">
                  <div className="p-3 rounded-full bg-purple-100 dark:bg-purple-900">
                    <Gamepad2 className="w-8 h-8 text-purple-600 dark:text-purple-300" />
                  </div>
                  <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">Chess</h2>
                </div>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  Challenge yourself against our AI in the ultimate game of strategy
                </p>
                <div className="flex items-center text-sm text-purple-600 dark:text-purple-300">
                  <Crown className="w-4 h-4 mr-2" />
                  Multiple difficulty levels
                </div>
              </div>
            </Card>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <Card 
              className="relative overflow-hidden cursor-pointer group dark:bg-gray-800"
              onClick={() => setSelectedGame("go")}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-emerald-50 to-cyan-50 dark:from-emerald-900/30 dark:to-cyan-900/30 group-hover:opacity-100 transition-opacity" />
              <div className="p-6 relative z-10">
                <div className="flex items-center gap-4 mb-4">
                  <div className="p-3 rounded-full bg-emerald-100 dark:bg-emerald-900">
                    <CircleDot className="w-8 h-8 text-emerald-600 dark:text-emerald-300" />
                  </div>
                  <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">Go</h2>
                </div>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  Experience the ancient game of territory and strategy
                </p>
                <div className="flex items-center text-sm text-emerald-600 dark:text-emerald-300">
                  <Crown className="w-4 h-4 mr-2" />
                  Play against friends
                </div>
              </div>
            </Card>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Games;
