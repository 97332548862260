
import { Link, useNavigate } from "react-router-dom";
import { Building, Users, Dumbbell, GraduationCap, CalendarDays } from "lucide-react";
import { useEffect } from "react";
import { useIsMobile } from "@/hooks/use-mobile";

const NotFound = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  useEffect(() => {
    // Automatically redirect mobile users to home page after a short delay
    if (isMobile) {
      const redirectTimer = setTimeout(() => {
        navigate("/");
      }, 2000); // 2 second delay before redirecting
      
      return () => clearTimeout(redirectTimer);
    }
  }, [isMobile, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center relative overflow-hidden bg-gradient-to-br from-purple-50 to-pink-50">
      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-4 -left-4 w-24 h-24 bg-gradient-to-br from-purple-400 to-pink-400 rounded-full opacity-20 animate-float-diagonal-1"></div>
        <div className="absolute top-1/4 right-1/4 w-32 h-32 bg-gradient-to-br from-blue-400 to-teal-400 rounded-full opacity-20 animate-float-diagonal-2"></div>
        <div className="absolute bottom-1/4 left-1/3 w-40 h-40 bg-gradient-to-br from-yellow-400 to-orange-400 rounded-full opacity-20 animate-float-diagonal-3"></div>
        <div className="absolute -bottom-8 -right-8 w-48 h-48 bg-gradient-to-br from-red-400 to-pink-400 rounded-full opacity-20 animate-float-diagonal-4"></div>
      </div>

      <div className="relative z-10 max-w-4xl w-full mx-auto px-4 py-8 text-center">
        {isMobile && (
          <div className="bg-white/80 backdrop-blur-md p-4 mb-8 rounded-xl border border-pink-100 shadow-lg animate-pulse">
            <p className="text-gray-800">Redirecting you to the home page...</p>
          </div>
        )}
        
        <h1 className="text-8xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 via-pink-600 to-blue-600 animate-gradient mb-4">
          404
        </h1>
        
        <div className="text-3xl font-semibold text-gray-800 mb-8 animate-fadeIn">
          Oops! This page seems to have wandered off to play sports! 🏃‍♂️
        </div>

        <p className="text-lg text-gray-600 mb-12 max-w-2xl mx-auto animate-fadeIn delay-200">
          Don't worry! You can explore these exciting destinations instead:
        </p>

        <div className="grid grid-cols-2 md:grid-cols-5 gap-4 max-w-3xl mx-auto">
          <Link
            to="/venues"
            className="group flex flex-col items-center p-4 rounded-xl bg-white shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1 border border-purple-100"
          >
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-br from-purple-500 to-pink-500 text-white mb-3 group-hover:scale-110 transition-transform">
              <Building className="w-6 h-6" />
            </div>
            <span className="text-sm font-medium text-gray-700">Venues</span>
          </Link>

          <Link
            to="/coaches"
            className="group flex flex-col items-center p-4 rounded-xl bg-white shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1 border border-blue-100"
          >
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-br from-blue-500 to-teal-500 text-white mb-3 group-hover:scale-110 transition-transform">
              <Users className="w-6 h-6" />
            </div>
            <span className="text-sm font-medium text-gray-700">Coaches</span>
          </Link>

          <Link
            to="/gyms"
            className="group flex flex-col items-center p-4 rounded-xl bg-white shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1 border border-green-100"
          >
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-br from-green-500 to-teal-500 text-white mb-3 group-hover:scale-110 transition-transform">
              <Dumbbell className="w-6 h-6" />
            </div>
            <span className="text-sm font-medium text-gray-700">Gyms</span>
          </Link>

          <Link
            to="/academies"
            className="group flex flex-col items-center p-4 rounded-xl bg-white shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1 border border-yellow-100"
          >
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-br from-yellow-500 to-orange-500 text-white mb-3 group-hover:scale-110 transition-transform">
              <GraduationCap className="w-6 h-6" />
            </div>
            <span className="text-sm font-medium text-gray-700">Academies</span>
          </Link>

          <Link
            to="/events-and-games"
            className="group flex flex-col items-center p-4 rounded-xl bg-white shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1 border border-red-100"
          >
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-br from-red-500 to-pink-500 text-white mb-3 group-hover:scale-110 transition-transform">
              <CalendarDays className="w-6 h-6" />
            </div>
            <span className="text-sm font-medium text-gray-700">Events</span>
          </Link>
        </div>

        <Link
          to="/"
          className="inline-block mt-12 text-gray-600 hover:text-gray-800 transition-colors"
        >
          ← Back to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
