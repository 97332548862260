import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Lock, Brain, Heart, Trophy, BrainCircuit, Rss, Users, Fingerprint, Sparkles, ArrowRight, MessageSquare } from "lucide-react";
import { ChatMessage } from "@/components/Communities/ChatMessage";
import { ChatInput } from "@/components/Communities/ChatInput";
import { WhatsAppSection } from "@/components/Communities/WhatsAppSection";
import { useToast } from "@/hooks/use-toast";
import { useIsMobile } from "@/hooks/use-mobile";
import { navigateTo } from "@/lib/utils";
import { motion } from "framer-motion";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";

const Communities = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();
  const isMobile = useIsMobile();
  const isTablet = window.innerWidth <= 1024 && window.innerWidth > 768;
  const [selectedRoom, setSelectedRoom] = useState<string | null>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [rooms, setRooms] = useState<any[]>([]);
  const [category, setCategory] = useState("Sports");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchRooms();
  }, []);

  useEffect(() => {
    if (selectedRoom) {
      fetchMessages();
      const subscription = subscribeToMessages();
      return () => {
        subscription();
      };
    }
  }, [selectedRoom]);

  const fetchRooms = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from("chat_rooms")
        .select("*")
        .order("created_at", { ascending: true });
      
      if (error) {
        console.error("Error fetching rooms:", error);
        toast({
          title: "Error",
          description: "Failed to load chat rooms. Please try again.",
          variant: "destructive"
        });
        return;
      }
      setRooms(data || []);
    } catch (err) {
      console.error("Unexpected error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMessages = async () => {
    try {
      const { data, error } = await supabase
        .from("chat_messages")
        .select(`
          *,
          profiles:user_id (
            username,
            full_name
          )
        `)
        .eq("room_id", selectedRoom)
        .order("created_at", { ascending: true });
      
      if (error) {
        console.error("Error fetching messages:", error);
        toast({
          title: "Error",
          description: "Failed to load messages. Please try again.",
          variant: "destructive"
        });
        return;
      }
      setMessages(data || []);
    } catch (err) {
      console.error("Unexpected error:", err);
    }
  };

  const subscribeToMessages = () => {
    const channel = supabase
      .channel(`room-${selectedRoom}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "chat_messages",
          filter: `room_id=eq.${selectedRoom}`,
        },
        async (payload) => {
          if (payload.eventType === "INSERT") {
            const { data: newMessage, error } = await supabase
              .from("chat_messages")
              .select(`
                *,
                profiles:user_id (
                  username,
                  full_name
                )
              `)
              .eq("id", payload.new.id)
              .single();

            if (!error && newMessage) {
              setMessages((current) => [...current, newMessage]);
            }
          } else if (payload.eventType === "DELETE") {
            setMessages((current) => 
              current.filter((msg) => msg.id !== payload.old.id)
            );
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  };

  const handleRoomSelect = (roomId: string) => {
    setSelectedRoom(roomId);
    toast({
      title: "Chat box loaded",
      description: "Scroll down to view the chat box and start messaging!",
      duration: 3000,
    });
    setTimeout(() => {
      const chatBox = document.querySelector("#chat-box");
      if (chatBox) {
        chatBox.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  const handleNavigateToQuizzes = () => {
    navigateTo(navigate, "/quizzes");
  };

  const handleNavigateToLiveFeed = () => {
    navigateTo(navigate, "/live-feed");
  };

  if (!user) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-violet-50 to-white dark:from-gray-900 dark:to-gray-800 pt-16 pb-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h1 
            className="text-4xl font-bold text-center mb-8 bg-gradient-to-r from-violet-600 via-purple-600 to-indigo-600 text-transparent bg-clip-text"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Join Our Communities
          </motion.h1>
          
          <motion.div 
            className="max-w-4xl mx-auto"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <Card className="overflow-hidden border-none shadow-2xl">
              <div className="h-2 bg-gradient-to-r from-violet-500 via-purple-500 to-indigo-500" />
              
              <div className="p-1 bg-gradient-to-br from-violet-50 to-indigo-50">
                <CardContent className={`p-8 space-y-8 bg-white rounded-lg ${isMobile ? "pt-4" : ""}`}>
                  <div className="flex justify-center">
                    <div className="relative">
                      <div className="absolute -inset-4 rounded-full opacity-30 bg-gradient-to-r from-violet-600 to-indigo-600 blur-xl animate-pulse" />
                      <div className="relative bg-gradient-to-r from-violet-600 to-indigo-600 p-4 rounded-full">
                        <Users className="w-10 h-10 text-white" />
                      </div>
                    </div>
                  </div>
                  
                  <div className="space-y-4 text-center">
                    <h3 className="text-3xl font-bold bg-gradient-to-r from-violet-600 via-purple-600 to-indigo-600 bg-clip-text text-transparent">
                      Connect with like-minded enthusiasts
                    </h3>
                    
                    <p className="text-gray-600 text-lg max-w-2xl mx-auto leading-relaxed">
                      Join our vibrant community to discuss sports, share achievements, get advice, and meet fellow athletes and fitness enthusiasts.
                    </p>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {[
                      {
                        icon: <Trophy className="w-5 h-5 text-amber-500" />,
                        title: "Sporting Discussions",
                        description: "Chat about your favorite sports, teams and upcoming events"
                      },
                      {
                        icon: <Heart className="w-5 h-5 text-rose-500" />,
                        title: "Fitness Community",
                        description: "Share workout routines and wellness tips with other members"
                      },
                      {
                        icon: <BrainCircuit className="w-5 h-5 text-blue-500" />,
                        title: "Expert Advice",
                        description: "Get tips from coaches and experienced athletes"
                      }
                    ].map((feature, i) => (
                      <motion.div 
                        key={i}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.4, delay: 0.3 + (i * 0.1) }}
                        className="flex flex-col items-center p-5 rounded-xl bg-gradient-to-br from-violet-50 to-indigo-50 hover:shadow-md transition-all group"
                      >
                        <div className="bg-white p-3 rounded-lg shadow-sm mb-3 group-hover:-translate-y-1 transition-transform">
                          {feature.icon}
                        </div>
                        <h4 className="font-medium text-gray-800 mb-1">{feature.title}</h4>
                        <p className="text-sm text-gray-600 text-center">{feature.description}</p>
                      </motion.div>
                    ))}
                  </div>
                  
                  <div className="pt-2 flex flex-col sm:flex-row gap-4 justify-center items-center">
                    <motion.div
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <Button 
                        onClick={() => navigate("/auth")}
                        className="bg-gradient-to-r from-violet-600 via-purple-600 to-indigo-600 text-white px-8 py-6 h-auto text-lg group relative overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300"
                      >
                        <span className="relative z-10 flex items-center">
                          Sign In
                          <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
                        </span>
                        <span className="absolute inset-0 bg-gradient-to-r from-violet-700 via-purple-700 to-indigo-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                      </Button>
                    </motion.div>
                    
                    <Dialog>
                      <DialogTrigger asChild>
                        <Button variant="outline" className="border-violet-300 text-violet-700 font-medium px-6 py-6 h-auto text-base hover:bg-violet-50 hover:text-violet-800 hover:border-violet-400">
                          Learn More <Sparkles className="ml-2 h-4 w-4" />
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="sm:max-w-md">
                        <DialogHeader>
                          <DialogTitle className="text-center text-xl font-bold bg-gradient-to-r from-violet-600 via-purple-600 to-indigo-600 bg-clip-text text-transparent">
                            Community Benefits
                          </DialogTitle>
                        </DialogHeader>
                        <div className="space-y-4 py-4">
                          <div className="space-y-2">
                            <h4 className="font-medium flex items-center gap-2">
                              <Trophy className="h-4 w-4 text-amber-500" /> 
                              Quizzes and WhatsApp Communities
                            </h4>
                            <p className="text-sm text-gray-500">
                              Access interactive quizzes and join our WhatsApp groups to connect with local enthusiasts.
                            </p>
                          </div>
                          <div className="space-y-2">
                            <h4 className="font-medium flex items-center gap-2">
                              <Users className="h-4 w-4 text-violet-500" /> 
                              Connect with Pros
                            </h4>
                            <p className="text-sm text-gray-500">
                              Interact with professional athletes and coaches who regularly participate in our community.
                            </p>
                          </div>
                          <div className="space-y-2">
                            <h4 className="font-medium flex items-center gap-2">
                              <Fingerprint className="h-4 w-4 text-indigo-500" /> 
                              Personalized Experience
                            </h4>
                            <p className="text-sm text-gray-500">
                              Get recommendations for events, workouts, and content based on your interests.
                            </p>
                          </div>
                          <div className="mt-6 text-center">
                            <Button 
                              onClick={() => navigate("/auth")}
                              className="bg-gradient-to-r from-violet-600 to-indigo-600 w-full"
                            >
                              Join Now
                            </Button>
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                  
                  <div className="text-center text-sm text-gray-500 pt-1">
                    Already have an account? <span className="text-violet-600 font-medium cursor-pointer hover:underline" onClick={() => navigate("/auth")}>Sign in here</span>
                  </div>
                </CardContent>
              </div>
            </Card>
          </motion.div>
        </div>
      </div>
    );
  }

  const SidebarContent = () => (
    <div className="space-y-6">
      <WhatsAppSection />

      <Card className="bg-gradient-to-br from-purple-50 to-pink-50 border-purple-100 shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden group">
        <div className="absolute -right-6 -top-6 w-20 h-20 bg-purple-100 rounded-full opacity-70"></div>
        <div className="absolute right-12 top-20 w-8 h-8 bg-purple-200 rounded-full opacity-50"></div>
        <CardHeader>
          <CardTitle className="flex items-center gap-2 dark:text-black relative z-10">
            <motion.div
              whileHover={{ rotate: 360 }}
              transition={{ duration: 0.6 }}
              className="p-2 bg-purple-100 rounded-full"
            >
              <BrainCircuit className="w-5 h-5 text-purple-600" />
            </motion.div>
            Test Your Knowledge
          </CardTitle>
        </CardHeader>
        <CardContent className="relative z-10">
          <p className="text-gray-600 dark:text-black mb-4">
            Challenge yourself with our sports and fitness quizzes!
          </p>
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <Button 
              onClick={handleNavigateToQuizzes}
              className="w-full bg-gradient-to-r from-purple-600 to-pink-600 text-white shadow-md hover:shadow-lg group-hover:from-purple-700 group-hover:to-pink-700 transition-all duration-300"
            >
              <span className="flex items-center">
                Take a Quiz
                <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
              </span>
            </Button>
          </motion.div>
        </CardContent>
      </Card>

      {user && (
        <Card className="bg-gradient-to-br from-blue-50 to-cyan-50 border-blue-100 shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden group">
          <div className="absolute -right-6 -top-6 w-20 h-20 bg-blue-100 rounded-full opacity-70"></div>
          <div className="absolute right-12 top-20 w-8 h-8 bg-blue-200 rounded-full opacity-50"></div>
          <CardHeader>
            <CardTitle className="flex items-center gap-2 dark:text-black relative z-10">
              <motion.div
                whileHover={{ y: -5 }}
                transition={{ duration: 0.3 }}
                className="p-2 bg-blue-100 rounded-full"
              >
                <Rss className="w-5 h-5 text-blue-600" />
              </motion.div>
              Live Sports Feed
            </CardTitle>
          </CardHeader>
          <CardContent className="relative z-10">
            <p className="text-gray-600 dark:text-black mb-4">
              Get real-time updates on sports events and matches!
            </p>
            <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
              <Button 
                onClick={handleNavigateToLiveFeed}
                className="w-full bg-gradient-to-r from-blue-600 to-cyan-600 text-white shadow-md hover:shadow-lg group-hover:from-blue-700 group-hover:to-cyan-700 transition-all duration-300"
              >
                <span className="flex items-center">
                  View Live Feed
                  <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
                </span>
              </Button>
            </motion.div>
          </CardContent>
        </Card>
      )}
    </div>
  );

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white pt-16 md:pt-32 pb-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h1 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-3xl font-bold text-center mb-16 md:mb-16 mt-8 md:mt-0 dark:text-black bg-clip-text text-transparent bg-gradient-to-r from-violet-600 via-purple-500 to-indigo-600"
        >
          Communities
        </motion.h1>
        
        {(isMobile || isTablet) && (
          <>
            <motion.div 
              className="mb-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <SidebarContent />
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Tabs defaultValue="Sports" className="w-full" onValueChange={setCategory}>
                <TabsList className="grid w-full grid-cols-2 p-1 rounded-lg bg-transparent">
                  <TabsTrigger value="Sports" className="dark:text-black rounded-md data-[state=active]:bg-white data-[state=active]:text-purple-600 data-[state=active]:shadow-sm py-3">
                    <Trophy className="w-4 h-4 mr-2" />
                    Sports
                  </TabsTrigger>
                  <TabsTrigger value="Health" className="dark:text-black rounded-md data-[state=active]:bg-white data-[state=active]:text-purple-600 data-[state=active]:shadow-sm py-3">
                    <Heart className="w-4 h-4 mr-2" />
                    Health & Wellness
                  </TabsTrigger>
                </TabsList>

                <TabsContent value="Sports" className="mt-6">
                  <motion.div 
                    className="grid grid-cols-2 gap-3"
                    variants={container}
                    initial="hidden"
                    animate="show"
                  >
                    {rooms
                      .filter((room) => room.category === "Sports")
                      .map((room) => (
                        <motion.div key={room.id} variants={item}>
                          <Card
                            className={`cursor-pointer transition-all duration-300 hover:shadow-xl border border-transparent hover:border-purple-200 ${
                              selectedRoom === room.id ? "ring-2 ring-primary shadow-lg" : "bg-white hover:bg-purple-50"
                            }`}
                            onClick={() => handleRoomSelect(room.id)}
                          >
                            <CardHeader className="p-3">
                              <CardTitle className="flex items-center gap-2 text-sm dark:text-black">
                                <div className={`p-1.5 rounded-full ${selectedRoom === room.id ? 'bg-purple-100' : 'bg-purple-50'}`}>
                                  <Trophy className={`w-4 h-4 ${selectedRoom === room.id ? 'text-purple-600' : 'text-purple-500'}`} />
                                </div>
                                {room.name}
                              </CardTitle>
                            </CardHeader>
                          </Card>
                        </motion.div>
                      ))}
                  </motion.div>
                </TabsContent>

                <TabsContent value="Health" className="mt-6">
                  <motion.div 
                    className="grid grid-cols-2 gap-3"
                    variants={container}
                    initial="hidden"
                    animate="show"
                  >
                    {rooms
                      .filter((room) => room.category === "Health")
                      .map((room) => (
                        <motion.div key={room.id} variants={item}>
                          <Card
                            className={`cursor-pointer transition-all duration-300 hover:shadow-xl border border-transparent hover:border-pink-200 ${
                              selectedRoom === room.id ? "ring-2 ring-pink-500 shadow-lg" : "bg-white hover:bg-pink-50"
                            }`}
                            onClick={() => handleRoomSelect(room.id)}
                          >
                            <CardHeader className="p-3">
                              <CardTitle className="flex items-center gap-2 text-sm dark:text-black">
                                <div className={`p-1.5 rounded-full ${selectedRoom === room.id ? 'bg-pink-100' : 'bg-pink-50'}`}>
                                  <Heart className={`w-4 h-4 ${selectedRoom === room.id ? 'text-pink-600' : 'text-pink-500'}`} />
                                </div>
                                {room.name}
                              </CardTitle>
                            </CardHeader>
                          </Card>
                        </motion.div>
                      ))}
                  </motion.div>
                </TabsContent>
              </Tabs>
            </motion.div>
          </>
        )}

        {!isMobile && !isTablet && (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <motion.div 
              className="lg:col-span-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Tabs defaultValue="Sports" className="w-full" onValueChange={setCategory}>
                <TabsList className="grid w-full grid-cols-2 p-1 rounded-lg bg-transparent">
                  <TabsTrigger value="Sports" className="dark:text-black data-[state=active]:bg-white data-[state=active]:text-purple-600 data-[state=active]:shadow-sm py-3 rounded-md">
                    <Trophy className="w-4 h-4 mr-2" />
                    Sports
                  </TabsTrigger>
                  <TabsTrigger value="Health" className="dark:text-black data-[state=active]:bg-white data-[state=active]:text-purple-600 data-[state=active]:shadow-sm py-3 rounded-md">
                    <Heart className="w-4 h-4 mr-2" />
                    Health & Wellness
                  </TabsTrigger>
                </TabsList>

                <TabsContent value="Sports" className="mt-6">
                  <motion.div 
                    className="grid grid-cols-1 md:grid-cols-2 gap-4"
                    variants={container}
                    initial="hidden"
                    animate="show"
                  >
                    {rooms
                      .filter((room) => room.category === "Sports")
                      .map((room) => (
                        <motion.div key={room.id} variants={item}>
                          <Card
                            className={`cursor-pointer transition-all duration-300 hover:shadow-xl group border border-transparent hover:border-purple-200 ${
                              selectedRoom === room.id ? "ring-2 ring-primary bg-purple-50" : "bg-white hover:bg-purple-50"
                            }`}
                            onClick={() => handleRoomSelect(room.id)}
                          >
                            <CardHeader>
                              <CardTitle className="flex items-center gap-3 dark:text-black">
                                <motion.div 
                                  whileHover={{ rotate: 360 }}
                                  transition={{ duration: 0.6 }}
                                  className={`p-2 rounded-full ${selectedRoom === room.id ? 'bg-purple-100' : 'bg-purple-50 group-hover:bg-purple-100'}`}
                                >
                                  <Trophy className={`w-5 h-5 ${selectedRoom === room.id ? 'text-purple-600' : 'text-purple-500'}`} />
                                </motion.div>
                                {room.name}
                                {selectedRoom === room.id && (
                                  <motion.div
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1 }}
                                    className="ml-auto"
                                  >
                                    <span className="text-xs font-medium bg-purple-100 text-purple-700 px-2 py-1 rounded-full">Active</span>
                                  </motion.div>
                                )}
                              </CardTitle>
                            </CardHeader>
                          </Card>
                        </motion.div>
                      ))}
                  </motion.div>
                </TabsContent>

                <TabsContent value="Health" className="mt-6">
                  <motion.div 
                    className="grid grid-cols-1 md:grid-cols-2 gap-4"
                    variants={container}
                    initial="hidden"
                    animate="show"
                  >
                    {rooms
                      .filter((room) => room.category === "Health")
                      .map((room) => (
                        <motion.div key={room.id} variants={item}>
                          <Card
                            className={`cursor-pointer transition-all duration-300 hover:shadow-xl group border border-transparent hover:border-pink-200 ${
                              selectedRoom === room.id ? "ring-2 ring-pink-500 bg-pink-50" : "bg-white hover:bg-pink-50"
                            }`}
                            onClick={() => handleRoomSelect(room.id)}
                          >
                            <CardHeader>
                              <CardTitle className="flex items-center gap-3 dark:text-black">
                                <motion.div 
                                  whileHover={{ scale: 1.1 }}
                                  transition={{ duration: 0.3 }}
                                  className={`p-2 rounded-full ${selectedRoom === room.id ? 'bg-pink-100' : 'bg-pink-50 group-hover:bg-pink-100'}`}
                                >
                                  <Heart className={`w-5 h-5 ${selectedRoom === room.id ? 'text-pink-600' : 'text-pink-500'}`} />
                                </motion.div>
                                {room.name}
                                {selectedRoom === room.id && (
                                  <motion.div
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1 }}
                                    className="ml-auto"
                                  >
                                    <span className="text-xs font-medium bg-pink-100 text-pink-700 px-2 py-1 rounded-full">Active</span>
                                  </motion.div>
                                )}
                              </CardTitle>
                            </CardHeader>
                          </Card>
                        </motion.div>
                      ))}
                  </motion.div>
                </TabsContent>
              </Tabs>
            </motion.div>

            <motion.div 
              className="space-y-6"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <SidebarContent />
            </motion.div>
          </div>
        )}

        {selectedRoom && (
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Card className="mt-8 shadow-xl border-purple-100 overflow-hidden" id="chat-box">
              <div className="h-2 bg-gradient-to-r from-purple-500 via-fuchsia-500 to-pink-500" />
              <CardContent className="p-6">
                <ScrollArea className="h-[400px] pr-4 rounded-md">
                  <div className="space-y-4 p-2">
                    {messages.length > 0 ? (
                      messages.map((msg) => (
                        <ChatMessage
                          key={msg.id}
                          message={msg}
                          onDelete={fetchMessages}
                        />
                      ))
                    ) : (
                      <div className="flex flex-col items-center justify-center h-[320px] text-center">
                        <motion.div
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ duration: 0.5 }}
                          className="bg-purple-100 p-4 rounded-full mb-4"
                        >
                          <MessageSquare className="h-10 w-10 text-purple-500" />
                        </motion.div>
                        <h3 className="text-lg font-medium text-gray-700 mb-1">Start the conversation</h3>
                        <p className="text-gray-500 max-w-xs">Be the first to send a message in this chat room!</p>
                      </div>
                    )}
                  </div>
                </ScrollArea>

                <div className="mt-4">
                  <ChatInput selectedRoom={selectedRoom} />
                </div>
              </CardContent>
            </Card>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Communities;
