
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { CheckCircle2, XCircle, Mail, MessageSquare, ArrowRight, Camera } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useIsMobile } from "@/hooks/use-mobile";

interface BookingDetails {
  venue_name: string;
  facility_name: string;
  booking_date: string;
  start_time: string;
  end_time: string;
  total_amount: number;
  customer_name?: string;
  customer_email?: string;
  customer_phone?: string;
  venue_id?: string;
}

const logMobileBooking = async (logType: string, message: string, bookingId?: string, userId?: string, details?: any) => {
  try {
    await supabase.functions.invoke('log-mobile-booking', {
      body: { 
        log_type: logType, 
        message, 
        booking_id: bookingId, 
        user_id: userId,
        details: details || {}
      }
    });
  } catch (error) {
    console.error("Error logging:", error);
  }
};

const BookingConfirmation = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [bookingDetails, setBookingDetails] = useState<BookingDetails | null>(null);
  const [notificationSent, setNotificationSent] = useState(false);
  const [dbUpdateAttempted, setDbUpdateAttempted] = useState(false);
  const [dbUpdateResult, setDbUpdateResult] = useState<string | null>(null);
  const isMobile = useIsMobile();

  const status = searchParams.get("status");
  const txnid = searchParams.get("txnid");
  const isSuccess = status === "success";

  useEffect(() => {
    if (!isSuccess && !loading) {
      const refreshTimer = setTimeout(() => {
        window.location.reload();
      }, 1500);
      
      return () => clearTimeout(refreshTimer);
    }
  }, [isSuccess, loading]);

  const sendBookingNotifications = async (details: BookingDetails) => {
    if (!details.customer_email && !details.customer_phone) {
      console.warn("No contact information available for sending notifications");
      return;
    }

    try {
      console.log("Calling send-booking-confirmation edge function with data:", {
        bookingId: txnid,
        customerInfo: {
          name: details.customer_name,
          email: details.customer_email,
          phone: details.customer_phone,
          venue_name: details.venue_name,
          facility_name: details.facility_name,
          booking_date: details.booking_date,
          start_time: details.start_time,
          end_time: details.end_time,
          total_amount: details.total_amount,
          venue_id: details.venue_id
        }
      });
      
      const { data, error } = await supabase.functions.invoke('send-booking-confirmation', {
        body: { 
          bookingId: txnid,
          customerInfo: {
            name: details.customer_name,
            email: details.customer_email,
            phone: details.customer_phone,
            venue_name: details.venue_name,
            facility_name: details.facility_name,
            booking_date: details.booking_date,
            start_time: details.start_time,
            end_time: details.end_time,
            total_amount: details.total_amount,
            venue_id: details.venue_id
          }
        }
      });
      
      if (error) {
        console.error("Error from edge function:", error);
        throw error;
      }
      
      console.log("Notification response from edge function:", data);
      setNotificationSent(true);
      
    } catch (error) {
      console.error("Error sending notifications:", error);
    }
  };

  const updateBookingInDatabase = async (details: BookingDetails) => {
    if (!txnid || !details || !details.venue_id || !user) {
      await logMobileBooking(
        "error", 
        "Missing required data for database update", 
        txnid || null,
        user?.id,
        {
          txnid,
          venueId: details?.venue_id,
          userId: user?.id
        }
      );
      return false;
    }

    try {
      await logMobileBooking(
        "attempt", 
        "Attempting to migrate mobile booking to database", 
        txnid,
        user.id,
        {
          bookingId: txnid,
          venueId: details.venue_id,
          userId: user.id,
          date: details.booking_date,
          startTime: details.start_time,
          endTime: details.end_time
        }
      );
      
      const { data, error } = await supabase.rpc('migrate_mobile_booking', {
        p_booking_id: txnid,
        p_venue_id: details.venue_id,
        p_user_id: user.id,
        p_booking_date: details.booking_date,
        p_start_time: details.start_time,
        p_end_time: details.end_time,
        p_total_amount: details.total_amount,
        p_facility_name: details.facility_name
      });
      
      if (error) {
        await logMobileBooking(
          "error", 
          "Error migrating mobile booking to database", 
          txnid,
          user.id,
          { error: error.message }
        );
        setDbUpdateResult(`Error: ${error.message}`);
        return false;
      }
      
      await logMobileBooking(
        "success", 
        "Mobile booking migration result", 
        txnid,
        user.id,
        { result: data }
      );
      
      setDbUpdateResult(data === true ? "Success: Booking migrated to database" : "Info: Booking already exists in database");
      return true;
    } catch (error) {
      await logMobileBooking(
        "exception", 
        "Exception during database migration", 
        txnid,
        user.id,
        { error: (error as Error).message }
      );
      
      setDbUpdateResult(`Exception: ${(error as Error).message}`);
      return false;
    }
  };

  useEffect(() => {
    if (isSuccess && bookingDetails && isMobile && user && !dbUpdateAttempted) {
      logMobileBooking(
        "info", 
        "Mobile user with successful booking, attempting database migration",
        txnid,
        user.id
      );
      
      setDbUpdateAttempted(true);
      
      setTimeout(() => {
        updateBookingInDatabase(bookingDetails)
          .catch(err => {
            logMobileBooking(
              "error", 
              "Failed to migrate booking to database", 
              txnid,
              user.id,
              { error: err.message }
            );
            setDbUpdateResult(`Failed: ${err.message}`);
          });
      }, 500);
    }
  }, [bookingDetails, isSuccess, isMobile, user, dbUpdateAttempted]);

  useEffect(() => {
    const fetchBookingDetails = async () => {
      if (!txnid) {
        setError("No transaction ID found");
        setLoading(false);
        return;
      }

      try {
        console.log("Fetching booking details for transaction:", txnid);
        const bookingId = txnid.split('_')[0];
        
        const localBookingData = localStorage.getItem(`booking_${bookingId}`);
        
        if (localBookingData) {
          try {
            console.log("Found booking data in localStorage");
            const localBooking = JSON.parse(localBookingData);
            
            const bookingDetails: BookingDetails = {
              venue_name: localBooking.venue_name || 'Not specified',
              facility_name: localBooking.facility_name || 'Not specified',
              booking_date: localBooking.booking_date || 'Not specified',
              start_time: localBooking.start_time || 'Not specified',
              end_time: localBooking.end_time || 'Not specified',
              total_amount: localBooking.total_amount || 0
            };
            
            const customerInfoStr = localStorage.getItem(`booking_${bookingId}_customer`);
            
            if (customerInfoStr) {
              console.log("Found customer info in localStorage, will be used for notifications");
              try {
                const customerInfo = JSON.parse(customerInfoStr);
                if (customerInfo.name) bookingDetails.customer_name = customerInfo.name;
                if (customerInfo.email) bookingDetails.customer_email = customerInfo.email;
                if (customerInfo.phone) bookingDetails.customer_phone = customerInfo.phone;
                if (customerInfo.venue_id) bookingDetails.venue_id = customerInfo.venue_id;
              } catch (e) {
                console.error("Error parsing customer info:", e);
              }
            }
            
            setBookingDetails(bookingDetails);
            
            if (isSuccess) {
              localStorage.removeItem(`booking_${bookingId}`);
              localStorage.removeItem(`booking_${bookingId}_customer`);
            }
            
            setLoading(false);
            return;
          } catch (e) {
            console.error("Error parsing local booking data:", e);
          }
        }
        
        console.log("Looking up booking in database:", bookingId);
        
        const { data: bookingData, error: bookingError } = await supabase
          .from('bookings')
          .select(`
            *,
            venue:venues(name),
            user:profiles(full_name, email, phone)
          `)
          .eq('id', bookingId)
          .single();

        if (bookingError) {
          console.error("Error fetching booking from database:", bookingError);
          throw bookingError;
        }

        if (bookingData) {
          console.log("Found booking in database:", bookingData);
          const details: BookingDetails = {
            venue_name: bookingData.venue?.name || 'Not specified',
            facility_name: bookingData.facility_name || 'Not specified',
            booking_date: new Date(bookingData.booking_date).toLocaleDateString(),
            start_time: bookingData.start_time,
            end_time: bookingData.end_time,
            total_amount: bookingData.total_amount,
            venue_id: bookingData.venue_id
          };
          
          if (bookingData.user?.full_name) details.customer_name = bookingData.user.full_name;
          if (bookingData.user?.email) details.customer_email = bookingData.user.email;
          if (bookingData.user?.phone) details.customer_phone = bookingData.user.phone?.replace(/^\+\d{2}/, '');
          
          setBookingDetails(details);
        } else {
          console.error("No booking found in database");
          throw new Error("Booking not found");
        }
      } catch (err) {
        console.error('Error retrieving booking:', err);
        setError('Failed to retrieve booking details');
      } finally {
        setLoading(false);
      }
    };

    fetchBookingDetails();
  }, [txnid, isSuccess]);

  useEffect(() => {
    if (isSuccess && bookingDetails && isMobile && user) {
      try {
        const userId = user.id;
        const mobileBookingData = {
          id: txnid || `temp-${Date.now()}`,
          venue_id: bookingDetails.venue_id || '',
          booking_date: bookingDetails.booking_date,
          start_time: bookingDetails.start_time,
          end_time: bookingDetails.end_time,
          total_amount: bookingDetails.total_amount,
          status: 'confirmed',
          payment_status: 'paid',
          facility_name: bookingDetails.facility_name,
          venue: {
            name: bookingDetails.venue_name,
            address: 'Search on Google Maps',
            city: ''
          },
          timestamp: Date.now(),
          isPermanent: true
        };
        
        const userBookingsKey = `mobile_bookings_${userId}`;
        
        const existingMobileBookings = JSON.parse(localStorage.getItem(userBookingsKey) || '[]');
        
        const isDuplicate = existingMobileBookings.some(
          (booking: any) => booking.id === mobileBookingData.id
        );
        
        if (!isDuplicate) {
          const updatedMobileBookings = [mobileBookingData, ...existingMobileBookings];
          localStorage.setItem(userBookingsKey, JSON.stringify(updatedMobileBookings));
          console.log("Mobile booking saved permanently to user-specific localStorage:", mobileBookingData);
        }
      } catch (err) {
        console.error("Error saving mobile booking to localStorage:", err);
      }
    }
  }, [txnid, isSuccess, isMobile, user, bookingDetails]);

  useEffect(() => {
    if (isSuccess && bookingDetails && isMobile && !notificationSent) {
      console.log("Mobile user with successful booking, sending notifications");
      sendBookingNotifications(bookingDetails)
        .catch(err => console.error("Failed to send notifications:", err));
    }
  }, [bookingDetails, isSuccess, isMobile, notificationSent]);
  
  useEffect(() => {
    if (dbUpdateResult) {
      console.log("Database update attempt result:", dbUpdateResult);
    }
  }, [dbUpdateResult]);

  if (loading) {
    return (
      <div className="min-h-screen pt-32 flex items-center justify-center">
        <div className="max-w-md mx-auto px-4">
          <div className="flex flex-col items-center justify-center p-8 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
            <div className="w-20 h-20 rounded-full bg-green-500 flex items-center justify-center mb-4">
              <CheckCircle2 className="h-10 w-10 text-white" />
            </div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-2 text-center">
              Payment Successful!
            </h1>
            <p className="text-gray-500 dark:text-gray-400 text-center mb-2">
              We're confirming your booking...
            </p>
            <p className="text-gray-500 dark:text-gray-400 text-center mb-6">
              Please keep the app open until you see a confirmation and receive an SMS.
            </p>
            <div className="flex space-x-2">
              <div className="h-2 w-2 bg-gray-300 rounded-full animate-bounce" 
                  style={{ animationDelay: "0ms" }}></div>
              <div className="h-2 w-2 bg-gray-300 rounded-full animate-bounce" 
                  style={{ animationDelay: "300ms" }}></div>
              <div className="h-2 w-2 bg-gray-300 rounded-full animate-bounce" 
                  style={{ animationDelay: "600ms" }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen pt-32 pb-16 bg-gray-50 dark:bg-gray-900">
      <div className="max-w-lg mx-auto px-4">
        <Card className="p-8 text-center space-y-6">
          {isSuccess ? (
            <>
              <CheckCircle2 className="w-16 h-16 mx-auto text-green-500" />
              <h1 className="text-2xl font-bold">Booking Confirmed!</h1>
              {bookingDetails && (
                <div className="text-left space-y-4 mt-6">
                  <h2 className="font-semibold text-lg">Booking Details:</h2>
                  <div className="space-y-2 text-gray-600">
                    <p><span className="font-medium">Venue:</span> {bookingDetails.venue_name}</p>
                    <p><span className="font-medium">Facility:</span> {bookingDetails.facility_name}</p>
                    <p><span className="font-medium">Date:</span> {bookingDetails.booking_date}</p>
                    <p><span className="font-medium">Time:</span> {bookingDetails.start_time} - {bookingDetails.end_time}</p>
                    <p><span className="font-medium">Amount Paid:</span> ₹{bookingDetails.total_amount}</p>
                  </div>
                </div>
              )}
              
              <Alert className="bg-indigo-50 border-indigo-200 mt-4">
                <Camera className="h-4 w-4 text-indigo-600" />
                <AlertDescription className="text-indigo-700">
                  Please take a screenshot of this confirmation and show it at the venue along with your SMS or email.
                </AlertDescription>
              </Alert>
              
              <div className="space-y-4">
                <p className="text-gray-600">
                  {isMobile && notificationSent 
                    ? "We've sent your booking details via:" 
                    : "Your booking details are available via:"}
                </p>
                <div className="flex justify-center gap-6">
                  <div className="flex items-center gap-2 text-gray-600">
                    <Mail className="w-5 h-5" />
                    <span>Email</span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-600">
                    <MessageSquare className="w-5 h-5" />
                    <span>SMS</span>
                  </div>
                </div>
                
                <p className="text-sm text-gray-500 mt-2">
                  Remember to show your SMS or email confirmation when you arrive at the venue.
                </p>
                
                {user && (
                  <Button
                    className="w-full mt-6"
                    onClick={() => navigate("/my-bookings")}
                  >
                    View My Bookings
                    <ArrowRight className="w-4 h-4 ml-2" />
                  </Button>
                )}
              </div>
            </>
          ) : (
            <div className="space-y-4">
              <XCircle className="w-16 h-16 mx-auto text-red-500" />
              <h1 className="text-2xl font-bold">Booking Failed</h1>
              <p className="text-gray-600">
                {error || "Your booking could not be completed."}
              </p>
              <div className="text-sm text-gray-500 mt-4">
                <p>If any amount was deducted from your account, it will be automatically refunded within 48 hours.</p>
                <p className="mt-2">Need help? Contact our support team:</p>
                <div className="mt-2 space-y-2">
                  <Link to="/support" className="text-blue-600 hover:underline block">
                    Visit Support Page
                  </Link>
                  <a href="mailto:social@besportly.com" className="text-blue-600 hover:underline block">
                    social@besportly.com
                  </a>
                </div>
              </div>
              <Button
                className="w-full"
                onClick={() => navigate("/venues")}
              >
                Go Back
              </Button>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default BookingConfirmation;
