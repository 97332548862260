import { useState, useEffect } from "react";
import { MapPin, Users, ShoppingBag, Newspaper, Trophy, Dumbbell, Calendar, Target, Heart, Flame, Zap, Award, Sparkles } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { popularCities } from "@/utils/cityData";
import { useIsMobile } from "@/hooks/use-mobile";
import SportCircleButtons from "./SportCircleButtons";
import MagicButton from "./MagicButton";
import MobileTrendingWidget from "./MobileTrendingWidget";
import MobileStatsWidget from "./MobileStatsWidget";
import WeatherWidget from "./WeatherWidget";
import { supabase } from "@/integrations/supabase/client";

const Hero = () => {
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedType, setSelectedType] = useState<string>("");
  const [priceRange, setPriceRange] = useState<string>("");
  const [titleColor, setTitleColor] = useState("from-[#ff00ff] to-[#00bfff]");
  const [searchText, setSearchText] = useState("five a side football");
  const [searchIcon, setSearchIcon] = useState(<Trophy className="w-5 h-5" />);
  const [currentTaglineIndex, setCurrentTaglineIndex] = useState(0);
  const [currentNoteIndex, setCurrentNoteIndex] = useState(0);
  const [viewInitialized, setViewInitialized] = useState(false);
  const [user, setUser] = useState<any>(null);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const taglines = [
    { text: "Venues", icon: <MapPin className="w-5 h-5" /> },
    { text: "Coaches", icon: <Users className="w-5 h-5" /> },
    { text: "Gyms", icon: <Dumbbell className="w-5 h-5" /> },
    { text: "Academies", icon: <Trophy className="w-5 h-5" /> },
    { text: "Events", icon: <Calendar className="w-5 h-5" /> },
    { text: "Products", icon: <ShoppingBag className="w-5 h-5" /> },
    { text: "Communities", icon: <Users className="w-5 h-5 ml-1" /> },
  ];

  const inspirationalNotes = [
    { text: "Treat yourself to a game today!", icon: <Zap className="w-6 h-6" /> },
    { text: "Level up your fitness journey!", icon: <Flame className="w-6 h-6" /> },
    { text: "Find your perfect training spot!", icon: <Target className="w-6 h-6" /> },
    { text: "Join a community that moves!", icon: <Heart className="w-6 h-6" /> },
    { text: "Discover your sporting passion!", icon: <Sparkles className="w-6 h-6" /> },
  ];

  useEffect(() => {
    if (typeof isMobile !== 'undefined' && !viewInitialized) {
      setViewInitialized(true);
    }
  }, [isMobile, viewInitialized]);

  useEffect(() => {
    const checkUser = async () => {
      const { data } = await supabase.auth.getUser();
      setUser(data.user);
    };
    
    checkUser();
    
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUser(session?.user || null);
      }
    );
    
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const colors = [
      "from-[#ff00ff] to-[#00bfff]",
      "from-[#8B5CF6] to-[#D946EF]",
      "from-[#F97316] to-[#0EA5E9]",
      "from-[#9b87f5] to-[#33C3F0]",
    ];
    let currentIndex = 0;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % colors.length;
      setTitleColor(colors[currentIndex]);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const searchItems = [
      { text: "five a side football", icon: <Trophy className="w-5 h-5" /> },
      { text: "personal trainer", icon: <Dumbbell className="w-5 h-5" /> },
      { text: "badminton academy", icon: <Trophy className="w-5 h-5" /> },
      { text: "yoga classes", icon: <Heart className="w-5 h-5" /> },
      { text: "cricket practice net", icon: <Target className="w-5 h-5" /> },
      { text: "swimming lessons", icon: <Award className="w-5 h-5" /> },
      { text: "boxing gym", icon: <Flame className="w-5 h-5" /> },
      { text: "table tennis club", icon: <Zap className="w-5 h-5" /> },
    ];
    let currentIndex = 0;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % searchItems.length;
      setSearchText(searchItems[currentIndex].text);
      setSearchIcon(searchItems[currentIndex].icon);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isMobile) {
      const taglineInterval = setInterval(() => {
        setCurrentTaglineIndex((prevIndex) => (prevIndex + 1) % taglines.length);
      }, 2000);
      
      const noteInterval = setInterval(() => {
        setCurrentNoteIndex((prevIndex) => (prevIndex + 1) % inspirationalNotes.length);
      }, 3000);
      
      return () => {
        clearInterval(taglineInterval);
        clearInterval(noteInterval);
      };
    }
  }, [isMobile]);

  const handleSearch = () => {
    navigate("/search");
  };

  if (!viewInitialized) {
    return <div className="min-h-screen flex items-center justify-center">
      <div className="w-12 h-12 rounded-full border-4 border-purple-500 border-t-transparent animate-spin"></div>
    </div>;
  }

  return (
    <div className={`relative min-h-screen md:min-h-[85vh] w-full bg-gradient-to-b from-white to-gray-50 dark:from-gray-900 dark:to-black ${user ? 'pb-12 md:pb-6' : 'pb-14'}`}>
      <div className="absolute inset-0 w-full h-full md:h-auto bg-gradient-to-r from-[#ff00ff] to-[#00bfff] opacity-10 animate-gradient">
        <div className="absolute inset-0 overflow-hidden">
          <div className="sports-icon football animate-float-diagonal-1" />
          <div className="sports-icon cricket-ball animate-float-diagonal-2" />
          <div className="sports-icon basketball animate-float-diagonal-3" />
          <div className="sports-icon tennis animate-float-diagonal-4" />
        </div>
      </div>
      
      <div className="relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-center pt-20 md:pt-36 hero-content">
        {isMobile ? (
          <div className="mb-5">
            <WeatherWidget />
          </div>
        ) : (
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#ff00ff] to-[#00bfff] mb-3 animate-fadeIn">
            beSportly: India Plays Here
          </h1>
        )}
        
        {!isMobile && (
          <p className={`text-xl bg-clip-text text-transparent bg-gradient-to-r ${titleColor} mb-12 animate-fadeIn transition-colors duration-500`} style={{ animationDelay: "0.2s" }}>
            Find venues, coaches, gyms and academies at super competitive prices
          </p>
        )}
        
        <div className="max-w-4xl mx-auto space-y-4 md:space-y-8 animate-fadeIn" style={{ animationDelay: "0.4s" }}>
          {!isMobile && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6">
              <Select value={selectedCity} onValueChange={setSelectedCity}>
                <SelectTrigger className="h-12 md:h-14 bg-white/95 dark:bg-gray-800/95 dark:text-white backdrop-blur-md rounded-xl shadow-[0_4px_14px_rgba(0,0,0,0.1)] border border-gray-100/50 dark:border-gray-700/50 hover:border-purple-300 dark:hover:border-purple-600 transition-all duration-300 bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-900">
                  <SelectValue placeholder="Select City" className="flex items-center">
                    <span className="flex items-center gap-2">
                      {selectedCity ? (
                        <>
                          <MapPin className="w-4 h-4 text-pink-500" />
                          {selectedCity}
                        </>
                      ) : (
                        <>
                          <MapPin className="w-4 h-4 text-purple-500" />
                          <span>Select City</span>
                        </>
                      )}
                    </span>
                  </SelectValue>
                </SelectTrigger>
                <SelectContent position="popper" className="bg-white/95 dark:bg-gray-800/95 backdrop-blur-md border border-gray-100/50 dark:border-gray-700/50 rounded-xl shadow-[0_8px_30px_rgba(0,0,0,0.12)]">
                  <SelectItem value="all">All Cities</SelectItem>
                  {popularCities.map((city) => (
                    <SelectItem key={city} value={city}>
                      {city}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <Select value={selectedType} onValueChange={setSelectedType}>
                <SelectTrigger className="h-12 md:h-14 bg-white/95 dark:bg-gray-800/95 dark:text-white backdrop-blur-md rounded-xl shadow-[0_4px_14px_rgba(0,0,0,0.1)] border border-gray-100/50 dark:border-gray-700/50 hover:border-blue-300 dark:hover:border-blue-600 transition-all duration-300 bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-900">
                  <SelectValue placeholder="Select Type" className="flex items-center">
                    <span className="flex items-center gap-2">
                      {selectedType ? (
                        <>
                          {selectedType === "venue" && <MapPin className="w-4 h-4 text-violet-500" />}
                          {selectedType === "coach" && <Users className="w-4 h-4 text-blue-500" />}
                          {selectedType === "gym" && <Dumbbell className="w-4 h-4 text-emerald-500" />}
                          {selectedType === "academy" && <Trophy className="w-4 h-4 text-amber-500" />}
                          {selectedType.charAt(0).toUpperCase() + selectedType.slice(1)}
                        </>
                      ) : (
                        <>
                          <Trophy className="w-4 h-4 text-blue-500" />
                          <span>Select Type</span>
                        </>
                      )}
                    </span>
                  </SelectValue>
                </SelectTrigger>
                <SelectContent position="popper" className="bg-white/95 dark:bg-gray-800/95 backdrop-blur-md border border-gray-100/50 dark:border-gray-700/50 rounded-xl shadow-[0_8px_30px_rgba(0,0,0,0.12)]">
                  <SelectItem value="venue">Venue</SelectItem>
                  <SelectItem value="coach">Coach</SelectItem>
                  <SelectItem value="gym">Gym</SelectItem>
                  <SelectItem value="academy">Academy</SelectItem>
                </SelectContent>
              </Select>

              <Select value={priceRange} onValueChange={setPriceRange}>
                <SelectTrigger className="h-12 md:h-14 bg-white/95 dark:bg-gray-800/95 dark:text-white backdrop-blur-md rounded-xl shadow-[0_4px_14px_rgba(0,0,0,0.1)] border border-gray-100/50 dark:border-gray-700/50 hover:border-green-300 dark:hover:border-green-600 transition-all duration-300 bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-900">
                  <SelectValue placeholder="Price Range" className="flex items-center">
                    <span className="flex items-center gap-2">
                      {priceRange ? (
                        <>
                          <Award className="w-4 h-4 text-cyan-500" />
                          {priceRange === "all" ? "Any Price" : priceRange}
                        </>
                      ) : (
                        <>
                          <Award className="w-4 h-4 text-emerald-500" />
                          <span>Price Range</span>
                        </>
                      )}
                    </span>
                  </SelectValue>
                </SelectTrigger>
                <SelectContent position="popper" className="bg-white/95 dark:bg-gray-800/95 backdrop-blur-md border border-gray-100/50 dark:border-gray-700/50 rounded-xl shadow-[0_8px_30px_rgba(0,0,0,0.12)]">
                  <SelectItem value="all">Any Price</SelectItem>
                  <SelectItem value="0-200">₹0 - ₹200</SelectItem>
                  <SelectItem value="200-500">₹200 - ₹500</SelectItem>
                  <SelectItem value="500-1000">₹500 - ₹1,000</SelectItem>
                  <SelectItem value="1000-2000">₹1,000 - ₹2,000</SelectItem>
                  <SelectItem value="2000-3000">₹2,000 - ₹3,000</SelectItem>
                  <SelectItem value="3000-4000">₹3,000 - ₹4,000</SelectItem>
                  <SelectItem value="4000-5000">₹4,000 - ₹5,000</SelectItem>
                  <SelectItem value="5000-10000">₹5,000 - ₹10,000</SelectItem>
                  <SelectItem value="10000-50000">₹10,000+</SelectItem>
                </SelectContent>
              </Select>
            </div>
          )}

          {isMobile && (
            <div className="mb-4">
              {user ? <MobileStatsWidget /> : <MobileTrendingWidget />}
            </div>
          )}

          <Button 
            onClick={handleSearch}
            className="w-full h-12 md:h-16 bg-gradient-to-r from-[#ff00ff] to-[#00bfff] text-white text-lg md:text-xl hover:opacity-90 transition-opacity flex items-center justify-center gap-2 rounded-xl shadow-lg"
          >
            Search for{" "}
            <AnimatePresence mode="wait">
              <motion.span
                key={searchText}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ duration: 0.3, ease: "easeOut" }}
                className="ml-1 flex items-center gap-2"
              >
                {searchText} {searchIcon}
              </motion.span>
            </AnimatePresence>
          </Button>
        </div>
        
        <SportCircleButtons />
        
        <div className="mt-6 md:mt-16 hidden md:grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-8 animate-fadeIn pb-0 md:pb-8 mb-0 sm:mb-8" style={{ animationDelay: "0.6s" }}>
          <motion.div 
            whileTap={{ scale: 0.95 }}
            className="flex flex-col items-center space-y-2 md:space-y-3 p-2 md:p-6 rounded-xl bg-white/90 backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300"
          >
            <MapPin className="w-5 h-5 md:w-10 md:h-10 text-[#ff00ff]" />
            <span className="text-[10px] md:text-base font-medium text-gray-700">Premium Indoor & Outdoor Sports Facilities</span>
          </motion.div>
          <motion.div 
            whileTap={{ scale: 0.95 }}
            className="flex flex-col items-center space-y-2 md:space-y-3 p-2 md:p-6 rounded-xl bg-white/90 backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300"
          >
            <Users className="w-5 h-5 md:w-10 md:h-10 text-[#ff00ff]" />
            <span className="text-[10px] md:text-base font-medium text-gray-700">Professional Coaches & Elite Training Programs</span>
          </motion.div>
          <motion.div 
            whileTap={{ scale: 0.95 }}
            className="flex flex-col items-center space-y-2 md:space-y-3 p-2 md:p-6 rounded-xl bg-white/90 backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300"
          >
            <ShoppingBag className="w-5 h-5 md:w-10 md:h-10 text-[#00bfff]" />
            <span className="text-[10px] md:text-base font-medium text-gray-700">Sport & Fitness Retail + Super Fast Delivery</span>
          </motion.div>
          <motion.div 
            whileTap={{ scale: 0.95 }}
            className="flex flex-col items-center space-y-2 md:space-y-3 p-2 md:p-6 rounded-xl bg-white/90 backdrop-blur-sm shadow-lg hover:shadow-xl transition-all duration-300"
          >
            <Newspaper className="w-5 h-5 md:w-10 md:h-10 text-[#00bfff]" />
            <span className="text-[10px] md:text-base font-medium text-gray-700">Latest Sports News, Events & Community Updates</span>
          </motion.div>
        </div>
      </div>

      <div className={`md:hidden mt-6 grid grid-cols-2 gap-3 px-4 ${user ? 'pb-10' : 'pb-14'}`}>
        <div className="space-y-3">
          <Button
            onClick={() => navigate('/games')}
            className="w-full bg-gradient-to-r from-pink-400 to-purple-400 text-white hover:from-pink-500 hover:to-purple-500 transform hover:scale-105 transition-all duration-300 shadow-lg"
          >
            <Trophy className="w-5 h-5 mr-2" />
            Play Games
          </Button>
          <Button
            onClick={() => navigate('/be-fit')}
            className="w-full bg-gradient-to-r from-green-400 to-emerald-400 text-white hover:from-green-500 hover:to-emerald-500 transform hover:scale-105 transition-all duration-300 shadow-lg"
          >
            <Dumbbell className="w-5 h-5 mr-2" />
            Get Fit
          </Button>
        </div>
        <div className="space-y-3">
          <Button
            onClick={() => navigate('/live-feed')}
            className="w-full bg-gradient-to-r from-blue-400 to-cyan-400 text-white hover:from-blue-500 hover:to-cyan-500 transform hover:scale-105 transition-all duration-300 shadow-lg"
          >
            <Newspaper className="w-5 h-5 mr-2" />
            News & Updates
          </Button>
          <Button
            onClick={() => navigate('/events-and-games')}
            className="w-full bg-gradient-to-r from-orange-400 to-amber-400 text-white hover:from-orange-500 hover:to-amber-500 transform hover:scale-105 transition-all duration-300 shadow-lg"
          >
            <Calendar className="w-5 h-5 mr-2" />
            Events
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
