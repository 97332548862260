
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { Flag, Loader2 } from "lucide-react";

interface ReportFormProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  email: string;
  content: string;
  isSubmitting: boolean;
  onEmailChange: (email: string) => void;
  onContentChange: (content: string) => void;
  onSubmit: () => void;
}

export const ReportForm = ({
  isOpen,
  onOpenChange,
  email,
  content,
  isSubmitting,
  onEmailChange,
  onContentChange,
  onSubmit,
}: ReportFormProps) => {
  const isFormValid = email.trim() && content.trim();

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetTrigger asChild>
        <Button 
          variant="outline" 
          className="w-full bg-white hover:bg-gray-50 border-purple-200 hover:border-purple-300 text-purple-600 font-medium"
        >
          <Flag className="w-4 h-4 mr-2" />
          Report Content
        </Button>
      </SheetTrigger>
      <SheetContent className="w-[90vw] sm:max-w-[500px] bg-white top-[25vh] h-[50vh] rounded-t-xl rounded-b-xl border-t border-b border-purple-100">
        <SheetHeader>
          <SheetTitle className="text-center text-xl font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            Report Inappropriate Content
          </SheetTitle>
        </SheetHeader>
        <div className="mt-6 space-y-6">
          <div className="space-y-2">
            <Label htmlFor="email" className="text-sm font-medium text-gray-700">
              Your Email
            </Label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => onEmailChange(e.target.value)}
              placeholder="Enter your email"
              className="w-full border-purple-100 focus:border-purple-300 focus:ring-purple-200"
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="report" className="text-sm font-medium text-gray-700">
              Report Details
            </Label>
            <Textarea
              id="report"
              value={content}
              onChange={(e) => onContentChange(e.target.value)}
              placeholder="Please describe the issue..."
              className="min-h-[100px] w-full border-purple-100 focus:border-purple-300 focus:ring-purple-200"
              required
            />
          </div>
          <Button
            onClick={onSubmit}
            disabled={isSubmitting || !isFormValid}
            className="w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white shadow-md hover:shadow-lg transition-all"
          >
            {isSubmitting ? (
              <Loader2 className="w-4 h-4 animate-spin mr-2" />
            ) : (
              <Flag className="w-4 h-4 mr-2" />
            )}
            Submit Report
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
};
