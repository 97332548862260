
import { ArrowRight, MapPin } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/hooks/use-mobile";
import { motion } from "framer-motion";

const FeaturedGyms = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  
  const gyms = [
    {
      id: 1,
      name: "Cult.fit",
      address: "Sector 29",
      city: "Gurgaon",
      facilities: ["Strength Training", "Cardio", "More"],
      monthly_fee: 3999,
      rating: 4.8,
      image: "/lovable-uploads/83d12202-4375-4b42-8458-7b822e16fdfd.png",
      category: "External"
    },
    {
      id: 2,
      name: "Gold's Gym",
      address: "DLF Phase 4",
      city: "Gurgaon",
      facilities: ["Strength Training", "Cardio", "More"],
      monthly_fee: 4500,
      rating: 4.7,
      image: "/lovable-uploads/86ae5115-9a0f-404f-a8ac-3c4adda526a0.png",
      category: "External"
    },
    {
      id: 3,
      name: "Anytime Fitness",
      address: "Sector 50",
      city: "Gurgaon",
      facilities: ["Strength Training", "Cardio", "More"],
      monthly_fee: 3500,
      rating: 4.6,
      image: "/lovable-uploads/7929818f-fc84-4e7f-8d66-ade852946054.png",
      category: "External"
    }
  ];

  const handleNavigate = () => {
    navigate('/gyms');
  };

  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold bg-gradient-to-r from-orange-500 via-amber-500 to-yellow-500 bg-clip-text text-transparent animate-gradient px-1 py-1 rounded-lg relative">
            Featured Gyms
            <div className="h-1 w-full rounded-full bg-gradient-to-r from-orange-400 via-amber-400 to-yellow-400 opacity-70 absolute bottom-0 left-0"></div>
          </h2>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleNavigate}
            className="flex items-center space-x-2 px-4 py-2 rounded-full bg-gradient-to-r from-orange-500 to-amber-500 text-white shadow-lg hover:shadow-xl transition-all duration-300 group cursor-pointer"
          >
            <span className="font-medium">View more</span>
            <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
          </motion.button>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {gyms.map((gym, index) => (
            <motion.div
              key={gym.id}
              initial={isMobile ? { opacity: 0, y: 20 } : false}
              animate={isMobile ? { opacity: 1, y: 0 } : {}}
              transition={{ delay: index * 0.2 }}
              className="bg-white rounded-xl overflow-hidden border border-gray-100 hover:shadow-lg transition-all duration-300 animate-fadeIn"
            >
              <div className="relative h-48 overflow-hidden">
                <img
                  src={gym.image}
                  alt={gym.name}
                  className="w-full h-full object-cover"
                />
                <div className="absolute top-4 left-4">
                  <span className="px-3 py-1 bg-primary text-white backdrop-blur-sm rounded-full text-sm font-medium">
                    {gym.category}
                  </span>
                </div>
              </div>

              <div className="p-6 space-y-4">
                <h3 className="text-xl font-semibold text-gray-900">{gym.name}</h3>
                
                <div className="flex items-center text-gray-600">
                  <MapPin size={16} className="mr-2" />
                  <span>{gym.address}, {gym.city}</span>
                </div>

                <div className="flex flex-wrap gap-2">
                  {gym.facilities.map((facility, index) => (
                    <span key={index} className="text-xs bg-gray-100 dark:bg-gray-900 px-2 py-1 rounded-full dark:text-white">
                      {facility}
                    </span>
                  ))}
                </div>

                <div className="flex justify-between items-center pt-4 border-t border-gray-100">
                  <div className="flex items-center">
                    <span className="text-yellow-400">★</span>
                    <span className="ml-1 text-gray-600">{gym.rating}</span>
                  </div>
                  <span className="text-primary font-semibold">Available on inquiry</span>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturedGyms;
