
import { motion } from "framer-motion";

interface PieceProps {
  piece: {
    type: string;
    color: string;
  };
}

export const Piece = ({ piece }: PieceProps) => {
  const getPieceSymbol = () => {
    const symbols: { [key: string]: string } = {
      'w': {
        'p': '♙',
        'r': '♖',
        'n': '♘',
        'b': '♗',
        'q': '♕',
        'k': '♔'
      },
      'b': {
        'p': '♟',
        'r': '♜',
        'n': '♞',
        'b': '♝',
        'q': '♛',
        'k': '♚'
      }
    }[piece.color];

    return symbols[piece.type];
  };

  return (
    <motion.div
      className={`${piece.type === 'p' ? 'text-2xl sm:text-3xl' : 'text-4xl sm:text-5xl'} ${
        piece.color === 'w' 
          ? 'text-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.6)]' 
          : 'text-black'
      }`}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ type: "spring", stiffness: 300, damping: 25 }}
    >
      {getPieceSymbol()}
    </motion.div>
  );
};
