import { LucideIcon } from "lucide-react";
import { motion } from "framer-motion";

interface AICoachCardProps {
  icon: LucideIcon;
  category: string;
  description: string;
  isSelected: boolean;
  onClick: () => void;
}

export const AICoachCard = ({ icon: Icon, category, description, isSelected, onClick }: AICoachCardProps) => {
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onClick={onClick}
      className={`relative p-6 rounded-xl cursor-pointer transition-all duration-300 ${
        isSelected 
          ? 'bg-gradient-to-br from-purple-500/20 to-blue-500/20 border-2 border-purple-500/50 shadow-lg' 
          : 'bg-white hover:bg-gradient-to-br hover:from-purple-50 hover:to-blue-50 shadow-md hover:shadow-lg'
      }`}
    >
      <div className="flex items-start gap-4">
        <div className={`p-3 rounded-xl ${
          isSelected 
            ? 'bg-gradient-to-br from-purple-500 to-blue-500 text-white' 
            : 'bg-gradient-to-br from-purple-100 to-blue-100 text-purple-500'
        }`}>
          <Icon className="w-6 h-6" />
        </div>
        <div>
          <h3 className="font-semibold text-lg text-gray-900 capitalize mb-1">{category}</h3>
          <p className="text-sm text-gray-600 line-clamp-2">{description}</p>
        </div>
      </div>
    </motion.div>
  );
};