import { useState, useEffect, useCallback, useRef } from "react";
import { Crown, Medal, Award, Info, Mail, Lock } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import { motion, AnimatePresence } from "framer-motion";
import { useIsMobile } from "@/hooks/use-mobile";
import {
  Alert,
  AlertDescription,
} from "@/components/ui/alert";
import LoadingScreen from "@/components/ui/loading-screen";

interface Plan {
  name: string;
  icon: any;
  price: string;
  amount: number;
  color: string;
  textColor: string;
  borderColor: string;
  bgGradient: string;
  shadowColor: string;
  hoverGradient: string;
  buttonGradient: string;
  benefits: string[];
}

const Memberships = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useAuth();
  const isMobile = useIsMobile();
  const [activeMembership, setActiveMembership] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [pendingMembershipId, setPendingMembershipId] = useState<string | null>(null);
  const [paymentOption, setPaymentOption] = useState<'subscription' | 'one-off' | null>(null);
  const razorpayInstance = useRef<any>(null);
  const [showPaymentUI, setShowPaymentUI] = useState(false);
  const paymentSectionRef = useRef<HTMLDivElement>(null);
  const [isMembershipEnabled, setIsMembershipEnabled] = useState(false);

  useEffect(() => {
    if (user) {
      fetchMembership();
    } else {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (showPaymentUI && paymentSectionRef.current) {
      setTimeout(() => {
        paymentSectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    }
  }, [showPaymentUI]);

  const fetchMembership = async () => {
    try {
      const { data, error } = await supabase
        .from('memberships_purchased')
        .select('*')
        .eq('user_id', user?.id)
        .eq('status', 'active')
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      setActiveMembership(data || null);
    } catch (error) {
      console.error('Error fetching membership:', error);
    } finally {
      setLoading(false);
    }
  };

  const cleanupPendingMembership = useCallback(async () => {
    if (pendingMembershipId) {
      try {
        await supabase
          .from('memberships_purchased')
          .delete()
          .eq('id', pendingMembershipId)
          .eq('status', 'pending');
        
        console.log('Deleted pending membership:', pendingMembershipId);
      } catch (err) {
        console.error('Error deleting pending membership:', err);
      } finally {
        setPendingMembershipId(null);
      }
    }
  }, [pendingMembershipId]);

  useEffect(() => {
    return () => {
      cleanupPendingMembership();
      
      if (razorpayInstance.current) {
        razorpayInstance.current.close();
        razorpayInstance.current = null;
      }
    };
  }, [cleanupPendingMembership]);

  useEffect(() => {
    if (!showPaymentUI) {
      cleanupPendingMembership();
      setPaymentOption(null);
    }
  }, [showPaymentUI, cleanupPendingMembership]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingScreen size="md" />
      </div>
    );
  }

  const handlePurchase = (planType: string) => {
    if (!isMembershipEnabled) {
      return;
    }

    if (!user) {
      toast({
        title: "Please login first",
        description: "You need to be logged in to purchase a membership",
        duration: 3000,
      });
      navigate("/auth");
      return;
    }

    setSelectedPlan(planType);
    setShowPaymentUI(true);
    setPaymentOption(null);
  };

  const handlePaymentOptionSelect = (option: 'subscription' | 'one-off') => {
    if (processingPayment) return;
    setPaymentOption(option);
  };

  const processPayment = async () => {
    if (processingPayment || !paymentOption) return;
    
    try {
      setProcessingPayment(true);
      
      if (paymentOption === 'subscription') {
        await handleSubscription();
      } else {
        await handleOneOffPayment();
      }
    } catch (error: any) {
      console.error('Payment option error:', error);
      toast({
        variant: "destructive",
        title: "Payment Error",
        description: error.message || 'An error occurred while processing payment',
      });
      setProcessingPayment(false);
    }
  };

  const handleSubscription = async () => {
    if (!selectedPlan || !user) return;

    try {
      const { data: subscriptionData, error: subscriptionError } = await supabase.functions.invoke(
        'create-razorpay-subscription',
        {
          body: { planType: selectedPlan, email: user.email }
        }
      );

      if (subscriptionError) throw subscriptionError;

      if (!subscriptionData || !subscriptionData.key_id || !subscriptionData.subscription_id) {
        throw new Error('Invalid subscription data received');
      }

      const { data: membershipData, error: membershipError } = await supabase
        .from('memberships_purchased')
        .insert({
          user_id: user.id,
          plan_type: selectedPlan,
          razorpay_subscription_id: subscriptionData.subscription_id,
          razorpay_plan_id: subscriptionData.plan_id,
          subscription_status: 'pending',
          status: 'pending',
          end_date: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString(),
        })
        .select('id')
        .single();

      if (membershipError) throw membershipError;

      const membershipId = membershipData.id;
      setPendingMembershipId(membershipId);

      const options = {
        key: subscriptionData.key_id,
        subscription_id: subscriptionData.subscription_id,
        name: "beSportly",
        description: `${selectedPlan.charAt(0).toUpperCase() + selectedPlan.slice(1)} Membership`,
        handler: async function (response: any) {
          try {
            if (!response.razorpay_subscription_id || !response.razorpay_payment_id) {
              throw new Error('Invalid payment response');
            }

            setPendingMembershipId(null);

            const { error: verificationError } = await supabase.functions.invoke(
              'verify-razorpay-payment',
              {
                body: {
                  membershipId: membershipId,
                  paymentId: response.razorpay_payment_id,
                  orderId: response.razorpay_subscription_id,
                  signature: response.razorpay_signature
                }
              }
            );

            if (verificationError) throw verificationError;

            toast({
              title: "Payment Successful",
              description: "Your membership has been activated",
              duration: 5000,
            });

            setProcessingPayment(false);
            setShowPaymentUI(false);
            
            setTimeout(() => {
              navigate("/membership-success");
            }, 500);
          } catch (error: any) {
            console.error('Error saving membership:', error);
            toast({
              variant: "destructive",
              title: "Error saving membership",
              description: error.message,
            });
            setProcessingPayment(false);
          }
        },
        prefill: {
          email: user.email,
        },
        theme: {
          color: "#9333EA",
        },
        modal: {
          ondismiss: function() {
            setProcessingPayment(false);
          }
        },
      };

      if (razorpayInstance.current) {
        razorpayInstance.current.close();
      }

      razorpayInstance.current = new (window as any).Razorpay(options);
      razorpayInstance.current.open();
    } catch (error: any) {
      console.error('Payment error:', error);
      toast({
        variant: "destructive",
        title: "Payment Error",
        description: error.message || 'An error occurred while processing payment',
      });
      setProcessingPayment(false);
    }
  };

  const handleOneOffPayment = async () => {
    if (!selectedPlan || !user) return;

    try {
      const planPrices = {
        gold: 499,
        silver: 399,
        bronze: 199
      };

      const amount = planPrices[selectedPlan.toLowerCase() as keyof typeof planPrices];

      const { data: membershipData, error: membershipError } = await supabase
        .from('memberships_purchased')
        .insert({
          user_id: user.id,
          plan_type: selectedPlan,
          status: 'pending',
          end_date: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
        })
        .select('id')
        .single();

      if (membershipError) throw membershipError;

      const membershipId = membershipData.id;
      setPendingMembershipId(membershipId);

      const { data: orderData, error: orderError } = await supabase.functions.invoke(
        'create-razorpay-order',
        {
          body: {
            amount: amount * 100,
            currency: 'INR',
            receipt: `mem_${Date.now()}`,
            notes: {
              plan_type: selectedPlan,
              user_email: user.email,
              membership_id: membershipId
            }
          }
        }
      );

      if (orderError) throw orderError;

      const options = {
        key: orderData.key_id,
        amount: orderData.amount,
        currency: "INR",
        name: "beSportly",
        description: `${selectedPlan} Monthly Membership`,
        order_id: orderData.order_id,
        handler: async function (response: any) {
          try {
            setPendingMembershipId(null);
            
            const { error: verificationError } = await supabase.functions.invoke(
              'verify-razorpay-payment',
              {
                body: {
                  membershipId: membershipId,
                  paymentId: response.razorpay_payment_id,
                  orderId: response.razorpay_order_id,
                  signature: response.razorpay_signature
                }
              }
            );

            if (verificationError) throw verificationError;

            toast({
              title: "Payment Successful",
              description: "Your membership has been activated",
              duration: 5000,
            });

            setProcessingPayment(false);
            setShowPaymentUI(false);
            
            setTimeout(() => {
              navigate("/membership-success");
            }, 500);
          } catch (error: any) {
            console.error('Error verifying payment:', error);
            toast({
              variant: "destructive",
              title: "Error verifying payment",
              description: error.message,
            });
            setProcessingPayment(false);
          }
        },
        modal: {
          ondismiss: function() {
            setProcessingPayment(false);
          }
        },
        prefill: {
          email: user.email,
        },
        theme: {
          color: "#9333EA",
        },
      };

      if (razorpayInstance.current) {
        razorpayInstance.current.close();
      }

      razorpayInstance.current = new (window as any).Razorpay(options);
      razorpayInstance.current.open();
    } catch (error: any) {
      console.error('Payment error:', error);
      toast({
        variant: "destructive",
        title: "Payment Error",
        description: error.message || 'An error occurred while processing payment',
      });
      setProcessingPayment(false);
    }
  };

  const PaymentOptionsSection = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ type: "spring", damping: 25, stiffness: 500 }}
      className="w-full max-w-2xl mx-auto mt-20 mb-12"
      ref={paymentSectionRef}
    >
      <div className="relative w-full overflow-hidden bg-gradient-to-br from-white to-gray-50 shadow-2xl rounded-3xl">
        <div className="absolute -top-24 -right-24 w-48 h-48 rounded-full bg-purple-500/20 blur-3xl"></div>
        <div className="absolute -bottom-24 -left-24 w-48 h-48 rounded-full bg-blue-500/20 blur-3xl"></div>
        
        <div className="relative p-6 pb-4 overflow-hidden">
          <div className="absolute inset-x-0 bottom-0 h-1 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-600"></div>
          <h2 className="text-3xl font-bold text-center bg-gradient-to-r from-purple-700 to-indigo-700 bg-clip-text text-transparent mb-1">
            Choose Your Payment
          </h2>
          <p className="text-center text-gray-600 mb-2">
            Select your preferred payment option
          </p>
        </div>
        
        <div className="p-6 pt-2 space-y-6">
          <div className="grid gap-4">
            <div
              className={`relative overflow-hidden rounded-2xl p-6 cursor-pointer transition-all duration-300 
                ${paymentOption === 'subscription' 
                  ? 'bg-gradient-to-br from-purple-100/90 to-purple-50 border-2 border-purple-500/70 shadow-lg shadow-purple-200' 
                  : 'bg-white/90 border-2 border-gray-200 hover:border-purple-300/70'}`}
              onClick={() => handlePaymentOptionSelect('subscription')}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-purple-500/5 to-indigo-500/5 opacity-80"></div>
              
              {paymentOption === 'subscription' && (
                <div 
                  className="absolute right-4 top-4 w-6 h-6 rounded-full bg-purple-600 flex items-center justify-center"
                >
                  <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
              )}
              
              <div className="relative flex items-start space-x-5">
                <div className="bg-gradient-to-br from-purple-600 to-indigo-600 w-12 h-12 rounded-xl flex items-center justify-center shadow-lg shadow-purple-200">
                  <Crown className="w-6 h-6 text-white" />
                </div>
                
                <div className="flex-1">
                  <h3 className="font-bold text-lg text-gray-900 mb-1">Monthly Subscription</h3>
                  <p className="text-sm text-gray-600">Automatic monthly renewal with credit/debit cards</p>
                  
                  <div className="mt-3 flex items-center">
                    <span className="text-xs px-2 py-1 rounded-full bg-purple-100 text-purple-800 font-medium">
                      Continuous Benefits
                    </span>
                  </div>
                </div>
              </div>
            </div>
            
            <div
              className={`relative overflow-hidden rounded-2xl p-6 cursor-pointer transition-all duration-300 
                ${paymentOption === 'one-off' 
                  ? 'bg-gradient-to-br from-blue-100/90 to-blue-50 border-2 border-blue-500/70 shadow-lg shadow-blue-200' 
                  : 'bg-white/90 border-2 border-gray-200 hover:border-blue-300/70'}`}
              onClick={() => handlePaymentOptionSelect('one-off')}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-cyan-500/5 opacity-80"></div>
              
              {paymentOption === 'one-off' && (
                <div 
                  className="absolute right-4 top-4 w-6 h-6 rounded-full bg-blue-600 flex items-center justify-center"
                >
                  <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
              )}
              
              <div className="relative flex items-start space-x-5">
                <div className="bg-gradient-to-br from-blue-600 to-cyan-600 w-12 h-12 rounded-xl flex items-center justify-center shadow-lg shadow-blue-200">
                  <Medal className="w-6 h-6 text-white" />
                </div>
                
                <div className="flex-1">
                  <h3 className="font-bold text-lg text-gray-900 mb-1">One-time Payment</h3>
                  <p className="text-sm text-gray-600">Pay for one month with any payment method</p>
                  
                  <div className="mt-3 flex items-center">
                    <span className="text-xs px-2 py-1 rounded-full bg-blue-100 text-blue-800 font-medium">
                      All Payment Methods
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="pt-2 space-y-3">
            <Button 
              onClick={processPayment}
              disabled={!paymentOption || processingPayment}
              className="w-full h-12 text-lg font-semibold bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white rounded-xl shadow-lg shadow-purple-200/50 transition-all duration-300 relative overflow-hidden group"
            >
              {processingPayment ? (
                <div className="flex items-center justify-center space-x-2">
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <span>Processing...</span>
                </div>
              ) : (
                <span>Continue to Payment</span>
              )}
              <div className="absolute inset-0 bg-white opacity-0 group-hover:opacity-10 transition-opacity duration-300"></div>
            </Button>
            
            <Button 
              onClick={() => setShowPaymentUI(false)}
              className="w-full h-11 font-medium text-gray-600 bg-transparent hover:bg-gray-100 border-2 border-gray-200 rounded-xl transition-all duration-300"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  );

  const plans: Plan[] = [
    {
      name: "Gold",
      icon: Crown,
      price: "₹499/month",
      amount: 499,
      color: "bg-gradient-to-br from-amber-200 to-yellow-400",
      textColor: "text-amber-900",
      borderColor: "border-amber-300",
      bgGradient: "from-amber-50 to-amber-100/50",
      shadowColor: "shadow-amber-200/50",
      hoverGradient: "hover:from-amber-300 hover:to-yellow-500",
      buttonGradient: "from-amber-500 to-yellow-500",
      benefits: [
        "Additional 5% discount on venue bookings",
        "Priority customer support response",
        "Access to exclusive community events",
        "Sports equipment discounts (15%)",
        "Monthly digital fitness tips newsletter",
        "Free entry to quarterly networking meetups",
        "Exclusive content and training videos",
        "Discount coupons for sports apparel (10%)",
        "Access to member-only webinars",
        "Early access to new platform features",
        "Personalized sport recommendations"
      ]
    },
    {
      name: "Silver",
      icon: Medal,
      price: "₹399/month",
      amount: 399,
      color: "bg-gradient-to-br from-gray-200 to-gray-400",
      textColor: "text-gray-900",
      borderColor: "border-gray-300",
      bgGradient: "from-gray-50 to-gray-100/50",
      shadowColor: "shadow-gray-200/50",
      hoverGradient: "hover:from-gray-300 hover:to-gray-500",
      buttonGradient: "from-gray-500 to-gray-600",
      benefits: [
        "Additional 5% discount on venue bookings",
        "Standard customer support response",
        "Access to selected community events",
        "Sports equipment discounts (10%)",
        "Bi-monthly digital fitness tips newsletter",
        "Discounted entry to quarterly networking meetups",
        "Select premium content and videos",
        "Occasional discount coupons for sports apparel"
      ]
    },
    {
      name: "Bronze",
      icon: Award,
      price: "₹199/month",
      amount: 199,
      color: "bg-gradient-to-br from-orange-200 to-orange-400",
      textColor: "text-orange-900",
      borderColor: "border-orange-300",
      bgGradient: "from-orange-50 to-orange-100/50",
      shadowColor: "shadow-orange-200/50",
      hoverGradient: "hover:from-orange-300 hover:to-orange-500",
      buttonGradient: "from-orange-500 to-orange-600",
      benefits: [
        "Additional 5% discount on venue bookings",
        "Basic customer support access",
        "Sports equipment discounts (5%)",
        "Quarterly digital fitness tips newsletter",
        "Access to selected community content",
        "Entry to seasonal online events"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 md:pt-32">
        <Alert className="mb-8 bg-gradient-to-r from-purple-100/70 to-pink-100/70 border-none shadow-xl shadow-purple-200/20 rounded-2xl overflow-hidden relative">
          <div className="absolute inset-0 bg-white/40 backdrop-blur-sm -z-10"></div>
          <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-purple-300/20 to-pink-300/20 rounded-full blur-2xl transform translate-x-10 -translate-y-10"></div>
          <div className="absolute bottom-0 left-0 w-32 h-32 bg-gradient-to-tr from-blue-300/20 to-purple-300/20 rounded-full blur-2xl transform -translate-x-10 translate-y-10"></div>
          
          <div className="flex items-start relative z-10">
            <Info className="h-6 w-6 text-purple-700 mt-1 mr-3 flex-shrink-0" />
            <div className="space-y-2">
              <AlertDescription className="text-gray-800 font-medium">
                Our memberships include exclusive in-person benefits at partner venues, coaches, gyms, academies and communities!
              </AlertDescription>
              <p className="text-gray-700 text-sm">
                Have questions about membership perks? Contact us at{" "}
                <a 
                  href="mailto:social@besportly.com" 
                  className="text-purple-700 font-semibold hover:underline inline-flex items-center"
                >
                  <Mail className="h-3.5 w-3.5 mr-1" /> social@besportly.com
                </a>
              </p>
            </div>
          </div>
        </Alert>

        {user && (
          <div className="mb-12">
            {loading ? (
              <div className="text-center">Loading membership status...</div>
            ) : activeMembership ? (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Card className="p-8 bg-gradient-to-r from-emerald-50 to-teal-50 border-none shadow-xl shadow-emerald-200/30 rounded-2xl overflow-hidden relative">
                  <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-emerald-300/10 to-teal-300/10 rounded-full blur-3xl transform translate-x-20 -translate-y-20"></div>
                  <div className="absolute -bottom-10 -left-10 w-48 h-48 bg-gradient-to-tr from-emerald-300/10 to-teal-300/10 rounded-full blur-3xl"></div>
                  
                  <div className="flex items-center justify-between relative z-10">
                    <div>
                      <h3 className="text-2xl font-bold text-emerald-800 mb-2 flex items-center">
                        <span className="bg-gradient-to-r from-emerald-600 to-teal-600 bg-clip-text text-transparent">
                          Active {activeMembership.plan_type} Membership
                        </span>
                        <div className="ml-3 px-2 py-1 bg-emerald-100 rounded-full text-xs font-semibold text-emerald-700">
                          ACTIVE
                        </div>
                      </h3>
                      <p className="text-emerald-600 font-medium flex items-center">
                        <span className="mr-2">Valid until:</span>
                        <span className="bg-white/70 px-3 py-1 rounded-full text-emerald-700 shadow-sm">
                          {new Date(activeMembership.end_date).toLocaleDateString('en-IN', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric'
                          })}
                        </span>
                      </p>
                    </div>
                    <div className="bg-gradient-to-br from-emerald-500 to-teal-500 p-4 rounded-2xl shadow-lg shadow-emerald-200/50 transform -rotate-6">
                      <Crown className="w-12 h-12 text-white" />
                    </div>
                  </div>
                </Card>
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Card className="p-6 bg-gradient-to-r from-gray-50 to-slate-50 border-none shadow-xl shadow-gray-200/30 rounded-2xl overflow-hidden relative">
                  <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-purple-300/10 to-blue-300/10 rounded-full blur-3xl transform translate-x-20 -translate-y-20"></div>
                  
                  <p className="text-gray-600 text-center text-lg font-medium relative z-10">
                    You don't have any active memberships. Choose a plan below to get started!
                  </p>
                </Card>
              </motion.div>
            )}
          </div>
        )}

        <div className="text-center mb-16">
          <motion.h1 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
            className="text-4xl font-extrabold bg-gradient-to-r from-purple-700 via-pink-700 to-purple-700 bg-clip-text text-transparent"
          >
            Choose Your Membership Plan
          </motion.h1>
          <motion.div 
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: "10rem" }}
            transition={{ duration: 0.8, delay: 0.3 }}
            className="h-1.5 bg-gradient-to-r from-purple-500 via-pink-500 to-purple-500 rounded-full mx-auto mt-3"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
          {plans.map((plan, index) => (
            <motion.div
              key={plan.name}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 + 0.2 }}
            >
              <Card className={`relative overflow-hidden border-none bg-gradient-to-b ${plan.bgGradient} shadow-xl ${plan.shadowColor} rounded-2xl transform transition-all duration-500 hover:scale-105 hover:shadow-2xl hover:z-10`}>
                <div className="absolute -top-12 -right-12 w-48 h-48 bg-gradient-to-br from-purple-300/10 via-pink-300/10 to-indigo-300/10 rounded-full blur-3xl"></div>
                <div className="absolute -bottom-16 -left-16 w-48 h-48 bg-gradient-to-tr from-blue-300/10 via-purple-300/10 to-pink-300/10 rounded-full blur-3xl"></div>
                
                <div className={`absolute h-16 w-full top-0 left-0 bg-gradient-to-r ${plan.buttonGradient} rounded-b-[100%] transform -translate-y-12 opacity-10`}></div>
                
                <div className="p-8 pt-10 relative z-10">
                  <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center">
                      <div className={`flex items-center justify-center w-12 h-12 rounded-xl bg-gradient-to-br ${plan.buttonGradient} shadow-lg ${plan.shadowColor}`}>
                        <plan.icon className="w-6 h-6 text-white" />
                      </div>
                      <h3 className={`text-2xl font-bold ml-3 bg-gradient-to-r ${plan.buttonGradient} bg-clip-text text-transparent`}>
                        {plan.name}
                      </h3>
                    </div>
                    
                    <div className="bg-white/80 backdrop-blur-sm px-4 py-2 rounded-xl border border-gray-200/50 shadow-sm membership-price-tag">
                      <p className="text-2xl font-bold text-gray-800">{plan.price}</p>
                    </div>
                  </div>

                  <div className="space-y-4 mb-8">
                    {plan.benefits.map((benefit, i) => (
                      <motion.div 
                        key={i}
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: i * 0.05 + 0.5 }}
                        className="flex items-start"
                      >
                        <div className={`p-1 rounded-full bg-gradient-to-br ${plan.buttonGradient} shadow-sm mr-3 flex-shrink-0 mt-0.5`}>
                          <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7"></path>
                          </svg>
                        </div>
                        <span className="text-gray-700 text-sm">{benefit}</span>
                      </motion.div>
                    ))}
                  </div>

                  <div className="mt-8">
                    <Button 
                      className={`w-full py-6 relative text-white font-semibold text-lg rounded-xl overflow-hidden transition-all duration-300 opacity-70 bg-gray-400 hover:bg-gray-400 cursor-not-allowed`}
                      onClick={() => handlePurchase(plan.name.toLowerCase())}
                      disabled={true}
                    >
                      <span className="relative z-0">
                        {`Get ${plan.name}`}
                      </span>
                    </Button>
                  </div>
                </div>
              </Card>
            </motion.div>
          ))}
        </div>
        
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="mb-20"
        >
          <Card className="p-6 bg-gradient-to-r from-amber-50 to-yellow-50 border-none shadow-xl shadow-amber-200/10 rounded-2xl relative overflow-hidden">
            <div className="absolute inset-0 bg-white/40 backdrop-blur-sm -z-10"></div>
            <div className="absolute -top-12 -right-12 w-48 h-48 rounded-full bg-yellow-500/10 blur-3xl"></div>
            <div className="absolute -bottom-12 -left-12 w-48 h-48 rounded-full bg-amber-500/10 blur-3xl"></div>
            
            <div className="flex items-center space-x-3 mb-2">
              <div className="bg-gradient-to-br from-amber-500 to-yellow-500 p-2 rounded-xl shadow-lg shadow-amber-200/30 lock-icon">
                <Lock className="w-5 h-5 text-white" />
              </div>
              <h3 className="text-lg font-bold text-amber-900">Memberships Coming Soon!</h3>
            </div>
            <p className="text-amber-800 ml-10">
              We're launching memberships shortly after our official launch. Stay tuned for exclusive benefits and special perks!
            </p>
          </Card>
        </motion.div>
        
        <AnimatePresence>
          {showPaymentUI && <PaymentOptionsSection />}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Memberships;

