
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Upload } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { motion } from "framer-motion";

const roles = [
  { id: "engineering", name: "Engineering" },
  { id: "design", name: "Product Design" },
  { id: "product", name: "Product Management" },
  { id: "marketing", name: "Marketing" },
  { id: "operations", name: "Operations" },
  { id: "customer", name: "Customer Success" },
];

const Careers = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "",
    role: "",
    experience: "",
    message: "",
    cv_url: ""
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (file.type !== 'application/pdf') {
        toast({
          variant: "destructive",
          title: "Invalid file type",
          description: "Please upload a PDF file",
        });
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        toast({
          variant: "destructive",
          title: "File too large",
          description: "Please upload a file smaller than 5MB",
        });
        return;
      }
      setSelectedFile(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      let cv_url = '';
      
      if (selectedFile) {
        const fileExt = selectedFile.name.split('.').pop();
        const fileName = `${crypto.randomUUID()}.${fileExt}`;
        
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from('careers_cv')
          .upload(fileName, selectedFile);

        if (uploadError) throw uploadError;

        const { data: { publicUrl } } = supabase.storage
          .from('careers_cv')
          .getPublicUrl(fileName);

        cv_url = publicUrl;
      }

      const { error } = await supabase
        .from('careers_waitlist')
        .insert({
          ...formData,
          cv_url
        });

      if (error) throw error;

      toast({
        title: "✨ Application Submitted!",
        description: "You've been added to our talent pool. We'll reach out when we have relevant opportunities!",
        className: "bg-gradient-to-r from-purple-500 to-pink-500 text-white border-none animate-fade-in-up",
      });

      setFormData({
        full_name: "",
        email: "",
        phone: "",
        role: "",
        experience: "",
        message: "",
        cv_url: ""
      });
      setSelectedFile(null);
      
      const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
      if (fileInput) fileInput.value = '';

    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 via-pink-50 to-white">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-12">
        <div className="flex items-center justify-between mb-16">
          <Button
            variant="ghost"
            onClick={() => navigate(-1)}
            className="hover:scale-105 transition-transform duration-300"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back
          </Button>
          <h1 className="text-3xl md:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 via-pink-600 to-purple-600 animate-gradient">
            Join Our Journey
          </h1>
          <div className="w-[100px]"></div> {/* Spacer for alignment */}
        </div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="space-y-8 text-center mb-12"
        >
          <p className="text-lg text-gray-600 max-w-2xl mx-auto leading-relaxed">
            While we're not actively hiring right now, we're always looking to connect with exceptional talent. Join our waitlist to be first in line when we expand our team.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.4 }}
          className="bg-white rounded-2xl shadow-xl p-6 md:p-8 max-w-2xl mx-auto transform hover:scale-[1.01] transition-all duration-300 border border-purple-100"
        >
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="group">
                <label className="block text-sm font-medium text-gray-700 mb-1 group-hover:text-purple-600 transition-colors">
                  Full Name
                </label>
                <Input
                  required
                  value={formData.full_name}
                  onChange={(e) => setFormData(prev => ({ ...prev, full_name: e.target.value }))}
                  className="w-full transition-all duration-300 hover:border-purple-400 focus:border-purple-500"
                  placeholder="John Doe"
                />
              </div>
              <div className="group">
                <label className="block text-sm font-medium text-gray-700 mb-1 group-hover:text-purple-600 transition-colors">
                  Email
                </label>
                <Input
                  required
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                  className="w-full transition-all duration-300 hover:border-purple-400 focus:border-purple-500"
                  placeholder="john@example.com"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="group">
                <label className="block text-sm font-medium text-gray-700 mb-1 group-hover:text-purple-600 transition-colors">
                  Phone Number
                </label>
                <Input
                  required
                  type="tel"
                  value={formData.phone}
                  onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value.replace(/\D/g, '') }))}
                  className="w-full transition-all duration-300 hover:border-purple-400 focus:border-purple-500"
                  placeholder="1234567890"
                  maxLength={10}
                />
              </div>
              <div className="group">
                <label className="block text-sm font-medium text-gray-700 mb-1 group-hover:text-purple-600 transition-colors">
                  Years of Experience
                </label>
                <Input
                  required
                  type="number"
                  value={formData.experience}
                  onChange={(e) => setFormData(prev => ({ ...prev, experience: e.target.value }))}
                  className="w-full transition-all duration-300 hover:border-purple-400 focus:border-purple-500"
                  placeholder="2"
                  min="0"
                  max="50"
                />
              </div>
            </div>

            <div className="group">
              <label className="block text-sm font-medium text-gray-700 mb-1 group-hover:text-purple-600 transition-colors">
                Preferred Role
              </label>
              <Select
                value={formData.role}
                onValueChange={(value) => setFormData(prev => ({ ...prev, role: value }))}
                required
              >
                <SelectTrigger className="w-full transition-all duration-300 hover:border-purple-400 focus:border-purple-500">
                  <SelectValue placeholder="Select a role" />
                </SelectTrigger>
                <SelectContent>
                  {roles.map((role) => (
                    <SelectItem key={role.id} value={role.id}>
                      {role.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="group">
              <label className="block text-sm font-medium text-gray-700 mb-1 group-hover:text-purple-600 transition-colors">
                Upload CV (PDF)
              </label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-purple-400 transition-colors">
                <div className="space-y-1 text-center">
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600">
                    <label htmlFor="cv-upload" className="relative cursor-pointer rounded-md font-medium text-purple-600 hover:text-purple-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500">
                      <span>Upload a file</span>
                      <Input
                        id="cv-upload"
                        type="file"
                        className="sr-only"
                        accept=".pdf"
                        onChange={handleFileChange}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">PDF up to 5MB</p>
                  {selectedFile && (
                    <p className="text-sm text-purple-600">Selected: {selectedFile.name}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="group">
              <label className="block text-sm font-medium text-gray-700 mb-1 group-hover:text-purple-600 transition-colors">
                Why do you want to join us?
              </label>
              <Textarea
                value={formData.message}
                onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
                className="w-full transition-all duration-300 hover:border-purple-400 focus:border-purple-500"
                placeholder="Tell us what excites you about beSportly..."
                rows={4}
              />
            </div>

            <Button
              type="submit"
              className="w-full bg-gradient-to-r from-purple-600 via-pink-600 to-purple-600 hover:from-purple-700 hover:via-pink-700 hover:to-purple-700 text-white py-2 px-4 rounded-lg transition-all duration-300 transform hover:scale-[1.02]"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Join Waitlist"}
            </Button>
          </form>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
          className="mt-12 text-center text-gray-600"
        >
          <p className="text-sm">
            We're committed to building a diverse and inclusive team. 
            All qualified applicants will receive consideration without regard to race, color, religion, gender, sexual orientation, national origin, or disability.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default Careers;
