
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Sparkles, Dumbbell, GitPullRequest, ArrowRight } from "lucide-react";
import { motion } from "framer-motion";

export const SignInPrompt = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="mt-12"
    >
      <Card className="overflow-hidden border-none shadow-xl">
        {/* Top decorative gradient bar */}
        <div className="h-2 bg-gradient-to-r from-violet-500 via-fuchsia-500 to-pink-500" />
        
        <div className="p-1 bg-gradient-to-br from-violet-50 to-pink-50">
          <CardContent className="p-8 space-y-8 bg-white rounded-b-lg">
            <div className="flex justify-center">
              <div className="relative">
                <div className="absolute -inset-4 rounded-full opacity-30 bg-gradient-to-r from-violet-600 to-pink-600 blur-xl animate-pulse" />
                <div className="relative bg-gradient-to-r from-violet-600 to-pink-600 p-4 rounded-full">
                  <Sparkles className="w-10 h-10 text-white" />
                </div>
              </div>
            </div>
            
            <div className="space-y-4 text-center">
              <h3 className="text-3xl font-bold bg-gradient-to-r from-violet-600 via-fuchsia-600 to-pink-600 bg-clip-text text-transparent">
                Transform Your Fitness Journey
              </h3>
              
              <p className="text-gray-600 text-lg max-w-2xl mx-auto leading-relaxed">
                Your personalized fitness experience awaits. Sign in to unlock premium features designed to elevate your wellness journey.
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[
                {
                  icon: <Dumbbell className="w-5 h-5 text-violet-500" />,
                  title: "Personalized Tracking",
                  description: "Custom meal plans and weight monitoring tailored to your goals"
                },
                {
                  icon: <GitPullRequest className="w-5 h-5 text-pink-500" />,
                  title: "Progress Visualization",
                  description: "Beautiful charts that showcase your fitness journey"
                }
              ].map((feature, i) => (
                <motion.div 
                  key={i}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: 0.2 + (i * 0.1) }}
                  className="flex items-start space-x-4 p-4 rounded-lg bg-gradient-to-br from-violet-50 to-pink-50"
                >
                  <div className="mt-1 bg-white p-2 rounded-lg shadow-sm">
                    {feature.icon}
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-800">{feature.title}</h4>
                    <p className="text-sm text-gray-600">{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
            
            <div className="pt-4 flex justify-center">
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.98 }}
              >
                <Button 
                  className="bg-gradient-to-r from-violet-600 via-fuchsia-600 to-pink-600 text-white px-8 py-6 h-auto text-lg group relative overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300"
                  onClick={() => window.location.href = "/auth"}
                >
                  <span className="relative z-10 flex items-center">
                    Get Started Now
                    <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
                  </span>
                  <span className="absolute inset-0 bg-gradient-to-r from-violet-700 via-fuchsia-700 to-pink-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                </Button>
              </motion.div>
            </div>
          </CardContent>
        </div>
      </Card>
    </motion.div>
  );
};
