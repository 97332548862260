import { CheckCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const MembershipSuccess = () => {
  return (
    <div className="min-h-screen pt-32 bg-gradient-to-b from-green-50 to-white">
      <div className="max-w-md mx-auto px-4">
        <div className="text-center space-y-6">
          <div className="animate-bounce">
            <CheckCircle className="w-24 h-24 text-green-500 mx-auto" />
          </div>
          
          <h1 className="text-3xl font-bold text-gray-900">
            Welcome to the Club!
          </h1>
          
          <div className="bg-white rounded-lg p-6 shadow-lg border border-green-100">
            <p className="text-gray-600 mb-4">
              Your membership has been successfully activated. Get ready to enjoy premium benefits and exclusive features!
            </p>
            
            <div className="space-y-4">
              <Link to="/memberships">
                <Button className="w-full bg-gradient-to-r from-green-500 to-emerald-500 hover:from-green-600 hover:to-emerald-600 text-white">
                  View My Membership
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipSuccess;