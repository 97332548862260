
import React, { useState } from "react";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Sparkles, Bug, Lightbulb, Send } from "lucide-react";

interface FeedbackSheetProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const FeedbackSheet: React.FC<FeedbackSheetProps> = ({ open, onOpenChange }) => {
  const [feedbackType, setFeedbackType] = useState<"bug" | "feature">("bug");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!description.trim()) {
      toast({
        variant: "destructive",
        title: "Description required",
        description: "Please provide details about the bug or feature request."
      });
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      const { error } = await supabase.functions.invoke('send-feedback-email', {
        body: {
          type: feedbackType,
          email,
          description,
          recipient: "tech@besportly.com"
        }
      });
      
      if (error) throw error;
      
      toast({
        title: "✨ Submission received!",
        description: feedbackType === "bug" 
          ? "Thanks for helping us improve beSportly!" 
          : "Thanks for your awesome feature idea!",
        className: "bg-gradient-to-r from-indigo-50 to-purple-50 border-2 border-purple-200"
      });
      
      // Reset form
      setDescription("");
      onOpenChange(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast({
        variant: "destructive",
        title: "Submission failed",
        description: "Please try again or contact us directly."
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent 
        side="bottom" 
        className="h-[85vh] rounded-t-[30px] bg-gradient-to-b from-purple-50 via-indigo-50 to-blue-50 p-0 border-t-4 border-purple-400 overflow-hidden"
        hideCloseButton={true}
      >
        <div className="absolute left-1/2 top-3 h-1.5 w-12 -translate-x-1/2 rounded-full bg-purple-400" />
        
        <div className="px-6 py-8 h-full overflow-auto pb-20">
          <div className="absolute top-3 right-3">
            <Button 
              variant="ghost" 
              size="icon" 
              onClick={() => onOpenChange(false)}
              className="rounded-full hover:bg-purple-100"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="20" 
                height="20" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                className="text-purple-700"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </Button>
          </div>
        
          <div className="flex justify-center mb-5 pt-3">
            <div className="w-16 h-16 rounded-full bg-gradient-to-br from-purple-400 to-blue-500 flex items-center justify-center shadow-lg animate-pulse">
              <Sparkles className="h-8 w-8 text-white" />
            </div>
          </div>
          
          <SheetHeader className="text-center mb-6">
            <SheetTitle className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-600">
              Help us improve!
            </SheetTitle>
            <p className="text-gray-600 mt-2">
              We'd love to hear your thoughts and ideas
            </p>
          </SheetHeader>
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="bg-white/70 backdrop-blur-sm rounded-2xl p-5 shadow-sm border border-purple-100">
              <RadioGroup 
                value={feedbackType} 
                onValueChange={(value) => setFeedbackType(value as "bug" | "feature")}
                className="flex gap-4"
              >
                <div className="flex-1">
                  <RadioGroupItem 
                    value="bug" 
                    id="bug" 
                    className="peer sr-only" 
                  />
                  <Label
                    htmlFor="bug"
                    className="flex flex-col items-center justify-between rounded-xl border-2 border-purple-200 bg-white p-4 hover:bg-purple-50 hover:border-purple-300 peer-data-[state=checked]:border-purple-500 peer-data-[state=checked]:bg-purple-50 [&:has([data-state=checked])]:border-purple-500 cursor-pointer transition-all"
                  >
                    <Bug className="mb-2 h-6 w-6 text-purple-600" />
                    <span className="text-sm font-medium">Report a Bug</span>
                  </Label>
                </div>
                
                <div className="flex-1">
                  <RadioGroupItem 
                    value="feature" 
                    id="feature" 
                    className="peer sr-only" 
                  />
                  <Label
                    htmlFor="feature"
                    className="flex flex-col items-center justify-between rounded-xl border-2 border-blue-200 bg-white p-4 hover:bg-blue-50 hover:border-blue-300 peer-data-[state=checked]:border-blue-500 peer-data-[state=checked]:bg-blue-50 [&:has([data-state=checked])]:border-blue-500 cursor-pointer transition-all"
                  >
                    <Lightbulb className="mb-2 h-6 w-6 text-blue-600" />
                    <span className="text-sm font-medium">Request a Feature</span>
                  </Label>
                </div>
              </RadioGroup>
            </div>
            
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email" className="text-gray-700 block font-medium">
                  Email (optional)
                </Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="your@email.com"
                  className="rounded-xl border-purple-200 bg-white/80 backdrop-blur-sm focus-visible:ring-purple-500"
                />
              </div>
              
              <div className="space-y-2">
                <Label htmlFor="description" className="text-gray-700 block font-medium">
                  {feedbackType === "bug" ? "Describe the issue" : "Describe your feature idea"}
                </Label>
                <Textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder={feedbackType === "bug" 
                    ? "What happened? What did you expect to happen?" 
                    : "What feature would you like to see? How would it help you?"}
                  className="min-h-[150px] rounded-xl border-purple-200 bg-white/80 backdrop-blur-sm focus-visible:ring-purple-500"
                  required
                />
              </div>
            </div>
            
            <Button 
              type="submit" 
              disabled={isSubmitting}
              className="w-full py-6 rounded-xl bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 text-white font-medium text-lg shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] active:scale-[0.98] group disabled:opacity-70"
            >
              <Send className="mr-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
              {isSubmitting ? "Submitting..." : "Submit Feedback"}
            </Button>
          </form>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default FeedbackSheet;
