// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://iywrnwsxrwjaewhgnssq.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml5d3Jud3N4cndqYWV3aGduc3NxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzcxMzU5NTgsImV4cCI6MjA1MjcxMTk1OH0.iJOxof7KZz3hp0c86rG3VOQZqvcvNYewA7mGAufPz4w";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);