
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { CalendarIcon, Clock, Send, User, Mail, Phone } from "lucide-react";
import { format } from "date-fns";
import { supabase } from "@/integrations/supabase/client";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { toast } from "@/hooks/use-toast";
import { Sheet, SheetContent } from "@/components/ui/sheet";

const formSchema = z.object({
  name: z.string().min(2, "Name must be at least 2 characters"),
  email: z.string().email("Please enter a valid email address"),
  phone: z.string().min(10, "Please enter a valid phone number"),
  message: z.string(), // Removed the minimum character requirement
  preferred_date: z.date({
    required_error: "Please select a preferred date",
  }),
  preferred_time: z.string().min(1, "Please enter a preferred time"),
});

type FormValues = z.infer<typeof formSchema>;

export const CoachInquiryForm = ({ 
  coach, 
  isOpen, 
  onClose 
}: { 
  coach: any; 
  isOpen: boolean; 
  onClose: () => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  // Check if device is mobile
  useState(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });
  
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      preferred_time: "",
    },
  });

  const onSubmit = async (data: FormValues) => {
    setIsSubmitting(true);
    
    try {
      // Add logging to debug the issue
      console.log("Submitting inquiry for coach:", coach);
      console.log("Coach phone:", coach.phone);
      console.log("Coach profile:", coach.profile);
      console.log("Inquiry data:", data);

      // Format the date for sending
      const formattedDate = format(data.preferred_date, "MMM dd, yyyy");
      console.log("Formatted date:", formattedDate);

      // Prepare the request payload
      const payload = { 
        coachName: coach.profile?.full_name || "Coach",
        coachPhone: coach.phone || "+917838954835", // Fallback to default phone if not found
        inquiry: {
          ...data,
          preferred_date: formattedDate,
          coachId: coach.id
        }
      };
      
      console.log("Sending payload to edge function:", payload);

      // Send the message using Supabase Edge Function
      const { data: response, error } = await supabase.functions.invoke("send-coach-inquiry", {
        body: payload,
      });

      console.log("Edge function response:", response);

      if (error) {
        console.error("Supabase function error:", error);
        throw new Error(`Edge function error: ${error.message}`);
      }

      // Check if the edge function returned an error
      if (response?.error) {
        console.error("Edge function returned error:", response.error);
        throw new Error(response.error);
      }

      toast({
        title: "Inquiry Sent!",
        description: "The coach will be notified and will get back to you soon.",
      });
      
      onClose();
    } catch (error: any) {
      console.error("Error submitting inquiry:", error);
      toast({
        title: "Error sending inquiry",
        description: error.message || "Please try again later.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // On mobile, use a bottom sheet instead of a dialog
  if (isMobile) {
    return (
      <Sheet open={isOpen} onOpenChange={onClose}>
        <SheetContent side="bottom" className="h-[85vh] overflow-y-auto px-4 py-6 rounded-t-[20px]">
          <div className="space-y-4">
            <div className="text-xl font-semibold text-center bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              Contact {coach.profile?.full_name}
            </div>
            
            <div className="bg-gradient-to-r from-blue-50 to-purple-50 p-3 rounded-lg">
              <p className="text-center text-gray-700 text-sm">
                The coach will contact you to confirm details.
              </p>
            </div>
            
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="flex items-center gap-2 text-sm">
                        <User className="h-3 w-3 text-blue-500" />
                        Your Name
                      </FormLabel>
                      <FormControl>
                        <Input 
                          placeholder="Enter your name" 
                          className="bg-white/80 h-9 text-sm" 
                          {...field} 
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />
                
                <div className="grid grid-cols-2 gap-3">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex items-center gap-2 text-sm">
                          <Mail className="h-3 w-3 text-blue-500" />
                          Email
                        </FormLabel>
                        <FormControl>
                          <Input 
                            placeholder="your@email.com" 
                            className="bg-white/80 h-9 text-sm" 
                            {...field} 
                          />
                        </FormControl>
                        <FormMessage className="text-xs" />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={form.control}
                    name="phone"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex items-center gap-2 text-sm">
                          <Phone className="h-3 w-3 text-blue-500" />
                          Phone
                        </FormLabel>
                        <FormControl>
                          <Input 
                            placeholder="Phone number" 
                            className="bg-white/80 h-9 text-sm" 
                            {...field} 
                          />
                        </FormControl>
                        <FormMessage className="text-xs" />
                      </FormItem>
                    )}
                  />
                </div>
                
                <div className="grid grid-cols-2 gap-3">
                  <FormField
                    control={form.control}
                    name="preferred_date"
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="flex items-center gap-2 text-sm">
                          <CalendarIcon className="h-3 w-3 text-blue-500" />
                          Date
                        </FormLabel>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                className={cn(
                                  "w-full pl-3 text-left font-normal bg-white/80 h-9 text-sm",
                                  !field.value && "text-muted-foreground"
                                )}
                              >
                                {field.value ? (
                                  format(field.value, "MMM dd")
                                ) : (
                                  <span>Pick a date</span>
                                )}
                                <CalendarIcon className="ml-auto h-3 w-3 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                              mode="single"
                              selected={field.value}
                              onSelect={field.onChange}
                              disabled={(date) =>
                                date < new Date(new Date().setHours(0, 0, 0, 0))
                              }
                              initialFocus
                              className="rounded-md border shadow-md"
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage className="text-xs" />
                      </FormItem>
                    )}
                  />
                  
                  <FormField
                    control={form.control}
                    name="preferred_time"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="flex items-center gap-2 text-sm">
                          <Clock className="h-3 w-3 text-blue-500" />
                          Time
                        </FormLabel>
                        <FormControl>
                          <div className="relative">
                            <Clock className="absolute left-3 top-1/2 -translate-y-1/2 h-3 w-3 text-gray-500" />
                            <Input 
                              className="pl-8 bg-white/80 h-9 text-sm" 
                              placeholder="e.g., 4:00 PM" 
                              {...field} 
                            />
                          </div>
                        </FormControl>
                        <FormMessage className="text-xs" />
                      </FormItem>
                    )}
                  />
                </div>
                
                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="flex items-center gap-2 text-sm">
                        <Send className="h-3 w-3 text-blue-500" />
                        Your Message
                      </FormLabel>
                      <FormControl>
                        <Textarea 
                          placeholder="Tell the coach what you're looking for..." 
                          className="min-h-[80px] bg-white/80 resize-none text-sm" 
                          {...field} 
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />
                
                <div className="pt-2 flex gap-2">
                  <Button type="button" variant="outline" onClick={onClose} className="w-1/2 h-9 text-sm">
                    Cancel
                  </Button>
                  <Button 
                    type="submit" 
                    className="w-1/2 bg-gradient-to-r from-blue-600 to-purple-600 h-9 text-sm"
                    disabled={isSubmitting}
                  >
                    <Send className="mr-1 h-3 w-3" />
                    {isSubmitting ? "Sending..." : "Send"}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </SheetContent>
      </Sheet>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md md:max-w-xl">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold text-center bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
            Contact {coach.profile?.full_name}
          </DialogTitle>
        </DialogHeader>
        
        <div className="bg-gradient-to-r from-blue-50 to-purple-50 p-4 rounded-lg mb-4">
          <p className="text-center text-gray-700">
            Fill out this form to schedule a session with {coach.profile?.full_name}. 
            The coach will contact you to confirm the details.
          </p>
        </div>
        
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="flex items-center gap-2">
                      <User className="h-4 w-4 text-blue-500" />
                      Your Name
                    </FormLabel>
                    <FormControl>
                      <Input 
                        placeholder="Enter your name" 
                        className="bg-white/80 hover:bg-white focus:bg-white transition-colors" 
                        {...field} 
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="flex items-center gap-2">
                      <Mail className="h-4 w-4 text-blue-500" />
                      Email
                    </FormLabel>
                    <FormControl>
                      <Input 
                        placeholder="your@email.com" 
                        className="bg-white/80 hover:bg-white focus:bg-white transition-colors" 
                        {...field} 
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="flex items-center gap-2">
                    <Phone className="h-4 w-4 text-blue-500" />
                    Phone Number
                  </FormLabel>
                  <FormControl>
                    <Input 
                      placeholder="Enter your phone number" 
                      className="bg-white/80 hover:bg-white focus:bg-white transition-colors" 
                      {...field} 
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="preferred_date"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel className="flex items-center gap-2">
                      <CalendarIcon className="h-4 w-4 text-blue-500" />
                      Preferred Date
                    </FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            className={cn(
                              "w-full pl-3 text-left font-normal bg-white/80 hover:bg-white focus:bg-white transition-colors",
                              !field.value && "text-muted-foreground"
                            )}
                          >
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) =>
                            date < new Date(new Date().setHours(0, 0, 0, 0))
                          }
                          initialFocus
                          className="rounded-md border shadow-md"
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={form.control}
                name="preferred_time"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="flex items-center gap-2">
                      <Clock className="h-4 w-4 text-blue-500" />
                      Preferred Time
                    </FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Clock className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500" />
                        <Input 
                          className="pl-10 bg-white/80 hover:bg-white focus:bg-white transition-colors" 
                          placeholder="e.g., 4:00 PM" 
                          {...field} 
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            
            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="flex items-center gap-2">
                    <Send className="h-4 w-4 text-blue-500" />
                    Your Message
                  </FormLabel>
                  <FormControl>
                    <Textarea 
                      placeholder="Tell the coach what you're looking for..." 
                      className="min-h-[120px] bg-white/80 hover:bg-white focus:bg-white transition-colors resize-y" 
                      {...field} 
                    />
                  </FormControl>
                  <FormMessage />
                  <p className="text-xs text-gray-500 mt-1">
                    Share your goals, experience level, or any questions you have for the coach.
                  </p>
                </FormItem>
              )}
            />
            
            <DialogFooter className="pt-4 flex flex-col sm:flex-row gap-3">
              <Button type="button" variant="outline" onClick={onClose} className="w-full sm:w-auto order-2 sm:order-1">
                Cancel
              </Button>
              <Button 
                type="submit" 
                className="w-full sm:w-auto bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white order-1 sm:order-2"
                disabled={isSubmitting}
              >
                <Send className="mr-2 h-4 w-4" />
                {isSubmitting ? "Sending..." : "Send Inquiry"}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
