import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Search, MapPin, Star, StarHalf, DollarSign, Filter } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import ComingSoonBanner from "@/components/ComingSoonBanner";
import BackButton from "@/components/ui/back-button";
import { motion } from "framer-motion";
import { popularCities } from "@/utils/cityData";
import { NoResultsAnimation } from "@/components/ui/no-results-animation";
import { useIsMobile } from "@/hooks/use-mobile";
import { useAutoRefresh } from "@/hooks/use-auto-refresh";
import GymDetailsSheet from "@/components/gyms/GymDetailsSheet";
import { Toggle } from "@/components/ui/toggle";
import { Checkbox } from "@/components/ui/checkbox";
import { getExternalImage } from "@/utils/venueImageUtils";

type Gym = {
  id: string;
  name: string;
  description?: string | null;
  address: string;
  city: string;
  state: string;
  pincode: string;
  facilities: string[];
  monthly_fee: number;
  rating: number;
  images?: string[];
  is_external: boolean;
  is_bookable: boolean;
  phone?: string;
  website?: string;
  place_id?: string;
  maps_url?: string;
  data_source?: string;
  opening_hours?: string[];
  reviews?: any[];
  opening_time: string;
  closing_time: string;
  max_capacity?: number | null;
};

const gymCategories = [
  "Strength Training",
  "CrossFit",
  "Yoga", 
  "Zumba",
  "Personal Training",
  "Cardio",
  "Swimming Pool",
];

const Gyms = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedPriceRanges, setSelectedPriceRanges] = useState<string[]>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<string[]>([]);
  const [selectedGym, setSelectedGym] = useState<Gym | null>(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const isMobile = useIsMobile();

  const { data: gyms = [], isLoading, error } = useQuery({
    queryKey: ["gyms", searchTerm, selectedCities, selectedPriceRanges, selectedFacilities],
    queryFn: async () => {
      let query = supabase
        .from("gyms")
        .select("*");

      if (searchTerm) {
        query = query.ilike("name", `%${searchTerm}%`);
      }

      if (selectedCities.length > 0) {
        query = query.in("city", selectedCities);
      }

      if (selectedPriceRanges.length > 0) {
        const priceConditions = selectedPriceRanges.map(range => {
          if (range === "all") return null;
          
          const [min, max] = range.split("-").map(Number);
          if (max) {
            return `monthly_fee.gte.${min},monthly_fee.lte.${max}`;
          } else {
            return `monthly_fee.gte.${min}`;
          }
        }).filter(Boolean);

        if (priceConditions.length > 0) {
          const orConditions = priceConditions.join(",");
          query = query.or(orConditions);
        }
      }

      if (selectedFacilities.length > 0) {
        query = query.contains("facilities", selectedFacilities);
      }

      const { data, error } = await query;

      if (error) throw error;
      
      const modifiedData = (data as any[])?.map((gym) => ({
        ...gym,
        maps_url: gym.place_id ? `https://www.google.com/maps/place/?q=place_id:${gym.place_id}` : null
      }));

      return modifiedData || [];
    },
  });

  const noResultsShowing = !isLoading && (!gyms || gyms.length === 0);
  useAutoRefresh(gyms?.length || 0, noResultsShowing);

  const priceRanges = [
    { label: "All Prices", value: "all" },
    { label: "₹1000 - ₹3000", value: "1000-3000" },
    { label: "₹3001 - ₹5000", value: "3001-5000" },
    { label: "₹5001 - ₹10000", value: "5001-10000" },
    { label: "₹10001+", value: "10001-50000" },
  ];

  const handleGymClick = (gym: Gym) => {
    setSelectedGym(gym);
    setIsSheetOpen(true);
  };

  const renderStars = (rating: number) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;

    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <Star
          key={`star-${i}`}
          className="w-4 h-4 fill-yellow-400 text-yellow-400"
        />
      );
    }

    if (hasHalfStar) {
      stars.push(
        <StarHalf
          key="half-star"
          className="w-4 h-4 fill-yellow-400 text-yellow-400"
        />
      );
    }

    const emptyStars = 5 - Math.ceil(rating);
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <Star
          key={`empty-star-${i}`}
          className="w-4 h-4 text-gray-300"
        />
      );
    }

    return stars;
  };

  const toggleCity = (city: string) => {
    setSelectedCities(prev => 
      prev.includes(city) 
        ? prev.filter(c => c !== city) 
        : [...prev, city]
    );
  };
  
  const toggleFacility = (facility: string) => {
    setSelectedFacilities(prev => 
      prev.includes(facility) 
        ? prev.filter(f => f !== facility) 
        : [...prev, facility]
    );
  };
  
  const togglePriceRange = (range: string) => {
    setSelectedPriceRanges(prev => 
      prev.includes(range) 
        ? prev.filter(r => r !== range) 
        : [...prev, range]
    );
  };

  const resetFilters = () => {
    setSearchTerm("");
    setSelectedCities([]);
    setSelectedPriceRanges([]);
    setSelectedFacilities([]);
  };

  const hasActiveFilters = selectedCities.length > 0 || selectedPriceRanges.length > 0 || selectedFacilities.length > 0;

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-red-500">Error loading gyms. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 md:pt-32">
        <div className="relative mb-8">
          <div className="absolute left-0 top-1 z-10">
            <BackButton />
          </div>
          <h1 className="text-4xl font-bold bg-gradient-to-r from-orange-500 via-amber-500 to-yellow-500 bg-clip-text text-transparent animate-gradient px-1 py-1 rounded-lg relative mb-4 pl-10 md:pl-0 inline-block md:block w-auto">
            Discover Fitness Gyms
            <div className="h-1 md:w-full w-[100%] rounded-full bg-gradient-to-r from-orange-400 via-amber-400 to-yellow-400 opacity-70 absolute bottom-0 left-0"></div>
          </h1>
          <p className="text-lg text-gray-600 pl-10 md:pl-0">
            Find the perfect gym for your fitness journey
          </p>
        </div>

        <ComingSoonBanner type="gyms" />

        <div className="relative mb-6">
          <div className="relative">
            <Search className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
            <Input
              type="text"
              placeholder="Search gyms..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10"
            />
            <Button
              variant="outline"
              size="icon"
              className="absolute right-0 top-0 h-full px-3 rounded-l-none border-l-0"
              onClick={() => setFiltersVisible(!filtersVisible)}
            >
              <Filter className="h-5 w-5" />
              {hasActiveFilters && (
                <span className="absolute -top-1 -right-1 bg-orange-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                  !
                </span>
              )}
            </Button>
          </div>

          {filtersVisible && (
            <div className="bg-white px-4 py-3 rounded-lg shadow-sm border mt-2 transition-all">
              <div className="mb-4">
                <h3 className="font-medium text-sm text-gray-700 mb-2">City</h3>
                <div className="flex flex-wrap gap-1.5">
                  {popularCities.slice(0, isMobile ? 8 : 20).map((city) => (
                    <Toggle
                      key={city}
                      className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                        selectedCities.includes(city) 
                          ? "bg-orange-500 text-white" 
                          : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                      }`}
                      pressed={selectedCities.includes(city)}
                      onPressedChange={() => toggleCity(city)}
                    >
                      {city}
                    </Toggle>
                  ))}
                </div>
              </div>

              <div className="mb-4">
                <h3 className="font-medium text-sm text-gray-700 mb-2">Facilities</h3>
                <div className="flex flex-wrap gap-1.5">
                  {gymCategories.slice(0, isMobile ? 6 : gymCategories.length).map((facility) => (
                    <Toggle
                      key={facility}
                      className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                        selectedFacilities.includes(facility) 
                          ? "bg-orange-500 text-white" 
                          : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                      }`}
                      pressed={selectedFacilities.includes(facility)}
                      onPressedChange={() => toggleFacility(facility)}
                    >
                      {facility}
                    </Toggle>
                  ))}
                </div>
              </div>

              <div className="mb-4">
                <h3 className="font-medium text-sm text-gray-700 mb-2">Price Range</h3>
                <div className="flex flex-wrap gap-1.5">
                  {priceRanges.slice(1).map((range) => (
                    <Toggle
                      key={range.value}
                      className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                        selectedPriceRanges.includes(range.value) 
                          ? "bg-orange-500 text-white" 
                          : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                      }`}
                      pressed={selectedPriceRanges.includes(range.value)}
                      onPressedChange={() => togglePriceRange(range.value)}
                    >
                      {range.label}
                    </Toggle>
                  ))}
                </div>
              </div>

              {hasActiveFilters && (
                <Button
                  variant="ghost"
                  className="text-orange-600 hover:text-orange-700 p-0 h-auto font-medium"
                  onClick={resetFilters}
                >
                  Clear all filters
                </Button>
              )}
            </div>
          )}
        </div>

        {isLoading ? (
          <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ${isMobile ? 'mobile-search-grid' : ''}`}>
            {[1, 2, 3, 4, 5, 6].map((n) => (
              <Card key={n} className="overflow-hidden">
                <Skeleton className="h-48 w-full" />
                <CardHeader>
                  <Skeleton className="h-6 w-2/3" />
                  <Skeleton className="h-4 w-full" />
                </CardHeader>
              </Card>
            ))}
          </div>
        ) : (
          <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ${isMobile ? 'mobile-search-grid' : ''}`}>
            {gyms && gyms.length > 0 ? (
              gyms.map((gym) => (
                isMobile ? (
                  <motion.div
                    key={gym.id}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className="mobile-search-card gym-search-card"
                    onClick={() => handleGymClick(gym)}
                  >
                    <div className="card-image">
                      <img
                        src={getExternalImage(gym)}
                        alt={gym.name}
                        className="w-full h-full object-cover"
                      />
                      <div className="card-badge">
                        External
                      </div>
                    </div>
                    <div className="card-content">
                      <h3 className="card-title">{gym.name}</h3>
                      <div className="card-subtitle">
                        <MapPin className="h-3 w-3 mr-1" />
                        <span>{gym.city}</span>
                      </div>
                      <div className="card-tags">
                        {gym.facilities?.slice(0, 2).map((facility, i) => (
                          <span key={i} className="card-tag">
                            {facility}
                          </span>
                        ))}
                        {gym.facilities?.length > 2 && (
                          <span className="card-tag">
                            +{gym.facilities.length - 2}
                          </span>
                        )}
                      </div>
                      <div className="card-footer">
                        <div className="card-rating">
                          <Star className="h-3 w-3 text-yellow-400 fill-yellow-400 mr-1" />
                          <span>{gym.rating?.toFixed(1) || "New"}</span>
                        </div>
                        {gym.is_external && (
                          <div className="flex items-center mx-auto">
                            <img src="/lovable-uploads/8ead321d-1e98-43b9-b971-053d58ba6729.png" alt="Google Maps" className="h-3 w-3" />
                            <span className="text-xs text-blue-600 font-medium ml-1">From Google Maps</span>
                          </div>
                        )}
                        <span className="card-price">{gym.is_external ? "Available on inquiry" : `₹${gym.monthly_fee}/mo`}</span>
                      </div>
                    </div>
                  </motion.div>
                ) : (
                  <Card key={gym.id} className="overflow-hidden hover:shadow-lg transition-shadow cursor-pointer" onClick={() => handleGymClick(gym)}>
                    <div className="h-48 bg-gray-200 relative flex items-center justify-center">
                      <img
                        src={getExternalImage(gym)}
                        alt={gym.name}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute top-2 left-2">
                        <span className="px-3 py-1 bg-gradient-to-r from-orange-400 to-yellow-300 text-white backdrop-blur-sm rounded-full text-sm font-medium">
                          External
                        </span>
                      </div>
                    </div>
                    <CardHeader>
                      <CardTitle className="text-gray-900 dark:text-gray-900">{gym.name}</CardTitle>
                      <CardDescription className="text-gray-600 dark:text-gray-600">
                        {gym.address}, {gym.city}
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <div className="flex flex-wrap gap-2 mb-2">
                        {gym.facilities?.map((facility) => (
                          <span key={facility} className="px-2 py-1 bg-gray-100 rounded-full text-sm text-gray-600">
                            {facility}
                          </span>
                        ))}
                      </div>
                      <div className="flex items-center text-gray-600 mb-2">
                        <span className="font-medium">{gym.is_external ? "Available on inquiry" : `₹${gym.monthly_fee}/month`}</span>
                      </div>
                      <div className="text-sm text-gray-600">
                        <div className="flex items-center justify-between mb-1">
                          <div className="flex items-center gap-0.5">
                            {renderStars(gym.rating || 0)}
                            <span className="ml-2 text-sm text-gray-600">
                              {gym.rating?.toFixed(1) || "No ratings"}
                            </span>
                          </div>
                        </div>
                        {gym.is_external && (
                          <div className="flex items-center justify-center mt-1">
                            <img src="/lovable-uploads/8ead321d-1e98-43b9-b971-053d58ba6729.png" alt="Google Maps" className="h-4 w-4 mr-1" />
                            <span className="text-xs text-blue-600 font-medium">From Google Maps</span>
                          </div>
                        )}
                      </div>
                    </CardContent>
                    <CardFooter className="flex justify-between items-center">
                      <span className="text-primary font-semibold">{gym.is_external ? "Available on inquiry" : `₹${gym.monthly_fee}/month`}</span>
                    </CardFooter>
                  </Card>
                )
              ))
            ) : (
              <div className="col-span-full">
                <NoResultsAnimation type="gyms" onReset={resetFilters} />
              </div>
            )}
          </div>
        )}
      </div>

      <GymDetailsSheet 
        gym={selectedGym} 
        isOpen={isSheetOpen} 
        onClose={() => setIsSheetOpen(false)} 
      />

      <style>
        {`
        .mobile-search-grid {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 1rem;
          padding: 0;
        }
        
        .mobile-search-card {
          display: flex;
          flex-direction: column;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0 1px 3px rgba(0,0,0,0.1);
          background: white;
          height: 100%;
          cursor: pointer;
          transition: transform 0.2s ease, box-shadow 0.2s ease;
        }
        
        .mobile-search-card:active {
          transform: scale(0.98);
        }
        
        .card-image {
          height: 160px;
          position: relative;
          background-color: #f3f4f6;
        }
        
        .card-badge {
          position: absolute;
          top: 8px;
          left: 8px;
          background: linear-gradient(90deg, #F97316, #FBBF24);
          color: white;
          font-size: 0.6875rem;
          font-weight: 500;
          padding: 0.25rem 0.5rem;
          border-radius: 9999px;
          display: flex;
          align-items: center;
          box-shadow: 0 1px 2px rgba(0,0,0,0.1);
        }
        
        .card-content {
          padding: 12px;
          display: flex;
          flex-direction: column;
          flex: 1;
        }
        
        .card-title {
          font-weight: 600;
          font-size: 0.9375rem;
          margin-bottom: 4px;
          color: #1f2937;
        }
        
        .card-subtitle {
          display: flex;
          align-items: center;
          color: #6b7280;
          font-size: 0.75rem;
          margin-bottom: 8px;
        }
        
        .card-tags {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          margin-bottom: 8px;
        }
        
        .card-tag {
          background-color: #f3f4f6;
          color: #4b5563;
          font-size: 0.6875rem;
          padding: 2px 6px;
          border-radius: 4px;
        }
        
        .card-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: auto;
          padding-top: 8px;
          border-top: 1px solid #f3f4f6;
        }
        
        .card-rating {
          display: flex;
          align-items: center;
          font-size: 0.75rem;
          font-weight: 500;
          color: #4b5563;
        }
        
        .card-price {
          font-size: 0.75rem;
          font-weight: 600;
          color: #7c3aed;
        }
        `}
      </style>
    </div>
  );
};

export default Gyms;
