import { motion } from "framer-motion";
import { Settings2, Bell, Share2, Moon, Sun } from "lucide-react";
import { Switch } from "@/components/ui/switch";
import { useState, useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";
import { useTheme } from "@/components/ThemeProvider";
import { useAuth } from "@/components/AuthProvider";

const generateCoolCode = () => {
  const consonants = 'BCDFGHJKLMNPQRSTVWXYZ';
  const vowels = 'AEIOU';
  let code = '';
  
  code += consonants[Math.floor(Math.random() * consonants.length)];
  code += vowels[Math.floor(Math.random() * vowels.length)];
  code += consonants[Math.floor(Math.random() * consonants.length)];
  code += consonants[Math.floor(Math.random() * consonants.length)];
  code += vowels[Math.floor(Math.random() * vowels.length)];
  code += consonants[Math.floor(Math.random() * consonants.length)];
  
  return code;
};

const Settings = () => {
  const [pushNotifications, setPushNotifications] = useState(false);
  const { toast } = useToast();
  const { theme, setTheme } = useTheme();
  const { user } = useAuth();
  const [referralCode, setReferralCode] = useState<string>("");

  useEffect(() => {
    if (user) {
      const userBasedCode = generateCoolCode() + user.id.substring(0, 2);
      setReferralCode(userBasedCode.substring(0, 6));
    } else {
      const storedCode = localStorage.getItem('referralCode');
      if (storedCode) {
        setReferralCode(storedCode);
      } else {
        const newCode = generateCoolCode();
        localStorage.setItem('referralCode', newCode);
        setReferralCode(newCode);
      }
    }
  }, [user]);

  const handleCopyReferral = async () => {
    await navigator.clipboard.writeText(`Join me on beSportly! Use my code: ${referralCode} at besportly.com`);
    toast({
      title: "Copied!",
      description: "Referral code copied to clipboard",
    });
  };

  const handlePushNotificationChange = (enabled: boolean) => {
    setPushNotifications(enabled);
    toast({
      title: enabled ? "Notifications enabled" : "Notifications disabled",
      description: enabled ? "You'll receive updates about your activities" : "You won't receive push notifications",
    });
  };

  return (
    <div className="min-h-screen bg-background text-foreground pt-16 px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md mx-auto space-y-8"
      >
        <div className="flex items-center space-x-3">
          <Settings2 className="w-8 h-8 text-primary" />
          <h1 className="text-2xl font-bold">Settings</h1>
        </div>

        <div className="space-y-4">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.1 }}
            className="bg-card rounded-xl p-4 shadow-lg border border-border"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <Bell className="w-5 h-5 text-primary" />
                <div>
                  <h3 className="font-semibold">Push Notifications</h3>
                  <p className="text-sm text-muted-foreground">Get updates about your activities</p>
                </div>
              </div>
              <Switch
                checked={pushNotifications}
                onCheckedChange={handlePushNotificationChange}
              />
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-card rounded-xl p-4 shadow-lg border border-border"
          >
            <div className="space-y-3">
              <div className="flex items-center space-x-3">
                <Share2 className="w-5 h-5 text-primary" />
                <div>
                  <h3 className="font-semibold">Your Referral Code</h3>
                  <p className="text-sm text-muted-foreground">Share beSportly with friends</p>
                </div>
              </div>
              <div
                onClick={handleCopyReferral}
                className="bg-muted p-3 rounded-lg text-center cursor-pointer active:scale-95 transition-transform"
              >
                <span className="font-mono font-semibold text-gray-900">{referralCode}</span>
                <p className="text-xs text-gray-600 mt-1">Tap to copy</p>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 }}
            className="bg-card rounded-xl p-4 shadow-lg border border-border"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                {theme === 'dark' ? (
                  <Moon className="w-5 h-5 text-primary" />
                ) : (
                  <Sun className="w-5 h-5 text-primary" />
                )}
                <div>
                  <h3 className="font-semibold">Dark Mode</h3>
                  <p className="text-sm text-muted-foreground">Toggle dark theme</p>
                </div>
              </div>
              <Switch
                checked={theme === 'dark'}
                onCheckedChange={(checked) => setTheme(checked ? 'dark' : 'light')}
              />
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Settings;
