
import { 
  ArrowLeft, Shield, Clock, CreditCard, RefreshCw, 
  CheckCircle, AlertTriangle, Mail, SlidersHorizontal 
} from 'lucide-react';
import BackButton from "@/components/ui/back-button";

const Refund = () => {
  return (
    <div className="min-h-screen bg-white pt-16 md:pt-32">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="absolute left-0 top-0">
          <BackButton className="md:flex hidden" />
          <button 
            onClick={() => window.history.back()}
            className="hidden md:flex items-center gap-2 text-gray-600 hover:text-purple-700 transition-colors group p-2"
            aria-label="Go back"
          >
            <ArrowLeft className="h-5 w-5 group-hover:-translate-x-1 transition-transform" />
            <span className="font-medium">Back</span>
          </button>
        </div>
        
        <div className="mb-16 text-center">
          <div className="relative inline-block mb-4">
            <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-indigo-600 rounded-lg blur opacity-25"></div>
            <h1 className="relative text-2xl md:text-3xl font-bold text-gray-900 bg-clip-text text-transparent bg-gradient-to-r from-purple-800 to-indigo-600">
              Refund Policy
            </h1>
          </div>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Our commitment to fair and transparent refund processes at beSportly
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          <div className="bg-white shadow-xl rounded-xl overflow-hidden transform transition-all hover:scale-105 duration-300">
            <div className="bg-gradient-to-r from-purple-500 to-indigo-600 h-2"></div>
            <div className="p-6">
              <Clock className="h-10 w-10 text-purple-600 mb-3" />
              <h3 className="text-xl font-bold text-gray-800 mb-2">48-Hour Cancellation</h3>
              <p className="text-gray-600">
                Cancellations made at least 48 hours before your scheduled booking are eligible for a 50% refund of the booking amount.
              </p>
            </div>
          </div>

          <div className="bg-white shadow-xl rounded-xl overflow-hidden transform transition-all hover:scale-105 duration-300">
            <div className="bg-gradient-to-r from-purple-500 to-indigo-600 h-2"></div>
            <div className="p-6">
              <RefreshCw className="h-10 w-10 text-purple-600 mb-3" />
              <h3 className="text-xl font-bold text-gray-800 mb-2">One-Time Reschedule</h3>
              <p className="text-gray-600">
                Each booking is eligible for a single reschedule through the app, subject to availability of the venue or service.
              </p>
            </div>
          </div>

          <div className="bg-white shadow-xl rounded-xl overflow-hidden transform transition-all hover:scale-105 duration-300">
            <div className="bg-gradient-to-r from-purple-500 to-indigo-600 h-2"></div>
            <div className="p-6">
              <CreditCard className="h-10 w-10 text-purple-600 mb-3" />
              <h3 className="text-xl font-bold text-gray-800 mb-2">Processing Time</h3>
              <p className="text-gray-600">
                Refunds typically take 5-7 business days to process, depending on your payment method and financial institution.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-2xl shadow-xl overflow-hidden mb-16">
          <div className="bg-gradient-to-r from-purple-600 to-indigo-600 py-6 px-8">
            <h2 className="text-2xl font-bold text-white">Detailed Refund Policy</h2>
          </div>
          <div className="p-8 prose prose-purple max-w-none">
            <div className="mb-10">
              <h3 className="text-2xl font-semibold text-gray-900 flex items-center gap-2 mb-4">
                <Shield className="h-6 w-6 text-purple-600" />
                Eligibility for Refunds
              </h3>
              <p className="text-gray-700 leading-relaxed mb-4">
                We offer refunds for our services under specific circumstances, as detailed in this policy. 
                All refund requests are evaluated on a case-by-case basis.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
                <div className="flex items-start gap-3">
                  <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0 mt-1" />
                  <p className="text-gray-700">
                    <span className="font-medium">Booking Cancellations: </span> 
                    Cancellations made at least 48 hours before scheduled time are eligible for a 50% refund.
                  </p>
                </div>
                <div className="flex items-start gap-3">
                  <AlertTriangle className="h-5 w-5 text-amber-500 flex-shrink-0 mt-1" />
                  <p className="text-gray-700">
                    <span className="font-medium">Late Cancellations: </span> 
                    Cancellations made less than 48 hours before scheduled time are not eligible for refunds.
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-10">
              <h3 className="text-2xl font-semibold text-gray-900 flex items-center gap-2 mb-4">
                <SlidersHorizontal className="h-6 w-6 text-purple-600" />
                Cancellation & Rescheduling Process
              </h3>
              <p className="text-gray-700 leading-relaxed mb-4">
                Bookings can be cancelled directly through the app when possible. If you encounter any issues with in-app cancellation, 
                please contact us at <a href="mailto:social@besportly.com" className="text-purple-600 hover:text-purple-800 transition-colors font-medium">
                social@besportly.com</a>.
              </p>
              <p className="text-gray-700 leading-relaxed mb-6">
                Each booking is eligible for a single reschedule, which can be done through the app's booking management section. 
                Rescheduling is subject to availability.
              </p>
              <div className="bg-purple-50 border-l-4 border-purple-500 p-4 rounded">
                <p className="text-purple-800 font-medium">
                  Please note that for special events, competitions, or membership purchases, different cancellation 
                  and refund policies may apply. These will be clearly communicated at the time of purchase.
                </p>
              </div>
            </div>

            <div className="mb-10">
              <h3 className="text-2xl font-semibold text-gray-900 flex items-center gap-2 mb-4">
                <CreditCard className="h-6 w-6 text-purple-600" />
                Refund Process
              </h3>
              <p className="text-gray-700 leading-relaxed mb-4">
                To request a refund, please contact our support team with your order details and reason for the refund. 
                We aim to process all refund requests within 5-7 business days.
              </p>
              <p className="text-gray-700 leading-relaxed">
                Refunds will be processed using the same payment method used for the original purchase. 
                Processing times may vary depending on your payment provider.
              </p>
            </div>

            <div>
              <h3 className="text-2xl font-semibold text-gray-900 flex items-center gap-2 mb-4">
                <Mail className="h-6 w-6 text-purple-600" />
                Contact Information
              </h3>
              <p className="text-gray-700 leading-relaxed mb-4">
                For any questions about our refund policy or to request a refund, please contact us at:
              </p>
              <div className="bg-white border border-gray-200 rounded-lg p-6 hover:border-purple-500 transition-colors">
                <div className="flex items-center gap-3">
                  <Mail className="h-5 w-5 text-purple-600" />
                  <a 
                    href="mailto:social@besportly.com" 
                    className="text-lg font-medium text-purple-600 hover:text-purple-800 transition-colors"
                  >
                    social@besportly.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refund;
