import { useState } from "react";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { Dumbbell } from "lucide-react";

export const ExerciseForm = ({ onExerciseAdded }: { onExerciseAdded: () => void }) => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [exerciseData, setExerciseData] = useState({
    exercise_name: "",
    exercise_type: "cardio",
    calories_burned: "",
    duration_minutes: "",
    notes: "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      const { error } = await supabase.from("exercise_logs").insert([
        {
          user_id: user.id,
          exercise_name: exerciseData.exercise_name,
          exercise_type: exerciseData.exercise_type,
          calories_burned: parseInt(exerciseData.calories_burned),
          duration_minutes: parseInt(exerciseData.duration_minutes),
          notes: exerciseData.notes,
        },
      ]);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Exercise logged successfully!",
      });

      setExerciseData({
        exercise_name: "",
        exercise_type: "cardio",
        calories_burned: "",
        duration_minutes: "",
        notes: "",
      });

      onExerciseAdded();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to log exercise. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex items-center gap-4">
        <Dumbbell className="w-6 h-6 text-purple-500" />
        <h3 className="text-lg font-semibold">Log Exercise</h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Input
          placeholder="Exercise name"
          value={exerciseData.exercise_name}
          onChange={(e) =>
            setExerciseData({ ...exerciseData, exercise_name: e.target.value })
          }
          required
        />
        <Select
          value={exerciseData.exercise_type}
          onValueChange={(value) =>
            setExerciseData({ ...exerciseData, exercise_type: value })
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Select exercise type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="cardio">Cardio</SelectItem>
            <SelectItem value="strength">Strength Training</SelectItem>
            <SelectItem value="flexibility">Flexibility</SelectItem>
            <SelectItem value="sports">Sports</SelectItem>
          </SelectContent>
        </Select>
        <Input
          type="number"
          placeholder="Calories burned"
          value={exerciseData.calories_burned}
          onChange={(e) =>
            setExerciseData({ ...exerciseData, calories_burned: e.target.value })
          }
          required
        />
        <Input
          type="number"
          placeholder="Duration (minutes)"
          value={exerciseData.duration_minutes}
          onChange={(e) =>
            setExerciseData({ ...exerciseData, duration_minutes: e.target.value })
          }
          required
        />
        <Input
          placeholder="Notes (optional)"
          value={exerciseData.notes}
          onChange={(e) => setExerciseData({ ...exerciseData, notes: e.target.value })}
          className="md:col-span-2"
        />
      </div>
      <Button type="submit" className="w-full">
        Log Exercise
      </Button>
    </form>
  );
};