
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from "@/components/ui/card";
import { Calendar, Shield, Building, Clock, LogOut, Database, MapPin, AlertCircle, ChevronRight, RefreshCw, Check, Dumbbell, BookOpen } from "lucide-react";
import { Button } from "@/components/ui/button";
import { motion } from "framer-motion";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Loader2 } from "lucide-react";
import { Progress } from "@/components/ui/progress";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

interface Venue {
  id: string;
  name: string;
  city: string;
  image?: string;
}

interface ScrapeState {
  isLoading: boolean;
  error: string | null;
  details: string | null;
  progress: number;
  totalVenues: number;
  nextPageToken: string | null;
  hasMore: boolean;
}

const popularCities = [
  "Gurgaon",
  "Delhi",
  "Mumbai",
  "Bangalore",
  "Hyderabad",
  "Chennai",
  "Kolkata",
  "Pune",
  "Ahmedabad",
  "Jaipur",
  "Lucknow",
  "Chandigarh"
];

const sportCategories = [
  "Cricket",
  "Football",
  "Basketball",
  "Tennis",
  "Badminton",
  "Pickleball",
  "Yoga",
  "Squash",
  "Table Tennis",
  "Gym",
  "Martial Arts",
  "Boxing",
  "Athletics",
  "Volleyball",
  "Hockey",
  "Golf",
  "Rugby",
  "Kabaddi",
  "Chess",
  "Carrom"
];

const AdminDashboard = () => {
  const [venues, setVenues] = useState<Venue[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCity, setSelectedCity] = useState<string>("Gurgaon");
  const [selectedSport, setSelectedSport] = useState<string>("Cricket");
  const [batchSize, setBatchSize] = useState<number>(20);
  const [skipExisting, setSkipExisting] = useState<boolean>(true);
  
  const [scrapeState, setScrapeState] = useState<ScrapeState>({
    isLoading: false,
    error: null,
    details: null,
    progress: 0,
    totalVenues: 0,
    nextPageToken: null,
    hasMore: false
  });
  
  const [gymScrapeState, setGymScrapeState] = useState<ScrapeState>({
    isLoading: false,
    error: null,
    details: null,
    progress: 0,
    totalVenues: 0,
    nextPageToken: null,
    hasMore: false
  });
  
  const [academyScrapeState, setAcademyScrapeState] = useState<ScrapeState>({
    isLoading: false,
    error: null,
    details: null,
    progress: 0,
    totalVenues: 0,
    nextPageToken: null,
    hasMore: false
  });
  
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    const isAdmin = localStorage.getItem("adminAuthenticated") === "true";
    if (!isAdmin) {
      navigate("/admin");
      return;
    }

    fetchVenues();
  }, [navigate, toast]);

  const fetchVenues = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from("venues")
        .select("id, name, city, images")
        .order("name");

      if (error) throw error;

      const processedVenues = data.map(venue => ({
        id: venue.id,
        name: venue.name,
        city: venue.city,
        image: venue.images && venue.images.length > 0 ? venue.images[0] : undefined
      }));

      setVenues(processedVenues);
    } catch (error) {
      console.error("Error fetching venues:", error);
      toast({
        variant: "destructive",
        title: "Error fetching venues",
        description: "Could not load venues from the database.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("adminAuthenticated");
    toast({
      title: "Logged out",
      description: "You have been logged out of the admin panel",
    });
    navigate("/admin");
  };

  const handleVenueClick = (venueId: string) => {
    navigate(`/admin/venue/${venueId}`);
  };

  const handleScrapeVenues = async (continueFromLastPage = false) => {
    const token = continueFromLastPage ? scrapeState.nextPageToken : null;
    
    setScrapeState(prev => ({
      ...prev,
      isLoading: true,
      error: null,
      details: null,
      nextPageToken: token
    }));
    
    try {
      console.log(`Starting venue scraping for ${selectedSport} venues in ${selectedCity}`);
      console.log(`Batch size: ${batchSize}, Skip existing: ${skipExisting}, Continue from: ${token ? 'Yes' : 'No'}`);
      
      const { data, error } = await supabase.functions.invoke("scrape-venues", {
        body: { 
          city: selectedCity, 
          type: selectedSport,
          batchSize,
          skipExisting,
          nextPageToken: token
        },
      });

      if (error) {
        console.error("Edge function error:", error);
        setScrapeState(prev => ({
          ...prev,
          isLoading: false,
          error: `Edge function error: ${error.message}`
        }));
        throw error;
      }

      if (!data) {
        setScrapeState(prev => ({
          ...prev,
          isLoading: false,
          error: "No data returned from the scraping function"
        }));
        throw new Error("No data returned from scraping function");
      }

      if (data.error) {
        console.error("API Error:", data.error);
        console.error("Error details:", data.details);
        console.error("DB Error:", data.dbError);
        
        setScrapeState(prev => ({
          ...prev,
          isLoading: false,
          error: `API Error: ${data.error}`,
          details: data.details || data.dbError || data.hint || "No additional details provided"
        }));
        throw new Error(data.error);
      }

      console.log("Scrape response:", data);
      
      setScrapeState(prev => ({
        ...prev,
        isLoading: false,
        details: `Successfully added ${data.venues ? data.venues.length : 0} venues to the database`,
        totalVenues: prev.totalVenues + (data.venues ? data.venues.length : 0),
        nextPageToken: data.next_page_token,
        hasMore: data.has_more || false,
        progress: prev.progress + 25
      }));
      
      toast({
        title: "Venues scraped successfully",
        description: `Added ${data.venues ? data.venues.length : 0} ${selectedSport} venues from ${selectedCity}`,
      });

      fetchVenues();

    } catch (error) {
      console.error("Error scraping venues:", error);
      
      setScrapeState(prev => ({
        ...prev,
        isLoading: false,
        error: prev.error || "Failed to scrape venues. See console for details."
      }));
      
      toast({
        variant: "destructive",
        title: "Error scraping venues",
        description: scrapeState.error || "Could not scrape venues. Please check logs for details.",
      });
    }
  };

  const handleScrapeGyms = async (continueFromLastPage = false) => {
    const token = continueFromLastPage ? gymScrapeState.nextPageToken : null;
    
    setGymScrapeState(prev => ({
      ...prev,
      isLoading: true,
      error: null,
      details: null,
      nextPageToken: token
    }));
    
    try {
      console.log(`Starting gym scraping in ${selectedCity}`);
      console.log(`Batch size: ${batchSize}, Skip existing: ${skipExisting}, Continue from: ${token ? 'Yes' : 'No'}`);
      
      const { data, error } = await supabase.functions.invoke("scrape-gyms", {
        body: { 
          city: selectedCity,
          batchSize,
          skipExisting,
          nextPageToken: token
        },
      });

      if (error) {
        console.error("Edge function error:", error);
        setGymScrapeState(prev => ({
          ...prev,
          isLoading: false,
          error: `Edge function error: ${error.message}`
        }));
        throw error;
      }

      if (!data) {
        setGymScrapeState(prev => ({
          ...prev,
          isLoading: false,
          error: "No data returned from the gym scraping function"
        }));
        throw new Error("No data returned from gym scraping function");
      }

      if (data.error) {
        console.error("API Error:", data.error);
        console.error("Error details:", data.details);
        console.error("DB Error:", data.dbError);
        
        setGymScrapeState(prev => ({
          ...prev,
          isLoading: false,
          error: `API Error: ${data.error}`,
          details: data.details || data.dbError || data.hint || "No additional details provided"
        }));
        throw new Error(data.error);
      }

      console.log("Gym scrape response:", data);
      
      setGymScrapeState(prev => ({
        ...prev,
        isLoading: false,
        details: `Successfully added ${data.gyms ? data.gyms.length : 0} gyms to the database`,
        totalVenues: prev.totalVenues + (data.gyms ? data.gyms.length : 0),
        nextPageToken: data.next_page_token,
        hasMore: data.has_more || false,
        progress: prev.progress + 25
      }));
      
      toast({
        title: "Gyms scraped successfully",
        description: `Added ${data.gyms ? data.gyms.length : 0} gyms from ${selectedCity}`,
      });

    } catch (error) {
      console.error("Error scraping gyms:", error);
      
      setGymScrapeState(prev => ({
        ...prev,
        isLoading: false,
        error: prev.error || "Failed to scrape gyms. See console for details."
      }));
      
      toast({
        variant: "destructive",
        title: "Error scraping gyms",
        description: gymScrapeState.error || "Could not scrape gyms. Please check logs for details.",
      });
    }
  };

  const handleScrapeAcademies = async (continueFromLastPage = false) => {
    const token = continueFromLastPage ? academyScrapeState.nextPageToken : null;
    
    setAcademyScrapeState(prev => ({
      ...prev,
      isLoading: true,
      error: null,
      details: null,
      nextPageToken: token
    }));
    
    try {
      console.log(`Starting academy scraping for ${selectedSport} academies in ${selectedCity}`);
      console.log(`Batch size: ${batchSize}, Skip existing: ${skipExisting}, Continue from: ${token ? 'Yes' : 'No'}`);
      
      const { data, error } = await supabase.functions.invoke("scrape-academies", {
        body: { 
          city: selectedCity,
          sport: selectedSport,
          batchSize,
          skipExisting,
          nextPageToken: token
        },
      });

      if (error) {
        console.error("Edge function error:", error);
        setAcademyScrapeState(prev => ({
          ...prev,
          isLoading: false,
          error: `Edge function error: ${error.message}`
        }));
        throw error;
      }

      if (!data) {
        setAcademyScrapeState(prev => ({
          ...prev,
          isLoading: false,
          error: "No data returned from the academy scraping function"
        }));
        throw new Error("No data returned from academy scraping function");
      }

      if (data.error) {
        console.error("API Error:", data.error);
        console.error("Error details:", data.details);
        console.error("DB Error:", data.dbError);
        
        setAcademyScrapeState(prev => ({
          ...prev,
          isLoading: false,
          error: `API Error: ${data.error}`,
          details: data.details || data.dbError || data.hint || "No additional details provided"
        }));
        throw new Error(data.error);
      }

      console.log("Academy scrape response:", data);
      
      setAcademyScrapeState(prev => ({
        ...prev,
        isLoading: false,
        details: `Successfully added ${data.academies ? data.academies.length : 0} academies to the database`,
        totalVenues: prev.totalVenues + (data.academies ? data.academies.length : 0),
        nextPageToken: data.next_page_token,
        hasMore: data.has_more || false,
        progress: prev.progress + 25
      }));
      
      toast({
        title: "Academies scraped successfully",
        description: `Added ${data.academies ? data.academies.length : 0} ${selectedSport} academies from ${selectedCity}`,
      });

    } catch (error) {
      console.error("Error scraping academies:", error);
      
      setAcademyScrapeState(prev => ({
        ...prev,
        isLoading: false,
        error: prev.error || "Failed to scrape academies. See console for details."
      }));
      
      toast({
        variant: "destructive",
        title: "Error scraping academies",
        description: academyScrapeState.error || "Could not scrape academies. Please check logs for details.",
      });
    }
  };

  const handleContinueScraping = () => {
    if (scrapeState.hasMore && scrapeState.nextPageToken) {
      handleScrapeVenues(true);
    }
  };
  
  const handleContinueScrapingGyms = () => {
    if (gymScrapeState.hasMore && gymScrapeState.nextPageToken) {
      handleScrapeGyms(true);
    }
  };

  const handleContinueScrapingAcademies = () => {
    if (academyScrapeState.hasMore && academyScrapeState.nextPageToken) {
      handleScrapeAcademies(true);
    }
  };

  const resetScrapeState = () => {
    setScrapeState({
      isLoading: false,
      error: null,
      details: null,
      progress: 0,
      totalVenues: 0,
      nextPageToken: null,
      hasMore: false
    });
  };
  
  const resetGymScrapeState = () => {
    setGymScrapeState({
      isLoading: false,
      error: null,
      details: null,
      progress: 0,
      totalVenues: 0,
      nextPageToken: null,
      hasMore: false
    });
  };

  const resetAcademyScrapeState = () => {
    setAcademyScrapeState({
      isLoading: false,
      error: null,
      details: null,
      progress: 0,
      totalVenues: 0,
      nextPageToken: null,
      hasMore: false
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 pt-32 pb-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center">
            <Shield className="h-8 w-8 text-indigo-600 dark:text-indigo-400 mr-3" />
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Admin Dashboard</h1>
          </div>
          <Button 
            variant="outline"
            className="flex items-center gap-2 border-red-300 text-red-600 hover:bg-red-50 hover:text-red-700 dark:border-red-800 dark:text-red-400 dark:hover:bg-red-950"
            onClick={handleLogout}
          >
            <LogOut className="h-4 w-4" />
            Logout
          </Button>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          <Card className="hover:shadow-lg transition-all duration-300">
            <CardHeader className="pb-2">
              <CardTitle className="text-lg">Venue Image Updater</CardTitle>
              <CardDescription>Download and store venue images locally</CardDescription>
            </CardHeader>
            <CardContent>
              <p className="text-sm text-gray-500">
                Update external venue images from Google Custom Search results for better performance
              </p>
            </CardContent>
            <CardFooter>
              <Button 
                className="w-full"
                onClick={() => navigate('/admin/venue-images')}
              >
                Manage Venue Images
              </Button>
            </CardFooter>
          </Card>
        </div>
        
        <Card className="mb-8 bg-gradient-to-br from-indigo-50 to-purple-50 border-none dark:from-gray-800 dark:to-gray-900">
          <CardHeader>
            <CardTitle className="text-xl text-indigo-900 dark:text-indigo-300">Venue Management</CardTitle>
            <CardDescription className="text-indigo-700 dark:text-indigo-400">
              Select a venue to manage its availability and block time slots
            </CardDescription>
          </CardHeader>
        </Card>
        
        {isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 animate-pulse">
            {[1, 2, 3, 4, 5, 6].map((item) => (
              <div key={item} className="bg-gray-200 dark:bg-gray-800 h-64 rounded-lg"></div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-10">
            {venues.map((venue, index) => (
              <motion.div
                key={venue.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="cursor-pointer"
                onClick={() => handleVenueClick(venue.id)}
              >
                <Card className="h-full overflow-hidden hover:shadow-lg transition-all duration-300 group border dark:border-gray-700">
                  <div className="relative h-40 overflow-hidden">
                    {venue.image ? (
                      <img
                        src={venue.image}
                        alt={venue.name}
                        className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                      />
                    ) : (
                      <div className="w-full h-full bg-gradient-to-r from-purple-400 to-indigo-500 flex items-center justify-center">
                        <Building className="h-16 w-16 text-white opacity-75" />
                      </div>
                    )}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-70"></div>
                    <div className="absolute bottom-0 left-0 right-0 p-4">
                      <h3 className="text-xl font-semibold text-white">{venue.name}</h3>
                      <p className="text-sm text-gray-200">{venue.city}</p>
                    </div>
                  </div>
                  
                  <CardContent className="p-4">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center text-indigo-600 dark:text-indigo-400">
                        <Clock className="h-4 w-4 mr-2" />
                        <span className="text-sm">Manage Time Slots</span>
                      </div>
                      <Button 
                        size="sm" 
                        className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white"
                      >
                        <Calendar className="h-4 w-4 mr-2" />
                        Manage
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
        )}
        
        <Card className="mb-8 bg-gradient-to-br from-green-50 to-emerald-50 border-none dark:from-gray-800 dark:to-gray-900">
          <CardHeader>
            <CardTitle className="text-xl text-emerald-900 dark:text-emerald-300">
              <div className="flex items-center">
                <Database className="h-5 w-5 mr-2" />
                Data Import
              </div>
            </CardTitle>
            <CardDescription className="text-emerald-700 dark:text-emerald-400">
              Import venues, gyms, and academies from Google Maps by specifying a city
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Tabs defaultValue="venues" className="w-full">
              <TabsList className="grid w-full grid-cols-3 mb-4">
                <TabsTrigger value="venues" className="text-sm">
                  <MapPin className="h-4 w-4 mr-2" />
                  Venues
                </TabsTrigger>
                <TabsTrigger value="gyms" className="text-sm">
                  <Dumbbell className="h-4 w-4 mr-2" />
                  Gyms
                </TabsTrigger>
                <TabsTrigger value="academies" className="text-sm">
                  <BookOpen className="h-4 w-4 mr-2" />
                  Academies
                </TabsTrigger>
              </TabsList>
              
              <TabsContent value="venues">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
                  <div>
                    <Label htmlFor="city-select">City</Label>
                    <Select value={selectedCity} onValueChange={setSelectedCity}>
                      <SelectTrigger id="city-select" className="w-full mt-1">
                        <SelectValue placeholder="Select City" />
                      </SelectTrigger>
                      <SelectContent>
                        {popularCities.map((city) => (
                          <SelectItem key={city} value={city}>{city}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  
                  <div>
                    <Label htmlFor="sport-select">Sport Type</Label>
                    <Select value={selectedSport} onValueChange={setSelectedSport}>
                      <SelectTrigger id="sport-select" className="w-full mt-1">
                        <SelectValue placeholder="Select Sport" />
                      </SelectTrigger>
                      <SelectContent>
                        {sportCategories.map((sport) => (
                          <SelectItem key={sport} value={sport}>{sport}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  
                  <div>
                    <Label htmlFor="batch-size">Batch Size</Label>
                    <Input 
                      id="batch-size"
                      type="number"
                      value={batchSize}
                      onChange={(e) => setBatchSize(parseInt(e.target.value) || 20)}
                      min="1"
                      max="50"
                      className="mt-1"
                    />
                  </div>
                  
                  <div className="flex flex-col justify-end mt-1 lg:col-start-1">
                    <div className="flex items-center space-x-2">
                      <Checkbox 
                        id="skip-existing" 
                        checked={skipExisting}
                        onCheckedChange={(checked) => setSkipExisting(checked as boolean)}
                      />
                      <Label htmlFor="skip-existing">Skip existing venues</Label>
                    </div>
                  </div>
                </div>
                
                <div className="flex flex-col md:flex-row gap-4 mt-6">
                  <Button 
                    className="flex-1 bg-gradient-to-r from-emerald-500 to-green-500 hover:from-emerald-600 hover:to-green-600 text-white"
                    onClick={() => handleScrapeVenues(false)}
                    disabled={scrapeState.isLoading}
                  >
                    {scrapeState.isLoading ? (
                      <>
                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                        Importing Venues...
                      </>
                    ) : (
                      <>
                        <MapPin className="h-4 w-4 mr-2" />
                        Import Venues
                      </>
                    )}
                  </Button>
                  
                  {scrapeState.hasMore && scrapeState.nextPageToken && (
                    <Button 
                      className="flex-1 bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-600 hover:to-indigo-600 text-white"
                      onClick={handleContinueScraping}
                      disabled={scrapeState.isLoading}
                    >
                      {scrapeState.isLoading ? (
                        <>
                          <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                          Loading More...
                        </>
                      ) : (
                        <>
                          <ChevronRight className="h-4 w-4 mr-2" />
                          Load Next Page
                        </>
                      )}
                    </Button>
                  )}
                  
                  {scrapeState.totalVenues > 0 && (
                    <Button 
                      variant="outline" 
                      className="flex-1"
                      onClick={resetScrapeState}
                    >
                      <RefreshCw className="h-4 w-4 mr-2" />
                      Reset Counter
                    </Button>
                  )}
                </div>
                
                {scrapeState.totalVenues > 0 && (
                  <div className="mt-6">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-sm font-medium text-emerald-700 dark:text-emerald-400">
                        Progress: {scrapeState.progress}%
                      </span>
                      <span className="text-sm font-medium text-emerald-700 dark:text-emerald-400">
                        Total venues imported: {scrapeState.totalVenues}
                      </span>
                    </div>
                    <Progress value={scrapeState.progress} className="h-2 bg-emerald-100 dark:bg-emerald-900" />
                  </div>
                )}
                
                {scrapeState.error && (
                  <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-lg dark:bg-red-900/20 dark:border-red-800">
                    <div className="flex items-start">
                      <AlertCircle className="h-5 w-5 text-red-600 dark:text-red-400 mr-2 mt-0.5" />
                      <div>
                        <h4 className="font-medium text-red-800 dark:text-red-300">Error Scraping Venues</h4>
                        <p className="text-red-700 dark:text-red-400 text-sm mt-1">{scrapeState.error}</p>
                        {scrapeState.details && (
                          <div className="mt-2 p-2 bg-red-100 dark:bg-red-900/40 rounded text-sm text-red-800 dark:text-red-300 font-mono overflow-auto max-h-64">
                            {scrapeState.details}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                
                {!scrapeState.error && scrapeState.details && (
                  <div className="mt-4 p-4 bg-green-50 border border-green-200 rounded-lg dark:bg-green-900/20 dark:border-green-800">
                    <div className="flex items-start">
                      <Check className="h-5 w-5 text-green-600 dark:text-green-400 mr-2 mt-0.5" />
                      <div>
                        <h4 className="font-medium text-green-800 dark:text-green-300">Success</h4>
                        <p className="text-green-700 dark:text-green-400 text-sm mt-1">{scrapeState.details}</p>
                        {scrapeState.hasMore && (
                          <p className="text-green-700 dark:text-green-400 text-sm mt-1">
                            There are more venues available. Click "Load Next Page" to continue.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </TabsContent>
              
              <TabsContent value="gyms">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label htmlFor="gym-city-select">City</Label>
                    <Select value={selectedCity} onValueChange={setSelectedCity}>
                      <SelectTrigger id="gym-city-select" className="w-full mt-1">
                        <SelectValue placeholder="Select City" />
                      </SelectTrigger>
                      <SelectContent>
                        {popularCities.map((city) => (
                          <SelectItem key={city} value={city}>{city}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  
                  <div>
                    <Label htmlFor="gym-batch-size">Batch Size</Label>
                    <Input 
                      id="gym-batch-size"
                      type="number"
                      value={batchSize}
                      onChange={(e) => setBatchSize(parseInt(e.target.value) || 20)}
                      min="1"
                      max="50"
                      className="mt-1"
                    />
                  </div>
                  
                  <div className="flex flex-col justify-end mt-1">
                    <div className="flex items-center space-x-2">
                      <Checkbox 
                        id="gym-skip-existing" 
                        checked={skipExisting}
                        onCheckedChange={(checked) => setSkipExisting(checked as boolean)}
                      />
                      <Label htmlFor="gym-skip-existing">Skip existing gyms</Label>
                    </div>
                  </div>
                </div>
                
                <div className="flex flex-col md:flex-row gap-4 mt-6">
                  <Button 
                    className="flex-1 bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white"
                    onClick={() => handleScrapeGyms(false)}
                    disabled={gymScrapeState.isLoading}
                  >
                    {gymScrapeState.isLoading ? (
                      <>
                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                        Importing Gyms...
                      </>
                    ) : (
                      <>
                        <Dumbbell className="h-4 w-4 mr-2" />
                        Import Gyms
                      </>
                    )}
                  </Button>
                  
                  {gymScrapeState.hasMore && gymScrapeState.nextPageToken && (
                    <Button 
                      className="flex-1 bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-600 hover:to-indigo-600 text-white"
                      onClick={handleContinueScrapingGyms}
                      disabled={gymScrapeState.isLoading}
                    >
                      {gymScrapeState.isLoading ? (
                        <>
                          <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                          Loading More...
                        </>
                      ) : (
                        <>
                          <ChevronRight className="h-4 w-4 mr-2" />
                          Load Next Page
                        </>
                      )}
                    </Button>
                  )}
                  
                  {gymScrapeState.totalVenues > 0 && (
                    <Button 
                      variant="outline" 
                      className="flex-1"
                      onClick={resetGymScrapeState}
                    >
                      <RefreshCw className="h-4 w-4 mr-2" />
                      Reset Counter
                    </Button>
                  )}
                </div>
                
                {gymScrapeState.totalVenues > 0 && (
                  <div className="mt-6">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-sm font-medium text-indigo-700 dark:text-indigo-400">
                        Progress: {gymScrapeState.progress}%
                      </span>
                      <span className="text-sm font-medium text-indigo-700 dark:text-indigo-400">
                        Total gyms imported: {gymScrapeState.totalVenues}
                      </span>
                    </div>
                    <Progress value={gymScrapeState.progress} className="h-2 bg-indigo-100 dark:bg-indigo-900" />
                  </div>
                )}
                
                {gymScrapeState.error && (
                  <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-lg dark:bg-red-900/20 dark:border-red-800">
                    <div className="flex items-start">
                      <AlertCircle className="h-5 w-5 text-red-600 dark:text-red-400 mr-2 mt-0.5" />
                      <div>
                        <h4 className="font-medium text-red-800 dark:text-red-300">Error Scraping Gyms</h4>
                        <p className="text-red-700 dark:text-red-400 text-sm mt-1">{gymScrapeState.error}</p>
                        {gymScrapeState.details && (
                          <div className="mt-2 p-2 bg-red-100 dark:bg-red-900/40 rounded text-sm text-red-800 dark:text-red-300 font-mono overflow-auto max-h-64">
                            {gymScrapeState.details}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                
                {!gymScrapeState.error && gymScrapeState.details && (
                  <div className="mt-4 p-4 bg-green-50 border border-green-200 rounded-lg dark:bg-green-900/20 dark:border-green-800">
                    <div className="flex items-start">
                      <Check className="h-5 w-5 text-green-600 dark:text-green-400 mr-2 mt-0.5" />
                      <div>
                        <h4 className="font-medium text-green-800 dark:text-green-300">Success</h4>
                        <p className="text-green-700 dark:text-green-400 text-sm mt-1">{gymScrapeState.details}</p>
                        {gymScrapeState.hasMore && (
                          <p className="text-green-700 dark:text-green-400 text-sm mt-1">
                            There are more gyms available. Click "Load Next Page" to continue.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </TabsContent>
              
              <TabsContent value="academies">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                  <div>
                    <Label htmlFor="academy-city-select">City</Label>
                    <Select value={selectedCity} onValueChange={setSelectedCity}>
                      <SelectTrigger id="academy-city-select" className="w-full mt-1">
                        <SelectValue placeholder="Select City" />
                      </SelectTrigger>
                      <SelectContent>
                        {popularCities.map((city) => (
                          <SelectItem key={city} value={city}>{city}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  
                  <div>
                    <Label htmlFor="academy-sport-select">Sport</Label>
                    <Select value={selectedSport} onValueChange={setSelectedSport}>
                      <SelectTrigger id="academy-sport-select" className="w-full mt-1">
                        <SelectValue placeholder="Select Sport" />
                      </SelectTrigger>
                      <SelectContent>
                        {sportCategories.map((sport) => (
                          <SelectItem key={sport} value={sport}>{sport}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  
                  <div>
                    <Label htmlFor="academy-batch-size">Batch Size</Label>
                    <Input 
                      id="academy-batch-size"
                      type="number"
                      value={batchSize}
                      onChange={(e) => setBatchSize(parseInt(e.target.value) || 20)}
                      min="1"
                      max="50"
                      className="mt-1"
                    />
                  </div>
                  
                  <div className="flex flex-col justify-end mt-1 lg:col-start-1">
                    <div className="flex items-center space-x-2">
                      <Checkbox 
                        id="academy-skip-existing" 
                        checked={skipExisting}
                        onCheckedChange={(checked) => setSkipExisting(checked as boolean)}
                      />
                      <Label htmlFor="academy-skip-existing">Skip existing academies</Label>
                    </div>
                  </div>
                </div>
                
                <div className="flex flex-col md:flex-row gap-4 mt-6">
                  <Button 
                    className="flex-1 bg-gradient-to-r from-blue-500 to-cyan-500 hover:from-blue-600 hover:to-cyan-600 text-white"
                    onClick={() => handleScrapeAcademies(false)}
                    disabled={academyScrapeState.isLoading}
                  >
                    {academyScrapeState.isLoading ? (
                      <>
                        <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                        Importing Academies...
                      </>
                    ) : (
                      <>
                        <BookOpen className="h-4 w-4 mr-2" />
                        Import Academies
                      </>
                    )}
                  </Button>
                  
                  {academyScrapeState.hasMore && academyScrapeState.nextPageToken && (
                    <Button 
                      className="flex-1 bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-600 hover:to-indigo-600 text-white"
                      onClick={handleContinueScrapingAcademies}
                      disabled={academyScrapeState.isLoading}
                    >
                      {academyScrapeState.isLoading ? (
                        <>
                          <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                          Loading More...
                        </>
                      ) : (
                        <>
                          <ChevronRight className="h-4 w-4 mr-2" />
                          Load Next Page
                        </>
                      )}
                    </Button>
                  )}
                  
                  {academyScrapeState.totalVenues > 0 && (
                    <Button 
                      variant="outline" 
                      className="flex-1"
                      onClick={resetAcademyScrapeState}
                    >
                      <RefreshCw className="h-4 w-4 mr-2" />
                      Reset Counter
                    </Button>
                  )}
                </div>
                
                {academyScrapeState.totalVenues > 0 && (
                  <div className="mt-6">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-sm font-medium text-blue-700 dark:text-blue-400">
                        Progress: {academyScrapeState.progress}%
                      </span>
                      <span className="text-sm font-medium text-blue-700 dark:text-blue-400">
                        Total academies imported: {academyScrapeState.totalVenues}
                      </span>
                    </div>
                    <Progress value={academyScrapeState.progress} className="h-2 bg-blue-100 dark:bg-blue-900" />
                  </div>
                )}
                
                {academyScrapeState.error && (
                  <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-lg dark:bg-red-900/20 dark:border-red-800">
                    <div className="flex items-start">
                      <AlertCircle className="h-5 w-5 text-red-600 dark:text-red-400 mr-2 mt-0.5" />
                      <div>
                        <h4 className="font-medium text-red-800 dark:text-red-300">Error Scraping Academies</h4>
                        <p className="text-red-700 dark:text-red-400 text-sm mt-1">{academyScrapeState.error}</p>
                        {academyScrapeState.details && (
                          <div className="mt-2 p-2 bg-red-100 dark:bg-red-900/40 rounded text-sm text-red-800 dark:text-red-300 font-mono overflow-auto max-h-64">
                            {academyScrapeState.details}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                
                {!academyScrapeState.error && academyScrapeState.details && (
                  <div className="mt-4 p-4 bg-green-50 border border-green-200 rounded-lg dark:bg-green-900/20 dark:border-green-800">
                    <div className="flex items-start">
                      <Check className="h-5 w-5 text-green-600 dark:text-green-400 mr-2 mt-0.5" />
                      <div>
                        <h4 className="font-medium text-green-800 dark:text-green-300">Success</h4>
                        <p className="text-green-700 dark:text-green-400 text-sm mt-1">{academyScrapeState.details}</p>
                        {academyScrapeState.hasMore && (
                          <p className="text-green-700 dark:text-green-400 text-sm mt-1">
                            There are more academies available. Click "Load Next Page" to continue.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AdminDashboard;
