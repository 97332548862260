
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { MessageSquare, ArrowRight } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { useState } from "react";
import { motion } from "framer-motion";

export const WhatsAppSection = () => {
  const { toast } = useToast();
  const [isJoining, setIsJoining] = useState(false);

  const handleJoinWaitlist = () => {
    setIsJoining(true);
    
    // Simulate API call with timeout
    setTimeout(() => {
      setIsJoining(false);
      toast({
        title: "You've joined the waitlist!",
        description: "We'll notify you when WhatsApp communities are available.",
        duration: 3000,
      });
    }, 500);
  };

  return (
    <Card className="bg-gradient-to-br from-green-50 to-emerald-50 shadow-lg hover:shadow-xl transition-all duration-300 border-green-100 overflow-hidden">
      <div className="absolute -right-6 -top-6 w-20 h-20 bg-green-100 rounded-full opacity-70"></div>
      <div className="absolute right-12 top-20 w-8 h-8 bg-green-200 rounded-full opacity-50"></div>
      <CardHeader className="pb-3 relative z-10">
        <CardTitle className="flex items-center gap-2 text-green-800">
          <motion.div
            animate={{ rotate: [0, 5, 0, -5, 0] }}
            transition={{ repeat: Infinity, duration: 5, ease: "easeInOut" }}
            className="p-2 bg-green-100 rounded-full"
          >
            <MessageSquare className="w-5 h-5 text-green-600" />
          </motion.div>
          WhatsApp Communities
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4 relative z-10">
        <p className="text-gray-600">
          Connect with sports and fitness enthusiasts in your area through our curated WhatsApp communities.
          This feature is coming soon!
        </p>
        <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
          <Button 
            className="w-full bg-gradient-to-r from-green-500 to-emerald-500 text-white group hover:from-green-600 hover:to-emerald-600 transition-all duration-300 shadow-md hover:shadow-lg"
            onClick={handleJoinWaitlist}
            disabled={isJoining}
          >
            {isJoining ? (
              <span className="flex items-center">
                <motion.div
                  className="mr-2 h-4 w-4 rounded-full border-2 border-t-transparent border-white animate-spin"
                />
                Joining...
              </span>
            ) : (
              <span className="flex items-center justify-center w-full">
                Join Waitlist
                <motion.div
                  className="inline-block ml-2"
                  animate={{ x: [0, 4, 0] }}
                  transition={{ repeat: Infinity, duration: 1.5, ease: "easeInOut" }}
                >
                  <ArrowRight className="h-4 w-4" />
                </motion.div>
              </span>
            )}
          </Button>
        </motion.div>
      </CardContent>
    </Card>
  );
};
