
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { WifiOff, CloudOff, ArrowUpCircle } from "lucide-react";
import { Alert, AlertTitle, AlertDescription } from "./ui/alert";
import { useIsMobile } from "../hooks/use-mobile";

export const OfflineNotification = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showRetryTip, setShowRetryTip] = useState(false);
  const isMobile = useIsMobile();
  
  useEffect(() => {
    // Delay appearance for a smoother UX
    const timer = setTimeout(() => setIsVisible(true), 300);
    // Show retry tip after 5 seconds
    const retryTimer = setTimeout(() => setShowRetryTip(true), 5000);
    
    return () => {
      clearTimeout(timer);
      clearTimeout(retryTimer);
    };
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={`fixed z-50 flex justify-center p-4 ${isMobile ? 'inset-0 items-center' : 'top-0 left-0 right-0'}`}
          initial={{ y: isMobile ? 0 : -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: isMobile ? 0 : -100, opacity: 0 }}
          transition={{ type: "spring", damping: 20, stiffness: 300 }}
        >
          <Alert className="max-w-md bg-gradient-to-r from-slate-900 to-purple-900 text-white border-none shadow-xl backdrop-blur-xl">
            <div className="flex items-start gap-4">
              <div className="relative">
                {/* Animated WiFi icon with pulsing effect */}
                <div className="relative">
                  <motion.div
                    className="absolute inset-0 bg-red-500 rounded-full"
                    initial={{ scale: 0.8, opacity: 0.5 }}
                    animate={{ 
                      scale: [0.8, 1.2, 0.8],
                      opacity: [0.5, 0.2, 0.5]
                    }}
                    transition={{ 
                      repeat: Infinity,
                      duration: 2
                    }}
                  />
                  <WifiOff className="w-6 h-6 relative z-10" />
                </div>
              </div>
              
              <div className="space-y-3 flex-1">
                <div>
                  <AlertTitle className="text-lg font-bold tracking-tight mb-1">
                    You're offline
                  </AlertTitle>
                  <AlertDescription className="text-white/80">
                    <motion.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.2 }}
                    >
                      Houston, we have a connection problem! Let's wait for your internet to return from its coffee break.
                    </motion.p>
                  </AlertDescription>
                </div>
                
                <AnimatePresence>
                  {showRetryTip && (
                    <motion.div 
                      className="flex items-center gap-2 text-xs text-white/70 bg-white/10 rounded-lg p-2"
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                    >
                      <ArrowUpCircle className="w-4 h-4 text-emerald-400" />
                      <p>Pull down to refresh when connection returns</p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              
              {/* Disconnected cloud animation */}
              <motion.div
                animate={{ 
                  y: [0, -3, 0, 3, 0],
                  rotate: [0, 5, 0, -5, 0]
                }}
                transition={{ 
                  repeat: Infinity,
                  duration: 5,
                  ease: "easeInOut"
                }}
              >
                <CloudOff className="w-5 h-5 text-white/40" />
              </motion.div>
            </div>
            
            {/* Animated dots to indicate waiting */}
            <div className="flex justify-center mt-3">
              <div className="flex space-x-2">
                {[0, 1, 2].map((dot) => (
                  <motion.div
                    key={dot}
                    className="w-2 h-2 rounded-full bg-white/50"
                    animate={{
                      opacity: [0.3, 1, 0.3],
                      scale: [0.8, 1.2, 0.8],
                    }}
                    transition={{
                      duration: 1.5,
                      repeat: Infinity,
                      delay: dot * 0.2,
                    }}
                  />
                ))}
              </div>
            </div>
          </Alert>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
