
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { TimeoutNotification } from "./timeout-notification";

const MobileLoadingScreen = () => {
  const [colorScheme, setColorScheme] = useState<number>(0);
  
  const colorSchemes = [
    ["#ff00ff", "#00bfff"], // Magenta to Cyan (Brand colors)
    ["#FF9933", "#138808"], // Saffron to Green (Indian flag inspired)
    ["#8B5CF6", "#D946EF"], // Purple to Pink (Royal colors)
    ["#F97316", "#1E3A8A"]  // Orange to Navy (Contrast)
  ];
  
  useEffect(() => {
    setColorScheme(Math.floor(Math.random() * colorSchemes.length));
  }, []);

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-black overflow-hidden">
      <motion.div 
        className="absolute inset-0 opacity-30"
        animate={{
          background: [
            `radial-gradient(circle at 30% 70%, ${colorSchemes[colorScheme][0]}30, transparent 50%)`,
            `radial-gradient(circle at 70% 30%, ${colorSchemes[colorScheme][1]}30, transparent 50%)`,
            `radial-gradient(circle at 30% 70%, ${colorSchemes[colorScheme][0]}30, transparent 50%)`
          ]
        }}
        transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
      />
      
      <div className="absolute inset-0">
        {[...Array(30)].map((_, i) => (
          <motion.div
            key={`star-${i}`}
            className="absolute w-1 h-1 bg-white rounded-full"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
            }}
            animate={{
              opacity: [0.1, 0.8, 0.1],
              scale: [1, 1.5, 1]
            }}
            transition={{
              duration: 2 + Math.random() * 2,
              repeat: Infinity,
              delay: Math.random() * 2
            }}
          />
        ))}
      </div>
      
      <div className="relative z-10 flex flex-col items-center">
        <motion.div
          className="relative w-40 h-40 flex items-center justify-center"
          animate={{ rotate: 360 }}
          transition={{ duration: 8, repeat: Infinity, ease: "linear" }}
        >
          <motion.div
            className="absolute w-full h-full rounded-full border-2 border-dashed"
            style={{ borderColor: colorSchemes[colorScheme][0] }}
            animate={{ 
              scale: [1, 1.05, 1],
              opacity: [0.7, 1, 0.7]
            }}
            transition={{ 
              duration: 2, 
              repeat: Infinity, 
              ease: "easeInOut" 
            }}
          />
          
          <motion.div
            className="absolute w-3/4 h-3/4 rounded-full border"
            style={{ borderColor: colorSchemes[colorScheme][1] }}
            animate={{ 
              rotate: -360,
              scale: [1, 1.1, 1]
            }}
            transition={{ 
              rotate: { duration: 5, repeat: Infinity, ease: "linear" },
              scale: { duration: 3, repeat: Infinity, ease: "easeInOut" }
            }}
          />
          
          {/* Boomerang animation removed */}
        </motion.div>
        
        <motion.div
          className="mt-8 flex flex-col items-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          <motion.h1 
            className="text-3xl font-bold text-white mb-1"
            animate={{ 
              textShadow: [
                "0 0 8px rgba(255,255,255,0.5)",
                "0 0 16px rgba(255,255,255,0.8)",
                "0 0 8px rgba(255,255,255,0.5)"
              ] 
            }}
            transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
          >
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#ff00ff] to-[#00bfff]">
              beSportly
            </span>
          </motion.h1>
          
          <motion.div
            className="relative overflow-hidden rounded-full px-4 py-1"
            style={{
              background: `linear-gradient(45deg, ${colorSchemes[colorScheme][0]}80, ${colorSchemes[colorScheme][1]}80)`
            }}
          >
            <motion.div 
              className="absolute inset-0 bg-white opacity-30"
              animate={{
                x: ["-100%", "100%"],
                opacity: [0, 0.5, 0]
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                ease: "easeInOut",
                repeatDelay: 1
              }}
            />
            
            <p className="text-white font-medium tracking-wider text-sm relative z-10">
              India plays here
            </p>
          </motion.div>
        </motion.div>
        
        <div className="mt-12 flex space-x-3">
          {[0, 1, 2].map((i) => (
            <motion.div
              key={i}
              className="w-2 h-2 rounded-full bg-white"
              animate={{
                scale: [1, 1.5, 1],
                opacity: [0.5, 1, 0.5]
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                delay: i * 0.2,
                ease: "easeInOut"
              }}
            />
          ))}
        </div>
        
        {['🏏', '🏀', '⚽️', '🏸', '🏊‍♂️'].map((emoji, i) => (
          <motion.div
            key={`emoji-${i}`}
            className="absolute text-lg opacity-70"
            style={{
              x: Math.cos(Math.PI * 2 / 5 * i) * 100,
              y: Math.sin(Math.PI * 2 / 5 * i) * 100,
              translateX: '-50%',
              translateY: '-50%'
            }}
            animate={{
              y: [`${Math.sin(Math.PI * 2 / 5 * i) * 100}px`, `${Math.sin(Math.PI * 2 / 5 * i) * 100 + 10}px`, `${Math.sin(Math.PI * 2 / 5 * i) * 100}px`],
              rotate: [0, 10, 0, -10, 0],
              scale: [1, 1.1, 1]
            }}
            transition={{
              y: { duration: 2 + i * 0.2, repeat: Infinity, ease: "easeInOut" },
              rotate: { duration: 4 + i * 0.3, repeat: Infinity, ease: "easeInOut" },
              scale: { duration: 3 + i * 0.2, repeat: Infinity, ease: "easeInOut" }
            }}
          >
            {emoji}
          </motion.div>
        ))}
      </div>
      
      <TimeoutNotification 
        isMobile={true} 
        message="This is taking longer than expected. Please close and reopen the app to try again."
        timeout={3500}
        refreshTimeout={6000}
      />
    </div>
  );
};

export default MobileLoadingScreen;
