
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { X, Phone, Key, Mail, Info, Shield, Eye, ChevronDown, AlertCircle, CheckCircle2, RefreshCw } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import type { AuthError } from "@supabase/supabase-js";
import { useAuth } from "@/components/AuthProvider";
import { countryCodes } from "@/utils/countryData";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "@/components/ui/input-otp";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useToast } from "@/hooks/use-toast";
import { Checkbox } from "@/components/ui/checkbox";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

export default function Auth() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser, setSession } = useAuth();
  const { toast } = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [signupError, setSignupError] = useState<string | null>(null);
  const [forgotPasswordError, setForgotPasswordError] = useState<string | null>(null);
  const [mode, setMode] = useState<"email" | "phone">("phone");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [signupOtpSent, setSignupOtpSent] = useState(false);
  const [signupOtp, setSignupOtp] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetPasswordStep, setResetPasswordStep] = useState<"email" | "otp" | "password">("email");
  const [newPassword, setNewPassword] = useState("");
  const [privacyConsent, setPrivacyConsent] = useState(false);
  const [showPrivacyPopup, setShowPrivacyPopup] = useState(false);
  const [showEmailVerificationAlert, setShowEmailVerificationAlert] = useState(false);
  const [unverifiedEmail, setUnverifiedEmail] = useState("");
  const isMobile = window.innerWidth <= 1024;
  const returnUrl = location.state?.returnUrl || "/";

  useEffect(() => {
    if (user) {
      navigate(returnUrl);
    }
  }, [user, navigate, returnUrl]);

  const handleResendVerification = async () => {
    if (!unverifiedEmail) return;
    
    setLoading(true);
    try {
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email: unverifiedEmail,
      });
      
      if (error) throw error;
      
      toast({
        title: "Verification Email Sent",
        description: "We've sent you a new verification email. Please check your inbox.",
        duration: 5000,
      });
    } catch (error) {
      console.error("Error resending verification email:", error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to resend verification email. Please try again later.",
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setShowEmailVerificationAlert(false);

    try {
      const { error: emailError, data: emailData } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (emailError) {
        console.log("Email login failed");
        
        if (emailError.message.includes("Email not confirmed")) {
          setUnverifiedEmail(email);
          setShowEmailVerificationAlert(true);
          throw new Error("Please verify your email before signing in. Check your inbox for a verification link.");
        }
        
        throw emailError;
      }

      navigate("/");
    } catch (error) {
      setError((error as AuthError).message);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!privacyConsent) {
      setSignupError("Please accept the privacy policy to continue.");
      return;
    }
    
    setLoading(true);
    setSignupError(null);

    try {
      const { data, error } = await supabase.auth.signUp({
        email: signupEmail,
        password: signupPassword,
        options: {
          emailRedirectTo: `${window.location.origin}/auth`,
          data: {
            username: signupEmail.split('@')[0],
          }
        }
      });

      if (error) throw error;

      if (data.user) {
        if (data.user.identities && data.user.identities.length === 0) {
          setSignupError("This email is already registered. Please sign in instead.");
          return;
        }
        
        setUnverifiedEmail(signupEmail);
        setShowEmailVerificationAlert(true);
        
        if (data.user.id) {
          await supabase.from('profiles').upsert({
            id: data.user.id,
            email: signupEmail,
            username: signupEmail.split('@')[0]
          });
        }
        
        toast({
          title: "Verification Email Sent! 📧",
          description: "Please check your email to verify your account before signing in.",
          duration: 8000,
        });
        
        return;
      }
    } catch (error: any) {
      console.error("Signup error:", error);
      if (error.message.includes("already registered")) {
        setSignupError("This email is already registered. Please sign in instead.");
      } else {
        setSignupError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const verifySignUpOtp = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const { error } = await supabase.auth.verifyOtp({
        email: signupEmail,
        token: signupOtp,
        type: 'signup'
      });

      if (error) throw error;

      const { error: signInError } = await supabase.auth.signInWithPassword({
        email: signupEmail,
        password: signupPassword,
      });

      if (signInError) throw signInError;

      toast({
        title: "Email Verified! 🎉",
        description: "Your account has been verified and you're now logged in.",
        duration: 6000,
      });

      navigate("/");
    } catch (error: any) {
      setError("Invalid or expired verification code. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setForgotPasswordError(null);

    try {
      if (!forgotPasswordEmail || !forgotPasswordEmail.trim()) {
        throw new Error("Please enter your email address");
      }

      const { error } = await supabase.auth.resetPasswordForEmail(
        forgotPasswordEmail.trim(),
        {
          redirectTo: `${window.location.origin}/auth`,
        }
      );

      if (error) throw error;

      toast({
        title: "Reset Link Sent! 📧",
        description: "Check your email for the magic link.",
        duration: 8000,
      });
      
      setForgotPasswordError(
        "We've sent you an email with a secure magic link. Click the link in your email, then head to your Profile page to set a new password. The link will expire in 24 hours for security."
      );
    } catch (error: any) {
      console.error('Password reset error:', error);
      let errorMessage = "Failed to send reset link. Please try again.";
      if (error?.message?.includes("not found")) {
        errorMessage = "This email is not registered. Please check the email address.";
      } else if (error?.message?.includes("too many requests")) {
        errorMessage = "Too many reset attempts. Please try again later.";
      }
      setForgotPasswordError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const verifyOtpAndResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const { error } = await supabase.auth.verifyOtp({
        email: forgotPasswordEmail,
        token: otp,
        type: 'recovery'
      });

      if (error) throw error;

      setResetPasswordStep("password");
    } catch (error: any) {
      setError("Invalid or expired code. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;

      toast({
        title: "Password Updated Successfully",
        description: "You can now sign in with your new password.",
      });

      setShowForgotPassword(false);
      setResetPasswordStep("email");
      setOtp("");
      setNewPassword("");
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const fullPhoneNumber = `${countryCode}${phone}`;
      
      if (!otpSent) {
        const { error } = await supabase.auth.signInWithOtp({
          phone: fullPhoneNumber,
        });

        if (error) {
          if (error.message.includes("security purposes")) {
            throw new Error("Please wait a few seconds before requesting another code.");
          }
          throw error;
        }
        
        setOtpSent(true);
        toast({
          title: "Code Sent! 📱",
          description: "Check your phone for the OTP code.",
        });
      } else {
        const { error, data } = await supabase.auth.verifyOtp({
          phone: fullPhoneNumber,
          token: otp,
          type: 'sms',
        });
        
        if (error) throw error;
        
        if (data.user) {
          await updateProfile(data.user.id, fullPhoneNumber);
        }
        
        navigate("/");
      }
    } catch (error: any) {
      setError(error.message);
      if (!error.message.includes("security purposes")) {
        setOtpSent(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const updateProfile = async (userId: string, fullPhoneNumber: string) => {
    const { error } = await supabase
      .from('profiles')
      .upsert({
        id: userId,
        phone: fullPhoneNumber,
      });
    
    if (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}/auth`,
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
        },
      });
      
      if (error) throw error;
      
      if (data?.url) {
        window.location.href = data.url;
      }
    } catch (error) {
      setError((error as AuthError).message);
    }
  };

  const renderForgotPasswordContent = () => {
    return (
      <form onSubmit={handleForgotPassword} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="forgot-email">Email</Label>
          <Input
            id="forgot-email"
            type="email"
            value={forgotPasswordEmail}
            onChange={(e) => setForgotPasswordEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>
        {error && (
          <Alert variant={error.includes("sent you") ? "default" : "destructive"} className="mt-4">
            <Info className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <div className="flex justify-end">
          <Button type="submit" disabled={loading}>
            {loading ? "Sending..." : "Send Reset Link"}
          </Button>
        </div>
      </form>
    );
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-50 to-pink-50 px-4">
      <div className="w-full max-w-md">
        <div className="bg-white p-8 rounded-lg shadow-lg relative">
          <Button
            variant="ghost"
            size="icon"
            className="absolute right-4 top-4 dark:text-black"
            onClick={() => navigate('/')}
          >
            <X className="h-4 w-4" />
          </Button>

          <div className="mb-8 text-center">
            <h2 className="text-2xl font-bold text-gray-900">Welcome Back</h2>
            <p className="text-gray-600">Sign in to your account</p>
          </div>

          {showEmailVerificationAlert && (
            <Alert className="mb-6 border-amber-500 bg-amber-50 text-amber-800">
              <div className="flex flex-col space-y-3">
                <div className="flex items-start">
                  <AlertCircle className="h-5 w-5 text-amber-600 mt-0.5 mr-2 flex-shrink-0" />
                  <div>
                    <h3 className="font-medium text-amber-800">Email verification required</h3>
                    <p className="text-sm mt-1">
                      Please check your inbox for a verification email and click the link to activate your account.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 pt-1">
                  <p className="text-xs text-amber-700">
                    Email sent to: <strong>{unverifiedEmail}</strong>
                  </p>
                  <Button 
                    variant="outline"
                    size="sm" 
                    className="border-amber-300 text-amber-700 hover:bg-amber-100 flex items-center px-2 py-1 h-7 text-xs"
                    onClick={handleResendVerification}
                    disabled={loading}
                  >
                    <RefreshCw className="h-3 w-3 mr-1" />
                    {loading ? "Sending..." : "Resend email"}
                  </Button>
                </div>
              </div>
            </Alert>
          )}

          <div className="flex justify-center space-x-4 mb-4">
            <Button
              variant={mode === "phone" ? "default" : "outline"}
              onClick={() => {
                setMode("phone");
                setError(null);
                setOtpSent(false);
                setShowEmailVerificationAlert(false);
              }}
            >
              <Phone className="w-4 h-4 mr-2" />
              Phone
            </Button>
            <Button
              variant={mode === "email" ? "default" : "outline"}
              onClick={() => {
                setMode("email");
                setError(null);
                setOtpSent(false);
                setShowEmailVerificationAlert(false);
              }}
            >
              <Mail className="w-4 h-4 mr-2" />
              Email
            </Button>
          </div>

          {error && !showEmailVerificationAlert && (
            <Alert variant={error.includes("Check") ? "default" : "destructive"}>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <Tabs defaultValue="signin" className="space-y-6">
            <TabsList className="grid grid-cols-2 w-full">
              <TabsTrigger value="signin">Sign In</TabsTrigger>
              <TabsTrigger value="signup">Sign Up</TabsTrigger>
            </TabsList>

            <div className="space-y-4">
              <div className="hidden lg:block">
                <Button
                  type="button"
                  variant="outline"
                  className="w-full flex items-center justify-center gap-2"
                  onClick={handleGoogleSignIn}
                >
                  <svg viewBox="0 0 24 24" className="w-5 h-5" aria-hidden="true">
                    <path
                      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                      fill="#4285F4"
                    />
                    <path
                      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                      fill="#EA4335"
                    />
                  </svg>
                  Continue with Google
                </Button>
              </div>

              <TabsContent value="signin">
                {mode === "phone" ? (
                  <form onSubmit={handlePhoneSignIn} className="space-y-4">
                    <div className="flex gap-2">
                      <Select defaultValue="+91" value={countryCode} onValueChange={setCountryCode}>
                        <SelectTrigger className="w-[140px]">
                          <SelectValue placeholder="Code" />
                        </SelectTrigger>
                        <SelectContent>
                          {countryCodes.map((country) => (
                            <SelectItem key={country.code} value={country.code}>
                              {country.code} ({country.country})
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <Input
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value.replace(/\D/g, ''))}
                        placeholder="Phone number"
                        required
                      />
                    </div>

                    {otpSent && (
                      <div className="space-y-2">
                        <Label>Enter OTP</Label>
                        <InputOTP
                          value={otp}
                          onChange={setOtp}
                          maxLength={6}
                        >
                          <InputOTPGroup>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                      </div>
                    )}

                    <Button type="submit" className="w-full" disabled={loading}>
                      {loading ? "Processing..." : otpSent ? "Verify OTP" : "Send OTP"}
                    </Button>
                  </form>
                ) : (
                  <form onSubmit={handleEmailSignIn} className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="dark:text-white"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="password">Password</Label>
                      <Input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="dark:text-white"
                        placeholder="Enter your password"
                      />
                    </div>

                    <div className="flex items-center justify-end">
                      <Button
                        type="button"
                        variant="link"
                        className="px-0 font-semibold text-purple-600 hover:text-purple-700"
                        onClick={() => setShowForgotPassword(true)}
                      >
                        <Key className="w-4 h-4 mr-1" />
                        Forgot Password?
                      </Button>
                    </div>

                    <Button type="submit" className="w-full" disabled={loading}>
                      {loading ? "Signing in..." : "Sign In"}
                    </Button>
                  </form>
                )}
              </TabsContent>

              <TabsContent value="signup">
                {mode === "phone" ? (
                  <form onSubmit={handlePhoneSignIn} className="space-y-4">
                    <div className="flex gap-2">
                      <Select defaultValue="+91" value={countryCode} onValueChange={setCountryCode}>
                        <SelectTrigger className="w-[140px]">
                          <SelectValue placeholder="Code" />
                        </SelectTrigger>
                        <SelectContent>
                          {countryCodes.map((country) => (
                            <SelectItem key={country.code} value={country.code}>
                              {country.code} ({country.country})
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <Input
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value.replace(/\D/g, ''))}
                        placeholder="Phone number"
                        required
                      />
                    </div>

                    {otpSent && (
                      <div className="space-y-2">
                        <Label>Enter OTP</Label>
                        <InputOTP
                          value={otp}
                          onChange={setOtp}
                          maxLength={6}
                        >
                          <InputOTPGroup>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                      </div>
                    )}

                    <div className="rounded-lg bg-gradient-to-r from-purple-100 to-pink-50 p-4 relative overflow-hidden group">
                      <div className="absolute top-0 left-0 w-2 h-full bg-purple-500"></div>
                      <div className="flex items-start space-x-3">
                        <Checkbox 
                          id="privacy-policy-phone" 
                          checked={privacyConsent}
                          onCheckedChange={(checked) => setPrivacyConsent(checked as boolean)}
                          className="mt-0.5"
                        />
                        <div className="space-y-1">
                          <div className="flex items-center">
                            <Label 
                              htmlFor="privacy-policy-phone" 
                              className="font-medium text-gray-800 cursor-pointer"
                            >
                              I understand and accept the privacy policy
                            </Label>
                            <Popover>
                              <PopoverTrigger asChild>
                                <Button 
                                  variant="ghost" 
                                  size="icon" 
                                  className="h-6 w-6 rounded-full ml-1 text-purple-600"
                                >
                                  <Shield className="h-4 w-4" />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent 
                                className="w-80 md:w-96 bg-white p-4 rounded-xl shadow-xl border border-purple-100"
                                side="top"
                                align="center"
                              >
                                <div className="space-y-3">
                                  <div className="flex items-center gap-2">
                                    <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center text-purple-600">
                                      <Shield className="h-4 w-4" />
                                    </div>
                                    <h4 className="font-bold text-gray-900">Our Privacy Commitment</h4>
                                  </div>
                                  
                                  <div className="space-y-2">
                                    <p className="text-sm text-gray-600">
                                      We're committed to protecting your privacy. By using our app, you agree to our data collection practices:
                                    </p>
                                    
                                    <div className="bg-gray-50 p-3 rounded-md space-y-2">
                                      <p className="text-sm text-gray-800 flex items-center gap-2">
                                        <Eye className="h-3.5 w-3.5 text-purple-500 flex-shrink-0" />
                                        <span>Your phone number will always be kept private and never shared with third parties</span>
                                      </p>
                                      <p className="text-sm text-gray-800 flex items-center gap-2">
                                        <span>We don't track app interactions for advertising purposes</span>
                                      </p>
                                    </div>
                                    
                                    <p className="text-sm text-gray-600">
                                      We use this minimal data only to provide and improve our services. Your information is secure and will never be sold to third parties.
                                    </p>
                                  </div>
                                </div>
                              </PopoverContent>
                            </Popover>
                          </div>
                          <p className="text-xs text-gray-500 leading-relaxed pl-0.5">
                            We protect your privacy: your phone number is kept private. We never track app interactions for advertising.
                          </p>
                        </div>
                      </div>
                    </div>

                    <Button type="submit" className="w-full mt-4" disabled={loading}>
                      {loading ? "Processing..." : otpSent ? "Verify OTP" : "Send OTP"}
                    </Button>
                  </form>
                ) : (
                  <form onSubmit={handleEmailSignUp} className="space-y-6">
                    <div className="space-y-2">
                      <Label htmlFor="signup-email">Email</Label>
                      <Input
                        id="signup-email"
                        type="email"
                        value={signupEmail}
                        onChange={(e) => setSignupEmail(e.target.value)}
                        required
                        className="dark:text-white"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="signup-password">Password</Label>
                      <Input
                        id="signup-password"
                        type="password"
                        value={signupPassword}
                        onChange={(e) => setSignupPassword(e.target.value)}
                        required
                        className="dark:text-white"
                        placeholder="Enter your password"
                        minLength={6}
                      />
                    </div>

                    <div className="space-y-4">
                      <div className="rounded-lg bg-gradient-to-r from-purple-100 to-pink-50 p-4 relative overflow-hidden group">
                        <div className="absolute top-0 left-0 w-2 h-full bg-purple-500"></div>
                        <div className="flex items-start space-x-3">
                          <Checkbox 
                            id="privacy-policy" 
                            checked={privacyConsent}
                            onCheckedChange={(checked) => setPrivacyConsent(checked as boolean)}
                            className="mt-0.5"
                          />
                          <div className="space-y-1">
                            <div className="flex items-center">
                              <Label 
                                htmlFor="privacy-policy" 
                                className="font-medium text-gray-800 cursor-pointer"
                              >
                                I understand and accept the privacy policy
                              </Label>
                              <Popover>
                                <PopoverTrigger asChild>
                                  <Button 
                                    variant="ghost" 
                                    size="icon" 
                                    className="h-6 w-6 rounded-full ml-1 text-purple-600"
                                  >
                                    <Shield className="h-4 w-4" />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent 
                                  className="w-80 md:w-96 bg-white p-4 rounded-xl shadow-xl border border-purple-100"
                                  side="top"
                                  align="center"
                                >
                                  <div className="space-y-3">
                                    <div className="flex items-center gap-2">
                                      <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center text-purple-600">
                                        <Shield className="h-4 w-4" />
                                      </div>
                                      <h4 className="font-bold text-gray-900">Our Privacy Commitment</h4>
                                    </div>
                                    
                                    <div className="space-y-2">
                                      <p className="text-sm text-gray-600">
                                        We're committed to protecting your privacy. By using our app, you agree to our data collection practices:
                                      </p>
                                      
                                      <div className="bg-gray-50 p-3 rounded-md space-y-2">
                                        <p className="text-sm text-gray-800 flex items-center gap-2">
                                          <Eye className="h-3.5 w-3.5 text-purple-500 flex-shrink-0" />
                                          <span>Your email address will always be kept private and never shared with third parties</span>
                                        </p>
                                        <p className="text-sm text-gray-800 flex items-center gap-2">
                                          <span>We don't track app interactions for advertising purposes</span>
                                        </p>
                                      </div>
                                      
                                      <p className="text-sm text-gray-600">
                                        We use this minimal data only to provide and improve our services. Your information is secure and will never be sold to third parties.
                                      </p>
                                    </div>
                                  </div>
                                </PopoverContent>
                              </Popover>
                            </div>
                            <p className="text-xs text-gray-500 leading-relaxed pl-0.5">
                              We protect your privacy: emails are always kept private. We never track app interactions for advertising.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {signupError && (
                      <Alert variant={signupError.includes("sent you") ? "default" : "destructive"} className="my-4">
                        <AlertDescription>{signupError}</AlertDescription>
                      </Alert>
                    )}

                    <Button type="submit" className="w-full mt-6" disabled={loading}>
                      {loading ? "Creating account..." : "Sign Up"}
                    </Button>
                  </form>
                )}
              </TabsContent>
            </div>
          </Tabs>

          <Dialog open={showForgotPassword} onOpenChange={setShowForgotPassword}>
            <DialogContent className="sm:max-w-md">
              <DialogHeader>
                <DialogTitle>Reset your password</DialogTitle>
                <DialogDescription>
                  Enter your email address and we'll send you a link to reset your password.
                </DialogDescription>
              </DialogHeader>
              {renderForgotPasswordContent()}
              {forgotPasswordError && (
                <Alert variant={forgotPasswordError.includes("We've sent you") ? "default" : "destructive"}>
                  <AlertDescription>{forgotPasswordError}</AlertDescription>
                </Alert>
              )}
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
