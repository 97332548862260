
import { useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronRight, Sparkle } from "lucide-react";
import { ChatMessage } from "./ChatMessage";
import { TypingIndicator } from "./TypingIndicator";
import { Skeleton } from "../ui/skeleton";

interface MessageListProps {
  messages: any[];
  category: string;
  suggestedPrompts: Record<string, string[]>;
  onSendMessage: (message: string) => void;
  messagesEndRef: React.RefObject<HTMLDivElement>;
  isLoading?: boolean;
}

export const MessageList = ({
  messages,
  category,
  suggestedPrompts,
  onSendMessage,
  messagesEndRef,
  isLoading = false,
}: MessageListProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  
  // Capitalize the first letter of the category
  const capitalizedCategory = category.charAt(0).toUpperCase() + category.slice(1);

  useEffect(() => {
    // Scroll to bottom when messages change or loading state changes
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, isLoading, messagesEndRef]);

  return (
    <div 
      ref={containerRef}
      className="flex-1 overflow-y-auto p-4 space-y-4 bg-gradient-to-b from-gray-50 to-white"
    >
      {messages.length === 0 && (
        <div className="space-y-4 py-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center"
          >
            <div className="w-16 h-16 rounded-full bg-gradient-to-br from-purple-500 to-blue-500 flex items-center justify-center text-white mx-auto mb-4 shadow-lg">
              <Sparkle className="w-8 h-8" />
            </div>
            <h3 className="text-xl font-semibold mb-2 bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
              Ask Your {capitalizedCategory} Coach
            </h3>
            <p className="text-sm text-gray-500 max-w-md mx-auto mb-6">
              Select a suggested prompt or type your own message to get personalized coaching
            </p>
          </motion.div>
          
          <div className="grid gap-2 max-w-md mx-auto">
            {suggestedPrompts[category.toLowerCase()]?.map((prompt, index) => (
              <motion.button
                key={index}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                onClick={() => onSendMessage(prompt)}
                className="p-3 text-left text-sm bg-white hover:bg-gray-50 border border-gray-100 rounded-xl flex items-center gap-2 transition-all duration-200 shadow-sm hover:shadow-md group"
              >
                <span className="flex-1">{prompt}</span>
                <span className="w-6 h-6 rounded-full bg-purple-100 flex items-center justify-center text-purple-500 group-hover:bg-purple-500 group-hover:text-white transition-colors">
                  <ChevronRight className="w-4 h-4" />
                </span>
              </motion.button>
            ))}
          </div>
        </div>
      )}
      
      <AnimatePresence mode="popLayout">
        {messages.map((msg) => (
          <ChatMessage
            key={msg.id}
            content={msg.content}
            role={msg.role}
            timestamp={msg.created_at}
          />
        ))}
        
        {isLoading && (
          <TypingIndicator />
        )}
      </AnimatePresence>
      
      <div ref={messagesEndRef} />
    </div>
  );
};
