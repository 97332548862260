import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { ArrowLeft, ExternalLink } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

const ProductDemo = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const navigate = useNavigate();

  // Updated to the new YouTube Shorts link
  const videoUrl = "https://www.youtube.com/embed/CXukARpYZe4?autoplay=1&mute=1";
  
  const features = [
    {
      title: "Discover Venues",
      gradient: "from-pink-500/30 to-orange-400/30",
      borderGradient: "from-pink-400 to-orange-300"
    },
    {
      title: "Discover Coaches",
      gradient: "from-blue-500/30 to-purple-400/30",
      borderGradient: "from-blue-400 to-purple-300"
    },
    {
      title: "Join Academies",
      gradient: "from-green-500/30 to-teal-400/30",
      borderGradient: "from-green-400 to-teal-300"
    },
    {
      title: "Stay Fit",
      gradient: "from-amber-500/30 to-yellow-400/30", 
      borderGradient: "from-amber-400 to-yellow-300"
    },
    {
      title: "Communities",
      gradient: "from-indigo-500/30 to-purple-400/30",
      borderGradient: "from-indigo-400 to-purple-300"
    }
  ];

  const handleBack = () => {
    navigate('/');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentSection < features.length - 1) {
        setCurrentSection(prev => prev + 1);
      } else {
        setCurrentSection(0);
      }
    }, 1500); // 1.5 seconds per feature

    return () => clearTimeout(timer);
  }, [currentSection, features.length]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-violet-800 text-white">
      <div className="absolute inset-0 opacity-20">
        <div className="absolute inset-0 bg-[url('/lovable-uploads/7492ee19-44a3-4ad8-a0f2-2342c4614cf6.png')] bg-cover bg-center mix-blend-overlay"></div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 md:pt-32 relative z-10">
        <Button 
          variant="ghost" 
          className="absolute left-4 top-4 text-white hover:bg-white/10 mb-8"
          onClick={handleBack}
        >
          <ArrowLeft className="mr-2 h-4 w-4" /> Back
        </Button>

        <div className="flex flex-col items-center justify-center pt-16 md:pt-16">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-12"
          >
            <h1 className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-pink-400 via-purple-300 to-indigo-400 bg-clip-text text-transparent mb-4">
              Experience beSportly
            </h1>
            <p className="text-lg text-gray-300 max-w-2xl mx-auto">
              We're building for a playful, healthier and more connected India.
            </p>
          </motion.div>

          {/* Feature boxes - only visible on desktop */}
          <div className="w-full max-w-5xl mx-auto hidden md:grid md:grid-cols-5 gap-6 mb-12">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 + 0.3 }}
              >
                <div 
                  className={`
                    relative rounded-xl p-4 h-28 flex items-center justify-center text-center transition-all duration-300
                    ${currentSection === index 
                      ? `bg-gradient-to-br ${feature.gradient} scale-110 shadow-xl z-10 backdrop-blur-sm border border-gradient-to-r ${feature.borderGradient}` 
                      : 'bg-white/5 backdrop-blur-sm opacity-70 scale-100'}
                  `}
                >
                  <span className={`text-lg font-semibold ${currentSection === index ? "text-white" : "text-gray-300"}`}>
                    {feature.title}
                  </span>
                  
                  {currentSection === index && (
                    <motion.div
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      className="absolute -inset-0.5 rounded-xl z-[-1] bg-gradient-to-r opacity-70 blur-sm"
                      style={{ 
                        backgroundImage: `linear-gradient(to right, var(--tw-gradient-stops))`,
                        backgroundSize: '200% 200%',
                        animation: 'gradient-shift 2s ease infinite' 
                      }}
                    />
                  )}
                </div>
              </motion.div>
            ))}
          </div>

          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
            className="w-full max-w-md mx-auto aspect-[9/16] rounded-2xl overflow-hidden shadow-2xl shadow-purple-900/50 border border-purple-700/30 mb-10"
          >
            <div className="relative w-full h-full">
              <iframe 
                src={videoUrl}
                title="beSportly Demo Video"
                className="w-full h-full"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </motion.div>

          <div className="flex flex-col md:flex-row gap-6 items-center justify-center mt-6 mb-16">
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="flex"
            >
              <a 
                href="https://apps.apple.com/app/besportly/id6742348742"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-3 px-6 py-3 bg-gradient-to-br from-white/10 to-white/5 backdrop-blur-md rounded-xl border border-white/20 hover:border-white/30 transition-all group"
              >
                <div className="h-8 w-8 rounded-full bg-gradient-to-br from-gray-200 to-white flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" className="h-4 w-4 fill-black">
                    <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                  </svg>
                </div>
                <div className="text-left">
                  <p className="text-xs text-gray-300">Download on the</p>
                  <p className="text-white font-semibold">App Store</p>
                </div>
                <ExternalLink className="h-4 w-4 text-white/50 group-hover:text-white/80 transition-colors ml-1" />
              </a>
            </motion.div>
            
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="flex"
            >
              <a 
                href="https://play.google.com/store/apps/details?id=branded.m6818cd43ae2241278977ff65ceb6cf40.besportly"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-3 px-6 py-3 bg-gradient-to-br from-white/10 to-white/5 backdrop-blur-md rounded-xl border border-white/20 hover:border-white/30 transition-all group"
              >
                <img 
                  src="/lovable-uploads/5da71b37-3e02-4bf1-8252-4fd41ed118bf.png" 
                  alt="Play Store"
                  className="h-8 w-auto" 
                />
                <div className="text-left">
                  <p className="text-xs text-gray-300">Get it on</p>
                  <p className="text-white font-semibold">Google Play</p>
                </div>
                <ExternalLink className="h-4 w-4 text-white/50 group-hover:text-white/80 transition-colors ml-1" />
              </a>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDemo;
