import { motion } from "framer-motion";

const AlsoByUs = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 py-32">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#ff00ff] to-[#00bfff] mb-6">
            Also by beSportly
          </h1>
          <p className="text-xl text-gray-600">
            Discover our other innovative platforms
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="bg-white rounded-2xl shadow-xl overflow-hidden"
        >
          <div className="p-8">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-3xl font-bold text-gray-900">Classpace</h2>
              <a
                href="https://classpace.in"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#ff00ff] hover:text-[#00bfff] transition-colors"
              >
                Visit classpace.in →
              </a>
            </div>
            
            <p className="text-lg text-gray-700 mb-6">
              An end-to-end platform revolutionizing how people discover and engage with classes 
              across various lifestyle disciplines and professions.
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-gray-50 rounded-xl p-6">
                <h3 className="text-xl font-semibold mb-3">For Learners</h3>
                <ul className="space-y-2 text-gray-600">
                  <li>• Search and discover classes</li>
                  <li>• Book sessions seamlessly</li>
                  <li>• Track your learning journey</li>
                  <li>• Connect with expert instructors</li>
                </ul>
              </div>

              <div className="bg-gray-50 rounded-xl p-6">
                <h3 className="text-xl font-semibold mb-3">Categories</h3>
                <ul className="space-y-2 text-gray-600">
                  <li>• Yoga and Wellness</li>
                  <li>• Professional Skills</li>
                  <li>• Arts and Crafts</li>
                  <li>• Music and Dance</li>
                </ul>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AlsoByUs;