
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

const CoachImageUpdater = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [results, setResults] = useState<any>(null);
  const navigate = useNavigate();

  const updateCoachImages = async () => {
    setIsUpdating(true);
    try {
      // First attempt: Call the edge function to update the images
      const { data: funcData, error: funcError } = await supabase.functions.invoke('update-coach-images');
      
      if (funcError) {
        console.error("Error calling edge function:", funcError);
        // Fallback to updating directly if function fails
        await updateImagesDirectly();
      } else {
        setResults(funcData.results);
        toast.success("Coach images updated successfully!");
      }
    } catch (error) {
      console.error("Error updating coach images:", error);
      await updateImagesDirectly();
    } finally {
      setIsUpdating(false);
    }
  };

  const updateImagesDirectly = async () => {
    const coachImages = [
      {
        bio_contains: 'Meghalaya',
        name: 'Raghav Kapur',
        image_url: '/lovable-uploads/43af30a5-f215-450d-b49a-2833b8e2a254.png'
      },
      {
        bio_contains: 'side-arm',
        name: 'Kaptaan Varma',
        image_url: '/lovable-uploads/d8557430-9463-43e7-b1d4-0e18f4a2b9da.png'
      },
      {
        bio_contains: 'bowling',
        name: 'Sukhveer Singh',
        image_url: '/lovable-uploads/3bf8a645-44fe-47a5-a7ce-105b04e83ee2.png'
      }
    ];

    const results = [];

    for (const coach of coachImages) {
      try {
        // First find the coach by the bio content
        const { data: coachData } = await supabase
          .from('coaches')
          .select('id, bio')
          .ilike('bio', `%${coach.bio_contains}%`);
          
        if (coachData && coachData.length > 0) {
          // Then update the coach with the image URL
          const { data, error } = await supabase
            .from('coaches')
            .update({ profile_image: coach.image_url })
            .eq('id', coachData[0].id)
            .select('id, bio, profile_image');
            
          results.push({
            coach: coach.name,
            data,
            error: error ? error.message : null,
            success: !error
          });
        } else {
          results.push({
            coach: coach.name,
            error: "Coach not found",
            success: false
          });
        }
      } catch (error) {
        results.push({
          coach: coach.name,
          error: error.message,
          success: false
        });
      }
    }

    setResults(results);
    
    // Check if at least one coach was updated successfully
    const anySuccess = results.some(r => r.success);
    if (anySuccess) {
      toast.success("Coach images updated successfully!");
    } else {
      toast.error("Failed to update coach images");
    }
  };

  return (
    <div className="min-h-screen bg-white pt-24 pb-16">
      <div className="max-w-3xl mx-auto px-4">
        <Card>
          <CardHeader>
            <CardTitle>Coach Image Updater</CardTitle>
            <CardDescription>
              This utility will update coach profile images in the database
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <Button
              onClick={updateCoachImages}
              disabled={isUpdating}
              className="w-full"
            >
              {isUpdating ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Updating Coach Images...
                </>
              ) : "Update Coach Images"}
            </Button>

            {results && (
              <div className="mt-6 space-y-4">
                <h3 className="text-lg font-medium">Results:</h3>
                <pre className="p-4 bg-gray-50 rounded-md text-sm overflow-auto max-h-80">
                  {JSON.stringify(results, null, 2)}
                </pre>
                <Button onClick={() => navigate('/coaches')} className="mt-4">
                  Go to Coaches Page
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CoachImageUpdater;
