
import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { toast } from "sonner";
import { Loader2, PlusCircle, MinusCircle, Upload } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/components/AuthProvider";
import { useNavigate } from "react-router-dom";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

const sportCategories = [
  "Cricket",
  "Football",
  "Basketball",
  "Tennis",
  "Badminton",
  "Pickleball",
  "Swimming",
  "Gym",
  "Yoga",
  "Table Tennis",
  "Squash",
  "Chess",
  "Carrom",
  "Boxing",
  "Martial Arts",
  "Athletics"
];

const popularCities = [
  "Gurgaon",
  "Mumbai",
  "Delhi",
  "Bangalore",
  "Hyderabad",
  "Chennai",
  "Kolkata",
  "Pune",
  "Ahmedabad",
  "Jaipur",
  "Lucknow",
  "Chandigarh",
  "Indore",
];

const formSchema = z.object({
  bio: z.string().min(20, { message: "Bio must be at least 20 characters" }).max(500, { message: "Bio must not exceed 500 characters" }),
  experience_years: z.string().transform(val => parseInt(val, 10)),
  hourly_rate: z.string().transform(val => parseFloat(val)),
  city: z.string().min(1, { message: "Please select your city" }),
  phone: z.string().min(10, { message: "Please provide a valid phone number" }),
});

type FormValues = z.input<typeof formSchema>;

const CoachRegistrationForm = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [specializations, setSpecializations] = useState<string[]>([""]);
  const [certifications, setCertifications] = useState<string[]>([""]);
  const [locations, setLocations] = useState<string[]>([""]);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      bio: "",
      experience_years: "1",
      hourly_rate: "500",
      city: "Gurgaon",
      phone: "",
    },
  });

  const handleSpecializationChange = (index: number, value: string) => {
    const newSpecializations = [...specializations];
    newSpecializations[index] = value;
    setSpecializations(newSpecializations);
  };

  const handleCertificationChange = (index: number, value: string) => {
    const newCertifications = [...certifications];
    newCertifications[index] = value;
    setCertifications(newCertifications);
  };

  const handleLocationChange = (index: number, value: string) => {
    const newLocations = [...locations];
    newLocations[index] = value;
    setLocations(newLocations);
  };

  const addSpecialization = () => {
    setSpecializations([...specializations, ""]);
  };

  const removeSpecialization = (index: number) => {
    if (specializations.length > 1) {
      const newSpecializations = [...specializations];
      newSpecializations.splice(index, 1);
      setSpecializations(newSpecializations);
    }
  };

  const addCertification = () => {
    setCertifications([...certifications, ""]);
  };

  const removeCertification = (index: number) => {
    if (certifications.length > 1) {
      const newCertifications = [...certifications];
      newCertifications.splice(index, 1);
      setCertifications(newCertifications);
    }
  };

  const addLocation = () => {
    setLocations([...locations, ""]);
  };

  const removeLocation = (index: number) => {
    if (locations.length > 1) {
      const newLocations = [...locations];
      newLocations.splice(index, 1);
      setLocations(newLocations);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setProfileImage(file);
      
      // Create a preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const uploadImage = async (file: File): Promise<string | null> => {
    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random().toString(36).substring(2, 15)}.${fileExt}`;
      const filePath = `coach-profiles/${fileName}`;
      
      const { error: uploadError } = await supabase.storage
        .from('profiles')
        .upload(filePath, file);
      
      if (uploadError) {
        console.error("Error uploading image:", uploadError);
        throw uploadError;
      }
      
      const { data } = supabase.storage
        .from('profiles')
        .getPublicUrl(filePath);
        
      return data.publicUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  const onSubmit = async (values: FormValues) => {
    console.log("Form submitted with values:", values);
    
    if (!user) {
      toast.error("You must be logged in to register as a coach");
      return;
    }

    const filteredSpecializations = specializations.filter(spec => spec.trim() !== "");
    const filteredCertifications = certifications.filter(cert => cert.trim() !== "");
    const filteredLocations = locations.filter(loc => loc.trim() !== "");

    if (filteredSpecializations.length === 0) {
      toast.error("Please add at least one specialization");
      return;
    }

    setIsSubmitting(true);

    try {
      let profileImageUrl = null;
      if (profileImage) {
        toast.info("Uploading profile image...");
        profileImageUrl = await uploadImage(profileImage);
        if (!profileImageUrl) {
          toast.error("Failed to upload profile image");
          // Continue without image if upload fails
        }
      }

      console.log("Creating coach profile with data:", {
        profile_id: user.id,
        bio: values.bio,
        experience_years: values.experience_years,
        hourly_rate: values.hourly_rate,
        city: values.city,
        phone: values.phone,
        specialization: filteredSpecializations,
        certifications: filteredCertifications,
        coaching_locations: filteredLocations,
        profile_image: profileImageUrl
      });

      const { data, error } = await supabase
        .from('coaches')
        .insert({
          profile_id: user.id,
          bio: values.bio,
          experience_years: parseInt(values.experience_years, 10),
          hourly_rate: parseFloat(values.hourly_rate),
          city: values.city,
          phone: values.phone,
          specialization: filteredSpecializations,
          certifications: filteredCertifications,
          coaching_locations: filteredLocations,
          profile_image: profileImageUrl
        })
        .select('id')
        .single();

      if (error) {
        console.error("Error creating coach profile:", error);
        throw error;
      }

      console.log("Coach profile created successfully:", data);
      toast.success("Coach profile created successfully!");
      navigate("/coach/dashboard");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to create coach profile. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className="flex flex-col items-center mb-6">
          <div 
            className="mb-4 relative cursor-pointer group"
            onClick={triggerFileInput}
          >
            <Avatar className="w-24 h-24 border-2 border-gray-200">
              <AvatarImage src={imagePreview || undefined} />
              <AvatarFallback className="bg-primary/10 text-primary text-lg">
                {user?.email?.charAt(0).toUpperCase() || 'C'}
              </AvatarFallback>
            </Avatar>
            <div className="absolute inset-0 bg-black/30 rounded-full flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
              <Upload className="h-8 w-8 text-white" />
            </div>
          </div>
          <input 
            type="file" 
            ref={fileInputRef}
            onChange={handleImageChange}
            accept="image/*"
            className="hidden"
          />
          <p className="text-sm text-gray-500">Click to upload profile image</p>
        </div>

        <FormField
          control={form.control}
          name="bio"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Coach Bio</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Tell us about your coaching experience, philosophy, and achievements..."
                  className="resize-none min-h-[150px]"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                This will be displayed on your public profile
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="space-y-4">
          <FormLabel>Specializations</FormLabel>
          <FormDescription>
            Select the sports or activities you specialize in coaching
          </FormDescription>
          
          {specializations.map((specialization, index) => (
            <div key={index} className="flex gap-2">
              <Select
                value={specialization}
                onValueChange={(value) => handleSpecializationChange(index, value)}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a sport" />
                </SelectTrigger>
                <SelectContent>
                  {sportCategories.map((sport) => (
                    <SelectItem key={sport} value={sport}>
                      {sport}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              
              <Button
                type="button"
                variant="outline"
                size="icon"
                onClick={() => removeSpecialization(index)}
                disabled={specializations.length <= 1}
              >
                <MinusCircle className="h-4 w-4" />
              </Button>
            </div>
          ))}
          
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={addSpecialization}
            className="mt-2"
          >
            <PlusCircle className="h-4 w-4 mr-2" />
            Add Specialization
          </Button>
        </div>

        <div className="space-y-4">
          <FormLabel>Certifications (Optional)</FormLabel>
          <FormDescription>
            List any certifications or qualifications you have
          </FormDescription>
          
          {certifications.map((certification, index) => (
            <div key={index} className="flex gap-2">
              <Input
                value={certification}
                onChange={(e) => handleCertificationChange(index, e.target.value)}
                placeholder="e.g., Level 1 Cricket Coach, FIFA Certified"
              />
              
              <Button
                type="button"
                variant="outline"
                size="icon"
                onClick={() => removeCertification(index)}
                disabled={certifications.length <= 1}
              >
                <MinusCircle className="h-4 w-4" />
              </Button>
            </div>
          ))}
          
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={addCertification}
            className="mt-2"
          >
            <PlusCircle className="h-4 w-4 mr-2" />
            Add Certification
          </Button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormField
            control={form.control}
            name="experience_years"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Years of Experience</FormLabel>
                <FormControl>
                  <Input type="number" min="1" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="hourly_rate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Hourly Rate (₹)</FormLabel>
                <FormControl>
                  <Input type="number" min="100" step="100" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem>
                <FormLabel>City</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select your city" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {popularCities.map((city) => (
                      <SelectItem key={city} value={city}>
                        {city}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Phone Number</FormLabel>
                <FormControl>
                  <Input placeholder="+91 98765 43210" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="space-y-4">
          <FormLabel>Coaching Locations</FormLabel>
          <FormDescription>
            Enter locations where you provide coaching services
          </FormDescription>
          
          {locations.map((location, index) => (
            <div key={index} className="flex gap-2">
              <Input
                value={location}
                onChange={(e) => handleLocationChange(index, e.target.value)}
                placeholder="e.g., DLF Cricket Academy, Sector 53"
              />
              
              <Button
                type="button"
                variant="outline"
                size="icon"
                onClick={() => removeLocation(index)}
                disabled={locations.length <= 1}
              >
                <MinusCircle className="h-4 w-4" />
              </Button>
            </div>
          ))}
          
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={addLocation}
            className="mt-2"
          >
            <PlusCircle className="h-4 w-4 mr-2" />
            Add Location
          </Button>
        </div>

        <Button type="submit" className="w-full" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Creating Profile...
            </>
          ) : (
            "Create Coach Profile"
          )}
        </Button>
      </form>
    </Form>
  );
};

export default CoachRegistrationForm;
