
import { useState } from "react";
import { Send, Loader2 } from "lucide-react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { motion } from "framer-motion";

interface ChatInputProps {
  message: string;
  isLoading: boolean;
  onMessageChange: (message: string) => void;
  onSend: () => void;
}

export const ChatInput = ({
  message,
  isLoading,
  onMessageChange,
  onSend,
}: ChatInputProps) => {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && message.trim()) {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <motion.div 
      className="p-4 border-t backdrop-blur-sm bg-white/80"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
    >
      <div className="flex gap-2 relative">
        <Input
          value={message}
          onChange={(e) => onMessageChange(e.target.value)}
          placeholder="Type your message..."
          onKeyDown={handleKeyPress}
          disabled={isLoading}
          className="flex-1 shadow-sm focus-visible:ring-purple-500 rounded-full py-6 pr-10 bg-white"
        />
        <Button 
          onClick={onSend}
          disabled={isLoading || !message.trim()}
          className={`rounded-full w-12 h-12 shadow-md transition-all duration-300 ${
            isLoading 
              ? "bg-purple-300" 
              : "bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600"
          }`}
        >
          {isLoading ? (
            <Loader2 className="w-5 h-5 animate-spin text-white" />
          ) : (
            <Send className="w-5 h-5 text-white" />
          )}
        </Button>
      </div>
    </motion.div>
  );
};
