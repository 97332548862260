import { ArrowRight, MapPin, Clock, Users } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/hooks/use-mobile";
import { motion } from "framer-motion";

const FeaturedVenues = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  
  const venues = [
    {
      id: "a5fbab67-5516-4577-8440-cde9f6139b47",
      name: "Sportslane",
      category: "Coming Soon",
      address: "Grand Arch, Behind Ireo Campus, Sector 58",
      city: "Gurgaon",
      opening_time: "6:00 AM",
      closing_time: "10:00 PM",
      max_capacity: 100,
      facilities: ["Badminton", "Pickleball Courts (1-2)", "Tennis Courts (1-2)"],
      rating: 4.9,
      price_per_hour: 208,
      images: ["/lovable-uploads/3be8a4e6-6b93-4939-a954-a79bbd3aadd5.png"],
      is_bookable: true
    },
    {
      id: 2,
      name: "Ultrex",
      category: "External",
      address: "Sector 50",
      city: "Gurgaon",
      opening_time: "6:00 AM",
      closing_time: "11:00 PM",
      max_capacity: 80,
      facilities: ["Badminton", "Gym"],
      rating: 4.7,
      price_per_hour: 550,
      images: ["/lovable-uploads/44ddd521-9abd-4fef-80d9-a3ffaea014fc.png"],
      is_bookable: false
    },
    {
      id: 3,
      name: "AV Sports Academy",
      category: "External",
      address: "Sector 51",
      city: "Gurgaon",
      opening_time: "5:00 AM",
      closing_time: "10:00 PM",
      max_capacity: 120,
      facilities: ["Tennis", "Swimming"],
      rating: 4.7,
      price_per_hour: 500,
      images: ["/lovable-uploads/75c62068-60df-4a97-9504-1b06acc35f18.png"],
      is_bookable: false
    }
  ];

  const handleNavigate = () => {
    navigate('/venues');
  };

  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-indigo-600 bg-clip-text text-transparent animate-gradient px-1 py-1 rounded-lg relative">
            Featured Venues
            <div className="h-1 w-full rounded-full bg-gradient-to-r from-purple-500 via-pink-400 to-indigo-500 opacity-70 absolute bottom-0 left-0"></div>
          </h2>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleNavigate}
            className="flex items-center space-x-2 px-4 py-2 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 text-white shadow-lg hover:shadow-xl transition-all duration-300 group cursor-pointer"
          >
            <span className="font-medium">View more</span>
            <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
          </motion.button>
        </div>
        
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-br from-[#accbee] to-[#e7f0fd] opacity-20"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {venues.map((venue, index) => (
              <motion.div
                key={venue.id}
                initial={isMobile ? { opacity: 0, y: 20 } : false}
                animate={isMobile ? { opacity: 1, y: 0 } : {}}
                transition={{ delay: index * 0.2 }}
                className="group bg-white rounded-xl overflow-hidden border border-gray-100 hover:shadow-lg transition-all duration-300 animate-fadeIn"
              >
                <div className="relative h-48 overflow-hidden">
                  <img
                    src={venue.images[0]}
                    alt={venue.name}
                    className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                  />
                  <div className="absolute top-4 left-4">
                    {venue.name === "Sportslane" ? (
                      <Button 
                        size="sm"
                        className="bg-primary text-white hover:bg-primary/90"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/book-venue/${venue.id}`);
                        }}
                      >
                        Book Now
                      </Button>
                    ) : (
                      <span className="px-3 py-1 bg-primary text-white backdrop-blur-sm rounded-full text-sm font-medium">
                        {venue.category}
                      </span>
                    )}
                  </div>
                </div>

                <div className="p-6 space-y-4">
                  <h3 className="text-xl font-semibold text-gray-900">{venue.name}</h3>
                  
                  <div className="flex items-center text-gray-600">
                    <MapPin size={16} className="mr-2" />
                    <span>{venue.address}, {venue.city}</span>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Clock size={16} className="mr-2" />
                      <span className="text-gray-600">{venue.opening_time} - {venue.closing_time}</span>
                    </div>
                    <div className="flex items-center">
                      <Users size={16} className="mr-2" />
                      <span className="text-gray-600">{venue.max_capacity} people</span>
                    </div>
                  </div>

                  <div className="flex flex-wrap gap-2">
                    {venue.facilities?.map((facility, index) => (
                      <span key={index} className="text-xs bg-gray-100 dark:bg-gray-800 px-2 py-1 rounded-full text-gray-600 dark:text-white">
                        {facility}
                      </span>
                    ))}
                  </div>

                  <div className="flex justify-between items-center pt-4 border-t border-gray-100">
                    <div className="flex items-center">
                      <span className="text-yellow-400">★</span>
                      <span className="ml-1 text-gray-600 dark:text-gray-300">{venue.rating}</span>
                    </div>
                    <span className="text-primary font-semibold">₹{venue.price_per_hour}/hour</span>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedVenues;
