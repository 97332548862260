import { useState } from "react";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { Scale } from "lucide-react";

export const WeightForm = ({ onWeightAdded }: { onWeightAdded: () => void }) => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [weightData, setWeightData] = useState({
    weight: "",
    notes: "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      const { error } = await supabase.from("weight_logs").insert([
        {
          user_id: user.id,
          weight: parseFloat(weightData.weight),
          notes: weightData.notes,
        },
      ]);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Weight logged successfully!",
      });

      setWeightData({
        weight: "",
        notes: "",
      });

      onWeightAdded();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to log weight. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex items-center gap-4">
        <Scale className="w-6 h-6 text-purple-500" />
        <h3 className="text-lg font-semibold">Log Weight</h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Input
          type="number"
          step="0.1"
          placeholder="Weight (kg)"
          value={weightData.weight}
          onChange={(e) =>
            setWeightData({ ...weightData, weight: e.target.value })
          }
          required
        />
        <Input
          placeholder="Notes (optional)"
          value={weightData.notes}
          onChange={(e) => setWeightData({ ...weightData, notes: e.target.value })}
        />
      </div>
      <Button type="submit" className="w-full">
        Log Weight
      </Button>
    </form>
  );
};