
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminLogin from "@/components/admin/AdminLogin";

const Admin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if already authenticated
    const isAdmin = localStorage.getItem("adminAuthenticated") === "true";
    if (isAdmin) {
      navigate("/admin/dashboard");
    }
  }, [navigate]);

  return <AdminLogin />;
};

export default Admin;
