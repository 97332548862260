
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AlertCircle } from "lucide-react";
import { useLocation } from "react-router-dom";

interface TimeoutNotificationProps {
  timeout?: number; // Time in ms before showing the notification
  refreshTimeout?: number; // Time in ms before refreshing the page (if enabled)
  message?: string;
  isMobile?: boolean;
}

export const TimeoutNotification = ({
  timeout = 3500,
  refreshTimeout = 4000, // Changed from 6000 to 4000 ms (4 seconds)
  message = "This is taking longer than expected. We'll refresh the app for you.", // Updated message
  isMobile = false,
}: TimeoutNotificationProps) => {
  const [showNotification, setShowNotification] = useState(false);
  const location = useLocation();
  
  // Check if current path is the venue booking page
  const isVenueBookingPage = location.pathname.includes('/book-venue/');

  useEffect(() => {
    const notificationTimer = setTimeout(() => {
      setShowNotification(true);
    }, timeout);

    // Set up page refresh, but NOT on the venue booking page
    let refreshTimer: number | undefined;
    if (!isVenueBookingPage && refreshTimeout) {
      refreshTimer = window.setTimeout(() => {
        console.log("Auto-refreshing page due to long loading time");
        window.location.reload();
      }, refreshTimeout);
    }

    return () => {
      clearTimeout(notificationTimer);
      if (refreshTimer) clearTimeout(refreshTimer);
    };
  }, [timeout, refreshTimeout, isVenueBookingPage]);

  if (!showNotification) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`
        ${isMobile ? "fixed bottom-16 left-4 right-4 z-50" : "mt-4"}
        bg-gradient-to-r from-amber-500 to-amber-600
        rounded-lg shadow-lg 
        p-4 text-white
      `}
    >
      <div className="flex items-center gap-3">
        <AlertCircle className="h-5 w-5 text-white flex-shrink-0" />
        <p className="text-sm font-medium">{message}</p>
      </div>
    </motion.div>
  );
};
