
import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Info, Star } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import BackButton from '@/components/ui/back-button';

const Offers = () => {
  const navigate = useNavigate();
  
  const handleBookNow = () => {
    // Navigate to the specific Sportslane venue booking page with the correct ID
    window.location.href = 'https://besportly.com/book-venue/a5fbab67-5516-4577-8440-cde9f6139b47';
  };
  
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 md:pt-32">
        <div className="relative mb-8">
          <div className="absolute left-0 top-1 z-10">
            <BackButton />
          </div>
          
          <h1 className="text-2xl md:text-3xl font-bold bg-gradient-to-r from-purple-600 via-fuchsia-500 to-indigo-600 bg-clip-text text-transparent animate-gradient px-1 py-1 rounded-lg mb-2 pl-10 md:pl-0 inline-block md:block w-auto relative">
            Exclusive Offers
          </h1>
          <p className="text-lg text-gray-600 pl-10 md:pl-0">
            Limited time deals just for you
          </p>
        </div>
        
        {/* Featured Sportslane Offer */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-8"
        >
          <Card className="overflow-hidden border-0 shadow-lg">
            <div className="h-40 bg-gradient-to-r from-violet-600 via-fuchsia-600 to-indigo-700 relative">
              <div className="absolute inset-0 pattern-grid-lg opacity-30"></div>
              <div className="absolute inset-0 flex flex-col justify-center items-center text-white p-6">
                <motion.div 
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="text-4xl font-bold mb-2 text-center"
                >
                  60% OFF
                </motion.div>
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                  className="text-xl font-medium text-center"
                >
                  at Sportslane
                </motion.div>
              </div>
              
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
                className="absolute top-4 right-4 bg-white/20 backdrop-blur-sm rounded-full px-3 py-1 text-white text-sm"
              >
                Limited Time
              </motion.div>
            </div>
            <CardContent className="p-6">
              <div className="flex justify-between items-center mb-4">
                <div>
                  <h2 className="text-xl font-bold">Sportslane Special</h2>
                  <p className="text-muted-foreground">Applicable for Badminton Courts 5 & 6 only</p>
                </div>
                <Button 
                  onClick={handleBookNow}
                  className="bg-gradient-to-r from-violet-600 to-indigo-600 hover:from-violet-700 hover:to-indigo-700 text-white"
                >
                  Book Now
                </Button>
              </div>
              <p className="text-sm text-gray-600">
                Get an exclusive 60% discount on your next booking at Sportslane. 
                Valid only for Badminton Courts 5 & 6. Limited time offer!
              </p>
            </CardContent>
          </Card>
        </motion.div>
        
        {/* Information Card */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
          className="mb-8"
        >
          <Card className="bg-gray-50 border border-gray-100">
            <CardContent className="p-6">
              <div className="flex items-start gap-4">
                <div className="bg-blue-100 p-2 rounded-full">
                  <Info className="h-6 w-6 text-blue-600" />
                </div>
                <div>
                  <h3 className="font-medium mb-2">How to use this offer</h3>
                  <p className="text-sm text-gray-600">
                    Simply click on the "Book Now" button to go to the Sportslane venue page. Select Badminton Court 5 or 6
                    to avail the 60% discount. The offer is automatically applied during checkout.
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        </motion.div>
      </div>
    </div>
  );
};

export default Offers;
