import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function generateUsername(): string {
  const adjectives = [
    "Happy", "Swift", "Brave", "Clever", "Mighty", "Noble", "Wise", "Calm", "Bold", "Agile"
  ];
  const nouns = [
    "Runner", "Player", "Athlete", "Champion", "Warrior", "Spirit", "Hero", "Star", "Legend", "Phoenix"
  ];
  
  const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
  const randomNumber = Math.floor(Math.random() * 1000);
  
  return `${randomAdjective}${randomNoun}${randomNumber}`;
}

export function getGradientColors(index: number, isDark: boolean = false) {
  const baseColors = [
    { from: '#8B5CF6', to: '#6366F1' }, // Purple to Indigo
    { from: '#EC4899', to: '#8B5CF6' }, // Pink to Purple
    { from: '#EF4444', to: '#EC4899' }, // Red to Pink
    { from: '#F59E0B', to: '#EF4444' }, // Amber to Red
    { from: '#10B981', to: '#3B82F6' }, // Emerald to Blue
  ];
  
  const colorSet = baseColors[index % baseColors.length];
  const opacity = isDark ? '20' : '10';
  
  return {
    from: `${colorSet.from}${opacity}`,
    to: `${colorSet.to}${opacity}`,
  };
}

export function clearSupabaseData() {
  console.log("Clearing Supabase auth data from storage");
  try {
    // Get all storage keys first to avoid modification during iteration
    const localStorageKeys = [];
    const sessionStorageKeys = [];
    
    // Collect all keys from localStorage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && (key.startsWith('sb-') || key.includes('supabase'))) {
        localStorageKeys.push(key);
      }
    }
    
    // Collect all keys from sessionStorage
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key && (key.startsWith('sb-') || key.includes('supabase'))) {
        sessionStorageKeys.push(key);
      }
    }
    
    // Remove all collected keys from localStorage
    localStorageKeys.forEach(key => {
      localStorage.removeItem(key);
      console.log(`Removed ${key} from localStorage`);
    });
    
    // Remove all collected keys from sessionStorage
    sessionStorageKeys.forEach(key => {
      sessionStorage.removeItem(key);
      console.log(`Removed ${key} from sessionStorage`);
    });
    
    // Additional cleanup of any other auth-related items
    localStorage.removeItem('auth');
    localStorage.removeItem('token');
    sessionStorage.removeItem('auth');
    sessionStorage.removeItem('token');
    
    console.log("Cleared all Supabase auth data from storage");
  } catch (e) {
    console.error("Error clearing storage:", e);
  }
}

export function forcePageLoad() {
  // Force a re-render of the current page
  window.location.reload();
}

// Added new helper for navigating between pages
export function navigateTo(navigate: any, path: string) {
  // First check if we're already on the target path
  if (window.location.pathname === path) {
    // If so, just refresh the page to ensure everything loads correctly
    window.location.reload();
    return;
  }
  
  // Otherwise use the react-router navigate function
  navigate(path);
}
