import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Search, GraduationCap, MapPin, Star, Filter } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import ComingSoonBanner from "@/components/ComingSoonBanner";
import BackButton from "@/components/ui/back-button";
import { motion } from "framer-motion";
import { popularCities } from "@/utils/cityData";
import { NoResultsAnimation } from "@/components/ui/no-results-animation";
import SEO from "@/components/SEO";
import { useIsMobile } from "@/hooks/use-mobile";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { useAutoRefresh } from "@/hooks/use-auto-refresh";
import AcademyDetailsSheet from "@/components/academies/AcademyDetailsSheet";
import { Button } from "@/components/ui/button";
import { Toggle } from "@/components/ui/toggle";
import { getExternalImage } from "@/utils/venueImageUtils";

type Academy = {
  id: string;
  name: string;
  description: string | null;
  sports: string[];
  monthly_fee: number;
  programs: any | null;
  image_url: string | null;
  city: string;
  rating: number;
  total_ratings: number;
  is_external: boolean;
  is_bookable?: boolean;
  address?: string;
  state?: string;
  pincode?: string;
  phone?: string;
  website?: string;
  place_id?: string;
  maps_url?: string;
  data_source?: string;
  opening_hours?: string[];
  reviews?: any[];
  opening_time?: string;
  closing_time?: string;
};

const sportCategories = [
  "Cricket",
  "Football",
  "Basketball",
  "Tennis",
  "Badminton",
  "Swimming",
  "Yoga",
  "Table Tennis",
  "Squash",
  "Pickleball",
];

const Academies = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedPriceRanges, setSelectedPriceRanges] = useState<string[]>([]);
  const [selectedSports, setSelectedSports] = useState<string[]>([]);
  const [selectedAcademy, setSelectedAcademy] = useState<Academy | null>(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const isMobile = useIsMobile();

  const { data: academies, isLoading, error } = useQuery({
    queryKey: ["academies", searchTerm, selectedCities, selectedPriceRanges, selectedSports],
    queryFn: async () => {
      console.log("Fetching academies...");
      let query = supabase
        .from("academies")
        .select("*");

      if (searchTerm) {
        query = query.ilike("name", `%${searchTerm}%`);
      }

      if (selectedCities.length > 0) {
        query = query.in("city", selectedCities);
      }

      if (selectedSports.length > 0) {
        const sportsQueryConditions = selectedSports.map(sport => `sports.cs.{${sport}}`);
        query = query.or(sportsQueryConditions.join(','));
      }

      const { data, error } = await query;
      
      if (error) {
        console.error("Error fetching academies:", error);
        throw error;
      }
      
      let filteredData = data as Academy[];
      if (selectedPriceRanges.length > 0) {
        filteredData = filteredData.filter(academy => {
          return selectedPriceRanges.some(range => {
            const [min, max] = range.split('-').map(Number);
            return academy.monthly_fee >= min && academy.monthly_fee <= max;
          });
        });
      }
      
      console.log("Fetched academies:", filteredData);
      return filteredData;
    },
  });

  const noResultsShowing = !isLoading && (!academies || academies.length === 0);
  useAutoRefresh(academies?.length || 0, noResultsShowing);

  const priceRanges = [
    { label: "₹1000 - ₹3000", value: "1000-3000" },
    { label: "₹3001 - ₹5000", value: "3001-5000" },
    { label: "₹5001 - ₹10000", value: "5001-10000" },
    { label: "₹10001+", value: "10001-50000" },
  ];

  const handleAcademyClick = (academy: Academy) => {
    setSelectedAcademy(academy);
    setIsSheetOpen(true);
  };

  const renderStars = (rating: number) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        className={`w-4 h-4 ${
          index < rating
            ? "text-yellow-400 fill-yellow-400"
            : "text-gray-300"
        }`}
      />
    ));
  };

  const toggleCity = (city: string) => {
    setSelectedCities(prev => 
      prev.includes(city) 
        ? prev.filter(c => c !== city) 
        : [...prev, city]
    );
  };

  const toggleSport = (sport: string) => {
    setSelectedSports(prev => 
      prev.includes(sport) 
        ? prev.filter(s => s !== sport) 
        : [...prev, sport]
    );
  };

  const togglePriceRange = (range: string) => {
    setSelectedPriceRanges(prev => 
      prev.includes(range) 
        ? prev.filter(r => r !== range) 
        : [...prev, range]
    );
  };

  const resetFilters = () => {
    setSearchTerm("");
    setSelectedCities([]);
    setSelectedPriceRanges([]);
    setSelectedSports([]);
  };

  const hasActiveFilters = selectedCities.length > 0 || selectedPriceRanges.length > 0 || selectedSports.length > 0;

  const academyStructuredData = academies && academies.length > 0 ? {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": academies.map((academy, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "item": {
        "@type": "SportsActivityLocation",
        "name": academy.name,
        "description": academy.description || "",
        "image": academy.image_url || "",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": academy.city
        },
        "offers": {
          "@type": "Offer",
          "price": academy.monthly_fee,
          "priceCurrency": "INR",
          "priceValidUntil": new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0]
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": academy.rating || 5,
          "reviewCount": academy.total_ratings || 1
        }
      }
    }))
  } : null;

  return (
    <div className="min-h-screen bg-white">
      <SEO 
        title="Find Sports Academies | beSportly"
        description="Discover and join sports academies in your city. Filter by sport, location, and price to find the perfect academy for your training needs."
        canonicalUrl="https://besportly.app/academies"
        structuredData={academyStructuredData}
      />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 md:pt-32">
        <div className="relative mb-8">
          <div className="absolute left-0 top-1 z-10">
            <BackButton />
          </div>
          <h1 className="text-4xl font-bold bg-gradient-to-r from-emerald-500 via-teal-500 to-green-500 bg-clip-text text-transparent animate-gradient px-1 py-1 rounded-lg mb-4 pl-10 md:pl-0 inline-block md:block w-auto relative">
            Discover and Join Academies
            <div className="h-1 md:w-full w-[100%] rounded-full bg-gradient-to-r from-emerald-400 via-teal-400 to-green-400 opacity-70 absolute bottom-0 left-0"></div>
          </h1>
          <p className="text-lg text-gray-600 pl-10 md:pl-0">
            Nurture your journey
          </p>
        </div>

        <ComingSoonBanner type="academies" />

        <div className="relative mb-6">
          <div className="relative">
            <Search className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
            <Input
              type="text"
              placeholder="Search academies..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-10"
            />
            <Button
              variant="outline"
              size="icon"
              className="absolute right-0 top-0 h-full px-3 rounded-l-none border-l-0"
              onClick={() => setFiltersVisible(!filtersVisible)}
            >
              <Filter className="h-5 w-5" />
              {hasActiveFilters && (
                <span className="absolute -top-1 -right-1 bg-emerald-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                  !
                </span>
              )}
            </Button>
          </div>

          {filtersVisible && (
            <div className="bg-white px-4 py-3 rounded-lg shadow-sm border mt-2 transition-all">
              <div className="mb-4">
                <h3 className="font-medium text-sm text-gray-700 mb-2">City <span className="text-xs text-gray-500">(select multiple)</span></h3>
                <div className="flex flex-wrap gap-1.5">
                  {popularCities.slice(0, isMobile ? 8 : 20).map((city) => (
                    <Toggle
                      key={city}
                      className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                        selectedCities.includes(city) 
                          ? "bg-emerald-500 text-white" 
                          : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                      }`}
                      pressed={selectedCities.includes(city)}
                      onPressedChange={() => toggleCity(city)}
                    >
                      {city}
                    </Toggle>
                  ))}
                </div>
              </div>

              <div className="mb-4">
                <h3 className="font-medium text-sm text-gray-700 mb-2">Sports <span className="text-xs text-gray-500">(select multiple)</span></h3>
                <div className="flex flex-wrap gap-1.5">
                  {sportCategories.map((sport) => (
                    <Toggle
                      key={sport}
                      className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                        selectedSports.includes(sport) 
                          ? "bg-emerald-500 text-white" 
                          : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                      }`}
                      pressed={selectedSports.includes(sport)}
                      onPressedChange={() => toggleSport(sport)}
                    >
                      {sport}
                    </Toggle>
                  ))}
                </div>
              </div>

              <div className="mb-4">
                <h3 className="font-medium text-sm text-gray-700 mb-2">Price Range <span className="text-xs text-gray-500">(select multiple)</span></h3>
                <div className="flex flex-wrap gap-1.5">
                  {priceRanges.map((range) => (
                    <Toggle
                      key={range.value}
                      className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                        selectedPriceRanges.includes(range.value) 
                          ? "bg-emerald-500 text-white" 
                          : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                      }`}
                      pressed={selectedPriceRanges.includes(range.value)}
                      onPressedChange={() => togglePriceRange(range.value)}
                    >
                      {range.label}
                    </Toggle>
                  ))}
                </div>
              </div>

              {hasActiveFilters && (
                <Button
                  variant="ghost"
                  className="text-emerald-600 hover:text-emerald-700 p-0 h-auto font-medium"
                  onClick={resetFilters}
                >
                  Clear all filters
                </Button>
              )}
            </div>
          )}
        </div>

        {isLoading ? (
          <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ${isMobile ? 'mobile-search-grid' : ''}`}>
            {[1, 2, 3].map((n) => (
              <Skeleton key={n} className="h-[400px] w-full rounded-xl" />
            ))}
          </div>
        ) : (
          <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ${isMobile ? 'mobile-search-grid' : ''}`}>
            {academies && academies.length > 0 ? (
              academies.map((academy) => (
                isMobile ? (
                  <motion.div
                    key={academy.id}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className="mobile-search-card academy-search-card"
                    onClick={() => handleAcademyClick(academy)}
                  >
                    <div className="card-image">
                      <img 
                        src={getExternalImage(academy)}
                        alt={academy.name}
                        className="w-full h-full object-cover"
                      />
                      <div className="card-badge">
                        External
                      </div>
                    </div>
                    <div className="card-content">
                      <h3 className="card-title">{academy.name}</h3>
                      <div className="card-subtitle">
                        <MapPin className="h-3 w-3 mr-1" />
                        <span>{academy.city}</span>
                      </div>
                      <div className="card-tags">
                        {academy.sports.slice(0, 2).map((sport, i) => (
                          <span key={i} className="card-tag">
                            {sport}
                          </span>
                        ))}
                        {academy.sports.length > 2 && (
                          <span className="card-tag">
                            +{academy.sports.length - 2}
                          </span>
                        )}
                      </div>
                      <div className="card-footer">
                        <div className="card-rating">
                          <Star className="h-3 w-3 text-yellow-400 fill-yellow-400 mr-1" />
                          <span>{academy.rating?.toFixed(1) || "5.0"}</span>
                        </div>
                        {academy.is_external && (
                          <div className="flex items-center mx-auto">
                            <img src="/lovable-uploads/8ead321d-1e98-43b9-b971-053d58ba6729.png" alt="Google Maps" className="h-3 w-3" />
                            <span className="text-xs text-blue-600 font-medium ml-1">From Google Maps</span>
                          </div>
                        )}
                        <span className="card-price">
                          {academy.is_external ? "Available on inquiry" : `₹${academy.monthly_fee}/mo`}
                        </span>
                      </div>
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    key={academy.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className="bg-white rounded-xl overflow-hidden border border-gray-100 hover:shadow-lg transition-all duration-300 cursor-pointer"
                    onClick={() => handleAcademyClick(academy)}
                  >
                    <div className="relative h-48 bg-gray-100 flex items-center justify-center">
                      <img 
                        src={getExternalImage(academy)}
                        alt={academy.name}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute top-2 left-2">
                        <span className="px-3 py-1 bg-gradient-to-r from-orange-400 to-yellow-300 text-white backdrop-blur-sm rounded-full text-sm font-medium">
                          External
                        </span>
                      </div>
                    </div>

                    <div className="p-6 space-y-4">
                      <h3 className="text-xl font-semibold text-gray-900">{academy.name}</h3>
                      
                      <div className="flex items-center text-gray-600">
                        <MapPin size={16} className="mr-2" />
                        <span>{academy.city}</span>
                      </div>

                      <div className="flex flex-wrap gap-2">
                        {academy.sports.map((sport, index) => (
                          <span key={index} className="text-xs bg-gray-100 px-2 py-1 rounded-full">
                            {sport}
                          </span>
                        ))}
                      </div>

                      <div className="flex justify-between items-center pt-4 border-t border-gray-100">
                        <div className="flex items-center gap-1">
                          {renderStars(academy.rating || 5)}
                        </div>
                        {academy.is_external && (
                          <div className="flex items-center mx-auto">
                            <img src="/lovable-uploads/8ead321d-1e98-43b9-b971-053d58ba6729.png" alt="Google Maps" className="h-4 w-4 mr-1" />
                            <span className="text-xs text-blue-600 font-medium">From Google Maps</span>
                          </div>
                        )}
                        <span className="text-primary font-semibold">
                          {academy.is_external ? "Available on inquiry" : `₹${academy.monthly_fee}/month`}
                        </span>
                      </div>
                    </div>
                  </motion.div>
                )
              ))
            ) : (
              <div className="col-span-full">
                <NoResultsAnimation type="academies" onReset={resetFilters} />
              </div>
            )}
          </div>
        )}
      </div>

      <AcademyDetailsSheet 
        academy={selectedAcademy} 
        isOpen={isSheetOpen} 
        onClose={() => setIsSheetOpen(false)} 
      />

      <style>
        {`
        .mobile-search-grid {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 1rem;
          padding: 0;
        }
        
        .mobile-search-card {
          display: flex;
          flex-direction: column;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0 1px 3px rgba(0,0,0,0.1);
          background: white;
          height: 100%;
          cursor: pointer;
          transition: transform 0.2s ease, box-shadow 0.2s ease;
        }
        
        .mobile-search-card:active {
          transform: scale(0.98);
        }
        
        .card-image {
          height: 160px;
          position: relative;
          background-color: #f3f4f6;
        }
        
        .card-badge {
          position: absolute;
          top: 8px;
          left: 8px;
          background: linear-gradient(90deg, #F97316, #FBBF24);
          color: white;
          font-size: 0.6875rem;
          font-weight: 500;
          padding: 0.25rem 0.5rem;
          border-radius: 9999px;
          display: flex;
          align-items: center;
          box-shadow: 0 1px 2px rgba(0,0,0,0.1);
        }
        
        .card-content {
          padding: 12px;
          display: flex;
          flex-direction: column;
          flex: 1;
        }
        
        .card-title {
          font-weight: 600;
          font-size: 0.9375rem;
          margin-bottom: 4px;
          color: #1f2937;
        }
        
        .card-subtitle {
          display: flex;
          align-items: center;
          color: #6b7280;
          font-size: 0.75rem;
          margin-bottom: 8px;
        }
        
        .card-tags {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          margin-bottom: 8px;
        }
        
        .card-tag {
          background-color: #f3f4f6;
          color: #4b5563;
          font-size: 0.6875rem;
          padding: 2px 6px;
          border-radius: 4px;
        }
        
        .card-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: auto;
          padding-top: 8px;
          border-top: 1px solid #f3f4f6;
        }
        
        .card-rating {
          display: flex;
          align-items: center;
          font-size: 0.75rem;
          font-weight: 500;
          color: #4b5563;
        }
        
        .card-price {
          font-size: 0.75rem;
          font-weight: 600;
          color: #7c3aed;
        }
        `}
      </style>
    </div>
  );
};

export default Academies;
