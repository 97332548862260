import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

interface PaymentDetails {
  bookingId: string;
  amount: number;
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  // Additional booking details for guest bookings
  venueName?: string;
  facilityName?: string;
  bookingDate?: string;
  startTime?: string;
  endTime?: string;
  venueId?: string;
}

const loadRazorpayScript = (): Promise<boolean> => {
  return new Promise((resolve) => {
    if ((window as any).Razorpay) {
      console.log("Razorpay already loaded");
      resolve(true);
      return;
    }
    
    console.log("Loading Razorpay script");
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => {
      console.log("Razorpay script loaded successfully");
      resolve(true);
    };
    script.onerror = () => {
      console.error("Failed to load Razorpay SDK");
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const initiatePayment = async ({
  bookingId,
  amount,
  customerName,
  customerEmail,
  customerPhone,
  venueName,
  facilityName,
  bookingDate,
  startTime,
  endTime,
  venueId
}: PaymentDetails): Promise<string | null> => {
  try {
    const isScriptLoaded = await loadRazorpayScript();
    if (!isScriptLoaded) {
      toast.error("Payment gateway failed to load. Please try again.");
      return null;
    }

    console.log("Creating Razorpay order for booking:", bookingId, "amount:", amount);
    
    const { data: paymentData, error } = await supabase.functions.invoke('create-razorpay-order', {
      body: {
        bookingId,
        amount: amount * 100,
        customerName,
        customerEmail,
        customerPhone
      }
    });

    if (error) {
      console.error("Razorpay order creation error:", error);
      toast.error("Failed to create payment order. Please try again.");
      throw error;
    }

    if (!paymentData || !paymentData.key_id || !paymentData.order_id) {
      console.error("Invalid payment data received:", paymentData);
      toast.error("Invalid payment configuration. Please try again.");
      return null;
    }

    console.log("Razorpay order created successfully:", paymentData);

    // Check if bookingDate is in the range where UTC date is different from IST date
    // This helps debug timezone issues
    if (bookingDate && startTime) {
      const [hours, minutes] = startTime.split(':').map(Number);
      const isInDateShiftRange = hours < 5 || (hours === 5 && minutes < 30);
      console.log(`Booking time ${startTime} IST on ${bookingDate} ${isInDateShiftRange ? "IS" : "is NOT"} in the date shift range (00:00-05:29 IST)`);
      if (isInDateShiftRange) {
        console.log("NOTE: This booking time will appear as the previous day in UTC time");
      }
    }

    // Store customer info and booking details for guest users
    const customerInfo = {
      name: customerName,
      email: customerEmail,
      phone: customerPhone,
      venue_name: venueName,
      facility_name: facilityName,
      booking_date: bookingDate,
      start_time: startTime,
      end_time: endTime,
      total_amount: amount,
      venue_id: venueId
    };
    
    // Save booking details for confirmation page
    localStorage.setItem(`booking_${bookingId}`, JSON.stringify({
      venue_name: venueName,
      facility_name: facilityName,
      booking_date: bookingDate,
      start_time: startTime,
      end_time: endTime,
      total_amount: amount
    }));
    
    // Save customer info for notifications
    localStorage.setItem(`booking_${bookingId}_customer`, JSON.stringify(customerInfo));

    // Add CSS to control the Razorpay modal height only
    const styleElement = document.createElement('style');
    styleElement.id = 'razorpay-modal-style';
    styleElement.textContent = `
      @media (max-width: 768px) {
        .razorpay-payment-button, .razorpay-checkout-frame {
          max-height: 80vh !important;
          height: 80% !important;
        }
        
        .razorpay-backdrop {
          background-color: rgba(0, 0, 0, 0.7) !important;
        }
      }
    `;
    document.head.appendChild(styleElement);

    return new Promise((resolve, reject) => {
      const options = {
        key: paymentData.key_id,
        amount: paymentData.amount,
        currency: "INR",
        name: "beSportly",
        description: "Venue Booking Payment",
        order_id: paymentData.order_id,
        prefill: {
          name: customerName,
          email: customerEmail,
          contact: customerPhone,
        },
        handler: async function (response: any) {
          console.log("Payment successful, Razorpay response:", response);
          
          // Remove the style element once payment is processed
          const styleEl = document.getElementById('razorpay-modal-style');
          if (styleEl) styleEl.remove();
          
          try {
            toast.info("Verifying payment...");
            
            // Show loading animation
            const loadingOverlay = document.createElement('div');
            loadingOverlay.id = 'payment-success-overlay';
            loadingOverlay.className = 'fixed inset-0 z-50 flex items-center justify-center bg-black/60 backdrop-blur-md';
            
            // Create animation container
            const animationContainer = document.createElement('div');
            animationContainer.className = 'relative flex flex-col items-center p-8 rounded-xl bg-white/10 backdrop-blur-md';
            
            // Success animation
            animationContainer.innerHTML = `
              <div class="relative w-24 h-24 mb-4">
                <div class="absolute inset-0 rounded-full bg-gradient-to-r from-green-400 to-emerald-500 opacity-50 animate-pulse"></div>
                <div class="absolute inset-0 flex items-center justify-center">
                  <svg class="w-12 h-12 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
              </div>
              <h3 class="text-xl font-bold text-white mb-2">Payment Successful!</h3>
              <p class="text-white/80 text-center mb-4">We're confirming your booking...</p>
              <div class="flex space-x-2">
                ${[...Array(3)].map((_, i) => 
                  `<div class="w-2 h-2 rounded-full bg-white animate-bounce" style="animation-delay: ${i * 0.2}s"></div>`
                ).join('')}
              </div>
            `;
            
            loadingOverlay.appendChild(animationContainer);
            document.body.appendChild(loadingOverlay);
            
            // Detect mobile device here to determine verification strategy
            const isMobileDevice = window.innerWidth < 768;
            const redirectUrl = `/booking-confirmation?status=success&txnid=${bookingId}`;
            
            if (isMobileDevice) {
              const timestamp = () => new Date().toISOString().substring(11, 19);
              
              console.log("[MOBILE PAYMENT FLOW] Mobile device detected - using mobile auto-confirmation flow");
              
              // Debug log element for mobile users - HIDDEN FROM VIEW now (previously visible)
              const debugLogContainer = document.createElement('div');
              debugLogContainer.id = 'mobile-payment-debug';
              debugLogContainer.className = 'fixed bottom-0 left-0 right-0 bg-black/80 text-white text-xs p-2 max-h-32 overflow-y-auto z-[999]';
              debugLogContainer.style.display = 'none'; // Hide by default - changed from 'block' to 'none'
              
              const debugLog = (message: string) => {
                const currentTime = timestamp();
                console.log(`[MOBILE PAYMENT DEBUG][${currentTime}] ${message}`);
                if (debugLogContainer.parentNode) {
                  const logEntry = document.createElement('div');
                  logEntry.innerText = `${currentTime}: ${message}`;
                  debugLogContainer.appendChild(logEntry);
                  debugLogContainer.scrollTop = debugLogContainer.scrollHeight;
                }
              };
              
              document.body.appendChild(debugLogContainer);
              
              // Debug toggle button - Also hidden now
              const debugToggle = document.createElement('button');
              debugToggle.className = 'fixed bottom-2 right-2 bg-gray-800 text-white rounded-full w-8 h-8 flex items-center justify-center z-[1000]';
              debugToggle.innerHTML = '🐞';
              debugToggle.style.display = 'none'; // Hide debug toggle - changed from visible to 'none'
              debugToggle.onclick = () => {
                debugLogContainer.style.display = debugLogContainer.style.display === 'none' ? 'block' : 'none';
              };
              document.body.appendChild(debugToggle);
              
              debugLog("Payment successful, starting mobile auto-confirmation flow");
              debugLog(`Payment successful at ${new Date().toLocaleTimeString()}`);
              debugLog(`Booking ID: ${bookingId}`);
              debugLog(`Payment ID: ${response.razorpay_payment_id}`);
              debugLog(`Order ID: ${response.razorpay_order_id}`);
              debugLog(`Signature exists: ${!!response.razorpay_signature}`);
              debugLog(`customerInfo exists: ${!!customerInfo}`);
              
              // Add faster 3-second redirect for mobile users
              debugLog("Setting up quick 3-second redirect for mobile users");
              setTimeout(() => {
                debugLog("Quick 3-second redirect timer triggered");
                window.location.replace(redirectUrl);
              }, 3000);
              
              // Keep existing functionality - background operations continue but user is redirected quicker
              // Perform all booking-related operations directly without calling edge function
              try {
                debugLog("Starting auto-confirmation process for mobile");
                  
                // Create a promise for all database operations with a timeout
                const autoConfirmOperations = async () => {
                  try {
                    // Set a global timeout for all operations
                    let operationsCompleted = false;
                      
                    // Setup timeout to prevent hanging
                    const timeoutPromise = new Promise<boolean>((_, reject) => {
                      setTimeout(() => {
                        if (!operationsCompleted) {
                          debugLog("⚠️ TIMEOUT: Operations took too long, forcing redirect");
                          window.location.replace(redirectUrl);
                          reject(new Error("Operations timeout"));
                        }
                      }, 30000); // 30 second timeout (increased from 15s)
                    });
                      
                    // Run operations with individual timeouts and detailed error logging
                    const runOperations = async () => {
                      debugLog("Starting database operations with timeouts");
                      
                      try {
                        // ENHANCED LOGGING: Check Supabase connection first
                        debugLog("Checking Supabase connection...");
                        const { data: connectionTest, error: connectionError } = await supabase.from('payment_transactions').select('count(*)', { count: 'exact', head: true });
                        
                        if (connectionError) {
                          debugLog(`⚠️ Supabase connection error: ${connectionError.message}`);
                          debugLog(`Error details: ${JSON.stringify(connectionError)}`);
                        } else {
                          debugLog("✅ Supabase connection OK");
                        }
                      } catch (connErr) {
                        debugLog(`⚠️ Exception testing connection: ${connErr instanceof Error ? connErr.message : String(connErr)}`);
                      }
                        
                      // Record payment with timeout and enhanced error logging
                      try {
                        debugLog("🔄 STEP 1: Recording payment transaction...");
                        await Promise.race([
                          recordPaymentTransaction(),
                          new Promise((_, reject) => setTimeout(() => reject(new Error("Payment record timeout")), 8000)) // Increased from 5s
                        ]);
                        debugLog("✅ Payment transaction recorded successfully");
                      } catch (error) {
                        const errorMsg = error instanceof Error ? error.message : String(error);
                        debugLog(`❌ FAILED STEP 1: Payment record error: ${errorMsg}`);
                        debugLog(`Continuing with next operations despite error...`);
                      }
                        
                      // Update booking with timeout
                      try {
                        debugLog("🔄 STEP 2: Updating booking status...");
                        await Promise.race([
                          updateBookingStatus(),
                          new Promise((_, reject) => setTimeout(() => reject(new Error("Booking update timeout")), 8000)) // Increased from 5s
                        ]);
                        debugLog("✅ Booking status updated successfully");
                      } catch (error) {
                        const errorMsg = error instanceof Error ? error.message : String(error);
                        debugLog(`❌ FAILED STEP 2: Booking update error: ${errorMsg}`);
                        debugLog(`Continuing with next operations despite error...`);
                      }
                        
                      // Update slot with timeout
                      try {
                        debugLog("🔄 STEP 3: Updating availability slot...");
                        await Promise.race([
                          updateAvailabilitySlot(),
                          new Promise((_, reject) => setTimeout(() => reject(new Error("Slot update timeout")), 8000)) // Increased from 5s
                        ]);
                        debugLog("✅ Availability slot updated successfully");
                      } catch (error) {
                        const errorMsg = error instanceof Error ? error.message : String(error);
                        debugLog(`❌ FAILED STEP 3: Slot update error: ${errorMsg}`);
                        debugLog(`Continuing with next operations despite error...`);
                      }
                        
                      // Send notifications with timeout
                      try {
                        debugLog("🔄 STEP 4: Sending confirmation notifications...");
                        await Promise.race([
                          sendConfirmationNotifications(),
                          new Promise((_, reject) => setTimeout(() => reject(new Error("Notifications timeout")), 8000)) // Increased from 5s
                        ]);
                        debugLog("✅ Confirmation notifications sent successfully");
                      } catch (error) {
                        const errorMsg = error instanceof Error ? error.message : String(error);
                        debugLog(`❌ FAILED STEP 4: Notifications error: ${errorMsg}`);
                        debugLog(`All steps attempted, proceeding to completion...`);
                      }

                      debugLog("🎉 All operations completed or attempted! Redirecting to confirmation page.");
                      return true;
                    };
                      
                    // Race the operations against the timeout
                    await Promise.race([runOperations(), timeoutPromise]);
                      
                    operationsCompleted = true;
                    debugLog("✅ All operations completed or timed out individually");
                    debugLog("🔄 Redirecting to confirmation page");
                    window.location.replace(redirectUrl);
                    return true;
                  } catch (error) {
                    const errorMsg = error instanceof Error ? error.message : String(error);
                    debugLog(`⚠️ Critical error in operations: ${errorMsg}`);
                    debugLog(`Full error: ${JSON.stringify(error)}`);
                    debugLog("🔄 Redirecting to confirmation page despite errors");
                    window.location.replace(redirectUrl);
                    return false;
                  }
                };
                  
                // Execute operations with global error handling
                autoConfirmOperations().catch(err => {
                  const errorMsg = err instanceof Error ? err.message : String(err);
                  debugLog(`⚠️ Fatal error in payment flow: ${errorMsg}`);
                  debugLog(`Stack trace: ${err instanceof Error ? err.stack : 'No stack trace'}`);
                  debugLog("🔄 Emergency redirect to confirmation page");
                  window.location.replace(redirectUrl);
                });
                  
              } catch (err) {
                const errorMsg = err instanceof Error ? err.message : String(err);
                debugLog(`⚠️ Unexpected error in payment flow: ${errorMsg}`);
                debugLog(`Stack trace: ${err instanceof Error ? err.stack : 'No stack trace'}`);
                debugLog("🔄 Emergency redirect to confirmation page");
                window.location.replace(redirectUrl);
              }
                
              // Always resolve the promise to prevent payment errors
              resolve(bookingId);
                
              // Helper function to record payment transaction
              async function recordPaymentTransaction() {
                debugLog("Recording payment transaction...");
                try {
                  debugLog(`Transaction data: Booking=${bookingId}, Amount=${amount}, Payment ID=${response.razorpay_payment_id}`);
                  
                  // First check if payment record already exists
                  debugLog("Checking if payment record already exists...");
                  const { data: existingPayment, error: checkError } = await supabase
                    .from('payment_transactions')
                    .select('id')
                    .eq('transaction_id', response.razorpay_payment_id)
                    .maybeSingle();
                    
                  if (checkError) {
                    debugLog(`Error checking existing payment: ${checkError.message}`);
                    debugLog(`Error code: ${checkError.code}, details: ${JSON.stringify(checkError)}`);
                  } else if (existingPayment) {
                    debugLog(`Payment record already exists with ID: ${existingPayment.id}`);
                    return; // Skip creating duplicate
                  } else {
                    debugLog("No existing payment record found, creating new one...");
                  }
                  
                  // Insert new payment record
                  debugLog("Inserting payment transaction record...");
                  const paymentRecord = {
                    booking_id: bookingId,
                    amount: amount,
                    payment_status: 'completed',
                    transaction_id: response.razorpay_payment_id,
                    payment_method: 'razorpay'
                  };
                  debugLog(`Payment record data: ${JSON.stringify(paymentRecord)}`);
                  
                  const { data: paymentRecordData, error: paymentRecordError } = await supabase
                    .from('payment_transactions')
                    .insert([paymentRecord]);
                  
                  if (paymentRecordError) {
                    debugLog(`Error inserting payment transaction: ${paymentRecordError.message}`);
                    debugLog(`Error code: ${paymentRecordError.code}, hint: ${paymentRecordError.hint || 'none'}`);
                    debugLog(`Error details: ${JSON.stringify(paymentRecordError)}`);
                    
                    // Handle foreign key constraint errors
                    if (paymentRecordError.code === '23503') {
                      debugLog("Foreign key constraint error. Attempting to create booking first...");
                      await createBookingRecord();
                      // Try payment record again
                      debugLog("Trying payment record again after creating booking...");
                      const secondAttempt = await supabase
                        .from('payment_transactions')
                        .insert([paymentRecord]);
                      
                      if (secondAttempt.error) {
                        debugLog(`Second attempt to insert payment transaction failed: ${secondAttempt.error.message}`);
                        debugLog(`Error details: ${JSON.stringify(secondAttempt.error)}`);
                        throw new Error(`Second attempt failed: ${secondAttempt.error.message}`);
                      } else {
                        debugLog("Second attempt to insert payment transaction succeeded!");
                      }
                    } else {
                      throw new Error(`Payment record error: ${paymentRecordError.message}`);
                    }
                  } else {
                    debugLog("Payment transaction recorded successfully");
                  }
                } catch (innerError) {
                  const errorMsg = innerError instanceof Error ? innerError.message : String(innerError);
                  debugLog(`Unexpected error recording payment: ${errorMsg}`);
                  debugLog(`Error details: ${JSON.stringify(innerError)}`);
                  throw innerError; // Re-throw for the parent catch
                }
              }
              
              // Helper function to update booking status
              async function updateBookingStatus() {
                debugLog("Updating booking status...");
                try {
                  // First check if booking exists
                  debugLog("Checking if booking exists...");
                  const { data: existingBooking, error: checkError } = await supabase
                    .from('bookings')
                    .select('id, status, payment_status')
                    .eq('id', bookingId)
                    .maybeSingle();
                    
                  if (checkError) {
                    debugLog(`Error checking existing booking: ${checkError.message}`);
                    debugLog(`Error details: ${JSON.stringify(checkError)}`);
                  } else if (existingBooking) {
                    debugLog(`Found existing booking with status: ${existingBooking.status}, payment_status: ${existingBooking.payment_status}`);
                    
                    // Only update if not already confirmed
                    if (existingBooking.status !== 'confirmed' || existingBooking.payment_status !== 'paid') {
                      debugLog("Updating booking to confirmed/paid status");
                      const { error: updateError } = await supabase
                        .from('bookings')
                        .update({
                          status: 'confirmed',
                          payment_status: 'paid'
                        })
                        .eq('id', bookingId);
                        
                      if (updateError) {
                        debugLog(`Error updating booking: ${updateError.message}`);
                        debugLog(`Error details: ${JSON.stringify(updateError)}`);
                        throw new Error(`Update booking error: ${updateError.message}`);
                      } else {
                        debugLog("Booking status updated successfully");
                      }
                    } else {
                      debugLog("Booking already in confirmed/paid status, no update needed");
                    }
                  } else {
                    debugLog("Booking not found, will create new booking record");
                    await createBookingRecord();
                  }
                } catch (bookingError) {
                  const errorMsg = bookingError instanceof Error ? bookingError.message : String(bookingError);
                  debugLog(`Unexpected error updating booking: ${errorMsg}`);
                  debugLog(`Error details: ${JSON.stringify(bookingError)}`);
                  throw bookingError; // Re-throw for the parent catch
                }
              }
              
              // Helper function to create booking record if needed
              async function createBookingRecord() {
                if (!customerInfo || !customerInfo.booking_date || !customerInfo.venue_name) {
                  debugLog("Cannot create booking: insufficient customer info");
                  debugLog(`Available customer info: ${JSON.stringify(customerInfo)}`);
                  throw new Error("Insufficient customer info for booking creation");
                }
                
                debugLog("Creating new booking record");
                try {
                  debugLog(`Booking data: ID=${bookingId}, Date=${customerInfo.booking_date}, Facility=${customerInfo.facility_name}`);
                  
                  const bookingData = {
                    id: bookingId,
                    booking_date: customerInfo.booking_date,
                    start_time: customerInfo.start_time,
                    end_time: customerInfo.end_time,
                    total_amount: customerInfo.total_amount,
                    facility_name: customerInfo.facility_name,
                    venue_id: customerInfo.venue_id,
                    status: 'confirmed',
                    payment_status: 'paid'
                  };
                  
                  debugLog(`Full booking data: ${JSON.stringify(bookingData)}`);
                  
                  const { data: newBooking, error: createError } = await supabase
                    .from('bookings')
                    .insert([bookingData])
                    .select();
                      
                  if (createError) {
                    debugLog(`Error creating booking: ${createError.message}`);
                    debugLog(`Error code: ${createError.code}, hint: ${createError.hint || 'none'}`);
                    debugLog(`Error details: ${JSON.stringify(createError)}`);
                    throw new Error(`Create booking error: ${createError.message}`);
                  } else {
                    debugLog("Booking created successfully");
                    debugLog(`New booking data: ${JSON.stringify(newBooking)}`);
                  }
                } catch (createError) {
                  const errorMsg = createError instanceof Error ? createError.message : String(createError);
                  debugLog(`Exception creating booking: ${errorMsg}`);
                  debugLog(`Error details: ${JSON.stringify(createError)}`);
                  throw createError; // Re-throw for the parent catch
                }
              }
              
              // Helper function to update availability slot
              async function updateAvailabilitySlot() {
                if (customerInfo && customerInfo.booking_date && customerInfo.start_time && customerInfo.venue_id && customerInfo.facility_name) {
                  debugLog("Finding and updating availability slot");
                  
                  // Find facility ID
                  debugLog(`Looking for facility: ${customerInfo.facility_name} in venue: ${customerInfo.venue_id}`);
                  try {
                    const { data: facilityData, error: facilityError } = await supabase
                      .from('facilities')
                      .select('id')
                      .eq('name', customerInfo.facility_name)
                      .eq('venue_id', customerInfo.venue_id)
                      .maybeSingle();
                    
                    if (facilityError) {
                      debugLog(`Error finding facility: ${facilityError.message}`);
                      debugLog(`Error details: ${JSON.stringify(facilityError)}`);
                      throw new Error(`Find facility error: ${facilityError.message}`);
                    } 
                    
                    if (!facilityData) {
                      debugLog(`Facility not found: ${customerInfo.facility_name}`);
                      // Detailed query information for debugging
                      debugLog(`Query parameters: name='${customerInfo.facility_name}', venue_id='${customerInfo.venue_id}'`);
                      
                      // Try a broader search to see what facilities exist
                      const { data: allFacilities } = await supabase
                        .from('facilities')
                        .select('id, name')
                        .eq('venue_id', customerInfo.venue_id)
                        .limit(10);
                        
                      debugLog(`Available facilities for venue: ${JSON.stringify(allFacilities)}`);
                      throw new Error(`Facility not found: ${customerInfo.facility_name}`);
                    }
                    
                    debugLog(`Found facility with ID: ${facilityData.id}`);
                    
                    // Parse date components
                    const [year, month, day] = customerInfo.booking_date.split('-').map(Number);
                    const [hours, minutes] = customerInfo.start_time.split(':').map(Number);
                    
                    debugLog(`Date components: year=${year}, month=${month}, day=${day}, hours=${hours}, minutes=${minutes}`);
                    
                    // Create Date object in IST
                    const dateInIST = new Date(year, month - 1, day, hours, minutes);
                    debugLog(`Date in IST: ${dateInIST.toISOString()}`);
                    
                    // Check if we're in the "date shift" time range (00:00 to 05:29 IST)
                    const isInDateShiftRange = hours < 5 || (hours === 5 && minutes < 30);
                    debugLog(`Is in date shift range (00:00-05:29 IST): ${isInDateShiftRange}`);
                    
                    // Convert to UTC
                    const dateInUTC = new Date(dateInIST);
                    dateInUTC.setHours(dateInIST.getHours() - 5);
                    dateInUTC.setMinutes(dateInIST.getMinutes() - 30);
                    debugLog(`Date in UTC: ${dateInUTC.toISOString()}`);
                    
                    // Get date part for filtering
                    let utcDateStr;
                    if (isInDateShiftRange) {
                      // Use the original IST date for the search
                      utcDateStr = customerInfo.booking_date;
                      debugLog(`Using original IST date for slot search due to date shift: ${utcDateStr}`);
                    } else {
                      // For other times, use the UTC date
                      utcDateStr = dateInUTC.toISOString().split('T')[0];
                      debugLog(`Using UTC date for slot search: ${utcDateStr}`);
                    }
                    
                    // Set up start and end of day
                    let startOfDay, endOfDay;
                    
                    if (isInDateShiftRange) {
                      // Use the original booking date but in UTC format
                      startOfDay = new Date(`${customerInfo.booking_date}T00:00:00.000Z`);
                      endOfDay = new Date(`${customerInfo.booking_date}T23:59:59.999Z`);
                    } else {
                      // Standard approach for non-problematic times
                      startOfDay = new Date(utcDateStr);
                      startOfDay.setUTCHours(0, 0, 0, 0);
                      
                      endOfDay = new Date(utcDateStr);
                      endOfDay.setUTCHours(23, 59, 59, 999);
                    }
                    
                    debugLog(`Querying slots between ${startOfDay.toISOString()} and ${endOfDay.toISOString()}`);
                    
                    // Query for availability slots
                    const { data: availableSlots, error: slotsError } = await supabase
                      .from('availability_slots')
                      .select('*')
                      .eq('facility_id', facilityData.id)
                      .gte('start_time', startOfDay.toISOString())
                      .lt('start_time', endOfDay.toISOString());
                    
                    if (slotsError) {
                      debugLog(`Error finding slots: ${slotsError.message}`);
                      debugLog(`Error details: ${JSON.stringify(slotsError)}`);
                      throw new Error(`Find slots error: ${slotsError.message}`);
                    } 
                    
                    if (!availableSlots || availableSlots.length === 0) {
                      debugLog(`No slots found for facility ID ${facilityData.id} on date ${utcDateStr}`);
                      
                      // Try a broader date range search for debugging
                      const broadStartDate = new Date(startOfDay);
                      broadStartDate.setDate(broadStartDate.getDate() - 1);
                      
                      const broadEndDate = new Date(endOfDay);
                      broadEndDate.setDate(broadEndDate.getDate() + 1);
                      
                      debugLog(`Trying broader date range search: ${broadStartDate.toISOString()} to ${broadEndDate.toISOString()}`);
                      
                      const { data: broadRangeSlots } = await supabase
                        .from('availability_slots')
                        .select('id, start_time, is_booked')
                        .eq('facility_id', facilityData.id)
                        .gte('start_time', broadStartDate.toISOString())
                        .lt('start_time', broadEndDate.toISOString())
                        .limit(10);
                        
                      if (broadRangeSlots && broadRangeSlots.length > 0) {
                        debugLog(`Found ${broadRangeSlots.length} slots in broader date range: ${JSON.stringify(broadRangeSlots)}`);
                      } else {
                        debugLog(`No slots found even in broader date range search`);
                      }
                      
                      throw new Error(`No slots found for facility ID ${facilityData.id} on date ${utcDateStr}`);
                    }
                    
                    debugLog(`Found ${availableSlots.length} slots for the day`);
                    debugLog(`First few slots: ${JSON.stringify(availableSlots.slice(0, 3))}`);
                    
                    // Find matching slot
                    const utcHour = dateInUTC.getUTCHours();
                    const utcMinute = dateInUTC.getUTCMinutes();
                    const targetMinutes = utcHour * 60 + utcMinute;
                    
                    debugLog(`Looking for slot matching time ${utcHour}:${utcMinute} (${targetMinutes} minutes)`);
                    
                    let bestMatch = null;
                    let smallestDifference = Infinity;
                    
                    for (const slot of availableSlots) {
                      const slotDate = new Date(slot.start_time);
                      const slotHours = slotDate.getUTCHours();
                      const slotMinutes = slotDate.getUTCMinutes();
                      
                      const slotTotalMinutes = slotHours * 60 + slotMinutes;
                      const difference = Math.abs(targetMinutes - slotTotalMinutes);
                      
                      debugLog(`Slot ${slot.id} at ${slotDate.toISOString()} (${slotHours}:${slotMinutes}) has difference of ${difference} minutes from target ${utcHour}:${utcMinute}`);
                      
                      if (difference < smallestDifference) {
                        smallestDifference = difference;
                        bestMatch = slot;
                      }
                    }
                    
                    if (bestMatch && smallestDifference <= 15) {
                      debugLog(`Found matching slot at ${new Date(bestMatch.start_time).toISOString()} with difference of ${smallestDifference} minutes`);
                      
                      if (!bestMatch.is_booked) {
                        debugLog(`Marking slot ${bestMatch.id} as booked`);
                        const updateResult = await supabase
                          .from('availability_slots')
                          .update({ is_booked: true })
                          .eq('id', bestMatch.id);
                          
                        if (updateResult.error) {
                          debugLog(`Error updating slot: ${updateResult.error.message}`);
                          debugLog(`Error details: ${JSON.stringify(updateResult.error)}`);
                          throw new Error(`Update slot error: ${updateResult.error.message}`);
                        } else {
                          debugLog("Successfully marked slot as booked");
                        }
                      } else {
                        debugLog("Slot is already marked as booked");
                      }
                    } else {
                      debugLog("No matching slot found within reasonable time difference");
                      if (bestMatch) {
                        debugLog(`Best match had difference of ${smallestDifference} minutes, which is > 15 minute threshold`);
                      }
                      throw new Error("No matching slot found within reasonable time difference");
                    }
                  } catch (error) {
                    const errorMsg = error instanceof Error ? error.message : String(error);
                    debugLog(`Exception updating availability slot: ${errorMsg}`);
                    debugLog(`Error details: ${JSON.stringify(error)}`);
                    throw error; // Re-throw for the parent catch
                  }
                } else {
                  const missingFields = [];
                  if (!customerInfo) missingFields.push("customerInfo");
                  else {
                    if (!customerInfo.booking_date) missingFields.push("booking_date");
                    if (!customerInfo.start_time) missingFields.push("start_time");
                    if (!customerInfo.venue_id) missingFields.push("venue_id");
                    if (!customerInfo.facility_name) missingFields.push("facility_name");
                  }
                  
                  debugLog(`Missing required fields for slot update: ${missingFields.join(", ")}`);
                  debugLog(`Available customer info: ${JSON.stringify(customerInfo)}`);
                  throw new Error(`Missing required fields: ${missingFields.join(", ")}`);
                }
              }
              
              // Helper function to send confirmation notifications
              async function sendConfirmationNotifications() {
                if (customerInfo && customerInfo.email) {
                  debugLog("Sending booking confirmation notifications");
                  try {
                    debugLog(`Sending confirmation to email: ${customerInfo.email}`);
                    const notifResult = await supabase.functions.invoke('send-booking-confirmation', {
                      body: { 
                        bookingId,
                        customerInfo
                      }
                    });
                    
                    if (notifResult.error) {
                      debugLog(`Error sending notifications: ${notifResult.error.message}`);
                      debugLog(`Error details: ${JSON.stringify(notifResult.error)}`);
                      throw new Error(`Send notifications error: ${notifResult.error.message}`);
                    } else {
                      debugLog("Notifications sent successfully");
                      if (notifResult.data) {
                        debugLog(`Notification response: ${JSON.stringify(notifResult.data)}`);
                      }
                    }
                  } catch (notifError) {
                    const errorMsg = notifError instanceof Error ? notifError.message : String(notifError);
                    debugLog(`Exception sending notifications: ${errorMsg}`);
                    debugLog(`Error details: ${JSON.stringify(notifError)}`);
                    throw notifError; // Re-throw for the parent catch
                  }
                } else {
                  debugLog("No customer email available for notifications");
                  if (customerInfo) {
                    debugLog(`Available customer info: ${JSON.stringify(customerInfo)}`);
                  } else {
                    debugLog("customerInfo is null or undefined");
                  }
                  throw new Error("No customer email available for notifications");
                }
              }
            } else {
              // Desktop flow remains unchanged
              console.log("Desktop flow - proceeding with normal verification");
              
              try {
                // Call verification endpoint
                const { data: verificationData, error: verificationError } = await supabase.functions.invoke('verify-razorpay-payment', {
                  body: {
                    bookingId,
                    paymentId: response.razorpay_payment_id,
                    orderId: response.razorpay_order_id,
                    signature: response.razorpay_signature,
                    customerInfo
                  }
                });

                if (verificationError) {
                  console.error("Payment verification failed:", verificationError);
                  toast.error("Payment verification failed. Please contact support.");
                  
                  // Remove loading overlay
                  const overlay = document.getElementById('payment-success-overlay');
                  if (overlay) overlay.remove();
                  
                  window.location.href = `/booking-confirmation?status=failure&txnid=${bookingId}`;
                  reject(verificationError);
                  return;
                }

                console.log("Payment verification successful:", verificationData);
                toast.success("Payment successful!");
                
                // On desktop, keep the existing behavior
                const overlay = document.getElementById('payment-success-overlay');
                if (overlay) {
                  // Add a short delay to ensure the success message is visible on desktop
                  setTimeout(() => {
                    console.log("Executing desktop redirect now to:", redirectUrl);
                    window.location.replace(redirectUrl);
                    resolve(bookingId);
                  }, 1500);
                } else {
                  // If overlay was already removed, redirect immediately
                  console.log("Overlay not found, redirecting immediately to:", redirectUrl);
                  window.location.replace(redirectUrl);
                  resolve(bookingId);
                }
              } catch (error) {
                console.error("Error during payment verification:", error);
                toast.error("Payment verification failed. Please contact support.");
                
                // Remove loading overlay if it exists
                const overlay = document.getElementById('payment-success-overlay');
                if (overlay) overlay.remove();
                
                window.location.href = `/booking-confirmation?status=failure&txnid=${bookingId}`;
                reject(error);
              }
            }
          } catch (error) {
            console.error("Error after payment completion:", error);
            toast.error("Payment verification failed. Please contact support.");
            
            // Remove loading overlay if it exists
            const overlay = document.getElementById('payment-success-overlay');
            if (overlay) overlay.remove();
            
            window.location.href = `/booking-confirmation?status=failure&txnid=${bookingId}`;
            reject(error);
          }
        },
        modal: {
          ondismiss: function() {
            console.log("Payment modal dismissed");
            // Remove the style element if modal is dismissed
            const styleEl = document.getElementById('razorpay-modal-style');
            if (styleEl) styleEl.remove();
            
            window.location.href = `/booking-confirmation?status=failure&txnid=${bookingId}`;
            reject(new Error("Payment cancelled"));
          }
        },
        theme: {
          color: "#3399cc"
        }
      };

      const rzp = new (window as any).Razorpay(options);
      rzp.on('payment.failed', function (response: any) {
        console.error("Payment failed:", response.error);
        
        // Remove the style element if payment fails
        const styleEl = document.getElementById('razorpay-modal-style');
        if (styleEl) styleEl.remove();
        
        toast.error(`Payment failed: ${response.error.description}`);
        window.location.href = `/booking-confirmation?status=failure&txnid=${bookingId}`;
        reject(new Error("Payment failed"));
      });
      
      rzp.open();
    });
  } catch (error) {
    console.error('Payment error:', error);
    toast.error("Payment initialization failed. Please try again.");
    return null;
  }
};
