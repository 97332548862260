
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Clock, Sparkle, Heart, Construction, Home, Stars, Beaker, Moon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";

export const MaintenanceOverlay = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const navigate = useNavigate();
  
  // Update the current time every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    
    return () => clearInterval(interval);
  }, []);
  
  // Calculate time remaining until 5:30 AM IST
  const calculateTimeRemaining = () => {
    const now = new Date();
    const endTime = new Date(now);
    
    // Set to 5:30 AM
    endTime.setHours(5, 30, 0, 0);
    
    // If current time is after 5:30 AM, set end time to 5:30 AM tomorrow
    if (now > endTime) {
      endTime.setDate(endTime.getDate() + 1);
    }
    
    const diffMs = endTime.getTime() - now.getTime();
    const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
    const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
    
    return `${diffHrs}h ${diffMins}m`;
  };
  
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/40 backdrop-blur-md">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-indigo-900/50 via-purple-900/40 to-pink-900/50"></div>
        
        {/* Animated stars */}
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute rounded-full bg-white"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${Math.random() * 3 + 1}px`,
              height: `${Math.random() * 3 + 1}px`,
            }}
            animate={{
              opacity: [0.2, 0.8, 0.2],
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: Math.random() * 3 + 2,
              repeat: Infinity,
              repeatType: "reverse",
            }}
          />
        ))}
      </div>
      
      <motion.div 
        className="w-full max-w-md bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 dark:from-indigo-900/20 dark:via-purple-900/20 dark:to-pink-900/20 p-8 rounded-3xl shadow-2xl border border-white/20 z-10 relative overflow-hidden"
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", damping: 15 }}
      >
        {/* Decorative elements */}
        <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-pink-400/10 to-purple-400/10 rounded-full blur-xl -mr-16 -mt-16"></div>
        <div className="absolute bottom-0 left-0 w-32 h-32 bg-gradient-to-br from-indigo-400/10 to-blue-400/10 rounded-full blur-xl -ml-16 -mb-16"></div>
        
        <motion.div
          className="absolute -top-10 -right-8"
          animate={{ rotate: [0, 10, 0, -10, 0] }}
          transition={{ repeat: Infinity, duration: 5 }}
        >
          <Stars className="h-10 w-10 text-purple-400" />
        </motion.div>
        
        <motion.div
          className="absolute -left-6 top-10"
          animate={{ scale: [1, 1.1, 1], opacity: [0.7, 1, 0.7] }}
          transition={{ repeat: Infinity, duration: 3 }}
        >
          <Moon className="h-8 w-8 text-indigo-400 fill-indigo-200" />
        </motion.div>
        
        <div className="flex flex-col items-center text-center relative">
          <motion.div
            className="h-24 w-24 rounded-full bg-gradient-to-r from-indigo-500 to-purple-500 flex items-center justify-center mb-6 shadow-lg shadow-purple-500/20"
            animate={{ y: [0, -5, 0] }}
            transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
          >
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ repeat: Infinity, duration: 10, ease: "linear" }}
            >
              <Construction className="h-12 w-12 text-white" />
            </motion.div>
          </motion.div>
          
          <h2 className="text-3xl font-bold mb-2 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 bg-clip-text text-transparent">
            Night Maintenance
          </h2>
          
          <motion.p 
            className="text-gray-600 dark:text-gray-300 mb-6 max-w-xs"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            Our booking system is taking a well-deserved rest and undergoing maintenance. Please check back after 5:30 AM.
          </motion.p>
          
          <div className="bg-white/50 dark:bg-white/10 rounded-xl p-4 mb-6 w-full backdrop-blur-sm">
            <div className="flex items-center gap-3">
              <div className="bg-gradient-to-r from-indigo-500 to-purple-500 p-2 rounded-lg">
                <Clock className="h-5 w-5 text-white" />
              </div>
              <div className="flex-1">
                <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  System will be back in
                </p>
                <p className="text-lg font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
                  {calculateTimeRemaining()}
                </p>
              </div>
              <motion.div
                animate={{ rotate: [0, 360] }}
                transition={{ repeat: Infinity, duration: 60, ease: "linear" }}
              >
                <Beaker className="h-6 w-6 text-purple-500/70" />
              </motion.div>
            </div>
          </div>
          
          <div className="space-y-3 w-full">
            <Button 
              onClick={() => navigate('/')}
              className="w-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:from-indigo-600 hover:via-purple-600 hover:to-pink-600 text-white font-medium py-6 rounded-xl transition-all duration-300 shadow-md hover:shadow-lg flex items-center justify-center gap-2"
            >
              <Home className="h-5 w-5" />
              Return to Home
            </Button>
            
            <Button
              onClick={() => navigate('/venues')}
              variant="outline"
              className="w-full border border-indigo-200 dark:border-indigo-800 text-gray-700 dark:text-gray-300 hover:bg-indigo-50 dark:hover:bg-indigo-900/20 rounded-xl py-5"
            >
              Browse Venues
            </Button>
          </div>
          
          <div className="flex space-x-3 mt-6">
            {[0, 1, 2].map((dot) => (
              <motion.div
                key={dot}
                className="w-2 h-2 rounded-full bg-gradient-to-r from-indigo-400 to-purple-400"
                animate={{
                  opacity: [0.3, 1, 0.3],
                  scale: [0.8, 1.2, 0.8],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  delay: dot * 0.2,
                }}
              />
            ))}
          </div>
          
          <p className="text-xs text-gray-500 mt-6 max-w-xs">
            We're enhancing your booking experience while you sleep. You'll enjoy a smoother, faster system when you return!
          </p>
        </div>
      </motion.div>
    </div>
  );
};
