
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/hooks/use-mobile";

interface BackButtonProps {
  className?: string;
  onClick?: () => void;
}

const BackButton = ({ className = "", onClick }: BackButtonProps) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const location = useLocation();
  
  // Only show on mobile and on specific pages
  if (!isMobile || (location.pathname.includes('/book-venue/') && !location.pathname.includes('/search'))) return null;
  
  // Handle navigation
  const handleBackNavigation = () => {
    if (onClick) {
      onClick();
    } else if (location.pathname === '/search') {
      navigate('/'); // If on search page, go to home
    } else {
      navigate(-1); // Otherwise go back in history
    }
  };
  
  return (
    <Button
      onClick={handleBackNavigation}
      variant="ghost"
      size="sm"
      className={`md:hidden group flex items-center justify-center p-2 rounded-full backdrop-blur-sm bg-white/80 hover:bg-white/90 shadow-md transition-all duration-300 z-10 ${className}`}
      aria-label="Go back"
    >
      <ArrowLeft className="h-5 w-5 text-primary group-hover:text-primary/80 transition-transform group-hover:-translate-x-0.5" />
    </Button>
  );
};

export default BackButton;
