
export const BadmintonIcon = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 512 512"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="256" cy="256" r="256" fill="#00CC88" />
    <g>
      <path d="M366.5,381.6c0,0-11.5-108.9-13.2-126c-1.7-17.1-79.9-147.9-79.9-147.9s-14.9-6.6-14.9,9.9
        c0,16.5,83.2,144.5,83.2,144.5l17.1,119.4H366.5z" fill="#E82074" />
      <path d="M145.5,381.6c0,0,11.5-108.9,13.2-126c1.7-17.1,79.9-147.9,79.9-147.9s14.9-6.6,14.9,9.9c0,16.5-83.2,144.5-83.2,144.5
        l-17.1,119.4H145.5z" fill="#0066BB" />
      <ellipse cx="158" cy="107.4" rx="74.7" ry="99.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -22.6741 144.9919)" fill="#ffffff" stroke="#0066BB" stroke-width="12" />
      <ellipse cx="354" cy="107.4" rx="74.7" ry="99.4" transform="matrix(0.7071 0.7071 -0.7071 0.7071 175.9919 -226.6741)" fill="#ffffff" stroke="#E82074" stroke-width="12" />
      <path d="M256,304L256,304c-7.3,0-13.2-5.9-13.2-13.2V224c0-7.3,5.9-13.2,13.2-13.2h0c7.3,0,13.2,5.9,13.2,13.2v66.8
        C269.2,298.1,263.3,304,256,304z" fill="#B3E0FF" />
      <circle cx="255.7" cy="316.5" r="45.9" fill="#B3E0FF" />
      <path d="M255.7,352.4h-9.9c0,0-13.2,3.3-13.2,9.9c0,6.6,2.5,9.9,5,9.9c2.5,0,18.1,0,18.1,0s15.7,0,18.1,0
        c2.5,0,5-3.3,5-9.9c0-6.6-13.2-9.9-13.2-9.9H255.7z" fill="#FFA533" />
      <line x1="256" y1="226.5" x2="256" y2="290.8" stroke="#FFFFFF" stroke-width="6"/>
    </g>
  </svg>
);

export const CricketIcon = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 512 512"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <circle cx="256" cy="256" r="170" fill="#ff5533" />
      <path d="M256 100C167.2 100 95 172.2 95 261s72.2 161 161 161 161-72.2 161-161S344.8 100 256 100zm0 291.8c-72.3 0-130.8-58.5-130.8-130.8S183.7 130.2 256 130.2 386.8 188.7 386.8 261 328.3 391.8 256 391.8z" fill="#ffffff" />
      <path d="M323 189c-18.4-18.4-48.3-18.4-66.8 0l-11.1 11.1c-18.4 18.4-18.4 48.3 0 66.8l134 134c18.4 18.4 48.3 18.4 66.8 0l11.1-11.1c18.4-18.4 18.4-48.3 0-66.8L323 189z" fill="#ffffff" stroke="#000000" stroke-width="8" />
      <circle cx="256" cy="261" r="50" fill="#ffffff" stroke="#000000" stroke-width="8" />
      <path d="M256 211c-27.6 0-50 22.4-50 50s22.4 50 50 50 50-22.4 50-50-22.4-50-50-50zm0 80c-16.6 0-30-13.4-30-30s13.4-30 30-30 30 13.4 30 30-13.4 30-30 30z" fill="#ff0000" />
      <path d="M256 261v-20m0 40v-20m-20-20h40m-40 40h40" stroke="#ffffff" stroke-width="5" />
      <path d="M330 230l-60 60" stroke="#ffffff" stroke-width="6" />
      <path d="M190 330l-30 30" stroke="#ffffff" stroke-width="6" />
      <path d="M220 310l-60 60" stroke="#ffffff" stroke-width="6" />
    </g>
  </svg>
);

export const PickleballIcon = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 512 512"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="rotate(45 256 256)">
      <rect x="125" y="100" width="262" height="312" rx="30" ry="30" fill="#003380" stroke="#003380" stroke-width="10" />
      <path d="M176 100h160v200a80 80 0 01-80 80h0a80 80 0 01-80-80V100z" fill="#66CCDD" />
      <rect x="176" y="100" width="160" height="110" fill="#FFBB44" />
      <rect x="176" y="320" width="160" height="60" fill="#A060FF" />
      <rect x="176" y="380" width="160" height="32" fill="#663399" />
      <rect x="195" y="380" width="10" height="32" fill="#7744BB" />
      <rect x="225" y="380" width="10" height="32" fill="#7744BB" />
      <rect x="255" y="380" width="10" height="32" fill="#7744BB" />
      <rect x="285" y="380" width="10" height="32" fill="#7744BB" />
      <rect x="315" y="380" width="10" height="32" fill="#7744BB" />
    </g>
  </svg>
);

export const FootballIcon = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 512 512"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <radialGradient id="goldBallGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
        <stop offset="0%" style={{ stopColor: "#FFD700" }} />
        <stop offset="80%" style={{ stopColor: "#FFA500" }} />
        <stop offset="100%" style={{ stopColor: "#FF8C00" }} />
      </radialGradient>
    </defs>
    
    <circle cx="256" cy="256" r="240" fill="url(#goldBallGradient)" stroke="#B8860B" stroke-width="5" />
    
    <path d="M256 56v60M256 396v60M116 256H56M456 256h-60M160 160l-42-42M394 394l-42-42M160 352l-42 42M394 118l-42 42" stroke="#B8860B" stroke-width="8" />
    
    <path d="M226 106h60l40 40v60l-40 40h-60l-40-40v-60z" fill="#B8860B" />
    <path d="M226 266h60l40 40v60l-40 40h-60l-40-40v-60z" fill="#B8860B" />
    <path d="M106 226v60l40 40h60l40-40v-60l-40-40h-60z" fill="#B8860B" />
    <path d="M266 226v60l40 40h60l40-40v-60l-40-40h-60z" fill="#B8860B" />
    
    <circle cx="256" cy="256" r="200" fill="none" stroke="#B8860B" stroke-width="3" stroke-dasharray="15,15" />
    
    <circle cx="256" cy="256" r="60" fill="none" stroke="#B8860B" stroke-width="3" />
    
    <path d="M196 256h120M256 196v120" stroke="#B8860B" stroke-width="3" />
  </svg>
);

export const TennisIcon = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 512 512"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="512" height="512" rx="256" fill="#FFFFFF" />
    <g transform="translate(60, 25) scale(0.8)">
      <path fill="#FF7043" d="M131.9 416.5L131.9 416.5c-9.9 9.9-26 9.9-35.9 0L49.5 370c-9.9-9.9-9.9-26 0-35.9l0 0c9.9-9.9 26-9.9 35.9 0l46.5 46.5C141.8 390.5 141.8 406.6 131.9 416.5z" />
      <path fill="#1E88E5" d="M482 183.8c0 97.3-78.8 176.1-176.1 176.1-63.8 0-119.7-33.9-150.5-84.7l-23.3 44.4 0 0 0 0-46.5-46.5 0 0 0 0 42.3-22.1c-25.1-77 15.5-160.2 92.5-185.2 77-25.1 160.2 15.5 185.2 92.5C477.3 171.8 482 177.7 482 183.8z" />
      <path fill="#FFF9C4" d="M457.5 114.4c-25.1-77-108.2-117.6-185.2-92.5 -32.2 10.5-58.7 32.3-75.4 61.3 50.5-20 107.2-14.3 151.8 14.7 60.8 39.7 93.1 109.7 83.9 179.3 15.7-20.1 26.3-44.3 30.3-70.7C477.3 171.8 482 177.7 482 183.8c0 97.3-78.8 176.1-176.1 176.1 4.6 0 9.1-0.2 13.6-0.6 92.9-8.8 164.9-86.8 164.9-181.7C484.5 157.3 474.5 133.7 457.5 114.4z" />
      <path fill="#FFFFFF" d="M369.1 83.7C322.1 53.1 261.9 49.7 211.5 71.3c-22.5 44.9-18 99.8 13.5 140 46.1 58.8 131.3 69.1 190.1 23 13.9-10.9 25-24.1 33.1-38.9C440.4 125.1 408.2 53.5 369.1 83.7zM217.4 203.3c-31.5-40.2-36-95.1-13.5-140 -24.2 10.4-44.8 28.5-59.1 51.8 -11.2 33.6-8.4 71.3 8.8 103.2C189.6 229.7 208.1 218.1 217.4 203.3zM368.7 89.9c35.1 27.5 56.4 72.1 51.7 119.5 18.5-14.5 30.8-35.7 34.4-58.6 0-0.1 0-0.2 0-0.3 0.8-5 1.1-10.1 1.1-15.2C434.2 112.7 407.1 78.8 368.7 89.9z" />
      <path opacity="0.2" fill="#424242" d="M482 183.8c0 97.3-78.8 176.1-176.1 176.1-63.8 0-119.7-33.9-150.5-84.7l-23.3 44.4 0 0 0 0-46.5-46.5 0 0 0 0 42.3-22.1c-25.1-77 15.5-160.2 92.5-185.2 77-25.1 160.2 15.5 185.2 92.5C477.3 171.8 482 177.7 482 183.8zM135.9 90.3c-10.9 18.3-17.8 38.2-20.7 58.6 -32.1 51.3-24.3 120.2 18.5 163 -10.9 10.9-28.6 10.9-39.5 0l-46.5-46.5c-10.9-10.9-10.9-28.6 0-39.5L135.9 90.3z" />
      <path opacity="0.1" fill="#424242" d="M133.9 272.2l-2 3.9 -23.3 44.4 -3.1-3.1 22.3-42.4C129.6 273.9 131.7 273 133.9 272.2zM115.5 261.8C90.4 184.8 131 101.6 208 76.5c77-25.1 160.2 15.5 185.2 92.5 7.7 13.5 12.3 19.5 12.4 25.6 -0.1-5.1-4.7-11-12.4-24.6 -25.1-77-108.2-117.6-185.2-92.5 -77 25.1-117.6 108.2-92.5 185.2 0.2 0.7 0.5 1.4 0.8 2.1L115.5 261.8z" />
      <path opacity="0.1" fill="#424242" d="M131.9 417.5c-10.5 10.5-27.4 10.5-37.9 0l-46.5-46.5c-10.5-10.5-10.5-27.4 0-37.9 10.5-10.5 27.4-10.5 37.9 0l46.5 46.5C142.3 390.1 142.3 407 131.9 417.5zM131.9 415.5c9.9 9.9 26 9.9 35.9 0l0 0c9.9-9.9 9.9-26 0-35.9l-46.5-46.5c-9.9-9.9-26-9.9-35.9 0l0 0c-9.9 9.9-9.9 26 0 35.9L131.9 415.5z" />
      <circle fill="#FFD54F" cx="305.9" cy="183.8" r="104.7"/>
      <path fill="#1565C0" d="M449.6 183.8c0 79.5-64.4 144-144 144 -57.6 0-107.5-33.9-130.9-82.9L131.9 318 81.9 268l73.2-38.2c-16.8-75.4 31.3-149.6 106.8-166.4 75.4-16.8 149.6 31.3 166.4 106.8C435.5 171.8 449.6 177.7 449.6 183.8z"/>
      <path fill="#E3F2FD" d="M369.1 83.7C322.1 53.1 261.9 49.7 211.5 71.3c-22.5 44.9-18 99.8 13.5 140 46.1 58.8 131.3 69.1 190.1 23 13.9-10.9 25-24.1 33.1-38.9C440.4 125.1 408.2 53.5 369.1 83.7zM217.4 203.3c-31.5-40.2-36-95.1-13.5-140 -24.2 10.4-44.8 28.5-59.1 51.8 -11.2 33.6-8.4 71.3 8.8 103.2C189.6 229.7 208.1 218.1 217.4 203.3zM368.7 89.9c35.1 27.5 56.4 72.1 51.7 119.5 18.5-14.5 30.8-35.7 34.4-58.6 0-0.1 0-0.2 0-0.3 0.8-5 1.1-10.1 1.1-15.2C434.2 112.7 407.1 78.8 368.7 89.9z"/>
    </g>
  </svg>
);

export const SquashIcon = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 512 512"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="256" cy="256" r="240" fill="#8B5CF6" stroke="#000000" stroke-width="8"/>
    <g transform="translate(100, 65) scale(0.7)">
      <path d="M215,65c-45.5,0-82.5,37-82.5,82.5c0,56,75,210,82.5,210s82.5-154,82.5-210C297.5,102,260.5,65,215,65z" fill="#FFFFFF" stroke="#000000" stroke-width="12"/>
      <path d="M215,65c-45.5,0-82.5,37-82.5,82.5c0,56,75,210,82.5,210s82.5-154,82.5-210C297.5,102,260.5,65,215,65z" fill="none" stroke="#000000" stroke-width="12"/>
      <path d="M215,100v223" stroke="#000000" stroke-width="8"/>
      <path d="M180,147.5h70" stroke="#000000" stroke-width="8"/>
      <path d="M180,180h70" stroke="#000000" stroke-width="8"/>
      <path d="M180,212.5h70" stroke="#000000" stroke-width="8"/>
      <path d="M180,245h70" stroke="#000000" stroke-width="8"/>
      <path d="M170,340l-70,120" stroke="#000000" stroke-width="12" stroke-linecap="round"/>
      <rect x="80" y="450" width="50" height="20" fill="#FFB8B8" stroke="#000000" stroke-width="6"/>
    </g>
    <circle cx="340" cy="340" r="50" fill="#FFB8B8" stroke="#000000" stroke-width="6"/>
  </svg>
);

export const BasketballIcon = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 512 512"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="256" cy="256" r="240" fill="#000000" stroke="#000000" stroke-width="8"/>
    <circle cx="256" cy="256" r="220" fill="#FF9D42" stroke="#000000" stroke-width="6"/>
    <path d="M256,36 v440" stroke="#000000" stroke-width="6" />
    <path d="M36,256 h440" stroke="#000000" stroke-width="6" />
    <path d="M107,107 C154,154 154,358 107,405" stroke="#000000" stroke-width="6" />
    <path d="M405,107 C358,154 358,358 405,405" stroke="#000000" stroke-width="6" />
    <path d="M256,36 C154,36 80,125 80,256 C80,387 154,476 256,476" fill="none" stroke="#000000" stroke-width="3" />
    <path d="M256,36 C358,36 432,125 432,256 C432,387 358,476 256,476" fill="none" stroke="#000000" stroke-width="3" />
    <path d="M180,36 C128,80 100,160 100,256 C100,352 128,432 180,476" fill="none" stroke="#000000" stroke-width="2" />
    <path d="M332,36 C384,80 412,160 412,256 C412,352 384,432 332,476" fill="none" stroke="#000000" stroke-width="2" />
    <circle cx="256" cy="256" r="220" fill="none" stroke="#000000" stroke-width="6"/>
  </svg>
);
