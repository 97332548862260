import { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { initiatePayment } from "@/services/paymentService";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import { BookingConfirmDialog } from "./BookingConfirmDialog";
import { useIsMobile } from "@/hooks/use-mobile";
import { Loader2 } from "lucide-react";
import { TimeoutNotification } from "@/components/ui/timeout-notification";

interface BookingFormProps {
  bookingId: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  specialRequests: string;
  totalAmount: {
    subtotal: number;
    processingFee: number;
    gst: number;
    total: number;
  };
  onContactNameChange: (value: string) => void;
  onContactEmailChange: (value: string) => void;
  onContactPhoneChange: (value: string) => void;
  onSpecialRequestsChange: (value: string) => void;
  onSubmit: () => Promise<void>;
  isValid: boolean;
  venueName?: string;
  facilityName?: string;
  bookingDate?: Date;
  selectedTimeSlots?: Array<{id: string; start_time: string; facility_id?: string;}>;
  selectedFacilitiesMap?: Record<string, { 
    facilityName: string; 
    slots: Array<{id: string; start_time: string;}>;
  }>;
  formRef?: React.RefObject<HTMLDivElement>;
}

export const BookingForm = ({
  bookingId,
  contactName,
  contactEmail,
  contactPhone,
  specialRequests,
  totalAmount,
  onContactNameChange,
  onContactEmailChange,
  onContactPhoneChange,
  onSpecialRequestsChange,
  onSubmit,
  isValid,
  venueName = "",
  facilityName = "",
  bookingDate = new Date(),
  selectedTimeSlots = [],
  selectedFacilitiesMap = {},
  formRef
}: BookingFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const isMobile = useIsMobile();
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [formFieldsAutoFilled, setFormFieldsAutoFilled] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user) {
        const { data: profile, error } = await supabase
          .from('profiles')
          .select('full_name, email, phone')
          .eq('id', user.id)
          .single();

        if (!error && profile) {
          let fieldsAutoFilled = false;
          
          if (profile.full_name) {
            onContactNameChange(profile.full_name);
          }
          
          if (profile.email) {
            onContactEmailChange(profile.email);
            fieldsAutoFilled = true;
          }
          
          if (profile.phone) {
            let phoneNumber = profile.phone;
            
            if (phoneNumber.length >= 12 && phoneNumber.startsWith('+91')) {
              phoneNumber = phoneNumber.substring(3);
            } else if (phoneNumber.length >= 12 && phoneNumber.startsWith('91')) {
              phoneNumber = phoneNumber.substring(2);
            }
            
            phoneNumber = phoneNumber.slice(0, 10);
            
            onContactPhoneChange(phoneNumber);
            fieldsAutoFilled = true;
          }
          
          setFormFieldsAutoFilled(fieldsAutoFilled);
        }
      }
    };

    fetchUserProfile();
    
    const autoFillCheckTimer = setTimeout(() => {
      if (user && !contactEmail && !contactPhone) {
        console.log("Form fields not auto-filled after 5 seconds, refreshing page...");
        window.location.reload();
      }
    }, 5000);
    
    return () => clearTimeout(autoFillCheckTimer);
  }, [user, contactEmail, contactPhone]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone: string) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const handlePhoneChange = (value: string) => {
    const numbersOnly = value.replace(/[^\d]/g, '').slice(0, 10);
    onContactPhoneChange(numbersOnly);
  };

  const selectedFacilitiesArray = Object.values(selectedFacilitiesMap);

  const handleInitiatePayment = () => {
    if (!user) {
      navigate("/auth", { state: { returnUrl: window.location.pathname + window.location.search } });
      return;
    }

    if (!contactName.trim()) {
      toast.error("Please enter your name");
      return;
    }

    if (!validateEmail(contactEmail)) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (!validatePhone(contactPhone)) {
      toast.error("Please enter a valid 10-digit phone number");
      return;
    }

    if (user && !formFieldsAutoFilled) {
      toast.info("Refreshing page to load your contact information...");
      window.location.reload();
      return;
    }

    setShowConfirmDialog(true);
  };

  const handlePayment = async () => {
    setShowConfirmDialog(false);
    setIsPaymentLoading(true);
    
    if (!user) {
      console.log("Guest user detected, redirecting to login");
      navigate("/auth", { state: { returnUrl: window.location.pathname } });
      return;
    }

    try {
      console.log("Starting payment process for logged-in user...");
      
      await onSubmit();
      
      console.log("Booking saved, initiating payment...");
      
      if (isMobile) {
        console.log("Mobile user detected, setting 4-second redirect timeout");
        initiatePayment({
          bookingId,
          amount: totalAmount.total,
          customerName: contactName,
          customerEmail: contactEmail,
          customerPhone: contactPhone,
          venueName,
          facilityName,
          bookingDate: bookingDate.toISOString().split('T')[0],
          startTime: selectedTimeSlots.length > 0 ? new Date(selectedTimeSlots[0].start_time).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }) : '',
          endTime: selectedTimeSlots.length > 0 ? new Date(selectedTimeSlots[selectedTimeSlots.length - 1].start_time).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }) : '',
          venueId: bookingId
        }).then(() => {
          setIsPaymentLoading(false);
        }).catch(err => {
          console.error("Background payment process error:", err);
          setIsPaymentLoading(false);
        });
        
        setTimeout(() => {
          console.log("Mobile timeout triggered, redirecting to confirmation page");
          toast.info("Redirecting to booking confirmation...");
          navigate(`/booking-confirmation?status=success&txnid=${bookingId}`);
        }, 4000);
      } else {
        try {
          const result = await initiatePayment({
            bookingId,
            amount: totalAmount.total,
            customerName: contactName,
            customerEmail: contactEmail,
            customerPhone: contactPhone,
            venueName,
            facilityName,
            bookingDate: bookingDate.toISOString().split('T')[0],
            startTime: selectedTimeSlots.length > 0 ? new Date(selectedTimeSlots[0].start_time).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }) : '',
            endTime: selectedTimeSlots.length > 0 ? new Date(selectedTimeSlots[selectedTimeSlots.length - 1].start_time).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }) : '',
            venueId: bookingId
          });
          
          setIsPaymentLoading(false);
          
          if (!result) {
            console.error("Payment initiation failed");
            toast.error("Failed to initiate payment. Please try again.");
          }
        } catch (error) {
          console.error('Payment error:', error);
          setIsPaymentLoading(false);
          toast.error("An error occurred while processing your payment. Please try again.");
        }
      }
    } catch (error) {
      console.error('Payment error:', error);
      toast.error("An error occurred while processing your payment. Please try again.");
      setIsPaymentLoading(false);
    }
  };

  return (
    <>
      <Card ref={formRef}>
        <CardHeader>
          <CardTitle className="text-gray-900">Contact Information</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <Input
              placeholder="Your Name"
              value={contactName}
              onChange={(e) => onContactNameChange(e.target.value)}
            />
            <Input
              type="email"
              placeholder="Email"
              value={contactEmail}
              onChange={(e) => onContactEmailChange(e.target.value)}
              className={!validateEmail(contactEmail) && contactEmail ? "border-red-500" : ""}
            />
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-500">
                +91
              </div>
              <Input
                type="tel"
                placeholder="Phone (10 digits)"
                value={contactPhone}
                onChange={(e) => handlePhoneChange(e.target.value)}
                className={`pl-10 ${!validatePhone(contactPhone) && contactPhone ? "border-red-500" : ""}`}
              />
            </div>
            {contactPhone && !validatePhone(contactPhone) && (
              <p className="text-red-500 text-xs mt-1">Please enter a valid 10-digit phone number</p>
            )}
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-900">Special Requests</label>
            <Textarea
              placeholder="Any special requirements?"
              value={specialRequests}
              onChange={(e) => onSpecialRequestsChange(e.target.value)}
            />
          </div>

          <div className="pt-4 border-t">
            <div className="space-y-2 mb-4">
              <div className="flex justify-between text-sm">
                <span className="text-muted-foreground">Subtotal:</span>
                <span>₹{totalAmount.subtotal}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-muted-foreground">Processing Fee (2%):</span>
                <span>₹{totalAmount.processingFee}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-muted-foreground">GST (18%):</span>
                <span>₹{totalAmount.gst}</span>
              </div>
              <div className="flex justify-between font-medium pt-2 border-t">
                <span>Total Amount:</span>
                <span className="font-bold">₹{totalAmount.total}</span>
              </div>
            </div>
            <Button 
              className="w-full bg-primary hover:bg-primary/90"
              onClick={handleInitiatePayment}
              disabled={user ? !isValid || isPaymentLoading : false}
            >
              {user ? (isPaymentLoading ? (
                <span className="flex items-center justify-center">
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Proceed to Pay
                </span>
              ) : 'Proceed to Pay') : 'Sign in to Book'}
            </Button>
          </div>
        </CardContent>
        
        <BookingConfirmDialog 
          isOpen={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={handlePayment}
          venueName={venueName}
          facilityName={facilityName}
          bookingDate={bookingDate}
          startTimes={selectedTimeSlots.map(slot => slot.start_time)}
          totalAmount={totalAmount.total}
          selectedFacilities={selectedFacilitiesArray}
        />
      </Card>

      {isPaymentLoading && (
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black/60 backdrop-blur-sm">
          <div className="relative flex flex-col items-center p-8 rounded-xl bg-white dark:bg-gray-800 shadow-2xl max-w-sm mx-auto">
            <div className="relative w-24 h-24 mb-6">
              <div className="absolute inset-0 rounded-full bg-gradient-to-r from-purple-500 to-blue-500 opacity-70 animate-pulse"></div>
              <div className="absolute inset-2 rounded-full bg-white dark:bg-gray-800 flex items-center justify-center">
                <svg className="w-12 h-12 text-blue-500 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>
            <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">Waiting for Payment Gateway</h3>
            <p className="text-gray-600 dark:text-gray-300 text-center mb-4">The payment gateway is opening. Please don't close this window.</p>
            <p className="text-gray-600 dark:text-gray-300 text-center mb-4">Please keep the app open until you see a confirmation and receive an SMS.</p>
            <div className="flex space-x-3">
              <div className="w-2.5 h-2.5 rounded-full bg-blue-600 animate-bounce" style={{ animationDelay: "0s" }}></div>
              <div className="w-2.5 h-2.5 rounded-full bg-purple-600 animate-bounce" style={{ animationDelay: "0.2s" }}></div>
              <div className="w-2.5 h-2.5 rounded-full bg-indigo-600 animate-bounce" style={{ animationDelay: "0.4s" }}></div>
            </div>
            
            <TimeoutNotification 
              message="This is taking longer than expected. We'll refresh the app for you."
              isMobile={false}
              timeout={3500}
            />
          </div>
        </div>
      )}
    </>
  );
};
