
import { useState, useEffect } from "react";
import { format } from "date-fns";
import { DateSelector } from "@/components/booking/DateSelector";
import { TimeSlots } from "@/components/booking/TimeSlots";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Calendar, Clock, CheckCircle, RefreshCw, Loader2, Mail, MessageSquare, ArrowLeft } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

interface AvailabilitySlot {
  id: string;
  start_time: string;
  end_time: string;
  is_booked: boolean;
  facility_id: string;
}

interface RescheduleDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  booking: {
    id: string;
    venue_id: string;
    facility_name: string;
    booking_date: string;
    start_time: string;
    end_time: string;
    total_amount: number;
  };
  onRescheduleComplete: (updatedBooking?: {
    id: string;
    booking_date: string;
    start_time: string;
    end_time: string;
  }) => void;
  userEmail?: string;
  userName?: string;
  userPhone?: string;
}

export const RescheduleDialog = ({
  open,
  onOpenChange,
  booking,
  onRescheduleComplete,
  userEmail,
  userName,
  userPhone
}: RescheduleDialogProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [availableSlots, setAvailableSlots] = useState<AvailabilitySlot[]>([]);
  const [selectedSlots, setSelectedSlots] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [facilityId, setFacilityId] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stage, setStage] = useState<'date' | 'time' | 'confirmation'>('date');

  useEffect(() => {
    if (open && booking) {
      const bookingDate = new Date(booking.booking_date);
      bookingDate.setHours(12, 0, 0, 0);
      setSelectedDate(bookingDate);
    }
  }, [open, booking]);

  useEffect(() => {
    if (open && booking) {
      fetchFacilityId();
    }
  }, [open, booking]);

  useEffect(() => {
    if (selectedDate && facilityId) {
      fetchAvailableSlots();
    }
  }, [selectedDate, facilityId]);

  const fetchFacilityId = async () => {
    try {
      const { data, error } = await supabase
        .from("facilities")
        .select("id")
        .eq("venue_id", booking.venue_id)
        .eq("name", booking.facility_name)
        .single();

      if (error) {
        throw error;
      }

      setFacilityId(data.id);
    } catch (error) {
      console.error("Error fetching facility:", error);
      toast.error("Unable to load facility information");
    }
  };

  const fetchAvailableSlots = async () => {
    if (!selectedDate || !facilityId) return;

    setIsLoading(true);
    try {
      const formattedDate = selectedDate.toISOString().split("T")[0];

      const startOfDay = new Date(formattedDate);
      startOfDay.setUTCHours(0, 0, 0, 0);
      
      const endOfDay = new Date(formattedDate);
      endOfDay.setUTCHours(23, 59, 59, 999);
      
      const { data, error } = await supabase
        .from("availability_slots")
        .select("*")
        .eq("facility_id", facilityId)
        .gte("start_time", startOfDay.toISOString())
        .lte("start_time", endOfDay.toISOString())
        .order("start_time");

      if (error) {
        throw error;
      }

      setAvailableSlots(data || []);
      setSelectedSlots([]);
    } catch (error) {
      console.error("Error fetching available slots:", error);
      toast.error("Unable to load available time slots");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    setStage('time');
  };

  const handleSlotSelect = (slotId: string) => {
    setSelectedSlots(prev => 
      prev.includes(slotId) ? [] : [slotId]
    );
  };

  const handleClearSelections = () => {
    setSelectedSlots([]);
  };

  const handleReschedule = async () => {
    if (!selectedSlots.length || !selectedDate) {
      toast.error("Please select a new time slot");
      return;
    }

    setIsSubmitting(true);
    try {
      console.log("=== RESCHEDULE DEBUG INFO ===");
      console.log("Selected date:", selectedDate);
      console.log("Selected slots:", selectedSlots);
      
      const selectedSlot = availableSlots.find(slot => slot.id === selectedSlots[0]);
      if (!selectedSlot) {
        const errorMsg = "Selected slot not found in available slots";
        console.error(errorMsg, {availableSlots, selectedSlots});
        toast.error(errorMsg);
        throw new Error(errorMsg);
      }
      
      const newTime = new Date(selectedSlot.start_time);
      const hours = newTime.getHours().toString().padStart(2, '0');
      const minutes = newTime.getMinutes().toString().padStart(2, '0');
      const newTimeString = `${hours}:${minutes}`;

      const endTime = new Date(newTime);
      endTime.setHours(endTime.getHours() + 1);
      const endHours = endTime.getHours().toString().padStart(2, '0');
      const endMinutes = endTime.getMinutes().toString().padStart(2, '0');
      const newEndTimeString = `${endHours}:${endMinutes}`;

      const formattedDate = selectedDate.toISOString().split("T")[0];
      
      console.log("New booking time details:");
      console.log("- Formatted date:", formattedDate);
      console.log("- New start time:", newTimeString);
      console.log("- New end time:", newEndTimeString);

      const unblockResponse = await supabase
        .rpc('admin_unblock_slots', { 
          slot_ids: [booking.id] 
        });
        
      console.log("Full unblock response:", unblockResponse);
      
      if (unblockResponse.error) {
        console.error("Error unblocking old slot:", unblockResponse.error);
        console.error("Error details:", JSON.stringify(unblockResponse.error, null, 2));
        toast.error(`Failed to free up your previous booking time: ${unblockResponse.error.message}`);
        throw unblockResponse.error;
      }
      
      console.log("Unblock result successful:", unblockResponse.data);

      const updateResponse = await supabase
        .from("bookings")
        .update({
          booking_date: formattedDate,
          start_time: newTimeString,
          end_time: newEndTimeString,
          updated_at: new Date().toISOString()
        })
        .eq("id", booking.id)
        .select();
        
      console.log("Full update response:", updateResponse);

      if (updateResponse.error) {
        console.error("Error updating booking:", updateResponse.error);
        console.error("Error details:", JSON.stringify(updateResponse.error, null, 2));
        toast.error(`Failed to update your booking: ${updateResponse.error.message}`);
        throw updateResponse.error;
      }

      console.log("Booking updated successfully:", updateResponse.data);

      const blockResponse = await supabase
        .rpc('admin_block_slots', {
          slot_ids: [selectedSlot.id]
        });
        
      console.log("Full block response:", blockResponse);

      if (blockResponse.error) {
        console.error("Error blocking new slot:", blockResponse.error);
        console.error("Error details:", JSON.stringify(blockResponse.error, null, 2));
        toast.error(`Failed to secure your new booking time: ${blockResponse.error.message}`);
        throw blockResponse.error;
      }
      
      console.log("Block result successful:", blockResponse.data);

      const { data: venueData, error: venueError } = await supabase
        .from("venues")
        .select("name")
        .eq("id", booking.venue_id)
        .single();
        
      if (venueError) {
        console.warn("Unable to fetch venue name:", venueError);
      }
        
      const venueName = venueData?.name || "our venue";
      
      const customerInfo = {
        name: userName || "User",
        email: userEmail,
        phone: userPhone,
        venue_id: booking.venue_id,
        venue_name: venueName,
        facility_name: booking.facility_name,
        booking_date: formattedDate,
        start_time: newTimeString,
        end_time: newEndTimeString,
        total_amount: booking.total_amount,
        is_rescheduled: true,
        original_date: booking.booking_date,
        original_start_time: booking.start_time,
        original_end_time: booking.end_time
      };

      console.log("Sending booking confirmation notification for rescheduled booking");
      const notificationResponse = await supabase.functions.invoke("send-booking-confirmation", {
        body: { 
          bookingId: booking.id, 
          customerInfo,
          notificationType: "reschedule"
        }
      });
      
      console.log("Notification response:", notificationResponse);

      if (notificationResponse.error) {
        console.warn("Error sending notifications:", notificationResponse.error);
        console.warn("Error details:", JSON.stringify(notificationResponse.error, null, 2));
        // Continue anyway since the booking was successful
      } else {
        console.log("Notification sent successfully");
      }

      console.log("=== RESCHEDULE PROCESS COMPLETED SUCCESSFULLY ===");
      toast.success("Booking successfully rescheduled!");
      setStage('confirmation');
      
      const updatedBookingData = {
        id: booking.id,
        booking_date: formattedDate,
        start_time: newTimeString,
        end_time: newEndTimeString
      };
      
      setTimeout(() => {
        onRescheduleComplete(updatedBookingData);
        onOpenChange(false);
      }, 20000); // Changed from 5000 to 20000 milliseconds (20 seconds)
    } catch (error) {
      console.error("=== RESCHEDULE PROCESS FAILED ===");
      console.error("Error in rescheduling booking:", error);
      console.error("Error details:", JSON.stringify(error, null, 2));
      toast.error("Failed to reschedule booking. Please try again or contact support.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    if (stage === 'time') {
      setStage('date');
    } else if (stage === 'confirmation') {
      setStage('time');
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px] p-0 rounded-lg overflow-hidden">
        <div className="bg-gradient-to-r from-purple-600 to-indigo-600 p-6">
          <DialogHeader>
            <DialogTitle className="text-white text-xl font-bold flex items-center">
              <RefreshCw className="w-5 h-5 mr-2" />
              Reschedule Your Booking
            </DialogTitle>
            <DialogDescription className="text-purple-100">
              Select a new date and time for your booking
            </DialogDescription>
          </DialogHeader>
        </div>

        <div className="p-6 max-h-[70vh] overflow-y-auto">
          {stage === 'date' && (
            <div className="space-y-4">
              <h3 className="text-lg font-medium flex items-center">
                <Calendar className="w-5 h-5 mr-2 text-purple-500" />
                Select New Date
              </h3>
              <DateSelector
                selectedDate={selectedDate || new Date()}
                onDateChange={handleDateChange}
              />
            </div>
          )}

          {stage === 'time' && (
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-medium flex items-center">
                  <Clock className="w-5 h-5 mr-2 text-purple-500" />
                  Select New Time
                </h3>
                <Button 
                  variant="ghost" 
                  size="sm" 
                  onClick={handleBack}
                  className="text-sm text-purple-600"
                >
                  Back to Calendar
                </Button>
              </div>
              <div className="bg-purple-50 dark:bg-purple-900/10 rounded-lg p-4 mb-4">
                <p className="text-sm font-medium">
                  Selected Date: {selectedDate ? format(selectedDate, "MMMM d, yyyy") : "None"}
                </p>
              </div>
              {isLoading ? (
                <div className="flex justify-center p-12">
                  <Loader2 className="w-8 h-8 animate-spin text-purple-600" />
                </div>
              ) : availableSlots.length === 0 ? (
                <div className="text-center py-12 bg-gray-50 rounded-lg">
                  <p className="text-gray-600">No available slots for this date.</p>
                  <Button 
                    className="mt-4" 
                    variant="outline"
                    onClick={() => setStage('date')}
                  >
                    Select Another Date
                  </Button>
                </div>
              ) : (
                <TimeSlots
                  facilityName={booking.facility_name}
                  slots={availableSlots}
                  selectedSlots={selectedSlots}
                  onSlotSelect={handleSlotSelect}
                  pricePerHour={booking.total_amount}
                  onClearSelections={() => handleClearSelections()}
                />
              )}
            </div>
          )}

          {stage === 'confirmation' && (
            <div className="flex flex-col items-center py-6">
              <div className="w-20 h-20 bg-gradient-to-br from-green-100 to-emerald-200 rounded-full flex items-center justify-center mb-5 shadow-md">
                <CheckCircle className="w-12 h-12 text-green-600" />
              </div>
              
              <h3 className="text-2xl font-bold text-green-700 mb-3">Successfully Rescheduled!</h3>
              
              <div className="bg-gradient-to-r from-green-50 to-emerald-50 p-4 rounded-lg border border-green-100 w-full mb-4">
                <p className="text-green-800 text-center">
                  Your booking has been rescheduled successfully. The booking details in your account will be updated shortly.
                </p>
              </div>
              
              <div className="bg-amber-50 border border-amber-200 rounded-lg p-4 w-full mb-5">
                <div className="flex items-start">
                  <div className="flex-shrink-0 mt-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-amber-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2h-1V9z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <p className="ml-2 text-sm text-amber-800">
                    If needed, you can show the SMS or email confirmation as proof of your updated booking details.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col sm:flex-row gap-3 w-full mt-1">
                <div className="flex-1 bg-purple-50 border border-purple-100 rounded-lg p-3 flex items-center justify-center">
                  <Mail className="w-4 h-4 text-purple-600 mr-2" />
                  <span className="text-sm text-purple-800">
                    {userEmail ? "Email sent" : "No email available"}
                  </span>
                </div>
                <div className="flex-1 bg-blue-50 border border-blue-100 rounded-lg p-3 flex items-center justify-center">
                  <MessageSquare className="w-4 h-4 text-blue-600 mr-2" />
                  <span className="text-sm text-blue-800">
                    {userPhone ? "SMS sent" : "No phone available"}
                  </span>
                </div>
              </div>
              
              <Button 
                onClick={() => onOpenChange(false)}
                className="mt-6 bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white py-2 rounded-md shadow-md transition-all duration-300 w-full"
              >
                Return to My Bookings
              </Button>
            </div>
          )}
        </div>

        {stage === 'time' && (
          <DialogFooter className="p-6 pt-0">
            <Button
              onClick={handleReschedule}
              disabled={selectedSlots.length === 0 || isSubmitting}
              className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white py-2 rounded-md shadow-md transition-all duration-300"
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Rescheduling...
                </>
              ) : (
                <>Confirm Reschedule</>
              )}
            </Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
};
