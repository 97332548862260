
import { useState, useEffect } from 'react';
import { X, Heart, Copy, Gift } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '@/components/AuthProvider';
import { supabase } from '@/integrations/supabase/client';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { useIsMobile } from '@/hooks/use-mobile';

// Create a global state to track banner visibility
const globalBannerState = {
  isDiscountBannerVisible: false,
  isOffersBannerVisible: false
};

// Function to update global banner state
const updateGlobalBannerState = (key: 'isDiscountBannerVisible' | 'isOffersBannerVisible', value: boolean) => {
  globalBannerState[key] = value;
};

// Export the function to be used by OffersBanner
export const getGlobalBannerState = () => globalBannerState;

const DiscountBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useIsMobile();
  
  useEffect(() => {
    const checkDismissState = () => {
      if (!user) return false;
      
      const dismissedState = localStorage.getItem(`discount_banner_dismissed_${user.id}`);
      if (dismissedState) return true;
      
      return false;
    };
    
    const checkEligibility = async () => {
      if (!user) {
        setIsLoading(false);
        return;
      }
      
      try {
        if (checkDismissState()) {
          setIsLoading(false);
          return;
        }
        
        const { data: profile } = await supabase
          .from('profiles')
          .select('email, phone')
          .eq('id', user.id)
          .single();
          
        if (!profile) {
          setIsLoading(false);
          return;
        }
        
        let eligibleDiscount = null;
        
        if (profile.email) {
          const { data: emailDiscount } = await supabase
            .from('discount_eligible_users')
            .select('discount_code')
            .eq('email', profile.email)
            .is('expires_at', null)
            .order('created_at', { ascending: false })
            .limit(1);
            
          if (emailDiscount && emailDiscount.length > 0) {
            eligibleDiscount = emailDiscount[0];
          }
        }
        
        if (!eligibleDiscount && profile.phone) {
          const { data: phoneDiscount } = await supabase
            .from('discount_eligible_users')
            .select('discount_code')
            .eq('phone', profile.phone)
            .is('expires_at', null)
            .order('created_at', { ascending: false })
            .limit(1);
            
          if (phoneDiscount && phoneDiscount.length > 0) {
            eligibleDiscount = phoneDiscount[0];
          }
        }
        
        if (eligibleDiscount && !globalBannerState.isOffersBannerVisible) {
          setShowBanner(true);
          updateGlobalBannerState('isDiscountBannerVisible', true);
        }
      } catch (error) {
        console.error('Error checking discount eligibility:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    checkEligibility();

    return () => {
      updateGlobalBannerState('isDiscountBannerVisible', false);
    };
  }, [user]);
  
  const handleDismiss = () => {
    if (user) {
      localStorage.setItem(`discount_banner_dismissed_${user.id}`, 'true');
    }
    setShowBanner(false);
    updateGlobalBannerState('isDiscountBannerVisible', false);
  };
  
  if (isLoading || !showBanner) return null;
  
  return (
    <AnimatePresence>
      {showBanner && (
        <motion.div
          initial={{ opacity: 0, y: isMobile ? -50 : -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: isMobile ? -50 : -20 }}
          transition={{ duration: 0.4, ease: "easeOut" }}
          className={`fixed z-50 ${isMobile ? 'inset-x-0 top-1/2 -translate-y-1/2 px-4' : 'top-0 inset-x-0 py-3'}`}
        >
          <div className={`${isMobile ? 'w-full max-w-sm mx-auto' : 'container max-w-6xl mx-auto px-4'}`}>
            <div className={`
              relative overflow-hidden
              ${isMobile 
                ? 'rounded-2xl shadow-2xl p-5 bg-gradient-to-br from-purple-600 to-indigo-700' 
                : 'flex items-center justify-between py-3 px-4 bg-gradient-to-r from-violet-600 to-indigo-600 rounded-lg shadow-xl'
              }
            `}>
              
              {/* Mobile version */}
              {isMobile && (
                <>
                  <div className="absolute inset-0 overflow-hidden">
                    <div className="absolute top-5 left-5 w-6 h-6 animate-pulse opacity-30">
                      <Gift className="text-white" />
                    </div>
                    {/* Removed the bottom-right Gift icon as requested */}
                  </div>
                  
                  <button 
                    onClick={handleDismiss}
                    className="absolute top-2 right-2 p-1.5 rounded-full hover:bg-white/20 text-white/80 transition-colors"
                  >
                    <X className="h-4 w-4" />
                  </button>
                  
                  <div className="flex flex-col items-center text-center space-y-4">
                    <div className="flex items-center justify-center mb-1">
                      <Heart className="h-6 w-6 text-pink-300 mr-2 animate-pulse" />
                    </div>
                    
                    <div className="space-y-3">
                      <p className="text-white font-medium leading-tight">
                        Thank you for being one of our first users! We're still learning and growing, and we appreciate your patience.
                      </p>
                      
                      <div>
                        <p className="text-white/80 text-sm mb-2">Get 60% off on Badminton, Tennis, and Pickleball bookings!</p>
                        <a 
                          href="https://besportly.com/book-venue/a5fbab67-5516-4577-8440-cde9f6139b47"
                          className="block bg-white/10 backdrop-blur-sm border border-white/20 rounded-lg p-3 flex items-center justify-center mt-1 hover:bg-white/20 transition-colors"
                        >
                          <span className="font-medium text-white text-sm">Get Discount</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              )}
              
              {/* Desktop version */}
              {!isMobile && (
                <>
                  <div className="flex items-center space-x-3">
                    <div className="flex p-2 rounded-full bg-white/20">
                      <Heart className="h-5 w-5 text-pink-300 animate-pulse" />
                    </div>
                    <div>
                      <p className="text-white font-medium">
                        Thank you for being one of our first users! We're still learning and growing, and we appreciate your patience. Get 60% off on Badminton, Tennis, and Pickleball bookings!
                      </p>
                    </div>
                  </div>
                  
                  <div className="flex items-center space-x-2">
                    <a 
                      href="https://besportly.com/book-venue/a5fbab67-5516-4577-8440-cde9f6139b47"
                      className="px-4 py-1.5 bg-white/10 hover:bg-white/20 text-white rounded-full transition-colors"
                    >
                      Get Discount
                    </a>
                    <button 
                      onClick={handleDismiss}
                      className="p-1 rounded-full hover:bg-white/20 text-white transition-colors"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                </>
              )}
              
              {/* Light effects */}
              <div className="absolute -top-10 -left-10 w-40 h-40 bg-white/5 rounded-full blur-3xl"></div>
              <div className="absolute -bottom-8 -right-8 w-28 h-28 bg-pink-500/10 rounded-full blur-2xl"></div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DiscountBanner;
