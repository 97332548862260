
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/components/AuthProvider';
import { motion, AnimatePresence } from 'framer-motion';
import { Sparkles, X, Tag } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { getGlobalBannerState } from './DiscountBanner';
import { useIsMobile } from '@/hooks/use-mobile';

// Function to update global banner state
const updateGlobalBannerState = (key: 'isDiscountBannerVisible' | 'isOffersBannerVisible', value: boolean) => {
  const state = getGlobalBannerState();
  state[key] = value;
};

const OffersBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  
  useEffect(() => {
    // Only proceed if on mobile
    if (!isMobile) return;
    
    // Only show for authenticated users
    if (!isAuthenticated) return;
    
    // Show banner with 10% probability
    const shouldShow = Math.random() <= 0.1;
    
    if (shouldShow && !getGlobalBannerState().isDiscountBannerVisible) {
      // Small delay to ensure page is loaded first
      const timer = setTimeout(() => {
        setShowBanner(true);
        updateGlobalBannerState('isOffersBannerVisible', true);
      }, 500);
      
      return () => {
        clearTimeout(timer);
        updateGlobalBannerState('isOffersBannerVisible', false);
      };
    }
  }, [isAuthenticated, isMobile]);
  
  const handleClose = () => {
    setShowBanner(false);
    updateGlobalBannerState('isOffersBannerVisible', false);
  };
  
  const handleViewOffers = () => {
    navigate('/offers');
    setShowBanner(false);
    updateGlobalBannerState('isOffersBannerVisible', false);
  };
  
  if (!showBanner || !isMobile) return null;
  
  return (
    <AnimatePresence>
      {showBanner && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="fixed inset-0 flex items-center justify-center z-50 p-4 bg-black/30"
        >
          <div className="relative overflow-hidden rounded-xl shadow-lg max-w-md w-full">
            {/* Gradient background with pattern */}
            <div className="absolute inset-0 bg-gradient-to-r from-purple-600 via-violet-600 to-indigo-700 pattern-grid-lg opacity-100"></div>
            
            {/* Animated sparkle effects */}
            <div className="absolute top-1/4 left-1/4 animate-pulse">
              <Sparkles className="text-white/30 h-6 w-6" />
            </div>
            <div className="absolute bottom-1/3 right-1/3 animate-pulse" style={{animationDelay: '0.5s'}}>
              <Sparkles className="text-white/30 h-5 w-5" />
            </div>
            
            {/* Content */}
            <div className="relative p-4 flex flex-col items-center">
              <button 
                onClick={handleClose}
                className="absolute top-2 right-2 text-white/80 hover:text-white p-1 rounded-full hover:bg-white/10"
              >
                <X className="h-5 w-5" />
              </button>
              
              <div className="mb-3 bg-white/20 p-2 rounded-full backdrop-blur-md">
                <Tag className="h-8 w-8 text-white" />
              </div>
              
              <div className="text-center mb-4">
                <h3 className="text-xl font-bold text-white">Exclusive Offers!</h3>
                <p className="text-white/90 text-sm">Limited time discounts & cashbacks</p>
              </div>
              
              <Button 
                onClick={handleViewOffers}
                className="bg-white hover:bg-white/90 text-indigo-700 font-semibold px-5 py-2 rounded-full shadow-md"
              >
                View Offers
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OffersBanner;
