
import { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { Bot, Loader } from "lucide-react";
import { useToast } from "../ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "../AuthProvider";
import { ChatHeader } from "./ChatHeader";
import { MessageList } from "./MessageList";
import { ChatInput } from "./ChatInput";

const suggestedPrompts: Record<string, string[]> = {
  fitness: [
    "What's a good workout routine for beginners?",
    "How can I improve my running endurance?",
    "What should I eat before and after workouts?",
  ],
  nutrition: [
    "How can I plan a balanced meal?",
    "What are good protein sources for vegetarians?",
    "How many calories should I eat daily?",
  ],
  cricket: [
    "How to improve batting technique",
    "Best bowling exercises for pace",
    "Cricket fielding drills",
    "Match preparation tips",
    "Mental preparation for big games"
  ],
  football: [
    "Soccer dribbling techniques",
    "Improving ball control",
    "Stamina training for football",
    "Position-specific training tips",
    "Team strategy development"
  ],
  basketball: [
    "Shooting form improvement",
    "Basketball dribbling drills",
    "Jump shot techniques",
    "Defense positioning tips",
    "Team play strategies"
  ],
  tennis: [
    "Serve technique improvement",
    "Forehand stroke practice",
    "Tennis footwork drills",
    "Match strategy tips",
    "Court positioning advice"
  ],
  badminton: [
    "Improve your smash technique",
    "Footwork drills for better court coverage",
    "Developing effective drop shots",
    "Service techniques and variations",
    "Match strategy and game planning"
  ],
  squash: [
    "Basic squash techniques for beginners",
    "Advanced court movement patterns",
    "Improving your serve accuracy",
    "Wall practice drills and exercises",
    "Match tactics and shot selection"
  ]
};

interface ChatInterfaceProps {
  coachId: string;
  category: string;
  onClose: () => void;
}

export const ChatInterface = ({ coachId, category, onClose }: ChatInterfaceProps) => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<any[]>([]);
  const [conversationId, setConversationId] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const initializeChat = async () => {
      if (!user) {
        setIsInitializing(false);
        return;
      }
      await loadOrCreateConversation();
    };

    initializeChat();
  }, [coachId, user]);

  const loadOrCreateConversation = async () => {
    try {
      const { data: existingConversation } = await supabase
        .from('ai_chat_conversations')
        .select('id')
        .eq('user_id', user?.id)
        .eq('coach_id', coachId)
        .maybeSingle();

      if (existingConversation) {
        setConversationId(existingConversation.id);
        await loadMessages(existingConversation.id);
      } else {
        const { data: newConversation, error: createError } = await supabase
          .from('ai_chat_conversations')
          .insert([
            {
              user_id: user?.id,
              coach_id: coachId,
            }
          ])
          .select()
          .single();

        if (createError) throw createError;

        setConversationId(newConversation.id);
      }
    } catch (error) {
      console.error('Error in loadOrCreateConversation:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to initialize chat. Please try again.",
      });
    } finally {
      setIsInitializing(false);
    }
  };

  const loadMessages = async (convId: string) => {
    try {
      const { data, error } = await supabase
        .from('ai_chat_messages')
        .select('*')
        .eq('conversation_id', convId)
        .order('created_at', { ascending: true });

      if (error) throw error;
      setMessages(data || []);
    } catch (error) {
      console.error('Error loading messages:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load messages. Please try again.",
      });
    }
  };

  const handleSend = async (text?: string) => {
    const messageToSend = text || message;
    if (!messageToSend.trim() || !conversationId || isLoading) return;

    setIsLoading(true);
    try {
      // Insert user message
      const { error: userMessageError } = await supabase
        .from('ai_chat_messages')
        .insert([
          {
            conversation_id: conversationId,
            content: messageToSend,
            role: 'user'
          }
        ]);

      if (userMessageError) throw userMessageError;

      // Update UI immediately with user message
      setMessages(prev => [...prev, {
        id: Date.now(), // Temporary ID until we reload
        conversation_id: conversationId,
        content: messageToSend,
        role: 'user',
        created_at: new Date().toISOString()
      }]);
      
      setMessage(""); // Clear input field immediately after sending
      
      // Scroll to bottom
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);

      // Get AI response using Supabase Edge Function
      const { data, error: functionError } = await supabase.functions.invoke('ai-coach', {
        body: JSON.stringify({ message: messageToSend, category }),
      });

      if (functionError) throw functionError;
      
      const { error: aiMessageError } = await supabase
        .from('ai_chat_messages')
        .insert([
          {
            conversation_id: conversationId,
            content: data.generatedText,
            role: 'assistant'
          }
        ]);

      if (aiMessageError) throw aiMessageError;

      await loadMessages(conversationId);
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to send message. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteConversation = async () => {
    if (!conversationId) return;

    try {
      const { error } = await supabase
        .from('ai_chat_conversations')
        .delete()
        .eq('id', conversationId);

      if (error) throw error;

      onClose();
      toast({
        title: "Success",
        description: "Conversation deleted successfully",
      });
    } catch (error) {
      console.error('Error deleting conversation:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete conversation. Please try again.",
      });
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (!user) {
    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex flex-col h-[80vh] bg-white rounded-t-3xl shadow-2xl"
      >
        <div className="flex-1 flex items-center justify-center">
          <div className="text-center space-y-4">
            <Bot className="w-16 h-16 mx-auto text-purple-500" />
            <p className="text-gray-600">Please sign in to use the AI coaching feature.</p>
          </div>
        </div>
      </motion.div>
    );
  }

  if (isInitializing) {
    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex flex-col h-[80vh] bg-white rounded-t-3xl shadow-2xl"
      >
        <div className="flex-1 flex items-center justify-center">
          <div className="text-center space-y-4">
            <div className="mx-auto w-16 h-16 rounded-full bg-purple-100 flex items-center justify-center">
              <Loader className="w-8 h-8 text-purple-500 animate-spin" />
            </div>
            <p className="text-gray-600">Initializing chat...</p>
          </div>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="flex flex-col h-[80vh] bg-white rounded-t-3xl shadow-2xl"
    >
      <ChatHeader 
        category={category} 
        onDelete={handleDeleteConversation} 
        onBack={onClose}
      />
      
      <MessageList
        messages={messages}
        category={category}
        suggestedPrompts={suggestedPrompts}
        onSendMessage={handleSend}
        messagesEndRef={messagesEndRef}
        isLoading={isLoading}
      />

      <ChatInput
        message={message}
        isLoading={isLoading}
        onMessageChange={setMessage}
        onSend={() => handleSend()}
      />
    </motion.div>
  );
};
