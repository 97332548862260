
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Loader2, AlertTriangle, Info, CheckCircle, AlertCircle } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { Progress } from "@/components/ui/progress";

export const VenueImageUpdater = () => {
  const [apiKey, setApiKey] = useState("");
  const [searchEngineId, setSearchEngineId] = useState("a0fc4f604362040c9");
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<any>(null);
  const [progress, setProgress] = useState(0);
  const [venueCount, setVenueCount] = useState(0);
  const [isCreatingBucket, setIsCreatingBucket] = useState(false);
  const [errorDetails, setErrorDetails] = useState<string | null>(null);
  const [functionResponse, setFunctionResponse] = useState<any>(null);
  const [storageStatus, setStorageStatus] = useState<{
    checked: boolean;
    bucketExists: boolean;
    bucketName: string;
    isCreating: boolean;
    error?: string;
  }>({
    checked: false,
    bucketExists: false,
    bucketName: "venueimages",
    isCreating: false
  });
  const { toast } = useToast();

  // Check if storage bucket exists on component load
  useEffect(() => {
    const checkStorageBucket = async () => {
      console.log("Checking if bucket exists:", storageStatus.bucketName);
      try {
        // First try to list all buckets to see if our bucket is among them
        const { data: buckets, error: listError } = await supabase.storage.listBuckets();
        
        if (listError) {
          console.error("Error listing buckets:", listError);
          setStorageStatus(prev => ({
            ...prev,
            checked: true,
            bucketExists: false,
            error: `Error listing buckets: ${listError.message}`
          }));
          return;
        }
        
        console.log("Available buckets:", buckets?.map(b => b.name) || []);
        
        const bucketExists = buckets?.some(bucket => bucket.name === storageStatus.bucketName) || false;
        
        console.log(`Bucket '${storageStatus.bucketName}' exists:`, bucketExists);
        
        setStorageStatus(prev => ({
          ...prev,
          checked: true,
          bucketExists: bucketExists,
          error: bucketExists ? undefined : `Bucket '${storageStatus.bucketName}' not found in available buckets`
        }));
        
      } catch (err: any) {
        console.error("Error checking storage bucket:", err);
        setStorageStatus(prev => ({
          ...prev,
          checked: true,
          bucketExists: false,
          error: `Error: ${err.message || String(err)}`
        }));
      }
    };

    // Get total venue count
    const getVenueCount = async () => {
      try {
        const { count, error } = await supabase
          .from('venues')
          .select('*', { count: 'exact', head: true })
          .filter('images', 'not.is', null)
          .filter('images', 'neq', '{}');
        
        if (!error && count !== null) {
          setVenueCount(count);
        }
      } catch (err) {
        console.error("Error getting venue count:", err);
      }
    };

    checkStorageBucket();
    getVenueCount();
  }, [storageStatus.bucketName]);

  const manuallyCheckBucket = async () => {
    setIsCreatingBucket(true);
    try {
      // Force refresh the bucket check
      console.log("Manually checking bucket existence...");
      
      const { data, error } = await supabase.storage.listBuckets();
      
      if (error) {
        toast({
          title: "Error Checking Buckets",
          description: error.message,
          variant: "destructive"
        });
        return;
      }
      
      const bucketExists = data?.some(bucket => bucket.name === storageStatus.bucketName) || false;
      
      console.log("Available buckets:", data?.map(b => b.name));
      console.log(`Bucket '${storageStatus.bucketName}' exists:`, bucketExists);
      
      setStorageStatus(prev => ({
        ...prev,
        checked: true,
        bucketExists: bucketExists,
        error: bucketExists ? undefined : `Bucket '${storageStatus.bucketName}' not found in available buckets`
      }));
      
      toast({
        title: bucketExists ? "Bucket Found" : "Bucket Not Found",
        description: bucketExists 
          ? `Successfully detected the '${storageStatus.bucketName}' bucket.` 
          : `The '${storageStatus.bucketName}' bucket could not be found. Please ensure it exists in Supabase.`,
        variant: bucketExists ? "default" : "destructive"
      });
    } catch (err: any) {
      toast({
        title: "Error",
        description: err.message || "Failed to check bucket",
        variant: "destructive"
      });
      console.error("Error checking bucket:", err);
    } finally {
      setIsCreatingBucket(false);
    }
  };

  const handleUpdateImages = async () => {
    if (!apiKey) {
      toast({
        title: "API Key Required",
        description: "Please enter a Google Custom Search API Key",
        variant: "destructive"
      });
      return;
    }

    // Even if the UI thinks the bucket doesn't exist, try anyway if the user confirms
    if (!storageStatus.bucketExists) {
      const proceed = window.confirm(
        `The '${storageStatus.bucketName}' bucket wasn't detected in our check, but it might still exist. Do you want to try proceeding anyway?`
      );
      
      if (!proceed) {
        toast({
          title: "Operation Cancelled",
          description: "Please ensure the bucket exists before trying again.",
          variant: "default"
        });
        return;
      }
    }

    setIsLoading(true);
    setResults(null);
    setProgress(0);
    setErrorDetails(null);
    setFunctionResponse(null);

    try {
      console.log("Calling edge function to recover missing images");
      
      const functionCall = await supabase.functions.invoke("download-venue-images", {
        body: {
          apiKey,
          searchEngineId,
          processingMode: "restore"
        }
      });
      
      const { data, error } = functionCall;

      if (error) {
        console.error("Edge function error:", error);
        throw new Error(`Error invoking function: ${error.message}`);
      }

      console.log("Edge function response:", data);
      setResults(data);
      setFunctionResponse(functionCall);
      
      if (data?.storageInfo?.bucketExists) {
        // If the edge function found the bucket, update our local state
        setStorageStatus(prev => ({
          ...prev,
          bucketExists: true,
          error: undefined
        }));
      }
      
      const recoveredImages = data.total_recovered_images || 0;
      
      toast({
        title: "Image Recovery Complete",
        description: `Processed ${data.processed} venues. Found ${data.venues_with_missing_images} venues with missing images. Recovered ${recoveredImages} images.`,
        variant: "default"
      });
    } catch (error: any) {
      console.error("Error recovering venue images:", error);
      
      // Save detailed error for display
      setErrorDetails(error.message || "An unexpected error occurred");
      
      toast({
        title: "Error Recovering Images",
        description: "Failed to recover venue images. See error details below.",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>Recover Missing Venue Images</CardTitle>
        <CardDescription>
          Find and restore missing images for venues by downloading from original URLs or finding replacements
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        {/* Storage bucket status indicator */}
        {storageStatus.checked && (
          <div className={`flex items-center p-3 rounded-md ${storageStatus.bucketExists ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'}`}>
            {storageStatus.isCreating ? (
              <Loader2 className="h-5 w-5 mr-2 text-amber-500 animate-spin" />
            ) : storageStatus.bucketExists ? (
              <CheckCircle className="h-5 w-5 mr-2 text-green-500" />
            ) : (
              <AlertCircle className="h-5 w-5 mr-2 text-red-500" />
            )}
            <div>
              <p className="font-medium">
                {storageStatus.isCreating 
                  ? "Checking storage bucket..." 
                  : storageStatus.bucketExists 
                  ? "Storage bucket ready" 
                  : "Storage bucket not detected"}
              </p>
              <p className="text-sm">
                {storageStatus.isCreating 
                  ? `Checking '${storageStatus.bucketName}' bucket status...` 
                  : storageStatus.bucketExists 
                  ? `The '${storageStatus.bucketName}' bucket is available for storing images` 
                  : `Could not detect the '${storageStatus.bucketName}' bucket. If you've created it, try checking again.`}
              </p>
              
              {!storageStatus.bucketExists && !storageStatus.isCreating && (
                <div className="mt-2">
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={manuallyCheckBucket}
                    disabled={isCreatingBucket}
                    className="mr-2"
                  >
                    {isCreatingBucket ? (
                      <><Loader2 className="h-4 w-4 mr-1 animate-spin" /> Checking...</>
                    ) : (
                      "Check Again"
                    )}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}

        {storageStatus.error && (
          <div className="bg-amber-50 p-3 rounded-md text-amber-800 text-sm">
            <AlertTriangle className="h-4 w-4 inline-block mr-1" />
            <span className="font-medium">Debug info:</span> {storageStatus.error}
          </div>
        )}

        {!storageStatus.bucketExists && storageStatus.checked && (
          <div className="bg-amber-50 p-4 rounded-md border border-amber-200 text-amber-800 text-sm">
            <p className="font-bold mb-2">Bucket Creation Instructions:</p>
            <ol className="list-decimal pl-4 mb-4 space-y-2">
              <li>Go to your <strong>Supabase Dashboard</strong></li>
              <li>Click on <strong>Storage</strong> in the left sidebar</li>
              <li>Click <strong>Create new bucket</strong> button</li>
              <li>Enter the name: <strong>{storageStatus.bucketName}</strong></li> 
              <li>Make sure to <strong>check "Public bucket"</strong> option</li>
              <li>Click <strong>Create bucket</strong></li>
              <li>After creating, click <strong>Check Again</strong> above</li>
            </ol>
            <p className="text-xs mt-2">
              <Info className="inline h-3 w-3 mr-1" /> 
              If you've already created the bucket but it's not being detected, you can still try proceeding with the update.
            </p>
          </div>
        )}

        <div className="space-y-2">
          <label htmlFor="apiKey" className="text-sm font-medium">
            Google Custom Search API Key
          </label>
          <Input
            id="apiKey"
            type="password"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            placeholder="Enter your Google Custom Search API Key"
          />
        </div>
        
        <div className="space-y-2">
          <label htmlFor="searchEngineId" className="text-sm font-medium">
            Custom Search Engine ID
          </label>
          <Input
            id="searchEngineId"
            value={searchEngineId}
            onChange={(e) => setSearchEngineId(e.target.value)}
            placeholder="Enter your Custom Search Engine ID"
          />
          <p className="text-xs text-gray-500">
            Using the same search engine ID as in Live Feed (a0fc4f604362040c9)
          </p>
        </div>
        
        <div className="bg-blue-50 p-3 rounded-md text-blue-800 text-sm">
          <Info className="h-5 w-5 inline-block mr-2 text-blue-500" />
          Found {venueCount} venues with images that may need recovery
        </div>

        {isLoading && (
          <div className="mt-4 space-y-2">
            <p className="text-sm flex items-center">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Processing venues...
            </p>
            <Progress value={progress} className="h-2" />
          </div>
        )}

        {errorDetails && (
          <div className="mt-4 p-4 bg-red-50 rounded-md border border-red-200">
            <h3 className="font-medium mb-2 flex items-center text-red-700">
              <AlertTriangle className="mr-2 h-5 w-5" /> Error Details
            </h3>
            <div className="text-sm text-red-700 overflow-auto max-h-40 p-2 bg-red-50 rounded">
              {errorDetails}
            </div>
            <p className="mt-2 text-xs text-red-600">
              Check if the edge function is properly deployed or if there are any issues with the bucket permissions.
            </p>
            
            {functionResponse && (
              <div className="mt-3 border-t border-red-200 pt-3">
                <p className="text-sm font-medium text-red-700">Function Response:</p>
                <pre className="text-xs mt-1 p-2 bg-red-50/50 rounded-sm overflow-auto max-h-24">
                  {JSON.stringify(functionResponse, null, 2)}
                </pre>
              </div>
            )}
            
            <div className="mt-3 border-t border-red-200 pt-3">
              <p className="text-sm font-medium text-red-700">Troubleshooting Steps:</p>
              <ol className="list-decimal pl-4 mt-1 text-xs text-red-700 space-y-1">
                <li>Verify that the <code>venueimages</code> bucket exists in Supabase Storage</li>
                <li>Check the Edge Function logs in Supabase for detailed error messages</li>
                <li>Ensure your Google API key has Custom Search API enabled</li>
                <li>Try refreshing the page and running the update again</li>
              </ol>
            </div>
          </div>
        )}

        {results && (
          <div className="mt-4 p-4 bg-gray-50 rounded-md border">
            <h3 className="font-medium mb-2 flex items-center">
              <Info className="mr-2 h-5 w-5 text-blue-500" /> Results Summary
            </h3>
            <ul className="space-y-1 text-sm">
              <li>Total Venues Processed: {results.processed}</li>
              <li>Venues with Missing Images: {results.venues_with_missing_images}</li>
              <li className={`${results.total_recovered_images > 0 ? "text-green-600 font-medium" : ""}`}>
                Total Images Recovered: {results.total_recovered_images}
              </li>
              <li className={`${results.errors?.length > 0 ? "text-red-600 font-medium" : ""}`}>
                Recovery Errors: {results.errors?.length || 0}
              </li>
              {results.storageInfo && (
                <>
                  <li className="pt-2 font-medium">Storage Information:</li>
                  <li className={`pl-2 ${!results.storageInfo.bucketExists ? "text-red-600" : "text-green-600"}`}>
                    Bucket Status: {results.storageInfo.bucketExists ? "Available" : "Missing"}
                  </li>
                  {!results.storageInfo.bucketExists && (
                    <li className="pl-2 text-red-600">
                      Error: The storage bucket "{results.storageInfo.bucketName}" doesn't exist. Please create it in Supabase Storage.
                    </li>
                  )}
                </>
              )}
            </ul>
            {results.results && results.results.length > 0 && (
              <div className="mt-4">
                <h4 className="text-green-600 font-medium text-sm flex items-center">
                  <CheckCircle className="mr-2 h-4 w-4" />Recovered Images:
                </h4>
                <div className="max-h-60 overflow-y-auto text-xs mt-2 border rounded">
                  {results.results.map((result: any, i: number) => (
                    <div key={i} className="p-2 border-b border-gray-200">
                      <strong>{result.venueName || 'Unknown venue'}</strong>: Recovered {result.recoveredImages} of {result.missingImages} missing images (total: {result.totalImages})
                    </div>
                  ))}
                </div>
              </div>
            )}
            {results.errors && results.errors.length > 0 && (
              <div className="mt-4">
                <h4 className="text-red-600 font-medium text-sm flex items-center">
                  <AlertTriangle className="mr-2 h-4 w-4" />Errors:
                </h4>
                <div className="max-h-60 overflow-y-auto text-xs mt-2 border rounded">
                  {results.errors.map((err: any, i: number) => (
                    <div key={i} className="p-2 border-b border-gray-200">
                      <strong>{err.venueName || 'Unknown venue'}</strong>: {err.error}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </CardContent>
      <CardFooter>
        <Button 
          onClick={handleUpdateImages} 
          disabled={isLoading || !apiKey}
          className="w-full"
        >
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Recovering missing images...
            </>
          ) : (
            "Recover Missing Images"
          )}
        </Button>
      </CardFooter>
    </Card>
  );
};
