import { createContext, useContext, useEffect, useState, useRef } from "react";
import { Session, User } from "@supabase/supabase-js";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { clearSupabaseData } from "@/lib/utils";
import MobileLoadingScreen from "./ui/mobile-loading-screen";
import { Skeleton } from "./ui/skeleton";
import { useMediaQuery } from "@/hooks/use-media-query";

// Create a BroadcastChannel for cross-tab communication
const authChannel = typeof window !== "undefined" ? new BroadcastChannel("auth_channel") : null;

interface AuthContextType {
  session: Session | null;
  user: User | null;
  loading: boolean;
  isAuthenticated: boolean; // New explicit authenticated state flag
  setUser: (user: User | null) => void;
  setSession: (session: Session | null) => void;
  refreshSession: () => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  session: null,
  user: null,
  loading: true,
  isAuthenticated: false,
  setUser: () => {},
  setSession: () => {},
  refreshSession: async () => {},
  signOut: async () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const signOutInProgressRef = useRef<boolean>(false);
  const sessionRefreshIntervalRef = useRef<number | null>(null);
  const initializeRef = useRef<boolean>(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const authStateCheckTimeoutRef = useRef<number | null>(null);
  
  // Set a shorter max loading time to force proceed after timeout
  useEffect(() => {
    const maxLoadingTime = setTimeout(() => {
      if (loading) {
        console.log("Auth loading timeout reached - forcing completion");
        
        // Force state synchronization from localStorage if we have any indication of being logged in
        const hasSupabaseStorage = Object.keys(localStorage).some(key => 
          key.startsWith('sb-') || key.includes('supabase')
        );
        
        if (hasSupabaseStorage) {
          const accessToken = localStorage.getItem('sb-iywrnwsxrwjaewhgnssq-auth-token');
          if (accessToken) {
            try {
              const parsedData = JSON.parse(accessToken);
              if (parsedData?.access_token && parsedData?.user) {
                console.log("Found valid auth data in localStorage, forcing auth state");
                setUser(parsedData.user);
                setSession({
                  access_token: parsedData.access_token,
                  refresh_token: parsedData.refresh_token || "",
                  expires_in: parsedData.expires_in || 3600,
                  expires_at: parsedData.expires_at || Math.floor(Date.now() / 1000) + 3600,
                  token_type: parsedData.token_type || "bearer",
                  user: parsedData.user
                });
                
                // Schedule a session refresh to validate and update the session in the background
                setTimeout(() => refreshSession(), 1000);
              }
            } catch (e) {
              console.error("Error parsing stored auth data:", e);
            }
          }
        }
        
        setLoading(false);
      }
    }, 5000); // Reduced from 7000 to 5000 ms to be more responsive
    
    return () => clearTimeout(maxLoadingTime);
  }, [loading]);

  // Update authenticated state whenever session or user changes
  useEffect(() => {
    // Consider authenticated if either session or user exists (being more lenient)
    const authenticated = !!(session || user);
    console.log(`Auth state updated: authenticated=${authenticated}, userId=${user?.id}`);
    setIsAuthenticated(authenticated);
    
    // If we suddenly become unauthenticated but see evidence in localStorage, force a session refresh
    if (!authenticated) {
      const hasSupabaseStorage = Object.keys(localStorage).some(key => 
        key.startsWith('sb-') || key.includes('supabase')
      );
      
      if (hasSupabaseStorage) {
        console.log("Detected possible auth state mismatch - forcing refresh");
        refreshSession();
      }
    }
  }, [session, user]);

  // Centralized sign out function to ensure consistent behavior
  const signOut = async () => {
    console.log("AuthProvider: Signing out user...");
    
    // Prevent multiple simultaneous sign-outs
    if (signOutInProgressRef.current) {
      console.log("Sign out already in progress, skipping duplicate request");
      return;
    }
    
    signOutInProgressRef.current = true;
    
    try {
      // Clear any pending session refresh interval
      if (sessionRefreshIntervalRef.current) {
        window.clearInterval(sessionRefreshIntervalRef.current);
        sessionRefreshIntervalRef.current = null;
      }
      
      // Update local state first to immediately update UI
      setSession(null);
      setUser(null);
      setIsAuthenticated(false);
      
      // Force clear localStorage to ensure all auth data is removed BEFORE API call
      clearSupabaseData();
      
      // Broadcast the sign-out event to other tabs BEFORE API call
      authChannel?.postMessage({ type: "SIGNED_OUT" });
      
      // Now sign out from Supabase (which might fail if already signed out)
      const { error } = await supabase.auth.signOut();
      
      if (error) {
        console.warn("Error during Supabase signOut API call (continuing anyway):", error);
      }
      
      console.log("User signed out successfully");
      toast.success("You've been signed out");
    } catch (error) {
      console.error("Error during sign out:", error);
      
      // Force clear state even if API call fails
      setSession(null);
      setUser(null);
      setIsAuthenticated(false);
      clearSupabaseData();
      
      toast.error("There was an issue signing out. Please try again.");
    } finally {
      signOutInProgressRef.current = false;
    }
  };

  const ensureProfileExists = async (userId: string, userEmail?: string | null, userPhone?: string | null) => {
    try {
      console.log("Ensuring profile exists for user:", userId, "email:", userEmail, "phone:", userPhone);
      
      const { data: existingProfile, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .maybeSingle();
        
      if (profileError) {
        console.error("Error checking profile:", profileError);
        return;
      }
      
      if (!existingProfile) {
        console.log("Creating new profile for user:", userId);
        const newProfile = {
          id: userId,
          email: userEmail || null,
          phone: userPhone || null,
          username: userEmail ? userEmail.split('@')[0] : `user_${Math.random().toString(36).substring(2, 8)}`
        };
        
        const { error: createError } = await supabase
          .from('profiles')
          .insert(newProfile);
          
        if (createError) {
          console.error("Error creating profile:", createError);
        } else {
          console.log("Successfully created profile for user:", userId);
        }
      } else if (userEmail && (!existingProfile.email || existingProfile.email !== userEmail)) {
        console.log("Updating profile email to:", userEmail);
        const { error: updateError } = await supabase
          .from('profiles')
          .update({ email: userEmail })
          .eq('id', userId);
          
        if (updateError) {
          console.error("Error updating profile email:", updateError);
        } else {
          console.log("Successfully updated profile email");
        }
      }
    } catch (error) {
      console.error("Error in ensureProfileExists:", error);
    }
  };

  const setupSessionRefreshInterval = () => {
    if (sessionRefreshIntervalRef.current) {
      window.clearInterval(sessionRefreshIntervalRef.current);
    }
    
    if (user || session) {  // Change from requiring both to requiring either
      console.log("Setting up session refresh interval");
      sessionRefreshIntervalRef.current = window.setInterval(() => {
        console.log("Periodic session refresh");
        refreshSession();
      }, 60 * 1000); // 1 minute
    }
  };

  const refreshSession = async () => {
    console.log("Refreshing session...");
    
    // If a sign out is in progress, don't try to refresh
    if (signOutInProgressRef.current) {
      console.log("Sign out in progress, skipping session refresh");
      return;
    }
    
    try {
      // First check if there's any Supabase data in storage
      const hasSupabaseStorage = Object.keys(localStorage).some(key => 
        key.startsWith('sb-') || key.includes('supabase')
      );
      
      if (!hasSupabaseStorage) {
        console.log("No Supabase storage found, session is definitely logged out");
        setSession(null);
        setUser(null);
        setLoading(false);
        return;
      }
      
      const { data: { session: currentSession }, error } = await supabase.auth.getSession();
      
      if (error) {
        console.error("Error getting session:", error);
        
        // Even if there's an error, check if we have valid localStorage data
        const accessToken = localStorage.getItem('sb-iywrnwsxrwjaewhgnssq-auth-token');
        if (accessToken) {
          try {
            const parsedData = JSON.parse(accessToken);
            if (parsedData?.access_token && parsedData?.user) {
              console.log("Error in getSession but found valid auth data, maintaining auth state");
              setUser(parsedData.user);
              // Don't override session here as it might cause a loop
            }
          } catch (e) {
            console.error("Error parsing stored auth data:", e);
          }
        } else {
          throw error;
        }
      } else if (currentSession) {
        console.log("Session refresh successful", currentSession.user.id);
        setSession(currentSession);
        setUser(currentSession.user);
        
        // Broadcast to other tabs that we are signed in
        authChannel?.postMessage({ 
          type: "SESSION_REFRESHED", 
          userId: currentSession.user.id
        });
        
        setupSessionRefreshInterval();
        
        await ensureProfileExists(
          currentSession.user.id, 
          currentSession.user.email, 
          currentSession.user.phone
        );
      } else {
        console.log("No session found during refresh but checking localStorage");
        
        // Even if no session, check if we have valid localStorage data
        const accessToken = localStorage.getItem('sb-iywrnwsxrwjaewhgnssq-auth-token');
        if (accessToken) {
          try {
            const parsedData = JSON.parse(accessToken);
            if (parsedData?.access_token && parsedData?.user) {
              console.log("No session from API but found valid auth data, maintaining auth state");
              setUser(parsedData.user);
              setSession({
                access_token: parsedData.access_token,
                refresh_token: parsedData.refresh_token || "",
                expires_in: parsedData.expires_in || 3600,
                expires_at: parsedData.expires_at || Math.floor(Date.now() / 1000) + 3600,
                token_type: parsedData.token_type || "bearer",
                user: parsedData.user
              });
              return;
            }
          } catch (e) {
            console.error("Error parsing stored auth data:", e);
          }
        }
        
        // If we reach here, we have no valid session
        setSession(null);
        setUser(null);
        clearSupabaseData();
      }
    } catch (error) {
      console.error("Error during session refresh:", error);
      
      // Even if there's an error, check if we have valid localStorage data before clearing
      const accessToken = localStorage.getItem('sb-iywrnwsxrwjaewhgnssq-auth-token');
      if (accessToken) {
        try {
          const parsedData = JSON.parse(accessToken);
          if (parsedData?.access_token && parsedData?.user) {
            console.log("Error in refresh but found valid auth data, maintaining auth state");
            setUser(parsedData.user);
            setSession({
              access_token: parsedData.access_token,
              refresh_token: parsedData.refresh_token || "",
              expires_in: parsedData.expires_in || 3600,
              expires_at: parsedData.expires_at || Math.floor(Date.now() / 1000) + 3600,
              token_type: parsedData.token_type || "bearer",
              user: parsedData.user
            });
            return;
          }
        } catch (e) {
          console.error("Error parsing stored auth data:", e);
        }
      }
      
      setSession(null);
      setUser(null);
      clearSupabaseData();
    } finally {
      setLoading(false);
    }
  };

  // Simple cross-tab auth state handling
  useEffect(() => {
    const handleAuthMessage = (event: MessageEvent) => {
      if (event.data.type === "SIGNED_OUT") {
        console.log("Received sign-out event from another tab");
        setSession(null);
        setUser(null);
        setIsAuthenticated(false);
        
        if (sessionRefreshIntervalRef.current) {
          window.clearInterval(sessionRefreshIntervalRef.current);
          sessionRefreshIntervalRef.current = null;
        }
        
        clearSupabaseData();
      } else if (event.data.type === "SESSION_REFRESHED") {
        console.log("Received session refreshed event from another tab");
        refreshSession();
      }
    };

    authChannel?.addEventListener("message", handleAuthMessage);
    return () => {
      authChannel?.removeEventListener("message", handleAuthMessage);
    };
  }, []);

  // Handle tab visibility change - refresh when tab becomes visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log("Tab became visible, refreshing session");
        
        // Force synchronization with localStorage first
        const accessToken = localStorage.getItem('sb-iywrnwsxrwjaewhgnssq-auth-token');
        if (accessToken) {
          try {
            const parsedData = JSON.parse(accessToken);
            if (parsedData?.access_token && parsedData?.user && !user) {
              console.log("Tab visible and found auth data but no user, forcing auth state");
              setUser(parsedData.user);
              setSession({
                access_token: parsedData.access_token,
                refresh_token: parsedData.refresh_token || "",
                expires_in: parsedData.expires_in || 3600,
                expires_at: parsedData.expires_at || Math.floor(Date.now() / 1000) + 3600,
                token_type: parsedData.token_type || "bearer",
                user: parsedData.user
              });
            }
          } catch (e) {
            console.error("Error parsing stored auth data:", e);
          }
        }
        
        // Then do a full refresh with the API
        refreshSession();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [user]);

  // Setup session refresh interval when user or session changes
  useEffect(() => {
    if (!user && !session) {
      if (sessionRefreshIntervalRef.current) {
        window.clearInterval(sessionRefreshIntervalRef.current);
        sessionRefreshIntervalRef.current = null;
      }
      return;
    }
    
    setupSessionRefreshInterval();
  }, [user, session]);

  // Initialize auth session - only run once
  useEffect(() => {
    // Prevent multiple initializations
    if (initializeRef.current) return;
    initializeRef.current = true;
    
    const initSession = async () => {
      console.log("Initializing auth session...");
      setLoading(true);
      
      try {
        // First, check if there's any Supabase data in localStorage
        const hasSupabaseStorage = Object.keys(localStorage).some(key => 
          key.startsWith('sb-') || key.includes('supabase')
        );
        
        if (!hasSupabaseStorage) {
          console.log("No Supabase storage found, skipping session initialization");
          setLoading(false);
          return;
        }
        
        const { data: { session: currentSession }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error("Error getting session:", sessionError);
          throw sessionError;
        }
        
        if (currentSession) {
          console.log("Session found during initialization:", currentSession.user.id);
          setSession(currentSession);
          setUser(currentSession.user);
          
          // Let other tabs know we have initialized
          authChannel?.postMessage({ 
            type: "SESSION_REFRESHED", 
            userId: currentSession.user.id
          });
          
          setupSessionRefreshInterval();
          
          await ensureProfileExists(
            currentSession.user.id, 
            currentSession.user.email, 
            currentSession.user.phone
          );
        } else {
          console.log("No session found during initialization");
          clearSupabaseData();
        }
      } catch (error) {
        console.error("Session initialization error:", error);
        setSession(null);
        setUser(null);
        clearSupabaseData();
      } finally {
        setLoading(false);
      }
    };

    initSession();

    // Setup auth state change listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, newSession) => {
      console.log("Auth state changed:", event, newSession?.user?.id);
      
      if (event === 'SIGNED_OUT') {
        setSession(null);
        setUser(null);
        
        if (sessionRefreshIntervalRef.current) {
          window.clearInterval(sessionRefreshIntervalRef.current);
          sessionRefreshIntervalRef.current = null;
        }
        
        clearSupabaseData();
        
        // Broadcast the sign-out event to other tabs
        authChannel?.postMessage({ type: "SIGNED_OUT" });
      } else if (event === 'SIGNED_IN' && newSession) {
        console.log("New sign-in detected:", newSession.user.id);
        setSession(newSession);
        setUser(newSession.user);
        
        setupSessionRefreshInterval();
        
        await ensureProfileExists(
          newSession.user.id,
          newSession.user.email,
          newSession.user.phone
        );
        
        // Broadcast the sign-in event to other tabs
        authChannel?.postMessage({ 
          type: "SESSION_REFRESHED",
          userId: newSession.user.id
        });
      } else if (event === 'TOKEN_REFRESHED' && newSession) {
        console.log("Token refreshed:", newSession.user.id);
        setSession(newSession);
        setUser(newSession.user);
      } else if (event === 'USER_UPDATED' && newSession) {
        console.log("User updated:", newSession.user.id);
        setSession(newSession);
        setUser(newSession.user);
        
        if (newSession.user?.email_confirmed_at) {
          console.log("Email confirmed event detected!");
          await ensureProfileExists(
            newSession.user.id,
            newSession.user.email,
            newSession.user.phone
          );
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Add periodic auth state consistency check
  useEffect(() => {
    const checkAuthStateConsistency = () => {
      // If UI shows logged in but we have any doubt, verify and force
      if (user && !session) {
        console.log("Auth state inconsistency detected: have user but no session");
        refreshSession();
      } else if (session && !user) {
        console.log("Auth state inconsistency detected: have session but no user");
        setUser(session.user);
      }
      
      // Check localStorage for auth data even if we think we're not logged in
      if (!user && !session) {
        const accessToken = localStorage.getItem('sb-iywrnwsxrwjaewhgnssq-auth-token');
        if (accessToken) {
          try {
            const parsedData = JSON.parse(accessToken);
            if (parsedData?.access_token && parsedData?.user) {
              console.log("Auth data found in localStorage but not in state - forcing auth");
              setUser(parsedData.user);
              setSession({
                access_token: parsedData.access_token,
                refresh_token: parsedData.refresh_token || "",
                expires_in: parsedData.expires_in || 3600,
                expires_at: parsedData.expires_at || Math.floor(Date.now() / 1000) + 3600,
                token_type: parsedData.token_type || "bearer",
                user: parsedData.user
              });
              
              setTimeout(() => refreshSession(), 1000);
            }
          } catch (e) {
            console.error("Error parsing stored auth data:", e);
          }
        }
      }
    };
    
    // Run check immediately at startup
    checkAuthStateConsistency();
    
    // Set interval for periodic checks
    const intervalId = setInterval(checkAuthStateConsistency, 10000); // Check every 10 seconds
    
    return () => clearInterval(intervalId);
  }, [user, session]);

  // If loading and on mobile, show the mobile loading screen
  if (loading && isMobile) {
    return <MobileLoadingScreen />;
  }

  // If loading and on desktop, return a minimal loading state
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="w-full max-w-md p-6 space-y-4">
          <Skeleton className="h-12 w-full rounded" />
          <Skeleton className="h-32 w-full rounded" />
          <div className="space-y-2">
            <Skeleton className="h-4 w-full rounded" />
            <Skeleton className="h-4 w-3/4 rounded" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ 
      session, 
      user, 
      loading, 
      isAuthenticated,
      setUser, 
      setSession, 
      refreshSession,
      signOut
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

