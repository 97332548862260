
import { useState } from "react";
import { Send } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/components/AuthProvider";
import { generateUsername } from "@/lib/utils";

interface ChatInputProps {
  selectedRoom: string;
  onOptimisticUpdate?: (message: any) => void;
}

const BAD_WORDS = [
  "badword1", "badword2", "badword3",
];

export const ChatInput = ({ selectedRoom, onOptimisticUpdate }: ChatInputProps) => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [message, setMessage] = useState("");
  const [isAnonymous, setIsAnonymous] = useState(false);

  const censorMessage = (text: string) => {
    let censoredText = text;
    BAD_WORDS.forEach(word => {
      const regex = new RegExp(word, "gi");
      censoredText = censoredText.replace(regex, "*".repeat(word.length));
    });
    return censoredText;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim() || !user || !selectedRoom) return;

    const censoredMessage = censorMessage(message.trim());
    const anonymousUsername = isAnonymous ? generateUsername() : null;

    // Create optimistic message
    const optimisticMessage = {
      id: crypto.randomUUID(),
      content: censoredMessage,
      user_id: user.id,
      is_anonymous: isAnonymous,
      anonymous_username: anonymousUsername,
      created_at: new Date().toISOString(),
      profiles: {
        username: user.user_metadata?.username,
        full_name: user.user_metadata?.full_name
      }
    };

    // Update UI immediately
    onOptimisticUpdate?.(optimisticMessage);
    setMessage("");

    try {
      const { error } = await supabase.from("chat_messages").insert({
        room_id: selectedRoom,
        user_id: user.id,
        content: censoredMessage,
        is_anonymous: isAnonymous,
        anonymous_username: anonymousUsername,
      });

      if (error) throw error;
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error sending message",
        description: "Please try again",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-2">
      <div className="flex items-center gap-2">
        <Checkbox
          id="anonymous"
          checked={isAnonymous}
          onCheckedChange={(checked) => setIsAnonymous(checked as boolean)}
        />
        <label htmlFor="anonymous" className="text-sm text-gray-600">
          Send anonymously
        </label>
      </div>
      <div className="flex gap-2">
        <Input
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
          className="flex-grow"
        />
        <Button type="submit">
          <Send className="w-4 h-4" />
        </Button>
      </div>
    </form>
  );
};
