
import { motion } from "framer-motion";
import { Bot } from "lucide-react";

interface ChatMessageProps {
  content: string;
  role: 'user' | 'assistant';
  timestamp: string;
}

export const ChatMessage = ({ content, role, timestamp }: ChatMessageProps) => {
  const isUser = role === 'user';

  const messageVariants = {
    hidden: { opacity: 0, y: 20, scale: 0.95 },
    visible: { opacity: 1, y: 0, scale: 1, transition: { duration: 0.3 } }
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={messageVariants}
      className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}
    >
      <div className={`flex items-start max-w-[80%] gap-2 ${isUser ? 'flex-row-reverse' : 'flex-row'}`}>
        {!isUser && (
          <div className="flex-shrink-0 w-8 h-8 rounded-full bg-gradient-to-br from-purple-500 to-blue-500 flex items-center justify-center text-white shadow-md">
            <Bot className="w-5 h-5" />
          </div>
        )}
        <div
          className={`p-4 rounded-2xl shadow-sm ${
            isUser
              ? 'bg-gradient-to-br from-purple-500 to-blue-500 text-white'
              : 'bg-white border border-gray-100 text-gray-900'
          }`}
        >
          <p className="text-sm leading-relaxed whitespace-pre-wrap">{content}</p>
          <p className="text-xs mt-2 opacity-70">
            {new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          </p>
        </div>
        {isUser && (
          <div className="flex-shrink-0 w-8 h-8 rounded-full bg-gradient-to-r from-indigo-500 to-purple-500 flex items-center justify-center text-white shadow-md">
            <span className="text-sm font-medium">
              {/* Use the first letter of the user's name if available */}
              {localStorage.getItem('userName')?.[0]?.toUpperCase() || 'U'}
            </span>
          </div>
        )}
      </div>
    </motion.div>
  );
};
