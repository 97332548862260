import { useState } from "react";
import { 
  ArrowRight, TrendingUp, BarChart4, Globe, ChartPieIcon,
  Users, Mail, Building2, Send, ArrowUpRight, PartyPopper, 
  Sparkles, CheckCircle2, ArrowLeft
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import BackButton from "@/components/ui/back-button";

const Investors = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.name || !formData.email) {
      toast.error("Please provide your name and email address.");
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      const { error } = await supabase
        .from('investor_inquiries')
        .insert([formData]);
        
      if (error) {
        throw error;
      }
      
      toast.success("Your interest has been submitted. Thank you!");
      setIsSuccess(true);
      
      setFormData({
        name: "",
        email: "",
        company: "",
        message: ""
      });
      
    } catch (error) {
      console.error("Error submitting investor interest:", error);
      toast.error("Failed to submit your interest. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-white via-white to-indigo-50">
      <div className="pt-32 pb-20 relative overflow-hidden">
        <div className="absolute inset-0 z-0">
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-white via-white to-indigo-50 opacity-95"></div>
          <div className="absolute top-20 left-10 w-64 h-64 bg-gradient-to-r from-indigo-300/20 to-purple-300/20 rounded-full blur-3xl"></div>
          <div className="absolute bottom-20 right-10 w-64 h-64 bg-gradient-to-l from-indigo-300/20 to-purple-300/20 rounded-full blur-3xl"></div>
        </div>
        
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="absolute left-0 top-0">
            <BackButton className="md:flex hidden" />
            <button 
              onClick={() => window.history.back()}
              className="hidden md:flex items-center gap-2 text-gray-600 hover:text-indigo-700 transition-colors group p-2"
              aria-label="Go back"
            >
              <ArrowLeft className="h-5 w-5 group-hover:-translate-x-1 transition-transform" />
              <span className="font-medium">Back</span>
            </button>
          </div>
          
          <div className="text-center mb-16">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-8 bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-purple-600">
              beSportly: India Plays Here
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-10">
              We're an early-stage tech startup building for a healthier, more playful and connected India.
            </p>
            <div className="flex flex-col md:flex-row items-center justify-center gap-4">
              <a href="#investment-form" className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:shadow-lg transition-all flex items-center gap-2 group">
                Become an Investor
                <ArrowRight className="h-4 w-4 group-hover:translate-x-1 transition-transform" />
              </a>
              <a href="#why-invest" className="text-indigo-600 border border-indigo-600 bg-white px-8 py-3 rounded-lg font-medium hover:bg-indigo-50 transition-all">
                Learn More
              </a>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10">
            {[
              { 
                icon: <TrendingUp className="h-8 w-8 text-indigo-600" />, 
                title: "Growth Market", 
                description: "Sporting and fitness in India is rapidly growing with a projected 20% YoY growth rate." 
              },
              { 
                icon: <Users className="h-8 w-8 text-indigo-600" />, 
                title: "Pan-India Presence", 
                description: "Operations in 10+ major cities with strategic expansion plans across tier 2 and 3 cities." 
              },
              { 
                icon: <Globe className="h-8 w-8 text-indigo-600" />, 
                title: "Community Impact", 
                description: "Creating local sporting communities and fostering healthier lifestyles across India." 
              }
            ].map((item, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow border border-gray-100 hover:border-indigo-200">
                <div className="mb-4">
                  {item.icon}
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      <div id="why-invest" className="py-20 bg-gradient-to-br from-indigo-900 to-purple-900 text-white relative">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute top-0 right-0 w-96 h-96 bg-indigo-600/20 rounded-full blur-3xl"></div>
          <div className="absolute bottom-0 left-0 w-96 h-96 bg-purple-600/20 rounded-full blur-3xl"></div>
        </div>
        
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-6">Why Invest in beSportly</h2>
            <p className="text-xl text-indigo-100 max-w-3xl mx-auto">
              We're building India's most comprehensive sports and fitness ecosystem, connecting venues, coaches, and communities.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
            {[
              {
                icon: <ChartPieIcon className="h-10 w-10 text-indigo-300" />,
                title: "Market Opportunity",
                description: "Sporting and fitness in India is rapidly growing, projected to be worth over $10B by 2027."
              },
              {
                icon: <Globe className="h-10 w-10 text-indigo-300" />,
                title: "Unified App",
                description: "beSportly is a complete sporting ecosystem with venue booking, coach hiring, fitness tracking, AI coaching, communities, and fitness product shopping."
              },
              {
                icon: <BarChart4 className="h-10 w-10 text-indigo-300" />,
                title: "Revenue Model",
                description: "Multiple revenue streams including commissions, premium subscriptions, and AI-powered coaching services."
              },
              {
                icon: <PartyPopper className="h-10 w-10 text-indigo-300" />,
                title: "Customer Love",
                description: "We've received amazing feedback from users who love how beSportly is transforming their sporting and fitness journey."
              }
            ].map((item, index) => (
              <div key={index} className="bg-white/10 backdrop-blur-sm p-8 rounded-xl border border-white/20 hover:bg-white/15 transition-colors">
                <div className="mb-4">
                  {item.icon}
                </div>
                <h3 className="text-2xl font-bold mb-3">{item.title}</h3>
                <p className="text-indigo-100">{item.description}</p>
              </div>
            ))}
          </div>
          
          <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8 border border-white/20">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8">
              {[
                { feature: "Venue Booking" },
                { feature: "Coach Hiring" },
                { feature: "AI Coaching" },
                { feature: "Fitness Tracking" },
                { feature: "Live Sports" },
                { feature: "Communities" },
                { feature: "Product Shop" },
                { feature: "Events & Games" }
              ].map((item, index) => (
                <div key={index} className="text-center">
                  <div className="text-lg md:text-xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-indigo-300 to-purple-300">
                    {item.feature}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      <div id="investment-form" className="py-20 relative">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Express Your Interest
              </h2>
              <p className="text-lg text-gray-600 mb-8">
                We're seeking strategic investment partners who share our vision for transforming sports and fitness in India. 
                Fill out the form to receive our investor deck and schedule a discussion with our founding team.
              </p>
              
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="bg-indigo-100 p-2 rounded-full text-indigo-600">
                    <CheckCircle2 className="h-5 w-5" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">Detailed Financials</h3>
                    <p className="text-gray-600">Access to our current financial data and projections</p>
                  </div>
                </div>
                
                <div className="flex items-start gap-4">
                  <div className="bg-indigo-100 p-2 rounded-full text-indigo-600">
                    <CheckCircle2 className="h-5 w-5" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">Growth Strategy</h3>
                    <p className="text-gray-600">Our comprehensive 3-year expansion and acquisition plan</p>
                  </div>
                </div>
                
                <div className="flex items-start gap-4">
                  <div className="bg-indigo-100 p-2 rounded-full text-indigo-600">
                    <CheckCircle2 className="h-5 w-5" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900">Founding Team</h3>
                    <p className="text-gray-600">Connect with our team</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="bg-white rounded-2xl shadow-xl p-8 border border-gray-100">
              {isSuccess ? (
                <div className="text-center py-10">
                  <div className="inline-block p-3 rounded-full bg-green-100 text-green-600 mb-4">
                    <CheckCircle2 className="h-12 w-12" />
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900 mb-2">Thank You!</h3>
                  <p className="text-gray-600 mb-6">
                    Your interest has been received. Our investor relations team will contact you shortly with more information.
                  </p>
                  <Button 
                    onClick={() => setIsSuccess(false)}
                    className="bg-indigo-600 hover:bg-indigo-700"
                  >
                    Submit Another Inquiry
                  </Button>
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <h3 className="text-xl font-bold text-gray-900 mb-6">Investor Interest Form</h3>
                  
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Full Name *</label>
                      <Input 
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Your name"
                        required
                        className="w-full"
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email *</label>
                      <Input 
                        id="email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="your@email.com"
                        required
                        className="w-full"
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">Company / VC Firm</label>
                      <div className="flex">
                        <div className="bg-gray-100 py-2 px-3 rounded-l-md flex items-center">
                          <Building2 className="h-5 w-5 text-gray-500" />
                        </div>
                        <Input 
                          id="company"
                          name="company"
                          value={formData.company}
                          onChange={handleInputChange}
                          placeholder="Your organization"
                          className="w-full rounded-l-none"
                        />
                      </div>
                    </div>
                    
                    <div>
                      <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Message</label>
                      <Textarea 
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        placeholder="Tell us about your investment interests and questions..."
                        className="w-full min-h-[120px]"
                      />
                    </div>
                    
                    <Button 
                      type="submit"
                      className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 text-white font-medium py-2.5"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span className="flex items-center gap-2">
                          <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Processing...
                        </span>
                      ) : (
                        <span className="flex items-center gap-2">
                          Submit Interest <Send className="h-4 w-4" />
                        </span>
                      )}
                    </Button>
                  </div>
                  
                  <div className="mt-6 text-center text-sm text-gray-500">
                    <div className="flex items-center justify-center gap-2">
                      <Mail className="h-4 w-4" />
                      <span>Or email us directly at </span>
                      <a 
                        href="mailto:investors@besportly.com" 
                        className="text-indigo-600 hover:text-indigo-800 font-medium"
                      >
                        investors@besportly.com
                      </a>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      
      <div className="py-16 bg-gradient-to-r from-indigo-50 to-purple-50">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Ready to Shape the Future of Sports?</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
            Join beSportly as an early investor and be part of India's fastest-growing sports technology platform.
          </p>
          <a 
            href="#investment-form" 
            className="inline-flex items-center gap-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-8 py-3 rounded-lg font-medium hover:shadow-lg transition-all group"
          >
            Get Started Today
            <ArrowUpRight className="h-4 w-4 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Investors;
