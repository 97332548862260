import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Search, MapPin, Star, Filter, ArrowLeft } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import ComingSoonBanner from "@/components/ComingSoonBanner";
import BackButton from "@/components/ui/back-button";
import { NoResultsAnimation } from "@/components/ui/no-results-animation";
import VenueDetailsDialog from "@/components/venues/VenueDetailsDialog";
import VenueDetailsSheet from "@/components/venues/VenueDetailsSheet";
import { useIsMobile } from "@/hooks/use-mobile";
import { Venue } from "@/types/venue";
import { motion } from "framer-motion";
import { useAutoRefresh } from '@/hooks/use-auto-refresh';
import { Toggle } from "@/components/ui/toggle";
import { popularCities } from "@/utils/cityData";
import { getVenueImage } from "@/utils/venueImageUtils";

const sportCategories = [
  "Cricket",
  "Football",
  "Basketball",
  "Tennis",
  "Badminton",
  "Pickleball",
  "Swimming",
  "Yoga",
  "Squash",
  "Table Tennis",
  "Volleyball",
  "Athletics",
  "Hockey",
  "Wrestling",
  "Martial Arts",
];

const Venues = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedPriceRanges, setSelectedPriceRanges] = useState<string[]>([]);
  const [selectedSports, setSelectedSports] = useState<string[]>([]);
  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const cityParam = params.getAll('city');
    const sportParam = params.getAll('sport');
    const priceRangeParam = params.getAll('priceRange');
    
    if (cityParam.length > 0) setSelectedCities(cityParam);
    if (sportParam.length > 0) {
      const formattedSports = sportParam.map(
        sport => sport.charAt(0).toUpperCase() + sport.slice(1).toLowerCase()
      );
      setSelectedSports(formattedSports);
    }
    if (priceRangeParam.length > 0) setSelectedPriceRanges(priceRangeParam);
  }, [location.search]);

  const { data: venues, isLoading, error } = useQuery({
    queryKey: ["venues", searchTerm, selectedCities, selectedPriceRanges, selectedSports],
    queryFn: async () => {
      let query = supabase
        .from("venues")
        .select("*");

      if (searchTerm) {
        query = query.ilike("name", `%${searchTerm}%`);
      }

      if (selectedCities.length > 0) {
        query = query.in("city", selectedCities);
      }

      if (selectedPriceRanges.length > 0) {
        const priceConditions = selectedPriceRanges.map(range => {
          if (range === "all") return null;
          
          const [min, max] = range.split("-").map(Number);
          if (max) {
            return `price_per_hour.gte.${min},price_per_hour.lte.${max}`;
          } else {
            return `price_per_hour.gte.${min}`;
          }
        }).filter(Boolean);

        if (priceConditions.length > 0) {
          const orConditions = priceConditions.join(",");
          query = query.or(orConditions);
        }
      }

      if (selectedSports.length > 0) {
        query = query.contains('available_sports', selectedSports);
      }

      const { data, error } = await query;

      if (error) throw error;
      
      return data.sort((a, b) => {
        if (a.name.toLowerCase().includes('sportslane')) return -1;
        if (b.name.toLowerCase().includes('sportslane')) return 1;
        return 0;
      }).map(venue => {
        const typedVenue: Venue = {
          id: venue.id,
          name: venue.name,
          description: venue.description,
          category: venue.category,
          address: venue.address,
          city: venue.city,
          state: venue.state,
          pincode: venue.pincode,
          price_per_hour: venue.price_per_hour,
          rating: venue.rating,
          total_ratings: venue.total_ratings,
          images: venue.images,
          facilities: venue.facilities,
          available_sports: venue.available_sports,
          is_bookable: venue.is_bookable,
          opening_time: venue.opening_time,
          closing_time: venue.closing_time,
          max_capacity: venue.max_capacity,
          phone: venue.phone || null,
          website: venue.website || null,
          place_id: venue.place_id || null,
          data_source: venue.data_source || null,
          maps_url: venue.place_id ? 
            `https://www.google.com/maps/place/?q=place_id:${venue.place_id}` : null,
          created_at: venue.created_at,
          updated_at: venue.updated_at
        };
        
        return typedVenue;
      });
    },
  });

  const noResultsShowing = !isLoading && (!venues || venues.length === 0);
  useAutoRefresh(venues?.length || 0, noResultsShowing);

  const handleVenueAction = (venue: Venue) => {
    if (venue.is_bookable) {
      const queryParams = selectedSports.length > 0 ? `?sport=${selectedSports[0].toLowerCase()}` : '';
      navigate(`/book-venue/${venue.id}${queryParams}`);
    } else {
      setSelectedVenue(venue);
      setIsSheetOpen(true);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams();
    
    if (selectedCities.length > 0) {
      selectedCities.forEach(city => {
        params.append("city", city);
      });
    }
    
    if (selectedSports.length > 0) {
      selectedSports.forEach(sport => {
        params.append("sport", sport);
      });
    }
    
    if (selectedPriceRanges.length > 0) {
      selectedPriceRanges.forEach(range => {
        params.append("priceRange", range);
      });
    }
    
    const queryString = params.toString();
    const newUrl = `${location.pathname}${queryString ? `?${queryString}` : ''}`;
    
    if (location.search !== (queryString ? `?${queryString}` : '')) {
      navigate(newUrl, { replace: true });
    }
  }, [selectedCities, selectedSports, selectedPriceRanges]);

  const priceRanges = [
    { label: "All Prices", value: "all" },
    { label: "₹200 - ₹500", value: "200-500" },
    { label: "₹501 - ₹1000", value: "501-1000" },
    { label: "₹1001 - ₹2000", value: "1001-2000" },
    { label: "₹2001 - ₹5000", value: "2001-5000" },
  ];

  const toggleCity = (city: string) => {
    setSelectedCities(prev => 
      prev.includes(city) 
        ? prev.filter(c => c !== city) 
        : [...prev, city]
    );
  };
  
  const toggleSport = (sport: string) => {
    setSelectedSports(prev => 
      prev.includes(sport) 
        ? prev.filter(s => s !== sport) 
        : [...prev, sport]
    );
  };
  
  const togglePriceRange = (range: string) => {
    setSelectedPriceRanges(prev => 
      prev.includes(range) 
        ? prev.filter(r => r !== range) 
        : [...prev, range]
    );
  };

  const resetFilters = () => {
    setSearchTerm("");
    setSelectedCities([]);
    setSelectedPriceRanges([]);
    setSelectedSports([]);
  };

  const hasActiveFilters = selectedCities.length > 0 || selectedPriceRanges.length > 0 || selectedSports.length > 0;

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 md:pt-32">
        <main>
          <div className="mb-8 pl-10 md:pl-0 venue-title-container">
            <BackButton className="absolute left-3 top-16 md:left-0 md:top-32" />
            <h1 className="text-4xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-indigo-600 bg-clip-text text-transparent animate-gradient px-1 py-1 rounded-lg mb-4 relative inline-block md:block w-auto">
              Find Your Perfect Venue
              <div className="h-1 w-full rounded-full bg-gradient-to-r from-purple-500 via-pink-400 to-indigo-500 opacity-70 absolute bottom-0 left-0"></div>
            </h1>
            <p className="text-lg text-gray-600 md:pl-0">
              Discover and book sports venues in your area
            </p>
          </div>

          <ComingSoonBanner />

          <div className="relative mb-6">
            <div className="relative">
              <Search className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
              <Input
                type="text"
                placeholder="Search venues..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10"
              />
              <Button
                variant="outline"
                size="icon"
                className="absolute right-0 top-0 h-full px-3 rounded-l-none border-l-0"
                onClick={() => setFiltersVisible(!filtersVisible)}
              >
                <Filter className="h-5 w-5" />
                {hasActiveFilters && (
                  <span className="absolute -top-1 -right-1 bg-purple-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                    !
                  </span>
                )}
              </Button>
            </div>

            {filtersVisible && (
              <div className="bg-white px-4 py-3 rounded-lg shadow-sm border mt-2 transition-all">
                <div className="mb-4">
                  <h3 className="font-medium text-sm text-gray-700 mb-2">City <span className="text-xs text-gray-500">(select multiple)</span></h3>
                  <div className="flex flex-wrap gap-1.5">
                    {popularCities.slice(0, isMobile ? 8 : 20).map((city) => (
                      <Toggle
                        key={city}
                        className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                          selectedCities.includes(city) 
                            ? "bg-purple-500 text-white" 
                            : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                        }`}
                        pressed={selectedCities.includes(city)}
                        onPressedChange={() => toggleCity(city)}
                      >
                        {city}
                      </Toggle>
                    ))}
                  </div>
                </div>

                <div className="mb-4">
                  <h3 className="font-medium text-sm text-gray-700 mb-2">Sports <span className="text-xs text-gray-500">(select multiple)</span></h3>
                  <div className="flex flex-wrap gap-1.5">
                    {sportCategories.map((sport) => (
                      <Toggle
                        key={sport}
                        className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                          selectedSports.includes(sport) 
                            ? "bg-purple-500 text-white" 
                            : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                        }`}
                        pressed={selectedSports.includes(sport)}
                        onPressedChange={() => toggleSport(sport)}
                      >
                        {sport}
                      </Toggle>
                    ))}
                  </div>
                </div>

                <div className="mb-4">
                  <h3 className="font-medium text-sm text-gray-700 mb-2">Price Range <span className="text-xs text-gray-500">(select multiple)</span></h3>
                  <div className="flex flex-wrap gap-1.5">
                    {priceRanges.slice(1).map((range) => (
                      <Toggle
                        key={range.value}
                        className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                          selectedPriceRanges.includes(range.value) 
                            ? "bg-purple-500 text-white" 
                            : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                        }`}
                        pressed={selectedPriceRanges.includes(range.value)}
                        onPressedChange={() => togglePriceRange(range.value)}
                      >
                        {range.label}
                      </Toggle>
                    ))}
                  </div>
                </div>

                {hasActiveFilters && (
                  <Button
                    variant="ghost"
                    className="text-purple-600 hover:text-purple-700 p-0 h-auto font-medium"
                    onClick={resetFilters}
                  >
                    Clear all filters
                  </Button>
                )}
              </div>
            )}
          </div>

          {isLoading ? (
            <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ${isMobile ? 'mobile-search-grid' : ''}`}>
              {[1, 2, 3, 4, 5, 6].map((n) => (
                <Card key={n} className="overflow-hidden">
                  <Skeleton className="h-48 w-full" />
                  <CardHeader>
                    <Skeleton className="h-6 w-2/3" />
                    <Skeleton className="h-4 w-full" />
                  </CardHeader>
                </Card>
              ))}
            </div>
          ) : (
            <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ${isMobile ? 'mobile-search-grid' : ''}`}>
              {venues?.length > 0 ? (
                venues?.map((venue) => (
                  isMobile ? (
                    <motion.div
                      key={venue.id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className="mobile-search-card venue-search-card"
                      onClick={() => handleVenueAction(venue)}
                    >
                      <div className="card-image">
                        <img
                          src={getVenueImage(venue)}
                          alt={venue.name}
                          className="w-full h-full object-cover"
                        />
                        <div className="card-badge">
                          {venue.is_bookable ? "Book Now" : "External"}
                        </div>
                      </div>
                      <div className="card-content">
                        <h3 className="card-title">{venue.name}</h3>
                        <div className="card-subtitle">
                          <MapPin className="h-3 w-3 mr-1" />
                          <span className="text-gray-600 text-xs truncate">{venue.city}</span>
                        </div>
                        <div className="card-tags">
                          {venue.available_sports?.slice(0, 2).map((sport, i) => (
                            <span key={i} className="card-tag">
                              {sport}
                            </span>
                          ))}
                          {venue.available_sports?.length > 2 && (
                            <span className="card-tag">
                              +{venue.available_sports.length - 2}
                            </span>
                          )}
                        </div>
                        <div className="card-footer">
                          <div className="card-rating">
                            <Star className="h-3 w-3 text-yellow-400 fill-yellow-400 mr-1" />
                            <span>{venue.rating?.toFixed(1) || "4.5"}</span>
                          </div>
                          
                          {!venue.is_bookable && (
                            <div className="flex items-center mr-1">
                              <img src="/lovable-uploads/8ead321d-1e98-43b9-b971-053d58ba6729.png" alt="Google Maps" className="h-4 w-4 mr-1" />
                              <span className="text-xs text-blue-600 font-medium">From Google Maps</span>
                            </div>
                          )}
                          
                          <span className="card-price">
                            {venue.is_bookable 
                              ? `₹${venue.price_per_hour}/hr` 
                              : "Available on inquiry"}
                          </span>
                        </div>
                      </div>
                    </motion.div>
                  ) : (
                    <Card key={venue.id} className="overflow-hidden hover:shadow-lg transition-shadow">
                      <div className="h-48 bg-gray-200 relative">
                        <img
                          src={getVenueImage(venue)}
                          alt={venue.name}
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute top-2 left-2">
                          <span className="px-3 py-1 bg-gradient-to-r from-orange-400 to-yellow-300 text-white backdrop-blur-sm rounded-full text-sm font-medium">
                            {venue.is_bookable ? "Book Now" : "External"}
                          </span>
                        </div>
                      </div>
                      <CardHeader>
                        <CardTitle className="text-gray-900 dark:text-gray-900">{venue.name}</CardTitle>
                        <CardDescription className="line-clamp-2 text-gray-600 dark:text-gray-600">
                          {venue.description}
                        </CardDescription>
                      </CardHeader>
                      <CardContent>
                        <div className="flex items-center text-gray-600 dark:text-gray-600 mb-2">
                          <MapPin className="h-4 w-4 mr-2" />
                          {venue.address}, {venue.city}
                        </div>
                        <div className="flex items-center text-gray-600 dark:text-gray-600">
                          <span className="font-medium">
                            {venue.is_bookable ? `₹${venue.price_per_hour}/hour` : "Available on inquiry"}
                          </span>
                        </div>
                      </CardContent>
                      <CardFooter className="flex justify-between items-center">
                        <div className="flex items-center space-x-2">
                          {venue.rating && (
                            <div className="text-sm text-gray-600 dark:text-gray-600 flex items-center">
                              <span>{venue.rating.toFixed(1)}</span>
                              <Star className="h-3.5 w-3.5 fill-yellow-400 text-yellow-400 ml-1" />
                            </div>
                          )}
                          
                          {!venue.is_bookable && (
                            <div className="flex items-center">
                              <img src="/lovable-uploads/8ead321d-1e98-43b9-b971-053d58ba6729.png" alt="Google Maps" className="h-4 w-4 mr-1" />
                              <span className="text-xs text-blue-600 font-medium">From Google Maps</span>
                            </div>
                          )}
                        </div>
                        <Button 
                          onClick={() => handleVenueAction(venue)}
                          className={venue.is_bookable ? 
                            "bg-green-600 hover:bg-green-700" : 
                            "bg-primary/90 hover:bg-primary"}
                        >
                          {venue.is_bookable ? "Book Now" : "View Details"}
                        </Button>
                      </CardFooter>
                    </Card>
                  )
                ))
              ) : (
                <div className="col-span-full">
                  <NoResultsAnimation type="venues" onReset={resetFilters} />
                </div>
              )}
            </div>
          )}

          <VenueDetailsDialog 
            venue={selectedVenue}
            isOpen={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
          />

          <VenueDetailsSheet
            venue={selectedVenue}
            isOpen={isSheetOpen}
            onClose={() => setIsSheetOpen(false)}
          />
        </main>
      </div>

      <style>
        {`
        .mobile-search-grid {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 1rem;
          padding: 0;
        }
        
        .mobile-search-card {
          display: flex;
          flex-direction: column;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0 1px 3px rgba(0,0,0,0.1);
          background: white;
          height: 100%;
          cursor: pointer;
          transition: transform 0.2s ease, box-shadow 0.2s ease;
        }
        
        .mobile-search-card:active {
          transform: scale(0.98);
        }
        
        .card-image {
          height: 160px;
          position: relative;
          background-color: #f3f4f6;
        }
        
        .card-badge {
          position: absolute;
          top: 8px;
          left: 8px;
          background: linear-gradient(90deg, #F97316, #FBBF24);
          color: white;
          font-size: 0.6875rem;
          font-weight: 500;
          padding: 0.25rem 0.5rem;
          border-radius: 9999px;
          display: flex;
          align-items: center;
          box-shadow: 0 1px 2px rgba(0,0,0,0.1);
        }
        
        .card-content {
          padding: 12px;
          display: flex;
          flex-direction: column;
          flex: 1;
        }
        
        .card-title {
          font-weight: 600;
          font-size: 0.9375rem;
          margin-bottom: 4px;
          color: #1f2937;
        }
        
        .card-subtitle {
          display: flex;
          align-items: center;
          color: #6b7280;
          font-size: 0.75rem;
          margin-bottom: 8px;
        }
        
        .card-tags {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          margin-bottom: 8px;
        }
        
        .card-tag {
          background-color: #f3f4f6;
          color: #4b5563;
          font-size: 0.6875rem;
          padding: 2px 6px;
          border-radius: 4px;
        }
        
        .card-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: auto;
          padding-top: 8px;
          border-top: 1px solid #f3f4f6;
        }
        
        .card-rating {
          display: flex;
          align-items: center;
          font-size: 0.75rem;
          font-weight: 500;
          color: #4b5563;
        }
        
        .card-price {
          font-size: 0.75rem;
          font-weight: 600;
          color: #7c3aed;
        }
        `}
      </style>
    </div>
  );
};

export default Venues;
