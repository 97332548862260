
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/components/AuthProvider";
import CoachDashboard from "@/components/coaches/CoachDashboard";
import { toast } from "sonner";
import BackButton from "@/components/ui/back-button";
import LoadingScreen from "@/components/ui/loading-screen";

const CoachDashboardPage = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !user) {
      toast.error("Please log in to access your coach dashboard");
      navigate("/auth?redirect=/coach/dashboard");
    }
  }, [user, loading, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen bg-white pt-24 pb-16">
        <div className="max-w-6xl mx-auto px-4">
          <LoadingScreen size="md" />
        </div>
      </div>
    );
  }

  if (!user) {
    return null; // We'll redirect in the effect
  }

  return (
    <div className="min-h-screen bg-white pt-24 pb-16">
      <div className="max-w-6xl mx-auto px-4">
        <div className="mb-8">
          <BackButton />
        </div>

        <CoachDashboard />
      </div>
    </div>
  );
};

export default CoachDashboardPage;
