import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { TimeoutNotification } from "./timeout-notification";

interface LoadingScreenProps {
  message?: string;
  size?: "sm" | "md" | "lg" | "full";
  transparent?: boolean;
}

const LoadingScreen = ({ 
  size = "md", 
  transparent = false 
}: LoadingScreenProps) => {
  const [randomColor, setRandomColor] = useState(0);
  
  // Sports-themed color schemes
  const colorSchemes = [
    ["#00AB66", "#0099E5"], // Green to Blue (Field/Court colors)
    ["#F34423", "#FDB913"], // Red to Yellow (Basketball theme)
    ["#003594", "#FFB81C"], // Blue to Gold (Sports team colors)
    ["#D3003F", "#002F5F"]  // Red to Navy (Baseball theme)
  ];
  
  // Randomly select a color scheme on mount
  useEffect(() => {
    setRandomColor(Math.floor(Math.random() * colorSchemes.length));
  }, []);
  
  // Define size classes
  const sizeClasses = {
    sm: "h-20 w-20 p-4",
    md: "h-32 w-32 p-4",
    lg: "h-40 w-40 p-4",
    full: "fixed inset-0 z-50 flex items-center justify-center"
  };

  // For small, medium, and large sizes
  if (size !== "full") {
    return (
      <div className={`flex flex-col items-center justify-center gap-3 ${transparent ? 'bg-transparent' : 'bg-white/80 backdrop-blur-sm rounded-xl p-4'}`}>
        <div className={`relative ${sizeClasses[size]} flex flex-col items-center justify-center overflow-hidden rounded-full`}>
          {/* Background pulse effect */}
          <motion.div
            className="absolute inset-0 opacity-40 blur-md rounded-full"
            style={{ 
              background: `radial-gradient(circle, ${colorSchemes[randomColor][0]} 0%, ${colorSchemes[randomColor][1]} 100%)` 
            }}
            animate={{ 
              scale: [1, 1.1, 1],
              opacity: [0.4, 0.6, 0.4]
            }}
            transition={{ 
              duration: 0.7, 
              repeat: Infinity,
              ease: "easeInOut" 
            }}
          />
          
          {/* Spinning ball effect */}
          <motion.div
            className="absolute inset-0 opacity-70 rounded-full"
            style={{ 
              background: `conic-gradient(from 0deg, transparent, ${colorSchemes[randomColor][0]}, ${colorSchemes[randomColor][1]}, transparent)` 
            }}
            animate={{ rotate: 360 }}
            transition={{ 
              duration: 2, 
              repeat: Infinity,
              ease: "linear" 
            }}
          />
          
          {/* Sports motion dots */}
          {[...Array(3)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute h-2 w-2 bg-white rounded-full shadow-[0_0_10px_2px_rgba(255,255,255,0.8)]"
              style={{ 
                originX: '50%',
                originY: '50%',
              }}
              animate={{
                scale: [1, 1.3, 1],
                x: `${Math.cos(Math.PI * 2 / 3 * i) * 25}%`,
                y: `${Math.sin(Math.PI * 2 / 3 * i) * 25}%`,
              }}
              transition={{ 
                duration: 0.7 + i * 0.1, 
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut" 
              }}
            />
          ))}
          
          {/* Central ball */}
          <motion.div 
            className="h-10 w-10 rounded-full bg-white z-10 shadow-[0_0_20px_5px_rgba(255,255,255,0.8)]"
            style={{
              background: "radial-gradient(circle, #ffffff 0%, #f0f0f0 100%)"
            }}
            animate={{ 
              rotate: 360,
              boxShadow: [
                "0 0 20px 5px rgba(255,255,255,0.8)",
                "0 0 30px 8px rgba(255,255,255,0.9)",
                "0 0 20px 5px rgba(255,255,255,0.8)"
              ] 
            }}
            transition={{ 
              rotate: {
                duration: 3,
                repeat: Infinity,
                ease: "linear"
              },
              boxShadow: {
                duration: 1.5, 
                repeat: Infinity,
                ease: "easeInOut"
              }
            }}
          >
            {/* Ball pattern */}
            <motion.div
              className="absolute inset-0 rounded-full opacity-50"
              style={{
                background: "linear-gradient(to right, transparent 45%, #000 47%, #000 53%, transparent 55%)"
              }}
            />
          </motion.div>
        </div>
        
        {/* "beSportly - India plays here" note with animation */}
        <motion.div 
          className="flex flex-col items-center justify-center"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <motion.div 
            className="relative px-6 py-2 overflow-hidden rounded-full bg-gradient-to-r from-purple-600 to-pink-600 shadow-lg"
            whileHover={{ scale: 1.03 }}
          >
            {/* Animated shimmer effect */}
            <motion.div 
              className="absolute inset-0 bg-white opacity-20"
              animate={{
                x: ["-100%", "100%"],
                opacity: [0.1, 0.3, 0.1]
              }}
              transition={{
                x: { duration: 1.5, repeat: Infinity, ease: "linear" },
                opacity: { duration: 1.5, repeat: Infinity, ease: "easeInOut" }
              }}
            />
            
            <div className="relative flex flex-col items-center gap-0.5">
              <span className="text-white font-bold text-sm tracking-wider">beSportly</span>
              <span className="text-white/80 text-xs">India plays here</span>
            </div>
          </motion.div>
          
          <motion.p
            className="mt-2 text-center text-gray-600 text-xs max-w-36"
            animate={{
              opacity: [0.7, 1, 0.7]
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          >
            Just a second while we get everything ready...
          </motion.p>
        </motion.div>
        
        {/* Add timeout notification */}
        <TimeoutNotification 
          message="This is taking longer than expected. Please close and reopen the app to try again."
          isMobile={false}
        />
      </div>
    );
  }
  
  // For full-screen version
  return (
    <motion.div 
      className={`fixed inset-0 z-50 flex items-center justify-center ${transparent ? 'bg-transparent' : 'bg-black/90 backdrop-blur-md'}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="relative w-full h-full flex flex-col items-center justify-center overflow-hidden">
        {/* Sport team name - fades in and out - MOVED TO TOP */}
        <motion.div
          className="absolute top-24 md:top-32 left-1/2 -translate-x-1/2 text-center z-10 mb-16"
          animate={{
            opacity: [0, 1, 0.8]
          }}
          transition={{
            duration: 1.5,
            ease: "easeOut"
          }}
        >
          <h2 className="text-white font-bold text-2xl md:text-3xl tracking-wider">
            beSportly
          </h2>
          <p className="text-white/70 text-sm">
            India plays here
          </p>
        </motion.div>
        
        {/* Background gradient for sports field/court effect */}
        <motion.div 
          className="absolute inset-0 opacity-30"
          style={{ 
            background: `radial-gradient(circle at center, ${colorSchemes[randomColor][0]} 0%, transparent 70%)` 
          }}
          animate={{ 
            scale: [1, 1.05, 1],
            opacity: [0.3, 0.4, 0.3]
          }}
          transition={{ 
            duration: 2, 
            repeat: Infinity,
            ease: "easeInOut" 
          }}
        />
        
        {/* Sports court/field lines effect */}
        <div className="absolute inset-0 opacity-10">
          <motion.div
            className="absolute top-1/2 left-0 right-0 h-px bg-white"
            animate={{ opacity: [0.2, 0.5, 0.2] }}
            transition={{ duration: 3, repeat: Infinity }}
          />
          <motion.div
            className="absolute top-0 bottom-0 left-1/2 w-px bg-white"
            animate={{ opacity: [0.2, 0.5, 0.2] }}
            transition={{ duration: 3, repeat: Infinity }}
          />
          <motion.div
            className="absolute top-1/2 left-1/2 w-20 h-20 rounded-full border border-white -translate-x-1/2 -translate-y-1/2"
            animate={{ opacity: [0.2, 0.4, 0.2], scale: [1, 1.05, 1] }}
            transition={{ duration: 3, repeat: Infinity }}
          />
        </div>
        
        {/* Dynamic particles - represent athletes/movement */}
        <div className="absolute inset-0 overflow-hidden">
          {[...Array(12)].map((_, i) => (
            <motion.div
              key={`athlete-${i}`}
              className="absolute h-1 w-1 bg-white rounded-full"
              style={{ 
                left: `${Math.random() * 100}%`, 
                top: `${Math.random() * 100}%`,
                opacity: Math.random() * 0.7 + 0.3
              }}
              animate={{ 
                opacity: [0.3, 0.8, 0.3],
                scale: [1, 1.5, 1],
                x: [0, (Math.random() - 0.5) * 50, 0],
                y: [0, (Math.random() - 0.5) * 50, 0]
              }}
              transition={{ 
                duration: 2 + Math.random() * 3, 
                repeat: Infinity,
                delay: Math.random() * 1
              }}
            />
          ))}
        </div>
        
        {/* Central spinning ball system */}
        <div className="relative h-40 w-40 md:h-48 md:w-48 mt-8">
          {/* Outer spinning ring - track/field */}
          <motion.div 
            className="absolute top-1/2 left-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 rounded-full border-4 border-dashed transform-gpu"
            style={{ borderColor: colorSchemes[randomColor][0] }}
            animate={{ rotate: 360 }}
            transition={{ 
              duration: 12, 
              repeat: Infinity,
              ease: "linear" 
            }}
          />
          
          {/* Middle spinning ring - court boundary */}
          <motion.div 
            className="absolute top-1/2 left-1/2 h-3/4 w-3/4 -translate-x-1/2 -translate-y-1/2 rounded-full border-2 transform-gpu"
            style={{ borderColor: colorSchemes[randomColor][1] }}
            animate={{ rotate: -360 }}
            transition={{ 
              duration: 8, 
              repeat: Infinity,
              ease: "linear" 
            }}
          />
          
          {/* Inner spinning ring - game boundary */}
          <motion.div 
            className="absolute top-1/2 left-1/2 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full border transform-gpu"
            style={{ borderColor: "white" }}
            animate={{ rotate: 360 }}
            transition={{ 
              duration: 6, 
              repeat: Infinity,
              ease: "linear" 
            }}
          />
          
          {/* Central ball with spinning pattern */}
          <motion.div 
            className="absolute top-1/2 left-1/2 h-1/4 w-1/4 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white shadow-[0_0_30px_10px_rgba(255,255,255,0.7)]"
            animate={{ 
              scale: [1, 1.1, 1],
              rotate: 360,
              boxShadow: [
                "0 0 30px 10px rgba(255,255,255,0.7)",
                "0 0 40px 15px rgba(255,255,255,0.9)",
                "0 0 30px 10px rgba(255,255,255,0.7)"
              ]
            }}
            transition={{ 
              scale: {
                duration: 1.5, 
                repeat: Infinity,
                ease: "easeInOut"
              },
              rotate: {
                duration: 8,
                repeat: Infinity,
                ease: "linear" 
              },
              boxShadow: {
                duration: 1.5, 
                repeat: Infinity,
                ease: "easeInOut"
              }
            }}
          >
            {/* Ball pattern */}
            <motion.div
              className="absolute inset-0 rounded-full opacity-50"
              style={{
                background: "linear-gradient(to right, transparent 45%, #000 47%, #000 53%, transparent 55%)"
              }}
            />
          </motion.div>
          
          {/* Orbiting elements - represent players/balls */}
          {[...Array(4)].map((_, i) => (
            <motion.div
              key={`orbit-${i}`}
              className="absolute top-1/2 left-1/2 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rounded-full shadow-[0_0_10px_2px_rgba(255,255,255,0.6)]"
              style={{
                background: i % 2 === 0 ? colorSchemes[randomColor][0] : colorSchemes[randomColor][1],
              }}
              animate={{ 
                x: Math.cos(Math.PI * 2 / 4 * i) * 80,
                y: Math.sin(Math.PI * 2 / 4 * i) * 80,
                scale: [1, 1.2, 1],
                opacity: [0.7, 1, 0.7]
              }}
              transition={{ 
                duration: 2 + i * 0.5, 
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
                delay: i * 0.2
              }}
            />
          ))}
        </div>
        
        {/* Add timeout notification */}
        <div className="absolute bottom-20 left-0 right-0 flex justify-center">
          <TimeoutNotification 
            message="This is taking longer than expected. Please close and reopen the app to try again."
            isMobile={false}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default LoadingScreen;
