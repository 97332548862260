
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { supabase } from "@/integrations/supabase/client";
import { Activity, Calendar, Dumbbell, Award, TrendingUp } from "lucide-react";
import { useAuth } from "@/components/AuthProvider";
import { useIsMobile } from "@/hooks/use-mobile";

const MobileStatsWidget = () => {
  const { isAuthenticated, user } = useAuth();
  const isMobile = useIsMobile();
  const [stats, setStats] = useState({
    bookings: 0,
    fitnessActivities: 0,
    memberships: 0,
    events: 0,
  });
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("Hi there!");

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!isAuthenticated || !user) return;
      
      try {
        const { data, error } = await supabase
          .from("profiles")
          .select("full_name, username")
          .eq("id", user.id)
          .single();
          
        if (error) {
          console.error("Error fetching profile:", error);
          return;
        }
        
        if (data) {
          // Use full_name if available, otherwise fallback to username, then default
          const displayName = data.full_name || data.username || "Hi there!";
          setUsername(displayName);
        }
      } catch (error) {
        console.error("Error in fetchUserProfile:", error);
      }
    };
    
    fetchUserProfile();
  }, [isAuthenticated, user]);

  useEffect(() => {
    const fetchUserStats = async () => {
      try {
        setLoading(true);
        
        // Check for authenticated state before proceeding
        if (!isAuthenticated || !user) {
          console.log("Not authenticated, skipping stats fetch");
          setLoading(false);
          return;
        }

        console.log("Fetching stats for user:", user.id);

        // Fetch user's bookings (confirmed only)
        const { data: bookingsData, error: bookingsError } = await supabase
          .from("bookings")
          .select("id")
          .eq("user_id", user.id)
          .eq("status", "confirmed");
          
        if (bookingsError) console.error("Error fetching bookings:", bookingsError);
        
        // Fetch user's fitness activities (exercise logs + meal logs)
        const { data: exerciseData, error: exerciseError } = await supabase
          .from("exercise_logs")
          .select("id")
          .eq("user_id", user.id);
          
        if (exerciseError) console.error("Error fetching exercise logs:", exerciseError);
        
        const { data: mealData, error: mealError } = await supabase
          .from("meal_logs")
          .select("id")
          .eq("user_id", user.id);
          
        if (mealError) console.error("Error fetching meal logs:", mealError);
        
        // Fetch user's memberships
        const { data: membershipsData, error: membershipsError } = await supabase
          .from("memberships_purchased")
          .select("id")
          .eq("user_id", user.id);
          
        if (membershipsError) console.error("Error fetching memberships:", membershipsError);

        // Get mobile bookings if on mobile
        let mobileBookingsCount = 0;
        if (isMobile && user) {
          try {
            const userBookingsKey = `mobile_bookings_${user.id}`;
            const mobileBookings = JSON.parse(localStorage.getItem(userBookingsKey) || '[]');
            mobileBookingsCount = mobileBookings.length;
            console.log("Found mobile bookings:", mobileBookingsCount);
          } catch (error) {
            console.error("Error parsing mobile bookings:", error);
          }
        }

        // Update the stats
        setStats({
          bookings: (bookingsData?.length || 0) + mobileBookingsCount,
          fitnessActivities: (exerciseData?.length || 0) + (mealData?.length || 0),
          memberships: membershipsData?.length || 0,
          events: 0, // Placeholder as we don't have events table yet
        });
        
      } catch (error) {
        console.error("Error fetching user stats:", error);
      } finally {
        setLoading(false);
      }
    };

    // Only fetch stats when authenticated
    if (isAuthenticated) {
      fetchUserStats();
    } else {
      setLoading(false);
    }
  }, [isAuthenticated, user, isMobile]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full overflow-hidden rounded-2xl shadow-md bg-gradient-to-br from-white/90 to-white/70 backdrop-blur-md border border-white/20"
    >
      <div className="relative w-full h-full">
        {/* Background gradient */}
        <div className="absolute inset-0 opacity-10 bg-gradient-to-r from-blue-400 via-purple-400 to-indigo-400 animate-gradient-x" />
        
        {/* Content */}
        <div className="relative px-3 py-4">
          <div className="flex items-center space-x-2 mb-2">
            <div className="flex items-center justify-center h-6 w-6 rounded-full bg-gradient-to-r from-blue-500 to-indigo-500 text-white">
              <Activity className="h-3.5 w-3.5" />
            </div>
            <span className="text-xs font-semibold text-gray-700 uppercase tracking-wider">
              My Stats - {username}
            </span>
          </div>
          
          {loading ? (
            <div className="flex justify-center items-center h-16">
              <div className="w-5 h-5 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-2">
              <div className="flex items-center space-x-2 bg-white/60 backdrop-blur-sm rounded-lg p-2">
                <div className="flex-shrink-0 w-8 h-8 rounded-md bg-gradient-to-br from-blue-400 to-indigo-500 flex items-center justify-center text-white">
                  <Calendar className="w-4 h-4" />
                </div>
                <div>
                  <p className="text-xs text-gray-600">Bookings</p>
                  <p className="text-sm font-bold text-gray-900">{stats.bookings}</p>
                </div>
              </div>
              
              <div className="flex items-center space-x-2 bg-white/60 backdrop-blur-sm rounded-lg p-2">
                <div className="flex-shrink-0 w-8 h-8 rounded-md bg-gradient-to-br from-pink-400 to-purple-500 flex items-center justify-center text-white">
                  <Dumbbell className="w-4 h-4" />
                </div>
                <div>
                  <p className="text-xs text-gray-600">Fitness Logs</p>
                  <p className="text-sm font-bold text-gray-900">{stats.fitnessActivities}</p>
                </div>
              </div>
              
              <div className="flex items-center space-x-2 bg-white/60 backdrop-blur-sm rounded-lg p-2">
                <div className="flex-shrink-0 w-8 h-8 rounded-md bg-gradient-to-br from-emerald-400 to-teal-500 flex items-center justify-center text-white">
                  <Award className="w-4 h-4" />
                </div>
                <div>
                  <p className="text-xs text-gray-600">Memberships</p>
                  <p className="text-sm font-bold text-gray-900">{stats.memberships}</p>
                </div>
              </div>
              
              <div className="flex items-center space-x-2 bg-white/60 backdrop-blur-sm rounded-lg p-2">
                <div className="flex-shrink-0 w-8 h-8 rounded-md bg-gradient-to-br from-orange-400 to-amber-500 flex items-center justify-center text-white">
                  <TrendingUp className="w-4 h-4" />
                </div>
                <div>
                  <p className="text-xs text-gray-600">Events</p>
                  <p className="text-sm font-bold text-gray-900">{stats.events}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default MobileStatsWidget;

