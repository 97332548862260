import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Mic, StopCircle, Wand2, X, Loader2, Sparkles, Bot, MessageCircle } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";

import AudioVisualizer from "./AudioVisualizer";
import { VoiceParticles } from "./VoiceParticles";
import { useIsMobile } from "@/hooks/use-mobile";

interface VoiceNavigationProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const VoiceNavigation = ({ open, onOpenChange }: VoiceNavigationProps) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleClose = () => {
    onOpenChange(false);
  };

  const navigateToAICoaching = () => {
    onOpenChange(false);
    navigate('/ai-coaching');
  };

  const navigateToAISupport = () => {
    onOpenChange(false);
    navigate('/ai-support');
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent 
        className="max-w-[95vw] sm:max-w-[500px] p-0 border-none bg-transparent shadow-none"
        onInteractOutside={(e) => e.preventDefault()}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key="record"
            className="relative bg-black/90 backdrop-blur-md border border-purple-500/40 rounded-3xl overflow-hidden"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3, ease: [0.22, 1, 0.36, 1] }}
          >
            <button 
              onClick={handleClose} 
              className="absolute top-3 right-3 z-30 w-8 h-8 rounded-full bg-white/10 flex items-center justify-center"
              aria-label="Close"
            >
              <X className="w-4 h-4 text-white/70" />
            </button>
            
            <div className="relative z-10">
              <DialogTitle className="text-center text-white text-xl sm:text-2xl font-bold pt-8 pb-3">
                Coming Soon!
              </DialogTitle>
              
              <div className="flex flex-col items-center justify-center px-6 py-6 sm:py-8">
                <motion.div 
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay: 0.2, duration: 0.4 }}
                  className="relative w-24 h-24 sm:w-28 sm:h-28 mb-6 rounded-full bg-gradient-to-br from-[#ff00ff] to-[#00bfff] flex items-center justify-center"
                >
                  <div className="absolute inset-0 rounded-full bg-gradient-to-r from-[#ff00ff] to-[#00bfff] opacity-30 animate-pulse"></div>
                  <Wand2 className="w-10 h-10 sm:w-12 sm:h-12 text-white" />
                  <motion.div
                    className="absolute -right-1 -top-1 w-8 h-8 rounded-full bg-yellow-400 flex items-center justify-center"
                    animate={{ 
                      scale: [1, 1.2, 1],
                    }}
                    transition={{ 
                      repeat: Infinity,
                      duration: 2
                    }}
                  >
                    <Sparkles className="w-5 h-5 text-white" />
                  </motion.div>
                </motion.div>
                
                <motion.h2
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3, duration: 0.4 }}
                  className="text-xl sm:text-2xl font-bold text-white mb-2 text-center"
                >
                  AI Voice Navigation
                </motion.h2>
                
                <motion.p
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.4, duration: 0.4 }}
                  className="text-white/80 text-center mb-8 max-w-[90%]"
                >
                  We're adding AI-based voice search in a future update! Tell us what you want, and we'll take you there.
                </motion.p>
                
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.5, duration: 0.4 }}
                  className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full px-4 sm:px-6"
                >
                  <Button
                    onClick={navigateToAICoaching}
                    className="relative group overflow-hidden px-4 py-3 h-auto rounded-xl bg-gradient-to-r from-indigo-500 to-purple-600 text-white font-medium hover:shadow-lg"
                  >
                    <div className="absolute inset-0 bg-white/20 opacity-0 group-hover:opacity-100 transition-opacity" />
                    <div className="absolute -inset-[1px] bg-gradient-to-r from-indigo-300 to-purple-300 rounded-xl opacity-0 blur-[2px] group-hover:opacity-30 transition-opacity" />
                    <div className="flex items-center justify-center gap-2">
                      <Bot className="w-5 h-5" />
                      <span>AI Coaching</span>
                    </div>
                  </Button>
                  
                  <Button
                    onClick={navigateToAISupport}
                    className="relative group overflow-hidden px-4 py-3 h-auto rounded-xl bg-gradient-to-r from-cyan-500 to-blue-600 text-white font-medium hover:shadow-lg"
                  >
                    <div className="absolute inset-0 bg-white/20 opacity-0 group-hover:opacity-100 transition-opacity" />
                    <div className="absolute -inset-[1px] bg-gradient-to-r from-cyan-300 to-blue-300 rounded-xl opacity-0 blur-[2px] group-hover:opacity-30 transition-opacity" />
                    <div className="flex items-center justify-center gap-2">
                      <MessageCircle className="w-5 h-5" />
                      <span>AI Support</span>
                    </div>
                  </Button>
                </motion.div>
                
                <motion.p
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.6, duration: 0.4 }}
                  className="text-white/60 text-sm text-center mt-8 max-w-[80%]"
                >
                  Stay tuned for our voice-powered features coming soon!
                </motion.p>
              </div>
            </div>
            
            <VoiceParticles />
          </motion.div>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
};

export default VoiceNavigation;
