import { Gamepad2, Users, Trophy, List, Calendar, Bot, Ticket, Building2, HelpCircle, ArrowLeft, Lock, Medal } from "lucide-react";
import { Card } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/components/AuthProvider";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useState } from "react";
import BracketGPT from "./BracketGPT";
import { useToast } from "@/hooks/use-toast";

const EventsAndGames = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showBracketGPT, setShowBracketGPT] = useState(false);
  const { toast } = useToast();

  const handleCardClick = (link: string, requiresAuth: boolean) => {
    if (requiresAuth && !user) {
      toast({
        title: "Authentication Required",
        description: "Please sign in to create a game",
        variant: "destructive",
      });
      navigate("/auth");
      return;
    }
    navigate(link);
  };

  const features = [
    {
      title: "Game",
      icon: Gamepad2,
      description: "Create a single game between two teams or players",
      gradient: "bg-gradient-to-br from-purple-50 to-purple-100",
      comingSoon: true,
    },
    {
      title: "League",
      icon: Users,
      description: "Set up a league where everyone plays against each other",
      gradient: "bg-gradient-to-br from-blue-50 to-blue-100",
      comingSoon: true,
    },
    {
      title: "Tournament",
      icon: Trophy,
      description: "Organize a knockout tournament with multiple participants",
      gradient: "bg-gradient-to-br from-green-50 to-green-100",
      comingSoon: true,
    },
    {
      title: "Group + Knockout",
      icon: List,
      description: "Create a mixed format with group stages and knockout rounds",
      comingSoon: true,
      gradient: "bg-gradient-to-br from-orange-50 to-orange-100",
    },
    {
      title: "League + Knockout",
      icon: Calendar,
      description: "Start with a league phase followed by knockout rounds",
      comingSoon: true,
      gradient: "bg-gradient-to-br from-pink-50 to-pink-100",
    },
    {
      title: "BracketGPT",
      icon: Bot,
      description: "AI event planning assistant",
      gradient: "bg-gradient-to-br from-violet-50 to-violet-100",
      requiresAuth: true,
      onClick: () => user && setShowBracketGPT(true),
    },
    {
      title: "Host an Event",
      icon: Ticket,
      description: "Create ticketed events with registration and payment processing",
      comingSoon: true,
      gradient: "bg-gradient-to-br from-blue-50 to-blue-100",
    },
    {
      title: "Find Things Near You",
      icon: Building2,
      description: "Connect with others and find things happening in your city",
      comingSoon: true,
      gradient: "bg-gradient-to-br from-cyan-50 to-cyan-100",
    },
    {
      title: "Support",
      icon: HelpCircle,
      description: "Get help and learn how to use our platform effectively",
      gradient: "bg-gradient-to-br from-green-50 to-green-100",
      link: "/support",
    },
    {
      title: "My Competitions",
      icon: Medal,
      description: "View and manage your competitions",
      gradient: "bg-gradient-to-br from-amber-50 to-amber-100",
      requiresAuth: true,
      onClick: () => {
        if (user) {
          navigate("/competitions");
        } else {
          toast({
            title: "Authentication Required",
            description: "Please sign in to view your competitions",
            variant: "destructive",
          });
        }
      },
    },
  ];

  return (
    <div className="min-h-screen bg-white pt-16 md:pt-24 pb-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="mb-8">
          <Button
            variant="ghost"
            onClick={() => navigate("/")}
            className="flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back
          </Button>
        </div>

        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="dark:text-black">Create and track</span>
            <div className="mt-2">
              <span className="text-[#F06A6A] dark:text-black">events</span>
              <span className="text-gray-900 dark:text-black">, </span> 
              <span className="text-[#4A90E2] dark:text-black">games</span>
              <span className="text-gray-900 dark:text-black">, </span>
              <span className="text-[#FF69B4] dark:text-black">leagues</span>
              <span className="text-gray-900 dark:text-black"> and </span>
              <span className="text-[#F06A6A] dark:text-black">tournaments</span>
            </div>
          </h1>
          <div className="relative w-full h-48 mb-8 rounded-lg overflow-hidden">
            <img
              src="https://images.unsplash.com/photo-1461896836934-ffe607ba8211?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
              alt="Sports"
              className="w-full h-full object-cover"
            />
          </div>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Create and manage sporting events with ease. Track scores, generate fixtures, and share results in real-time.
          </p>
        </div>

        <Dialog open={showBracketGPT} onOpenChange={setShowBracketGPT}>
          <DialogContent className="max-w-full h-screen p-0">
            <BracketGPT onClose={() => setShowBracketGPT(false)} />
          </DialogContent>
        </Dialog>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature) => (
            <Card
              key={feature.title}
              className={`relative p-6 cursor-pointer transition-transform duration-200 hover:scale-105 ${feature.gradient} border-none`}
              onClick={() => {
                if (feature.onClick) {
                  feature.onClick();
                } else if (feature.link && !feature.comingSoon) {
                  handleCardClick(feature.link, feature.requiresAuth || false);
                }
              }}
            >
              {feature.comingSoon && (
                <span className="absolute top-4 right-4 text-xs font-medium text-blue-600 bg-blue-100 px-2 py-1 rounded-full">
                  Coming Soon
                </span>
              )}
              {feature.requiresAuth && !user && (
                <span className="absolute top-4 right-4 flex items-center text-xs font-medium text-amber-600 bg-amber-100 px-2 py-1 rounded-full">
                  <Lock className="w-3 h-3 mr-1" />
                  Must Sign In
                </span>
              )}
              <div className="flex items-center mb-4">
                <feature.icon className="w-6 h-6 text-blue-600 mr-2" />
                <h3 className="text-xl font-semibold text-gray-900">{feature.title}</h3>
              </div>
              <p className="text-gray-600">{feature.description}</p>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventsAndGames;
