import { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Brain } from "lucide-react";

export const AIInsights = ({ refreshTrigger }: { refreshTrigger?: number }) => {
  const { user } = useAuth();
  const [insights, setInsights] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) return;
    fetchInsights();
  }, [user, refreshTrigger]); // Add refreshTrigger to dependencies

  const fetchInsights = async () => {
    try {
      // Fetch recent exercise logs
      const { data: exerciseLogs } = await supabase
        .from("exercise_logs")
        .select("*")
        .eq("user_id", user?.id)
        .order("log_date", { ascending: false })
        .limit(10);

      // Fetch recent meal logs
      const { data: mealLogs } = await supabase
        .from("meal_logs")
        .select("*")
        .eq("user_id", user?.id)
        .order("meal_time", { ascending: false })
        .limit(10);

      // Fetch recent weight logs
      const { data: weightLogs } = await supabase
        .from("weight_logs")
        .select("*")
        .eq("user_id", user?.id)
        .order("log_date", { ascending: false })
        .limit(10);

      // Generate insights based on the data
      const newInsights = [];

      if (exerciseLogs?.length) {
        const totalCaloriesBurned = exerciseLogs.reduce(
          (sum, log) => sum + log.calories_burned,
          0
        );
        newInsights.push(
          `You've burned ${totalCaloriesBurned} calories in your last ${exerciseLogs.length} workouts. Keep up the great work!`
        );

        const exerciseTypes = new Set(exerciseLogs.map((log) => log.exercise_type));
        if (exerciseTypes.size < 3) {
          newInsights.push(
            "Consider diversifying your workout routine by trying different types of exercises."
          );
        }
      }

      if (mealLogs?.length) {
        const totalCalories = mealLogs.reduce(
          (sum, log) => sum + log.calories,
          0
        );
        const avgCalories = Math.round(totalCalories / mealLogs.length);
        newInsights.push(
          `Your average caloric intake is ${avgCalories} calories per meal.`
        );
      }

      if (weightLogs?.length >= 2) {
        const latestWeight = weightLogs[0].weight;
        const previousWeight = weightLogs[1].weight;
        const weightDiff = latestWeight - previousWeight;
        const trend = weightDiff > 0 ? "gained" : "lost";
        newInsights.push(
          `You've ${trend} ${Math.abs(weightDiff).toFixed(1)} kg since your previous weigh-in.`
        );
      }

      if (newInsights.length === 0) {
        newInsights.push(
          "Start logging your exercises, meals, and weight to receive personalized insights!"
        );
      }

      setInsights(newInsights);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching insights:", error);
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading insights...</div>;
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center gap-4">
          <Brain className="w-6 h-6 text-purple-500" />
          <CardTitle>AI Health Insights</CardTitle>
        </div>
      </CardHeader>
      <CardContent>
        <ul className="space-y-4">
          {insights.map((insight, index) => (
            <li
              key={index}
              className="flex items-start gap-2 text-sm text-gray-600"
            >
              <span className="text-purple-500">•</span>
              {insight}
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};