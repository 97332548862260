
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";
import Hero from "../components/Hero";
import FeaturedVenues from "../components/FeaturedVenues";
import FeaturedCoaches from "../components/FeaturedCoaches";
import FeaturedGyms from "../components/FeaturedGyms";
import FeaturedAcademies from "../components/FeaturedAcademies";
import FeatureHighlights from "../components/FeatureHighlights";
import LoadingScreen from "@/components/ui/loading-screen";
import MobileLoadingScreen from "@/components/ui/mobile-loading-screen";
import { useIsMobile } from "@/hooks/use-mobile";
import OffersBanner from "@/components/offers/OffersBanner";
import DiscountBanner from "@/components/offers/DiscountBanner";

const Index = () => {
  const [showSplash, setShowSplash] = useState(() => {
    const hasVisited = sessionStorage.getItem('hasVisitedHome');
    if (!hasVisited) {
      sessionStorage.setItem('hasVisitedHome', 'true');
      return true;
    }
    return false;
  });
  const isMobile = useIsMobile();

  useEffect(() => {
    if (showSplash) {
      const timer = setTimeout(() => {
        setShowSplash(false);
      }, 1500); // Changed from 2500 to 1500 ms (1.5 seconds)

      return () => clearTimeout(timer);
    }
  }, [showSplash]);

  return (
    <div className="min-h-screen flex flex-col bg-white font-['Quicksand']">
      <AnimatePresence>
        {showSplash && (
          <motion.div
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black z-50 flex items-center justify-center"
          >
            {isMobile ? <MobileLoadingScreen /> : <LoadingScreen size="full" />}
          </motion.div>
        )}
      </AnimatePresence>

      {/* Targeted discount banner for eligible users */}
      <DiscountBanner />

      {/* Offers Banner - only appears on mobile */}
      <OffersBanner />

      <main className="flex-grow">
        <div className="absolute inset-0 z-0">
          <div className="w-full h-full bg-gradient-to-r from-[#ff00ff] to-[#00bfff] opacity-10 animate-gradient">
            <div className="absolute inset-0 bg-gradient-to-b from-white via-white to-transparent"></div>
          </div>
        </div>
        
        <Hero />
        
        <div className="md:mt-0 mt-[-1rem]">
          <FeatureHighlights />
        </div>
        
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-br from-[#accbee] to-[#e7f0fd] opacity-20"></div>
          <FeaturedVenues />
        </div>
        
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-[#D6BCFA] to-[#9F7AEA] opacity-20"></div>
          <FeaturedCoaches />
        </div>
        
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-[#FDE1D3] to-[#F97316] opacity-20"></div>
          <FeaturedGyms />
        </div>
        
        <div className="relative overflow-hidden md:mb-0">
          <div className="absolute inset-0 bg-gradient-to-br from-[#FFE29F] to-[#FF719A] opacity-20"></div>
          <FeaturedAcademies />
          {/* Add peach-colored spacer div for mobile only */}
          <div className="h-[2px] bg-[#FDE1D3] md:hidden" />
        </div>
      </main>
    </div>
  );
};

export default Index;
