import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Calendar, Clock, MapPin, Building, RefreshCw, CheckCircle, Clock4, AlertCircle, Mail, MessageSquare, Info, User, Ticket, ArrowRight, Phone, Send, HelpCircle } from "lucide-react";
import { format, addHours, isPast, isFuture } from "date-fns";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/components/AuthProvider";
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import LoadingScreen from "@/components/ui/loading-screen";
import { toast } from "sonner";
import { RescheduleDialog } from "@/components/booking/RescheduleDialog";
import { motion } from "framer-motion";
import { useIsMobile } from "@/hooks/use-mobile";
import { SupportInfoDialog } from "@/components/booking/SupportInfoDialog";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";

const logMobileBooking = async (logType: string, message: string, bookingId?: string, userId?: string, details?: any) => {
  try {
    await supabase.functions.invoke('log-mobile-booking', {
      body: { 
        log_type: logType, 
        message, 
        booking_id: bookingId, 
        user_id: userId,
        details: details || {}
      }
    });
  } catch (error) {
    console.error("Error logging:", error);
  }
};

interface Booking {
  id: string;
  venue_id: string;
  booking_date: string;
  start_time: string;
  end_time: string;
  total_amount: number;
  status: string;
  payment_status: string;
  facility_name: string;
  venue: {
    name: string;
    address: string;
    city: string;
  };
  from_mobile?: boolean;
  timestamp?: number;
  migrated_to_db?: boolean;
}

const MyBookings = () => {
  const { user, refreshSession } = useAuth();
  const navigate = useNavigate();
  const [retryCount, setRetryCount] = useState(0);
  const [isRescheduleOpen, setIsRescheduleOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [bookingsData, setBookingsData] = useState<Booking[]>([]);
  const [activeTab, setActiveTab] = useState<'upcoming' | 'past' | 'venue-passes'>('upcoming');
  const isMobile = useIsMobile();
  const [migrationResults, setMigrationResults] = useState<Record<string, string>>({});
  const [isSupportDialogOpen, setIsSupportDialogOpen] = useState(false);

  useEffect(() => {
    console.log("MyBookings mounted, user state:", !!user);
    
    if (!user && retryCount < 1) {
      console.log("No user detected but on MyBookings page, attempting session refresh");
      refreshSession();
      setRetryCount(prev => prev + 1);
    }
    
    if (!user && retryCount >= 1) {
      console.log("Still no user after refresh attempt, redirecting to auth");
      navigate("/auth");
    }
  }, [user, navigate, retryCount, refreshSession]);

  const { data: fetchedBookings, isLoading, error, refetch } = useQuery({
    queryKey: ["bookings", user?.id, refreshTrigger],
    queryFn: async () => {
      console.log("Fetching bookings for user:", user?.id);
      try {
        const { data, error } = await supabase
          .from("bookings")
          .select(`
            *,
            venue:venues(name, address, city)
          `)
          .eq("user_id", user?.id)
          .eq("status", "confirmed")
          .order("booking_date", { ascending: false });

        if (error) {
          console.error("Error fetching bookings:", error);
          throw error;
        }
        
        console.log(`Successfully fetched ${data?.length || 0} bookings`);
        return data as Booking[];
      } catch (err) {
        console.error("Exception during bookings fetch:", err);
        throw err;
      }
    },
    enabled: !!user?.id,
    retry: 2,
    retryDelay: 1000,
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const migrateMobileBooking = async (booking: Booking) => {
    if (!user || booking.migrated_to_db || !booking.from_mobile) {
      return false;
    }

    try {
      await logMobileBooking(
        "attempt", 
        "Attempting to migrate mobile booking to database", 
        booking.id,
        user.id,
        {
          bookingId: booking.id,
          venueId: booking.venue_id,
          userId: user.id
        }
      );
      
      const { data, error } = await supabase.rpc('migrate_mobile_booking', {
        p_booking_id: booking.id,
        p_venue_id: booking.venue_id,
        p_user_id: user.id,
        p_booking_date: booking.booking_date,
        p_start_time: booking.start_time,
        p_end_time: booking.end_time,
        p_total_amount: booking.total_amount,
        p_facility_name: booking.facility_name
      });
      
      if (error) {
        await logMobileBooking(
          "error", 
          "Error migrating mobile booking to database", 
          booking.id,
          user.id,
          { error: error.message }
        );
        
        setMigrationResults(prev => ({
          ...prev, 
          [booking.id]: `Error: ${error.message}`
        }));
        return false;
      }
      
      await logMobileBooking(
        "success", 
        "Mobile booking database migration result", 
        booking.id,
        user.id,
        { result: data }
      );
      
      setMigrationResults(prev => ({
        ...prev, 
        [booking.id]: data === true ? "Success: Booking migrated to database" : "Info: Booking already exists in database"
      }));
      
      if (data === true) {
        const userBookingsKey = `mobile_bookings_${user.id}`;
        const mobileBookings = JSON.parse(localStorage.getItem(userBookingsKey) || '[]');
        const updatedMobileBookings = mobileBookings.map((mb: Booking) => 
          mb.id === booking.id ? {...mb, migrated_to_db: true} : mb
        );
        localStorage.setItem(userBookingsKey, JSON.stringify(updatedMobileBookings));
        
        setBookingsData(prev => 
          prev.map(b => b.id === booking.id ? {...b, migrated_to_db: true} : b)
        );
      }
      
      return data === true;
    } catch (error) {
      await logMobileBooking(
        "exception", 
        "Exception during database migration", 
        booking.id,
        user.id,
        { error: (error as Error).message }
      );
      
      setMigrationResults(prev => ({
        ...prev, 
        [booking.id]: `Exception: ${(error as Error).message}`
      }));
      return false;
    }
  };

  useEffect(() => {
    if (!user || !isMobile || bookingsData.length === 0) return;
    
    const mobileBookingsToMigrate = bookingsData.filter(
      b => b.from_mobile && !b.migrated_to_db && !isPast(new Date(b.booking_date))
    );
    
    if (mobileBookingsToMigrate.length === 0) return;
    
    logMobileBooking(
      "info", 
      `Found ${mobileBookingsToMigrate.length} mobile bookings to migrate to database`,
      undefined,
      user.id
    );
    
    let index = 0;
    const processNextBooking = async () => {
      if (index >= mobileBookingsToMigrate.length) return;
      
      const booking = mobileBookingsToMigrate[index];
      await migrateMobileBooking(booking);
      
      index++;
      setTimeout(processNextBooking, 500);
    };
    
    setTimeout(processNextBooking, 1000);
  }, [bookingsData, user, isMobile]);

  useEffect(() => {
    if (fetchedBookings) {
      console.log("Updating bookings state with fetched data:", fetchedBookings);
      
      if (isMobile && user) {
        try {
          const userBookingsKey = `mobile_bookings_${user.id}`;
          const mobileBookings = JSON.parse(localStorage.getItem(userBookingsKey) || '[]');
          console.log("Retrieved user-specific mobile bookings from localStorage:", mobileBookings);
          
          if (mobileBookings.length > 0) {
            const existingIds = new Set(fetchedBookings.map(b => b.id));
            
            const uniqueMobileBookings = mobileBookings.filter(
              (mb: Booking) => !existingIds.has(mb.id)
            );
            
            if (uniqueMobileBookings.length > 0) {
              const flaggedMobileBookings = uniqueMobileBookings.map((booking: Booking) => ({
                ...booking,
                from_mobile: true,
                venue: {
                  ...booking.venue,
                  address: booking.venue.address || "Search on Google Maps",
                  city: booking.venue.city || ""
                }
              }));
              
              setBookingsData([...fetchedBookings, ...flaggedMobileBookings]);
              return;
            }
          }
        } catch (err) {
          console.error("Error processing mobile bookings:", err);
        }
      }
      
      setBookingsData(fetchedBookings);
    }
  }, [fetchedBookings, isMobile, user]);

  useEffect(() => {
    if (error) {
      console.error("Query error in MyBookings:", error);
      toast.error("Failed to load bookings. Tap to retry.", {
        duration: 4000,
        action: {
          label: "Retry",
          onClick: () => {
            refreshSession().then(() => refetch());
          }
        }
      });
    }
  }, [error, refetch, refreshSession]);

  useEffect(() => {
    if (Object.keys(migrationResults).length > 0) {
      console.log("Mobile booking migration results:", migrationResults);
    }
  }, [migrationResults]);

  const handleReschedule = (booking: Booking) => {
    setSelectedBooking(booking);
    setIsRescheduleOpen(true);
  };

  const handleRescheduleComplete = (updatedBooking?: {
    id: string;
    booking_date: string;
    start_time: string;
    end_time: string;
  }) => {
    console.log("Rescheduling complete, refreshing bookings list");
    
    if (updatedBooking) {
      console.log("Received updated booking data:", updatedBooking);
      
      setBookingsData(currentBookings => 
        currentBookings.map(booking => 
          booking.id === updatedBooking.id ? 
            { 
              ...booking, 
              booking_date: updatedBooking.booking_date,
              start_time: updatedBooking.start_time,
              end_time: updatedBooking.end_time 
            } : 
            booking
        )
      );
      
      toast.success("Booking successfully rescheduled!", {
        duration: 3000,
      });
    }
    
    setRefreshTrigger(prev => prev + 1);
    setIsRescheduleOpen(false);
    setSelectedBooking(null);
  };

  const handleRebook = (venueId: string) => {
    navigate(`/book-venue/${venueId}`);
  };

  const isWithinRescheduleWindow = (bookingDate: string, startTime: string, fromMobile?: boolean): boolean => {
    if (fromMobile) return false;
    
    const [hours, minutes] = startTime.split(':').map(Number);
    const bookingDateTime = new Date(bookingDate);
    bookingDateTime.setHours(hours, minutes, 0, 0);
    
    const currentTimePlus8Hours = addHours(new Date(), 8);
    
    return bookingDateTime > currentTimePlus8Hours;
  };

  const isBookingInPast = (bookingDate: string, startTime: string): boolean => {
    const [hours, minutes] = startTime.split(':').map(Number);
    const bookingDateTime = new Date(bookingDate);
    bookingDateTime.setHours(hours, minutes, 0, 0);
    
    return isPast(bookingDateTime);
  };

  const isUpcomingSoon = (bookingDate: string, startTime: string): boolean => {
    const [hours, minutes] = startTime.split(':').map(Number);
    const bookingDateTime = new Date(bookingDate);
    bookingDateTime.setHours(hours, minutes, 0, 0);
    
    const currentTimePlus8Hours = addHours(new Date(), 8);
    
    return isFuture(bookingDateTime) && bookingDateTime <= currentTimePlus8Hours;
  };

  const filteredBookings = bookingsData.filter(booking => {
    const isPastBooking = isBookingInPast(booking.booking_date, booking.start_time);
    return activeTab === 'past' ? isPastBooking : 
           activeTab === 'upcoming' ? !isPastBooking : 
           false; // No filtering for venue-passes tab yet
  });

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingScreen size="md" />
      </div>
    );
  }

  return (
    <div className="min-h-screen pt-32 pb-16 bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      <div className="max-w-4xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="mb-8 flex flex-col md:flex-row md:items-center md:justify-between"
        >
          <h1 className="text-3xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">My Bookings</h1>
          
          {bookingsData.length > 0 && (
            <div className="mt-4 md:mt-0">
              <ToggleGroup 
                type="single" 
                value={activeTab}
                onValueChange={(value) => {
                  if (value) setActiveTab(value as 'upcoming' | 'past' | 'venue-passes');
                }}
                className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-purple-100 dark:border-purple-900/30 p-1"
              >
                <ToggleGroupItem 
                  value="upcoming" 
                  className={`rounded-lg px-3 py-2 text-sm flex items-center gap-1.5 transition-all duration-300 ${
                    activeTab === 'upcoming' 
                      ? 'bg-gradient-to-r from-primary to-primary/80 dark:from-primary dark:to-primary/80 text-white dark:text-white shadow-sm border border-primary/50 dark:border-primary/40' 
                      : 'text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-300 hover:bg-gray-100/50 dark:hover:bg-gray-700/30'
                  }`}
                  aria-label="Show upcoming bookings"
                >
                  <Calendar className="h-4 w-4" />
                  <span className={activeTab === 'upcoming' ? 'font-medium' : ''}>Upcoming</span>
                  {activeTab === 'upcoming' && (
                    <motion.div 
                      layoutId="activeTabGlow"
                      className="absolute inset-0 rounded-lg bg-gradient-to-r from-primary/20 to-primary/10 dark:from-primary/10 dark:to-primary/5 -z-10 shadow-[0_0_20px_rgba(74,222,128,0.3)]"
                      initial={false}
                      transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                    />
                  )}
                </ToggleGroupItem>
                
                <ToggleGroupItem 
                  value="past" 
                  className={`rounded-lg px-3 py-2 text-sm flex items-center gap-1.5 transition-all duration-300 ${
                    activeTab === 'past' 
                      ? 'bg-gradient-to-r from-primary to-primary/80 dark:from-primary dark:to-primary/80 text-white dark:text-white shadow-sm border border-primary/50 dark:border-primary/40' 
                      : 'text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-300 hover:bg-gray-100/50 dark:hover:bg-gray-700/30'
                  }`}
                  aria-label="Show past bookings"
                >
                  <Clock className="h-4 w-4" />
                  <span className={activeTab === 'past' ? 'font-medium' : ''}>Past</span>
                  {activeTab === 'past' && (
                    <motion.div 
                      layoutId="activeTabGlow"
                      className="absolute inset-0 rounded-lg bg-gradient-to-r from-primary/20 to-primary/10 dark:from-primary/10 dark:to-primary/5 -z-10 shadow-[0_0_20px_rgba(74,222,128,0.3)]"
                      initial={false}
                      transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                    />
                  )}
                </ToggleGroupItem>
                
                <ToggleGroupItem 
                  value="venue-passes" 
                  className={`rounded-lg px-3 py-2 text-sm flex items-center gap-1.5 transition-all duration-300 ${
                    activeTab === 'venue-passes' 
                      ? 'bg-gradient-to-r from-primary to-primary/80 dark:from-primary dark:to-primary/80 text-white dark:text-white shadow-sm border border-primary/50 dark:border-primary/40' 
                      : 'text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-300 hover:bg-gray-100/50 dark:hover:bg-gray-700/30'
                  }`}
                  aria-label="Show venue passes"
                >
                  <Ticket className="h-4 w-4" />
                  <span className={activeTab === 'venue-passes' ? 'font-medium' : ''}>Venue Passes</span>
                  {activeTab === 'venue-passes' && (
                    <motion.div 
                      layoutId="activeTabGlow"
                      className="absolute inset-0 rounded-lg bg-gradient-to-r from-primary/20 to-primary/10 dark:from-primary/10 dark:to-primary/5 -z-10 shadow-[0_0_20px_rgba(74,222,128,0.3)]"
                      initial={false}
                      transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                    />
                  )}
                </ToggleGroupItem>
              </ToggleGroup>
            </div>
          )}
        </motion.div>
        
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.1 }}
        >
          <Alert className="mb-3 bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-blue-950/40 dark:to-indigo-950/40 border-blue-200 dark:border-blue-800 shadow-sm">
            <div className="flex items-start gap-3">
              <Info className="h-5 w-5 text-blue-600 dark:text-blue-400 mt-0.5" />
              <div>
                <AlertTitle className="text-blue-700 dark:text-blue-300 font-semibold">Booking Updates</AlertTitle>
                <AlertDescription className="text-blue-600/90 dark:text-blue-400/90">
                  Recently made a booking? New bookings may take a few minutes to appear here. You can use your 
                  email and SMS confirmations as proof of booking until then.
                </AlertDescription>
              </div>
            </div>
          </Alert>
          
          <motion.div
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            className="mb-6"
          >
            <div className="rounded-lg overflow-hidden border border-pink-100 dark:border-pink-800/40 shadow-sm bg-gradient-to-r from-pink-50/70 to-purple-50/70 dark:from-pink-950/30 dark:to-purple-950/30 relative">
              <div className="absolute top-2 right-2 z-10">
                <button
                  onClick={() => setIsSupportDialogOpen(true)}
                  className="h-7 w-7 rounded-full bg-white dark:bg-gray-800 shadow-sm flex items-center justify-center text-pink-500 dark:text-pink-400 hover:bg-pink-50 dark:hover:bg-pink-900/20 transition-colors"
                  aria-label="More information"
                >
                  <HelpCircle className="h-4 w-4" />
                </button>
              </div>
              <div className="p-3 flex items-start gap-3">
                <div className="bg-white dark:bg-gray-800 rounded-full p-1.5 shrink-0">
                  <Phone className="h-4 w-4 text-pink-500 dark:text-pink-400" />
                </div>
                <div>
                  <p className="text-sm font-medium text-pink-700 dark:text-pink-300">
                    Need help with your booking?
                  </p>
                  <p className="text-xs text-pink-600/80 dark:text-pink-400/80 mt-0.5">
                    Contact us at <span className="font-medium">+91 92204 40441</span> or <span className="font-medium">social@besportly.com</span> for refunds or booking issues.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
        
        {activeTab === 'venue-passes' ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4 }}
            className="rounded-xl overflow-hidden shadow-xl bg-gradient-to-r from-purple-50 to-indigo-50 dark:from-purple-950/40 dark:to-indigo-950/40 border border-purple-200 dark:border-purple-800"
          >
            <div className="p-6 md:p-8 relative">
              <div className="absolute top-0 right-0 w-64 h-64 -mt-32 -mr-32 bg-gradient-to-bl from-indigo-300/20 to-purple-300/20 rounded-full blur-3xl"></div>
              <div className="absolute bottom-0 left-0 w-64 h-64 -mb-32 -ml-32 bg-gradient-to-tr from-purple-300/20 to-pink-300/20 rounded-full blur-3xl"></div>
              
              <div className="flex flex-col items-center text-center space-y-6 relative">
                <motion.div 
                  className="h-24 w-24 rounded-full bg-gradient-to-br from-purple-400/80 to-indigo-500/80 flex items-center justify-center shadow-lg"
                  animate={{ y: [0, -10, 0] }}
                  transition={{ repeat: Infinity, duration: 3, ease: "easeInOut" }}
                >
                  <Ticket className="h-12 w-12 text-white" />
                </motion.div>
                
                <div className="space-y-3 max-w-md">
                  <motion.h3 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1 }}
                    className="text-2xl font-bold text-gray-800 dark:text-gray-200"
                  >
                    No venue passes yet
                  </motion.h3>
                  <motion.p 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                    className="text-gray-600 dark:text-gray-400"
                  >
                    Venue passes will allow you to access venues multiple times without booking each session individually.
                  </motion.p>
                </div>
                
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                  className="w-full max-w-md"
                >
                  <Alert className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm border-purple-200 dark:border-purple-800">
                    <div className="flex gap-3">
                      <div className="shrink-0 bg-gradient-to-br from-purple-400 to-indigo-500 rounded-full p-1.5">
                        <Info className="h-4 w-4 text-white" />
                      </div>
                      <div>
                        <AlertTitle className="text-purple-800 dark:text-purple-300 font-medium">Coming Soon</AlertTitle>
                        <AlertDescription className="mt-2 text-purple-700/90 dark:text-purple-400/90 text-sm">
                          We're currently working on our venue passes feature. They'll offer flexible, cost-effective access to your favorite sports venues. Stay tuned for updates!
                        </AlertDescription>
                      </div>
                    </div>
                  </Alert>
                </motion.div>
                
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.4 }}
                >
                  <Button 
                    variant="outline" 
                    className="bg-white dark:bg-gray-800 border-purple-200 dark:border-purple-800 text-purple-700 dark:text-purple-400 hover:bg-purple-50 dark:hover:bg-purple-900/50 shadow-sm rounded-full px-5"
                    onClick={() => setActiveTab('upcoming')}
                  >
                    <Calendar className="h-4 w-4 mr-2" />
                    View your bookings
                  </Button>
                </motion.div>
              </div>
            </div>
          </motion.div>
        ) : filteredBookings.length > 0 ? (
          <div className="space-y-6">
            {filteredBookings.map((booking, index) => (
              <motion.div
                key={`${booking.id}-${booking.booking_date}-${booking.start_time}-${booking.timestamp || ''}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4, delay: 0.1 * index }}
              >
                <Card className="overflow-hidden border-0 shadow-lg bg-white dark:bg-gray-800 hover:shadow-xl transition-all duration-300">
                  <div className={`h-2 w-full ${
                    booking.status === 'confirmed' ? 'bg-gradient-to-r from-green-400 to-emerald-500' :
                    booking.status === 'pending' ? 'bg-gradient-to-r from-yellow-400 to-amber-500' :
                    'bg-gradient-to-r from-red-400 to-rose-500'
                  }`} />
                  <CardHeader className="pb-4 pt-5 flex flex-row justify-between">
                    <CardTitle className="flex flex-col gap-1">
                      <span className="text-xl font-bold text-gray-800 dark:text-white">{booking.venue.name}</span>
                      <span className="text-sm text-gray-500 dark:text-gray-400 font-normal">Booking ID: {booking.id.slice(0, 8)}...</span>
                    </CardTitle>
                    <span className={`text-sm px-3 py-1 rounded-full self-start ${
                      booking.status === 'confirmed' ? 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400' :
                      booking.status === 'pending' ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-400' :
                      'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-400'
                    }`}>
                      {booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
                    </span>
                  </CardHeader>
                  <CardContent className="pb-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="space-y-3 text-gray-600 dark:text-gray-300">
                        <div className="flex items-center">
                          <Building className="h-4 w-4 mr-3 text-indigo-500" />
                          <span className="font-medium">{booking.facility_name}</span>
                        </div>
                        <div className="flex items-center">
                          <Calendar className="h-4 w-4 mr-3 text-purple-500" />
                          <span>{format(new Date(booking.booking_date), 'EEEE, MMMM d, yyyy')}</span>
                        </div>
                        <div className="flex items-center">
                          <Clock className="h-4 w-4 mr-3 text-blue-500" />
                          <span>{booking.start_time} - {booking.end_time}</span>
                        </div>
                      </div>
                      
                      <div className="space-y-3 text-gray-600 dark:text-gray-300">
                        <div className="flex items-center">
                          <MapPin className="h-4 w-4 mr-3 text-red-500" />
                          <span>
                            {booking.venue.address}
                            {booking.venue.city && booking.venue.city.trim() !== '' ? `, ${booking.venue.city}` : ''}
                          </span>
                        </div>
                        <div className="flex items-center">
                          <Ticket className="h-4 w-4 mr-3 text-green-500" />
                          <span className="font-medium">₹{booking.total_amount}</span>
                        </div>
                        <div className="flex items-center">
                          <span className={`text-sm px-3 py-1 rounded-full ${
                            booking.payment_status === 'paid' ? 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400' :
                            'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-400'
                          }`}>
                            {booking.payment_status.charAt(0).toUpperCase() + booking.payment_status.slice(1)}
                          </span>
                        </div>
                      </div>
                    </div>
                    
                    <div className="mt-6 pt-4 border-t border-gray-100 dark:border-gray-700 flex gap-3">
                      {isWithinRescheduleWindow(booking.booking_date, booking.start_time, booking.from_mobile) && (
                        <Button 
                          variant="outline" 
                          size="sm" 
                          className="flex-1 bg-gradient-to-r from-purple-50 to-indigo-50 hover:from-purple-100 hover:to-indigo-100 dark:from-purple-900/20 dark:to-indigo-900/20 dark:hover:from-purple-900/30 dark:hover:to-indigo-900/30 border border-purple-200 dark:border-purple-700 text-purple-700 dark:text-purple-400"
                          onClick={() => handleReschedule(booking)}
                        >
                          <RefreshCw className="h-4 w-4 mr-2" />
                          Reschedule
                        </Button>
                      )}
                      
                      <Button 
                        variant="outline" 
                        size="sm"
                        className="flex-1 bg-gradient-to-r from-pink-50 to-indigo-50 hover:from-pink-100 hover:to-indigo-100 dark:from-pink-900/20 dark:to-indigo-900/20 dark:hover:from-pink-900/30 dark:hover:to-indigo-900/30 border border-pink-200 dark:border-pink-700 text-pink-700 dark:text-pink-400 shadow-sm hover:shadow transition-all"
                        onClick={() => handleRebook(booking.venue_id)}
                      >
                        <ArrowRight className="h-4 w-4 mr-2" />
                        Rebook
                      </Button>
                      
                      {isBookingInPast(booking.booking_date, booking.start_time) && (
                        <div className="bg-gradient-to-r from-blue-50 to-teal-50 dark:from-blue-950/20 dark:to-teal-950/20 border border-blue-200 dark:border-blue-900/30 rounded-lg p-3 text-center">
                          <div className="flex items-center justify-center text-blue-600 dark:text-blue-400 mb-1">
                            <CheckCircle className="h-4 w-4 mr-1" />
                            <span className="font-medium">Completed</span>
                          </div>
                          <p className="text-sm text-blue-700 dark:text-blue-300">This booking has already taken place.</p>
                        </div>
                      )}
                      
                      {isUpcomingSoon(booking.booking_date, booking.start_time) && !isBookingInPast(booking.booking_date, booking.start_time) && (
                        <div className="bg-gradient-to-r from-amber-50 to-orange-50 dark:from-amber-950/20 dark:to-orange-950/20 border border-amber-100 dark:border-amber-900/30 rounded-lg p-3 text-center">
                          <div className="flex items-center justify-center text-amber-600 dark:text-amber-400 mb-1">
                            <Clock4 className="h-4 w-4 mr-1" />
                            <span className="font-medium">Coming Soon</span>
                          </div>
                          <p className="text-sm text-amber-700 dark:text-amber-300">This booking is past its rescheduling window.</p>
                        </div>
                      )}
                    </div>
                    
                    {booking.from_mobile && (
                      <div className="mt-2 px-4 pb-4">
                        <div className="text-xs bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400 p-2 rounded-sm">
                          This booking was completed on your mobile device.
                        </div>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4 }}
            className="rounded-xl overflow-hidden shadow-xl bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 dark:from-blue-950/40 dark:via-indigo-950/40 dark:to-purple-950/40 border border-blue-200 dark:border-blue-800"
          >
            <div className="p-6 md:p-8 relative">
              <div className="absolute top-0 right-0 w-64 h-64 -mt-32 -mr-32 bg-gradient-to-bl from-indigo-300/20 to-blue-300/20 rounded-full blur-3xl"></div>
              <div className="absolute bottom-0 left-0 w-64 h-64 -mb-32 -ml-32 bg-gradient-to-tr from-blue-300/20 to-purple-300/20 rounded-full blur-3xl"></div>
              
              <div className="flex flex-col items-center text-center space-y-6 relative">
                <motion.div 
                  className="h-24 w-24 rounded-full bg-gradient-to-br from-blue-400/80 to-indigo-500/80 flex items-center justify-center shadow-lg"
                  animate={{ y: [0, -10, 0] }}
                  transition={{ repeat: Infinity, duration: 3, ease: "easeInOut" }}
                >
                  <Calendar className="h-12 w-12 text-white" />
                </motion.div>
                
                <div className="space-y-3 max-w-md">
                  <motion.h3 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1 }}
                    className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent"
                  >
                    {activeTab === 'upcoming' ? 'No upcoming bookings' : 'No past bookings'}
                  </motion.h3>
                  <motion.p 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                    className="text-gray-600 dark:text-gray-400"
                  >
                    {activeTab === 'upcoming' 
                      ? 'Recently made a booking? It may take a few minutes to appear here.'
                      : 'You don\'t have any past bookings yet.'}
                  </motion.p>
                </div>
                
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 }}
                  className="w-full max-w-md"
                >
                  <Alert className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm border-blue-200 dark:border-blue-800">
                    <AlertCircle className="h-4 w-4 text-blue-600 dark:text-blue-400" />
                    <AlertTitle className="text-blue-700 dark:text-blue-300 font-medium">Booking Confirmation</AlertTitle>
                    <AlertDescription className="mt-2 text-blue-600/90 dark:text-blue-400/90">
                      <p className="mb-3">You can also use your confirmation as proof of booking:</p>
                      <div className="space-y-2 text-sm">
                        <div className="flex items-center gap-2 bg-blue-50/50 dark:bg-blue-900/20 p-2 rounded-md">
                          <Mail className="h-4 w-4 text-green-600 dark:text-green-500" />
                          <span>Check your email for booking confirmation</span>
                        </div>
                        <div className="flex items-center gap-2 bg-indigo-50/50 dark:bg-indigo-900/20 p-2 rounded-md">
                          <MessageSquare className="h-4 w-4 text-purple-600 dark:text-purple-500" />
                          <span>SMS receipt sent to your registered number</span>
                        </div>
                      </div>
                    </AlertDescription>
                  </Alert>
                </motion.div>
                
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.4 }}
                  className="space-y-3"
                >
                  <Button 
                    variant="outline" 
                    className="bg-white dark:bg-gray-800 border-blue-200 dark:border-blue-800 text-blue-700 dark:text-blue-400 hover:bg-blue-50 dark:hover:bg-blue-900/50 shadow-sm rounded-full px-5"
                    onClick={() => {
                      refreshSession().then(() => {
                        setRefreshTrigger(prev => prev + 1);
                        refetch();
                      });
                      toast.info("Refreshing your bookings...");
                    }}
                  >
                    <RefreshCw className="h-4 w-4 mr-2" />
                    Refresh Bookings
                  </Button>
                  
                  {activeTab === 'past' && bookingsData.length > 0 && (
                    <Button
                      variant="ghost"
                      className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
                      onClick={() => setActiveTab('upcoming')}
                    >
                      View upcoming bookings
                    </Button>
                  )}
                </motion.div>
              </div>
            </div>
          </motion.div>
        )}
      </div>

      {selectedBooking && (
        <RescheduleDialog
          open={isRescheduleOpen}
          onOpenChange={setIsRescheduleOpen}
          booking={selectedBooking}
          onRescheduleComplete={handleRescheduleComplete}
          userEmail={user?.email || undefined}
          userName={user?.user_metadata?.full_name || undefined}
          userPhone={user?.user_metadata?.phone || undefined}
        />
      )}
      
      <SupportInfoDialog
        open={isSupportDialogOpen}
        onOpenChange={setIsSupportDialogOpen}
      />
    </div>
  );
};

const TabButton = ({ isActive, onClick, icon, label }: { 
  isActive: boolean; 
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
}) => {
  return (
    <button
      onClick={onClick}
      className={`relative flex items-center gap-1.5 px-4 py-2 rounded-md text-sm font-medium transition-all duration-200 ${
        isActive 
          ? 'text-purple-700 dark:text-purple-400' 
          : 'text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-300'
      }`}
    >
      {isActive && (
        <motion.div
          layoutId="activeTabBackground"
          className="absolute inset-0 bg-gradient-to-r from-purple-50 to-indigo-50 dark:from-purple-900/20 dark:to-indigo-900/20 rounded-md shadow-sm -z-10"
          initial={false}
          transition={{ type: "spring", stiffness: 500, damping: 30 }}
        />
      )}
      {icon}
      {label}
    </button>
  );
};

export default MyBookings;
