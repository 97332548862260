
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Sparkles, Rocket, CalendarPlus, PlusCircle } from "lucide-react";
import { Button } from "@/components/ui/button";

interface NoResultsAnimationProps {
  type: "venues" | "coaches" | "gyms" | "academies";
  onReset?: () => void;
}

const typeConfig = {
  venues: {
    title: "More venues on the way!",
    icon: CalendarPlus,
    description: "We're expanding our network of sports venues across the country"
  },
  coaches: {
    title: "Pro coaches coming soon!",
    icon: Sparkles,
    description: "We're onboarding experienced coaches from various sports disciplines"
  },
  gyms: {
    title: "Fitness centers incoming!",
    icon: PlusCircle,
    description: "We're partnering with premium fitness centers in your area"
  },
  academies: {
    title: "Academies expanding soon!",
    icon: Rocket,
    description: "We're adding sports academies for all skill levels and age groups"
  }
};

export function NoResultsAnimation({ type, onReset }: NoResultsAnimationProps) {
  const [sparkleElements, setSparkleElements] = useState<JSX.Element[]>([]);
  const config = typeConfig[type];
  
  // Create decorative particles
  useEffect(() => {
    const elements = [];
    for (let i = 0; i < 20; i++) {
      elements.push(
        <motion.div
          key={`particle-${i}`}
          className="absolute w-1 h-1 bg-primary/80 rounded-full"
          initial={{ 
            opacity: 0,
            x: Math.random() * 100 - 50, 
            y: Math.random() * 100 - 50 
          }}
          animate={{ 
            opacity: [0, 1, 0],
            scale: [0, 1.5, 0],
            x: Math.random() * 200 - 100,
            y: Math.random() * 200 - 100,
          }}
          transition={{ 
            repeat: Infinity,
            duration: 3 + Math.random() * 2,
            delay: Math.random() * 2,
            ease: "easeInOut" 
          }}
        />
      );
    }
    setSparkleElements(elements);
  }, []);
  
  return (
    <motion.div 
      className="w-full py-12 flex flex-col items-center justify-center relative overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {sparkleElements}
      
      <motion.div
        className="w-20 h-20 rounded-full bg-gradient-to-r from-primary/20 to-primary/30 flex items-center justify-center mb-6 relative"
        initial={{ scale: 0.8 }}
        animate={{ 
          scale: [0.8, 1, 0.8],
          rotate: [0, 10, 0, -10, 0]
        }}
        transition={{ 
          duration: 5,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        <motion.div
          className="absolute inset-0 rounded-full bg-gradient-to-r from-primary/10 to-primary/20"
          animate={{ 
            scale: [1, 1.5, 1],
          }}
          transition={{ 
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <config.icon className="w-10 h-10 text-primary z-10" />
      </motion.div>
      
      <motion.h3
        className="text-2xl font-bold text-gray-800 dark:text-gray-200 mb-2 text-center"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        {config.title}
      </motion.h3>
      
      <motion.p
        className="text-gray-600 dark:text-gray-400 text-center max-w-md mb-6"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        {config.description}. Check back soon for new additions!
      </motion.p>
      
      {onReset && (
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <Button 
            variant="outline" 
            onClick={onReset}
            className="bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm hover:bg-white dark:hover:bg-gray-800"
          >
            Reset Filters
          </Button>
        </motion.div>
      )}
    </motion.div>
  );
}
