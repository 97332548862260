
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/components/AuthProvider";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import CoachRegistrationForm from "@/components/coaches/CoachRegistrationForm";
import { toast } from "sonner";
import BackButton from "@/components/ui/back-button";
import LoadingScreen from "@/components/ui/loading-screen";

const CoachRegister = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !user) {
      toast.error("Please log in to register as a coach");
      navigate("/auth?redirect=/coach/register");
    }
  }, [user, loading, navigate]);

  if (loading) {
    return (
      <div className="min-h-screen bg-white pt-24 pb-16">
        <div className="max-w-3xl mx-auto px-4">
          <LoadingScreen size="sm" />
        </div>
      </div>
    );
  }

  if (!user) {
    return null; // We'll redirect in the effect
  }

  return (
    <div className="min-h-screen bg-white pt-24 pb-16">
      <div className="max-w-3xl mx-auto px-4">
        <div className="mb-8">
          <BackButton />
          <h1 className="text-3xl font-bold mt-4">Become a Coach</h1>
          <p className="text-gray-600 mt-2">Share your expertise and grow your coaching business</p>
        </div>

        <Card>
          <CardHeader>
            <CardTitle>Coach Registration</CardTitle>
            <CardDescription>
              Fill out the form below to create your coach profile
            </CardDescription>
          </CardHeader>
          <CardContent>
            <CoachRegistrationForm />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CoachRegister;
