
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Menu, X, UserCircle, Calendar } from "lucide-react";
import { useAuth } from "./AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "./ui/button";
import { useToast } from "./ui/use-toast";
import { Avatar, AvatarFallback } from "./ui/avatar";
import { useQuery } from "@tanstack/react-query";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();
  const isMobile = window.innerWidth <= 768;

  const { data: shopSettings } = useQuery({
    queryKey: ['shopSettings'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('shop_settings')
        .select('*')
        .maybeSingle();
      
      if (error) throw error;
      return data;
    },
    enabled: !!user,
  });

  const { data: coachProfile } = useQuery({
    queryKey: ['coachProfile', user?.id],
    queryFn: async () => {
      if (!user) return null;
      
      const { data, error } = await supabase
        .from('coaches')
        .select('id')
        .eq('profile_id', user.id)
        .maybeSingle();
      
      if (error) {
        console.error("Error fetching coach profile:", error);
        return null;
      }
      
      return data;
    },
    enabled: !!user,
  });

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrolledDown = prevScrollPos < currentScrollPos;
      const isScrolledPastThreshold = currentScrollPos > 100;

      setVisible(!isScrolledDown || !isScrolledPastThreshold);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const handleSignOut = async () => {
    try {
      console.log("Navigation: Signing out user");
      await signOut();
      navigate("/auth");
    } catch (error: any) {
      console.error("Error during sign out:", error);
      toast({
        variant: "destructive",
        title: "Error signing out",
        description: error.message,
      });
    }
  };

  // Updated color logic - swapped the selected and unselected colors
  const getNavLinkClass = (path: string) => {
    const isActive = location.pathname === path;
    return `font-bold transition-colors ${
      isActive 
        ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#ff00ff] to-[#FF00FF]' 
        : 'text-transparent bg-clip-text bg-gradient-to-r from-[#9b87f5] to-[#8B5CF6] hover:opacity-80'
    }`;
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsOpen(false);
  };

  const shopUrl = shopSettings 
    ? `/shop`
    : '#';

  const navItems = [
    { path: '/venues', label: 'Venues' },
    { path: '/coaches', label: 'Coaches' },
    { path: '/gyms', label: 'Gyms' },
    { path: '/academies', label: 'Academies' },
    { path: '/events-and-games', label: 'Events' },
    { path: '/shop', label: 'Shop' },
    ...(user ? [] : [{ path: '/live-feed', label: 'Live Feed' }]),
    { path: '/memberships', label: 'Memberships' },
    { path: '/be-fit', label: 'be.fit' },
    { path: '/communities', label: 'Communities' },
  ];

  return (
    <nav 
      className={`fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-md z-50 border-b border-gray-100 hidden lg:block transition-transform duration-300 ${
        visible ? 'translate-y-0' : '-translate-y-full'
      }`} 
      data-mobile-sidebar={isMobile}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-24">
          <div className="flex items-center pl-4 lg:pl-8">
            <Link to="/" className="flex items-center">
              <img 
                src="/lovable-uploads/a0c2292c-cf10-4db0-a09d-fb9ada0e5287.png" 
                alt="beSportly" 
                className="h-12 w-auto transition-transform duration-300 hover:scale-105"
              />
            </Link>
          </div>

          <div className="hidden md:flex items-center space-x-8">
            {navItems.map((item) => (
              <div key={item.path} className="relative">
                <Link
                  to={item.path}
                  className={getNavLinkClass(item.path)}
                >
                  {item.label}
                  {item.path === '/communities' && (
                    <span className="absolute -bottom-5 left-1/2 transform -translate-x-1/2 text-xs px-2 py-0.5 bg-gradient-to-r from-[#8B5CF6] to-[#D946EF] text-white rounded-full whitespace-nowrap font-medium">
                      +quizzes
                    </span>
                  )}
                  {item.path === '/events-and-games' && (
                    <span className="absolute -bottom-5 left-1/2 transform -translate-x-1/2 text-xs px-2 py-0.5 bg-gradient-to-r from-[#F97316] to-[#FDE1D3] text-white rounded-full whitespace-nowrap font-medium">
                      create your own
                    </span>
                  )}
                  {item.path === '/live-feed' && (
                    <span className="absolute -bottom-5 left-1/2 transform -translate-x-1/2 text-xs px-2 py-0.5 bg-gradient-to-r from-[#22C55E] to-[#86EFAC] text-white rounded-full whitespace-nowrap font-medium">
                      for all sports
                    </span>
                  )}
                  {item.path === '/venues' && (
                    <span className="absolute -bottom-5 left-1/2 transform -translate-x-1/2 text-xs px-2 py-0.5 bg-gradient-to-r from-[#0EA5E9] to-[#D3E4FD] text-white rounded-full whitespace-nowrap font-medium">
                      play now
                    </span>
                  )}
                </Link>
              </div>
            ))}

            {user ? (
              <div className="flex items-center space-x-4">
                <Link 
                  to="/my-bookings"
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  title="My Bookings"
                >
                  <Calendar className="h-5 w-5 text-gray-600" />
                </Link>
                <Link to="/profile">
                  <Avatar className="cursor-pointer hover:opacity-80 transition-opacity">
                    <AvatarFallback>
                      <UserCircle className="h-6 w-6" />
                    </AvatarFallback>
                  </Avatar>
                </Link>
                <Button
                  onClick={handleSignOut}
                  variant="outline"
                  className="hover:bg-red-50 hover:text-red-600 hover:border-red-200"
                >
                  Sign Out
                </Button>
              </div>
            ) : (
              <Button
                onClick={() => navigate("/auth")}
                className="bg-gradient-to-r from-[#ff00ff] to-[#00bfff] text-white hover:opacity-90 transition-all duration-300 transform hover:scale-105"
              >
                Sign In
              </Button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
