import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { Medal, Sparkles, Star } from "lucide-react";

export const ExpertContact = () => {
  const { toast } = useToast();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    expertType: "",
    message: "",
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    toast({
      title: "Request Submitted Successfully! 🎉",
      description: "A representative will contact you within 24 hours to connect you with your perfect expert match.",
    });
    setFormData({
      name: "",
      email: "",
      phone: "",
      expertType: "",
      message: "",
    });
  };

  return (
    <Card className="bg-gradient-to-br from-purple-50 via-white to-pink-50">
      <CardHeader className="space-y-4">
        <div className="flex justify-center">
          <div className="relative">
            <Medal className="w-12 h-12 text-purple-500" />
            <Sparkles className="w-6 h-6 text-yellow-500 absolute -top-2 -right-2 animate-pulse" />
          </div>
        </div>
        <CardTitle className="text-center text-2xl bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent">
          Connect with an Expert
        </CardTitle>
        <p className="text-center text-gray-600">
          Get personalized guidance from certified professionals
        </p>
        <div className="flex justify-center gap-4 flex-wrap">
          <div className="flex items-center gap-2">
            <Star className="w-5 h-5 text-yellow-500" />
            <span className="text-sm">Verified Experts</span>
          </div>
          <div className="flex items-center gap-2">
            <Star className="w-5 h-5 text-yellow-500" />
            <span className="text-sm">Personalized Plans</span>
          </div>
          <div className="flex items-center gap-2">
            <Star className="w-5 h-5 text-yellow-500" />
            <span className="text-sm">24/7 Support</span>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <Input
            placeholder="Your Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
            className="bg-white/50"
          />
          <Input
            type="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            required
            className="bg-white/50"
          />
          <Input
            type="tel"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            required
            className="bg-white/50"
          />
          <Select
            value={formData.expertType}
            onValueChange={(value) =>
              setFormData({ ...formData, expertType: value })
            }
          >
            <SelectTrigger className="bg-white/50">
              <SelectValue placeholder="Select Expert Type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="nutritionist">Nutritionist</SelectItem>
              <SelectItem value="trainer">Personal Trainer</SelectItem>
              <SelectItem value="both">Both</SelectItem>
            </SelectContent>
          </Select>
          <Textarea
            placeholder="Tell us about your goals and preferences..."
            value={formData.message}
            onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            className="bg-white/50 min-h-[100px]"
          />
          <Button
            type="submit"
            className="w-full bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 text-white"
          >
            Request Expert Match
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};