
import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/components/AuthProvider";
import { ArrowLeft, Star, Activity, Play, Loader2, Plus, Trash2, AlertCircle } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";
import { openInExternalBrowser } from "@/utils/utils";
import InAppBrowser from "@/components/InAppBrowser";

export const popularTeams = {
  football: [
    "Manchester United", "Real Madrid", "Barcelona", "Liverpool", "Bayern Munich",
    "Paris Saint-Germain", "Juventus", "Manchester City", "Chelsea", "Arsenal",
    "AC Milan", "Inter Milan", "Borussia Dortmund", "Atletico Madrid", "Tottenham"
  ],
  basketball: [
    "LA Lakers", "Golden State Warriors", "Chicago Bulls", "Boston Celtics", "Brooklyn Nets",
    "Miami Heat", "Milwaukee Bucks", "Phoenix Suns", "Dallas Mavericks", "Toronto Raptors",
    "Philadelphia 76ers", "LA Clippers", "Denver Nuggets", "Atlanta Hawks", "Memphis Grizzlies"
  ],
  cricket: [
    "Mumbai Indians", "Chennai Super Kings", "Royal Challengers Bangalore", "Kolkata Knight Riders",
    "Delhi Capitals", "Australia Cricket Team", "India Cricket Team", "England Cricket Team",
    "New Zealand Cricket Team", "South Africa Cricket Team", "Pakistan Cricket Team",
    "West Indies Cricket Team", "Bangladesh Cricket Team", "Sri Lanka Cricket Team",
    "Rajasthan Royals"
  ],
  tennis: [
    "Novak Djokovic", "Rafael Nadal", "Roger Federer", "Carlos Alcaraz", "Daniil Medvedev",
    "Alexander Zverev", "Stefanos Tsitsipas", "Andrey Rublev", "Casper Ruud", "Holger Rune",
    "Iga Świątek", "Aryna Sabalenka", "Elena Rybakina", "Coco Gauff", "Jessica Pegula"
  ]
};

const sports = [
  { id: "football", name: "Football", icon: Activity },
  { id: "basketball", name: "Basketball", icon: Activity },
  { id: "cricket", name: "Cricket", icon: Activity },
  { id: "tennis", name: "Tennis", icon: Activity }
];

export default function Favorites() {
  const [selectedSport, setSelectedSport] = useState("football");
  const [selectedView, setSelectedView] = useState("trending");
  const [browserUrl, setBrowserUrl] = useState("");
  const [browserOpen, setBrowserOpen] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const isMobile = useIsMobile();

  const { data: favoriteTeams, isLoading: isLoadingTeams } = useQuery({
    queryKey: ['favorite-teams'],
    queryFn: async () => {
      if (!user?.id) return [];
      const { data, error } = await supabase
        .from('favorite_teams')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data || [];
    },
    enabled: !!user?.id,
  });

  const { data: trendingNews, isLoading: isTrendingLoading, error: trendingError } = useQuery({
    queryKey: ['team-trending-news', selectedSport, favoriteTeams],
    queryFn: async () => {
      if (!favoriteTeams?.length) return [];
      const teamsForSport = favoriteTeams.filter(t => t.sport === selectedSport);
      if (!teamsForSport.length) return [];

      const { data, error } = await supabase.functions.invoke('fetch-cse-news', {
        body: { 
          sport: selectedSport,
          teamNames: teamsForSport.map(t => t.team_name)
        }
      });
      
      if (error) {
        console.error('Error fetching trending news:', error);
        throw error;
      }
      
      console.log('Received trending data for teams:', data);
      return data?.feed || [];
    },
    enabled: selectedView === 'trending' && !!favoriteTeams?.length,
    staleTime: 1000 * 60 * 5,
  });

  const { data: highlights, isLoading: isHighlightsLoading } = useQuery({
    queryKey: ['sports-highlights', selectedSport, favoriteTeams],
    queryFn: async () => {
      if (!favoriteTeams?.length) return [];
      const teamsForSport = favoriteTeams.filter(t => t.sport === selectedSport);
      if (!teamsForSport.length) return [];

      const teamQueries = teamsForSport.map(t => `"${t.team_name}"`).join(' OR ');
      const { data, error } = await supabase.functions.invoke('fetch-sports-highlights', {
        body: { 
          sport: selectedSport,
          query: `(${teamQueries})`,
          teamNames: teamsForSport.map(t => t.team_name)
        }
      });
      if (error) throw error;
      return data?.highlights || [];
    },
    enabled: selectedView === 'highlights' && !!favoriteTeams?.length,
  });

  const handleOpenArticle = (url: string, event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    
    // Force opening in external browser
    openInExternalBrowser(url);
  };

  const addTeam = async (teamName: string) => {
    if (!user?.id) {
      toast({
        variant: "destructive",
        title: "Authentication required",
        description: "Please sign in to add favorite teams.",
      });
      return;
    }

    try {
      const { error: deleteError } = await supabase
        .from('favorite_teams')
        .delete()
        .eq('user_id', user.id)
        .eq('sport', selectedSport);

      if (deleteError) throw deleteError;

      const { error } = await supabase
        .from('favorite_teams')
        .insert({
          team_name: teamName,
          sport: selectedSport,
          user_id: user.id
        });

      if (error) throw error;

      toast({
        title: "Team updated!",
        description: `${teamName} is now your favorite ${selectedSport} team`,
      });

      queryClient.invalidateQueries({ queryKey: ['favorite-teams'] });
      queryClient.invalidateQueries({ queryKey: ['team-trending-news'] });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error updating team",
        description: "Please try again later.",
      });
    }
  };

  const removeTeam = async (teamName: string) => {
    if (!user?.id) return;

    try {
      const { error } = await supabase
        .from('favorite_teams')
        .delete()
        .eq('team_name', teamName)
        .eq('sport', selectedSport)
        .eq('user_id', user.id);

      if (error) throw error;

      toast({
        title: "Team removed",
        description: `${teamName} has been removed from your favorites`,
      });

      queryClient.invalidateQueries({ queryKey: ['favorite-teams'] });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error removing team",
        description: "Please try again later.",
      });
    }
  };

  const renderContent = () => {
    if (!user?.id) {
      return (
        <Alert className="bg-purple-50 border-purple-200">
          <Star className="h-4 w-4 text-purple-600" />
          <AlertDescription className="text-gray-900">
            Please sign in to manage your favorite teams and see personalized updates.
          </AlertDescription>
        </Alert>
      );
    }

    if (isLoadingTeams || isTrendingLoading || isHighlightsLoading) {
      return (
        <div className="flex justify-center items-center py-12">
          <Loader2 className="w-8 h-8 animate-spin text-purple-600" />
        </div>
      );
    }

    const currentTeams = favoriteTeams?.filter(t => t.sport === selectedSport) || [];
    const availableTeams = popularTeams[selectedSport as keyof typeof popularTeams]
      .filter(team => !currentTeams.some(t => t.team_name === team));

    return (
      <div className="space-y-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {currentTeams.map((team) => (
            <Card key={team.id} className="bg-white shadow-lg hover:shadow-xl transition-shadow">
              <div className="p-4 flex items-center justify-between gap-4">
                <div className="flex items-center gap-3">
                  <Star className="w-5 h-5 text-yellow-500" />
                  <span className="font-medium text-gray-900">{team.team_name}</span>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => removeTeam(team.team_name)}
                  className="hover:bg-red-50 hover:text-red-500"
                >
                  <Trash2 className="w-4 h-4" />
                </Button>
              </div>
            </Card>
          ))}
          {availableTeams.length > 0 && (
            <Card className="bg-gradient-to-br from-purple-50 to-white border-dashed border-2 border-purple-200">
              <div className="p-4">
                <Select onValueChange={addTeam}>
                  <SelectTrigger className="w-full bg-white/50 backdrop-blur-sm">
                    <SelectValue placeholder="Add a team" />
                  </SelectTrigger>
                  <SelectContent>
                    {availableTeams.map((team) => (
                      <SelectItem key={team} value={team}>
                        <div className="flex items-center gap-2">
                          <Plus className="w-4 h-4" />
                          {team}
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </Card>
          )}
        </div>
        
        {selectedView === 'trending' && trendingNews && trendingNews.length > 0 && (
          <div className="mt-8 space-y-4">
            <h2 className="text-2xl font-bold text-gray-900">Team News</h2>
            <div className="space-y-4">
              {trendingNews.map((item: any, index: number) => (
                <a 
                  href={item.url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  key={index}
                  className="block"
                  onClick={(e) => handleOpenArticle(item.url, e)}
                >
                  <Card className="overflow-hidden hover:shadow-xl transition-all duration-300 border-purple-100 bg-gradient-to-br from-white via-purple-50/30 to-blue-50/30 transform hover:scale-[1.02]">
                    <div className="p-4 sm:p-6">
                      <div className="flex flex-col sm:flex-row items-start gap-4">
                        <div className="w-full sm:w-16 h-16 rounded-lg overflow-hidden flex-shrink-0 bg-gradient-to-br from-purple-500 to-blue-500">
                          {item.imageUrl ? (
                            <img 
                              src={item.imageUrl} 
                              alt={item.title}
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <div className="w-full h-full flex items-center justify-center">
                              <Star className="w-8 h-8 text-white" />
                            </div>
                          )}
                        </div>
                        <div className="space-y-3 flex-1">
                          <h3 className="text-lg sm:text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600 line-clamp-2">
                            {item.title}
                          </h3>
                          <p className="text-sm sm:text-base text-gray-600 leading-relaxed line-clamp-3">
                            {item.description}
                          </p>
                          <div className="flex flex-wrap items-center gap-4 text-xs sm:text-sm mt-4">
                            <span className="text-purple-600 flex items-center gap-1">
                              <Activity className="w-4 h-4" />
                              {new Date(item.publishedAt).toLocaleString()}
                            </span>
                            <span className="text-blue-600 flex items-center gap-1">
                              {item.source}
                            </span>
                            {item.upvotes && (
                              <span className="text-orange-600 flex items-center gap-1">
                                ↑ {item.upvotes}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </a>
              ))}
            </div>
          </div>
        )}
        
        {selectedView === 'highlights' && highlights && highlights.length > 0 && (
          <div className="mt-8 space-y-4">
            <h2 className="text-2xl font-bold text-gray-900">Team Highlights</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {highlights.map((highlight: any) => (
                <a
                  href={`https://www.youtube.com/watch?v=${highlight.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={highlight.id}
                  className="block group"
                  onClick={(e) => handleOpenArticle(`https://www.youtube.com/watch?v=${highlight.id}`, e)}
                >
                  <Card className="overflow-hidden hover:shadow-xl transition-all duration-300">
                    <div className="p-0">
                      <div className="relative aspect-video">
                        <img
                          src={highlight.thumbnail}
                          alt={highlight.title}
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                          <Play className="w-16 h-16 text-white" />
                        </div>
                        <div className="absolute bottom-2 right-2 bg-black/80 text-white px-2 py-1 rounded text-sm">
                          {highlight.duration}
                        </div>
                      </div>
                      <div className="p-4">
                        <h3 className="font-semibold text-lg line-clamp-2">{highlight.title}</h3>
                        <p className="text-sm text-gray-500 mt-2">{highlight.channelTitle}</p>
                      </div>
                    </div>
                  </Card>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white pt-16 md:pt-24 px-4 sm:px-6 lg:px-8">
      <InAppBrowser 
        url={browserUrl}
        isOpen={browserOpen}
        onClose={() => setBrowserOpen(false)}
      />
      
      <div className="max-w-7xl mx-auto pb-16">
        <div className="flex items-center mb-8">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => navigate(-1)}
            className="mr-4"
          >
            <ArrowLeft className="mr-2 h-4 w-4" />
            Back
          </Button>
          <h1 className="text-3xl font-bold text-purple-800">Your Favorite Teams</h1>
        </div>

        {isMobile ? (
          <div className="flex flex-wrap gap-2 mb-8 overflow-x-auto pb-2">
            {sports.map((sport) => (
              <Button
                key={sport.id}
                variant={selectedSport === sport.id ? "default" : "outline"}
                onClick={() => setSelectedSport(sport.id)}
                className={`px-3 py-1 h-9 ${selectedSport === sport.id ? "bg-purple-600 hover:bg-purple-700" : ""}`}
                size="sm"
              >
                <sport.icon className="mr-1 h-4 w-4" />
                <span>{sport.name}</span>
              </Button>
            ))}
          </div>
        ) : (
          <div className="flex flex-wrap gap-4 mb-8">
            {sports.map((sport) => (
              <Button
                key={sport.id}
                variant={selectedSport === sport.id ? "default" : "outline"}
                onClick={() => setSelectedSport(sport.id)}
                className={selectedSport === sport.id ? "bg-purple-600 hover:bg-purple-700" : ""}
              >
                <sport.icon className="mr-2 h-4 w-4" />
                {sport.name}
              </Button>
            ))}
          </div>
        )}

        <Tabs 
          value={selectedView} 
          onValueChange={setSelectedView}
          className="mb-8"
        >
          <TabsList className="grid w-full md:w-[400px] grid-cols-2">
            <TabsTrigger value="trending">Team News</TabsTrigger>
            <TabsTrigger value="highlights">Highlights</TabsTrigger>
          </TabsList>
        </Tabs>

        {renderContent()}
      </div>
    </div>
  );
}
