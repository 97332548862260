
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { 
  ArrowLeft, 
  Dumbbell,
  Salad,
  Trophy, 
  CircleDot, 
  Target, 
  CircleDashed,
  Sparkles,
  type LucideIcon
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { AICoachCard } from "@/components/AICoaching/AICoachCard";
import { ChatInterface } from "@/components/AICoaching/ChatInterface";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/components/AuthProvider";

const coachIcons: Record<string, LucideIcon> = {
  'fitness': Dumbbell,
  'nutrition': Salad,
  'cricket': Trophy,
  'football': CircleDot,
  'badminton': CircleDot,
  'tennis': Target,
  'squash': Target,
  'basketball': CircleDashed,
};

export default function AICoaching() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { toast } = useToast();
  const [coaches, setCoaches] = useState<any[]>([]);
  const [selectedCoach, setSelectedCoach] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadCoaches();
  }, []);

  const loadCoaches = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('ai_coaches')
        .select('*')
        .order('category');

      if (error) {
        toast({
          variant: "destructive",
          title: "Error loading coaches",
          description: "Please try again",
        });
        return;
      }

      // Sort coaches to prioritize fitness and nutrition
      const sortedCoaches = data?.sort((a, b) => {
        if (a.category === 'fitness') return -1;
        if (b.category === 'fitness') return 1;
        if (a.category === 'nutrition') return -1;
        if (b.category === 'nutrition') return 1;
        return a.category.localeCompare(b.category);
      });

      setCoaches(sortedCoaches || []);
    } catch (error) {
      console.error('Error loading coaches:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!user) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4 bg-gradient-to-br from-violet-50 to-indigo-50">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="max-w-md w-full"
        >
          <div className="text-center mb-8">
            <div className="flex justify-center mb-6">
              <div className="relative">
                <div className="absolute -inset-1 rounded-full bg-gradient-to-r from-violet-500 to-indigo-500 opacity-75 blur-lg animate-pulse"></div>
                <div className="relative bg-gradient-to-r from-violet-500 to-indigo-500 p-4 rounded-full shadow-lg">
                  <Sparkles className="w-10 h-10 text-white" />
                </div>
              </div>
            </div>
            
            <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-violet-600 to-indigo-600 bg-clip-text text-transparent">
              Unlock AI Coaching
            </h2>
            
            <p className="text-gray-700 mb-8 text-lg">
              Get personalized guidance and insights tailored to your fitness goals.
            </p>
            
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
              className="mb-8"
            >
              <Button 
                onClick={() => navigate("/auth")}
                className="bg-gradient-to-r from-violet-600 to-indigo-600 hover:from-violet-700 hover:to-indigo-700 text-white text-lg px-10 py-6 h-auto rounded-xl shadow-lg relative overflow-hidden group"
              >
                <span className="relative z-10 flex items-center">
                  Sign In
                </span>
                <span className="absolute inset-0 bg-gradient-to-r from-violet-700 to-indigo-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="absolute inset-0 -z-10 bg-gradient-to-r from-violet-500 to-indigo-500 opacity-30 blur-xl animate-pulse"></div>
              </Button>
            </motion.div>

            <div className="flex items-center justify-center space-x-4">
              {[0, 1, 2].map((i) => (
                <motion.div
                  key={i}
                  initial={{ scale: 0.8, opacity: 0.5 }}
                  animate={{ 
                    scale: [0.8, 1, 0.8], 
                    opacity: [0.5, 1, 0.5] 
                  }}
                  transition={{ 
                    duration: 2,
                    repeat: Infinity,
                    delay: i * 0.5 
                  }}
                  className="w-2 h-2 rounded-full bg-gradient-to-r from-violet-500 to-indigo-500"
                />
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white pt-24 pb-12 px-4">
      <div className="max-w-2xl mx-auto">
        <Button
          variant="ghost"
          onClick={() => navigate(-1)}
          className="mb-6 text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back
        </Button>

        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold mb-2 bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
            AI Coaching
          </h1>
          <p className="text-gray-600">
            Get personalized guidance from our AI coaches
          </p>
        </div>

        <AnimatePresence mode="wait">
          {!selectedCoach ? (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="grid grid-cols-1 sm:grid-cols-2 gap-4"
            >
              {coaches.map((coach) => (
                <AICoachCard
                  key={coach.id}
                  icon={coachIcons[coach.category as keyof typeof coachIcons]}
                  category={coach.category}
                  description={coach.description}
                  isSelected={selectedCoach?.id === coach.id}
                  onClick={() => setSelectedCoach(coach)}
                />
              ))}
            </motion.div>
          ) : (
            <ChatInterface
              coachId={selectedCoach.id}
              category={selectedCoach.category}
              onClose={() => setSelectedCoach(null)}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
