
import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

const CoachAccountCreator = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [results, setResults] = useState<any>(null);
  const navigate = useNavigate();

  const createCoachAccounts = async () => {
    setIsCreating(true);
    try {
      // Call the edge function to create coach accounts
      const { data: funcData, error: funcError } = await supabase.functions.invoke('create-coach-accounts');
      
      if (funcError) {
        console.error("Error calling edge function:", funcError);
        toast.error("Failed to link coach accounts. Please try again.");
      } else {
        setResults(funcData.results);
        
        // Check if at least one coach account was created successfully
        const anySuccess = funcData.results.some((r: any) => r.success);
        if (anySuccess) {
          toast.success("Coach accounts linked successfully!");
        } else {
          toast.error("Failed to link coach accounts");
        }
      }
    } catch (error) {
      console.error("Error linking coach accounts:", error);
      toast.error("Failed to link coach accounts. Please try again.");
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <div className="min-h-screen bg-white pt-24 pb-16">
      <div className="max-w-3xl mx-auto px-4">
        <Card>
          <CardHeader>
            <CardTitle>Coach Account Creator</CardTitle>
            <CardDescription>
              Link the three existing coaches to the manually created user accounts
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="mb-4 p-4 bg-yellow-50 border border-yellow-200 rounded-md">
              <p className="text-yellow-800 font-medium">Coach User Mapping:</p>
              <ul className="mt-2 space-y-2 text-sm text-yellow-700">
                <li><strong>Raghav Kapur:</strong> User ID: 01f143fe-0bac-4001-a6bf-1059ee6a3c6c</li>
                <li><strong>Kaptaan Varma:</strong> User ID: d85bfee1-d5b3-42b4-b1e0-82187cec7bea</li>
                <li><strong>Sukhveer Singh:</strong> User ID: 9686c269-d829-4a85-b6b7-81fae1b77935</li>
              </ul>
            </div>
            
            <Button
              onClick={createCoachAccounts}
              disabled={isCreating}
              className="w-full"
            >
              {isCreating ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Linking Coach Accounts...
                </>
              ) : "Link Coach Accounts"}
            </Button>

            {results && (
              <div className="mt-6 space-y-4">
                <h3 className="text-lg font-medium">Results:</h3>
                <pre className="p-4 bg-gray-50 rounded-md text-sm overflow-auto max-h-80">
                  {JSON.stringify(results, null, 2)}
                </pre>
                <Button onClick={() => navigate('/coaches')} className="mt-4">
                  Go to Coaches Page
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CoachAccountCreator;
