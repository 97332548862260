
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./components/AuthProvider";
import { ThemeProvider } from "./components/ThemeProvider";
import { Toaster } from "./components/ui/toaster";
import Navigation from "./components/Navigation";
import BottomNav from "./components/BottomNav";
import Routes from "./Routes";
import ScrollToTop from "./components/ScrollToTop";
import { NetworkStatus } from "./components/NetworkStatus";
import SEO from "./components/SEO";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      retry: 1,
      refetchOnWindowFocus: true, // Refetch when window gains focus
      refetchOnReconnect: true,  // Refetch when reconnecting
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <AuthProvider>
            <SEO />
            <div className="min-h-screen flex flex-col">
              <ScrollToTop />
              <NetworkStatus />
              {/* Navigation has mt-[52px] class added in Mobile mode when discount banner is shown */}
              <Navigation />
              <main className="flex-grow w-full md:mt-0">
                <Routes />
              </main>
              <BottomNav />
            </div>
            <Toaster />
          </AuthProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
