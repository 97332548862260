
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { TrendingUp, Sparkles } from "lucide-react";
import { getGradientColors } from "@/lib/utils";
import { BadmintonIcon, CricketIcon, PickleballIcon, FootballIcon } from "@/components/icons/SportIcons";

const sports = [
  { name: "Badminton", icon: <BadmintonIcon className="w-5 h-5" />, color: "to-purple-500" },
  { name: "Cricket", icon: <CricketIcon className="w-5 h-5" />, color: "to-yellow-500" },
  { name: "Pickleball", icon: <PickleballIcon className="w-5 h-5" />, color: "to-blue-500" },
  { name: "Football", icon: <FootballIcon className="w-5 h-5" />, color: "to-emerald-500" },
];

const MobileTrendingWidget = () => {
  const [featuredSport, setFeaturedSport] = useState(sports[0]);
  const [isIOS, setIsIOS] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Randomly select a sport on initial render
    const randomIndex = Math.floor(Math.random() * sports.length);
    setFeaturedSport(sports[randomIndex]);

    // Detect iOS devices
    const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    setIsIOS(isIOSDevice);
  }, []);

  const handleClick = () => {
    navigate("/venues?sport=" + featuredSport.name.toLowerCase());
  };

  // Dynamically set padding based on device type (more padding for iOS)
  const paddingClasses = isIOS ? "px-4 py-5" : "px-3 py-4";

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`w-full overflow-hidden rounded-2xl shadow-md bg-gradient-to-br from-white/90 to-white/70 backdrop-blur-md border border-white/20 ${
        isIOS ? 'scale-105' : ''
      }`}
      onClick={handleClick}
    >
      <div className="relative w-full h-full">
        {/* Background gradient */}
        <div className="absolute inset-0 opacity-10 bg-gradient-to-r from-pink-400 via-purple-400 to-indigo-400 animate-gradient-x" />
        
        {/* Content */}
        <div className={`relative ${paddingClasses}`}>
          <div className="flex items-center space-x-2 mb-1">
            <div className="flex items-center justify-center h-6 w-6 rounded-full bg-white text-pink-500 overflow-hidden shadow-sm">
              <img 
                src="/lovable-uploads/a0c2292c-cf10-4db0-a09d-fb9ada0e5287.png" 
                alt="beSportly" 
                className="h-4 w-4 object-contain"
              />
            </div>
            <span className="text-xs font-semibold text-gray-700 uppercase tracking-wider">
              Trending Now
            </span>
          </div>
          
          <div className="flex items-center justify-between">
            <div className="flex-1">
              <h3 className="text-lg font-bold text-gray-800">
                Play {featuredSport.name}
              </h3>
              <p className="text-sm text-gray-600">
                Hot spots available near you
              </p>
            </div>
            
            <div className={`flex items-center justify-center w-11 h-11 rounded-xl bg-gradient-to-br from-pink-400 ${featuredSport.color} text-white shadow-inner`}>
              {featuredSport.icon}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default MobileTrendingWidget;
