
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { LogIn } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";

interface LoginPromptProps {
  className?: string;
}

export const LoginPrompt = ({ className }: LoginPromptProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  // Don't render on mobile - we'll use the overlay instead
  if (isMobile) return null;

  const handleSignIn = () => {
    console.log("[LoginPrompt] Redirecting to auth page");
    navigate("/auth", { state: { returnUrl: window.location.pathname + window.location.search } });
  };

  return (
    <div 
      className={cn(
        "flex items-center justify-center",
        "ml-4",
        className
      )}
    >
      <Button
        onClick={handleSignIn}
        className="bg-gradient-to-r from-primary to-primary/80 hover:from-primary/90 hover:to-primary/70 text-white border-none shadow-md hover:shadow-lg transition-all duration-300 gap-2"
      >
        <LogIn className="h-4 w-4" />
        <span>Sign in to book</span>
      </Button>
    </div>
  );
};
