
import * as React from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { Heart, Mail, BadgeCheck, Sparkles, LifeBuoy, Rocket } from "lucide-react";
import { Button } from "@/components/ui/button";
import { motion } from "framer-motion";
import { useIsMobile } from "@/hooks/use-mobile";

interface SupportInfoDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const SupportInfoDialog: React.FC<SupportInfoDialogProps> = ({
  open,
  onOpenChange,
}) => {
  const isMobile = useIsMobile();

  // Common content for both dialog and sheet
  const Content = () => (
    <>
      {/* Decorative elements */}
      <div className="absolute top-0 left-0 w-full h-24 bg-gradient-to-r from-purple-400/30 to-pink-400/30 rounded-t-2xl -z-10"></div>
      <div className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-r from-blue-400/20 to-indigo-400/20 rounded-b-2xl -z-10"></div>
      <div className="absolute -top-10 -right-10 w-40 h-40 bg-pink-400/10 rounded-full blur-xl"></div>
      <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-blue-400/10 rounded-full blur-xl"></div>
      
      <div className="px-5 pt-5 pb-6">
        {/* Header */}
        <div className="flex justify-center mb-4">
          <div className="w-16 h-16 rounded-full bg-gradient-to-tr from-purple-500 via-pink-500 to-indigo-500 flex items-center justify-center shadow-md">
            <Sparkles className="h-8 w-8 text-white" />
          </div>
        </div>
        
        <DialogHeader className="text-center mb-4">
          <DialogTitle className="text-xl font-bold text-white bg-gradient-to-r from-purple-600 via-pink-500 to-indigo-600 bg-clip-text">
            We're Here For You!
          </DialogTitle>
          <p className="text-gray-100 dark:text-gray-100 text-sm mt-1">
            Your satisfaction is our top priority
          </p>
        </DialogHeader>
        
        {/* Content */}
        <div className="space-y-4 mb-5">
          <motion.div 
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="flex gap-3 bg-purple-50 dark:bg-purple-900/20 p-3 rounded-lg border border-purple-100 dark:border-purple-800/30"
          >
            <div className="shrink-0">
              <Rocket className="h-5 w-5 text-purple-500 dark:text-purple-400" />
            </div>
            <div>
              <p className="text-sm text-purple-800 dark:text-purple-300 font-medium">Just Launched!</p>
              <p className="text-xs text-purple-600 dark:text-purple-400">We apologize in advance for any bugs or technical issues.</p>
            </div>
          </motion.div>
          
          <motion.div 
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="flex gap-3 bg-pink-50 dark:bg-pink-900/20 p-3 rounded-lg border border-pink-100 dark:border-pink-800/30"
          >
            <div className="shrink-0">
              <Mail className="h-5 w-5 text-pink-500 dark:text-pink-400" />
            </div>
            <div>
              <p className="text-sm text-pink-800 dark:text-pink-300 font-medium">Need Assistance?</p>
              <p className="text-xs text-pink-600 dark:text-pink-400">Email us at <span className="font-medium">social@besportly.com</span> or call <span className="font-medium">+91 92204 40441</span></p>
            </div>
          </motion.div>
          
          <motion.div 
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="flex gap-3 bg-blue-50 dark:bg-blue-900/20 p-3 rounded-lg border border-blue-100 dark:border-blue-800/30"
          >
            <div className="shrink-0">
              <BadgeCheck className="h-5 w-5 text-blue-500 dark:text-blue-400" />
            </div>
            <div>
              <p className="text-sm text-blue-800 dark:text-blue-300 font-medium">Our Guarantee</p>
              <p className="text-xs text-blue-600 dark:text-blue-400">If anything goes wrong, you'll receive a full instant refund plus a free booking on us!</p>
            </div>
          </motion.div>
        </div>
        
        <motion.div 
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.4 }}
          className="flex justify-center"
        >
          <Button
            onClick={() => onOpenChange(false)}
            className="rounded-full px-5 bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white shadow-md hover:shadow-lg transition-all duration-300"
          >
            <Heart className="h-4 w-4 mr-2" />
            <span>Got it, thanks!</span>
          </Button>
        </motion.div>
      </div>
    </>
  );

  if (isMobile) {
    return (
      <Sheet open={open} onOpenChange={onOpenChange}>
        <SheetContent 
          side="bottom" 
          className="p-0 overflow-hidden max-h-[85vh] rounded-t-[24px] border-0 shadow-2xl bg-gradient-to-br from-pink-500/90 via-purple-500/90 to-indigo-600/90"
        >
          <div className="w-12 h-1.5 bg-white/30 backdrop-blur-sm rounded-full mx-auto mt-2 mb-2"></div>
          <Content />
        </SheetContent>
      </Sheet>
    );
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="w-[90%] max-w-xs sm:max-w-sm rounded-2xl border-0 shadow-xl bg-gradient-to-br from-white to-gray-50 p-0 overflow-hidden">
        <Content />
      </DialogContent>
    </Dialog>
  );
};
