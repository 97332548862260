import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Search, User, Calendar, MapPin, Star, Filter } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import BackButton from "@/components/ui/back-button";
import { NoResultsAnimation } from "@/components/ui/no-results-animation";
import { useIsMobile } from "@/hooks/use-mobile";
import { motion } from "framer-motion";
import SEO from "@/components/SEO";
import { useAutoRefresh } from '@/hooks/use-auto-refresh';
import { Toggle } from "@/components/ui/toggle";
import { popularCities } from "@/utils/cityData";

type Coach = {
  id: string;
  specialization: string[];
  experience_years: number;
  hourly_rate: number;
  bio: string | null;
  certifications: string[] | null;
  city: string | null;
  profile_image: string | null;
  profile: {
    full_name: string | null;
    avatar_url: string | null;
  } | null;
};

const sportCategories = [
  "Cricket",
  "Football",
  "Basketball",
  "Tennis",
  "Badminton",
  "Pickleball",
  "Swimming",
  "Yoga",
  "Table Tennis",
  "Squash",
  "Chess",
  "Carrom",
  "Boxing",
  "Martial Arts",
  "Athletics",
  "Volleyball",
  "Running",
  "Golf",
];

const Coaches = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedPriceRanges, setSelectedPriceRanges] = useState<string[]>([]);
  const [selectedSports, setSelectedSports] = useState<string[]>([]);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const { data: coaches, isLoading, error } = useQuery({
    queryKey: ["coaches", searchTerm, selectedCities, selectedPriceRanges, selectedSports],
    queryFn: async () => {
      console.log("Fetching coaches with filters:", {
        searchTerm,
        selectedCities,
        selectedPriceRanges,
        selectedSports
      });

      let query = supabase
        .from("coaches")
        .select(`
          id,
          specialization,
          experience_years,
          hourly_rate,
          bio,
          certifications,
          city,
          profile_image,
          profile:profiles(full_name, avatar_url)
        `);

      if (searchTerm) {
        query = query.textSearch('profile.full_name', searchTerm);
      }

      if (selectedCities.length > 0) {
        query = query.in('city', selectedCities);
      }

      if (selectedPriceRanges.length > 0) {
        const priceConditions = selectedPriceRanges.map(range => {
          if (range === "all") return null;
          
          const [min, max] = range.split("-").map(Number);
          if (max) {
            return `hourly_rate.gte.${min},hourly_rate.lte.${max}`;
          } else {
            return `hourly_rate.gte.${min}`;
          }
        }).filter(Boolean);

        if (priceConditions.length > 0) {
          const orConditions = priceConditions.join(",");
          query = query.or(orConditions);
        }
      }

      if (selectedSports.length > 0) {
        query = query.contains('specialization', selectedSports);
      }

      const { data, error } = await query;

      if (error) {
        console.error("Error fetching coaches:", error);
        throw error;
      }

      const fallbackCoachImages: Record<string, string> = {
        'Raghav Kapur': '/lovable-uploads/43af30a5-f215-450d-b49a-2833b8e2a254.png',
        'Kaptaan Varma': '/lovable-uploads/d8557430-9463-43e7-b1d4-0e18f4a2b9da.png',
        'Sukhveer Singh': '/lovable-uploads/3bf8a645-44fe-47a5-a7ce-105b04e83ee2.png'
      };

      const getCoachSortOrder = (name: string) => {
        const order = ['Raghav Kapur', 'Kaptaan Varma', 'Sukhveer Singh'];
        const index = order.indexOf(name);
        return index === -1 ? order.length : index;
      };

      const sortedData = [...data].sort((a: Coach, b: Coach) => {
        const aName = a.bio?.includes('Meghalaya') ? 'Raghav Kapur' :
                     a.bio?.includes('bowling') ? 'Sukhveer Singh' :
                     a.bio?.includes('side-arm') ? 'Kaptaan Varma' : '';
        
        const bName = b.bio?.includes('Meghalaya') ? 'Raghav Kapur' :
                     b.bio?.includes('bowling') ? 'Sukhveer Singh' :
                     b.bio?.includes('side-arm') ? 'Kaptaan Varma' : '';
        
        return getCoachSortOrder(aName) - getCoachSortOrder(bName);
      });

      return sortedData.map((coach: Coach) => {
        const coachName = coach.bio?.includes('Meghalaya') ? 'Raghav Kapur' :
                          coach.bio?.includes('side-arm') ? 'Kaptaan Varma' :
                          coach.bio?.includes('bowling') ? 'Sukhveer Singh' :
                          coach.profile?.full_name;

        const avatarUrl = coach.profile_image || 
                         coach.profile?.avatar_url || 
                         (coachName ? fallbackCoachImages[coachName] : null);

        return {
          ...coach,
          profile: {
            ...coach.profile,
            full_name: coachName,
            avatar_url: avatarUrl
          }
        };
      }) as Coach[];
    },
  });

  const noResultsShowing = !isLoading && (!coaches || coaches.length === 0);
  useAutoRefresh(coaches?.length || 0, noResultsShowing);

  const priceRanges = [
    { label: "All Prices", value: "all" },
    { label: "₹500 - ₹1000", value: "500-1000" },
    { label: "₹1001 - ₹2000", value: "1001-2000" },
    { label: "₹2001 - ₹5000", value: "2001-5000" },
    { label: "₹5001+", value: "5001-10000" },
  ];

  const toggleCity = (city: string) => {
    setSelectedCities(prev => 
      prev.includes(city) 
        ? prev.filter(c => c !== city) 
        : [...prev, city]
    );
  };
  
  const toggleSport = (sport: string) => {
    setSelectedSports(prev => 
      prev.includes(sport) 
        ? prev.filter(s => s !== sport) 
        : [...prev, sport]
    );
  };
  
  const togglePriceRange = (range: string) => {
    setSelectedPriceRanges(prev => 
      prev.includes(range) 
        ? prev.filter(r => r !== range) 
        : [...prev, range]
    );
  };

  const handleCoachClick = (coachId: string) => {
    navigate(`/coaches/${coachId}`);
  };

  const resetFilters = () => {
    setSearchTerm("");
    setSelectedCities([]);
    setSelectedPriceRanges([]);
    setSelectedSports([]);
  };

  const hasActiveFilters = selectedCities.length > 0 || selectedPriceRanges.length > 0 || selectedSports.length > 0;

  const coachStructuredData = coaches && coaches.length > 0 ? {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": coaches.map((coach, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "item": {
        "@type": "Person",
        "name": coach.profile?.full_name || "Coach",
        "description": coach.bio || "",
        "image": coach.profile?.avatar_url || "",
        "jobTitle": "Sports Coach",
        "knowsAbout": coach.specialization,
        "location": {
          "@type": "Place",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": coach.city || ""
          }
        },
        "offers": {
          "@type": "Offer",
          "price": coach.hourly_rate,
          "priceCurrency": "INR",
          "priceValidUntil": new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0]
        }
      }
    }))
  } : null;

  return (
    <div className="min-h-screen bg-white">
      <SEO 
        title="Find Expert Sports Coaches | beSportly"
        description="Connect with professional sports coaches to achieve your goals. Filter by sport, city, and price range to find the perfect coach."
        canonicalUrl="https://besportly.app/coaches"
        structuredData={coachStructuredData}
      />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 md:pt-32">
        <main className="flex-grow pb-16">
          <div className="relative mb-8">
            <div className="absolute left-0 top-1 z-10">
              <BackButton />
            </div>
            <h1 className="text-4xl font-bold bg-gradient-to-r from-violet-600 via-purple-500 to-indigo-600 bg-clip-text text-transparent animate-gradient px-1 py-1 rounded-lg relative mb-4 pl-10 md:pl-0 inline-block md:block w-auto">
              Find Expert Coaches
              <div className="h-1 md:w-full w-[100%] rounded-full bg-gradient-to-r from-violet-500 via-purple-400 to-indigo-500 opacity-70 absolute bottom-0 left-0"></div>
            </h1>
            <p className="text-lg text-gray-600 pl-10 md:pl-0">
              Connect with professional coaches to achieve your goals
            </p>
          </div>

          <div className="relative mb-6">
            <div className="relative">
              <Search className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
              <Input
                type="text"
                placeholder="Search coaches..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10"
              />
              <Button
                variant="outline"
                size="icon"
                className="absolute right-0 top-0 h-full px-3 rounded-l-none border-l-0"
                onClick={() => setFiltersVisible(!filtersVisible)}
              >
                <Filter className="h-5 w-5" />
                {hasActiveFilters && (
                  <span className="absolute -top-1 -right-1 bg-purple-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                    !
                  </span>
                )}
              </Button>
            </div>

            {filtersVisible && (
              <div className="bg-white px-4 py-3 rounded-lg shadow-sm border mt-2 transition-all">
                <div className="mb-4">
                  <h3 className="font-medium text-sm text-gray-700 mb-2">City</h3>
                  <div className="flex flex-wrap gap-1.5">
                    {popularCities.slice(0, isMobile ? 8 : 20).map((city) => (
                      <button
                        key={city}
                        className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                          selectedCities.includes(city) 
                            ? "bg-purple-500 text-white" 
                            : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                        }`}
                        onClick={() => toggleCity(city)}
                      >
                        {city}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="mb-4">
                  <h3 className="font-medium text-sm text-gray-700 mb-2">Sport</h3>
                  <div className="flex flex-wrap gap-1.5">
                    {sportCategories.map((sport) => (
                      <button
                        key={sport}
                        className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                          selectedSports.includes(sport) 
                            ? "bg-purple-500 text-white" 
                            : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                        }`}
                        onClick={() => toggleSport(sport)}
                      >
                        {sport}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="mb-4">
                  <h3 className="font-medium text-sm text-gray-700 mb-2">Price Range</h3>
                  <div className="flex flex-wrap gap-1.5">
                    {priceRanges.slice(1).map((range) => (
                      <button
                        key={range.value}
                        className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                          selectedPriceRanges.includes(range.value) 
                            ? "bg-purple-500 text-white" 
                            : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                        }`}
                        onClick={() => togglePriceRange(range.value)}
                      >
                        {range.label}
                      </button>
                    ))}
                  </div>
                </div>

                {hasActiveFilters && (
                  <Button
                    variant="ghost"
                    className="text-purple-600 hover:text-purple-700 p-0 h-auto font-medium"
                    onClick={resetFilters}
                  >
                    Clear all filters
                  </Button>
                )}
              </div>
            )}
          </div>

          {isLoading ? (
            <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ${isMobile ? 'mobile-search-grid' : ''}`}>
              {[1, 2, 3, 4, 5, 6].map((n) => (
                <Card key={n} className="overflow-hidden">
                  <Skeleton className="h-48 w-full" />
                  <CardHeader>
                    <Skeleton className="h-6 w-2/3" />
                    <Skeleton className="h-4 w-full" />
                  </CardHeader>
                </Card>
              ))}
            </div>
          ) : noResultsShowing ? (
            <NoResultsAnimation type="coaches" onReset={resetFilters} />
          ) : (
            <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ${isMobile ? 'mobile-search-grid' : ''}`}>
              {coaches.map((coach) => (
                isMobile ? (
                  <motion.div
                    key={coach.id}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className="mobile-search-card coach-search-card"
                    onClick={() => handleCoachClick(coach.id)}
                  >
                    <div className="card-image h-44">
                      {coach.profile?.avatar_url ? (
                        <img
                          src={coach.profile.avatar_url}
                          alt={coach.profile.full_name || "Coach"}
                          className={`w-full h-full object-cover ${
                            coach.bio?.includes('Meghalaya') ? 'object-[center_25%]' : 
                            coach.bio?.includes('side-arm') ? 'object-[center_15%]' : 
                            coach.bio?.includes('bowling') ? 'object-[center_12%]' : 
                            'object-[center_45%]'
                          }`}
                        />
                      ) : (
                        <div className="w-full h-full flex items-center justify-center bg-gray-100">
                          <User className="h-8 w-8 text-gray-400" />
                        </div>
                      )}
                      {coach.experience_years > 5 && (
                        <div className="card-badge">
                          {coach.experience_years}+ yrs
                        </div>
                      )}
                    </div>
                    <div className="card-content">
                      <h3 className="card-title">
                        {coach.bio?.includes('Meghalaya') ? 'Raghav Kapur' :
                         coach.bio?.includes('side-arm') ? 'Kaptaan Varma' :
                         coach.bio?.includes('bowling') ? 'Sukhveer Singh' : 
                         coach.profile?.full_name || "Coach"}
                      </h3>
                      
                      {coach.city && (
                        <div className="card-subtitle">
                          <MapPin className="h-3 w-3 mr-1" />
                          <span>{coach.city}</span>
                        </div>
                      )}
                      
                      <div className="card-tags">
                        {coach.specialization.slice(0, 2).map((sport, i) => (
                          <span key={i} className="card-tag">
                            {sport}
                          </span>
                        ))}
                        {coach.specialization.length > 2 && (
                          <span className="card-tag">
                            +{coach.specialization.length - 2}
                          </span>
                        )}
                      </div>
                      
                      <div className="card-footer">
                        <div className="card-rating">
                          <Calendar className="h-3 w-3 mr-1" />
                          <span>Book</span>
                        </div>
                        <span className="card-price">₹{coach.hourly_rate}/hr</span>
                      </div>
                    </div>
                  </motion.div>
                ) : (
                  <Card 
                    key={coach.id} 
                    className="overflow-hidden hover:shadow-lg transition-shadow"
                  >
                    <div 
                      className="h-64 bg-gray-200 relative flex items-center justify-center cursor-pointer"
                      onClick={() => handleCoachClick(coach.id)}
                    >
                      {coach.profile?.avatar_url ? (
                        <>
                          <img
                            src={coach.profile.avatar_url}
                            alt={coach.profile.full_name || "Coach"}
                            className={`w-full h-full object-cover ${
                              coach.bio?.includes('Meghalaya') ? 'object-[center_25%]' : 
                              coach.bio?.includes('side-arm') ? 'object-[center_15%]' : 
                              coach.bio?.includes('bowling') ? 'object-[center_12%]' : 
                              'object-[center_45%]'
                            }`}
                          />
                          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 hover:opacity-100 transition-opacity flex items-end">
                            <div className="p-4 w-full">
                              <Button className="w-full bg-white text-gray-900 hover:bg-white/90">
                                View Profile
                              </Button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <User className="h-20 w-20 text-gray-400" />
                      )}
                    </div>
                    <CardHeader 
                      className="cursor-pointer"
                      onClick={() => handleCoachClick(coach.id)}
                    >
                      <CardTitle className="text-gray-900 dark:text-gray-900">
                        {coach.bio?.includes('Meghalaya') ? 'Raghav Kapur' :
                         coach.bio?.includes('side-arm') ? 'Kaptaan Varma' :
                         coach.bio?.includes('bowling') ? 'Sukhveer Singh' : 
                         coach.profile?.full_name || "Coach"}
                      </CardTitle>
                      <CardDescription className="text-gray-600 dark:text-gray-600">
                        {coach.bio}
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <div className="flex flex-wrap gap-2 mb-2">
                        {coach.specialization.map((sport) => (
                          <span key={sport} className="px-2 py-1 bg-gray-100 rounded-full text-sm text-gray-600">
                            {sport}
                          </span>
                        ))}
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="flex items-center text-gray-600">
                          <span className="font-medium">₹{coach.hourly_rate}/hour</span>
                        </div>
                        {coach.city && (
                          <div className="text-sm text-gray-600">
                            📍 {coach.city}
                          </div>
                        )}
                        <div className="text-sm text-gray-600">
                          {coach.experience_years} years of experience
                        </div>
                        {coach.certifications && coach.certifications.length > 0 && (
                          <div className="text-sm text-gray-600">
                            {coach.certifications.map((cert, index) => (
                              <div key={index} className="flex items-center gap-1">
                                <span>•</span> {cert}
                              </div>
                            ))}
                          </div>
                        )}
                        
                        <Button 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCoachClick(coach.id);
                          }}
                          className="w-full mt-4 bg-gradient-to-r from-violet-600 to-indigo-600 hover:from-violet-700 hover:to-indigo-700 text-white font-medium py-2 shadow-md hover:shadow-lg transition-all duration-300"
                        >
                          <Calendar className="w-4 h-4 mr-2" />
                          Book Now
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                )
              ))}
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Coaches;
