
import { useState, useRef, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Bot, ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/components/AuthProvider";
import { ChatMessage } from "@/components/AISupport/ChatMessage";
import { ChatInput } from "@/components/AISupport/ChatInput";
import { ReportForm } from "@/components/AISupport/ReportForm";

interface Message {
  role: "assistant" | "user";
  content: string;
  includeOptions?: boolean;
}

interface Option {
  label: string;
  action: () => void;
}

interface ReportData {
  user_id: string | undefined;
  email: string;
  content: string;
  status: 'pending' | 'resolved';
}

const AISupport = () => {
  const [messages, setMessages] = useState<Message[]>([
    {
      role: "assistant",
      content: "Hello! I'm your AI support assistant. How can I help you today?",
    },
  ]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [reportContent, setReportContent] = useState("");
  const [reportEmail, setReportEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { toast } = useToast();
  const { user } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleHumanSupport = async () => {
    const { error } = await supabase.from('support_inquiries').insert({
      name: 'AI Support User',
      email: 'support@besportly.com',
      message: 'User requested human support via AI chat interface',
      status: 'pending'
    });

    if (error) {
      console.error('Error logging support request:', error);
    }

    setMessages(prev => [...prev, {
      role: "assistant",
      content: "I've forwarded your request to our support team. A customer support representative will get back to you within 12 hours. In the meantime, is there anything else I can help you with?"
    }]);
  };

  const checkForHumanSupportRequest = (content: string) => {
    const humanKeywords = ['human', 'agent', 'person', 'representative', 'real person', 'support team'];
    return humanKeywords.some(keyword => content.toLowerCase().includes(keyword));
  };

  const handleSubmit = async (content: string) => {
    if (!content.trim()) return;

    setInput("");
    setIsLoading(true);

    const newMessages = [
      ...messages,
      { role: "user" as const, content: content.trim() },
    ];
    setMessages(newMessages);

    try {
      if (checkForHumanSupportRequest(content)) {
        setMessages([
          ...newMessages,
          {
            role: "assistant",
            content: "I understand you'd like to speak with a human agent. How would you like to proceed?",
            includeOptions: true
          }
        ]);
      } else {
        const { data, error } = await supabase.functions.invoke('ai-support', {
          body: {
            messages: newMessages.map(m => ({
              role: m.role,
              content: m.content,
            })),
          },
        });

        if (error) {
          console.error("Error calling AI Support:", error);
          throw error;
        }

        if (!data?.generatedText) {
          throw new Error("No response received from AI");
        }

        setMessages([
          ...newMessages,
          { role: "assistant", content: data.generatedText },
        ]);
      }
    } catch (error) {
      console.error("Error calling AI Support:", error);
      setMessages([
        ...newMessages,
        {
          role: "assistant",
          content: "I apologize, but I encountered an error. Please try again.",
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReport = async () => {
    if (!reportContent.trim()) return;

    setIsSubmitting(true);
    try {
      const reportData: ReportData = {
        user_id: user?.id,
        email: reportEmail,
        content: reportContent,
        status: 'pending'
      };

      const { error } = await supabase
        .from('ai_content_reports')
        .insert(reportData as any);

      if (error) throw error;

      toast({
        title: "Report Submitted",
        description: "Thank you for your report. We'll review it shortly.",
        className: "bg-white border-green-500 border-2"
      });
      
      setReportContent("");
      setIsReportOpen(false);
    } catch (error) {
      console.error("Error submitting report:", error);
      toast({
        variant: "destructive",
        title: "Error submitting report",
        description: "Please try again later.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (user?.email) {
      setReportEmail(user.email);
    }
  }, [user]);

  const supportOptions: Option[] = [
    {
      label: "Connect with Human Agent",
      action: handleHumanSupport
    },
    {
      label: "Continue with AI Support",
      action: () => setMessages(prev => [...prev, {
        role: "assistant",
        content: "I'll continue assisting you. What would you like to know?"
      }])
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 p-4 md:p-8 flex items-center justify-center md:pt-32 ai-support-page">
      <div className="w-full max-w-3xl bg-white rounded-xl shadow-lg border border-gray-200 overflow-hidden flex flex-col h-[85vh] md:h-[80vh]">
        <div className="flex items-center justify-between p-4 border-b bg-gradient-to-r from-blue-50 to-purple-50">
          <div className="flex items-center space-x-2">
            <Button variant="ghost" onClick={() => navigate(-1)} className="mr-2">
              <ArrowLeft className="w-4 h-4" />
            </Button>
            <Bot className="w-6 h-6 text-blue-600" />
            <h2 className="text-xl font-semibold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              AI Support
            </h2>
          </div>
        </div>

        <ScrollArea className="flex-1 p-4">
          <div className="space-y-4">
            {messages.map((message, i) => (
              <ChatMessage
                key={i}
                message={message}
                supportOptions={supportOptions}
              />
            ))}
            <div ref={messagesEndRef} />
          </div>
        </ScrollArea>

        <div className="p-4 border-t bg-white mb-[60px] md:mb-0">
          <div className="space-y-4">
            <ChatInput
              input={input}
              isLoading={isLoading}
              onInputChange={setInput}
              onSubmit={handleSubmit}
            />

            <ReportForm
              isOpen={isReportOpen}
              onOpenChange={setIsReportOpen}
              email={reportEmail}
              content={reportContent}
              isSubmitting={isSubmitting}
              onEmailChange={setReportEmail}
              onContentChange={setReportContent}
              onSubmit={handleReport}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AISupport;
