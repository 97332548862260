import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { useIsMobile } from "@/hooks/use-mobile";
import { supabase } from "@/integrations/supabase/client";
import { Search as SearchIcon, Star, MapPin, ArrowLeft, Filter, Sparkles, Gift, ShoppingBag, BadgeDollarSign, Percent, Tag } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Venue } from "@/types/venue";
import { motion } from "framer-motion";
import { popularCities } from "@/utils/cityData";
import BackButton from "@/components/ui/back-button";
import VenueDetailsSheet from "@/components/venues/VenueDetailsSheet";
import GymDetailsSheet from "@/components/gyms/GymDetailsSheet";
import AcademyDetailsSheet from "@/components/academies/AcademyDetailsSheet";
import { Toggle } from "@/components/ui/toggle";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { NoResultsAnimation } from "@/components/ui/no-results-animation";
import { User } from "lucide-react";
import { getVenueImage, getExternalImage } from "@/utils/venueImageUtils";

const bubbles = Array.from({ length: 12 }, (_, i) => i);

type Coach = {
  id: string;
  specialization: string[];
  experience_years: number;
  hourly_rate: number;
  bio: string | null;
  certifications: string[] | null;
  city: string | null;
  profile_image: string | null;
  profile_id?: string;
  name?: string;
  expertise?: string[];
  rating?: number;
  is_verified?: boolean;
};

type Gym = {
  id: string;
  name: string;
  description?: string | null;
  address: string;
  city: string;
  state: string;
  pincode: string;
  facilities: string[];
  monthly_fee: number;
  rating: number;
  images?: string[];
  is_external: boolean;
  is_bookable: boolean;
  phone?: string;
  website?: string;
  place_id?: string;
  maps_url?: string;
  data_source?: string;
  opening_hours?: string[];
  reviews?: any[];
  opening_time: string;
  closing_time: string;
  max_capacity?: number | null;
};

type Academy = {
  id: string;
  name: string;
  description: string | null;
  sports: string[];
  monthly_fee: number;
  programs: any | null;
  image_url: string | null;
  city: string;
  rating: number;
  total_ratings: number;
  is_external: boolean;
  is_bookable?: boolean;
  address?: string;
  state?: string;
  pincode?: string;
  phone?: string;
  website?: string;
  place_id?: string;
  maps_url?: string;
  data_source?: string;
  opening_hours?: string[];
  reviews?: any[];
  opening_time?: string;
  closing_time?: string;
};

const Search = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [searchTerm, setSearchTerm] = useState("");
  const [tab, setTab] = useState("venues");
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [selectedPriceRanges, setSelectedPriceRanges] = useState<string[]>([]);
  const [selectedSports, setSelectedSports] = useState<string[]>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<string[]>([]);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null);
  const [isVenueSheetOpen, setIsVenueSheetOpen] = useState(false);
  const [selectedGym, setSelectedGym] = useState<Gym | null>(null);
  const [isGymSheetOpen, setIsGymSheetOpen] = useState(false);
  const [selectedAcademy, setSelectedAcademy] = useState<Academy | null>(null);
  const [isAcademySheetOpen, setIsAcademySheetOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("venues");

  const { data: venues = [], isLoading: venuesLoading } = useQuery({
    queryKey: ["search-venues", searchTerm, selectedCities, selectedPriceRanges, selectedSports],
    queryFn: async () => {
      let query = supabase.from("venues").select("*");

      if (searchTerm) {
        query = query.ilike("name", `%${searchTerm}%`);
      }

      if (selectedCities.length > 0) {
        query = query.in("city", selectedCities);
      }

      if (selectedPriceRanges.length > 0) {
        const priceConditions = selectedPriceRanges.map(range => {
          if (range === "all") return null;
          
          const [min, max] = range.split("-").map(Number);
          if (max) {
            return `price_per_hour.gte.${min},price_per_hour.lte.${max}`;
          } else {
            return `price_per_hour.gte.${min}`;
          }
        }).filter(Boolean);

        if (priceConditions.length > 0) {
          const orConditions = priceConditions.join(",");
          query = query.or(orConditions);
        }
      }

      const { data, error } = await query;

      if (error) throw error;
      
      const typedVenues = (data as any[] || []).map(v => ({
        ...v,
        maps_url: v.place_id ? `https://www.google.com/maps/place/?q=place_id:${v.place_id}` : null
      }));
      
      let filteredVenues = typedVenues;
      if (selectedSports.length > 0) {
        filteredVenues = typedVenues.filter(venue => {
          if (!venue.available_sports) return false;
          return selectedSports.some(sport => venue.available_sports.includes(sport));
        });
      }

      return filteredVenues.sort((a, b) => {
        if (a.is_bookable === b.is_bookable) return 0;
        return a.is_bookable ? -1 : 1;
      });
    },
  });

  const { data: gyms = [], isLoading: gymsLoading } = useQuery({
    queryKey: ["search-gyms", searchTerm, selectedCities, selectedPriceRanges, selectedFacilities],
    queryFn: async () => {
      let query = supabase.from("gyms").select("*");

      if (searchTerm) {
        query = query.ilike("name", `%${searchTerm}%`);
      }

      if (selectedCities.length > 0) {
        query = query.in("city", selectedCities);
      }

      if (selectedPriceRanges.length > 0) {
        const priceConditions = selectedPriceRanges.map(range => {
          if (range === "all") return null;
          
          const [min, max] = range.split("-").map(Number);
          if (max) {
            return `monthly_fee.gte.${min},monthly_fee.lte.${max}`;
          } else {
            return `monthly_fee.gte.${min}`;
          }
        }).filter(Boolean);

        if (priceConditions.length > 0) {
          const orConditions = priceConditions.join(",");
          query = query.or(orConditions);
        }
      }

      const { data, error } = await query;

      if (error) throw error;
      
      const typedGyms = (data as any[] || []).map(g => ({
        ...g,
        maps_url: g.place_id ? `https://www.google.com/maps/place/?q=place_id:${g.place_id}` : null
      }));
      
      let filteredGyms = typedGyms;
      if (selectedFacilities.length > 0) {
        filteredGyms = typedGyms.filter(gym => {
          if (!gym.facilities) return false;
          return selectedFacilities.some(facility => gym.facilities.includes(facility));
        });
      }

      return filteredGyms;
    },
  });

  const { data: academies = [], isLoading: academiesLoading } = useQuery({
    queryKey: ["search-academies", searchTerm, selectedCities, selectedPriceRanges, selectedSports],
    queryFn: async () => {
      let query = supabase.from("academies").select("*");

      if (searchTerm) {
        query = query.ilike("name", `%${searchTerm}%`);
      }

      if (selectedCities.length > 0) {
        query = query.in("city", selectedCities);
      }

      const { data, error } = await query;

      if (error) throw error;
      
      let filteredData = data as Academy[];
      
      if (selectedPriceRanges.length > 0) {
        filteredData = filteredData.filter(academy => {
          return selectedPriceRanges.some(range => {
            const [min, max] = range.split('-').map(Number);
            return academy.monthly_fee >= min && academy.monthly_fee <= max;
          });
        });
      }
      
      if (selectedSports.length > 0) {
        filteredData = filteredData.filter(academy => {
          if (!academy.sports) return false;
          return selectedSports.some(sport => academy.sports.includes(sport));
        });
      }
      
      return filteredData;
    },
  });

  const { data: coaches = [], isLoading: coachesLoading } = useQuery({
    queryKey: ["search-coaches", searchTerm, selectedCities, selectedSports],
    queryFn: async () => {
      let query = supabase
        .from("coaches")
        .select("*, profiles(full_name, avatar_url)");

      if (searchTerm) {
        query = query.ilike("bio", `%${searchTerm}%`);
      }

      if (selectedCities.length > 0) {
        query = query.in("city", selectedCities);
      }

      const { data, error } = await query;

      if (error) throw error;
      
      let coachesData = (data as any[]).map(coach => ({
        ...coach,
        expertise: coach.specialization,
        name: coach.profiles?.full_name || `Coach ${coach.id.substring(0, 4)}`,
        profile_image: coach.profile_image || coach.profiles?.avatar_url,
        rating: 4.5,
        is_verified: false
      })) as Coach[];
      
      if (selectedSports.length > 0) {
        coachesData = coachesData.filter(coach => {
          if (!coach.specialization) return false;
          return selectedSports.some(sport => coach.specialization.includes(sport));
        });
      }
      
      return coachesData;
    },
  });

  const venuePriceRanges = [
    { label: "All Prices", value: "all" },
    { label: "₹200 - ₹500", value: "200-500" },
    { label: "₹501 - ₹1000", value: "501-1000" },
    { label: "₹1001 - ₹2000", value: "1001-2000" },
    { label: "₹2001 - ₹5000", value: "2001-5000" },
  ];

  const gymPriceRanges = [
    { label: "₹1000 - ₹3000", value: "1000-3000" },
    { label: "₹3001 - ₹5000", value: "3001-5000" },
    { label: "₹5001 - ₹10000", value: "5001-10000" },
    { label: "₹10001+", value: "10001-50000" },
  ];

  const academyPriceRanges = [
    { label: "₹1000 - ₹3000", value: "1000-3000" },
    { label: "₹3001 - ₹5000", value: "3001-5000" },
    { label: "₹5001 - ₹10000", value: "5001-10000" },
    { label: "₹10001+", value: "10001-50000" },
  ];

  const gymFacilities = [
    "Strength Training",
    "CrossFit",
    "Yoga", 
    "Zumba",
    "Personal Training",
    "Cardio",
    "Swimming Pool",
  ];

  const sportCategories = [
    "Cricket",
    "Football",
    "Basketball",
    "Tennis",
    "Badminton",
    "Pickleball",
    "Swimming",
    "Yoga",
    "Squash",
    "Table Tennis",
  ];

  const toggleCity = (city: string) => {
    setSelectedCities(prev => 
      prev.includes(city) 
        ? prev.filter(c => c !== city) 
        : [...prev, city]
    );
  };
  
  const togglePriceRange = (range: string) => {
    setSelectedPriceRanges(prev => 
      prev.includes(range) 
        ? prev.filter(r => r !== range) 
        : [...prev, range]
    );
  };
  
  const toggleSport = (sport: string) => {
    setSelectedSports(prev => 
      prev.includes(sport) 
        ? prev.filter(s => s !== sport) 
        : [...prev, sport]
    );
  };
  
  const toggleFacility = (facility: string) => {
    setSelectedFacilities(prev => 
      prev.includes(facility) 
        ? prev.filter(f => f !== facility) 
        : [...prev, facility]
    );
  };

  const resetFilters = () => {
    setSearchTerm("");
    setSelectedCities([]);
    setSelectedPriceRanges([]);
    setSelectedSports([]);
    setSelectedFacilities([]);
  };

  const hasActiveFilters = selectedCities.length > 0 || selectedPriceRanges.length > 0 || 
                         selectedSports.length > 0 || selectedFacilities.length > 0;

  const handleVenueClick = (venue: Venue) => {
    if (venue.is_bookable) {
      navigate(`/book-venue/${venue.id}`);
    } else {
      setSelectedVenue(venue);
      setIsVenueSheetOpen(true);
    }
  };

  const handleGymClick = (gym: Gym) => {
    setSelectedGym(gym);
    setIsGymSheetOpen(true);
  };

  const handleAcademyClick = (academy: Academy) => {
    setSelectedAcademy(academy);
    setIsAcademySheetOpen(true);
  };

  const getPriceRanges = () => {
    switch (tab) {
      case 'venues':
        return venuePriceRanges;
      case 'gyms':
        return gymPriceRanges;
      case 'academies':
        return academyPriceRanges;
      default:
        return venuePriceRanges;
    }
  };

  const renderCoachItem = (coach: any) => {
    const coachName = coach.bio?.includes('Meghalaya') ? 'Raghav Kapur' :
                     coach.bio?.includes('side-arm') ? 'Kaptaan Varma' :
                     coach.bio?.includes('bowling') ? 'Sukhveer Singh' : 
                     coach.profiles?.full_name || "Coach";
    
    const fallbackCoachImages: Record<string, string> = {
      'Raghav Kapur': '/lovable-uploads/43af30a5-f215-450d-b49a-2833b8e2a254.png',
      'Kaptaan Varma': '/lovable-uploads/d8557430-9463-43e7-b1d4-0e18f4a2b9da.png',
      'Sukhveer Singh': '/lovable-uploads/3bf8a645-44fe-47a5-a7ce-105b04e83ee2.png'
    };
    
    const avatarUrl = coach.profile_image || 
                     coach.profiles?.avatar_url || 
                     (coachName ? fallbackCoachImages[coachName] : null);
    
    const imageStyle = coach.bio?.includes('Meghalaya') ? 'object-[center_25%]' : 
                      coach.bio?.includes('side-arm') ? 'object-[center_15%]' : 
                      coach.bio?.includes('bowling') ? 'object-[center_12%]' : 
                      'object-[center_45%]';

    if (isMobile) {
      return (
        <motion.div
          key={coach.id}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="mobile-search-card"
          onClick={() => navigate(`/coaches/${coach.id}`)}
        >
          <div className="card-image">
            {avatarUrl ? (
              <img
                src={avatarUrl}
                alt={coachName}
                className={`w-full h-full object-cover ${imageStyle}`}
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center bg-gray-100">
                <User className="h-8 w-8 text-gray-400" />
              </div>
            )}
            {coach.experience_years > 5 && (
              <div className="card-badge primary-badge">
                {coach.experience_years}+ yrs
              </div>
            )}
          </div>
          <div className="card-content">
            <h3 className="card-title">{coachName}</h3>
            <div className="card-subtitle">
              <MapPin className="h-3 w-3 mr-1" />
              <span>{coach.city || "Gurgaon"}</span>
            </div>
            <div className="card-tags">
              {coach.specialization?.slice(0, 2).map((sport: string, i: number) => (
                <span key={i} className="card-tag">
                  {sport}
                </span>
              ))}
              {coach.specialization?.length > 2 && (
                <span className="card-tag">
                  +{coach.specialization.length - 2}
                </span>
              )}
            </div>
            <div className="card-footer">
              <div className="flex items-center text-xs text-gray-500">
                <Star className="h-3 w-3 text-yellow-400 fill-yellow-400 mr-1" />
                <span>4.9</span>
              </div>
              <span className="card-price">₹{coach.hourly_rate}/hr</span>
            </div>
          </div>
        </motion.div>
      );
    }

    return (
      <Card 
        key={coach.id}
        className="overflow-hidden cursor-pointer hover:shadow-lg transition-all"
        onClick={() => navigate(`/coaches/${coach.id}`)}
      >
        <div className="relative h-48 overflow-hidden bg-gray-100">
          {avatarUrl ? (
            <img
              src={avatarUrl}
              alt={coachName}
              className={`w-full h-full object-cover ${imageStyle}`}
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <User className="h-12 w-12 text-gray-400" />
            </div>
          )}
        </div>
        <CardHeader className="p-4">
          <CardTitle className="text-lg">{coachName}</CardTitle>
          <CardDescription className="flex items-center text-sm">
            <MapPin className="h-3.5 w-3.5 mr-1 flex-shrink-0" />
            <span>{coach.city || "Gurgaon"}</span>
          </CardDescription>
        </CardHeader>
        <CardContent className="px-4 pb-3 pt-0">
          <div className="flex flex-wrap gap-1 mb-3">
            {coach.specialization?.map((sport: string) => (
              <Badge key={sport} variant="outline" className="bg-gray-100 text-gray-700 hover:bg-gray-200">
                {sport}
              </Badge>
            ))}
          </div>
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <Star className="h-4 w-4 text-yellow-400 fill-yellow-400 mr-1" />
              <span className="text-sm font-medium">4.9</span>
            </div>
            <span className="text-primary font-semibold">₹{coach.hourly_rate}/hr</span>
          </div>
        </CardContent>
      </Card>
    );
  };

  const renderResults = () => {
    if (currentTab === 'venues') {
      if (venuesLoading) {
        return (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {[1, 2, 3, 4, 5, 6].map(i => (
              <div key={i} className="h-80 bg-gray-100 animate-pulse rounded-lg"></div>
            ))}
          </div>
        );
      }

      if (venues.length === 0) {
        return (
          <div className="text-center py-12">
            <h3 className="text-lg font-medium text-gray-900">No venues found</h3>
            <p className="mt-1 text-sm text-gray-500">Try adjusting your search filters</p>
          </div>
        );
      }

      return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {venues.map((venue) => (
            isMobile ? (
              <motion.div
                key={venue.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="mobile-search-card venue-search-card"
                onClick={() => handleVenueClick(venue)}
              >
                <div className="card-image">
                  <img
                    src={getVenueImage(venue)}
                    alt={venue.name}
                    className="w-full h-full object-cover"
                  />
                  <div className="card-badge">
                    {venue.is_bookable ? "Book Now" : "External"}
                  </div>
                </div>
                <div className="card-content">
                  <h3 className="card-title">{venue.name}</h3>
                  <div className="card-subtitle">
                    <MapPin className="h-3 w-3 mr-1" />
                    <span className="text-gray-600 text-xs truncate">{venue.city}</span>
                  </div>
                  <div className="card-tags">
                    {venue.available_sports?.slice(0, 2).map((sport, i) => (
                      <span key={i} className="card-tag">
                        {sport}
                      </span>
                    ))}
                    {venue.available_sports?.length > 2 && (
                      <span className="card-tag">
                        +{venue.available_sports.length - 2}
                      </span>
                    )}
                  </div>
                  <div className="card-footer">
                    <div className="card-rating">
                      <Star className="h-3 w-3 text-yellow-400 fill-yellow-400 mr-1" />
                      <span>{venue.rating?.toFixed(1) || "4.5"}</span>
                    </div>
                    
                    {!venue.is_bookable && (
                      <div className="flex items-center mr-1">
                        <img src="/lovable-uploads/8ead321d-1e98-43b9-b971-053d58ba6729.png" alt="Google Maps" className="h-4 w-4 mr-1" />
                        <span className="text-xs text-blue-600 font-medium">From Google Maps</span>
                      </div>
                    )}
                    
                    <span className="card-price">
                      {venue.is_bookable 
                        ? `₹${venue.price_per_hour}/hr` 
                        : "Available on inquiry"}
                    </span>
                  </div>
                </div>
              </motion.div>
            ) : (
              <div
                key={venue.id}
                className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow"
                onClick={() => handleVenueClick(venue)}
              >
                <div className="relative h-48">
                  <img
                    src={getVenueImage(venue)}
                    alt={venue.name}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute top-2 left-2 px-3 py-1 bg-gradient-to-r from-orange-400 to-yellow-300 text-white backdrop-blur-sm rounded-full text-sm font-medium">
                    {venue.is_bookable ? "Book Now" : "External"}
                  </div>
                </div>
                <div className="p-4">
                  <h3 className="font-semibold mb-1 truncate">{venue.name}</h3>
                  <div className="flex items-center text-gray-600 text-sm mb-2">
                    <MapPin className="h-3 w-3 mr-1" />
                    <span>{venue.city}</span>
                  </div>
                  <div className="flex flex-wrap gap-1 mb-3">
                    {venue.available_sports?.slice(0, 3).map((sport, idx) => (
                      <span key={idx} className="bg-gray-100 text-gray-700 text-xs px-2 py-0.5 rounded-full">
                        {sport}
                      </span>
                    ))}
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <Star className="h-4 w-4 text-yellow-400 fill-yellow-400 mr-1" />
                      <span className="text-sm">{venue.rating?.toFixed(1) || "4.5"}</span>
                    </div>
                    <span className="text-sm font-medium text-purple-600">
                      {venue.is_bookable ? `₹${venue.price_per_hour}/hr` : "View Details"}
                    </span>
                  </div>
                </div>
              </div>
            )
          ))}
        </div>
      );
    }

    if (currentTab === 'coaches') {
      if (coachesLoading) {
        return (
          <div className="col-span-full flex justify-center items-center p-8">
            <div className="h-8 w-8 border-4 border-primary border-t-transparent rounded-full animate-spin"></div>
          </div>
        );
      }

      if (coaches.length === 0) {
        return (
          <div className="col-span-full">
            <NoResultsAnimation 
              type="coaches" 
              onReset={() => {
                setSelectedCities([]);
                setSelectedPriceRanges([]);
                setSelectedSports([]);
              }} 
            />
          </div>
        );
      }

      return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {coaches.map((coach: any) => renderCoachItem(coach))}
        </div>
      );
    }

    if (currentTab === 'gyms') {
      if (gymsLoading) {
        return (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {[1, 2, 3, 4, 5, 6].map(i => (
              <div key={i} className="h-80 bg-gray-100 animate-pulse rounded-lg"></div>
            ))}
          </div>
        );
      }

      if (gyms.length === 0) {
        return (
          <div className="text-center py-12">
            <h3 className="text-lg font-medium text-gray-900">No gyms found</h3>
            <p className="mt-1 text-sm text-gray-500">Try adjusting your search filters</p>
          </div>
        );
      }

      return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {gyms.map((gym) => (
            isMobile ? (
              <motion.div
                key={gym.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="mobile-search-card gym-search-card"
                onClick={() => handleGymClick(gym)}
              >
                <div className="card-image">
                  <img
                    src={getExternalImage(gym)}
                    alt={gym.name}
                    className="w-full h-full object-cover"
                  />
                  <div className="card-badge">
                    {gym.is_external ? "External" : "Book Now"}
                  </div>
                </div>
                <div className="card-content">
                  <h3 className="card-title">{gym.name}</h3>
                  <div className="card-subtitle">
                    <MapPin className="h-3 w-3 mr-1" />
                    <span>{gym.city}</span>
                  </div>
                  <div className="card-tags">
                    {gym.facilities?.slice(0, 2).map((facility, i) => (
                      <span key={i} className="card-tag">
                        {facility}
                      </span>
                    ))}
                    {gym.facilities?.length > 2 && (
                      <span className="card-tag">
                        +{gym.facilities.length - 2}
                      </span>
                    )}
                  </div>
                  <div className="card-footer">
                    <div className="card-rating">
                      <Star className="h-3 w-3 text-yellow-400 fill-yellow-400 mr-1" />
                      <span>{gym.rating?.toFixed(1) || "New"}</span>
                    </div>
                    {gym.is_external && (
                      <div className="flex items-center mx-auto">
                        <img src="/lovable-uploads/8ead321d-1e98-43b9-b971-053d58ba6729.png" alt="Google Maps" className="h-3 w-3 mr-1" />
                        <span className="text-xs text-blue-600 font-medium">From Google Maps</span>
                      </div>
                    )}
                    <span className="card-price">{gym.is_external ? "Available on inquiry" : `₹${gym.monthly_fee}/mo`}</span>
                  </div>
                </div>
              </motion.div>
            ) : (
              <div
                key={gym.id}
                className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow"
                onClick={() => handleGymClick(gym)}
              >
                <div className="relative h-48">
                  <img
                    src={getExternalImage(gym)}
                    alt={gym.name}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute top-2 left-2 px-3 py-1 bg-gradient-to-r from-orange-400 to-yellow-300 text-white backdrop-blur-sm rounded-full text-sm font-medium">
                    {gym.is_external ? "External" : "Book Now"}
                  </div>
                </div>
                <div className="p-4">
                  <h3 className="font-semibold mb-1 truncate">{gym.name}</h3>
                  <div className="flex items-center text-gray-600 text-sm mb-2">
                    <MapPin className="h-3 w-3 mr-1" />
                    <span>{gym.city}</span>
                  </div>
                  <div className="flex flex-wrap gap-1 mb-3">
                    {gym.facilities?.slice(0, 3).map((facility, idx) => (
                      <span key={idx} className="bg-gray-100 text-gray-700 text-xs px-2 py-0.5 rounded-full">
                        {facility}
                      </span>
                    ))}
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <Star className="h-4 w-4 text-yellow-400 fill-yellow-400 mr-1" />
                      <span className="text-sm">{gym.rating?.toFixed(1) || "4.5"}</span>
                    </div>
                    <span className="text-sm font-medium text-orange-600">
                      {gym.is_external ? `View Details` : `₹${gym.monthly_fee}/mo`}
                    </span>
                  </div>
                </div>
              </div>
            )
          ))}
        </div>
      );
    }

    if (currentTab === 'academies') {
      if (academiesLoading) {
        return (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {[1, 2, 3, 4, 5, 6].map(i => (
              <div key={i} className="h-80 bg-gray-100 animate-pulse rounded-lg"></div>
            ))}
          </div>
        );
      }

      if (academies.length === 0) {
        return (
          <div className="text-center py-12">
            <h3 className="text-lg font-medium text-gray-900">No academies found</h3>
            <p className="mt-1 text-sm text-gray-500">Try adjusting your search filters</p>
          </div>
        );
      }

      return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {academies.map((academy) => (
            isMobile ? (
              <motion.div
                key={academy.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="mobile-search-card academy-search-card"
                onClick={() => handleAcademyClick(academy)}
              >
                <div className="card-image">
                  <img
                    src={getExternalImage(academy)}
                    alt={academy.name}
                    className="w-full h-full object-cover"
                  />
                  <div className="card-badge">
                    {academy.is_external ? "External" : "Book Now"}
                  </div>
                </div>
                <div className="card-content">
                  <h3 className="card-title">{academy.name}</h3>
                  <div className="card-subtitle">
                    <MapPin className="h-3 w-3 mr-1" />
                    <span>{academy.city}</span>
                  </div>
                  <div className="card-tags">
                    {academy.sports?.slice(0, 2).map((sport, i) => (
                      <span key={i} className="card-tag">
                        {sport}
                      </span>
                    ))}
                    {academy.sports?.length > 2 && (
                      <span className="card-tag">
                        +{academy.sports.length - 2}
                      </span>
                    )}
                  </div>
                  <div className="card-footer">
                    <div className="card-rating">
                      <Star className="h-3 w-3 text-yellow-400 fill-yellow-400 mr-1" />
                      <span>{academy.rating?.toFixed(1) || "New"}</span>
                    </div>
                    {academy.is_external && (
                      <div className="flex items-center mx-auto">
                        <img src="/lovable-uploads/8ead321d-1e98-43b9-b971-053d58ba6729.png" alt="Google Maps" className="h-3 w-3 mr-1" />
                        <span className="text-xs text-blue-600 font-medium">From Google Maps</span>
                      </div>
                    )}
                    <span className="card-price">{academy.is_external ? "Available on inquiry" : `₹${academy.monthly_fee}/mo`}</span>
                  </div>
                </div>
              </motion.div>
            ) : (
              <div
                key={academy.id}
                className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow"
                onClick={() => handleAcademyClick(academy)}
              >
                <div className="relative h-48">
                  <img
                    src={getExternalImage(academy)}
                    alt={academy.name}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute top-2 left-2 px-3 py-1 bg-gradient-to-r from-orange-400 to-yellow-300 text-white backdrop-blur-sm rounded-full text-sm font-medium">
                    {academy.is_external ? "External" : "Book Now"}
                  </div>
                </div>
                <div className="p-4">
                  <h3 className="font-semibold mb-1 truncate">{academy.name}</h3>
                  <div className="flex items-center text-gray-600 text-sm mb-2">
                    <MapPin className="h-3 w-3 mr-1" />
                    <span>{academy.city}</span>
                  </div>
                  <div className="flex flex-wrap gap-1 mb-3">
                    {academy.sports?.slice(0, 3).map((sport, idx) => (
                      <span key={idx} className="bg-gray-100 text-gray-700 text-xs px-2 py-0.5 rounded-full">
                        {sport}
                      </span>
                    ))}
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <Star className="h-4 w-4 text-yellow-400 fill-yellow-400 mr-1" />
                      <span className="text-sm">{academy.rating?.toFixed(1) || "4.5"}</span>
                    </div>
                    <span className="text-sm font-medium text-emerald-600">
                      {academy.is_external ? `View Details` : `₹${academy.monthly_fee}/mo`}
                    </span>
                  </div>
                </div>
              </div>
            )
          ))}
        </div>
      );
    }

    if (currentTab === 'deals') {
      return (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="w-full"
        >
          <div className="relative overflow-hidden rounded-2xl bg-gradient-to-br from-purple-600 via-violet-600 to-indigo-600 shadow-xl">
            <div className="absolute top-0 left-0 right-0 h-full overflow-hidden">
              {bubbles.map((i) => (
                <motion.div
                  key={i}
                  className="absolute bg-white/10 rounded-full"
                  style={{
                    width: Math.random() * 180 + 40,
                    height: Math.random() * 180 + 40,
                    left: `${Math.random() * 100}%`,
                    top: `${Math.random() * 100}%`,
                  }}
                  animate={{ 
                    scale: [0.8, 1.2, 0.8], 
                    opacity: [0.3, 0.6, 0.3],
                    x: [0, Math.random() * 40 - 20],
                    y: [0, Math.random() * 40 - 20]
                  }}
                  transition={{
                    duration: Math.random() * 5 + 8,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut",
                    delay: Math.random() * 2
                  }}
                />
              ))}
            </div>

            <div className="relative z-10 px-6 py-12 md:py-16 text-center text-white">
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ 
                  duration: 0.5,
                  delay: 0.3
                }}
                className="flex justify-center mb-8"
              >
                <div className="relative">
                  <motion.div
                    className="absolute -inset-1.5 rounded-full opacity-70 blur-sm bg-white"
                    animate={{ 
                      scale: [1, 1.2, 1], 
                      opacity: [0.5, 0.8, 0.5] 
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  />
                  <div className="relative bg-gradient-to-r from-pink-500 to-violet-500 p-4 rounded-full shadow-lg shadow-purple-900/30">
                    <Tag className="h-10 w-10 text-white" />
                  </div>
                </div>
              </motion.div>
              
              <motion.h2 
                className="text-3xl md:text-4xl font-bold mb-4 drop-shadow-md"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.5 }}
              >
                Exclusive Sports & Fitness Deals
              </motion.h2>
              
              <motion.p 
                className="text-white/90 text-lg mb-10 max-w-2xl mx-auto"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.7 }}
              >
                Discover amazing discounts on your favorite sports gear, fitness equipment, nutrition supplements and more. All curated just for you!
              </motion.p>

              <motion.div
                className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-3xl mx-auto mb-10"
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.7, delay: 0.8 }}
              >
                {[
                  {
                    title: "Gear & Equipment",
                    icon: <ShoppingBag className="h-6 w-6" />,
                    description: "Up to 40% off on quality sports gear",
                    color: "from-orange-400/90 to-amber-500/90"
                  },
                  {
                    title: "Fitness Apparel",
                    icon: <Tag className="h-6 w-6" />,
                    description: "Premium brands at unbeatable prices",
                    color: "from-indigo-400/90 to-blue-500/90"
                  },
                  {
                    title: "Supplements",
                    icon: <Gift className="h-6 w-6" />,
                    description: "BOGO offers on top nutrition brands",
                    color: "from-emerald-400/90 to-green-500/90"
                  }
                ].map((category, idx) => (
                  <div 
                    key={idx} 
                    className={`rounded-xl bg-gradient-to-br ${category.color} p-4 text-white shadow-lg hover:shadow-xl transform transition-all duration-300 hover:-translate-y-1 flex flex-col items-center`}
                  >
                    <div className="bg-white/20 p-3 rounded-full mb-3">
                      {category.icon}
                    </div>
                    <h3 className="font-bold">{category.title}</h3>
                    <p className="text-sm text-white/90 mt-1">{category.description}</p>
                  </div>
                ))}
              </motion.div>

              <motion.div 
                className="flex justify-center items-center mb-8"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1 }}
              >
                <div className="flex items-center space-x-2">
                  <motion.div 
                    className="h-2.5 w-2.5 rounded-full bg-white" 
                    animate={{
                      scale: [1, 1.5, 1],
                      opacity: [1, 0.7, 1]
                    }}
                    transition={{
                      duration: 1.5,
                      repeat: Infinity,
                      repeatType: "loop"
                    }}
                  />
                  <motion.div 
                    className="h-2.5 w-2.5 rounded-full bg-white" 
                    animate={{
                      scale: [1, 1.5, 1],
                      opacity: [1, 0.7, 1]
                    }}
                    transition={{
                      duration: 1.5,
                      delay: 0.3,
                      repeat: Infinity,
                      repeatType: "loop"
                    }}
                  />
                  <motion.div 
                    className="h-2.5 w-2.5 rounded-full bg-white" 
                    animate={{
                      scale: [1, 1.5, 1],
                      opacity: [1, 0.7, 1]
                    }}
                    transition={{
                      duration: 1.5,
                      delay: 0.6,
                      repeat: Infinity,
                      repeatType: "loop"
                    }}
                  />
                </div>
              </motion.div>
            </div>
          </div>

          <motion.div 
            className="mt-8 text-center bg-gradient-to-r from-purple-100 to-indigo-100/50 p-4 rounded-xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1.4 }}
          >
            <div className="flex justify-center items-center gap-2">
              <Sparkles className="h-5 w-5 text-purple-500" />
              <p className="text-purple-700 font-medium">Our team is assembling the best sports & fitness deals from across the web</p>
            </div>
          </motion.div>
        </motion.div>
      );
    }
  };

  useEffect(() => {
    setCurrentTab(tab);
  }, [tab]);

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 md:pt-32">
        <div className="relative mb-4">
          <div className="absolute left-3 top-0 z-10">
            <BackButton onClick={() => navigate('/')} />
          </div>
          
          <div className={`${isMobile ? 'text-center' : 'text-left pl-10 md:pl-0'} mb-6`}>
            <h1 className="text-2xl md:text-3xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-indigo-600 bg-clip-text text-transparent">
              Search for anything
            </h1>
            <p className="text-gray-600">
              Find venues, coaches, deals and more
            </p>
          </div>

          <Tabs 
            defaultValue="venues" 
            value={tab} 
            onValueChange={(value) => setTab(value as "venues" | "coaches" | "gyms" | "academies" | "deals")}
            className="w-full"
          >
            <div className="absolute w-full left-0 right-0 z-10 bg-white">
              <TabsList className="w-full">
                <TabsTrigger value="venues" className="flex-1">Venues</TabsTrigger>
                <TabsTrigger value="coaches" className="flex-1">Coaches</TabsTrigger>
                <TabsTrigger value="gyms" className="flex-1">Gyms</TabsTrigger>
                <TabsTrigger value="academies" className="flex-1">Academies</TabsTrigger>
                <TabsTrigger value="deals" className="flex-1">
                  Deals
                </TabsTrigger>
              </TabsList>
            </div>

            <div className="pt-14 pb-4">
              <div className="relative">
                <SearchIcon className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                <Input
                  type="text"
                  placeholder={`Search ${tab}...`}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-10"
                />
                <Button
                  variant="outline"
                  size="icon"
                  className="absolute right-0 top-0 h-full px-3 rounded-l-none border-l-0"
                  onClick={() => setFiltersVisible(!filtersVisible)}
                >
                  <Filter className="h-5 w-5" />
                  {hasActiveFilters && (
                    <span className="absolute -top-1 -right-1 flex h-2 w-2">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-2 w-2 bg-purple-500"></span>
                    </span>
                  )}
                </Button>
              </div>

              {filtersVisible && tab !== 'deals' && (
                <div className="bg-white px-4 py-3 rounded-lg shadow-sm border mt-2 transition-all">
                  <div className="mb-4">
                    <h3 className="font-medium text-sm text-gray-700 mb-2">City <span className="text-xs text-gray-500">(select multiple)</span></h3>
                    <div className="flex flex-wrap gap-1.5">
                      {popularCities.slice(0, isMobile ? 8 : 20).map((city) => (
                        <Toggle
                          key={city}
                          className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                            selectedCities.includes(city) 
                              ? "bg-purple-500 text-white" 
                              : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                          }`}
                          pressed={selectedCities.includes(city)}
                          onPressedChange={() => toggleCity(city)}
                        >
                          {city}
                        </Toggle>
                      ))}
                    </div>
                  </div>

                  <div className="mb-4">
                    <h3 className="font-medium text-sm text-gray-700 mb-2">Price Range <span className="text-xs text-gray-500">(select multiple)</span></h3>
                    <div className="flex flex-wrap gap-1.5">
                      {getPriceRanges().slice(isMobile ? 0 : 1).map((range) => (
                        <Toggle
                          key={range.value}
                          className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                            selectedPriceRanges.includes(range.value) 
                              ? "bg-purple-500 text-white" 
                              : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                          }`}
                          pressed={selectedPriceRanges.includes(range.value)}
                          onPressedChange={() => togglePriceRange(range.value)}
                        >
                          {range.label}
                        </Toggle>
                      ))}
                    </div>
                  </div>

                  {tab === 'gyms' && (
                    <div className="mb-4">
                      <h3 className="font-medium text-sm text-gray-700 mb-2">Amenities <span className="text-xs text-gray-500">(select multiple)</span></h3>
                      <div className="flex flex-wrap gap-1.5">
                        {gymFacilities.slice(0, isMobile ? 6 : gymFacilities.length).map((facility) => (
                          <Toggle
                            key={facility}
                            className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                              selectedFacilities.includes(facility)
                                ? "bg-purple-500 text-white" 
                                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                            }`}
                            pressed={selectedFacilities.includes(facility)}
                            onPressedChange={() => toggleFacility(facility)}
                          >
                            {facility}
                          </Toggle>
                        ))}
                      </div>
                    </div>
                  )}

                  {(tab === 'venues' || tab === 'academies' || tab === 'coaches') && (
                    <div className="mb-4">
                      <h3 className="font-medium text-sm text-gray-700 mb-2">Sports <span className="text-xs text-gray-500">(select multiple)</span></h3>
                      <div className="flex flex-wrap gap-1.5">
                        {sportCategories.map((sport) => (
                          <Toggle
                            key={sport}
                            className={`px-2 py-1 rounded-full text-xs focus:outline-none ${
                              selectedSports.includes(sport)
                                ? "bg-purple-500 text-white" 
                                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                            }`}
                            pressed={selectedSports.includes(sport)}
                            onPressedChange={() => toggleSport(sport)}
                          >
                            {sport}
                          </Toggle>
                        ))}
                      </div>
                    </div>
                  )}

                  {hasActiveFilters && (
                    <Button
                      variant="ghost"
                      className="text-purple-600 hover:text-purple-700 p-0 h-auto font-medium"
                      onClick={resetFilters}
                    >
                      Clear all filters
                    </Button>
                  )}
                </div>
              )}
            </div>

            <TabsContent value="venues" className="mt-0">
              {renderResults()}
            </TabsContent>

            <TabsContent value="coaches" className="mt-0">
              {renderResults()}
            </TabsContent>

            <TabsContent value="gyms" className="mt-0">
              {renderResults()}
            </TabsContent>

            <TabsContent value="academies" className="mt-0">
              {renderResults()}
            </TabsContent>

            <TabsContent value="deals" className="mt-0">
              {renderResults()}
            </TabsContent>
          </Tabs>
        </div>
      </div>

      <VenueDetailsSheet 
        venue={selectedVenue}
        isOpen={isVenueSheetOpen}
        onClose={() => setIsVenueSheetOpen(false)}
      />

      <GymDetailsSheet
        gym={selectedGym}
        isOpen={isGymSheetOpen}
        onClose={() => setIsGymSheetOpen(false)}
      />

      <AcademyDetailsSheet
        academy={selectedAcademy}
        isOpen={isAcademySheetOpen}
        onClose={() => setIsAcademySheetOpen(false)}
      />

      <style>
        {`
        .card-badge {
          position: absolute;
          top: 8px;
          left: 8px;
          background: linear-gradient(90deg, #F97316, #FBBF24);
          color: white;
          font-size: 0.6875rem;
          font-weight: 500;
          padding: 0.25rem 0.5rem;
          border-radius: 9999px;
          display: flex;
          align-items: center;
          box-shadow: 0 1px 2px rgba(0,0,0,0.1);
        }
        
        .mobile-search-card {
          display: flex;
          flex-direction: column;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0 1px 3px rgba(0,0,0,0.1);
          background: white;
          height: 100%;
          cursor: pointer;
          transition: transform 0.2s ease, box-shadow 0.2s ease;
        }
        
        .mobile-search-card:active {
          transform: scale(0.98);
        }
        
        .card-image {
          height: 160px;
          position: relative;
          background-color: #f3f4f6;
        }
        
        .card-content {
          padding: 12px;
          display: flex;
          flex-direction: column;
          flex: 1;
        }
        
        .card-title {
          font-weight: 600;
          font-size: 0.9375rem;
          margin-bottom: 4px;
          color: #1f2937;
        }
        
        .card-subtitle {
          display: flex;
          align-items: center;
          color: #6b7280;
          font-size: 0.75rem;
          margin-bottom: 8px;
        }
        
        .card-tags {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          margin-bottom: 8px;
        }
        
        .card-tag {
          background-color: #f3f4f6;
          color: #4b5563;
          font-size: 0.6875rem;
          padding: 2px 6px;
          border-radius: 4px;
        }
        
        .card-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: auto;
          padding-top: 8px;
          border-top: 1px solid #f3f4f6;
        }
        
        .card-rating {
          display: flex;
          align-items: center;
          font-size: 0.75rem;
          font-weight: 500;
          color: #4b5563;
        }
        
        .card-price {
          font-size: 0.75rem;
          font-weight: 600;
          color: #7c3aed;
        }
        `}
      </style>
    </div>
  );
};

export default Search;
