
import { format } from "date-fns";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useState, useEffect } from "react";

interface DateSelectorProps {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
}

export const DateSelector = ({ selectedDate, onDateChange }: DateSelectorProps) => {
  const [startIndex, setStartIndex] = useState(0);

  // Generate dates for a full year, ensuring we're working with IST dates
  const dates = Array.from({ length: 365 }, (_, i) => {
    // Create a date in the local timezone (which for Indian users will be IST)
    const date = new Date();
    date.setDate(date.getDate() + i);
    
    // Ensure the date is treated as IST by setting hours to noon to avoid any date shift issues
    date.setHours(12, 0, 0, 0);
    
    return date;
  });

  const visibleDates = dates.slice(startIndex, startIndex + 7);

  const handlePrevious = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const handleNext = () => {
    if (startIndex + 7 < dates.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const handleDateClick = (date: Date) => {
    // Preserve the time from the currently selected date if there is one
    const newDate = new Date(date);
    
    // Get current hours and minutes to determine if we're in the date shift window
    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    const isInDateShiftRange = currentHours < 5 || (currentHours === 5 && currentMinutes < 30);
    
    if (selectedDate) {
      newDate.setHours(
        selectedDate.getHours(),
        selectedDate.getMinutes(),
        selectedDate.getSeconds(),
        selectedDate.getMilliseconds()
      );
    } else {
      // Set to noon to avoid any timezone edge cases
      newDate.setHours(12, 0, 0, 0);
    }
    
    // If we're in the date shift range (midnight to 5:30 AM IST),
    // we need to ensure the date is correctly handled for the backend
    console.log("Current time in IST:", now.toLocaleString(), "Is in date shift range:", isInDateShiftRange);
    
    if (isInDateShiftRange) {
      console.log("In date shift range. Original selected date:", newDate.toISOString());
      console.log("Selected date in IST:", newDate.toISOString());
      // No additional adjustment needed as the backend now handles this special case
    } else {
      console.log("Normal hours. Selected date in IST:", newDate.toISOString());
    }
    
    onDateChange(newDate);
  };

  // If the selected date is out of view, adjust the view
  useEffect(() => {
    if (!selectedDate) return;
    
    const selectedIndex = dates.findIndex(date => 
      date.toDateString() === selectedDate.toDateString()
    );
    
    if (selectedIndex >= 0) {
      const isVisible = selectedIndex >= startIndex && selectedIndex < startIndex + 7;
      if (!isVisible) {
        // Move the view to show the selected date
        setStartIndex(Math.max(0, Math.min(dates.length - 7, selectedIndex - 3)));
      }
    }
  }, [selectedDate, dates, startIndex]);

  return (
    <Card className="mb-6 mx-[-1rem] sm:mx-0">
      <CardContent className="p-4">
        <div className="flex items-center justify-between space-x-2">
          <Button 
            variant="outline" 
            size="icon" 
            className="shrink-0"
            onClick={handlePrevious}
            disabled={startIndex === 0}
          >
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <div className="flex-1 grid grid-cols-7 gap-1">
            {visibleDates.map((date) => {
              const isSelected = selectedDate && date.toDateString() === selectedDate.toDateString();
              return (
                <Button
                  key={date.toISOString()}
                  variant={isSelected ? "default" : "outline"}
                  className="flex flex-col items-center p-1 h-auto w-full touch-manipulation"
                  onClick={() => handleDateClick(date)}
                  onTouchStart={() => handleDateClick(date)}
                >
                  <span className="text-xs font-medium">
                    {format(date, "EEE")}
                  </span>
                  <span className="text-lg font-bold">
                    {format(date, "d")}
                  </span>
                  <span className="text-xs">
                    {format(date, "MMM")}
                  </span>
                </Button>
              );
            })}
          </div>
          <Button 
            variant="outline" 
            size="icon" 
            className="shrink-0"
            onClick={handleNext}
            disabled={startIndex + 7 >= dates.length}
          >
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
