
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/components/AuthProvider";
import { Users, Award, MapPin, Clock, User, ArrowRight } from "lucide-react";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Badge } from "@/components/ui/badge";
import { toast } from "sonner";

type Coach = {
  id: string;
  bio: string | null;
  experience_years: number;
  hourly_rate: number;
  city: string;
  phone: string | null;
  specialization: string[];
  certifications: string[] | null;
  coaching_locations: string[] | null;
  created_at: string;
  profile: {
    full_name: string | null;
    avatar_url: string | null;
    email: string | null;
  } | null;
};

const CoachDashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [coach, setCoach] = useState<Coach | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [inquiryCount, setInquiryCount] = useState(0);

  useEffect(() => {
    const fetchCoachProfile = async () => {
      if (!user) return;

      try {
        const { data, error } = await supabase
          .from('coaches')
          .select(`
            *,
            profile:profiles(full_name, avatar_url, email)
          `)
          .eq('profile_id', user.id)
          .single();

        if (error) {
          if (error.code === 'PGRST116') {
            // No profile found
            setCoach(null);
          } else {
            throw error;
          }
        } else {
          setCoach(data);
          
          // Simulate inquiry count for demo purposes
          // In a real app, you would fetch this from a separate inquiries table
          setInquiryCount(Math.floor(Math.random() * 5));
        }
      } catch (error) {
        console.error("Error fetching coach profile:", error);
        toast.error("Failed to load your coach profile");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCoachProfile();
  }, [user]);

  const handleCreateProfile = () => {
    navigate("/coach/register");
  };

  const handleViewPublicProfile = () => {
    if (coach) {
      navigate(`/coaches/${coach.id}`);
    }
  };

  if (isLoading) {
    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <Skeleton className="h-10 w-1/3" />
          <Skeleton className="h-10 w-24" />
        </div>
        <Skeleton className="h-64 w-full" />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <Skeleton className="h-32 w-full" />
          <Skeleton className="h-32 w-full" />
          <Skeleton className="h-32 w-full" />
        </div>
      </div>
    );
  }

  if (!coach) {
    return (
      <div className="flex flex-col items-center justify-center space-y-6 py-12">
        <div className="text-center space-y-4">
          <div className="bg-gray-100 rounded-full p-6 inline-block">
            <User className="h-16 w-16 text-gray-400" />
          </div>
          <h2 className="text-2xl font-bold">You're not registered as a coach yet</h2>
          <p className="text-gray-600 max-w-md">
            Register as a coach to create your profile and start connecting with potential students. Share your expertise and grow your coaching business.
          </p>
        </div>
        <Button onClick={handleCreateProfile} size="lg" className="mt-4">
          Create Coach Profile
        </Button>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Coach Dashboard</h1>
          <p className="text-gray-500">Manage your coaching profile and respond to inquiries</p>
        </div>
        <Button onClick={handleViewPublicProfile}>
          View Public Profile
        </Button>
      </div>

      <Card className="overflow-hidden">
        <div className="bg-gradient-to-r from-indigo-500 to-violet-500 h-32"></div>
        <div className="relative px-6">
          <div className="absolute -top-16 left-6 bg-white rounded-full p-1 shadow-lg">
            {coach.profile?.avatar_url ? (
              <img
                src={coach.profile.avatar_url}
                alt={coach.profile?.full_name || "Coach"}
                className="h-24 w-24 rounded-full object-cover"
              />
            ) : (
              <div className="h-24 w-24 rounded-full bg-gray-100 flex items-center justify-center">
                <User className="h-12 w-12 text-gray-400" />
              </div>
            )}
          </div>
          <div className="pt-12 pb-6">
            <div className="space-y-1">
              <h2 className="text-2xl font-bold">
                {coach.profile?.full_name || "Coach"}
              </h2>
              <div className="flex flex-wrap gap-2">
                {coach.specialization.map((sport) => (
                  <Badge key={sport} variant="secondary">
                    {sport}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
        </div>
        <CardContent className="pb-6">
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-y-4 gap-x-6">
              <div className="flex items-center space-x-2">
                <Award className="h-5 w-5 text-amber-500" />
                <span>{coach.experience_years} years experience</span>
              </div>
              <div className="flex items-center space-x-2">
                <MapPin className="h-5 w-5 text-blue-500" />
                <span>{coach.city}</span>
              </div>
              <div className="flex items-center space-x-2">
                <Clock className="h-5 w-5 text-green-500" />
                <span>₹{coach.hourly_rate}/hour</span>
              </div>
            </div>
            
            <div className="pt-4">
              <h3 className="font-medium mb-2">Bio</h3>
              <p className="text-gray-600">{coach.bio}</p>
            </div>
          </div>
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-lg flex items-center">
              <Users className="mr-2 h-5 w-5 text-blue-500" />
              Inquiries
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-3xl font-bold">{inquiryCount}</p>
            <p className="text-sm text-gray-500">Pending inquiries</p>
          </CardContent>
          <CardFooter>
            <Button variant="ghost" className="w-full" onClick={() => navigate("/coach/inquiries")}>
              View Inquiries
              <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          </CardFooter>
        </Card>

        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-lg flex items-center">
              <Award className="mr-2 h-5 w-5 text-amber-500" />
              Profile Status
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex items-center space-x-2">
              <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">
                Active
              </Badge>
            </div>
            <p className="text-sm text-gray-500 mt-1">Your profile is visible to users</p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-lg flex items-center">
              <Award className="mr-2 h-5 w-5 text-amber-500" />
              Account Created
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="font-medium">
              {new Date(coach.created_at).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </p>
            <p className="text-sm text-gray-500">Date your coach profile was created</p>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CoachDashboard;
