
import { Helmet } from "react-helmet-async";

interface SEOProps {
  title?: string;
  description?: string;
  canonicalUrl?: string;
  ogType?: string;
  ogImage?: string;
  structuredData?: Record<string, any>;
}

const SEO = ({
  title = "beSportly - venues, coaches, gyms, academies, events, deals and more",
  description = "Find and book sports venues, coaches, gyms, academies, events, deals and more. Connect with the sports community in your city.",
  canonicalUrl = "https://besportly.app",
  ogType = "website",
  ogImage = "/og-image.png",
  structuredData,
}: SEOProps) => {
  // Base structured data for Organization (for Knowledge Graph)
  const baseStructuredData = {
    "@context": "https://schema.org",
    "@type": "SportsOrganization",
    "name": "beSportly",
    "url": "https://besportly.app",
    "logo": "https://besportly.app/lovable-uploads/a0c2292c-cf10-4db0-a09d-fb9ada0e5287.png",
    "description": "beSportly - Connect with the sports community in your city",
    "address": {
      "@type": "PostalAddress",
      "addressCountry": "India"
    },
    "sameAs": [
      "https://facebook.com/besportly",
      "https://twitter.com/besportly",
      "https://instagram.com/besportly"
    ]
  };

  // Merge with page-specific structured data
  const finalStructuredData = structuredData 
    ? { ...baseStructuredData, ...structuredData } 
    : baseStructuredData;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
      
      {/* Structured Data for Knowledge Graph */}
      <script type="application/ld+json">
        {JSON.stringify(finalStructuredData)}
      </script>
    </Helmet>
  );
};

export default SEO;
