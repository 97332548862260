
import { useState } from "react";
import { Dumbbell, Scale, Apple, Target, Bot, ArrowRight, Cpu } from "lucide-react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { useAuth } from "@/components/AuthProvider";
import { MealForm } from "@/components/CalorieTracker/MealForm";
import { WeightForm } from "@/components/CalorieTracker/WeightForm";
import { ExerciseForm } from "@/components/CalorieTracker/ExerciseForm";
import { MealHistory } from "@/components/CalorieTracker/MealHistory";
import { WeightHistory } from "@/components/CalorieTracker/WeightHistory";
import { ExerciseHistory } from "@/components/CalorieTracker/ExerciseHistory";
import { ProgressGraphs } from "@/components/CalorieTracker/ProgressGraphs";
import { AIInsights } from "@/components/CalorieTracker/AIInsights";
import { SignInPrompt } from "@/components/CalorieTracker/SignInPrompt";
import { ExpertContact } from "@/components/CalorieTracker/ExpertContact";
import { QuoteOfTheDay } from "@/components/CalorieTracker/QuoteOfTheDay";
import { useNavigate } from "react-router-dom";

const BeFit = () => {
  const { user } = useAuth();
  const [refreshKey, setRefreshKey] = useState(0);
  const navigate = useNavigate();

  const handleMealUpdated = () => {
    setRefreshKey(prev => prev + 1);
  };

  const handleWeightUpdated = () => {
    setRefreshKey(prev => prev + 1);
  };

  const handleExerciseUpdated = () => {
    setRefreshKey(prev => prev + 1);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white pt-16 md:pt-32">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600 mb-4">
            Let's Put Your Health First
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Use our AI-powered platform to create a personalized fitness and nutrition plan
            tailored to your unique goals and preferences.
          </p>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="mt-8 mb-12"
          >
            <Card className="max-w-2xl mx-auto bg-gradient-to-r from-violet-50 to-fuchsia-50 border-2 border-purple-100 hover:shadow-lg transition-all duration-300">
              <CardContent className="p-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center">
                      <Cpu className="h-6 w-6 text-purple-600" />
                    </div>
                    <div className="text-left">
                      <h3 className="text-lg font-semibold text-black">Connect with AI Coaches</h3>
                      <p className="text-sm text-gray-600">Get personalized guidance from our advanced AI coaches</p>
                    </div>
                  </div>
                  <Button 
                    onClick={() => navigate("/ai-coaching")}
                    className="bg-gradient-to-r from-purple-600 to-fuchsia-600 hover:from-purple-700 hover:to-fuchsia-700 text-white shadow-md hover:shadow-lg transition-all duration-200"
                    size="sm"
                  >
                    Try Now
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </div>
              </CardContent>
            </Card>
          </motion.div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <Card className="p-6">
            <CardHeader>
              <CardTitle className="text-black">Track Your Progress</CardTitle>
              <CardDescription>
                Log your meals, exercises, and weight to monitor your fitness journey
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-8">
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-black">Log a Meal</h3>
                <MealForm onMealAdded={handleMealUpdated} />
              </div>
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-black">Log Weight</h3>
                <WeightForm onWeightAdded={handleWeightUpdated} />
              </div>
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-black">Log Exercise</h3>
                <ExerciseForm onExerciseAdded={handleExerciseUpdated} />
              </div>
            </CardContent>
          </Card>

          <Card className="p-6">
            <CardContent>
              <Tabs defaultValue="meals" className="w-full">
                <TabsList className="grid w-full grid-cols-3">
                  <TabsTrigger value="meals" className="text-black">
                    <span className="md:inline hidden">Meal History</span>
                    <span className="md:hidden inline">Meals</span>
                  </TabsTrigger>
                  <TabsTrigger value="weights" className="text-black">
                    <span className="md:inline hidden">Weight History</span>
                    <span className="md:hidden inline">Weight</span>
                  </TabsTrigger>
                  <TabsTrigger value="exercises" className="text-black">
                    <span className="md:inline hidden">Exercise History</span>
                    <span className="md:hidden inline">Exercise</span>
                  </TabsTrigger>
                </TabsList>

                <TabsContent value="meals">
                  <MealHistory 
                    key={refreshKey} 
                    onMealUpdated={handleMealUpdated} 
                  />
                </TabsContent>
                <TabsContent value="weights">
                  <WeightHistory 
                    key={refreshKey} 
                    onWeightUpdated={handleWeightUpdated} 
                  />
                </TabsContent>
                <TabsContent value="exercises">
                  <ExerciseHistory 
                    key={refreshKey} 
                    onExerciseUpdated={handleExerciseUpdated} 
                  />
                </TabsContent>
              </Tabs>
            </CardContent>
          </Card>
        </div>

        {/* Progress Graphs Section */}
        <div className="mt-8 mb-8">
          <Card className="p-6">
            <CardHeader>
              <CardTitle className="text-black">Progress Overview</CardTitle>
              <CardDescription>
                Track your weight, calorie intake, and exercise progress over time
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ProgressGraphs key={refreshKey} />
            </CardContent>
          </Card>
        </div>

        {/* AI Insights Section */}
        <div className="mt-8 mb-8">
          <AIInsights refreshTrigger={refreshKey} />
        </div>

        {user ? (
          <div className="w-full max-w-none px-4 sm:px-6 lg:px-8 mt-12">
            <div className="grid grid-cols-1 gap-8">
              <ExpertContact />
              <QuoteOfTheDay />
              
              <Card className="p-6 max-w-none mx-auto w-full">
                <CardHeader>
                  <CardTitle className="text-black">Connect Your Health Apps</CardTitle>
                  <CardDescription className="text-left">
                    Sync your fitness data from your favorite platforms
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    {[
                      { name: "Apple Health", icon: "🍎" },
                      { name: "Google Fit", icon: "🏃" },
                      { name: "Fitbit", icon: "⌚" },
                      { name: "Garmin", icon: "🎯" },
                      { name: "Strava", icon: "🚴" },
                      { name: "MyFitnessPal", icon: "📱" },
                    ].map((app) => (
                      <div key={app.name} className="flex flex-col items-center">
                        <Button
                          variant="outline"
                          className="w-full h-24 flex flex-col items-center justify-center gap-2 relative overflow-hidden"
                          disabled
                        >
                          <span className="text-2xl">{app.icon}</span>
                          <span className="text-sm font-medium">{app.name}</span>
                          <span className="absolute top-1 right-1 text-[10px] text-amber-600 bg-amber-100 px-1.5 py-0.5 rounded-full">
                            Soon
                          </span>
                        </Button>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        ) : (
          <div className="mt-12">
            <SignInPrompt />
          </div>
        )}
      </div>
    </div>
  );
};

export default BeFit;
