import { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import { format } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Utensils, Pencil, Check, X, Trash2 } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface MealLog {
  id: string;
  meal_name: string;
  calories: number;
  meal_type: string;
  meal_time: string;
  notes: string | null;
}

export const MealHistory = ({ onMealUpdated }: { onMealUpdated: () => void }) => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [meals, setMeals] = useState<MealLog[]>([]);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editForm, setEditForm] = useState({
    meal_name: "",
    calories: "",
    meal_type: "",
    notes: "",
  });

  useEffect(() => {
    if (!user) return;
    fetchMeals();
  }, [user]);

  const fetchMeals = async () => {
    const { data } = await supabase
      .from("meal_logs")
      .select("*")
      .eq("user_id", user?.id)
      .order("meal_time", { ascending: false });

    if (data) {
      setMeals(data);
    }
  };

  const startEditing = (meal: MealLog) => {
    setEditingId(meal.id);
    setEditForm({
      meal_name: meal.meal_name,
      calories: meal.calories.toString(),
      meal_type: meal.meal_type,
      notes: meal.notes || "",
    });
  };

  const handleUpdate = async (id: string) => {
    try {
      const { error } = await supabase
        .from("meal_logs")
        .update({
          meal_name: editForm.meal_name,
          calories: parseInt(editForm.calories),
          meal_type: editForm.meal_type,
          notes: editForm.notes,
        })
        .eq("id", id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Meal log updated successfully!",
      });

      setEditingId(null);
      fetchMeals();
      onMealUpdated();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update meal log. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const { error } = await supabase
        .from("meal_logs")
        .delete()
        .eq("id", id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Meal log deleted successfully!",
      });

      fetchMeals();
      onMealUpdated();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete meal log. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <Utensils className="w-6 h-6 text-purple-500" />
        <h3 className="text-lg font-semibold">Meal History</h3>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Date</TableHead>
              <TableHead>Meal</TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Calories</TableHead>
              <TableHead>Notes</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {meals.map((meal) => (
              <TableRow key={meal.id}>
                <TableCell>
                  {format(new Date(meal.meal_time), "MMM dd, yyyy HH:mm")}
                </TableCell>
                <TableCell>
                  {editingId === meal.id ? (
                    <Input
                      value={editForm.meal_name}
                      onChange={(e) =>
                        setEditForm({ ...editForm, meal_name: e.target.value })
                      }
                    />
                  ) : (
                    meal.meal_name
                  )}
                </TableCell>
                <TableCell>
                  {editingId === meal.id ? (
                    <Select
                      value={editForm.meal_type}
                      onValueChange={(value) =>
                        setEditForm({ ...editForm, meal_type: value })
                      }
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="breakfast">Breakfast</SelectItem>
                        <SelectItem value="lunch">Lunch</SelectItem>
                        <SelectItem value="dinner">Dinner</SelectItem>
                        <SelectItem value="snack">Snack</SelectItem>
                      </SelectContent>
                    </Select>
                  ) : (
                    <span className="capitalize">{meal.meal_type}</span>
                  )}
                </TableCell>
                <TableCell>
                  {editingId === meal.id ? (
                    <Input
                      type="number"
                      value={editForm.calories}
                      onChange={(e) =>
                        setEditForm({ ...editForm, calories: e.target.value })
                      }
                      className="w-24"
                    />
                  ) : (
                    meal.calories
                  )}
                </TableCell>
                <TableCell>
                  {editingId === meal.id ? (
                    <Input
                      value={editForm.notes || ""}
                      onChange={(e) =>
                        setEditForm({ ...editForm, notes: e.target.value })
                      }
                    />
                  ) : (
                    meal.notes || "-"
                  )}
                </TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    {editingId === meal.id ? (
                      <>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => handleUpdate(meal.id)}
                        >
                          <Check className="w-4 h-4 text-green-500" />
                        </Button>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => setEditingId(null)}
                        >
                          <X className="w-4 h-4 text-red-500" />
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => startEditing(meal)}
                        >
                          <Pencil className="w-4 h-4 text-gray-500" />
                        </Button>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => handleDelete(meal.id)}
                          className="hover:bg-red-50"
                        >
                          <Trash2 className="w-4 h-4 text-red-500" />
                        </Button>
                      </>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};