
import { ArrowRight, Activity, Users, Dumbbell, MessageCircle, ShoppingBag, Calendar, Waves, Star } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useAuth } from "@/components/AuthProvider";
import { useToast } from "@/hooks/use-toast";

const FeatureHighlights = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { toast } = useToast();

  const features = [
    {
      title: "Events & Games",
      description: "Join exciting sports events and games in your area",
      icon: Activity,
      path: "/events-and-games",
      gradient: "from-pink-500 via-rose-500 to-orange-400",
    },
    {
      title: "Shop",
      description: "Get the best discounts from brand partners and retailers",
      icon: ShoppingBag,
      path: "/shop",
      gradient: "from-violet-500 via-purple-500 to-fuchsia-500",
    },
    {
      title: "Live Feed",
      description: "Stay updated with real-time sports events coverage",
      icon: Waves,
      path: "/live-feed",
      gradient: "from-purple-500 via-pink-500 to-rose-500",
    },
    {
      title: "be.fit",
      description: "Track your fitness journey with AI-powered insights",
      icon: Dumbbell,
      path: "/be-fit",
      gradient: "from-green-400 via-emerald-500 to-cyan-500",
    },
    {
      title: "Favorites",
      description: "Follow your favorite teams and their latest events",
      icon: Star,
      path: "/favorites",
      gradient: "from-yellow-400 via-amber-500 to-orange-500",
    },
    {
      title: "Bookings",
      description: "View and manage your facility bookings",
      icon: Calendar,
      gradient: "from-blue-400 via-cyan-500 to-teal-500",
      onClick: () => {
        if (user) {
          navigate("/my-bookings");
        } else {
          toast({
            title: "Authentication Required",
            description: "Please sign in to view your bookings",
            variant: "destructive",
          });
          navigate("/auth");
        }
      },
    },
  ];

  return (
    <section className="py-8 md:py-16 relative overflow-hidden bg-[#FFF5FF] dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center mb-8 md:mb-12">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-2xl md:text-3xl font-bold bg-gradient-to-r from-purple-600 via-pink-600 to-orange-400 bg-clip-text text-transparent"
          >
            Explore Our Features
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="mt-4 text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto"
          >
            Discover all the ways you can enhance your journey with us
          </motion.p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3 md:gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
              onClick={feature.onClick || (() => navigate(feature.path))}
              className="cursor-pointer group"
            >
              <div className={`relative overflow-hidden rounded-xl bg-gradient-to-r ${feature.gradient} p-1 shadow-lg hover:shadow-2xl transition-all duration-300`}>
                <div className="bg-white dark:bg-gray-900 rounded-lg p-4 md:p-6 h-full backdrop-blur-sm bg-opacity-90">
                  <div className="flex items-center justify-between mb-4">
                    <feature.icon className={`w-6 h-6 md:w-8 md:h-8 text-gray-700 dark:text-white group-hover:text-gray-900 transition-colors bg-gradient-to-r ${feature.gradient} bg-clip-text`} />
                    <ArrowRight className="w-4 h-4 md:w-5 md:h-5 text-gray-400 dark:text-white group-hover:text-gray-700 transform group-hover:translate-x-1 transition-all" />
                  </div>
                  <h3 className={`text-sm md:text-xl font-semibold text-gray-900 dark:text-white mb-2 group-hover:text-transparent group-hover:bg-clip-text group-hover:bg-gradient-to-r group-hover:${feature.gradient} transition-all duration-300`}>
                    {feature.title}
                  </h3>
                  <p className="text-xs md:text-base text-gray-600 dark:text-gray-300 group-hover:text-gray-800 dark:group-hover:text-white transition-colors">
                    {feature.description}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureHighlights;
