
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Mic, Sparkles } from "lucide-react";
import VoiceNavigation from "./VoiceNavigation/VoiceNavigation";
import { useIsMobile } from "@/hooks/use-mobile";
import { useAuth } from "@/components/AuthProvider";

interface MagicButtonProps {
  compact?: boolean;
}

const MagicButton = ({ compact = false }: MagicButtonProps) => {
  const [showVoiceNav, setShowVoiceNav] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const isMobile = useIsMobile();
  const { user } = useAuth();

  useEffect(() => {
    // Detect iOS devices
    const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    setIsIOS(isIOSDevice);
  }, []);

  // For guest users on mobile, use slightly smaller size
  const isGuestOnMobile = !user && isMobile;
  
  // Scale and padding adjustments for different user states
  const scaleClasses = isIOS && !user && isMobile ? "scale-105" : "";
  
  // Different padding based on user status for mobile
  const paddingClasses = compact
    ? "px-2 py-1" // Compact mode
    : isMobile && user 
      ? "px-3 py-1.5" // Smaller for logged-in mobile users
      : isGuestOnMobile
        ? "px-4 py-2" // Slightly smaller for guest mobile users
        : "px-5 py-2.5"; // Default size
      
  // Adjust text size for non-compact mobile buttons
  const textSizeClass = compact 
    ? "text-xs" 
    : isMobile && user 
      ? "text-xs" 
      : isGuestOnMobile 
        ? "text-xs" 
        : "text-sm";
  
  // Smaller icon for mobile
  const iconSizeClass = compact 
    ? "w-3 h-3" 
    : isMobile 
      ? "w-3.5 h-3.5" 
      : "w-4 h-4";

  return (
    <>
      <div className={compact ? "" : "mt-2 mb-2 flex justify-center"}>
        <motion.button
          onClick={() => setShowVoiceNav(true)}
          className={`relative group overflow-hidden rounded-full ${paddingClasses} bg-gradient-to-r from-[#ff00ff] to-[#00bfff] transform hover:scale-105 transition-all duration-300 ${scaleClasses}`}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.98 }}
        >
          <div className="absolute inset-0 bg-gradient-to-r from-[#ff00ff] to-[#00bfff] opacity-75 group-hover:opacity-100 transition-opacity" />
          <div className="absolute inset-0 backdrop-blur-sm" />
          <div className="absolute inset-0 border border-white/30 rounded-full group-hover:border-white/50 transition-colors" />
          <div className="absolute -inset-[1px] bg-gradient-to-r from-[#ff00ff] to-[#00bfff] rounded-full opacity-30 blur-[1px] group-hover:opacity-40 transition-opacity" />
          <div className="relative flex items-center space-x-1.5">
            {compact ? (
              <>
                <Mic className={`${iconSizeClass} text-white`} />
                <span className={`text-white font-semibold tracking-wide ${textSizeClass}`}>
                  AI
                </span>
                <Sparkles className={`${iconSizeClass} text-white`} />
              </>
            ) : (
              <>
                <span className={`text-white font-semibold tracking-wide ${textSizeClass}`}>
                  Tell an AI what you want
                </span>
                <div className="relative ml-0.5">
                  <Mic className={`${iconSizeClass} text-white`} />
                </div>
              </>
            )}
          </div>
        </motion.button>
      </div>

      <VoiceNavigation 
        open={showVoiceNav}
        onOpenChange={setShowVoiceNav}
      />
    </>
  );
};

export default MagicButton;
