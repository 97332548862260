import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { 
  ArrowLeft, Calendar, Award, MapPin, Clock, Phone, Mail, Send, 
  Edit, Check, X, Pencil, Save, Upload, Plus, Minus 
} from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { CoachInquiryForm } from "@/components/coaches/CoachInquiryForm";
import BackButton from "@/components/ui/back-button";
import { useAuth } from "@/components/AuthProvider";
import { toast } from "sonner";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const sportCategories = [
  "Cricket",
  "Football",
  "Basketball",
  "Tennis",
  "Badminton",
  "Pickleball",
  "Swimming",
  "Gym",
  "Yoga",
  "Table Tennis",
  "Squash",
  "Chess",
  "Carrom",
  "Boxing",
  "Martial Arts",
  "Athletics"
];

const popularCities = [
  "Gurgaon",
  "Mumbai",
  "Delhi",
  "Bangalore",
  "Hyderabad",
  "Chennai",
  "Kolkata",
  "Pune",
  "Ahmedabad",
  "Jaipur",
  "Lucknow",
  "Chandigarh",
  "Indore",
];

type Coach = {
  id: string;
  specialization: string[];
  experience_years: number;
  hourly_rate: number;
  bio: string | null;
  certifications: string[] | null;
  city: string | null;
  phone: string | null;
  coaching_locations: string[] | null;
  profile_image: string | null;
  profile_id: string;
  profile: {
    full_name: string | null;
    avatar_url: string | null;
    email: string | null;
  } | null;
};

const CoachDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showInquiryForm, setShowInquiryForm] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editedCoach, setEditedCoach] = useState<Coach | null>(null);
  const [specializations, setSpecializations] = useState<string[]>([]);
  const [certifications, setCertifications] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { data: coach, isLoading, refetch } = useQuery({
    queryKey: ["coach", id],
    queryFn: async () => {
      console.log("Fetching coach with ID:", id);
      const { data, error } = await supabase
        .from("coaches")
        .select(`
          id,
          specialization,
          experience_years,
          hourly_rate,
          bio,
          certifications,
          city,
          phone,
          coaching_locations,
          profile_image,
          profile_id,
          profile:profiles(full_name, avatar_url, email)
        `)
        .eq("id", id)
        .single();

      if (error) {
        console.error("Error fetching coach:", error);
        throw error;
      }

      console.log("Coach data fetched:", data);

      if (user && data.profile_id === user.id) {
        console.log("This is user's own coach profile");
        setIsOwnProfile(true);
      }

      const fallbackCoachImages: Record<string, string> = {
        'Raghav Kapur': '/lovable-uploads/43af30a5-f215-450d-b49a-2833b8e2a254.png',
        'Kaptaan Varma': '/lovable-uploads/d8557430-9463-43e7-b1d4-0e18f4a2b9da.png',
        'Sukhveer Singh': '/lovable-uploads/3bf8a645-44fe-47a5-a7ce-105b04e83ee2.png'
      };

      const coachName = data.bio?.includes('Meghalaya') ? 'Raghav Kapur' :
                      data.bio?.includes('side-arm') ? 'Kaptaan Varma' :
                      data.bio?.includes('bowling') ? 'Sukhveer Singh' : 
                      data.profile?.full_name;

      const avatarUrl = data.profile_image || 
                       data.profile?.avatar_url || 
                       (coachName ? fallbackCoachImages[coachName] : null);

      const enhancedData = {
        ...data,
        profile: {
          ...data.profile,
          full_name: coachName,
          avatar_url: avatarUrl
        }
      } as Coach;

      // Initialize edit states
      setEditedCoach(JSON.parse(JSON.stringify(enhancedData)));
      setSpecializations(enhancedData.specialization?.length ? [...enhancedData.specialization] : [""]);
      setCertifications(enhancedData.certifications?.length ? [...enhancedData.certifications] : [""]);
      setLocations(enhancedData.coaching_locations?.length ? [...enhancedData.coaching_locations] : [""]);

      return enhancedData;
    },
  });

  const uploadImage = async (file: File): Promise<string | null> => {
    try {
      console.log("Starting image upload for file:", file.name);
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random().toString(36).substring(2, 15)}.${fileExt}`;
      const filePath = `coach-profiles/${fileName}`;
      
      const { error: uploadError, data } = await supabase.storage
        .from('profiles')
        .upload(filePath, file);
      
      if (uploadError) {
        console.error("Error uploading image:", uploadError);
        throw uploadError;
      }
      
      console.log("Image uploaded successfully:", data);
      
      const { data: urlData } = supabase.storage
        .from('profiles')
        .getPublicUrl(filePath);
      
      console.log("Public URL retrieved:", urlData);
      return urlData.publicUrl;
    } catch (error) {
      console.error("Error in uploadImage function:", error);
      return null;
    }
  };

  const updateCoachMutation = useMutation({
    mutationFn: async (updatedCoach: Partial<Coach>) => {
      console.log("Updating coach with data:", updatedCoach);
      toast.info("Saving changes...");
      
      // Handle image upload if there's a new profile image
      let profileImageUrl = coach?.profile_image;
      
      if (profileImage) {
        console.log("Uploading profile image:", profileImage.name);
        toast.info("Uploading profile image...");
        const newImageUrl = await uploadImage(profileImage);
        if (newImageUrl) {
          profileImageUrl = newImageUrl;
          updatedCoach.profile_image = newImageUrl;
          console.log("New profile image URL:", profileImageUrl);
        } else {
          console.error("Failed to upload profile image");
          toast.error("Failed to upload profile image, keeping existing image");
        }
      }

      // Prepare filtered arrays from the form state
      const filteredSpecializations = specializations.filter(spec => spec.trim() !== "");
      const filteredCertifications = certifications.filter(cert => cert.trim() !== "");
      const filteredLocations = locations.filter(loc => loc.trim() !== "");

      if (filteredSpecializations.length === 0) {
        const error = new Error("Please add at least one specialization");
        console.error(error);
        toast.error(error.message);
        throw error;
      }

      // Add the filtered arrays to the update payload
      const finalUpdatePayload = {
        ...updatedCoach,
        specialization: filteredSpecializations,
        certifications: filteredCertifications,
        coaching_locations: filteredLocations,
      };
      
      console.log("Final update payload:", finalUpdatePayload);
      
      // Log the coach ID to ensure we're updating the right record
      console.log("Updating coach with ID:", coach?.id);
      
      const { data, error } = await supabase
        .from('coaches')
        .update(finalUpdatePayload)
        .eq('id', coach?.id)
        .select();

      if (error) {
        console.error("Supabase error updating coach:", error);
        toast.error(`Database error: ${error.message}`);
        throw error;
      }

      if (!data || data.length === 0) {
        console.error("No data returned from update operation");
        toast.error("Update may have failed - no records updated");
        throw new Error("No data returned from update");
      }

      console.log("Update successful, response:", data);
      toast.success("Profile updated successfully!");
      return data;
    },
    onSuccess: () => {
      console.log("Profile update successful, refreshing data");
      // Force a refetch to get the latest data
      refetch();
      setIsEditing(false);
      setIsSaving(false);
    },
    onError: (error) => {
      console.error("Error in update mutation:", error);
      toast.error(`Failed to update profile: ${error instanceof Error ? error.message : 'Unknown error'}`);
      setIsSaving(false);
    }
  });

  const handleEditProfile = () => {
    if (isEditing) {
      // Save changes
      setIsSaving(true);
      console.log("Saving coach profile changes...");
      
      if (!editedCoach) {
        console.error("No coach data to save");
        toast.error("No coach data to save");
        setIsSaving(false);
        return;
      }

      // Ensure we have the coach ID
      if (!coach?.id) {
        console.error("Missing coach ID for update operation");
        toast.error("Cannot save - missing coach ID");
        setIsSaving(false);
        return;
      }

      // We now handle specializations, certifications, and locations directly in the mutation
      updateCoachMutation.mutate({
        bio: editedCoach.bio,
        experience_years: editedCoach.experience_years,
        hourly_rate: editedCoach.hourly_rate,
        city: editedCoach.city,
        phone: editedCoach.phone
      });
    } else {
      // Enter edit mode
      setIsEditing(true);
    }
  };

  const handleCancelEdit = () => {
    // Reset to original values
    if (coach) {
      setEditedCoach(JSON.parse(JSON.stringify(coach)));
      setSpecializations(coach.specialization?.length ? [...coach.specialization] : [""]);
      setCertifications(coach.certifications?.length ? [...coach.certifications] : [""]);
      setLocations(coach.coaching_locations?.length ? [...coach.coaching_locations] : [""]);
    }
    setProfileImage(null);
    setIsEditing(false);
  };

  const handleInputChange = (field: keyof Coach, value: any) => {
    if (editedCoach) {
      setEditedCoach({
        ...editedCoach,
        [field]: value
      });
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setProfileImage(file);
      
      // Create a preview
      const reader = new FileReader();
      reader.onloadend = () => {
        if (editedCoach) {
          setEditedCoach({
            ...editedCoach,
            profile: {
              ...editedCoach.profile!,
              avatar_url: reader.result as string
            }
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };
  
  const triggerFileInput = () => {
    if (isEditing) {
      fileInputRef.current?.click();
    }
  };

  const handleSpecializationChange = (index: number, value: string) => {
    const newSpecializations = [...specializations];
    newSpecializations[index] = value;
    setSpecializations(newSpecializations);
  };

  const handleCertificationChange = (index: number, value: string) => {
    const newCertifications = [...certifications];
    newCertifications[index] = value;
    setCertifications(newCertifications);
  };

  const handleLocationChange = (index: number, value: string) => {
    const newLocations = [...locations];
    newLocations[index] = value;
    setLocations(newLocations);
  };

  const addSpecialization = () => {
    setSpecializations([...specializations, ""]);
  };

  const removeSpecialization = (index: number) => {
    if (specializations.length > 1) {
      const newSpecializations = [...specializations];
      newSpecializations.splice(index, 1);
      setSpecializations(newSpecializations);
    }
  };

  const addCertification = () => {
    setCertifications([...certifications, ""]);
  };

  const removeCertification = (index: number) => {
    if (certifications.length > 1) {
      const newCertifications = [...certifications];
      newCertifications.splice(index, 1);
      setCertifications(newCertifications);
    }
  };

  const addLocation = () => {
    setLocations([...locations, ""]);
  };

  const removeLocation = (index: number) => {
    if (locations.length > 1) {
      const newLocations = [...locations];
      newLocations.splice(index, 1);
      setLocations(newLocations);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-white pt-32">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="md:col-span-1">
              <Skeleton className="h-[500px] w-full rounded-xl" />
            </div>
            <div className="md:col-span-2 space-y-6">
              <Skeleton className="h-12 w-3/4" />
              <Skeleton className="h-6 w-1/3" />
              <Skeleton className="h-32 w-full" />
              <Skeleton className="h-8 w-full" />
              <Skeleton className="h-8 w-full" />
              <Skeleton className="h-10 w-48" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!coach) {
    return (
      <div className="min-h-screen bg-white pt-32 flex items-center justify-center">
        <div className="text-center space-y-4">
          <h1 className="text-2xl font-bold text-gray-900">Coach not found</h1>
          <Button onClick={() => navigate("/coaches")}>
            Back to Coaches
          </Button>
        </div>
      </div>
    );
  }

  const renderCoachProfile = () => {
    const displayCoach = isEditing ? editedCoach : coach;
    
    if (!displayCoach) return null;

    if (isMobile) {
      return (
        <div className="min-h-screen bg-white">
          <div className="h-[40vh] bg-gradient-to-r from-purple-600 to-blue-500 relative overflow-hidden">
            <div className="absolute inset-0 bg-black/30 z-10" />
            <img 
              src={isEditing ? editedCoach?.profile?.avatar_url : coach?.profile?.avatar_url} 
              alt={coach?.profile?.full_name || "Coach"} 
              className="w-full h-full object-cover object-[center_25%]" 
            />
            {isEditing && (
              <div 
                className="absolute inset-0 flex items-center justify-center z-20 bg-black/40 cursor-pointer"
                onClick={triggerFileInput}
              >
                <Upload className="h-10 w-10 text-white" />
                <input 
                  type="file" 
                  ref={fileInputRef}
                  onChange={handleImageChange}
                  accept="image/*"
                  className="hidden"
                />
              </div>
            )}
            <div className="absolute top-0 left-0 w-full p-4 z-20 pt-16 md:pt-24">
              <BackButton className="text-white hover:text-white/90" />
            </div>
          </div>

          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 -mt-20 relative z-20 pb-16">
            <div className="bg-white rounded-xl shadow-xl p-8">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-12 gap-y-8">
                <div className="md:col-span-2 space-y-8">
                  <div>
                    <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-2">
                      {displayCoach?.profile?.full_name}
                    </h1>
                    <div className="flex flex-wrap gap-2 mb-4">
                      {isEditing ? (
                        <div className="w-full space-y-4">
                          {specializations.map((specialization, index) => (
                            <div key={index} className="flex gap-2">
                              <Select
                                value={specialization}
                                onValueChange={(value) => handleSpecializationChange(index, value)}
                              >
                                <SelectTrigger className="w-full">
                                  <SelectValue placeholder="Select a sport" />
                                </SelectTrigger>
                                <SelectContent>
                                  {sportCategories.map((sport) => (
                                    <SelectItem key={sport} value={sport}>
                                      {sport}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                              
                              <Button
                                type="button"
                                variant="outline"
                                size="icon"
                                onClick={() => removeSpecialization(index)}
                                disabled={specializations.length <= 1}
                              >
                                <Minus className="h-4 w-4" />
                              </Button>
                            </div>
                          ))}
                          
                          <Button
                            type="button"
                            variant="outline"
                            size="sm"
                            onClick={addSpecialization}
                            className="mt-2"
                          >
                            <Plus className="h-4 w-4 mr-2" />
                            Add Specialization
                          </Button>
                        </div>
                      ) : (
                        displayCoach?.specialization.map((sport) => (
                          <span key={sport} className="px-3 py-1 bg-purple-100 text-purple-800 rounded-full text-sm font-medium">
                            {sport}
                          </span>
                        ))
                      )}
                    </div>
                    {isEditing ? (
                      <Textarea
                        value={editedCoach?.bio || ""}
                        onChange={(e) => handleInputChange("bio", e.target.value)}
                        placeholder="Enter your bio"
                        className="w-full h-40"
                      />
                    ) : (
                      <p className="text-lg text-gray-600 leading-relaxed">{displayCoach?.bio}</p>
                    )}
                  </div>

                  <div className="space-y-4 divide-y divide-gray-100">
                    <div className="pt-4">
                      <h2 className="text-xl font-semibold text-gray-900 mb-4">Expertise & Achievements</h2>
                      {isEditing ? (
                        <div className="space-y-4">
                          {certifications.map((certification, index) => (
                            <div key={index} className="flex gap-2">
                              <Input
                                value={certification}
                                onChange={(e) => handleCertificationChange(index, e.target.value)}
                                placeholder="e.g., Level 1 Cricket Coach, FIFA Certified"
                              />
                              
                              <Button
                                type="button"
                                variant="outline"
                                size="icon"
                                onClick={() => removeCertification(index)}
                                disabled={certifications.length <= 1}
                              >
                                <Minus className="h-4 w-4" />
                              </Button>
                            </div>
                          ))}
                          
                          <Button
                            type="button"
                            variant="outline"
                            size="sm"
                            onClick={addCertification}
                            className="mt-2"
                          >
                            <Plus className="h-4 w-4 mr-2" />
                            Add Certification
                          </Button>
                        </div>
                      ) : (
                        displayCoach.certifications && displayCoach.certifications.length > 0 && (
                          <div className="space-y-2">
                            {displayCoach.certifications.map((cert, index) => (
                              <div key={index} className="flex items-center gap-2">
                                <Award className="w-5 h-5 text-amber-500 flex-shrink-0" />
                                <span className="text-gray-700">{cert}</span>
                              </div>
                            ))}
                          </div>
                        )
                      )}
                    </div>

                    <div className="pt-4">
                      <h2 className="text-xl font-semibold text-gray-900 mb-4">Coaching Locations</h2>
                      {isEditing ? (
                        <div className="space-y-4">
                          {locations.map((location, index) => (
                            <div key={index} className="flex gap-2">
                              <Input
                                value={location}
                                onChange={(e) => handleLocationChange(index, e.target.value)}
                                placeholder="e.g., DLF Cricket Academy, Sector 53"
                              />
                              
                              <Button
                                type="button"
                                variant="outline"
                                size="icon"
                                onClick={() => removeLocation(index)}
                                disabled={locations.length <= 1}
                              >
                                <Minus className="h-4 w-4" />
                              </Button>
                            </div>
                          ))}
                          
                          <Button
                            type="button"
                            variant="outline"
                            size="sm"
                            onClick={addLocation}
                            className="mt-2"
                          >
                            <Plus className="h-4 w-4 mr-2" />
                            Add Location
                          </Button>
                        </div>
                      ) : (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                          {displayCoach.coaching_locations && displayCoach.coaching_locations.map((location, index) => (
                            <div key={index} className="flex items-center gap-2 bg-gray-50 p-3 rounded-lg">
                              <MapPin className="w-5 h-5 text-blue-500 flex-shrink-0" />
                              <span className="text-gray-700">{location}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="md:col-span-1">
                  <div className="bg-gray-50 rounded-xl p-6 shadow-sm space-y-6 sticky top-24">
                    <div className="text-center mb-6">
                      {isEditing ? (
                        <div className="space-y-4">
                          <div className="flex items-center">
                            <span className="text-lg mr-2">₹</span>
                            <Input
                              type="number"
                              value={editedCoach?.hourly_rate || 0}
                              onChange={(e) => handleInputChange("hourly_rate", Number(e.target.value))}
                              className="text-center text-2xl font-bold"
                            />
                            <span className="ml-2">/hour</span>
                          </div>
                          <div className="flex items-center">
                            <Input
                              type="number"
                              value={editedCoach?.experience_years || 0}
                              onChange={(e) => handleInputChange("experience_years", Number(e.target.value))}
                              className="text-center"
                            />
                            <span className="ml-2">years of experience</span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="text-3xl font-bold text-gray-900">₹{displayCoach?.hourly_rate}/hour</div>
                          <div className="text-gray-500">{displayCoach?.experience_years} years of experience</div>
                        </>
                      )}
                    </div>

                    <div className="space-y-4">
                      <div className="flex items-center gap-3">
                        <MapPin className="w-5 h-5 text-gray-500" />
                        {isEditing ? (
                          <Select 
                            value={editedCoach?.city || "Gurgaon"} 
                            onValueChange={(value) => handleInputChange("city", value)}
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Select city" />
                            </SelectTrigger>
                            <SelectContent>
                              {popularCities.map((city) => (
                                <SelectItem key={city} value={city}>
                                  {city}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        ) : (
                          <span className="text-gray-600">Based in {displayCoach?.city}</span>
                        )}
                      </div>
                      
                      {isEditing ? (
                        <div className="flex items-center gap-3">
                          <Phone className="w-5 h-5 text-gray-500" />
                          <Input
                            value={editedCoach?.phone || ""}
                            onChange={(e) => handleInputChange("phone", e.target.value)}
                            placeholder="Phone number"
                          />
                        </div>
                      ) : (
                        <div className="flex items-center gap-3">
                          <Clock className="w-5 h-5 text-gray-500" />
                          <span className="text-gray-600">Flexible coaching hours</span>
                        </div>
                      )}
                    </div>

                    <div className="pt-6">
                      {isOwnProfile ? (
                        isEditing ? (
                          <div className="flex gap-2">
                            <Button 
                              className="flex-1 bg-gradient-to-r from-green-600 to-teal-600 hover:from-green-700 hover:to-teal-700 text-white"
                              onClick={handleEditProfile}
                              disabled={isSaving}
                            >
                              {isSaving ? (
                                <>Saving...</>
                              ) : (
                                <>
                                  <Save className="w-4 h-4 mr-2" />
                                  Save Changes
                                </>
                              )}
                            </Button>
                            <Button 
                              variant="outline"
                              onClick={handleCancelEdit}
                              disabled={isSaving}
                            >
                              <X className="w-4 h-4 mr-2" />
                              Cancel
                            </Button>
                          </div>
                        ) : (
                          <Button 
                            className="w-full bg-gradient-to-r from-green-600 to-teal-600 hover:from-green-700 hover:to-teal-700 text-white font-medium py-3 rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
                            onClick={handleEditProfile}
                          >
                            <Edit className="w-4 h-4 mr-2" />
                            Edit Your Profile
                          </Button>
                        )
                      ) : (
                        <Button 
                          className="w-full bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white font-medium py-3 rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
                          onClick={() => setShowInquiryForm(true)}
                        >
                          <Send className="w-4 h-4 mr-2" />
                          Make an Inquiry
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="min-h-screen bg-white">
        <div className="bg-gradient-to-r from-indigo-700 to-purple-700 py-6">
          <div className="max-w-7xl mx-auto px-6 flex items-center">
            <BackButton className="text-white hover:text-white/90 mr-4" />
            <h1 className="text-white text-2xl font-bold">{isOwnProfile ? "Your Coach Profile" : "Coach Profile"}</h1>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-6 py-12">
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
            <div className="grid grid-cols-12 gap-0">
              <div className="col-span-4 bg-gradient-to-br from-indigo-50 to-purple-50">
                <div className="h-full relative overflow-hidden group">
                  <img 
                    src={isEditing ? editedCoach?.profile?.avatar_url : coach?.profile?.avatar_url} 
                    alt={coach?.profile?.full_name || "Coach"} 
                    className="w-full h-full object-cover object-[center_25%] transition-transform duration-500 group-hover:scale-105" 
                  />
                  {isEditing && (
                    <div 
                      className="absolute inset-0 bg-black/60 flex items-center justify-center cursor-pointer"
                      onClick={triggerFileInput}
                    >
                      <div className="text-center text-white">
                        <Upload className="h-12 w-12 mx-auto mb-2" />
                        <p className="text-lg font-medium">Change Profile Image</p>
                        <p className="text-sm opacity-80">Click to upload</p>
                      </div>
                      <input 
                        type="file" 
                        ref={fileInputRef}
                        onChange={handleImageChange}
                        accept="image/*"
                        className="hidden"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="col-span-8 p-10">
                <div className="flex flex-col h-full">
                  <div className="mb-8">
                    <div className="flex justify-between items-start">
                      <div>
                        <h1 className="text-4xl font-bold text-gray-900 mb-2 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
                          {displayCoach?.profile?.full_name}
                        </h1>
                        <div className="flex flex-wrap gap-2 mb-4">
                          {isEditing ? (
                            <div className="w-full space-y-4">
                              {specializations.map((specialization, index) => (
                                <div key={index} className="flex gap-2">
                                  <Select
                                    value={specialization}
                                    onValueChange={(value) => handleSpecializationChange(index, value)}
                                  >
                                    <SelectTrigger className="w-full">
                                      <SelectValue placeholder="Select a sport" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      {sportCategories.map((sport) => (
                                        <SelectItem key={sport} value={sport}>
                                          {sport}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                  
                                  <Button
                                    type="button"
                                    variant="outline"
                                    size="icon"
                                    onClick={() => removeSpecialization(index)}
                                    disabled={specializations.length <= 1}
                                  >
                                    <Minus className="h-4 w-4" />
                                  </Button>
                                </div>
                              ))}
                              
                              <Button
                                type="button"
                                variant="outline"
                                size="sm"
                                onClick={addSpecialization}
                                className="mt-2"
                              >
                                <Plus className="h-4 w-4 mr-2" />
                                Add Specialization
                              </Button>
                            </div>
                          ) : (
                            displayCoach?.specialization.map((sport) => (
                              <span key={sport} className="px-3 py-1 bg-indigo-100 text-indigo-800 rounded-full text-sm font-medium">
                                {sport}
                              </span>
                            ))
                          )}
                        </div>
                      </div>
                      <div className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-5 py-3 rounded-lg shadow-md">
                        {isEditing ? (
                          <div className="flex items-center gap-2">
                            <span className="text-xl">₹</span>
                            <Input
                              type="number"
                              value={editedCoach?.hourly_rate || 0}
                              onChange={(e) => handleInputChange("hourly_rate", Number(e.target.value))}
                              className="w-20 text-center text-white bg-transparent border-white"
                            />
                            <div className="text-sm opacity-90">/hr</div>
                          </div>
                        ) : (
                          <>
                            <div className="text-2xl font-bold">₹{displayCoach?.hourly_rate}</div>
                            <div className="text-sm opacity-90">per hour</div>
                          </>
                        )}
                      </div>
                    </div>
                    
                    <div className="mt-6 flex gap-4 flex-wrap text-gray-600">
                      {isEditing ? (
                        <div className="flex items-center gap-2 bg-gray-50 px-4 py-2 rounded-lg">
                          <Award className="w-5 h-5 text-indigo-500" />
                          <Input
                            type="number"
                            value={editedCoach?.experience_years || 0}
                            onChange={(e) => handleInputChange("experience_years", Number(e.target.value))}
                            className="w-16 text-center"
                          />
                          <span>years experience</span>
                        </div>
                      ) : (
                        <div className="flex items-center gap-2 bg-gray-50 px-4 py-2 rounded-lg">
                          <Award className="w-5 h-5 text-indigo-500" />
                          <span>{displayCoach?.experience_years} years experience</span>
                        </div>
                      )}
                      
                      <div className="flex items-center gap-2 bg-gray-50 px-4 py-2 rounded-lg">
                        <MapPin className="w-5 h-5 text-indigo-500" />
                        {isEditing ? (
                          <Select 
                            value={editedCoach?.city || "Gurgaon"} 
                            onValueChange={(value) => handleInputChange("city", value)}
                          >
                            <SelectTrigger className="h-8 min-w-[140px]">
                              <SelectValue placeholder="Select city" />
                            </SelectTrigger>
                            <SelectContent>
                              {popularCities.map((city) => (
                                <SelectItem key={city} value={city}>
                                  {city}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        ) : (
                          <span>Based in {displayCoach?.city}</span>
                        )}
                      </div>
                      
                      {isEditing ? (
                        <div className="flex items-center gap-2 bg-gray-50 px-4 py-2 rounded-lg">
                          <Phone className="w-5 h-5 text-indigo-500" />
                          <Input
                            value={editedCoach?.phone || ""}
                            onChange={(e) => handleInputChange("phone", e.target.value)}
                            placeholder="Phone number"
                            className="w-36"
                          />
                        </div>
                      ) : (
                        <div className="flex items-center gap-2 bg-gray-50 px-4 py-2 rounded-lg">
                          <Clock className="w-5 h-5 text-indigo-500" />
                          <span>Flexible hours</span>
                        </div>
                      )}
                    </div>
                    
                    <div className="mt-8">
                      {isEditing ? (
                        <Textarea
                          value={editedCoach?.bio || ""}
                          onChange={(e) => handleInputChange("bio", e.target.value)}
                          placeholder="Tell clients about your coaching experience, philosophy, and achievements..."
                          className="w-full h-32"
                        />
                      ) : (
                        <p className="text-lg text-gray-700 leading-relaxed">{displayCoach?.bio}</p>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-10 mt-6">
                    <div className="space-y-6">
                      <div>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                          <Award className="w-5 h-5 text-amber-500 mr-2" />
                          Expertise & Achievements
                        </h2>
                        {isEditing ? (
                          <div className="space-y-4">
                            {certifications.map((certification, index) => (
                              <div key={index} className="flex gap-2">
                                <Input
                                  value={certification}
                                  onChange={(e) => handleCertificationChange(index, e.target.value)}
                                  placeholder="e.g., Level 1 Cricket Coach, FIFA Certified"
                                />
                                
                                <Button
                                  type="button"
                                  variant="outline"
                                  size="icon"
                                  onClick={() => removeCertification(index)}
                                  disabled={certifications.length <= 1}
                                >
                                  <Minus className="h-4 w-4" />
                                </Button>
                              </div>
                            ))}
                            
                            <Button
                              type="button"
                              variant="outline"
                              size="sm"
                              onClick={addCertification}
                              className="mt-2"
                            >
                              <Plus className="h-4 w-4 mr-2" />
                              Add Certification
                            </Button>
                          </div>
                        ) : (
                          displayCoach.certifications && displayCoach.certifications.length > 0 && (
                            <div className="space-y-3 bg-amber-50 p-4 rounded-xl">
                              {displayCoach.certifications.map((cert, index) => (
                                <div key={index} className="flex items-center gap-2">
                                  <div className="w-2 h-2 rounded-full bg-amber-500"></div>
                                  <span className="text-gray-800">{cert}</span>
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </div>
                    </div>

                    <div className="space-y-6">
                      <div>
                        <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
                          <MapPin className="w-5 h-5 text-blue-500 mr-2" />
                          Coaching Locations
                        </h2>
                        {isEditing ? (
                          <div className="space-y-4">
                            {locations.map((location, index) => (
                              <div key={index} className="flex gap-2">
                                <Input
                                  value={location}
                                  onChange={(e) => handleLocationChange(index, e.target.value)}
                                  placeholder="e.g., DLF Cricket Academy, Sector 53"
                                />
                                
                                <Button
                                  type="button"
                                  variant="outline"
                                  size="icon"
                                  onClick={() => removeLocation(index)}
                                  disabled={locations.length <= 1}
                                >
                                  <Minus className="h-4 w-4" />
                                </Button>
                              </div>
                            ))}
                            
                            <Button
                              type="button"
                              variant="outline"
                              size="sm"
                              onClick={addLocation}
                              className="mt-2"
                            >
                              <Plus className="h-4 w-4 mr-2" />
                              Add Location
                            </Button>
                          </div>
                        ) : (
                          <div className="grid grid-cols-1 gap-3 bg-blue-50 p-4 rounded-xl">
                            {displayCoach.coaching_locations && displayCoach.coaching_locations.map((location, index) => (
                              <div key={index} className="flex items-center gap-2 bg-white p-3 rounded-lg shadow-sm">
                                <div className="w-2 h-2 rounded-full bg-blue-500"></div>
                                <span className="text-gray-800">{location}</span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-auto pt-10">
                    {isOwnProfile ? (
                      isEditing ? (
                        <div className="flex gap-3">
                          <Button 
                            className="flex-1 bg-gradient-to-r from-green-600 to-teal-600 hover:from-green-700 hover:to-teal-700 text-white font-medium py-4 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 text-lg"
                            onClick={handleEditProfile}
                            disabled={isSaving}
                          >
                            {isSaving ? (
                              <>Saving Changes...</>
                            ) : (
                              <>
                                <Save className="w-5 h-5 mr-2" />
                                Save Changes
                              </>
                            )}
                          </Button>
                          <Button 
                            variant="outline"
                            className="py-4 rounded-lg text-lg"
                            onClick={handleCancelEdit}
                            disabled={isSaving}
                          >
                            <X className="w-5 h-5 mr-2" />
                            Cancel
                          </Button>
                        </div>
                      ) : (
                        <Button 
                          className="w-full bg-gradient-to-r from-green-600 to-teal-600 hover:from-green-700 hover:to-teal-700 text-white font-medium py-4 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 text-lg"
                          onClick={handleEditProfile}
                        >
                          <Edit className="w-5 h-5 mr-2" />
                          Edit Your Profile
                        </Button>
                      )
                    ) : (
                      <Button 
                        className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 text-white font-medium py-4 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 text-lg"
                        onClick={() => setShowInquiryForm(true)}
                      >
                        <Send className="w-5 h-5 mr-2" />
                        Make an Inquiry
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderCoachProfile()}

      {showInquiryForm && coach && (
        <CoachInquiryForm 
          coach={coach} 
          isOpen={showInquiryForm} 
          onClose={() => setShowInquiryForm(false)} 
        />
      )}
    </>
  );
};

export default CoachDetail;

