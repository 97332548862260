
import { useState, useEffect } from "react";
import { Chess, Square } from "chess.js";
import { Piece } from "./Piece";
import { Button } from "@/components/ui/button";
import { RefreshCw, AlertCircle, Crown } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { motion } from "framer-motion";
import { toast } from "@/components/ui/use-toast";
import { 
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter
} from "@/components/ui/dialog";

export const ChessBoard = () => {
  const [game, setGame] = useState(new Chess());
  const [board, setBoard] = useState(game.board());
  const [selectedSquare, setSelectedSquare] = useState<Square | null>(null);
  const [possibleMoves, setPossibleMoves] = useState<Square[]>([]);
  const [difficulty, setDifficulty] = useState("easy");
  const [status, setStatus] = useState("");
  const [showGameEndDialog, setShowGameEndDialog] = useState(false);
  const [gameEndMessage, setGameEndMessage] = useState("");
  const [gameEndTitle, setGameEndTitle] = useState("");

  useEffect(() => {
    updateStatus();
  }, [game]);

  const updateStatus = () => {
    let statusTitle = "";
    let statusMessage = "";

    if (game.isGameOver()) {
      if (game.isCheckmate()) {
        statusTitle = "Checkmate!";
        statusMessage = (game.turn() === "w" ? "Black" : "White") + " wins!";
        
        toast({
          title: "Game Over",
          description: "Checkmate! " + (game.turn() === "w" ? "Black" : "White") + " wins!",
        });
        
        setGameEndTitle("Checkmate!");
        setGameEndMessage((game.turn() === "w" ? "Black" : "White") + " wins! The king is in check and has no valid moves.");
        setShowGameEndDialog(true);
      } else if (game.isDraw()) {
        let reason = "";
        if (game.isStalemate()) {
          reason = "Stalemate: The player to move has no valid moves but is not in check.";
        } else if (game.isThreefoldRepetition()) {
          reason = "Threefold Repetition: The same position has occurred three times.";
        } else if (game.isInsufficientMaterial()) {
          reason = "Insufficient Material: Neither player has enough pieces to checkmate.";
        } else {
          reason = "50-move rule: 50 moves have been made without a capture or pawn move.";
        }
        
        statusTitle = "Draw!";
        statusMessage = reason;
        
        toast({
          title: "Game Over",
          description: "The game is a draw!",
        });
        
        setGameEndTitle("Draw!");
        setGameEndMessage(reason);
        setShowGameEndDialog(true);
      }
    } else if (game.isCheck()) {
      statusTitle = "Check!";
      statusMessage = "Your king is under attack!";
      
      toast({
        description: "Check!",
      });
    }
    
    setStatus(statusTitle ? `${statusTitle} ${statusMessage}` : "");
  };

  const resetGame = () => {
    const newGame = new Chess();
    setGame(newGame);
    setBoard(newGame.board());
    setSelectedSquare(null);
    setPossibleMoves([]);
    setShowGameEndDialog(false);
    toast({
      description: "New game started!",
    });
  };

  const getSquareCoordinates = (square: Square) => {
    const file = square.charCodeAt(0) - 97;
    const rank = 8 - parseInt(square[1]);
    return { rank, file };
  };

  const handleSquareClick = (rank: number, file: number) => {
    if (game.isGameOver()) return;
    
    const square = String.fromCharCode(97 + file) + (8 - rank) as Square;
    
    if (selectedSquare === null) {
      const piece = game.get(square);
      if (piece && piece.color === game.turn()) {
        setSelectedSquare(square);
        const moves = game.moves({ square, verbose: true });
        setPossibleMoves(moves.map(move => move.to as Square));
      }
    } else {
      if (possibleMoves.includes(square)) {
        const move = game.move({
          from: selectedSquare,
          to: square,
          promotion: 'q'
        });

        if (move) {
          setBoard(game.board());
          setSelectedSquare(null);
          setPossibleMoves([]);

          // Check if game is over immediately after player's move
          if (game.isGameOver()) {
            updateStatus();
          } else {
            // AI move
            setTimeout(makeAIMove, 500);
          }
        }
      } else {
        setSelectedSquare(null);
        setPossibleMoves([]);
      }
    }
  };

  const makeAIMove = () => {
    if (game.isGameOver()) return;

    const moves = game.moves({ verbose: true });
    if (moves.length === 0) return;

    let bestMove;
    if (difficulty === "hard") {
      // More sophisticated move selection for hard difficulty
      bestMove = moves.reduce((best, current) => {
        const tempGame = new Chess(game.fen());
        tempGame.move(current);
        const score = evaluatePosition(tempGame);
        return score > best.score ? { move: current, score } : best;
      }, { move: moves[0], score: -Infinity }).move;
    } else {
      // Simple random move for easy difficulty
      bestMove = moves[Math.floor(Math.random() * moves.length)];
    }

    game.move(bestMove);
    setBoard(game.board());
    
    // Check if game is over after AI move
    if (game.isGameOver()) {
      updateStatus();
    }
  };

  const evaluatePosition = (tempGame: Chess) => {
    const pieceValues = {
      p: 1,
      n: 3,
      b: 3,
      r: 5,
      q: 9,
      k: 0
    };

    let score = 0;
    const board = tempGame.board();

    for (let rank = 0; rank < 8; rank++) {
      for (let file = 0; file < 8; file++) {
        const piece = board[rank][file];
        if (piece) {
          const value = pieceValues[piece.type as keyof typeof pieceValues];
          score += piece.color === 'b' ? value : -value;
        }
      }
    }

    return score;
  };

  return (
    <div className="flex flex-col items-center max-w-2xl mx-auto">
      <div className="mb-6 flex items-center gap-4">
        <Select value={difficulty} onValueChange={setDifficulty}>
          <SelectTrigger className="w-36 bg-white dark:bg-gray-800">
            <SelectValue placeholder="Difficulty" className="dark:text-white" />
          </SelectTrigger>
          <SelectContent className="bg-white dark:bg-gray-800">
            <SelectItem value="easy" className="dark:text-white">Easy</SelectItem>
            <SelectItem value="hard" className="dark:text-white">Hard</SelectItem>
          </SelectContent>
        </Select>
        <Button onClick={resetGame} variant="outline" className="bg-white dark:bg-gray-800">
          <RefreshCw className="w-4 h-4 mr-2" />
          <span className="dark:text-white text-center">New Game</span>
        </Button>
      </div>

      {status && (
        <div className="mb-4 flex items-center gap-2 text-gray-900 dark:text-white">
          <AlertCircle className="w-4 h-4" />
          <span>{status}</span>
        </div>
      )}

      <div className="rounded-lg overflow-hidden shadow-xl border border-indigo-100 dark:border-gray-700">
        <motion.div 
          className="grid grid-cols-8"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          {board.map((row, rank) => (
            row.map((piece, file) => {
              const square = String.fromCharCode(97 + file) + (8 - rank) as Square;
              const isSelected = selectedSquare === square;
              const isPossibleMove = possibleMoves.includes(square);
              const isLight = (rank + file) % 2 === 0;

              return (
                <motion.div
                  key={`${rank}-${file}`}
                  className={`
                    w-12 h-12 sm:w-16 sm:h-16 flex items-center justify-center relative
                    ${isLight ? 'bg-amber-50' : 'bg-emerald-700'}
                    ${isSelected ? 'ring-2 ring-yellow-400 z-10' : ''}
                    ${isPossibleMove ? 'ring-2 ring-green-400 z-10' : ''}
                    cursor-pointer transition-transform hover:scale-105
                  `}
                  onClick={() => handleSquareClick(rank, file)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {piece && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Piece piece={piece} />
                    </div>
                  )}
                  {isPossibleMove && !piece && (
                    <div className="w-3 h-3 rounded-full bg-green-400 opacity-70" />
                  )}
                </motion.div>
              );
            })
          ))}
        </motion.div>
      </div>

      <div className="mt-6 flex items-center gap-2 text-gray-900 dark:text-white">
        <Crown className="w-5 h-5" />
        <span>Current Turn: {game.turn() === 'w' ? 'White' : 'Black'}</span>
      </div>

      <Dialog open={showGameEndDialog} onOpenChange={setShowGameEndDialog}>
        <DialogContent className="bg-white dark:bg-gray-800">
          <DialogHeader>
            <DialogTitle className="text-xl font-bold text-center">{gameEndTitle}</DialogTitle>
            <DialogDescription className="text-center">
              {gameEndMessage}
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-center py-4">
            {gameEndTitle.includes("Checkmate") && (
              <div className="text-5xl animate-bounce">
                {game.turn() === 'w' ? '♚' : '♔'}
              </div>
            )}
            {gameEndTitle.includes("Draw") && (
              <div className="flex gap-4">
                <span className="text-5xl">♔</span>
                <span className="text-5xl">♚</span>
              </div>
            )}
          </div>
          <DialogFooter className="flex justify-center">
            <Button onClick={resetGame} className="bg-indigo-600 hover:bg-indigo-700">
              <RefreshCw className="w-4 h-4 mr-2" />
              Play Again
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
