import { useState } from "react";
import { useAuth } from "@/components/AuthProvider";
import { Flag, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface ChatMessageProps {
  message: {
    id: string;
    content: string;
    user_id: string;
    is_anonymous: boolean;
    anonymous_username: string | null;
    is_reported: boolean;
    profiles?: {
      username: string | null;
      full_name: string | null;
    };
  };
  onDelete: () => void;
}

export const ChatMessage = ({ message, onDelete }: ChatMessageProps) => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    if (!user || user.id !== message.user_id) return;
    
    setIsDeleting(true);
    try {
      const { error } = await supabase
        .from("chat_messages")
        .delete()
        .eq("id", message.id);

      if (error) throw error;
      onDelete();
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete message",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleReport = async () => {
    try {
      const { error } = await supabase
        .from("chat_messages")
        .update({
          is_reported: true,
          report_reason: "Inappropriate content",
        })
        .eq("id", message.id);

      if (error) throw error;

      toast({
        title: "Message Reported",
        description: "Thank you for helping keep our community safe.",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to report message",
      });
    }
  };

  const displayName = message.is_anonymous
    ? message.anonymous_username || "Anonymous"
    : message.profiles?.full_name || message.profiles?.username || "Unknown";

  return (
    <div className={`flex ${message.user_id === user?.id ? "justify-end" : "justify-start"}`}>
      <div
        className={`max-w-[70%] rounded-lg p-3 ${
          message.user_id === user?.id ? "bg-primary text-white" : "bg-gray-100"
        }`}
      >
        <div className="flex items-center justify-between gap-2 mb-1">
          <p className="text-sm font-semibold">{displayName}</p>
          <div className="flex items-center gap-1">
            {message.user_id === user?.id && (
              <Button
                size="sm"
                variant="ghost"
                onClick={handleDelete}
                disabled={isDeleting}
                className="h-6 w-6 p-0"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            )}
            {message.user_id !== user?.id && !message.is_reported && (
              <Button
                size="sm"
                variant="ghost"
                onClick={handleReport}
                className="h-6 w-6 p-0"
              >
                <Flag className="h-4 w-4" />
              </Button>
            )}
          </div>
        </div>
        <p>{message.content}</p>
      </div>
    </div>
  );
};