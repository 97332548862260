
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";
import { VenueImageUpdater } from "@/components/venues/VenueImageUpdater";

const VenueImageUpdaterPage = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    const checkAdminStatus = () => {
      const adminAuthenticated = localStorage.getItem("adminAuthenticated") === "true";
      setIsAdmin(adminAuthenticated);
      
      if (!adminAuthenticated) {
        navigate("/admin");
      }
    };
    
    checkAdminStatus();
  }, [navigate]);

  if (!isAdmin) {
    return null;
  }

  return (
    <div className="min-h-screen bg-white pt-24 pb-16">
      <div className="max-w-3xl mx-auto px-4">
        <h1 className="text-2xl font-bold mb-6 text-center">Venue Image Management</h1>
        <VenueImageUpdater />
      </div>
    </div>
  );
};

export default VenueImageUpdaterPage;
