
import { Bot, ChevronLeft, Trash2 } from "lucide-react";
import { Button } from "../ui/button";
import { motion } from "framer-motion";

interface ChatHeaderProps {
  category: string;
  onDelete: () => void;
  onBack?: () => void;
}

export const ChatHeader = ({ category, onDelete, onBack }: ChatHeaderProps) => {
  // Capitalize the first letter of the category
  const capitalizedCategory = category.charAt(0).toUpperCase() + category.slice(1);
  
  return (
    <motion.div 
      className="flex items-center justify-between p-4 border-b backdrop-blur-sm bg-white/80"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="flex items-center gap-3">
        {onBack && (
          <Button
            variant="ghost"
            size="icon"
            onClick={onBack}
            className="mr-1 text-gray-600 hover:text-gray-900 hover:bg-gray-100"
          >
            <ChevronLeft className="w-5 h-5" />
          </Button>
        )}
        <div className="w-10 h-10 rounded-full bg-gradient-to-br from-purple-500 to-blue-500 flex items-center justify-center shadow-md">
          <Bot className="w-6 h-6 text-white" />
        </div>
        <div>
          <h2 className="text-lg font-semibold capitalize">{capitalizedCategory} Coach</h2>
          <p className="text-xs text-gray-500">AI-powered personal coaching</p>
        </div>
      </div>
      <Button
        variant="ghost"
        size="sm"
        className="text-red-500 hover:text-red-700 hover:bg-red-50"
        onClick={onDelete}
      >
        <Trash2 className="w-4 h-4" />
      </Button>
    </motion.div>
  );
};
