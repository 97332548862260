
import { Button } from "@/components/ui/button";

interface Message {
  role: "assistant" | "user";
  content: string;
  includeOptions?: boolean;
}

interface Option {
  label: string;
  action: () => void;
}

interface ChatMessageProps {
  message: Message;
  supportOptions?: Option[];
}

export const ChatMessage = ({ message, supportOptions }: ChatMessageProps) => {
  return (
    <div
      className={`flex ${
        message.role === "assistant" ? "justify-start" : "justify-end"
      }`}
    >
      <div
        className={`max-w-[80%] rounded-lg p-4 ${
          message.role === "assistant"
            ? "bg-gradient-to-r from-blue-50 to-purple-50 text-gray-800"
            : "bg-gradient-to-r from-blue-600 to-purple-600 text-white"
        }`}
      >
        <p className="whitespace-pre-wrap">{message.content}</p>
        {message.includeOptions && supportOptions && (
          <div className="mt-4 space-y-2">
            {supportOptions.map((option, index) => (
              <Button
                key={index}
                variant="outline"
                className="w-full justify-start text-left hover:bg-blue-50"
                onClick={option.action}
              >
                {option.label}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
