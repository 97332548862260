
import { format } from "date-fns";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Calendar, Clock, MapPin, IndianRupee, CheckCircle2 } from "lucide-react";

interface BookingConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  venueName: string;
  facilityName: string;
  bookingDate: Date;
  startTimes: string[];
  totalAmount: number;
  // New prop for multiple facilities
  selectedFacilities?: Array<{
    facilityName: string;
    slots: Array<{
      id: string;
      start_time: string;
    }>;
  }>;
}

export const BookingConfirmDialog = ({
  isOpen,
  onClose,
  onConfirm,
  venueName,
  facilityName,
  bookingDate,
  startTimes,
  totalAmount,
  selectedFacilities = []
}: BookingConfirmDialogProps) => {
  // Format the date for display
  const formattedDate = format(bookingDate, "EEEE, MMMM d, yyyy");
  
  // Check if we're using the new multi-facility display or the old single facility display
  const useMultiFacilityDisplay = selectedFacilities && selectedFacilities.length > 0;
  
  // Format times for display for the old method
  const formattedTimes = startTimes.map(time => {
    const date = new Date(time);
    return format(date, "h:mm a");
  }).join(", ");
  
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-md mx-auto rounded-xl overflow-hidden border-0 shadow-2xl bg-gradient-to-br from-white to-blue-50 dark:from-gray-900 dark:to-blue-950">
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute -top-24 -right-24 w-48 h-48 bg-blue-400/20 dark:bg-blue-600/10 rounded-full blur-2xl"></div>
          <div className="absolute -bottom-24 -left-24 w-48 h-48 bg-purple-400/20 dark:bg-purple-600/10 rounded-full blur-2xl"></div>
        </div>
        
        <div className="relative">
          <DialogHeader className="pb-2">
            <div className="flex justify-center mb-2">
              <div className="w-16 h-16 rounded-full bg-gradient-to-r from-blue-400 to-purple-500 flex items-center justify-center shadow-lg">
                <CheckCircle2 className="h-8 w-8 text-white" />
              </div>
            </div>
            <DialogTitle className="text-xl md:text-2xl font-bold text-center bg-gradient-to-r from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400 text-transparent bg-clip-text">
              Confirm Your Booking
            </DialogTitle>
          </DialogHeader>
          
          <div className="space-y-5 my-6 relative">
            <div className="p-4 bg-white/50 dark:bg-gray-800/50 backdrop-blur-sm rounded-xl border border-gray-100 dark:border-gray-700 shadow-sm">
              <div className="space-y-3">
                <div className="flex items-start gap-3">
                  <MapPin className="w-5 h-5 text-purple-500 dark:text-purple-400 mt-0.5 flex-shrink-0" />
                  <div>
                    <p className="text-xs text-gray-500 dark:text-gray-400">Venue</p>
                    <p className="font-medium text-gray-900 dark:text-gray-100">{venueName}</p>
                  </div>
                </div>
                
                <div className="flex items-start gap-3">
                  <Calendar className="w-5 h-5 text-blue-500 dark:text-blue-400 mt-0.5 flex-shrink-0" />
                  <div>
                    <p className="text-xs text-gray-500 dark:text-gray-400">Date</p>
                    <p className="font-medium text-gray-900 dark:text-gray-100">{formattedDate}</p>
                  </div>
                </div>
                
                {useMultiFacilityDisplay ? (
                  // New way: Display multiple facilities with their time slots
                  <div className="flex items-start gap-3">
                    <Clock className="w-5 h-5 text-green-500 dark:text-green-400 mt-0.5 flex-shrink-0" />
                    <div className="w-full">
                      <p className="text-xs text-gray-500 dark:text-gray-400">Facilities & Time Slots</p>
                      {selectedFacilities.map((facility, index) => (
                        <div key={index} className="mt-2 pb-2 border-b border-gray-100 dark:border-gray-700 last:border-0 last:pb-0">
                          <p className="font-medium text-gray-900 dark:text-gray-100">{facility.facilityName}</p>
                          <p className="text-sm text-gray-600 dark:text-gray-300">
                            {facility.slots.map(slot => format(new Date(slot.start_time), "h:mm a")).join(", ")}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  // Old way: Display a single facility
                  <>
                    <div className="flex items-start gap-3">
                      <Clock className="w-5 h-5 text-green-500 dark:text-green-400 mt-0.5 flex-shrink-0" />
                      <div>
                        <p className="text-xs text-gray-500 dark:text-gray-400">Time Slot{startTimes.length > 1 ? 's' : ''}</p>
                        <p className="font-medium text-gray-900 dark:text-gray-100">{formattedTimes}</p>
                        <p className="text-sm text-gray-600 dark:text-gray-300">{facilityName}</p>
                      </div>
                    </div>
                  </>
                )}
                
                <div className="flex items-start gap-3">
                  <IndianRupee className="w-5 h-5 text-amber-500 dark:text-amber-400 mt-0.5 flex-shrink-0" />
                  <div>
                    <p className="text-xs text-gray-500 dark:text-gray-400">Total Amount</p>
                    <p className="font-bold text-gray-900 dark:text-gray-100">₹{totalAmount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <DialogFooter className="flex flex-col sm:flex-row gap-3">
            <Button 
              variant="outline" 
              onClick={onClose}
              className="w-full sm:w-auto border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-800"
            >
              Modify Booking
            </Button>
            <Button 
              onClick={onConfirm}
              className="w-full sm:w-auto bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white shadow-md hover:shadow-lg"
            >
              Proceed to Payment
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
};
