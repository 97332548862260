
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Sheet, SheetContent, SheetTrigger } from "./ui/sheet";
import { useAuth } from "./AuthProvider";
import { useToast } from "./ui/use-toast";
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from "./ThemeProvider";
import { 
  UserCircle, 
  MapPin, 
  Users, 
  Dumbbell,
  Menu,
  Calendar,
  Building2,
  Gamepad2,
  ShoppingBag,
  Heart,
  Users2,
  Trophy,
  FileText,
  UserPlus,
  Handshake,
  HelpCircle,
  Bot,
  Package,
  LogOut,
  Shield,
  UserX,
  Settings2,
  Search,
  Sparkles
} from "lucide-react";

const BottomNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, signOut } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const { toast } = useToast();
  const { theme = 'light' } = useTheme() || {};

  const handleSignOut = async () => {
    try {
      console.log("BottomNav: Signing out user");
      await signOut();
      navigate("/auth");
    } catch (error: any) {
      console.error("Unexpected error during sign out:", error);
      toast({
        variant: "destructive",
        title: "Error signing out",
        description: error.message,
      });
    }
  };

  const mainNavItems = user ? [
    {
      path: '/',
      label: 'Search',
      icon: Search,
      color: "#FF6B6B"
    },
    {
      path: '/my-bookings',
      label: 'Bookings',
      icon: Calendar,
      color: "#4ECDC4"
    },
    {
      path: '/venues',
      label: 'Venues',
      icon: MapPin,
      color: "#45B7D1"
    },
    {
      path: '/coaches',
      label: 'Coaches',
      icon: Users,
      color: "#96CEB4"
    }
  ] : [
    {
      path: '/auth',
      label: 'Login',
      icon: UserCircle,
      color: "#FF6B6B"
    },
    {
      path: '/venues',
      label: 'Venues',
      icon: MapPin,
      color: "#45B7D1"
    },
    {
      path: '/coaches',
      label: 'Coaches',
      icon: Users,
      color: "#4ECDC4"
    },
    {
      path: '/gyms',
      label: 'Gyms',
      icon: Dumbbell,
      color: "#96CEB4"
    }
  ];

  const moreNavItems = [
    ...(user ? [{ path: '/gyms', label: 'Gyms', icon: Dumbbell, color: "#96CEB4" }] : []),
    { path: '/academies', label: 'Academies', icon: Building2, color: "#FF8C42" },
    { path: '/events-and-games', label: 'Events', icon: Calendar, color: "#6C63FF" },
    { path: '/live-feed', label: 'Live Feed', icon: Gamepad2, color: "#4CAF50" },
    { path: '/shop', label: 'Shop', icon: ShoppingBag, color: "#FF4081" },
    { path: '/products', label: 'Products', icon: Package, color: "#9C27B0" },
    { 
      path: '/memberships', 
      label: "Memberships", 
      icon: Heart, 
      color: "#E91E63" 
    },
    { path: '/be-fit', label: 'be.fit', icon: Trophy, color: "#9C27B0" },
    { path: '/communities', label: 'Communities', icon: Users2, color: "#2196F3" },
    { path: '/refund', label: 'Refund Policy', icon: FileText, color: "#607D8B" },
    { path: '/privacy', label: 'Privacy Policy', icon: Shield, color: "#3F51B5" },
    // Only show join platform and partners for non-authenticated users
    ...(user ? [] : [
      { path: '/join-platform', label: 'Join Platform', icon: UserPlus, color: "#795548" },
      { path: '/partners', label: 'Partners', icon: Handshake, color: "#FFC107" },
    ]),
    { path: '/support', label: 'Support', icon: HelpCircle, color: "#00BCD4" },
    { path: '/ai-support', label: 'AI Support', icon: Bot, color: "#6C63FF" },
    { path: '/settings', label: 'Settings', icon: Settings2, color: "#9333EA" },
    ...(user ? [
      { path: '/profile', label: 'Profile', icon: UserCircle, color: "#FF6B6B" },
      {
        path: '/deleteaccount',
        label: 'Delete Account',
        icon: UserX,
        color: "#DC2626"
      },
      {
        label: 'Sign Out',
        icon: LogOut,
        color: "#FF5252",
        onClick: handleSignOut
      }
    ] : [])
  ];

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsOpen(false);
  };

  return (
    <motion.nav 
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 260, damping: 20 }}
      className="fixed bottom-0 left-0 right-0 z-50 bg-white/90 dark:bg-black/90 backdrop-blur-xl border-t border-gray-100 dark:border-gray-800 pb-[calc(env(safe-area-inset-bottom)+0.5rem)] lg:hidden"
      style={{
        boxShadow: '0 -8px 20px -5px rgba(0,0,0,0.05)',
      }}
    >
      <div className="flex justify-between items-center px-3 py-2 max-w-2xl mx-auto">
        {mainNavItems.map((item) => (
          <motion.button
            key={item.path}
            onClick={() => handleNavigation(item.path)}
            whileTap={{ scale: 0.9 }}
            className="flex flex-col items-center space-y-1 flex-1 px-2 pt-1.5 pb-1"
          >
            <motion.div
              initial={{ scale: 0.7 }}
              animate={{ 
                scale: location.pathname === item.path ? 1 : 0.9,
                y: location.pathname === item.path ? -3 : 0
              }}
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
              className="relative"
            >
              <item.icon
                className={`h-6 w-6 transition-all duration-300 ${
                  location.pathname === item.path ? 'opacity-100' : 'opacity-60'
                } dark:text-white`}
                style={{ color: theme === 'dark' ? '#FFFFFF' : item.color }}
              />
            </motion.div>
            <span className={`text-[12px] font-medium transition-colors duration-300 ${
              location.pathname === item.path 
                ? 'text-gray-900 dark:text-white' 
                : 'text-gray-600 dark:text-gray-400'
            }`}>
              {item.label}
            </span>
          </motion.button>
        ))}
        
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
          <SheetTrigger asChild>
            <motion.button 
              whileTap={{ scale: 0.9 }}
              className="flex flex-col items-center space-y-1 flex-1 pt-1.5 pb-1"
            >
              <motion.div
                animate={{ 
                  rotate: isOpen ? 45 : 0,
                  scale: isOpen ? 1.1 : 1
                }}
                transition={{ duration: 0.2 }}
              >
                <Menu className="h-6 w-6 text-gray-600 dark:text-white" />
              </motion.div>
              <span className="text-[12px] font-medium text-gray-600 dark:text-gray-400">More</span>
            </motion.button>
          </SheetTrigger>
          <SheetContent 
            side="bottom" 
            className="px-4 pt-6 pb-12 rounded-t-3xl max-h-[85vh] overflow-y-auto bg-gradient-to-b from-white to-gray-50 dark:from-gray-900 dark:to-gray-950 border-t border-purple-500/20"
            hideCloseButton={true}
          >
            {/* Purple gradient slider */}
            <div className="flex justify-center -mt-2 mb-6">
              <div className="w-12 h-1.5 bg-gradient-to-r from-[#9b87f5] to-[#D946EF] rounded-full shadow-sm">
                <div className="w-full h-full rounded-full bg-gradient-to-b from-white/50 to-transparent opacity-50"></div>
              </div>
            </div>
            
            <div className="absolute top-0 left-0 w-full h-24 overflow-hidden z-0 opacity-20">
              <div className="absolute -top-12 -left-12 w-24 h-24 bg-purple-500 rounded-full blur-3xl" />
              <div className="absolute -top-12 -right-12 w-24 h-24 bg-indigo-500 rounded-full blur-3xl" />
              <div className="absolute top-0 left-1/4 w-24 h-24 bg-fuchsia-500 rounded-full blur-3xl" />
            </div>

            <div className="absolute top-10 right-4">
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.3, type: "spring" }}
              >
                <Sparkles className="h-5 w-5 text-purple-500/70" />
              </motion.div>
            </div>
            
            <nav className="grid grid-cols-3 gap-4 relative z-10">
              <AnimatePresence>
                {moreNavItems.map((item, index) => (
                  <motion.button
                    key={typeof item.label === 'string' ? item.label : `menu-item-${index}`}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ delay: index * 0.03 }}
                    onClick={() => {
                      if (item.onClick) {
                        item.onClick();
                        setIsOpen(false);
                      } else if ('path' in item) {
                        handleNavigation(item.path);
                      }
                    }}
                    className="flex flex-col items-center p-3 rounded-xl hover:bg-white/80 dark:hover:bg-gray-800/50 transition-all duration-200 relative overflow-hidden group"
                    style={{
                      background: theme === 'dark' 
                        ? `linear-gradient(135deg, rgba(${index * 10}, ${index * 5}, ${index * 15}, 0.1), rgba(${index * 5}, ${index * 10}, ${index * 15}, 0.05))`
                        : `linear-gradient(135deg, rgba(${index * 10}, ${index * 5}, ${index * 15}, 0.05), rgba(${index * 5}, ${index * 10}, ${index * 15}, 0.02))`,
                      boxShadow: theme === 'dark'
                        ? '0 4px 8px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.05)'
                        : '0 4px 8px rgba(0, 0, 0, 0.03), inset 0 1px 0 rgba(255, 255, 255, 0.8)'
                    }}
                  >
                    <motion.div
                      whileHover={{ y: -3, scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="w-12 h-12 flex items-center justify-center rounded-full mb-2 relative"
                      style={{ 
                        background: `linear-gradient(135deg, ${item.color}33, ${item.color}11)`,
                      }}
                    >
                      <div className="absolute inset-0 rounded-full bg-gradient-to-br from-white/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
                      <div className="absolute inset-0 rounded-full border border-purple-200/20 opacity-0 group-hover:opacity-100 scale-110 group-hover:scale-100 transition-all"></div>
                      <item.icon 
                        className="h-5 w-5 relative z-10 transition-transform group-hover:scale-110 duration-300" 
                        style={{ color: theme === 'dark' ? '#FFFFFF' : item.color }} 
                      />
                      <div 
                        className="absolute bottom-0 w-full h-1/2 bg-gradient-to-t from-black/10 to-transparent rounded-b-full opacity-0 group-hover:opacity-100 transition-opacity"
                        style={{ backdropFilter: 'blur(4px)' }}
                      />
                    </motion.div>
                    <div className="relative">
                      <span className="text-xs font-medium text-center line-clamp-1 group-hover:text-primary transition-colors duration-300 relative z-10">
                        {item.label}
                      </span>
                      <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-0 h-0.5 bg-gradient-to-r from-transparent via-[#9b87f5]/70 to-transparent group-hover:w-full transition-all duration-300" />
                    </div>
                  </motion.button>
                ))}
              </AnimatePresence>
            </nav>
            
            <div className="mt-8 pt-6 border-t border-gray-100 dark:border-gray-800">
              <div className="text-center text-xs text-gray-500 dark:text-gray-400">
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="bg-gradient-to-r from-purple-500/10 via-indigo-500/10 to-blue-500/10 dark:from-purple-500/20 dark:via-indigo-500/20 dark:to-blue-500/20 px-4 py-3 rounded-xl"
                >
                  <p className="font-medium text-gray-700 dark:text-gray-300">beSportly</p>
                  <p className="text-xs mt-1 opacity-70">India plays here</p>
                  <div className="mt-2 flex justify-center space-x-1.5">
                    <div className="h-1 w-1 rounded-full bg-purple-500/50"></div>
                    <div className="h-1 w-1 rounded-full bg-indigo-500/50"></div>
                    <div className="h-1 w-1 rounded-full bg-blue-500/50"></div>
                  </div>
                </motion.div>
              </div>
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </motion.nav>
  );
};

export default BottomNav;
