interface ComingSoonBannerProps {
  type?: 'venues' | 'coaches' | 'gyms' | 'academies';
}

const ComingSoonBanner = ({ type = 'venues' }: ComingSoonBannerProps) => {
  const getMessage = () => {
    switch (type) {
      case 'coaches':
        return "We're adding new coaches every week. Check back soon to find someone near you!";
      case 'gyms':
        return "We're adding new gyms every week. Check back soon to find something near you!";
      case 'academies':
        return "We're adding new academies every week. Check back soon to find something near you!";
      default:
        return "We're adding new venues every week. Check back soon to find something near you!";
    }
  };

  return (
    <div className="bg-primary/10 p-4 rounded-lg mb-8 text-center">
      <p className="text-primary font-semibold">
        {getMessage()}
      </p>
    </div>
  );
};

export default ComingSoonBanner;