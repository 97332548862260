import { useState } from "react";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import { Utensils } from "lucide-react";

export const MealForm = ({ onMealAdded }: { onMealAdded: () => void }) => {
  const { user } = useAuth();
  const { toast } = useToast();
  const [mealData, setMealData] = useState({
    meal_name: "",
    calories: "",
    meal_type: "breakfast",
    notes: "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      const { error } = await supabase.from("meal_logs").insert([
        {
          user_id: user.id,
          meal_name: mealData.meal_name,
          calories: parseInt(mealData.calories),
          meal_type: mealData.meal_type,
          notes: mealData.notes,
        },
      ]);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Meal logged successfully!",
      });

      setMealData({
        meal_name: "",
        calories: "",
        meal_type: "breakfast",
        notes: "",
      });

      onMealAdded();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to log meal. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex items-center gap-4">
        <Utensils className="w-6 h-6 text-purple-500" />
        <h3 className="text-lg font-semibold">Log a Meal</h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Input
          placeholder="Meal name"
          value={mealData.meal_name}
          onChange={(e) =>
            setMealData({ ...mealData, meal_name: e.target.value })
          }
          required
        />
        <Input
          type="number"
          placeholder="Calories"
          value={mealData.calories}
          onChange={(e) =>
            setMealData({ ...mealData, calories: e.target.value })
          }
          required
        />
        <Select
          value={mealData.meal_type}
          onValueChange={(value) =>
            setMealData({ ...mealData, meal_type: value })
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Select meal type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="breakfast">Breakfast</SelectItem>
            <SelectItem value="lunch">Lunch</SelectItem>
            <SelectItem value="dinner">Dinner</SelectItem>
            <SelectItem value="snack">Snack</SelectItem>
          </SelectContent>
        </Select>
        <Input
          placeholder="Notes (optional)"
          value={mealData.notes}
          onChange={(e) => setMealData({ ...mealData, notes: e.target.value })}
        />
      </div>
      <Button type="submit" className="w-full">
        Log Meal
      </Button>
    </form>
  );
};