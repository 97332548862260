
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Plus, Trophy } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Database } from "@/integrations/supabase/types";
import { useAuth } from "@/components/AuthProvider";

type Competition = Database["public"]["Tables"]["competitions"]["Row"];
type CompetitionSettings = {
  gameType?: string;
  numberOfTeams?: number;
  teams?: string[];
  matches?: Array<{
    team1: string;
    team2: string;
    score1?: number;
    score2?: number;
  }>;
  winner?: string;
  venue?: string;
};

const Competitions = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  const { data: competitions, isLoading: isLoadingCompetitions } = useQuery({
    queryKey: ["competitions", user?.id],
    queryFn: async () => {
      if (!user) throw new Error("User not authenticated");
      
      const { data, error } = await supabase
        .from("competitions")
        .select("*")
        .order("created_at", { ascending: false });

      if (error) throw error;
      return data as Competition[];
    },
    enabled: !!user,
  });

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString();
  };

  const getStatusColor = (status: string | null) => {
    switch (status) {
      case "not_started":
        return "bg-yellow-100 text-yellow-800";
      case "group_stage":
      case "league_stage":
      case "knockout_stage":
        return "bg-blue-100 text-blue-800";
      case "completed":
        return "bg-green-100 text-green-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  if (!user) {
    navigate("/auth");
    return null;
  }

  return (
    <div className="min-h-screen bg-white pt-24 pb-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <Button
            variant="ghost"
            onClick={() => navigate("/events-and-games")}
            className="flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back
          </Button>
          <Button onClick={() => navigate("/create-game")} disabled={isLoading}>
            <Plus className="w-4 h-4 mr-2" />
            New Game
          </Button>
        </div>

        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">My Competitions</h1>
          <p className="text-xl text-gray-600">
            Create and manage your sports competitions
          </p>
        </div>

        {isLoadingCompetitions ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[1, 2, 3].map((i) => (
              <Card key={i} className="p-6 animate-pulse">
                <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
              </Card>
            ))}
          </div>
        ) : competitions?.length === 0 ? (
          <div className="text-center py-12">
            <Trophy className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              No competitions yet
            </h3>
            <p className="text-gray-500">
              Get started by creating your first competition
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {competitions?.map((competition) => {
              const settings = competition.settings as CompetitionSettings;
              return (
                <Card
                  key={competition.id}
                  className="p-6 cursor-pointer hover:shadow-lg transition-shadow duration-200"
                  onClick={() => navigate(`/competitions/${competition.id}`)}
                >
                  <div className="flex items-start justify-between mb-4">
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 mb-1">
                        {competition.name}
                      </h3>
                      <p className="text-sm text-gray-500 capitalize">
                        {competition.type.replace(/_/g, " ")}
                      </p>
                    </div>
                    <span
                      className={`px-2 py-1 rounded-full text-xs font-medium capitalize ${getStatusColor(
                        competition.status
                      )}`}
                    >
                      {(competition.status || "not_started").replace(/_/g, " ")}
                    </span>
                  </div>
                  <div className="text-sm text-gray-600">
                    <p>Starts: {formatDate(competition.start_date)}</p>
                    {competition.end_date && (
                      <p>Ends: {formatDate(competition.end_date)}</p>
                    )}
                    {competition.type === "game" && settings?.winner && (
                      <p className="mt-2 font-medium">
                        Winner: {settings.winner}
                      </p>
                    )}
                  </div>
                </Card>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Competitions;
