import { useState } from "react";
import { useForm } from "react-hook-form";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";

type ProfessionalApplication = {
  full_name: string;
  email: string;
  phone: string;
  profession: "coach" | "trainer" | "nutritionist";
  specializations: string[];
  experience_years: number;
  certifications: string[];
  bio: string;
  availability?: {
    weekdays?: boolean;
    weekends?: boolean;
    mornings?: boolean;
    evenings?: boolean;
  };
  portfolio_links?: string[];
};

const JoinPlatform = () => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm<ProfessionalApplication>();

  const onSubmit = async (data: ProfessionalApplication) => {
    setIsSubmitting(true);
    try {
      const { error } = await supabase
        .from('professional_applications')
        .insert([data]);

      if (error) throw error;

      toast({
        title: "Application Submitted Successfully!",
        description: "We'll review your application and get back to you within 24 hours.",
      });
      reset();
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error submitting your application. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 md:pt-32">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Join Our Platform</h1>
          <p className="text-lg text-gray-600">
            Share your expertise and help people achieve their fitness goals
          </p>
        </div>

        <div className="bg-white dark:bg-gray-900 rounded-2xl shadow-xl p-8">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-black mb-1">
                  Full Name
                </label>
                <input
                  type="text"
                  {...register("full_name", { required: "Full name is required" })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary dark:bg-black dark:text-white"
                />
                {errors.full_name && (
                  <p className="text-red-500 text-sm mt-1">{errors.full_name.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-black mb-1">
                  Email
                </label>
                <input
                  type="email"
                  {...register("email", { required: "Email is required" })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary dark:bg-black dark:text-white"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-black mb-1">
                  Phone
                </label>
                <input
                  type="tel"
                  {...register("phone", { required: "Phone is required" })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary dark:bg-black dark:text-white"
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm mt-1">{errors.phone.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-black mb-1">
                  Profession
                </label>
                <select
                  {...register("profession", { required: "Profession is required" })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary dark:bg-black dark:text-white"
                >
                  <option value="">Select profession</option>
                  <option value="coach">Sports Coach</option>
                  <option value="trainer">Fitness/Personal Trainer</option>
                  <option value="nutritionist">Nutritionist</option>
                </select>
                {errors.profession && (
                  <p className="text-red-500 text-sm mt-1">{errors.profession.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Years of Experience
                </label>
                <input
                  type="number"
                  {...register("experience_years", { required: "Experience is required", min: 0 })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary"
                />
                {errors.experience_years && (
                  <p className="text-red-500 text-sm mt-1">{errors.experience_years.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Specializations (comma-separated)
                </label>
                <input
                  type="text"
                  {...register("specializations", { required: "Specializations are required" })}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary"
                  placeholder="e.g., Yoga, CrossFit, Weight Training"
                />
                {errors.specializations && (
                  <p className="text-red-500 text-sm mt-1">{errors.specializations.message}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Certifications (comma-separated)
              </label>
              <input
                type="text"
                {...register("certifications", { required: "Certifications are required" })}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary"
                placeholder="e.g., ACE, NASM, ISSA"
              />
              {errors.certifications && (
                <p className="text-red-500 text-sm mt-1">{errors.certifications.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Bio
              </label>
              <textarea
                {...register("bio", { required: "Bio is required" })}
                rows={4}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary"
                placeholder="Tell us about yourself and your approach..."
              />
              {errors.bio && (
                <p className="text-red-500 text-sm mt-1">{errors.bio.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Portfolio Links (optional, comma-separated)
              </label>
              <input
                type="text"
                {...register("portfolio_links")}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary"
                placeholder="e.g., LinkedIn, Personal Website, Instagram"
              />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-primary text-white py-3 px-6 rounded-lg hover:bg-primary/90 transition-colors disabled:opacity-50"
            >
              {isSubmitting ? "Submitting..." : "Submit Application"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JoinPlatform;
