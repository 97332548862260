import { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/integrations/supabase/client";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { format } from "date-fns";

interface WeightLog {
  log_date: string;
  weight: number;
}

interface MealLog {
  meal_time: string;
  calories: number;
}

interface ExerciseLog {
  log_date: string;
  calories_burned: number;
}

export const ProgressGraphs = () => {
  const { user } = useAuth();
  const [weightData, setWeightData] = useState<WeightLog[]>([]);
  const [calorieData, setCalorieData] = useState<MealLog[]>([]);
  const [exerciseData, setExerciseData] = useState<ExerciseLog[]>([]);

  useEffect(() => {
    if (!user) return;

    const fetchData = async () => {
      // Fetch weight logs
      const { data: weightLogs } = await supabase
        .from("weight_logs")
        .select("weight, log_date")
        .eq("user_id", user.id)
        .order("log_date", { ascending: true });

      if (weightLogs) {
        setWeightData(weightLogs);
      }

      // Fetch meal logs
      const { data: mealLogs } = await supabase
        .from("meal_logs")
        .select("calories, meal_time")
        .eq("user_id", user.id)
        .order("meal_time", { ascending: true });

      if (mealLogs) {
        setCalorieData(mealLogs);
      }

      // Fetch exercise logs
      const { data: exerciseLogs } = await supabase
        .from("exercise_logs")
        .select("calories_burned, log_date")
        .eq("user_id", user.id)
        .order("log_date", { ascending: true });

      if (exerciseLogs) {
        setExerciseData(exerciseLogs);
      }
    };

    fetchData();
  }, [user]);

  const formattedWeightData = weightData.map((log) => ({
    date: format(new Date(log.log_date), "MMM dd"),
    weight: log.weight,
  }));

  const formattedCalorieData = calorieData.map((log) => ({
    date: format(new Date(log.meal_time), "MMM dd"),
    calories: log.calories,
  }));

  const formattedExerciseData = exerciseData.map((log) => ({
    date: format(new Date(log.log_date), "MMM dd"),
    caloriesBurned: log.calories_burned,
  }));

  // Aggregate calories by date
  const aggregatedCalorieData = formattedCalorieData.reduce((acc, curr) => {
    const existingEntry = acc.find((item) => item.date === curr.date);
    if (existingEntry) {
      existingEntry.calories += curr.calories;
    } else {
      acc.push({ date: curr.date, calories: curr.calories });
    }
    return acc;
  }, [] as { date: string; calories: number }[]);

  // Aggregate exercise calories by date
  const aggregatedExerciseData = formattedExerciseData.reduce((acc, curr) => {
    const existingEntry = acc.find((item) => item.date === curr.date);
    if (existingEntry) {
      existingEntry.caloriesBurned += curr.caloriesBurned;
    } else {
      acc.push({ date: curr.date, caloriesBurned: curr.caloriesBurned });
    }
    return acc;
  }, [] as { date: string; caloriesBurned: number }[]);

  return (
    <div className="space-y-8">
      <div className="h-[300px]">
        <h3 className="text-lg font-semibold mb-4">Weight Progress</h3>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={formattedWeightData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="weight"
              stroke="#8884d8"
              name="Weight (kg)"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="h-[300px]">
        <h3 className="text-lg font-semibold mb-4">Calorie Overview</h3>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              allowDuplicatedCategory={false}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              data={aggregatedCalorieData}
              type="monotone"
              dataKey="calories"
              stroke="#82ca9d"
              name="Calories Consumed"
            />
            <Line
              data={aggregatedExerciseData}
              type="monotone"
              dataKey="caloriesBurned"
              stroke="#ff7300"
              name="Calories Burned"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};