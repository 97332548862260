
import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { ArrowDown, ArrowLeft } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useIsMobile } from "@/hooks/use-mobile";

const Shop = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { error } = await supabase
        .from('shop_waitlist')
        .insert([{ email }]);

      if (error) throw error;

      toast({
        title: "Success!",
        description: "You've been added to our waitlist. We'll notify you when we launch!",
      });
      setEmail("");
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message === "duplicate key value violates unique constraint \"shop_waitlist_email_key\""
          ? "This email is already on our waitlist!"
          : "Something went wrong. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white pt-16 md:pt-24 px-4 sm:px-6 lg:px-8">
      {isMobile && (
        <div className="absolute top-12 left-4 z-10">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Button
              onClick={() => navigate(-1)}
              variant="ghost"
              size="sm"
              className="flex items-center justify-center p-2.5 rounded-full backdrop-blur-sm bg-white/90 hover:bg-white shadow-lg shadow-indigo-100/20 border border-indigo-100/30 transition-all duration-300 hover:scale-105"
              aria-label="Go back"
            >
              <ArrowLeft className="h-5 w-5 text-indigo-600" />
            </Button>
          </motion.div>
        </div>
      )}
      
      <div className="max-w-4xl mx-auto text-center space-y-12">
        <h1 className="text-3xl font-bold text-gray-900 mt-12">
          Our Brand Partner Store is Launching Really Soon
        </h1>
        
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-sm p-8 space-y-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Join the Waitlist
          </h2>
          <p className="text-gray-600">
            Be the first to know when we launch and get exclusive early access.
          </p>
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full"
            />
            <Button 
              type="submit" 
              className="w-full bg-gradient-to-r from-purple-600 to-pink-600 hover:opacity-90"
              disabled={isLoading}
            >
              {isLoading ? "Joining..." : "Join Waitlist"}
            </Button>
          </form>
        </div>

        <div className="flex flex-col items-center space-y-4">
          <p className="text-gray-600">
            Meanwhile, explore products from popular sporting brands
          </p>
          <Link 
            to="/products"
            className="flex items-center space-x-2 text-purple-600 hover:text-purple-700 transition-colors"
          >
            <span className="font-medium">Browse Products</span>
            <ArrowDown className="h-5 w-5 animate-bounce" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Shop;
