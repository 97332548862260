import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { format } from "date-fns";
import { Calendar as CalendarIcon, Upload, ArrowLeft } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/components/AuthProvider";

const baseSchema = {
  name: z.string().min(3, "Name must be at least 3 characters"),
  description: z.string().optional(),
  startDate: z.date({ required_error: "Start date is required" }),
  endDate: z.date({ required_error: "End date is required" }),
  venue: z.string().optional(),
};

const gameSchema = z.object({
  ...baseSchema,
  type: z.literal("game"),
  homeTeam: z.string().min(1, "Home team is required"),
  awayTeam: z.string().min(1, "Away team is required"),
  homeScore: z.string().min(1, "Home score is required"),
  awayScore: z.string().min(1, "Away score is required"),
});

const leagueSchema = z.object({
  ...baseSchema,
  type: z.literal("league"),
  numberOfTeams: z.number().min(2, "At least 2 teams required"),
  teams: z.array(z.string()).min(2, "At least 2 teams required"),
  homeAndAway: z.boolean(),
  pointsForWin: z.number().min(1),
  pointsForDraw: z.number().min(0),
  pointsForLoss: z.number().min(0),
});

const tournamentSchema = z.object({
  ...baseSchema,
  type: z.literal("tournament"),
  numberOfTeams: z.number().min(2).refine(n => n % 2 === 0, "Number of teams must be even"),
  teams: z.array(z.string()),
  matchups: z.array(z.object({
    round: z.number(),
    team1: z.string(),
    team2: z.string(),
  })),
});

const formSchema = z.discriminatedUnion("type", [
  gameSchema,
  leagueSchema,
  tournamentSchema,
]);

type FormValues = z.infer<typeof formSchema>;

const generateInitialMatches = (teams: string[], homeAndAway: boolean) => {
  const matches: any[] = [];
  
  for (let i = 0; i < teams.length; i++) {
    for (let j = i + 1; j < teams.length; j++) {
      matches.push({
        id: `match-${crypto.randomUUID()}`,
        homeTeam: teams[i],
        awayTeam: teams[j],
        homeScore: null,
        awayScore: null,
        round: 1,
      });
    }
  }

  if (homeAndAway) {
    const firstRoundMatches = [...matches];
    firstRoundMatches.forEach(match => {
      matches.push({
        id: `match-${crypto.randomUUID()}`,
        homeTeam: match.awayTeam,
        awayTeam: match.homeTeam,
        homeScore: null,
        awayScore: null,
        round: 2,
      });
    });
  }

  return matches;
};

const CreateCompetition = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type") as "game" | "league" | "tournament" || "game";

  const navigate = useNavigate();
  useEffect(() => {
    if (!searchParams.get("type")) {
      navigate("/competitions");
    }
  }, [searchParams, navigate]);

  const { toast } = useToast();
  const { user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [teams, setTeams] = useState<string[]>([]);
  const [teamCount, setTeamCount] = useState(2);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [showAuthDialog, setShowAuthDialog] = useState(false);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type,
      name: "",
      description: "",
      venue: "",
      startDate: new Date(),
      endDate: new Date(),
      ...(type === "game" ? {
        homeTeam: "",
        awayTeam: "",
        homeScore: "",
        awayScore: "",
      } : type === "league" ? {
        numberOfTeams: 2,
        teams: [],
        homeAndAway: true,
        pointsForWin: 3,
        pointsForDraw: 1,
        pointsForLoss: 0,
      } : {
        numberOfTeams: 2,
        teams: [],
        matchups: [],
      }),
    } as FormValues,
  });

  const handleTeamCountChange = (count: number) => {
    setTeamCount(count);
    setTeams(Array(count).fill(""));
    if (type === "tournament") {
      const initialMatchups = generateInitialMatches(Array(count).fill(""), false);
      form.setValue("matchups", initialMatchups);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const onSubmit = async (values: FormValues) => {
    if (!user) {
      const wantToLogin = window.confirm(
        "You need to be logged in to create a competition. Would you like to sign in now?"
      );
      if (wantToLogin) {
        sessionStorage.setItem('pendingCompetition', JSON.stringify(values));
        navigate('/auth', { state: { returnTo: `/competitions/new?type=${type}` } });
      }
      return;
    }

    try {
      setIsSubmitting(true);
      let imageUrl = null;

      if (imageFile) {
        const fileExt = imageFile.name.split('.').pop();
        const filePath = `${crypto.randomUUID()}.${fileExt}`;

        const { error: uploadError } = await supabase.storage
          .from('competition-images')
          .upload(filePath, imageFile);

        if (uploadError) {
          console.error('Error uploading image:', uploadError);
          throw new Error(`Failed to upload image: ${uploadError.message}`);
        }

        const { data: { publicUrl } } = supabase.storage
          .from('competition-images')
          .getPublicUrl(filePath);

        imageUrl = publicUrl;
      }

      let teamsArray = [];
      if (values.type === "league" || values.type === "tournament") {
        teamsArray = values.teams.filter(team => team.trim() !== '');
      }

      let matchesArray = [];
      if (values.type === "league") {
        matchesArray = generateInitialMatches(teamsArray, values.homeAndAway).map(match => ({
          ...match,
          id: match.id,
          homeTeam: match.homeTeam,
          awayTeam: match.awayTeam,
          homeScore: null,
          awayScore: null,
          round: match.round
        }));
      }

      const settings = values.type === "game" 
        ? {
            venue: values.venue,
            homeTeam: values.homeTeam,
            awayTeam: values.awayTeam,
            result: {
              homeScore: values.homeScore,
              awayScore: values.awayScore,
            }
          }
        : values.type === "league" 
        ? {
            venue: values.venue,
            teams: teamsArray,
            homeAndAway: values.homeAndAway,
            points: {
              win: values.pointsForWin,
              draw: values.pointsForDraw,
              loss: values.pointsForLoss,
            },
            matches: matchesArray,
          }
        : {
            venue: values.venue,
            teams: teamsArray,
            matchups: values.matchups.map(matchup => ({
              round: matchup.round,
              team1: matchup.team1,
              team2: matchup.team2,
            })),
          };

      const competitionData = {
        name: values.name,
        description: values.description || null,
        type: type,
        start_date: values.startDate.toISOString(),
        end_date: values.endDate.toISOString(),
        created_by: user.id,
        venue_id: null,
        image_url: imageUrl,
        settings: settings,
      };

      console.log('Creating competition with data:', JSON.stringify(competitionData, null, 2));

      const { data, error } = await supabase
        .from("competitions")
        .insert(competitionData)
        .select()
        .single();

      if (error) {
        console.error('Supabase error details:', error);
        throw new Error(`Database error: ${error.message}`);
      }

      if (!data) {
        throw new Error('No competition data returned after creation.');
      }

      toast({
        title: "Competition created",
        description: "Your competition has been created successfully.",
      });

      navigate(`/competitions/${data.id}`);
    } catch (error) {
      console.error("Error creating competition:", error);
      
      const errorMessage = error instanceof Error ? error.message : "Failed to create competition";
      
      toast({
        variant: "destructive",
        title: "Error",
        description: errorMessage,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const storedData = sessionStorage.getItem('pendingCompetition');
    if (storedData && user) {
      const parsedData = JSON.parse(storedData);
      form.reset(parsedData);
      sessionStorage.removeItem('pendingCompetition');
    }
  }, [user]);

  return (
    <div className="container max-w-2xl mx-auto py-8">
      <div className="space-y-6">
        <h1 className="text-3xl font-bold md:pt-4">Create a New {type.charAt(0).toUpperCase() + type.slice(1)}</h1>
        <p className="text-muted-foreground">
          Set up a {type === "game" ? "match" : type} with all the details
        </p>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <div className="flex items-center gap-4">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => navigate("/events-and-games")}
                className="hover:bg-gray-100 h-10 w-10"
              >
                <ArrowLeft className="h-5 w-5" />
              </Button>
              
              <FormItem className="flex-1">
                <FormLabel>Competition Image (Optional)</FormLabel>
                <FormControl>
                  <div className="space-y-4">
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="cursor-pointer"
                    />
                    {imagePreview && (
                      <div className="mt-2">
                        <img
                          src={imagePreview}
                          alt="Preview"
                          className="max-w-full h-auto rounded-lg"
                          style={{ maxHeight: '200px' }}
                        />
                      </div>
                    )}
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            </div>

            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description (Optional)</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Add a description"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="venue"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Venue Name (Optional)</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter venue name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FormField
                control={form.control}
                name="startDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Start Date</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "w-full pl-3 text-left font-normal",
                              !field.value && "text-muted-foreground"
                            )}
                          >
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) =>
                            date < new Date(new Date().setHours(0, 0, 0, 0))
                          }
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="endDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>End Date</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "w-full pl-3 text-left font-normal",
                              !field.value && "text-muted-foreground"
                            )}
                          >
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) =>
                            date < new Date(new Date().setHours(0, 0, 0, 0))
                          }
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {type === "game" && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <FormField
                    control={form.control}
                    name="homeTeam"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Home Team</FormLabel>
                        <FormControl>
                          <Input placeholder="Enter home team" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="awayTeam"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Away Team</FormLabel>
                        <FormControl>
                          <Input placeholder="Enter away team" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <FormField
                    control={form.control}
                    name="homeScore"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Home Score</FormLabel>
                        <FormControl>
                          <Input type="number" placeholder="Enter home score" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="awayScore"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Away Score</FormLabel>
                        <FormControl>
                          <Input type="number" placeholder="Enter away score" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </>
            )}

            {type === "league" && (
              <>
                <FormField
                  control={form.control}
                  name="numberOfTeams"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Number of Teams</FormLabel>
                      <FormControl>
                        <Input 
                          type="number" 
                          min="2"
                          placeholder="Enter number of teams" 
                          {...field}
                          onChange={(e) => {
                            field.onChange(parseInt(e.target.value));
                            handleTeamCountChange(parseInt(e.target.value));
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {Array.from({ length: teamCount }).map((_, index) => (
                  <FormField
                    key={index}
                    control={form.control}
                    name={`teams.${index}`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Team {index + 1}</FormLabel>
                        <FormControl>
                          <Input placeholder={`Enter team ${index + 1} name`} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}

                <FormField
                  control={form.control}
                  name="homeAndAway"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center space-x-2">
                      <FormControl>
                        <input
                          type="checkbox"
                          checked={field.value}
                          onChange={field.onChange}
                          className="h-4 w-4"
                        />
                      </FormControl>
                      <FormLabel className="!mt-0">Home and Away Matches</FormLabel>
                    </FormItem>
                  )}
                />

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <FormField
                    control={form.control}
                    name="pointsForWin"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Points for Win</FormLabel>
                        <FormControl>
                          <Input type="number" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="pointsForDraw"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Points for Draw</FormLabel>
                        <FormControl>
                          <Input type="number" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="pointsForLoss"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Points for Loss</FormLabel>
                        <FormControl>
                          <Input type="number" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </>
            )}

            {type === "tournament" && (
              <>
                <FormField
                  control={form.control}
                  name="numberOfTeams"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Number of Teams (must be even)</FormLabel>
                      <FormControl>
                        <Input 
                          type="number" 
                          min="2"
                          step="2"
                          placeholder="Enter number of teams" 
                          {...field}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (value % 2 === 0) {
                              field.onChange(value);
                              handleTeamCountChange(value);
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {Array.from({ length: teamCount }).map((_, index) => (
                  <FormField
                    key={index}
                    control={form.control}
                    name={`teams.${index}`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Team {index + 1}</FormLabel>
                        <FormControl>
                          <Input placeholder={`Enter team ${index + 1} name`} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}

                {form.watch("matchups")?.map((matchup, index) => (
                  <div key={index} className="grid grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name={`matchups.${index}.team1`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Round {matchup.round} - Team 1</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name={`matchups.${index}.team2`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Round {matchup.round} - Team 2</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                ))}
              </>
            )}

            <div className="flex justify-end space-x-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => navigate("/events-and-games")}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Creating..." : `Create ${type}`}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default CreateCompetition;
