
import { useState, useEffect } from "react";
import { Cloud, CloudRain, CloudSnow, Sun, CloudLightning, Wind, Droplets, CloudSun, CloudFog, Thermometer } from "lucide-react";
import { motion } from "framer-motion";
import { useIsMobile } from "@/hooks/use-mobile";
import { toast } from "sonner";
import { popularCities } from "@/utils/cityData";

type WeatherCondition = {
  icon: JSX.Element;
  gradient: string;
  isOutdoorFriendly: boolean;
};

type WeatherData = {
  location: {
    name: string;
    region: string;
    country: string;
  };
  current: {
    temp_c: number;
    condition: {
      text: string;
      code: number;
    };
    wind_kph: number;
    humidity: number;
    feelslike_c: number;
  };
};

// Create abbreviated city codes
const cityAbbreviations: Record<string, string> = {
  "Delhi": "DEL",
  "Gurgaon": "GGN",
  "Mumbai": "MUM",
  "Bangalore": "BLR",
  "Hyderabad": "HYD",
  "Kolkata": "KOL",
  "Chennai": "CHN",
  "Pune": "PNE",
  "Ahmedabad": "AMD",
  "Jaipur": "JAI",
  "Lucknow": "LKO",
  "Chandigarh": "CHD",
  "Indore": "IDR",
  "Noida": "NOI",
  "Thane": "THN",
  "Navi Mumbai": "NMB",
  "Faridabad": "FBD",
  "Ghaziabad": "GZB",
  "Vadodara": "VAD",
  "Surat": "SRT"
};

// Create two sets of gradients - purple and cyan
const purpleGradients = [
  "from-purple-400 to-indigo-300",
  "from-violet-500 to-purple-300",
  "from-indigo-400 to-purple-400"
];

const cyanGradients = [
  "from-cyan-400 to-blue-300",
  "from-blue-400 to-cyan-300",
  "from-teal-400 to-cyan-400"
];

const weatherConditions: Record<string, WeatherCondition> = {
  "Sunny": {
    icon: <Sun className="w-10 h-10 text-amber-400" />,
    gradient: "from-purple-400 to-indigo-300",
    isOutdoorFriendly: true
  },
  "Clear": {
    icon: <Sun className="w-10 h-10 text-amber-400" />,
    gradient: "from-cyan-400 to-blue-300",
    isOutdoorFriendly: true
  },
  "Partly cloudy": {
    icon: <CloudSun className="w-10 h-10 text-gray-400" />,
    gradient: "from-violet-500 to-purple-300",
    isOutdoorFriendly: true
  },
  "Cloudy": {
    icon: <Cloud className="w-10 h-10 text-gray-500" />,
    gradient: "from-blue-400 to-cyan-300",
    isOutdoorFriendly: true
  },
  "Overcast": {
    icon: <Cloud className="w-10 h-10 text-gray-600" />,
    gradient: "from-indigo-400 to-purple-400",
    isOutdoorFriendly: false
  },
  "Mist": {
    icon: <CloudFog className="w-10 h-10 text-gray-400" />,
    gradient: "from-teal-400 to-cyan-400",
    isOutdoorFriendly: false
  },
  "Fog": {
    icon: <CloudFog className="w-10 h-10 text-gray-400" />,
    gradient: "from-purple-400 to-indigo-300",
    isOutdoorFriendly: false
  },
  "Patchy rain possible": {
    icon: <CloudRain className="w-10 h-10 text-blue-400" />,
    gradient: "from-cyan-400 to-blue-300",
    isOutdoorFriendly: false
  },
  "Patchy snow possible": {
    icon: <CloudSnow className="w-10 h-10 text-blue-100" />,
    gradient: "from-violet-500 to-purple-300",
    isOutdoorFriendly: false
  },
  "Light rain": {
    icon: <CloudRain className="w-10 h-10 text-blue-400" />,
    gradient: "from-blue-400 to-cyan-300",
    isOutdoorFriendly: false
  },
  "Moderate rain": {
    icon: <CloudRain className="w-10 h-10 text-blue-500" />,
    gradient: "from-indigo-400 to-purple-400",
    isOutdoorFriendly: false
  },
  "Heavy rain": {
    icon: <CloudRain className="w-10 h-10 text-blue-600" />,
    gradient: "from-teal-400 to-cyan-400",
    isOutdoorFriendly: false
  },
  "Thunderstorm": {
    icon: <CloudLightning className="w-10 h-10 text-purple-600" />,
    gradient: "from-purple-400 to-indigo-300",
    isOutdoorFriendly: false
  },
  "default": {
    icon: <CloudSun className="w-10 h-10 text-gray-500" />,
    gradient: "from-cyan-400 to-blue-300",
    isOutdoorFriendly: true
  }
};

export const WeatherWidget = () => {
  const [weatherData, setWeatherData] = useState<WeatherData | null>(null);
  const [currentCityIndex, setCurrentCityIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [locationRequested, setLocationRequested] = useState(false);
  const isMobile = useIsMobile();

  // Find index of Delhi in the popularCities array for fallback
  const delhiIndex = popularCities.indexOf("Delhi");
  const defaultCityIndex = delhiIndex >= 0 ? delhiIndex : 0;
  const DEFAULT_CITY = "New Delhi"; // Using exact city name for API

  const fetchWeather = async (city: string) => {
    try {
      setLoading(true);
      console.log(`Fetching weather for: ${city}`);
      
      const response = await fetch(
        `https://api.weatherapi.com/v1/current.json?key=560dfd4c71d946f2bb9221242251203&q=${city}&aqi=no`
      );
      
      if (!response.ok) {
        throw new Error("Weather data fetch failed");
      }
      
      const data = await response.json();
      console.log("Weather data received:", data);
      setWeatherData(data);
      setError(null);
    } catch (err) {
      setError("Could not load weather data");
      console.error("Weather API error:", err);
      
      // If we failed on a city other than our default, try the default city
      if (city !== DEFAULT_CITY) {
        console.log("Attempting to fetch weather for default city:", DEFAULT_CITY);
        fetchWeatherForDefaultCity();
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchWeatherForDefaultCity = async () => {
    try {
      const response = await fetch(
        `https://api.weatherapi.com/v1/current.json?key=560dfd4c71d946f2bb9221242251203&q=${DEFAULT_CITY}&aqi=no`
      );
      
      if (!response.ok) {
        throw new Error("Default city weather data fetch failed");
      }
      
      const data = await response.json();
      console.log("Default city weather data received:", data);
      setWeatherData(data);
      setCurrentCityIndex(defaultCityIndex);
      setError(null);
    } catch (err) {
      setError("Could not load weather data for default city");
      console.error("Default city weather API error:", err);
    } finally {
      setLoading(false);
    }
  };

  const findNearestCity = (userLat: number, userLong: number) => {
    console.log("User coordinates:", userLat, userLong);
    
    // Coordinates for each city (approximate)
    const cityCoords = [
      { city: "Delhi", lat: 28.6139, long: 77.2090, index: popularCities.indexOf("Delhi") },
      { city: "Gurgaon", lat: 28.4595, long: 77.0266, index: popularCities.indexOf("Gurgaon") },
      { city: "Mumbai", lat: 19.0760, long: 72.8777, index: popularCities.indexOf("Mumbai") },
      { city: "Bangalore", lat: 12.9716, long: 77.5946, index: popularCities.indexOf("Bangalore") },
      { city: "Hyderabad", lat: 17.3850, long: 78.4867, index: popularCities.indexOf("Hyderabad") },
      { city: "Kolkata", lat: 22.5726, long: 88.3639, index: popularCities.indexOf("Kolkata") },
      { city: "Chennai", lat: 13.0827, long: 80.2707, index: popularCities.indexOf("Chennai") },
      { city: "Pune", lat: 18.5204, long: 73.8567, index: popularCities.indexOf("Pune") },
      { city: "Ahmedabad", lat: 23.0225, long: 72.5714, index: popularCities.indexOf("Ahmedabad") },
      { city: "Jaipur", lat: 26.9124, long: 75.7873, index: popularCities.indexOf("Jaipur") },
      { city: "Lucknow", lat: 26.8467, long: 80.9462, index: popularCities.indexOf("Lucknow") },
      { city: "Chandigarh", lat: 30.7333, long: 76.7794, index: popularCities.indexOf("Chandigarh") },
      { city: "Indore", lat: 22.7196, long: 75.8577, index: popularCities.indexOf("Indore") },
      { city: "Noida", lat: 28.5355, long: 77.3910, index: popularCities.indexOf("Noida") },
      { city: "Thane", lat: 19.2183, long: 72.9781, index: popularCities.indexOf("Thane") },
      { city: "Navi Mumbai", lat: 19.0330, long: 73.0297, index: popularCities.indexOf("Navi Mumbai") },
      { city: "Faridabad", lat: 28.4089, long: 77.3178, index: popularCities.indexOf("Faridabad") },
      { city: "Ghaziabad", lat: 28.6692, long: 77.4538, index: popularCities.indexOf("Ghaziabad") },
      { city: "Vadodara", lat: 22.3072, long: 73.1812, index: popularCities.indexOf("Vadodara") },
      { city: "Surat", lat: 21.1702, long: 72.8311, index: popularCities.indexOf("Surat") }
    ];
    
    // Calculate distance using the Haversine formula
    const getDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
      const R = 6371; // Radius of the earth in km
      const dLat = (lat2 - lat1) * Math.PI / 180;
      const dLon = (lon2 - lon1) * Math.PI / 180;
      const a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
        Math.sin(dLon/2) * Math.sin(dLon/2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      const d = R * c; // Distance in km
      return d;
    };
    
    let nearestCity = cityCoords[0];
    let minDistance = getDistance(userLat, userLong, nearestCity.lat, nearestCity.long);
    
    cityCoords.forEach(city => {
      const distance = getDistance(userLat, userLong, city.lat, city.long);
      console.log(`Distance to ${city.city}: ${distance.toFixed(2)} km`);
      if (distance < minDistance) {
        minDistance = distance;
        nearestCity = city;
      }
    });
    
    console.log(`Nearest city: ${nearestCity.city} (${minDistance.toFixed(2)} km away)`);
    return nearestCity.index;
  };

  useEffect(() => {
    // Immediately request location permission when component mounts
    const requestLocationPermission = () => {
      if (!locationRequested && navigator.geolocation) {
        setLocationRequested(true);
        setLoading(true);
        
        toast.info("Requesting your location for local weather");
        
        const locationTimeout = setTimeout(() => {
          console.log("Location request timed out");
          if (loading) {
            toast.error("Location request timed out. Showing New Delhi weather.");
            fetchWeatherForDefaultCity();
          }
        }, 10000);
        
        try {
          navigator.permissions.query({ name: 'geolocation' }).then(result => {
            console.log("Geolocation permission status:", result.state);
            
            if (result.state === "denied") {
              clearTimeout(locationTimeout);
              toast.error("Location access denied. Showing New Delhi weather.");
              fetchWeatherForDefaultCity();
              return;
            }
            
            navigator.geolocation.getCurrentPosition(
              (position) => {
                clearTimeout(locationTimeout);
                console.log("Got position:", position.coords.latitude, position.coords.longitude);
                const { latitude, longitude } = position.coords;
                const nearestCityIndex = findNearestCity(latitude, longitude);
                setCurrentCityIndex(nearestCityIndex);
                toast.success(`Weather shown for ${popularCities[nearestCityIndex]}`);
                fetchWeather(popularCities[nearestCityIndex]);
              },
              (error) => {
                clearTimeout(locationTimeout);
                console.error("Geolocation error:", error);
                toast.error("Could not access your location. Showing New Delhi weather.");
                fetchWeatherForDefaultCity();
              },
              {
                enableHighAccuracy: true,
                timeout: 8000,
                maximumAge: 0
              }
            );
          });
        } catch (err) {
          clearTimeout(locationTimeout);
          console.error("Permission API error:", err);
          
          // Fallback to direct geolocation request if permissions API fails
          navigator.geolocation.getCurrentPosition(
            (position) => {
              clearTimeout(locationTimeout);
              const { latitude, longitude } = position.coords;
              const nearestCityIndex = findNearestCity(latitude, longitude);
              setCurrentCityIndex(nearestCityIndex);
              toast.success(`Weather shown for ${popularCities[nearestCityIndex]}`);
              fetchWeather(popularCities[nearestCityIndex]);
            },
            (error) => {
              clearTimeout(locationTimeout);
              console.error("Geolocation error:", error);
              toast.error("Could not access your location. Showing New Delhi weather.");
              fetchWeatherForDefaultCity();
            },
            {
              enableHighAccuracy: true,
              timeout: 8000,
              maximumAge: 0
            }
          );
        }
      } else if (!navigator.geolocation) {
        toast.error("Geolocation is not supported by your browser. Showing New Delhi weather.");
        fetchWeatherForDefaultCity();
      } else if (locationRequested) {
        // Location already requested, but no data fetched yet
        // This ensures we don't try to request location twice
        if (!weatherData && !loading) {
          fetchWeatherForDefaultCity();
        }
      }
    };
    
    // Trigger the location request immediately
    requestLocationPermission();
  }, []);

  const getWeatherCondition = () => {
    if (!weatherData) return weatherConditions.default;
    
    const conditionText = weatherData.current.condition.text;
    return weatherConditions[conditionText] || weatherConditions.default;
  };

  const condition = getWeatherCondition();
  const usesPurpleGradient = currentCityIndex % 2 === 0;
  const gradientClass = usesPurpleGradient 
    ? purpleGradients[currentCityIndex % purpleGradients.length]
    : cyanGradients[currentCityIndex % cyanGradients.length];

  if (!isMobile) return null;

  return (
    <div className="flex flex-col gap-2">
      <motion.div 
        className={`relative p-4 rounded-2xl overflow-hidden shadow-lg bg-gradient-to-r ${gradientClass} h-[120px] mx-1`}
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="absolute inset-0 bg-white opacity-10 pattern-grid-lg"></div>
        
        <div className="relative z-10 flex flex-col h-full">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-white font-bold text-lg">
                {loading ? "Loading..." : weatherData?.location?.name}
              </h3>
              <div className="flex items-center space-x-1 mt-1">
                <motion.div
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  {condition.icon}
                </motion.div>
                <div>
                  <div className="flex items-center">
                    <p className="text-white font-semibold text-2xl ml-1">
                      {loading ? "--" : `${Math.round(weatherData?.current?.temp_c || 0)}°C`}
                    </p>
                    <p className="text-white/90 text-xs ml-2 px-1.5 py-0.5 bg-white/20 rounded-full">
                      {condition.isOutdoorFriendly ? "Outdoor games" : "Indoor games"}
                    </p>
                  </div>
                  <p className="text-white/80 text-xs">
                    {loading ? "--" : weatherData?.current?.condition?.text}
                  </p>
                </div>
              </div>
            </div>
            
            <div className="text-right text-white text-xs space-y-1">
              <div className="flex items-center justify-end">
                <Wind className="w-3 h-3 mr-1" />
                <span>{loading ? "--" : `${weatherData?.current?.wind_kph || 0} km/h`}</span>
              </div>
              <div className="flex items-center justify-end">
                <Droplets className="w-3 h-3 mr-1" />
                <span>{loading ? "--" : `${weatherData?.current?.humidity || 0}%`}</span>
              </div>
              <div className="flex items-center justify-end">
                <Thermometer className="w-3 h-3 mr-1" />
                <span>{loading ? "--" : `Feels ${Math.round(weatherData?.current?.feelslike_c || 0)}°C`}</span>
              </div>
            </div>
          </div>
        </div>

        {loading && (
          <div className="absolute inset-0 bg-black/10 flex items-center justify-center">
            <div className="w-6 h-6 border-2 border-white rounded-full border-t-transparent animate-spin"></div>
          </div>
        )}
        
        {error && !loading && (
          <div className="absolute inset-0 bg-red-500/20 flex items-center justify-center">
            <p className="text-white text-sm">{error}</p>
          </div>
        )}
      </motion.div>
      
      <div className="px-3 text-center">
        <span className="text-xs text-gray-500">
          {cityAbbreviations[popularCities[currentCityIndex]]} - Weather based on your location
        </span>
      </div>
    </div>
  );
};

export default WeatherWidget;
