
import { MapPin, ChevronLeft } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/hooks/use-mobile";
import { cn } from "@/lib/utils";
import { useAuth } from "@/components/AuthProvider";
import { LoginPrompt } from "./LoginPrompt";

interface VenueHeaderProps {
  name: string;
  description: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  images: string[];
}

export const VenueHeader = ({
  name,
  description,
  address,
  city,
  state,
  pincode,
  images,
}: VenueHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const { user } = useAuth();
  
  const defaultImages = [
    "/lovable-uploads/6d93c0e0-02bb-458f-aef6-3e034958468d.png",
    "/lovable-uploads/0418ace8-61d2-4e10-aef9-10f4bcdb5c88.png",
    "/lovable-uploads/da1471a7-f928-4d88-8f95-3e4ae15d4d4b.png"
  ];

  const displayImages = name.toLowerCase().includes('sportslane') 
    ? [...defaultImages, ...(images || [])]
    : images || [];

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleLoginClick = () => {
    navigate("/auth", { state: { returnUrl: window.location.pathname + window.location.search } });
  };

  // Check if we're on the venue booking page
  const isVenueBookingPage = location.pathname.startsWith('/book-venue/');

  // Determine if we should show the login overlay
  const showLoginOverlay = isMobile && !user && isVenueBookingPage;

  return (
    <>
      <Card className={`mb-4 ${isMobile ? 'pb-2' : 'mb-8'}`}>
        {isMobile && (
          <div className="absolute top-10 left-4 z-10">
            <Button
              variant="secondary"
              size="icon"
              className="rounded-full bg-gradient-to-r from-purple-500 to-indigo-500 border-none shadow-md hover:shadow-lg transition-all duration-300"
              onClick={handleBackClick}
              aria-label="Go back"
            >
              <ChevronLeft className="h-5 w-5 text-white" strokeWidth={2.5} />
            </Button>
          </div>
        )}
        <CardHeader className={`${isMobile ? 'pt-5 pb-2' : 'pt-12 md:pt-6'}`}>
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            {isMobile ? (
              <div className="pt-6 text-center w-full">
                <CardTitle className="text-3xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-indigo-600 bg-clip-text text-transparent animate-gradient px-1 py-1 rounded-lg">
                  {name}
                </CardTitle>
                <div className="h-1 w-full mx-auto mt-1.5 rounded-full bg-gradient-to-r from-purple-500 via-pink-400 to-indigo-500 opacity-70"></div>
              </div>
            ) : (
              <div className="text-center md:text-left">
                <CardTitle className="text-3xl font-bold bg-gradient-to-r from-purple-600 via-pink-500 to-indigo-600 bg-clip-text text-transparent animate-gradient px-1 py-1 rounded-lg">
                  {name}
                </CardTitle>
                <div className="h-1 w-full md:w-auto md:min-w-full mt-1.5 rounded-full bg-gradient-to-r from-purple-500 via-pink-400 to-indigo-500 opacity-70"></div>
              </div>
            )}
            {!user && !isMobile && <LoginPrompt />}
          </div>
        </CardHeader>
        <CardContent className={isMobile ? 'pt-0' : undefined}>
          <div className={`aspect-w-16 aspect-h-9 ${isMobile ? 'mb-3' : 'mb-6'}`}>
            {displayImages.length > 0 ? (
              <Carousel className="w-full">
                <CarouselContent>
                  {displayImages.map((image, index) => (
                    <CarouselItem key={index}>
                      <div className="relative">
                        <img
                          src={image}
                          alt={`${name} - Image ${index + 1}`}
                          className={`rounded-lg object-cover w-full ${isMobile ? 'h-[250px]' : 'h-[400px]'}`}
                        />
                      </div>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious className="left-2" />
                <CarouselNext className="right-2" />
              </Carousel>
            ) : (
              <div className={`w-full ${isMobile ? 'h-[250px]' : 'h-[400px]'} bg-gray-100 rounded-lg flex items-center justify-center`}>
                <MapPin className="h-16 w-16 text-gray-400" />
              </div>
            )}
          </div>
          <p className={`text-gray-600 dark:text-gray-600 ${isMobile ? 'mb-2 text-sm line-clamp-2' : 'mb-4'}`}>
            {description}
          </p>
          <div className={`flex items-center text-gray-600 dark:text-gray-600 ${isMobile ? 'text-xs' : ''}`}>
            <MapPin className={`${isMobile ? 'h-3.5 w-3.5' : 'h-5 w-5'} mr-2`} />
            <span>{address}, {city}, {state} - {pincode}</span>
          </div>
        </CardContent>
      </Card>

      <div className="md:hidden mb-4">
        <div className="bg-gradient-to-r from-purple-50 to-indigo-50 dark:from-purple-900/10 dark:to-indigo-900/10 rounded-xl p-3 border border-purple-100/50 dark:border-purple-500/10">
          <div className="flex flex-col items-center text-center space-y-1">
            <p className="text-purple-900 dark:text-gray-900 font-medium text-sm">
              Ready to book your slot?
            </p>
            <p className="text-xs text-purple-700/80 dark:text-gray-900/80">
              Select your preferred timings below
            </p>
          </div>
        </div>
      </div>

      {/* Login Overlay for Mobile Guest Users */}
      {showLoginOverlay && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
          <div 
            className="absolute inset-0 bg-black/60 backdrop-blur-md animate-fadeIn"
            onClick={handleBackClick}
          ></div>
          <div className="w-full max-w-sm bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 p-6 rounded-2xl shadow-2xl border border-white/20 z-10 animate-gradient relative animate-scaleIn">
            <div className="absolute -top-12 left-1/2 transform -translate-x-1/2 w-20 h-20 bg-gradient-to-r from-indigo-600 to-purple-600 rounded-full flex items-center justify-center shadow-lg">
              <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center">
                <img 
                  src="/lovable-uploads/a0c2292c-cf10-4db0-a09d-fb9ada0e5287.png" 
                  alt="beSportly" 
                  className="h-10 w-auto"
                />
              </div>
            </div>
            <div className="pt-10 text-center">
              <h3 className="text-2xl font-bold text-gray-800 mb-2">Join beSportly</h3>
              <p className="text-gray-600 mb-6">Sign in to view all venues and book your favorite sports facilities instantly</p>
              <div className="space-y-3">
                <Button 
                  onClick={handleLoginClick} 
                  className="w-full py-6 bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 text-white font-medium rounded-xl transition-all duration-300 shadow-md hover:shadow-lg flex items-center justify-center gap-2 text-lg"
                >
                  Sign in to continue
                </Button>
                <Button
                  variant="outline"
                  onClick={handleBackClick}
                  className="w-full border-gray-300 text-gray-700 hover:bg-gray-100 rounded-xl"
                >
                  Continue browsing
                </Button>
              </div>
              <div className="mt-6 flex flex-col items-center">
                <div className="flex space-x-3 mt-2">
                  <span className="w-2 h-2 rounded-full bg-indigo-400 animate-pulse"></span>
                  <span className="w-2 h-2 rounded-full bg-purple-400 animate-pulse delay-100"></span>
                  <span className="w-2 h-2 rounded-full bg-pink-400 animate-pulse delay-200"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
